import PropTypes from 'prop-types';
import React from 'react';

import FormInput from '../atoms/FormInput';
import FormLabel from '../atoms/FormLabel';

class FormInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      defaultValue: PropTypes.string,
      errorMessage: PropTypes.array,
    };
  }

  render() {
    return (
      <div className="form-group">
        <FormLabel>{this.props.label}</FormLabel>
        <FormInput
          onChange={this.props.onChange}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          className={
            this.props.errorMessage != null &&
            this.props.errorMessage.length > 0
              ? 'is-invalid'
              : ''
          }
        />
        {this.props.errorMessage != null &&
        this.props.errorMessage.length > 0 ? (
          <div className="invalid-feedback">
            <ul>
              {this.props.errorMessage.map((errorMessage, i) => {
                return <li key={i}>{errorMessage}</li>;
              })}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default FormInputComponent;

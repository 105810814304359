import PropTypes from 'prop-types';
import React from 'react';

import PrimaryButton from '../atoms/PrimaryButton';

class RegisterButtton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      isSubmitting: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.getClass = this.getClass.bind(this);
  }

  getClass() {
    let loadingClass = '';
    if (this.props.isSubmitting) {
      loadingClass = 'btn-progress';
    }

    return loadingClass;
  }

  render() {
    return (
      <PrimaryButton onClick={this.props.onClick} className={this.getClass()}>
        登録
      </PrimaryButton>
    );
  }
}

export default RegisterButtton;

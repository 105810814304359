import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from '../../atoms/NormalButton';
import { DeleteAction } from '../../utils/PermissionComponent';

class DisableButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <DeleteAction>
        <NormalButton color="danger" onClick={this.props.onClick}>
          無効化
        </NormalButton>
      </DeleteAction>
    );
  }
}

export default DisableButton;

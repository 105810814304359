import { array, bool, func, object, shape, string } from 'prop-types';
import React from 'react';

import VenueManagementEdit from '../../templates/venueManagement/Edit';

import routePath from './routePath';

class Edit extends React.Component {
  static get propTypes() {
    return {
      fetchData: func,
      getOptionBases: func,
      getOptionAreas: func,
      getOptionPref: func,
      history: object,
      initState: func,
      deleteReferData: func,
      insertState: func,
      isLoading: bool,
      match: shape({
        params: shape({
          id: string,
        }),
      }),
      postVenue: func,
      state: shape({
        options: array,
        venueRefer: object,
        venueEdit: shape({
          isNewData: bool,
          isComplete: bool,
          newVenueId: string,
        }),
      }),
      storeValues: object,
      updateVenue: func,
      search: func,
      backToEdit: func,
    };
  }

  constructor(props) {
    super(props);

    this.update = this.update.bind(this);

    this.transitionToRefer = this.transitionToRefer.bind(this);
    this.onCloseCompleteModal = this.onCloseCompleteModal.bind(this);

    this.state = {
      id: this.props.match.params.id,
      isLoading: false,
    };
    this.id = this.props.match.params.id;
  }

  componentDidMount() {
    this.props.deleteReferData();
    this.props.fetchData(this.id);
  }

  update(values) {
    this.props.updateVenue(values, this.state.id);
  }

  transitionToRefer() {
    this.props.deleteReferData();
    console.debug('transition to refer');

    if (!this.state.id) {
      this.props.history.push(routePath.search);
      return;
    }
    this.props.history.push(routePath.refer(this.state.id));
  }

  onCloseCompleteModal() {
    this.props.search(
      this.props.state.searchParams,
      this.props.state.searchOptions
    );

    this.transitionToRefer();
  }

  render() {
    return (
      <section className="section">
        <div className="section-header">
          <h1>会場管理</h1>
        </div>
        <div className="section-body">
          <VenueManagementEdit
            isLoading={this.state.isLoading}
            result={this.props.state.venueRefer}
            id={this.state.id}
            history={this.props.history}
            options={this.props.state.options}
            submitFunction={this.update}
            isComplete={
              this.props.state.venueEdit == null
                ? false
                : this.props.state.venueEdit.isComplete
            }
            transitionToRefer={this.transitionToRefer}
            onCloseCompleteModal={this.onCloseCompleteModal}
            storeValues={this.props.storeValues}
            errorMessages={this.props.state.venueEdit.errorMessages}
            backToEdit={this.props.backToEdit}
            isConfirm={this.props.state.venueEdit.isConfirm}
          />
        </div>
      </section>
    );
  }
}

export default Edit;

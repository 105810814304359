export default {
  /**
   * @type {string} 参照画面
   */
  refer: '/admin/profile/refer',
  /**
   *
   * @type {string} 編集画面
   */
  edit: '/admin/profile/edit',
};

import PropTypes from 'prop-types';
import React from 'react';

const smallSize = 'S';
const largeSize = 'L';
const space = ' ';

class LinkButton extends React.Component {
  static get propTypes() {
    return {
      href: PropTypes.string,
      onClick: PropTypes.func,
      optionalClass: PropTypes.string,
      children: PropTypes.any,
      size: PropTypes.number,
      hiddenFlg: PropTypes.bool,
    };
  }

  /**
   * ボタンサイズを設定します。
   * @param {ボタンサイズ} size
   */
  addClassBtnSize(size) {
    if (size === largeSize) {
      return 'btn-lg';
    } else if (size === smallSize) {
      return 'btn-sm';
    } else {
      return '';
    }
  }

  getOptionalClass() {
    const optionalClass = this.props.optionalClass;
    if (optionalClass === undefined || optionalClass == null) {
      return '';
    } else {
      return optionalClass;
    }
  }

  getHref() {
    const href = this.props.href;

    if (href === undefined || href == null) {
      return '#!';
    } else {
      return href;
    }
  }

  /**
   * メイン
   */
  render() {
    return (
      <a
        href={this.getHref()}
        className={
          'btn ' +
          space +
          this.addClassBtnSize(this.props.size) +
          space +
          this.getOptionalClass()
        }
        style={{ display: this.props.hiddenFlg ? 'none' : '' }}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </a>
    );
  }
}

export default LinkButton;

import PropTypes from 'prop-types';
import React from 'react';

import PrimaryButton from '../../atoms/PrimaryButton';
import { EditAction } from '../../utils/PermissionComponent';

class TransitionBulkUpdateButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      children: PropTypes.node,
    };
  }

  render() {
    return (
      <EditAction>
        <PrimaryButton onClick={this.props.onClick}>
          {this.props.children}
        </PrimaryButton>
      </EditAction>
    );
  }
}

export default TransitionBulkUpdateButton;

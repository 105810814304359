import PropTypes from 'prop-types';
import React from 'react';
import { Switch } from 'react-router-dom';

import RouteWithSubRoutes from '../../RouterUtil';
import { ActionCreatorProvider } from '../../utils/PermissionComponent';

class Venue extends React.Component {
  static get propTypes() {
    return {
      routes: PropTypes.array,
    };
  }

  render() {
    return (
      <ActionCreatorProvider menu={'/admin/venues'}>
        <Switch>
          {this.props.routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </ActionCreatorProvider>
    );
  }
}

export default Venue;

import { push } from 'connected-react-router';

import Action from '../components/model/Action';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('sidebar');

const fetchMenus = () => {
  return axios.get('/admin/accessible_menus');
};

const fetchMenusSuccess = (data) => {
  return new Action('sidebar/FETCH_MENUS', data);
};

const makeFetchMenus = () => {
  return async (dispatch) => {
    try {
      const menuRes = await fetchMenus();

      dispatch(fetchMenusSuccess(menuRes.data));
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const transitionToDefaultPage = (loginMenuPath) => {
  return push(loginMenuPath);
};

export { makeFetchMenus, transitionToDefaultPage };

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ProfileImage from '../../adminProfile/ProfileImage';

const DisableUserRow = styled.th`
  background-color: rgba(0, 0, 0, 0.03);
`;

export default class SelfInformation extends React.Component {
  static get propTypes() {
    return {
      userRefer: PropTypes.object,
    };
  }

  render() {
    const selector = (prop, name) => {
      if (
        Object.hasOwnProperty.call(this.props.userRefer, prop) &&
        Object.hasOwnProperty.call(this.props.userRefer[prop], name)
      ) {
        return this.props.userRefer[prop][name];
      }
      return null;
    };

    const selectorOr = (selector, name, elseValue) => {
      const value = selector(name);

      if (value != null) {
        return value;
      }
      return elseValue;
    };

    const profileBaseInfoSelector = selector.bind(this, 'profileBaseInfo');
    const profileCareerInfoSelector = selector.bind(this, 'profileCareerInfo');
    const profileWorkInfoSelector = selector.bind(this, 'profileWorkInfo');

    const selectorMap = (selector, name, f) => {
      const value = selector(name);

      if (value != null) {
        return f(value);
      }
      return null;
    };

    const getURL = () => {
      return selectorMap(profileBaseInfoSelector, 'profileImgId', (url) => {
        return `${process.env.REACT_APP_API_URI}/${url}`;
      });
    };

    const getName = () => {
      return selectorMap(
        profileBaseInfoSelector,
        'familyName',
        (familyName) => {
          return selectorMap(
            profileBaseInfoSelector,
            'firstName',
            (firstName) => {
              return `${familyName} ${firstName}`;
            }
          );
        }
      );
    };

    const getKana = () => {
      return selectorMap(
        profileBaseInfoSelector,
        'familyNameKana',
        (familyNameKana) => {
          return selectorMap(
            profileBaseInfoSelector,
            'firstNameKana',
            (firstNameKana) => {
              return `${familyNameKana} ${firstNameKana}`;
            }
          );
        }
      );
    };

    const getBirhtDay = () => {
      return profileBaseInfoSelector('dateOfBirth');
    };

    const getPhoneNumberMobile = () => {
      return profileBaseInfoSelector('phoneNumberMobile');
    };

    const getPhoneNumberHome = () => {
      return profileBaseInfoSelector('phoneNumberHome');
    };

    const getMailAddress = () => {
      return profileBaseInfoSelector('mailAddress');
    };

    const getGender = () => {
      return profileBaseInfoSelector('gender');
    };

    const getBirthplacePref = () => {
      return profileCareerInfoSelector('birthplacePref');
    };

    const getAddressPref = () => {
      return profileCareerInfoSelector('addressPref');
    };

    const getAddress = () => {
      const profileCareerInfoSelectorMap = selectorMap.bind(null, (name) => {
        return selectorOr(profileCareerInfoSelector, name, '');
      });

      return profileCareerInfoSelectorMap('addressPref', (addressPref) => {
        console.debug('getAddress, addressPref: ', addressPref);
        return profileCareerInfoSelectorMap('addressCity', (addressCity) => {
          console.debug('getAddress, addressCity: ', addressCity);

          return profileCareerInfoSelectorMap(
            'addressBlock',
            (addressBlock) => {
              console.debug('getAddress, addressBlock: ', addressBlock);

              return profileCareerInfoSelectorMap(
                'addressBuildingName',
                (addressBuildingName) => {
                  console.debug(
                    'getAddress, addressBuildingName: ',
                    addressBuildingName
                  );

                  return `${addressPref}${addressCity}${addressBlock}${addressBuildingName}`;
                }
              );
            }
          );
        });
      });
    };

    const getNearestStation = () => {
      return profileCareerInfoSelector('nearestStation');
    };

    const getIsWithFamily = () => {
      return profileCareerInfoSelector('isWithFamily');
    };

    const getMovingPossibility = () => {
      return profileCareerInfoSelector('movingPossibility');
    };

    // 普通自動車免許
    const getHaveDrivingLicense = () => {
      return profileWorkInfoSelector('haveDrivingLicense');
    };

    // 留学・海外滞在歴
    const getExperienceAbroad = () => {
      return profileWorkInfoSelector('experienceAbroad');
    };

    // 資格1
    const getQualification1 = () => {
      return profileWorkInfoSelector('qualification1');
    };

    // 資格2
    const getQualification2 = () => {
      return profileWorkInfoSelector('qualification2');
    };

    // 資格3
    const getQualification3 = () => {
      return profileWorkInfoSelector('qualification3');
    };

    // 趣味・特技・サークル経験など
    const getSkills = () => {
      return profileWorkInfoSelector('skills');
    };

    return (
      <>
        <h3>個人情報</h3>
        <ProfileImage label="プロフィール画像" url={getURL()} />

        <table className="table" border="1">
          <tr>
            <DisableUserRow scope="col">氏名</DisableUserRow>
            <th scope="col">{getName()}</th>
            <DisableUserRow scope="col">フリガナ</DisableUserRow>
            <th scope="col">{getKana()}</th>
            <DisableUserRow scope="col">生年月日</DisableUserRow>
            <th scope="col">{getBirhtDay()}</th>
          </tr>
        </table>
        <br />

        <table className="table" border="1">
          <tr>
            <DisableUserRow scope="col">電話番号</DisableUserRow>
            <th scope="col">{getPhoneNumberMobile()}</th>
            <DisableUserRow scope="col">メールアドレス</DisableUserRow>
            <th scope="col">{getMailAddress()}</th>
            <DisableUserRow scope="col">性別</DisableUserRow>
            <th scope="col">{getGender()}</th>
          </tr>
          <tr>
            <DisableUserRow scope="col">自宅電話番号</DisableUserRow>
            <th scope="col">{getPhoneNumberHome()}</th>
            <DisableUserRow scope="col">出身地</DisableUserRow>
            <th scope="col">{getBirthplacePref()}</th>
          </tr>
        </table>
        <br />

        <table className="table" border="1">
          <tr>
            <DisableUserRow scope="col">現住所(都道府県)</DisableUserRow>
            <th scope="col">{getAddressPref()}</th>
            <DisableUserRow scope="col">最寄駅(線/駅)</DisableUserRow>
            <th scope="col" colSpan="4">
              {getNearestStation()}
            </th>
          </tr>
          <tr>
            <DisableUserRow scope="col">現住所(詳細)</DisableUserRow>
            <th scope="col" colSpan="6">
              {getAddress()}
            </th>
          </tr>
          <tr>
            <DisableUserRow scope="col">同居状況</DisableUserRow>
            <th scope="col">{getIsWithFamily()}</th>
            <DisableUserRow scope="col">転居の可・不可</DisableUserRow>
            <th scope="col" colSpan="4">
              {getMovingPossibility()}
            </th>
          </tr>
        </table>
        <br />

        <table className="table" border="1">
          <tr>
            <DisableUserRow scope="col">普通自動車免許</DisableUserRow>
            <th scope="col">{getHaveDrivingLicense()}</th>
            <DisableUserRow scope="col">留学・海外滞在歴</DisableUserRow>
            <th scope="col" colSpan="4">
              {getExperienceAbroad()}
            </th>
          </tr>
          <tr>
            <DisableUserRow scope="col">資格1</DisableUserRow>
            <th scope="col">{getQualification1()}</th>
            <DisableUserRow scope="col">資格2</DisableUserRow>
            <th scope="col">{getQualification2()}</th>
            <DisableUserRow scope="col">資格3</DisableUserRow>
            <th scope="col">{getQualification3()}</th>
          </tr>
          <tr>
            <DisableUserRow scope="col" colSpan="2">
              趣味・特技・サークル経験など
            </DisableUserRow>
            <th scope="col" colSpan="4">
              {getSkills()}
            </th>
          </tr>
        </table>
        <br />
      </>
    );
  }
}

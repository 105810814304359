const initialState = {
  navBar: {
    isDisplayErrorModal: false,
  },
};

const navVarReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'common/FETCH_PROFILE': {
      const user = action.payload;

      return {
        ...state,
        loginUser: {
          profileId: user.profileId,
          firstName: user.firstName,
          familyName: user.familyName,
          loginMenuPath: user.loginMenuPath,
        },
      };
    }
    case 'authentication/FETCH_LOGOUT_ERROR': {
      return {
        ...state,
        navBar: {
          isDisplayErrorModal: true,
        },
      };
    }
    case 'navbar/HIDE_ERROR_MODAL': {
      return {
        ...state,
        navBar: {
          isDisplayErrorModal: false,
        },
      };
    }
    default:
      return state;
  }
};

export { navVarReducers as default };

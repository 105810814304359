import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import ModalBody from '../atoms/ModalBody';
import ModalFotterCloseButton from '../molecules/ModalFotterCloseButtonComponent';

class DeleteCompleteModal extends React.Component {
  static get propTypes() {
    return {
      targetId: PropTypes.string,
      children: PropTypes.string,
      onclick: PropTypes.string,
      show: PropTypes.bool,
      onCloseClick: PropTypes.func,
    };
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>
          <p className="text-center">正常に削除が完了しました</p>
        </ModalBody>
        <ModalFotterCloseButton
          closeButtonTitle="閉じる"
          onClick={this.props.onCloseClick}
        />
      </Modal>
    );
  }
}

export default DeleteCompleteModal;

import React from 'react';

class Footer extends React.Component {
  getCurrentYear() {
    const date = new Date();

    return date.getFullYear();
  }

  render() {
    return (
      <footer className="main-footer">
        <div className="footer-left">&copy; {this.getCurrentYear()} JAIC</div>
      </footer>
    );
  }
}

export default Footer;

import PropTypes from 'prop-types';
import React from 'react';

import PrimaryButton from '../atoms/PrimaryButton';
import { RegisterAction } from '../utils/PermissionComponent';

class CopyButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <RegisterAction>
        <PrimaryButton onClick={this.props.onClick}>複製</PrimaryButton>
      </RegisterAction>
    );
  }
}

export default CopyButton;

import { trackPromise } from 'react-promise-tracker';

import Action, { EmptyAction } from '../components/model/Action';
import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';
import { trackAsync } from './util';

const axios = createAxios('/admin/tag');

const fetchTagsSuccess = (result) => {
  return {
    type: 'tag/FETCH_STAFF_SEARCH',
    payload: {
      result: result,
    },
  };
};

const search = (values, searchParams) => {
  const params = new Params({
    name: values.name,
    romanCharName: values.romanCharName,
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  });

  return axios('/admin/tags/search', {
    params: params.removeParams(),
  });
};

const storeSearchValues = (values, searchParams) => {
  return new Action('tag/STORE_SEARCH_VALUES', {
    values,
    searchParams,
  });
};

const makeSearch = (values, searchParams) => {
  return (dispatch) => {
    dispatch(storeSearchValues(values, searchParams));
    return trackPromise(
      search(values, searchParams)
        .then((data) => dispatch(fetchTagsSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const hideLoading = () => {
  return new EmptyAction('tag/HIDE_LOADING');
};

const makeInitTagWithoutSearch = () => {
  return new EmptyAction('tag/INIT_TAG_WITHOUT_SEARCH');
};

const makeInitTag = (isComeFromOtherPage) => {
  return (dispatch) => {
    dispatch(hideLoading());
    if (isComeFromOtherPage) {
      dispatch(new EmptyAction('tag/INIT_TAG'));
    } else {
      dispatch(makeInitTagWithoutSearch());
    }
  };
};

const confirmIsUsed = (tagIds) => {
  return axios.get('/admin/tags/used', {
    params: {
      tags: tagIds,
    },
  });
};

const showDeleteConfirm = () => {
  return {
    type: 'tag/SHOW_DELETE_CONFIRM',
  };
};

const confirmIsUsedSuccess = (data) => {
  if (data.containsUsed) {
    return new EmptyAction('tag/SHOW_USED_CONTAINS_ALERT');
  } else {
    return showDeleteConfirm();
  }
};

const makeConfirmIsUsed = (tagIds) => {
  return (dispatch) => {
    return trackAsync(async () => {
      try {
        const isUsedRes = await confirmIsUsed(tagIds);
        dispatch(confirmIsUsedSuccess(isUsedRes.data));
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

const makeHideContainsAlertModal = () => {
  return new EmptyAction('tag/HIDE_USED_CONTAINS_ALERT');
};

const makeHideDeleteConfirmModal = () => {
  return new EmptyAction('tag/HIDE_DELETE_CONFIRM_MODAL');
};

const deleteTags = (ids) => {
  return axios.delete('/admin/tags', {
    data: {
      tagIds: ids,
    },
  });
};

const deleteTagsSuccess = (data) => {
  return {
    type: 'tag/DELETE_TAGS_SUCCESS',
    payload: {
      result: data,
    },
  };
};

const makeDeleteTags = (ids) => {
  return (dispatch) => {
    return trackPromise(
      deleteTags(ids)
        .then((data) => {
          dispatch(deleteTagsSuccess(data.data));
          dispatch(makeHideDeleteConfirmModal());
          dispatch(makeShowDeleteCompleteModal());
        })
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const makeShowDeleteCompleteModal = () => {
  return new EmptyAction('tag/SHOW_DELETE_COMPLETE_MODAL');
};

const makeHideDeleteCompleteModal = (searchValues) => {
  return (dispatch) => {
    if (searchValues && Object.keys(searchValues).length > 0) {
      search(searchValues.values, searchValues.searchParams)
        .then((data) => {
          dispatch(fetchTagsSuccess(data.data));
          dispatch(new EmptyAction('tag/HIDE_DELETE_COMPLETE_MODAL'));
        })
        .catch((err) => {
          dispatch(fetchErrorData(err));
        });
    } else {
      dispatch(new EmptyAction('tag/HIDE_DELETE_COMPLETE_MODAL'));
    }
  };
};

const fetchTag = (id) => {
  return axios.get(`/admin/tag/${id}`);
};

const fetchTagSuccess = (data) => {
  return new Action('tag/FETCH_TAG_SUCCESS', data);
};

const makeFetchTag = (tagId) => {
  return (dispatch) => {
    return trackPromise(
      fetchTag(tagId)
        .then((data) => {
          dispatch(fetchTagSuccess(data.data));
        })
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const makeSetEditValues = (values) => {
  return new Action('tag/SET_EDIT_VALUES', values);
};

const makeBackToInput = () => {
  return new EmptyAction('tag/GO_BACK_TO_INPUT');
};

const makeFetchCreateTag = (params) => {
  return axios.post(`/admin/tags`, params);
};

const makeFetchUpdateTag = (id, params) => {
  return axios.put(`/admin/tag/${id}`, params);
};

const fetchCreateTagSuccess = () => {
  return new EmptyAction('tag/CREATE_TAG_SUCCESS');
};

const fetchUpdateTagSuccess = () => {
  return new EmptyAction('tag/UPDATE_TAG_SUCCESS');
};

const makeUpdateError = (err) => {
  if (err.response) {
    if (err.response.status === 400 && err.response.data) {
      return new Action('tag/UPDATE_ERROR', err.response.data);
    }
  }

  return fetchErrorData(err);
};

const makeUpdateTag = (values) => {
  return (dispatch) => {
    let displayOrder;

    if (values.displayOrder) {
      displayOrder = parseInt(values.displayOrder);
    }

    const params = new Params({
      id: values.id,
      name: values.name,
      romanCharName: values.romanCharName,
      displayOrder: displayOrder,
    }).removeParams();
    if (values.id == null) {
      return trackPromise(
        makeFetchCreateTag(params)
          .then(() => {
            dispatch(fetchCreateTagSuccess());
          })
          .catch((err) => dispatch(makeUpdateError(err)))
      );
    } else {
      return trackPromise(
        makeFetchUpdateTag(values.id, params)
          .then(() => {
            dispatch(fetchUpdateTagSuccess());
          })
          .catch((err) => dispatch(makeUpdateError(err)))
      );
    }
  };
};

const makeInitEdit = () => {
  return new EmptyAction('tag/INIT_TAG_EDIT');
};

const makeInitTagEdit = (tagId) => {
  return (dispatch) => {
    return trackAsync(async () => {
      try {
        dispatch(makeInitEdit());

        if (tagId) {
          const tagData = await fetchTag(tagId);
          dispatch(fetchTagSuccess(tagData.data));
        }
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

export {
  makeSearch,
  makeInitTag,
  hideLoading,
  makeConfirmIsUsed,
  makeHideContainsAlertModal,
  makeHideDeleteConfirmModal,
  makeDeleteTags,
  makeHideDeleteCompleteModal,
  makeFetchTag,
  makeSetEditValues,
  makeBackToInput,
  makeUpdateTag,
  makeInitTagEdit,
};

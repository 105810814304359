import PropTypes from 'prop-types';
import React from 'react';

import InfoFormButton from '../atoms/InfoFormButton';
import { ReferAction } from '../utils/PermissionComponent';

class SearchButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      children: PropTypes.node,
    };
  }

  render() {
    return (
      <ReferAction>
        <InfoFormButton onClick={this.props.onClick}>
          {this.props.children ? this.props.children : '検索'}
        </InfoFormButton>
      </ReferAction>
    );
  }
}

export default SearchButton;

import PropTypes, { number } from 'prop-types';
import React from 'react';

import CardBody from '../atoms/CardBody';
import CardHeader from '../atoms/CardHeader';

class Card extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      headerTitle: PropTypes.string,
      color: number,
    };
  }

  render() {
    let color = '';
    if (Object.prototype.hasOwnProperty.call(this.props, 'color')) {
      switch (this.props.color) {
        case Color.PRIMARY: {
          color = ' card-primary';
        }
      }
    }

    return (
      <div className={`card${color}`}>
        <CardHeader>{this.props.headerTitle}</CardHeader>
        <CardBody>{this.props.children}</CardBody>
      </div>
    );
  }
}

export default Card;

export const Color = {
  NONE: -1,
  PRIMARY: 0,
};

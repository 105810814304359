import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from '../atoms/NormalButton';
import { RegisterAction } from '../utils/PermissionComponent';

class NewButton extends React.Component {
  static get propTypes() {
    return {
      message: PropTypes.string,
      buttonHidenFlg: PropTypes.bool,
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <RegisterAction>
        <NormalButton
          color="primary"
          onClick={this.props.onClick}
          hiddenFlg={this.props.buttonHidenFlg}
        >
          新規作成
        </NormalButton>
      </RegisterAction>
    );
  }
}

export default NewButton;

import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

class FormNumber extends React.Component {
  static get propTypes() {
    return {
      placeholder: PropTypes.string,
      onChange: PropTypes.func,
      value: PropTypes.string,
      defaultValue: PropTypes.string,
      className: PropTypes.string,
      input: PropTypes.any,
      meta: PropTypes.any,
      min: PropTypes.string,
      max: PropTypes.string,
      step: PropTypes.string,
      field: PropTypes.shape({
        input: PropTypes.object,
        meta: PropTypes.object,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    const { input, meta, ...other } = this.props.field;

    return (
      <Form.Control
        type="number"
        {...input}
        {...other}
        step={this.props.step}
        min={this.props.min}
        max={this.props.max}
        className={this.props.className}
        isInvalid={meta.touched && meta.error}
        value={this.props.value}
        defaultValue={this.props.defaultValue}
      ></Form.Control>
    );
  }
}

export default FormNumber;

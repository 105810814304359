import PropTypes from 'prop-types';
import React from 'react';

import DangerButton from '../atoms/DangerButton';
import LightButton from '../atoms/LightButton';
import ModalFooter from '../atoms/ModalFooter';

class ModalFotterYesNoButtonComponent extends React.Component {
  static get propTypes() {
    return {
      onYesClick: PropTypes.func,
      onNoClick: PropTypes.func,
    };
  }

  render() {
    return (
      <ModalFooter>
        <LightButton onClick={this.props.onNoClick}>いいえ</LightButton>
        <DangerButton onClick={this.props.onYesClick}>はい</DangerButton>
      </ModalFooter>
    );
  }
}

export default ModalFotterYesNoButtonComponent;

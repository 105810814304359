import PropTypes from 'prop-types';
import React from 'react';

import Link from '../atoms/Link';


class TdLink extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string
    };
  }

  render() {
    return (
      <td>
        <Link
          href={this.props.name} >
            name={this.props.name}
        </Link>
      </td>
    )
  }
}

export default TdLink;

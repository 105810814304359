import PropTypes from 'prop-types';
import React from 'react';

import ModalFooter from '../atoms/ModalFooter';
import PrimaryButton from '../atoms/PrimaryButton';

import ModalCloseButton from './ModalCloseButton';

class ModalFooterThreeButtonComponent extends React.Component {
  static get propTypes() {
    return {
      leftButtonLabel: PropTypes.string,
      leftButtonHref: PropTypes.func,
      leftButtonAction: PropTypes.element,
      rightButtonLabel: PropTypes.string,
      rightbuttonHref: PropTypes.func,
      rightButtonAction: PropTypes.element,
      rightButtonDisable: PropTypes.bool,
      onClickCloseButton: PropTypes.func,
      onClickLeftButton: PropTypes.func,
      onClickRightButton: PropTypes.func,
    };
  }

  render() {
    let LeftButtonAction = React.Fragment;
    if (this.props.leftButtonAction) {
      LeftButtonAction = this.props.leftButtonAction;
    }

    let RightButtonAction = React.Fragment;
    if (this.props.rightButtonAction) {
      RightButtonAction = this.props.rightButtonAction;
    }

    return (
      <ModalFooter>
        <ModalCloseButton onClick={this.props.onClickCloseButton}>
          閉じる
        </ModalCloseButton>
        <LeftButtonAction>
          <PrimaryButton onClick={this.props.onClickLeftButton}>
            {this.props.leftButtonLabel}
          </PrimaryButton>
        </LeftButtonAction>
        <RightButtonAction>
          <PrimaryButton
            onClick={this.props.onClickRightButton}
            isEnabled={() => {
              return !this.props.rightButtonDisable;
            }}
          >
            {this.props.rightButtonLabel}
          </PrimaryButton>
        </RightButtonAction>
      </ModalFooter>
    );
  }
}

export default ModalFooterThreeButtonComponent;

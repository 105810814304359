import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import RegisterButton from '../../molecules/RegisterButton';
import SaveButton from '../../molecules/SaveButton';

class TagEditConfirmForm extends React.Component {
  static get propTypes() {
    return {
      onSubmit: PropTypes.func,
      backToInput: PropTypes.func,
      options: PropTypes.shape({
        bases: PropTypes.array,
        divisions: PropTypes.array,
        occupations: PropTypes.array,
        roles: PropTypes.array,
      }),
      values: PropTypes.any,
      fetchRole: PropTypes.func,
      state: PropTypes.shape({
        role: PropTypes.shape({ isSystemAdmin: PropTypes.bool }),
        isSubmitting: PropTypes.bool,
      }),
      isNew: PropTypes.bool,
    };
  }

  componentDidMount() {}

  render() {
    const getName = () => {
      return this.props.values.name;
    };

    return (
      <>
        <ReferItem label="名前">{getName()}</ReferItem>

        <hr />
        <Row>
          <div className="mx-auto">
            <PrevButton onClick={this.props.backToInput} />
            {this.props.isNew ? (
              <RegisterButton
                onClick={this.props.onSubmit}
                isSubmitting={this.props.state.isSubmitting}
              />
            ) : (
              <SaveButton
                onClick={this.props.onSubmit}
                isSubmitting={this.props.state.isSubmitting}
              />
            )}
          </div>
        </Row>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      state: state.company.companyEdit,
      options: state.options,
    };
  },
  (dispatch) => {
    return {};
  }
)(TagEditConfirmForm);

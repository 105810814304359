import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from '../atoms/NormalButton';

class DeleteFileButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <NormalButton color="danger" onClick={this.props.onClick}>
        画像の削除
      </NormalButton>
    );
  }
}

export default DeleteFileButton;

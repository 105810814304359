const initialState = {
  searchResultsInterview: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    result: [],
  },
  searchType: null,
  searchResultsEventSession: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    result: [],
  },
  searchResultsEventSeminor: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    result: [],
  },
  searchResults: {
    interviewBases: [],
    interviewAreas: [],
    interviewDays: [],
    eventSessionBases: [],
    eventSeminorBases: [],

    errorMessage: [],
  },
  searchParams: {
    interview: {
      searchParams: {},
      values: {},
      id: null,
      searchType: null,
    },
    session: {
      searchParams: {},
      values: {},
      id: null,
    },
    seminar: {
      searchParams: {},
      values: {},
      id: null,
    },
  },
  isLoading: false,
};

const summaryReducer = (state = initialState, action) => {
  switch (action.type) {
    // 面談
    case 'summary/FETCH_SUMMARY_SEARCH_INTERVIEW_BASE': {
      const result = action.payload.result;
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          interviewBases: result.baseList,
          interviewAreas: result.areaList,
          interviewDays: result.dayList,
          errorMessage: result.errorMessage,
        },
      };
    }
    case 'summary/FETCH_SUMMARY_SEARCH_INTERVIEW': {
      const searchResults = action.payload.result;
      return {
        ...state,
        searchResultsInterview: searchResults,
        searchType: action.payload.searchType,
      };
    }

    // セミナー
    case 'summary/FETCH_SUMMARY_SEARCH_EVENT_SEMINOR_BASE': {
      const result = action.payload.result;
      return {
        ...state,
        searchResults: { ...state.searchResults, eventSeminorBases: result },
      };
    }
    case 'summary/FETCH_SUMMARY_SEARCH_EVENT_SEMINOR': {
      const searchResults = action.payload.result;
      return { ...state, searchResultsEventSeminor: searchResults };
    }

    // 説明会
    case 'summary/FETCH_SUMMARY_SEARCH_EVENT_SESSION_BASE': {
      const result = action.payload.result;
      return {
        ...state,
        searchResults: { ...state.searchResults, eventSessionBases: result },
      };
    }
    case 'summary/FETCH_SUMMARY_SEARCH_EVENT_SESSION': {
      const searchResults = action.payload.result;
      return { ...state, searchResultsEventSession: searchResults };
    }

    case 'SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'summary/INIT_STATE': {
      return initialState;
    }
    case 'summary/STORE_SEARCH_INTERVIEWS_PARAMS': {
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          interview: {
            id: action.payload.id,
            searchParams: action.payload.searchParams,
            values: action.payload.values,
            searchType: action.payload.searchType,
          },
        },
      };
    }
    case 'summary/STORE_SEARCH_SESSION_PARAMS': {
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          session: {
            id: action.payload.id,
            searchParams: action.payload.searchParams,
            values: action.payload.values,
          },
        },
      };
    }
    case 'summary/STORE_SEARCH_SEMINAR_PARAMS': {
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          seminar: {
            id: action.payload.id,
            searchParams: action.payload.searchParams,
            values: action.payload.values,
          },
        },
      };
    }
    default:
      return state;
  }
};

export { summaryReducer as default };

import PropTypes, {
  array,
  bool,
  func,
  number,
  object,
  shape,
  string,
} from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Field } from 'redux-form';
import styled from 'styled-components';

import FormInput from '../../atoms/FormInput';
import FormLabel from '../../atoms/FormLabel';
import NormalButton from '../../atoms/NormalButton';
import DeleteFileButton from '../../molecules/DeleteFileButton';
import FileButton from '../../molecules/FileButton';
import * as Validator from '../../utils/Validate';

class HorizontalFormAccessComponent extends React.Component {
  static get propTypes() {
    return {
      accessList: PropTypes.array,
      label: PropTypes.string,
      name: PropTypes.string,
      buttonLabel: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      isNew: PropTypes.bool,
      baseEdit: PropTypes.shape({
        isSubmitting: PropTypes.bool,
        isComplete: PropTypes.bool,
        accessList: PropTypes.array,
      }),
      addAccessList: PropTypes.array,
      deleteAccessList: PropTypes.array,
      fields: object,
      updateFile: func,
      deleteAccessMapImage: func,
      meta: shape({ error: array, submitFailed: bool }),
    };
  }

  constructor(props) {
    super(props);

    this.onAddClick = this.onAddClick.bind(this);
    this.onChangeUpload = this.onChangeUpload.bind(this);
    this.onClickDeleteAccessMapImage = this.onClickDeleteAccessMapImage.bind(
      this
    );
  }

  // 新規追加
  onAddClick() {
    this.props.fields.push({});
  }

  // 削除
  onDelClick(i) {
    this.props.fields.remove(i);
  }

  onChangeUpload(index, e) {
    const target = e.target;
    const file = target.files.item(0);
    console.debug('handleChangefile', target);
    console.debug(target.value);
    console.debug('file', file);

    if (!file) {
      return;
    }

    this.props.updateFile(file, index);
  }

  onClickDeleteAccessMapImage(index, e) {
    this.props.deleteAccessMapImage(index);
  }

  render() {
    const { fields } = this.props;

    return (
      <div className="form-group row">
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          <span className="text-danger">*</span>
        </FormLabel>
        <div className="col-sm-6">
          {fields.map((member, index) => (
            <AccessComponent
              onChangeUpload={this.onChangeUpload.bind(null, index)}
              onClickDeleteAccessMapImage={this.onClickDeleteAccessMapImage.bind(
                null,
                index
              )}
              onAddClick={this.onAddClick}
              onDelClick={this.onDelClick.bind(this, index)}
              key={index}
              member={member}
              index={index}
              maxItemNum={fields.length}
            />
          ))}
        </div>
      </div>
    );
  }
}

const MaxWidthImg = styled.img`
  width: 100%;
  margin-top: 10px;
`;

const DisplayFeedBack = styled(Form.Control.Feedback)`
  display: block;
`;

const renderImg = (field) => {
  return (
    <>
      {field.input.name ? (
        <Element name={`position-${field.input.name}`} />
      ) : (
        <></>
      )}
      {field.input.value ? (
        <MaxWidthImg
          alt="image"
          src={`${process.env.REACT_APP_API_URI}/${field.input.value}`}
        />
      ) : (
        <></>
      )}
      {field.meta.error && (
        <DisplayFeedBack type="invalid">{field.meta.error}</DisplayFeedBack>
      )}
    </>
  );
};

const MarginDiv = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

const renderField = (field) => {
  return (
    <>
      <MarginDiv>
        <FormInput {...field} />
        {field.meta.touched && field.meta.error && (
          <Form.Control.Feedback type="invalid">
            {field.meta.error}
          </Form.Control.Feedback>
        )}
      </MarginDiv>
    </>
  );
};

const AccessComponent = (props) => {
  const {
    index,
    member,
    maxItemNum,
    onChangeUpload,
    onClickDeleteAccessMapImage,
    onAddClick,
    onDelClick,
  } = props;
  return (
    <div key={index}>
      <FileButton onChange={onChangeUpload} />
      <DeleteFileButton onClick={onClickDeleteAccessMapImage} />

      <div>
        <div>
          <Field
            name={`${member}.accessMap`}
            component={renderImg}
            validate={[validateAccessMap]}
            label="accessMap"
          />
          <Field
            name={`${member}.accessNote`}
            type="text"
            component={renderField}
            label="accessNote"
          />

          <div className="float-right">
            {index !== 0 ? (
              <>
                <NormalButton
                  color="danger"
                  onClick={onDelClick}
                  hiddenFlg={false}
                >
                  削除
                </NormalButton>
              </>
            ) : (
              <></>
            )}
            {index === maxItemNum - 1 ? (
              <NormalButton
                color="success"
                onClick={onAddClick}
                hiddenFlg={false}
              >
                追加
              </NormalButton>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AccessComponent.propTypes = {
  index: number,
  member: string,
  maxItemNum: number,
  onChangeUpload: func,
  onClickDeleteAccessMapImage: func,
  onAddClick: func,
  onDelClick: func,
};

const validateAccessMap = (value) => {
  return Validator.requiredCustomMessage(
    '画像をアップロードしてください',
    value
  );
};

export default HorizontalFormAccessComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormGroupRow from '../atoms/FormGroupRow';
import FormLabel from '../atoms/FormLabel';

class HorizontalTextComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      text: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    console.debug('HorizontalTextComponent', this.props);
  }

  render() {
    return (
      <FormGroupRow>
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </FormLabel>
        <div className="col-sm-6">
          {this.props.text}
        </div>
      </FormGroupRow>
    );
  }
}

export default HorizontalTextComponent;

import { array, bool, func, shape, string } from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';

import LabelErrorComponent from '../../molecules/LabelErrorComponent';
import ModifyButton from '../../molecules/ModifyButton';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import ReferAccess from '../../organisms/base/ReferAccess';
import Card from '../../organisms/Card';
import routePath from '../../pages/venueManagement/routePath';

class Refer extends React.Component {
  static get propTypes() {
    return {
      history: shape({
        push: func,
      }),
      id: string,
      isLoading: bool,
      state: shape({
        venueRefer: shape({ accessList: array }),
      }),
      result: shape({
        name: string,
        areaName: string,
        baseName: string,
        googleMapShortUrl: string,
        zipCode: string,
        prefName: string,
        city: string,
        buildingName: string,
        phoneNumber: string,
        mailAddress: string,
        nearestStation: string,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
  }

  transitionToSearch() {
    this.props.history.push(routePath.search);
  }

  transitionToEdit() {
    this.props.history.push(routePath.edit(this.props.id));
  }

  render() {
    const getAccessList = () => {
      console.debug('getAccessList', this.props.state);
      if (
        this.props.state &&
        this.props.state.venueRefer &&
        this.props.state.venueRefer.accessList
      ) {
        console.debug('getAccessList', this.props.state.venueRefer.accessList);
        return this.props.state.venueRefer.accessList;
      }

      return [];
    };

    return (
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <Card headerTitle="会場管理 参照">
            {this.props.state.isLoading ? (
              <></>
            ) : (
              <>
                {this.props.result.name == null ? (
                  <LabelErrorComponent
                    title="エラー"
                    directly="ErrorMessage."
                  />
                ) : (
                  <>
                    <ReferItem label="会場名">
                      {this.props.result.name}
                    </ReferItem>
                    <ReferItem label="エリア">
                      {this.props.result.areaName}
                    </ReferItem>
                    <ReferItem label="拠点">
                      {this.props.result.baseName}
                    </ReferItem>
                    <h4>住所</h4>
                    <hr />
                    <ReferItem label="郵便番号">
                      {this.props.result.zipCode}
                    </ReferItem>
                    <ReferItem label="都道府県">
                      {this.props.result.prefName}
                    </ReferItem>
                    <ReferItem label="市区町村">
                      {this.props.result.city}
                    </ReferItem>
                    <ReferItem label="建物名">
                      {this.props.result.buildingName}
                    </ReferItem>
                    <ReferItem label="連絡先電話番号">
                      {this.props.result.phoneNumber}
                    </ReferItem>
                    <ReferItem label="連絡先メールアドレス">
                      {this.props.result.mailAddress}
                    </ReferItem>

                    <h4>地図</h4>
                    <hr />
                    <ReferItem label="通常URL">
                      <a
                        href={this.props.result.googleMapUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.props.result.googleMapUrl}
                      </a>
                    </ReferItem>
                    <ReferItem label="短縮URL">
                      <a
                        href={this.props.result.googleMapShortUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {this.props.result.googleMapShortUrl}
                      </a>
                    </ReferItem>
                    <ReferAccess accessList={getAccessList()} />
                    <ReferItem label="最寄駅">
                      {this.props.result.nearestStation}
                    </ReferItem>
                  </>
                )}
                <hr />
                <Row>
                  <div className="mx-auto">
                    <PrevButton onClick={this.transitionToSearch} />
                    {this.props.result.name == null ? (
                      <></>
                    ) : (
                      <ModifyButton onClick={this.transitionToEdit} />
                    )}
                  </div>
                </Row>
              </>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export default Refer;

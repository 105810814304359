import FormInput from '../../atoms/FormInput';
import FormLabel from '../../atoms/FormLabel';
import * as Validator from '../../utils/Validate';

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Field } from 'redux-form';
import FormGroupRow from "../../atoms/FormGroupRow";

class NameInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    return (
      <>
        <FormInput {...field} />
        {field.meta.touched && field.meta.error && (
          <Form.Control.Feedback type="invalid">
            {field.meta.error}
          </Form.Control.Feedback>
        )}
      </>
    );
  }

  render() {
    return (
      <FormGroupRow>
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          <span className="text-danger">*</span>
        </FormLabel>
        <div className="col-sm-6">
          <Row>
            <Col xs={6}>
              <Field
                component={this.component}
                name="familyName"
                type="input"
                validate={Validator.required}
              />
            </Col>
            <Col xs={6}>
              <Field
                component={this.component}
                name="firstName"
                type="input"
                validate={Validator.required}
              />
            </Col>
          </Row>
        </div>
      </FormGroupRow>
    );
  }
}

export default NameInputComponent;

import PropTypes from 'prop-types';
import React from 'react';

class ColAuto extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      className: PropTypes.string,
    };
  }

  render() {
    return (
      <div className={'col-auto' + ` ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default ColAuto;

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Card from '../../organisms/Card';
import ConfirmForm from '../../organisms/events/EventsEditConfirmForm';
import EditForm from '../../organisms/events/EventsEditForm';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';

import routePath from './routePath';

class EventsEditState {
  constructor(values = {}, chairmanId = null, chairmanName = null) {
    /**
     * @property {Object} values
     * @property {string} chairmanId
     * @property {string} chairmanName
     */
    this.values = values;
    this.chairmanId = chairmanId;
    this.chairmanName = chairmanName;
  }

  static fromState(state) {
    return new EventsEditState(
      state.values,
      state.chairmanId,
      state.chairmanName
    );
  }
}

class EventsEdit extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        eventsSearchParams: PropTypes.object,
        eventsSearchValues: PropTypes.object,
        eventsEdit: PropTypes.shape({
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
          generalErrorMessages: PropTypes.array,
        }),
      }),
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      fetchEvent: PropTypes.func,
      updateEvents: PropTypes.func,
      initState: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      setEditValues: PropTypes.func,
      fetchEventForEdit: PropTypes.func,
      backToInput: PropTypes.func,
      initEventDetail: PropTypes.func,
      searchEvents: PropTypes.func,
      initEditState: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.confirm = this.confirm.bind(this);
    this.transitionToRefer = this.transitionToRefer.bind(this);
    this.submit = this.submit.bind(this);
    this.backToInput = this.backToInput.bind(this);
    this.setChairman = this.setChairman.bind(this);
    this.state = new EventsEditState();
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.initEventDetail();
    if (this.id != null) {
      this.props.fetchEventForEdit(this.id);
    } else {
      this.props.fetchEventForEdit();
    }
  }

  confirm(values) {
    const value = {
      ...values,
      id: this.id,
    };

    const state = EventsEditState.fromState(this.state);
    state.values = value;
    this.setState(state);
    this.props.setEditValues(value);
  }

  setChairman(id, name) {
    const state = EventsEditState.fromState(this.state);
    state.chairmanId = id;
    state.chairmanName = name;
    this.setState(state);
  }

  submit() {
    const eventsEditState = EventsEditState.fromState(this.state);
    this.props.updateEvents(eventsEditState.values);
  }

  backToInput() {
    this.props.backToInput();
  }

  transitionToRefer() {
    const searchParams = this.props.state.eventsSearchParams;
    const values = this.props.state.eventsSearchValues;
    this.props.initEditState();
    if (Object.keys(searchParams).length > 0) {
      this.props.searchEvents(values, searchParams);
    }

    if (this.id) {
      this.props.history.push(routePath.refer(this.id));
    } else {
      this.props.history.push(routePath.search);
    }
  }

  render() {
    const isNew = () => {
      return !this.id;
    };
    const isConfirm = () => {
      return this.props.state.eventsEdit.isConfirm;
    };

    const getChairmanName = () => {
      return this.state.chairmanName;
    };

    const getChairmanId = () => {
      return this.state.chairmanId;
    };
    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>イベント管理</h1>
          </div>

          <div className="section-body">
            {!this.props.state.isLoading ? (
              <Row>
                <Col>
                  <Card headerTitle="">
                    {this.props.state.eventsEdit.generalErrorMessages != null &&
                    this.props.state.eventsEdit.generalErrorMessages.length >
                      0 ? (
                      <div className="alert alert-danger">
                        {this.props.state.eventsEdit.generalErrorMessages.map(
                          (message, i) => (
                            <p key={i}>{message}</p>
                          )
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {isConfirm() ? (
                      <ConfirmForm
                        onSubmit={this.submit}
                        backToInput={this.backToInput}
                        values={this.state.values}
                        isNew={isNew()}
                      />
                    ) : (
                      <EditForm
                        onSubmit={this.confirm}
                        onPrevButtonClick={this.transitionToRefer}
                        setChairman={this.setChairman}
                        chairmanId={getChairmanId()}
                        chairmanName={getChairmanName()}
                        isNew={isNew()}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
          <RegisterCompleteModal
            show={this.props.state.eventsEdit.isComplete}
            onCloseClick={this.transitionToRefer}
          />
        </section>
      </>
    );
  }
}

export default EventsEdit;

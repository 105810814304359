import PropTypes from 'prop-types';
import React from 'react';

import FormFieldArrayForm from "../../organisms/formManagement/FormFieldArrayForm";
import SelectFormTemplateModal from "../../organisms/formManagement/SelectFormTemplateModal";
import LabelErrorComponent from "../../molecules/LabelErrorComponent";

class Edit extends React.Component {
  static get propTypes() {
    return {
      editId: PropTypes.string,
      prevFunction: PropTypes.func,
      edit: PropTypes.func,
      setFormTemplate: PropTypes.func,
      referResult: PropTypes.object,
      error: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.clickSubmit = this.clickSubmit.bind(this);
    this.testSubmit = this.testSubmit.bind(this);
    this.closeTemplateModal = this.closeTemplateModal.bind(this);

    this.state = {
      showTemplateModal: true,
    };
  }

  closeTemplateModal(values) {
    this.props.setFormTemplate(values);
    this.setState({
      showTemplateModal: false
    });
  }

  /** 送信 */
  clickSubmit(value) {
    this.props.edit(value);
  }

  testSubmit(values) {
    this.props.edit(values);
    return false;
  }

  render() {
    return (
      <>
        <div>
          {this.props.error != null && <LabelErrorComponent title={"エラーが発生しました。"} />}
          <FormFieldArrayForm
            editId={this.props.editId}
            onSubmit={this.testSubmit}
            referResult={this.props.referResult}
            transitionToSearch={this.props.transitionToSearch}
          />
        </div>
        <SelectFormTemplateModal
          show={(this.props.referResult.sections == null)&& this.state.showTemplateModal}
          onClickClose={this.closeTemplateModal}
          transitionToSearch={this.props.transitionToSearch}
        />
      </>
    );
  }
}

export default Edit;

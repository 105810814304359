import PropTypes from 'prop-types';
import React from 'react';

import { wrap } from '../utils/PermissionComponent';

class PrimaryIconButton extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  static get propTypes() {
    return {
      children: PropTypes.any,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      isEnable: PropTypes.func,
    };
  }

  onClick() {
    if (this.props.disabled) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const disable = () => {
      let disable = false;
      if (Object.hasOwnProperty.call(this.props, 'disabled')) {
        disable = this.props.disabled;
      }
      return disable || !this.props.isEnable();
    };

    return (
      <button
        className={
          'btn btn-icon icon-left btn-primary mr-1' +
          (this.props.disabled ? ' disabled' : '')
        }
        type="submit"
        onClick={this.onClick}
        disabled={disable()}
      >
        {this.props.children}
      </button>
    );
  }
}

export default wrap(PrimaryIconButton);

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import {
  makeFetchAreas,
  makeFetchHallsByAreaId,
  makeFetchCategories,
  makeFetchTags,
  makeFetchEventTypes,
  setFormStartDateValue,
  setFormEndDateValue,
  clearHallsOptions,
} from '../../../actions/Events';
import PublishPeriodDateTimeInputComponent from '../../molecules/events/PublishPeriodDateTimeInputComponent';
import FormInlineCheckBoxes from '../../molecules/FormInlineCheckBoxesComponent';
import FormInlineRadioComponent from '../../molecules/FormInlineRadioComponentForForm';
import FormPeriodDateTimeInputComponent from '../../molecules/FormPeriodDateTimeInputComponent';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';

class EventsSearchForm extends React.Component {
  static get propTypes() {
    return {
      formState: PropTypes.object,
      options: PropTypes.shape({
        areas: PropTypes.array,
        halls: PropTypes.array,
        categories: PropTypes.array,
        tags: PropTypes.array,
        eventTypes: PropTypes.array,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),

      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      fetchAreas: PropTypes.func,
      fetchHalls: PropTypes.func,
      fetchTags: PropTypes.func,
      initState: PropTypes.func,
      fetchCategories: PropTypes.func,

      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        eventsSearchParams: PropTypes.shape({
          startDate: PropTypes.string,
        }),
        searchResults: PropTypes.shape({
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
        }),
      }),
      setFormStartDateValue: PropTypes.func,
      setFormEndDateValue: PropTypes.func,
      fetchHallsByAreaId: PropTypes.func,
      clearHallsOptions: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    if (e.target.value && e.target.value.length > 0) {
      this.props.fetchHallsByAreaId(e.target.value);
    } else {
      this.props.clearHallsOptions();
    }
  }

  onChangeStartDate(startDate) {
    this.props.setFormStartDateValue(startDate);
    this.setState({ startDate: startDate });
  }

  onChangeEndDate(endDate) {
    this.setState({ endDate: endDate });
    this.props.setFormEndDateValue(endDate);
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent
            label="イベント名"
            name="name"
            component="input"
            type="text"
          />
          <FormPeriodDateTimeInputComponent
            startDateName="periodFromDate"
            startHourName="periodFromHour"
            startMinuteName="periodFromMinute"
            endDateName="periodToDate"
            endHourName="periodToHour"
            endMinuteName="periodToMinute"
            onChangeStartDateTime={this.onChangeStartDate.bind(this)}
            onChangeEndDateTime={this.onChangeEndDate.bind(this)}
            label="開催期間"
          />
          <FormInlineRadioComponent
            label="公開日付"
            options={[
              { name: '指定なし', value: '', default: true },
              { name: '公開前', value: '1', default: false },
              { name: '公開中', value: '2', default: false },
              { name: '公開終了', value: '3', default: false },
              { name: '期間指定', value: '4', default: false },
            ]}
            name="publishType"
          />
          <PublishPeriodDateTimeInputComponent
            label="公開期間"
            isDisable={
              ((this.props.formState || {}).values || {}).publishType !== '4'
            }
          />
          <HorizontalSelectComponent
            label="エリア"
            name="areaId"
            options={this.props.options.areas}
            onChange={this.onChange}
          />
          <HorizontalSelectComponent
            label="会場"
            name="venueId"
            options={this.props.options.halls}
          />
          <HorizontalFormInputComponent
            label="講師"
            name="chairmanName"
            component="input"
            type="text"
          />
          <HorizontalFormInputComponent
            label="登録者"
            name="registerName"
            component="input"
            type="text"
          />
          <FormInlineCheckBoxes
            label="種別"
            name="kindId"
            options={this.props.options.eventTypes}
          />
          <HorizontalSelectComponent
            label="カテゴリー"
            name="categoryId"
            options={this.props.options.categories}
          />
          <FormInlineCheckBoxes
            label="タグ"
            name="tagId"
            options={this.props.options.tags}
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const eventsSearchFrom = reduxForm({
  // a unique name for the form
  form: 'eventsSearchFrom',
  enableReinitialize: true,
})(EventsSearchForm);

export default connect(
  (state) => {
    return {
      formState: state.form.eventsSearchFrom,
      options: state.options,
      initialValues: {
        tagId: [],
        kindId: [],
        publishType: '',
      },
      state: state.events,
    };
  },
  (dispatch) => {
    return {
      fetchAreas() {
        dispatch(makeFetchAreas());
      },
      fetchEventTypes() {
        dispatch(makeFetchEventTypes());
      },
      fetchHallsByAreaId(v) {
        dispatch(makeFetchHallsByAreaId(v));
      },
      fetchCategories() {
        dispatch(makeFetchCategories());
      },
      fetchTags() {
        dispatch(makeFetchTags());
      },
      setFormStartDateValue(date) {
        dispatch(setFormStartDateValue(date));
      },
      setFormEndDateValue(date) {
        dispatch(setFormEndDateValue(date));
      },
      clearHallsOptions() {
        dispatch(clearHallsOptions());
      },
    };
  }
)(eventsSearchFrom);

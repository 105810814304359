import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ModifyButton from '../../molecules/ModifyButton';
import PrevButton from '../../molecules/PrevButton';
import Card from '../../organisms/Card';

class SampleRefer extends React.Component {
  constructor(props) {
    super(props);

    this.transitionToEditPage = this.transitionToEditPage.bind(this);
    this.transitionToSearchPage = this.transitionToSearchPage.bind(this);
    this.state = {
      id: '',
    };
  }

  static get propTypes() {
    return {
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
      referData: PropTypes.func,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        data: PropTypes.shape({
          name: PropTypes.string,
          age: PropTypes.number,
          prefectureId: PropTypes.number,
        }),
        prefectures: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.name,
          })
        ),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      fetchPrefectures: PropTypes.func,
    };
  }

  transitionToEditPage() {
    this.props.history.push(`/sample/edit/${this.state.id}`);
  }

  transitionToSearchPage() {
    this.props.history.push(`/sample/`);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ id: id });
    console.log(id);
    this.props.referData(id);
    this.props.fetchPrefectures();
  }

  render() {
    const getPrefectureName = (prefectureId) => {
      const prefectures = this.props.state.prefectures
        .filter((prefecture) => prefecture.id === prefectureId)
        .slice(0, 1);
      if (prefectures.length === 0) {
        return '';
      } else {
        return prefectures[0].name;
      }
    };

    return (
      <section className="section">
        <div className="section-header">
          <h1>サンプルページ</h1>
        </div>

        <div className="section-body">
          <div>
            {this.props.state.isLoading ? (
              <p>Loading</p>
            ) : (
              <Row>
                <Col>
                  <Card headerTitle="サンプル参照ページ">
                    <p>{this.props.state.data.name}</p>
                    <p>{this.props.state.data.age}</p>
                    <p>
                      {getPrefectureName(this.props.state.data.prefectureId)}
                    </p>
                    <PrevButton onClick={this.transitionToSearchPage} />
                    <ModifyButton onClick={this.transitionToEditPage} />
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default SampleRefer;

import { array, bool, func, object, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Card from '../../organisms/Card';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import Form from '../../organisms/venueManagement/VenueEditForm';
import ConfirmForm from '../../organisms/venueManagement/VeuneEditConfirmForm';
import routePath from '../../pages/venueManagement/routePath';

class Edit extends React.Component {
  static get propTypes() {
    return {
      history: shape({
        push: func,
      }),
      state: shape({
        isLoading: bool,
      }),
      id: string,
      isComplete: bool,
      isLoading: bool,
      options: array,
      resultData: object,
      storeValues: object,
      submitFunction: func,
      transitionToRefer: func,
      onCloseCompleteModal: func,
      backToEdit: func,
      isConfirm: bool,
      errorMessages: object,
    };
  }

  constructor(props) {
    super(props);

    this.transitionToEdit = this.transitionToEdit.bind(this);
    this.backToInput = this.backToInput.bind(this);

    this.confirm = this.confirm.bind(this);
    this.submit = this.submit.bind(this);

    this.state = { isConfirm: false };
  }

  backToInput() {
    this.setState({ isConfirm: false });
    this.props.backToEdit();
  }

  transitionToEdit() {
    this.props.history.push(routePath.edit(this.props.id));
  }

  confirm(values) {
    this.setState({ values: values, isConfirm: true });
    this.props.storeValues(values);
  }

  submit() {
    console.debug('submit values: ', this.state.values);
    this.props.submitFunction(this.state.values);
  }

  render() {
    const isConfirm = () => {
      return this.props.isConfirm;
    };

    return (
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <Card headerTitle="編集">
            {this.props.state.isLoading ? (
              <></>
            ) : isConfirm() ? (
              <ConfirmForm
                onSubmit={this.submit}
                backToInput={this.backToInput}
                values={this.state.values}
              />
            ) : (
              <Form
                onSubmit={this.confirm}
                onPrevButtonClick={this.props.transitionToRefer}
                id={this.props.id}
                options={this.props.options}
                errorMessages={this.props.errorMessages}
              />
            )}
          </Card>

          <RegisterCompleteModal
            show={this.props.isComplete}
            onCloseClick={this.props.onCloseCompleteModal}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    state: state.venue,
  };
})(Edit);

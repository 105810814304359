const TypeName = {
  Seminar: 'セミナー',
  Session: '説明会',
  OneDay: 'ONEDAY',
};

export default class EventType {
  /**
   *
   * @param {string} eventTypeName
   */
  constructor(eventTypeName) {
    console.assert(typeof eventTypeName === 'string');

    /**
     * @property {string} name
     */
    this.name = eventTypeName;
  }

  isSeminar() {
    return this.name !== TypeName.Session;
  }

  isSession() {
    return this.name === TypeName.Session;
  }

  /**
   *
   * @returns {string}
   */
  get displayName() {
    if (this.name === TypeName.OneDay) {
      return TypeName.Seminar;
    }
    return this.name;
  }
}

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { makeFetchCompanyNames, makeFetchPrefs } from '../../../actions/User';
import FormInlineCheckBoxesComponent from '../../molecules/FormInlineCheckBoxesComponent';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';
import FormFromtoComponent from '../../molecules/user/FormFromtoComponent';

class UserSearchLeadForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      fetchPrefs: PropTypes.func,
      fetchCompanyNames: PropTypes.func,
      fetchCurrentStatuses: PropTypes.func,
      fetchCollegeRegistrationCategories: PropTypes.func,
      options: PropTypes.shape({
        prefs: PropTypes.array,
        companyNames: PropTypes.array,
      }),
    };
  }

  componentDidMount() {
    this.props.fetchPrefs();
    this.props.fetchCompanyNames();
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent label="SFID(リード)" name="leadSfId" />
          <HorizontalFormInputComponent
            label="メールアドレス"
            name="mailAddress"
          />
          <HorizontalFormInputComponent
            label="名前(フリガナ)"
            name="nameKana"
          />
          <HorizontalFormInputComponent
            label="LINEニックネーム"
            name="lineNickName"
          />
          <HorizontalSelectComponent
            label="企業名"
            name="company"
            options={this.props.options.companyNames}
          />
          <FormInlineCheckBoxesComponent
            label="年齢"
            options={[
              { name: '10代', value: '1' },
              { name: '20代', value: '2' },
              { name: '30代', value: '3' },
            ]}
            name="age"
          />
          <FormFromtoComponent
            label="流入日期間"
            fromId="registerFromDateYmd"
            toId="registerToDateYmd"
          />

          <FormInlineCheckBoxesComponent
            label="性別"
            name="sex"
            options={[
              { name: '男性', value: '1' },
              { name: '女性', value: '2' },
              { name: '未設定', value: '0' },
            ]}
          />
          <HorizontalSelectComponent
            label="都道府県"
            name="pref"
            options={this.props.options.prefs}
          />
          <HorizontalFormInputComponent label="携帯番号" name="phoneNumber" />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
const userSearchLeadForm = reduxForm({
  // a unique name for the form
  form: 'userSearchLeadForm',
  enableReinitialize: true,
})(UserSearchLeadForm);

export default connect(
  (state) => {
    return {
      options: state.options,
      state: state.user,
      initialValues: {
        age: [],
        sex: [],
      },
    };
  },
  (dispatch) => {
    return {
      fetchPrefs() {
        dispatch(makeFetchPrefs());
      },
      fetchCompanyNames() {
        dispatch(makeFetchCompanyNames());
      },
    };
  }
)(userSearchLeadForm);

import { connect } from 'react-redux';

import {
  makeSearchData,
  makeFetchArea,
  makeDeleteData,
  hideDeleteSuccessModal,
  makeVenueUsed,
  hideContainsUsedAlertModal,
  hideDeleteConfirmModal,
} from '../../actions/Venue';
import VenueManagementSearchComponent from '../../components/pages/venueManagement/Search';

const mapStateToProps = (state) => {
  return {
    state: state.venue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchData: (condition, searchParams) => {
      dispatch(makeSearchData(condition, searchParams));
    },
    fetchArea: () => {
      dispatch(makeFetchArea());
    },
    deleteData: (values) => {
      dispatch(makeDeleteData(values));
    },
    hideDeleteSuccessModal() {
      dispatch(hideDeleteSuccessModal());
    },
    venueUsed(ids) {
      dispatch(makeVenueUsed(ids));
    },
    hideContainsUsedAlertModal() {
      dispatch(hideContainsUsedAlertModal());
    },
    hideDeleteConfirmModal() {
      dispatch(hideDeleteConfirmModal());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenueManagementSearchComponent);

import axios from 'axios';

const addTodo = (todo) => {
  return {
    type: 'ADD_TODO',
    payload: { todo: todo },
  };
};

const setAge = (age) => {
  return {
    type: 'SET_AGE',
    payload: { age: age },
  };
};

const setPrefectureId = (prefectureId) => {
  return {
    type: 'SET_PREFECTURE_ID',
    payload: { prefectureId: prefectureId },
  };
};

const searchData = (results) => {
  return {
    type: 'SEARCH_DATA',
    payload: { results: results },
  };
};

const referData = (result) => {
  return {
    type: 'REFER_DATA',
    payload: { result: result },
  };
};

const fetchErrorData = (error) => {
  console.log('error:', error);
  return {
    type: 'FETCH_ERROR',
    payload: { result: error },
  };
};

const saveSuccess = (result) => {
  return {
    type: 'SAVE_SUCCESS',
    payload: { result: result },
  };
};

const saveFailure = (result) => {
  return {
    type: 'SAVE_FAILURE',
    payload: { result: result },
  };
};

const fetchPrefecturesSuccess = (result) => {
  return {
    type: 'FETCH_PREFECTURES',
    payload: { result: result },
  };
};

const transitionRefer = (history, id) => {
  history.push(`/sample/refer/${id}`);
  return {
    type: 'TRANSITION_REFER',
    payload: {},
  };
};

const dispatchPostSave = () => {
  return {
    type: 'POST_SAVE',
    payload: {},
  };
};

function fetchSearchData(condition, searchParams) {
  const params = {
    name: condition.name,
    prefectureId: condition.prefectureId,
    page: searchParams.pagination.page,
    pageSize: searchParams.pagination.pageSize,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  };

  const removedParams = Object.assign({}, params);

  for (const key in removedParams) {
    if (removedParams[key] == null || removedParams[key] === '') {
      delete removedParams[key];
    }
  }

  return axios.get(`/sample/users`, {
    params: removedParams,
  });
}

function fetchReferData(id) {
  return axios.get(`/sample/users/${id}`);
}

function postSave(data) {
  return axios.put(`/sample/users/${data.id}`, {
    name: data.name,
    age: parseInt(data.age, 10),
    prefectureId: parseInt(data.prefectureId, 10),
  });
}

function fetchPrefectures() {
  return axios.get('/prefectures');
}

function makeSearchData(condition, searchParams) {
  return function (dispatch) {
    return fetchSearchData(condition, searchParams)
      .then((data) => dispatch(searchData(data.data)))
      .catch((_) => dispatch(searchData([])));
  };
}

function makeReferData(id) {
  return function (dispatch) {
    return fetchReferData(id)
      .then((data) => dispatch(referData(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
}

function makeFetchPrefectures() {
  return function (dispatch) {
    return fetchPrefectures()
      .then((data) => dispatch(fetchPrefecturesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
}

function save(data) {
  return function (dispatch) {
    dispatch(dispatchPostSave());
    return postSave(data)
      .then((data) => dispatch(saveSuccess(data.data)))
      .catch((err) => dispatch(saveFailure(err)));
  };
}

export { addTodo };
export { searchData };
export { makeSearchData };
export { makeReferData };
export { setAge };
export { setPrefectureId };
export { save };
export { transitionRefer };
export { makeFetchPrefectures };

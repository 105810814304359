import { push } from 'connected-react-router';

import { EmptyAction } from '../components/model/Action';
import loginRoutePath from '../components/pages/login/routePath';

import createAxios from './axios';

const logoutAxios = createAxios('/admin/logout');
const navbarAxios = createAxios('/navbar');

const logoutApi = () => {
  return logoutAxios.post('/admin/auth/logout', {});
};

const logoutApiSuccess = () => {
  return push(loginRoutePath.login);
};

const fetchErrorData = (_) => {
  return push(loginRoutePath.login);
};

const fetchLogoutError = (err) => {
  if (err.response && err.response.status === 500) {
    return new EmptyAction('authentication/FETCH_LOGOUT_ERROR');
  }

  return fetchErrorData();
};

const logout = () => {
  return (dispatch) => {
    logoutApi()
      .then((_) => {
        dispatch(logoutApiSuccess());
      })
      .catch((err) => dispatch(fetchLogoutError(err)));
  };
};

const fetchUserProfile = () => {
  return navbarAxios.get('/admin/profile');
};

const fetchProfileSuccess = (data) => {
  return {
    type: 'common/FETCH_PROFILE',
    payload: {
      profileId: data.id,
      familyName: data.familyName,
      firstName: data.firstName,
      loginMenuPath: data.loginMenuPath,
    },
  };
};

const makeFetchProfile = () => {
  return (dispatch) => {
    return fetchUserProfile()
      .then((res) => dispatch(fetchProfileSuccess(res.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

export { logout as makeFetchLogout, makeFetchProfile };

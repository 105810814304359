import PropTypes from 'prop-types';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import { FormControl } from 'react-bootstrap';
import styled from 'styled-components';

export class Param {
  constructor(
    suggestions,
    fetchRequested,
    clearSuggestion,
    getSuggestionValue,
    renderSuggestion,
    inputProps,
    onSelected
  ) {
    this.suggestions = suggestions;
    this.fetchRequested = fetchRequested;
    this.clearSuggestion = clearSuggestion;
    this.getSuggestionValue = getSuggestionValue;
    this.renderSuggestion = renderSuggestion;
    this.inputProps = inputProps;
    this.onSelected = onSelected;
  }

  toObject() {
    return {
      suggestions: this.suggestions,
      fetchRequested: this.fetchRequested,
      clearSuggestion: this.clearSuggestion,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
      inputProps: this.inputProps,
      onSelected: this.onSelected,
    };
  }
}

class Suggest extends React.Component {
  static get propTypes() {
    return {
      fetchRequested: PropTypes.func,
      clearSuggestion: PropTypes.func,
      onSelected: PropTypes.func,
      suggestions: PropTypes.array,
      getSuggestionValue: PropTypes.func,
      renderSuggestion: PropTypes.func,
      inputProps: PropTypes.object,
    };
  }

  render() {
    const onSuggestionsFetchRequested = ({ value }) => {
      this.props.fetchRequested(value);
    };

    const onSuggestionsClearRequested = () => {
      this.props.clearSuggestion();
    };

    const onSuggestionSelected = (event, { suggestion, ...other }) => {
      console.debug('suggest', other);
      console.debug('suggest', suggestion.value);

      if (this.props.onSelected) {
        this.props.onSelected(suggestion);
      }
    };

    const renderSuggestionsContainer = ({ containerProps, children }) => {
      const NoPaddingUl = styled.ul`
        margin-left: -40px;
      `;
      return (
        <NoPaddingUl {...containerProps} className="list-group">
          {children}
        </NoPaddingUl>
      );
    };

    const renderInputComponent = (inputProps) => {
      return <FormControl {...inputProps} />;
    };

    return (
      <Autosuggest
        suggestions={this.props.suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={this.props.getSuggestionValue}
        renderSuggestion={this.props.renderSuggestion}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={this.props.inputProps}
        onSuggestionSelected={onSuggestionSelected}
        focusInputOnSuggestionClick={true}
        theme={{
          sectionContainer: 'list-group',
          suggestion: 'list-group-item',
          suggestionHighlighted: 'list-group-item active',
        }}
      />
    );
  }
}

export default Suggest;

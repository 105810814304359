import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import constants from '../../../constants';
import PrimaryButton from '../../atoms/PrimaryButton';
import SalesForceButton from '../../atoms/SalesForceButton';
import WarningButton from '../../atoms/WarningButton';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import Card from '../../organisms/Card';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import DeactivateConfirmModal from '../../organisms/user/DeactivateConfirmModal';
import EditConfirmModal from '../../organisms/user/EditConfirmModal';
import EditModal from '../../organisms/user/EditModal';
import CareerInformation from '../../organisms/user/refer/CareerInformation';
import JobHuntingInformation from '../../organisms/user/refer/JobHuntingInformation';
import SelfInformation from '../../organisms/user/refer/SelfInformation';

import routePath from './routePath';

const BottomMargin = styled.div`
  margin-bottom: 10px;
`;

class UserRefer extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      fetchUser: PropTypes.func,
      state: PropTypes.shape({
        profile: PropTypes.shape({
          isAdvisor: PropTypes.bool,
        }),
        userEdit: PropTypes.shape({
          isEdit: PropTypes.bool,
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
          company: PropTypes.string,
          compnayOld: PropTypes.string,
        }),
        company: PropTypes.string,
        isLoading: PropTypes.bool,
        userRefer: PropTypes.shape({
          detail: PropTypes.shape({
            profileBaseInfo: PropTypes.shape({
              sfID: PropTypes.string,
              profileUrl: PropTypes.string,
              profileImgId: PropTypes.string,
              profileBaseId: PropTypes.string,
              profileBaseInfoId: PropTypes.string,
              profileManagementInfoId: PropTypes.string,
              firstName: PropTypes.string,
              familyName: PropTypes.string,
              firstNameKana: PropTypes.string,
              familyNameKana: PropTypes.string,
              mailAddress: PropTypes.string,
              mailAddressPc: PropTypes.string,
              phoneNumberMobile: PropTypes.string,
              phoneNumberHome: PropTypes.string,
              dateOfBirth: PropTypes.string,
              gender: PropTypes.string,
              statusId: PropTypes.string,
              coments: PropTypes.string,
              condition: PropTypes.string,
              isLine: PropTypes.string,
              isReceiveMail: PropTypes.string,
              isReceiveLine: PropTypes.string,
              profileContactTools: PropTypes.array,
              inviteCount: PropTypes.string,
              reserveCount: PropTypes.string,
              company: PropTypes.string,
              contactNg: PropTypes.string,
              deactivateAt: PropTypes.string,
              mailNg: PropTypes.string,
            }),
            profileCareerInfo: PropTypes.shape({
              // 出身地(都道府県)ID
              birthplacePrefId: PropTypes.string,
              // 出身地(都道府県)
              birthplacePref: PropTypes.string,
              // 希望勤務地ID
              desiredPrefId: PropTypes.string,
              // 希望勤務地
              desiredPref: PropTypes.string,
              // 就職を希望する時期
              desiredTime: PropTypes.string,
              // カウンセリング
              isRequestCounseling: PropTypes.string,
              // 希望業界Id
              desiredIndustryId: PropTypes.string,
              // 希望業界
              desiredIndustry: PropTypes.string,
              // 就活で悩んでいること
              worries: PropTypes.string,
              // 就職希望する企業の規模Id
              desiredCompanyScaleId: PropTypes.string,
              // 就職希望する企業の規模
              desiredCompanyScale: PropTypes.string,
              // 就職/転職の理由
              purpose: PropTypes.string,
              // 就活において相談したいこと
              consultationTopic: PropTypes.string,
              // 希望職種Id
              desiredJobCategoryId: PropTypes.string,
              // 希望職種
              desiredJobCategory: PropTypes.string,
              // 希望給与（月給）
              desiredSalary: PropTypes.string,
              // 知り合いの就職/転職希望の有無
              haveFriends: PropTypes.string,
              // 就職支援講座の参加希望
              willAttendSeminar: PropTypes.string,
              // U/Iターン希望の方
              // 現住所(都道府県)ID
              addressPrefId: PropTypes.string,
              // 現住所(都道府県)
              addressPref: PropTypes.string,
              // 現住所(市区町村)
              addressCity: PropTypes.string,
              // 現住所(番地)
              addressBlock: PropTypes.string,
              // 現住所(建物)
              addressBuildingName: PropTypes.string,
              // 最寄駅(線/駅)
              nearestStation: PropTypes.string,
              // 同居状況
              isWithFamily: PropTypes.string,
              // 転居の可・不可
              movingPossibility: PropTypes.string,
            }),
            profileWorkInfo: PropTypes.shape({
              // 最終学歴ID
              finalEducationId: PropTypes.string,
              // 最終学歴
              finalEducation: PropTypes.string,
              // 学歴詳細LIST
              profileEducationList: PropTypes.array,
              // 普通自動車免許ID
              haveDrivingLicenseId: PropTypes.string,
              // 普通自動車免許
              haveDrivingLicense: PropTypes.string,
              // 資格1
              qualification1: PropTypes.string,
              // 資格2
              qualification2: PropTypes.string,
              // 資格3
              qualification3: PropTypes.string,
              // 留学・海外滞在歴
              experienceAbroad: PropTypes.string,
              // 趣味・特技・サークル経験など
              skills: PropTypes.string,
              // 職務履歴 LIST
              profileWorkExperiencesList: PropTypes.array,
              // 受けた企業数
              applications: PropTypes.string,
              // 面接まで進んだ企業数
              interviews: PropTypes.string,
              // 内定数
              offers: PropTypes.string,
              // 先行中の企業 LIST
              wipList: PropTypes.array,
            }),
          }),
          isDisplayDeactivateCompleteModal: PropTypes.bool,
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      updateUser: PropTypes.func,
      showEdit: PropTypes.func,
      showEditClose: PropTypes.func,
      showEditConfirm: PropTypes.func,
      showEditConfirmClose: PropTypes.func,
      showEditCompleteClose: PropTypes.func,
      deactivate: PropTypes.func,
      hideDeactivateCompleteModal: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
    this.transitionToReserve = this.transitionToReserve.bind(this);
    this.deactivateUser = this.deactivateUser.bind(this);
    this.displayDeactivateConfirmModal = this.displayDeactivateConfirmModal.bind(
      this
    );
    this.hideDeactivateConfirmModal = this.hideDeactivateConfirmModal.bind(
      this
    );

    this.onEditClick = this.onEditClick.bind(this);
    this.state = {
      isEdit: false,
      isConfirm: false,
      isComplete: false,
      company: '',
      isDisplayDeactivateConfirmModal: false,
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.fetchUser(this.id);
  }

  transitionToSearch() {
    this.props.history.push(routePath.search);
  }

  transitionToEdit() {
    this.props.showEdit();
  }

  transitionToReserve() {
    const { params } = this.props.match;
    this.props.history.push(`/admin/reservation/${params.id}`);
  }

  onEditClick(values) {
    this.setState({ company: values.companyId });
    this.props.showEditConfirm();
  }

  onEditCloseClick() {
    this.props.showEditClose();
  }

  onConfirmYesClick() {
    const { params } = this.props.match;
    this.props.updateUser(this.state.company, params.id);
  }

  onConfirmNoClick() {
    this.props.showEditClose();
  }

  onCompleteCloseClick() {
    this.props.showEditCompleteClose();

    const { params } = this.props.match;
    this.id = params.id;
    this.props.fetchUser(this.id);
  }

  displayDeactivateConfirmModal() {
    this.setState({
      isDisplayDeactivateConfirmModal: true,
    });
  }

  hideDeactivateConfirmModal() {
    this.setState({
      isDisplayDeactivateConfirmModal: false,
    });
  }

  deactivateUser() {
    this.setState({
      isDisplayDeactivateConfirmModal: false,
    });

    this.props.deactivate(this.id);
  }

  onDeactivateCompleteModalCloseClick() {
    this.props.hideDeactivateCompleteModal(this.id);
  }

  render() {
    const getCompany = () => {
      if (this.props.state.userRefer.detail.profileBaseInfo) {
        if (this.props.state.userRefer.detail.profileBaseInfo.company) {
          return `${this.props.state.userRefer.detail.profileBaseInfo.company}`;
        }
      }

      return null;
    };

    const getContactNg = () => {
      if (this.props.state.userRefer.detail.profileBaseInfo) {
        if (this.props.state.userRefer.detail.profileBaseInfo.contactNg) {
          if (this.props.state.userRefer.detail.profileBaseInfo.contactNg) {
            return '接触NG';
          }
        }
      }

      return 'なし';
    };

    const getMailNg = () => {
      if (this.props.state.userRefer.detail.profileBaseInfo) {
        if (this.props.state.userRefer.detail.profileBaseInfo.mailNg) {
          if (this.props.state.userRefer.detail.profileBaseInfo.mailNg) {
            return 'メールNG';
          }
        }
      }

      return 'なし';
    };

    // 連絡手段
    const getContactTool = () => {
      let ret = '';
      const profileBaseInfo = this.props.state.userRefer.detail.profileBaseInfo;
      if (profileBaseInfo) {
        if (profileBaseInfo.profileContactTools) {
          if (profileBaseInfo.profileContactTools.length > 0) {
            for (
              var i = 0, len = profileBaseInfo.profileContactTools.length;
              i < len;
              ++i
            ) {
              if (i !== 0) {
                ret = ret + ',';
              }
              ret = ret + profileBaseInfo.profileContactTools[i].name;
            }
          }
        }
      }

      return ret;
    };

    // メール受信設定
    const getIsReceiveMail = () => {
      if (this.props.state.userRefer.detail.profileBaseInfo) {
        if (this.props.state.userRefer.detail.profileBaseInfo.isReceiveMail) {
          return '受け取る';
        } else {
          return '受け取らない';
        }
      }

      return null;
    };

    // LINE受信設定
    const getIsReceiveLine = () => {
      if (this.props.state.userRefer.detail.profileBaseInfo) {
        if (this.props.state.userRefer.detail.profileBaseInfo.isReceiveLine) {
          return '受け取る';
        } else {
          return '受け取らない';
        }
      }

      return null;
    };

    // 招待者数
    const getInviteCount = () => {
      if (this.props.state.userRefer.detail.profileBaseInfo) {
        if (this.props.state.userRefer.detail.profileBaseInfo.inviteCount) {
          return `${this.props.state.userRefer.detail.profileBaseInfo.inviteCount}`;
        }
      }

      return null;
    };

    // SFID
    const getSfID = () => {
      if (this.props.state.userRefer.detail.profileBaseInfo) {
        if (this.props.state.userRefer.detail.profileBaseInfo.sfID) {
          return `${this.props.state.userRefer.detail.profileBaseInfo.sfID}`;
        }
      }

      return null;
    };

    const onClickSalesForceButton = (e) => {
      e.preventDefault();
      window.open(`https://${constants.SFDomain}/${getSfID()}`, '_blank');
    };

    const companySettingButton = () => {
      if ((this.props.state.profile || {}).isAdvisor) {
        return (
          <PrimaryButton onClick={this.transitionToEdit}>
            企業名変更
          </PrimaryButton>
        );
      } else {
        return <></>;
      }
    };

    const isDeactivate = () => {
      if (this.props.state.userRefer.detail.profileBaseInfo) {
        return (
          this.props.state.userRefer.detail.profileBaseInfo.deactivateAt != null
        );
      }
      return false;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>会員管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="詳細">
                  {this.props.state.isLoading ? (
                    <></>
                  ) : (
                    <>
                      <Row>
                        <div className="mx-auto">
                          <PrimaryButton onClick={this.transitionToReserve}>
                            代理予約
                          </PrimaryButton>
                          {companySettingButton()}
                          <SalesForceButton onClick={onClickSalesForceButton} />
                          {isDeactivate() ? (
                            <></>
                          ) : (
                            <WarningButton
                              onClick={this.displayDeactivateConfirmModal}
                            >
                              退会
                            </WarningButton>
                          )}
                        </div>
                      </Row>

                      <hr />

                      <table className="table" border="1">
                        <thead>
                          <tr>
                            <th scope="col">接触NGフラグ: {getContactNg()}</th>
                            <th scope="col">
                              メール送信NGフラグ:{getMailNg()}
                            </th>
                          </tr>
                        </thead>
                      </table>

                      <ReferItem label="企業名">{getCompany()}</ReferItem>

                      <br />

                      <SelfInformation
                        userRefer={this.props.state.userRefer.detail}
                      />
                      <CareerInformation
                        userRefer={this.props.state.userRefer.detail}
                      />

                      <JobHuntingInformation
                        userRefer={this.props.state.userRefer.detail}
                      />

                      <br />

                      <h3>その他登録内容</h3>
                      <ReferItem label="連絡手段">{getContactTool()}</ReferItem>
                      <ReferItem label="メール受信設定">
                        {getIsReceiveMail()}
                      </ReferItem>
                      <ReferItem label="LINE受信設定">
                        {getIsReceiveLine()}
                      </ReferItem>
                      <ReferItem label="招待者数">
                        {getInviteCount()}人
                      </ReferItem>

                      <hr />

                      <Row>
                        <div className="mx-auto">
                          <BottomMargin>
                            <PrimaryButton onClick={this.transitionToReserve}>
                              代理予約
                            </PrimaryButton>
                            {companySettingButton}
                            <SalesForceButton
                              onClick={onClickSalesForceButton}
                            />
                            {isDeactivate() ? (
                              <></>
                            ) : (
                              <WarningButton
                                onClick={this.displayDeactivateConfirmModal}
                              >
                                退会
                              </WarningButton>
                            )}
                          </BottomMargin>
                        </div>
                      </Row>
                      <Row>
                        <div className="mx-auto">
                          <PrevButton onClick={this.transitionToSearch} />
                        </div>
                      </Row>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        {this.props.state.userEdit.isEdit ? (
          <EditModal
            show={this.props.state.userEdit.isEdit}
            onSubmit={this.onEditClick}
            onCloseClick={this.onEditCloseClick.bind(this)}
            companyName={getCompany()}
          />
        ) : (
          <></>
        )}

        <DeactivateConfirmModal
          show={this.state.isDisplayDeactivateConfirmModal}
          onYesClick={this.deactivateUser}
          onNoClick={this.hideDeactivateConfirmModal}
        />

        <RegisterCompleteModal
          show={this.props.state.userRefer.isDisplayDeactivateCompleteModal}
          text={'正常に退会処理が完了いたしました'}
          onCloseClick={this.onDeactivateCompleteModalCloseClick.bind(this)}
        />

        <EditConfirmModal
          show={this.props.state.userEdit.isConfirm}
          onYesClick={this.onConfirmYesClick.bind(this)}
          onNoClick={this.onConfirmNoClick.bind(this)}
        />

        <RegisterCompleteModal
          show={this.props.state.userEdit.isComplete}
          onCloseClick={this.onCompleteCloseClick.bind(this)}
        />
      </>
    );
  }
}

export default UserRefer;

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { makeSendAdminPasswordReset } from '../../../actions/AdminPasswordReset';
import SaveButton from '../../molecules/SaveButton';
import * as Validator from '../../utils/Validate';
import PasswordInputComponent from '../adminPasswordReset/PasswordInputComponent';

class AdminPasswordResetInputForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      sendAdminPasswordReset: PropTypes.func,
    };
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          新しいパスワードを入力してください。<br></br>
          パスワードは8文字以上で設定してください。
        </div>
        <div>
          <PasswordInputComponent
            label="パスワード"
            name="newPassword"
            component="input"
            type="password"
            validate={[Validator.required, Validator.password]}
          />
          <PasswordInputComponent
            label="パスワード(確認)"
            name="newPasswordConfirm"
            component="input"
            type="password"
            validate={[Validator.required, Validator.password]}
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SaveButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const adminPasswordResetInputForm = reduxForm({
  // a unique name for the form
  form: 'adminPasswordResetInputForm',
  enableReinitialize: true,
})(AdminPasswordResetInputForm);

export default connect(
  (state) => {
    return {
      formState: state.form.adminPasswordReset,
    };
  },
  (dispatch) => {
    return {
      sendAdminPasswordReset(values) {
        dispatch(makeSendAdminPasswordReset(values));
      },
    };
  }
)(adminPasswordResetInputForm);

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import FormGroupRow from '../atoms/FormGroupRow';
import FormLabel from '../atoms/FormLabel';
import FormNumber from '../atoms/FormNumberForForm';
import * as Validator from '../utils/Validate';

class FormNumberOfPeopleComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      errorMessage: PropTypes.array,
      name: PropTypes.string,
      defaultValue: PropTypes.string,
      validate: PropTypes.array,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    return (
      <FormGroupRow>
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          {this.isRequired() ? <span className="text-danger">*</span> : <></>}
        </FormLabel>
        <div className="col-6">
          <ReducedFomrNumber
            onChange={this.props.onChange}
            min="0"
            step="1"
            className={
              this.props.errorMessage != null &&
              this.props.errorMessage.length > 0
                ? 'is-invalid'
                : ''
            }
            field={field}
            defaultValue={this.props.defaultValue}
          />
          <LeftMarginSpan>人</LeftMarginSpan>
        </div>

        {this.props.errorMessage != null &&
        this.props.errorMessage.length > 0 ? (
          <div className="invalid-feedback">
            <ul>
              {this.props.errorMessage.map((errorMessage, i) => {
                return <li key={i}>{errorMessage}</li>;
              })}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </FormGroupRow>
    );
  }

  render() {
    return <Field component={this.component} name={this.props.name} />;
  }
}

const ReducedFomrNumber = styled(FormNumber)`
  width: 5em;
  display: inline-block;
`;

const LeftMarginSpan = styled.span`
  margin-left: 0.5em;
`;

export default FormNumberOfPeopleComponent;

import PropTypes from 'prop-types';
import React from 'react';

import LightButton from '../atoms/LightButton';

class CancelButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
    };
  }

  render() {
    return <LightButton onClick={this.props.onClick}>キャンセル</LightButton>;
  }
}

export default CancelButton;

import React from 'react';

class Dashboard extends React.Component {
  render() {
    return (
      <section className="section">
        <div className="section-header">
          <h1>Dashboard</h1>
        </div>

        <div className="section-body"></div>
      </section>
    );
  }
}

export default Dashboard;

import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from '../atoms/NormalButton';
import { RegisterAction } from '../utils/PermissionComponent';

class BulkRegisterButton extends React.Component {
  static get propTypes() {
    return {
      message: PropTypes.string,
      buttonHiddenFlg: PropTypes.bool,
      onClick: PropTypes.func,
      isEnabled: PropTypes.func,
      children: PropTypes.node,
    };
  }

  render() {
    return (
      <RegisterAction>
        <NormalButton
          color="primary"
          onClick={this.props.onClick}
          hiddenFlg={this.props.buttonHiddenFlg}
          isEnabled={this.props.isEnabled}
        >
          {this.props.children ? this.props.children : '一括登録'}
        </NormalButton>
      </RegisterAction>
    );
  }
}

export default BulkRegisterButton;

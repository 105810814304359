import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import BulkRegisterButton from '../../molecules/BulkRegisterButton';
import DeleteButton from '../../molecules/DeleteButton';
import DetailButton from '../../molecules/DetailButton';
import NewButton from '../../molecules/NewButton';
import Card from '../../organisms/Card';
import DeleteAlertModal from '../../organisms/events/DeleteAlertModal';
import DeleteCompleteModal from '../../organisms/events/DeleteCompleteModal';
import DeleteConfirmModal from '../../organisms/events/DeleteConfirmModal';
import SearchForm from '../../organisms/events/EventsSearchForm';
import Pager from '../../organisms/Pager';
import SearchResultTable from '../../organisms/SearchResultTable';
import SearchComponent from '../common/SearchComponent';

import routePath from './routePath';

class EventsSearchState {
  /**
   *
   * @param {Object} values
   * @param {Array.<number>} deleteValues
   */
  constructor(values = {}, deleteValues = []) {
    this.values = values;
    /**
     *
     * @type {Array.<number>} deleteValues
     */
    this.deleteValues = deleteValues;
  }

  static fromState(state) {
    return new EventsSearchState(state.values, state.deleteValues);
  }
}

class EventsSearch extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      initState: PropTypes.func,
      searchEvents: PropTypes.func,
      searchEventCheck: PropTypes.func,
      deleteEvents: PropTypes.func,
      hideDeleteAlertModal: PropTypes.func,
      hideDeleteConfirmModal: PropTypes.func,
      hideDeleteCompleteModal: PropTypes.func,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        eventsSearchValues: PropTypes.object,
        eventsSearchResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          events: PropTypes.array,
        }),
        eventsDelete: PropTypes.shape({
          isDeleteAlert: PropTypes.bool,
          isDeleteConfirm: PropTypes.bool,
          isDeleteComplete: PropTypes.bool,
        }),
        eventsSearchParams: PropTypes.shape({
          pagination: PropTypes.shape({
            pageSize: PropTypes.number,
          }),
        }),
      }),
      fetchSearchOptions: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.state = new EventsSearchState();
    this.searchComponent = new SearchComponent();

    this.searchEvents = this.searchEvents.bind(this);
    this.getSearchEventsItem = this.getSearchEventsItem.bind(this);
    this.searchButtonClick = this.searchButtonClick.bind(this);
    this.deleteAlertOrConfirmModalShow = this.deleteAlertOrConfirmModalShow.bind(
      this
    );
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchSearchOptions();
  }

  searchEvents(
    searchParams,
    values = this.state.values || this.props.state.eventsSearchValues
  ) {
    this.props.searchEvents(values, searchParams);
  }

  /* 検索結果テーブル作成 */
  getSearchEventsItem() {
    const events = this.props.state.eventsSearchResults.events;

    const transitionRefer = (id) => {
      this.props.history.push(routePath.refer(id));
    };

    const isChecked = (id) => {
      const state = EventsSearchState.fromState(this.state);
      return state.deleteValues.includes(id);
    };

    const items = events.map((events, index) => {
      const isExpired = (events) => {
        const endTime = moment(
          `${events.dateYMDTo} ${events.dateHHTo}:${events.dateMMTo}`,
          'YYYY/MM/DD hh:mm'
        );

        const now = moment();

        const expired = endTime.isBefore(now);
        console.debug('isEditButtonEnable', now, endTime, expired);

        return expired;
      };

      /**
       *
       * @param {number} num
       * @returns {string}
       */
      const zeroPadding = (num) => {
        return ('00' + num.toString()).slice(-2);
      };

      /**
       * @param {Array.<string>} tags
       * @returns {string}
       */
      const formatTags = (tags) => {
        if (!tags || tags.length === 0) {
          return '';
        }

        return tags.reduce((acm, tag) => {
          return `${acm}, ${tag}`;
        });
      };

      /**
       *
       * @param {string} timeString
       * @returns {string}
       */
      const formatTime = (timeString) => {
        const dateTime = new Date(timeString);
        console.debug('dateTime', dateTime);
        return moment(dateTime).format('YYYY/MM/DD HH:mm:ss');
      };
      return (
        <tr key={events.id}>
          <td scope="row">
            {isExpired(events) ? (
              <></>
            ) : (
              <>
                <input
                  className="mx-auto"
                  type="checkbox"
                  checked={isChecked(events.id)}
                  onChange={this.deleteCheckBoxClick.bind(this, events.id)}
                  disabled={isExpired(events)}
                />
              </>
            )}
          </td>
          <td>{events.name}</td>
          <td>{events.venue}</td>
          <td>{events.chairmanName}</td>
          <td>{`${events.dateYMDFrom} ${zeroPadding(
            events.dateHHFrom
          )}:${zeroPadding(events.dateMMFrom)} ~ ${zeroPadding(
            events.dateHHTo
          )}:${zeroPadding(events.dateMMTo)}`}</td>
          <td>{events.kindName}</td>
          <td>{events.categoryName}</td>
          <td>{formatTags(events.tags)}</td>
          <td>
            {events.seats}/{events.seatAvailability}
          </td>
          <td>{events.createdByName ? events.createdByName : '-'}</td>
          <td>{events.updatedByName ? events.updatedByName : '-'}</td>
          <td>{events.updatedAt ? formatTime(events.updatedAt) : '-'}</td>
          <td>
            <DetailButton onClick={transitionRefer.bind(this, events.id)} />
          </td>
        </tr>
      );
    });
    return items;
  }

  deleteAlertOrConfirmModalShow() {
    this.props.searchEventCheck(this.state.deleteValues);
  }

  /* 検索ボタン押下処理 */
  searchButtonClick(values) {
    const state = EventsSearchState.fromState(this.state);
    state.values = values;
    this.setState(state);
    const prevSearchValues = this.props.state.eventsSearchParams;

    let pageSize = this.searchComponent.pageDefaultSize;
    if (
      prevSearchValues &&
      prevSearchValues.pagination &&
      prevSearchValues.pagination.pageSize
    ) {
      pageSize = prevSearchValues.pagination.pageSize;
    }

    this.searchEvents(
      {
        sort: {
          sortColumn: 'name',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      values
    );
  }

  /* 新規登録ボタン押下処理 */
  newButtonClick() {
    this.props.history.push(routePath.edit(''));
  }

  /* 一括登録ボタン押下処理 */
  bulkRegisterButtonClick() {
    this.props.history.push(routePath.bulkRegister);
  }

  /* 削除ボタン押下処理 */
  deleteButtonClick(e) {
    this.deleteAlertOrConfirmModalShow();
    return e.preventDefault();
  }

  /* inputボタン押下処理 */
  deleteCheckBoxClick(id) {
    const state = EventsSearchState.fromState(this.state);

    const stateDeleteValues = state.deleteValues;
    let deleteValues;

    if (stateDeleteValues.indexOf(id) !== -1) {
      deleteValues = stateDeleteValues.filter((deleteValue) => {
        return deleteValue !== id;
      });
    } else {
      deleteValues = stateDeleteValues.concat([id]);
    }
    state.deleteValues = deleteValues;
    this.setState(state);
  }

  /* アラート,確認モーダルYES */
  onClickAlertAndConfirmYes() {
    this.props.deleteEvents(this.state.deleteValues);
  }

  /* アラートモーダルNO */
  onClickAlertNo() {
    this.props.hideDeleteAlertModal();
  }

  /* 確認モーダルNO */
  onClickConfirmNo() {
    this.props.hideDeleteConfirmModal();
  }

  /* 完了モーダルクリック */
  onClickComplete() {
    const state = EventsSearchState.fromState(this.state);
    state.deleteValues = [];
    this.setState(state, () => {
      console.debug('setstate complete onClickComplete', this.state);
      this.props.hideDeleteCompleteModal();
      this.searchButtonClick(this.state.values);
    });
    console.debug('onclick complete state', state);
  }

  render() {
    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>イベント管理</h1>
          </div>
          <div className="section-body">
            {this.props.state.isLoading ? (
              <></>
            ) : (
              <>
                <Row>
                  <Col>
                    <Card headerTitle="イベント管理">
                      {/* 検索項目 */}
                      <SearchForm onSubmit={this.searchButtonClick} />
                      <div className="text-left">
                        <Row>
                          <NewButton onClick={this.newButtonClick.bind(this)} />
                          &nbsp;
                          <BulkRegisterButton
                            onClick={this.bulkRegisterButtonClick.bind(this)}
                          />
                        </Row>
                      </div>
                    </Card>
                  </Col>
                </Row>
                {/* 検索結果 */}
                <Row>
                  <DeleteAlertModal
                    show={this.props.state.eventsDelete.isDeleteAlert}
                    onClickYes={this.onClickAlertAndConfirmYes.bind(this)}
                    onClickNo={this.onClickAlertNo.bind(this)}
                  />
                  <DeleteConfirmModal
                    show={this.props.state.eventsDelete.isDeleteConfirm}
                    onClickYes={this.onClickAlertAndConfirmYes.bind(this)}
                    onClickNo={this.onClickConfirmNo.bind(this)}
                  />
                  <DeleteCompleteModal
                    show={this.props.state.eventsDelete.isDeleteComplete}
                    onClick={this.onClickComplete.bind(this)}
                  />
                </Row>
                {this.props.state.eventsSearchResults.events.length > 0 ? (
                  <>
                    <Card>
                      <Row className="text-center">
                        <Pager
                          searchParams={
                            this.props.state.eventsSearchResults.searchParams
                          }
                          search={this.searchEvents}
                        />
                      </Row>
                      <Row>
                        <SearchResultTable
                          headerItems={[
                            {},
                            { name: 'name', value: 'イベント名' },
                            { name: 'venue_name', value: '会場' },
                            { name: 'chairman_name', value: '講師' },
                            { name: '`from`', value: '開催日時' },
                            { name: 'event_type_name', value: '種別' },
                            { name: 'categories_name', value: 'カテゴリー' },
                            { name: '', value: 'タグ' },
                            { name: 'seat_availability', value: '空席状況' },
                            { name: 'events.created_by', value: '登録者' },
                            { name: 'events.updated_by', value: '更新者' },
                            { name: 'events.updated_at', value: '更新日時' },
                            {},
                          ]}
                          searchResults={this.props.state.eventsSearchResults}
                          getSearchItem={this.getSearchEventsItem}
                          search={this.searchEvents}
                        />
                      </Row>
                      <Row>
                        <DeleteButton onClick={this.deleteButtonClick} />
                      </Row>
                    </Card>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </section>
      </>
    );
  }
}

export default EventsSearch;

import PropTypes from 'prop-types';
import React from 'react';

class FormCheckBox extends React.Component {
  static get propTypes() {
    return {
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      onChange: PropTypes.func,
    };
  }

  render() {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={this.props.id}
          onChange={this.props.onChange}
        />
        <label className="form-check-label" htmlFor={this.props.id}>
          {this.props.name}
        </label>
      </div>
    );
  }
}

export default FormCheckBox;

export const EditStatus = {
  NONE: 0,
  NEW: 1,
  EDIT: 2,
};

const initialState = {
  searchResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    roles: [],
  },
  roleSearch: {
    values: {},
    searchParams: {},
  },
  roleRefer: {
    detail: {},
  },
  roleEdit: {
    editStatus: EditStatus.NONE,
    isSubmitting: false,
    isComplete: false,
  },
  roleDelete: {
    isDeleteConfirm: false,
    isDeleteAlert: false,
    isDeleteComplete: false,
    cnt: 0,
    deleteSelections: 0,
  },
  roleCopy: {
    isCopyConfirm: false,
    isCopyComplete: false,
  },
  menuOption: [],
  menus: [],
  isLoading: false,
};

const roleManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'role/FETCH_ROLE_SEARCH': {
      const searchResults = action.payload.result;
      return { ...state, searchResults };
    }
    case 'role/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'role/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'role/INIT_STATE': {
      return {
        ...state,
        roleEdit: {
          isSubmitting: false,
          isComplete: false,
        },
      };
    }
    case 'role/FETCH_ROLE': {
      const results = action.payload.result;
      return {
        ...state,
        roleRefer: {
          ...state.roleRefer,
          detail: {
            ...state.roleRefer.detail,
            ...results,
          },
        },
        roleEdit: {
          editStatus: EditStatus.EDIT,
        },
      };
    }
    case 'role/FETCH_ROLES': {
      const results = action.payload.result;
      return {
        ...state,
        roleDelete: {
          ...state.roleDelete,
          cnt: results.cnt,
          isDeleteConfirm: false,
          isDeleteAlert: true,
        },
      };
    }
    case 'role/UPDATE_ROLE_LOADING': {
      return {
        ...state,
        roleEdit: { ...state.roleEdit, isSubmitting: true },
      };
    }
    case 'role/FETCH_UPDATE_ROLE': {
      return {
        ...state,
        roleEdit: { ...state.roleEdit, isComplete: true },
      };
    }
    case 'role/FETCH_DELETE_ROLE': {
      return {
        ...state,
        roleDelete: {
          ...state.roleDelete,
          isDeleteConfirm: false,
          isDeleteAlert: false,
          isDeleteComplete: true,
        },
      };
    }
    case 'role/FETCH_COPY_ROLE': {
      const newRoleId = action.payload.result;
      return {
        ...state,
        roleCopy: {
          newRoleId,
          isCopyConfirm: false,
          isCopyComplete: true,
        },
      };
    }
    case 'role/FETCH_MENUS': {
      const result = action.payload.result;
      return {
        ...state,
        roleRefer: {
          ...state.roleRefer,
          detail: {
            ...state.roleRefer.detail,
            menus: result,
          },
        },
      };
    }
    case 'role/NEW_ROLE': {
      return {
        ...state,
        roleRefer: { detail: {} },
      };
    }
    case 'role/SHOW_DELETE_CONFIRM': {
      const result = action.payload.result;
      return {
        ...state,
        roleDelete: {
          ...state.roleDelete,
          isDeleteConfirm: true,
          deleteSelections: result,
        },
      };
    }
    case 'role/CLOSE_DELETE_CONFIRM': {
      return {
        ...state,
        roleDelete: { ...state.roleDelete, isDeleteConfirm: false },
      };
    }
    case 'role/SHOW_DELETE_ALERT': {
      return {
        ...state,
        ...state,
        roleDelete: {
          ...state.roleDelete,
          isDeleteConfirm: false,
          isDeleteAlert: true,
        },
      };
    }
    case 'role/CLOSE_DELETE_ALERT': {
      return {
        ...state,
        ...state,
        roleDelete: { ...state.roleDelete, isDeleteAlert: false },
      };
    }
    case 'role/CLOSE_DELETE_COMPLETE': {
      return {
        ...state,
        ...state,
        roleDelete: { ...state.roleDelete, isDeleteComplete: false },
      };
    }
    case 'role/SHOW_COPY_CONFIRM': {
      return {
        ...state,
        roleCopy: { ...state.roleCopy, isCopyConfirm: true },
      };
    }
    case 'role/CLOSE_COPY_CONFIRM': {
      return {
        ...state,
        roleCopy: { ...state.roleCopy, isCopyConfirm: false },
      };
    }
    case 'role/CLOSE_COPY_COMPLETE': {
      return {
        ...state,
        roleCopy: { ...state.roleCopy, isCopyComplete: false },
      };
    }
    case 'role/SET_EDIT_VALUES': {
      const values = action.payload.result;

      const roles = Object.entries(values)
        .filter(([key, value]) => {
          return key.includes('RolePermissionId');
        })
        .map(([key, value]) => {
          const menuId = key.replace('RolePermissionId-', '');
          return {
            menuId: menuId,
            rolePermissionId: value,
          };
        });

      return {
        ...state,
        roleRefer: {
          ...state.roleRefer,
          detail: {
            ...state.roleRefer.detail,
            name: values.name,
            isSystemAdmin: values.isSystemAdmin === '1',
            roles: roles,
            initMenuId: values.initMenu,
          },
        },
      };
    }
    case 'role/FINISH_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'role/STORE_SEARCH_VALUES': {
      const payload = action.payload;

      return {
        ...state,
        roleSearch: {
          values: payload.values,
          searchParams: payload.searchParams,
        },
      };
    }
    case 'role/INIT_NEW_EDIT_STATE': {
      return {
        ...state,
        roleEdit: {
          editStatus: EditStatus.NEW,
        },
      };
    }
    case 'role/INIT_ROLE': {
      return initialState;
    }
    default:
      return state;
  }
};

export { roleManagementReducer as default };

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';

import FormLabel from '../atoms/FormLabel';
import FormDatePicker from '../molecules/FormDateYmdInputComponentForm';

class FormFromtoComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      fromId: PropTypes.string,
      fromDate: PropTypes.String,
      toId: PropTypes.string,
      toDate: PropTypes.String,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  fieldComponent(field) {
    return <Form.Control type="text" />;
  }

  component() {
    return (
      <div className="form-group row">
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </FormLabel>
        <div className="col-sm-9">
          <Row>
            <Col>
              <FormDatePicker
                name={this.props.fromId}
                startDate={this.props.fromDate}
              />
            </Col>
            ～
            <Col>
              <FormDatePicker
                name={this.props.toId}
                startDate={this.props.toDate}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    return this.component();
  }
}

export default FormFromtoComponent;

import { connect } from 'react-redux';

import {
  makeSearchInterviewBase,
  makeSearchInterview,
  makeSearchEventSeminorBase,
  makeSearchEventSeminor,
  makeSearchEventSessionBase,
  makeSearchEventSession,
  initState,
} from '../actions/Summary';
import SummaryComponent from '../components/pages/summary/Summary';
import SummarySearchComponent from '../components/pages/summary/SummarySearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.summary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchInterviewBase(values) {
      dispatch(makeSearchInterviewBase(values));
    },
    searchInterview(id, values, searchParams, searchType) {
      dispatch(makeSearchInterview(id, values, searchParams, searchType));
    },

    searchEventSeminorBase(values) {
      dispatch(makeSearchEventSeminorBase(values));
    },
    searchEventSeminor(id, values, searchParams) {
      dispatch(makeSearchEventSeminor(id, values, searchParams));
    },

    searchEventSessionBase(values) {
      dispatch(makeSearchEventSessionBase(values));
    },
    searchEventSession(id, values, searchParams) {
      dispatch(makeSearchEventSession(id, values, searchParams));
    },
    initState() {
      dispatch(initState());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryComponent);
const SummarySearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(SummarySearchComponent);

export { SummarySearch };

import { func, any, string, object, bool, shape } from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ConfirmForm from '../../organisms/base/BaseEditConfirmForm';
import Form from '../../organisms/base/BaseEditForm';
import Card from '../../organisms/Card';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';

import routePath from './routePath';

class BaseEdit extends React.Component {
  static get propTypes() {
    return {
      children: any,
      state: shape({
        isLoading: bool,
        baseEdit: shape({
          isConfirm: bool,
          isComplete: bool,
        }),
        searchValues: object,
        searchParams: object,
      }),
      match: shape({
        params: shape({ id: string }),
      }),
      updateBase: func,
      initState: func,
      history: shape({
        push: func,
      }),
      fetchBaseDetailForEdit: func,
      storeValues: func,
      backToInput: func,
      search: func,
    };
  }

  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
    this.transitionToRefer = this.transitionToRefer.bind(this);
    this.backToInput = this.backToInput.bind(this);
    this.submit = this.submit.bind(this);
    this.onCloseCompleteModal = this.onCloseCompleteModal.bind(this);

    this.state = { isConfirm: false };
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.fetchBaseDetailForEdit(this.id);
  }

  confirm(values) {
    console.debug('values', values);

    this.setState({ values: values, isConfirm: true });

    this.props.storeValues(values);
  }

  submit() {
    console.debug('submit values: ', this.state.values);

    this.props.updateBase(this.state.values);
  }

  transitionToRefer() {
    console.debug('transition to refer');
    if (this.id == null) {
      this.props.history.push(routePath.search);
    } else {
      this.props.history.push(routePath.refer(this.id));
    }
  }

  backToInput() {
    this.setState({ isConfirm: false });
    this.props.backToInput();
  }

  onCloseCompleteModal() {
    const values = this.props.state.searchValues;
    const searchParams = this.props.state.searchParams;

    if (
      (values && Object.keys(values).length > 0) ||
      (searchParams && Object.keys(searchParams).length > 0)
    ) {
      this.props.search(values, searchParams);
    }

    this.transitionToRefer();
  }

  render() {
    const isConfirm = () => {
      return this.props.state.baseEdit.isConfirm;
    };

    const isNew = () => {
      if (this.state.id != null) {
        return false;
      }
      return true;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>拠点管理</h1>
          </div>

          <div className="section-body">
            {!this.props.state.isLoading ? (
              <Row>
                <Col>
                  <Card headerTitle="編集">
                    {isConfirm() ? (
                      <ConfirmForm
                        onSubmit={this.submit}
                        backToInput={this.backToInput}
                        values={this.state.values || {}}
                      />
                    ) : (
                      <Form
                        onSubmit={this.confirm}
                        isNew={isNew()}
                        onPrevButtonClick={this.transitionToRefer}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </section>

        <RegisterCompleteModal
          show={this.props.state.baseEdit.isComplete}
          onCloseClick={this.onCloseCompleteModal}
        />
      </>
    );
  }
}

export default BaseEdit;

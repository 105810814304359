import { trackPromise } from 'react-promise-tracker';

const trackAsync = (asyncFunc) => {
  return trackPromise(
    new Promise((resolve, reject) => {
      console.debug('trackAsync begin');
      const a = async (resolve, reject) => {
        try {
          await asyncFunc();
          resolve();
          console.debug('trackAsync end');
        } catch (err) {
          reject(err);
        }
      };
      a(resolve, reject);
    })
  );
};

// eslint-disable-next-line import/prefer-default-export
export { trackAsync };

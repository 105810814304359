const initialState = {
  adminPasswordResetSendMail: {
    isSubmitting: false,
    isError: false,
    isComplete: false,
  },
  adminPasswordReset: {
    error: {
      isError: false,
      message: '',
    },
    isComplete: false,
  },
  isLoading: false,
};

const adminPasswordResetReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'admin/password_reset/SEND_ERROR': {
      return {
        ...state,
        adminPasswordResetSendMail: {
          ...state.adminPasswordResetSendMail,
          isError: true,
        },
      };
    }
    case 'admin/password_reset/SEND_ADMIN_PASSWORD_RESET_MAIL_LOADING': {
      return {
        ...state,
        adminPasswordResetSendMail: {
          ...state.adminPasswordResetSendMail,
          isLoading: true,
        },
      };
    }
    case 'admin/password_reset/FETCH_ADMIN_PASSWORD_RESET_SEND_MAIL': {
      return {
        ...state,
        adminPasswordResetSendMail: {
          ...state.adminPasswordResetSendMail,
          isComplete: true,
        },
      };
    }
    case 'admin/password_reset/ADMIN_PASSWORD_RESET_LOADING': {
      return {
        ...state,
        adminPasswordReset: { ...state.adminPasswordReset, isLoading: true },
      };
    }
    case 'admin/password_reset/FETCH_ADMIN_PASSWORD_RESET': {
      return {
        ...state,
        adminPasswordReset: { ...state.adminPasswordReset, isComplete: true },
      };
    }
    case 'admin/password_reset/INIT_STATE': {
      return initialState;
    }
    case 'admin/password_reset/INTERNAL_SERVER_ERROR': {
      return {
        ...state,
        adminPasswordReset: {
          ...state.adminPasswordReset,
          error: { isError: true, message: '更新に失敗しました。' },
        },
      };
    }
    case 'admin/password_reset/EXPIRED_ERROR': {
      return {
        ...state,
        adminPasswordReset: {
          ...state.adminPasswordReset,
          error: { isError: true, message: 'リンクの有効期限が切れています。' },
        },
      };
    }
    case 'admin/password_reset/PASSWORD_WRONG_ERROR': {
      return {
        ...state,
        adminPasswordReset: {
          ...state.adminPasswordReset,
          error: {
            isError: true,
            message: 'パスワードとパスワード(確認)が異なります。',
          },
        },
      };
    }
    default:
      return state;
  }
};

export { adminPasswordResetReducer as default };

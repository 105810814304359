import PropTypes from 'prop-types';
import React from 'react';

import PrimaryButton from '../atoms/PrimaryButton';
import { EditAction } from '../utils/PermissionComponent';

class ModifyButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <EditAction>
        <PrimaryButton onClick={this.props.onClick}>変更</PrimaryButton>
      </EditAction>
    );
  }
}

export default ModifyButton;

import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import ReferItem from '../../molecules/ReferItem';

class ReferAccess extends React.Component {
  static get propTypes() {
    return {
      accessList: PropTypes.array,
    };
  }

  render() {
    return (
      <div>
        {this.props.accessList != null ? (
          <div>
            {this.props.accessList.map((item, i) => {
              return (
                <div key={i}>
                  <FormGroup>
                    <Row>
                      <Col xs={3}>
                        <label className="control-label">アクセス方法</label>
                      </Col>
                      <Col xs={6} className="control-detail-div">
                        <SizedImg
                          alt="image"
                          src={`${process.env.REACT_APP_API_URI}/${item.accessMap}`}
                        ></SizedImg>
                      </Col>
                    </Row>
                  </FormGroup>
                  <ReferItem label="">{item.accessNote}</ReferItem>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const SizedImg = styled.img`
  width: 100%;
`;

export default ReferAccess;

import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from '../atoms/NormalButton';

class InfoButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      className: PropTypes.string,
    };
  }

  render() {
    return (
      <NormalButton color="info" {...this.props}>
        {this.props.children}
      </NormalButton>
    );
  }
}

export default InfoButton;

import { connect } from 'react-redux';

import {
  initState,
  makeFetchAreas,
  makeFetchHallsByAreaId,
  makeFetchCategories,
  makeFetchTags,
  makeSearchEvents,
  makeSearchEventCheck,
  makeDeleteEvents,
  hideDeleteAlertModal,
  hideDeleteConfirmModal,
  hideDeleteCompleteModal,
  setEditValues,
  makeFetchEvents,
  makeUpdateEvent,
  staffSearchModalShow,
  staffSearchModalHide,
  makefetchStaff,
  makeUploadBulkInsertFile,
  makeBulkInsertExec,
  hideExecutedDialog,
  makeFetchEventsForEdit,
  backToInput,
  initEventDetail,
  initBulkUpdate,
  makeFetchReferItem,
  clearErrorMessage,
  initEditState,
  makeFetchSearchOptions,
} from '../actions/Events';
import EventsComponent from '../components/pages/events/Events';
import EventsBulkInsertComponent from '../components/pages/events/EventsBulkInsert';
import EventsEditComponent from '../components/pages/events/EventsEdit';
import EventsReferComponent from '../components/pages/events/EventsRefer';
import EventsSearchComponent from '../components/pages/events/EventsSearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.events,
    formState: state.formState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initState() {
      dispatch(initState());
    },
    fetchArea() {
      dispatch(makeFetchAreas());
    },
    fetchHallsByAreaId(areaId) {
      dispatch(makeFetchHallsByAreaId(areaId));
    },
    fetchCategories() {
      dispatch(makeFetchCategories());
    },
    fetchTags() {
      dispatch(makeFetchTags());
    },
    searchEvents(values, searchParams) {
      dispatch(makeSearchEvents(values, searchParams));
    },
    searchEventCheck(values) {
      dispatch(makeSearchEventCheck(values));
    },
    deleteEvents(ids) {
      dispatch(makeDeleteEvents(ids));
    },
    hideDeleteAlertModal() {
      dispatch(hideDeleteAlertModal());
    },
    hideDeleteConfirmModal() {
      dispatch(hideDeleteConfirmModal());
    },
    hideDeleteCompleteModal() {
      dispatch(hideDeleteCompleteModal());
    },
    setEditValues(values) {
      dispatch(setEditValues(values));
    },
    fetchEvent(id) {
      dispatch(makeFetchEvents(id));
    },
    fetchEventForEdit(id) {
      dispatch(makeFetchEventsForEdit(id));
    },
    UpdateEvent(values) {
      dispatch(makeUpdateEvent(values));
    },
    staffSearchModalShow() {
      dispatch(staffSearchModalShow());
    },
    staffSearchModalHide() {
      dispatch(staffSearchModalHide());
    },
    fetchStaff() {
      dispatch(makefetchStaff());
    },
    updateEvents(values) {
      dispatch(makeUpdateEvent(values));
    },
    uploadBulkInsertFile(file) {
      dispatch(makeUploadBulkInsertFile(file));
    },
    BulkInsertExec(tempFileId, originalFileName) {
      dispatch(makeBulkInsertExec(tempFileId, originalFileName));
    },
    hideExecutedDialog() {
      dispatch(hideExecutedDialog());
    },
    backToInput() {
      dispatch(backToInput());
    },
    initEventDetail() {
      dispatch(initEventDetail());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsComponent);

const EventsSearch = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchSearchOptions() {
      dispatch(makeFetchSearchOptions());
    },
  };
})(EventsSearchComponent);

export { EventsSearch };

const EventsEdit = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initEditState() {
      dispatch(initEditState());
    },
  };
})(EventsEditComponent);

export { EventsEdit };

const EventsRefer = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchReferItem(id) {
      dispatch(makeFetchReferItem(id));
    },
  };
})(EventsReferComponent);

export { EventsRefer };

const EventsBulkInsert = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initBulkUpdate() {
      dispatch(initBulkUpdate());
    },
    clearErrorMessage() {
      dispatch(clearErrorMessage());
    },
  };
})(EventsBulkInsertComponent);

export { EventsBulkInsert };

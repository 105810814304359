import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

class FileButton extends React.Component {
  static get propTypes() {
    return {
      onChange: PropTypes.func,
      disabled: PropTypes.bool,
      fileName: PropTypes.string,
      innerRef: PropTypes.object,
    };
  }

  render() {
    return (
      <div className="input-group">
        <label role="button" className="input-group-btn">
          <CursorButton className="btn btn-primary">
            ファイルを選択
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={this.props.onChange}
              ref={this.props.innerRef}
            />
          </CursorButton>
        </label>
        <label>{this.props.fileName}</label>
      </div>
    );
  }
}

const CursorButton = styled.span`
  cursor: pointer;
`;

export default React.forwardRef((props, ref) => {
  return <FileButton {...props} innerRef={ref} />;
});

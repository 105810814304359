import { saveAs } from 'file-saver';

const initialState = {
  searchResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    staffs: [],
  },
  staffSearch: {
    isShowDisableSuccessModal: false,
    isShowUnlockSuccessModal: false,
    searchValues: {
      values: {},
      searchParams: {},
    },
  },
  staffRefer: {
    isDisplayReSendComplete: false,
    isDisplayReSendFailureModal: false,
    isAlreadySend: false,
    errorMessages: [],
    detail: {},
    isSystemAdmin: false,
  },
  staffEdit: {
    isSubmitting: false,
    isComplete: false,
    isConfirm: false,
    isNewData: false,
    role: {},
  },
  staffBulkUpdate: {
    searchResults: {
      results: [],
    },
    existsRunning: true,
    isShowExecutedDialog: false,
    isAlreadyExec: false,
  },
  isLoading: false,
};

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'staff/FETCH_STAFF_SEARCH': {
      const searchResults = action.payload.result;
      return { ...state, searchResults };
    }
    case 'SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'staff/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'staff/FETCH_STAFF': {
      const results = action.payload.result;
      return {
        ...state,
        staffRefer: { ...state.staffRefer, detail: results },
      };
    }
    case 'staff/FETCH_ROLE': {
      const results = action.payload.result;
      return {
        ...state,
        staffEdit: { ...state.staffEdit, role: results },
      };
    }
    case 'staff/UPDATE_STAFF_LOADING': {
      return {
        ...state,
        staffEdit: { ...state.staffEdit, isSubmitting: true },
      };
    }
    case 'staff/FETCH_UPDATE_STAFF': {
      return {
        ...state,
        staffEdit: { ...state.staffEdit, isComplete: true },
      };
    }
    case 'staff/FETCH_INSERT_STAFF': {
      const data = action.payload.result.staffId;
      return {
        ...state,
        staffEdit: { ...state.staffEdit, isComplete: true, newStaffId: data },
      };
    }
    case 'staff/INIT_STATE': {
      return initialState;
    }
    case 'staff/SET_EDIT_VALUES': {
      const values = action.payload.values;
      return {
        ...state,
        staffRefer: {
          detail: {
            ...state.staffRefer.detail,
            familyName: values.familyName,
            firstName: values.firstName,
            profileTempId: values.profileTempId,
            mail: values.mail,
            tel: `${values.tel1}-${values.tel2}-${values.tel3}`,
            baseId: values.baseId,
            divisionId: values.divisionId,
            occupationId: values.occupationId,
            roleId: values.roleId,
            profileUrl: values.profileUrl,
            profileImageId: values.profileImageId,
          },
        },
        staffEdit: {
          ...state.staffEdit,
          isConfirm: true,
        },
      };
    }
    case 'staff/BAD_REQUEST': {
      const messages = action.payload.messages;
      return {
        ...state,
        staffEdit: {
          ...state.staffEdit,
          isError: true,
          messages: messages,
          isConfirm: false,
          isSubmitting: false,
        },
      };
    }
    case 'staff/BACK_TO_INPUT': {
      return {
        ...state,
        staffEdit: {
          ...state.staffEdit,
          isConfirm: false,
        },
      };
    }
    case 'staff/INIT_FETCH_STAFF_EDIT': {
      return {
        ...state,
        staffRefer: {
          detail: {},
        },
        staffEdit: initialState.staffEdit,
      };
    }
    case 'staff/NEW_DATA_EDIT': {
      return {
        ...state,
        staffEdit: {
          ...state.staffEdit,
          isNewData: true,
        },
      };
    }
    case 'staff/FETCH_BULK_UPDATE_HISTORIES_SUCCESS': {
      const data = action.payload.values;
      return {
        ...state,
        staffBulkUpdate: {
          ...state.staffBulkUpdate,
          searchResults: data,
        },
      };
    }
    case 'staff/UPLOAD_BULK_UPDATE_FILE_SUCCESS': {
      const data = action.payload;
      return {
        ...state,
        staffBulkUpdate: {
          ...state.staffBulkUpdate,
          tempFileId: data.id,
          originalFileName: data.originalFileName,
        },
      };
    }
    case 'staff/BULK_UPDATE_EXEC_SUCCESS': {
      return {
        ...state,
        staffBulkUpdate: {
          ...state.staffBulkUpdate,
          isShowExecutedDialog: true,
          isAlreadyExec: true,
        },
      };
    }
    case 'staff/HIDE_EXECUTED_DIALOG': {
      return {
        ...state,
        staffBulkUpdate: {
          ...state.staffBulkUpdate,
          isShowExecutedDialog: false,
        },
      };
    }
    case 'staff/DOWNLOAD_BULK_UPDATE_RESULT_FILE_SUCCESS': {
      const data = action.payload.data;
      const fileName = action.payload.fileName;
      saveAs(new Blob([data], { type: 'text/csv; charset=utf-8' }), fileName);
      return state;
    }
    case 'staff/DISABLRE_STAFF_SUCCESS': {
      return {
        ...state,
        staffSearch: {
          ...state.staffSearch,
          isShowDisableSuccessModal: true,
        },
      };
    }
    case 'staff/HIDE_DISABLE_SUCCESS_MODAL': {
      return {
        ...state,
        searchResults: {
          pagination: {
            page: 1,
            page_size: 10,
          },
          staffs: [],
        },
        staffSearch: {
          ...state.staffSearch,
          isShowDisableSuccessModal: false,
        },
      };
    }
    case 'staff/UNLOCK_STAFF_SUCCESS': {
      return {
        ...state,
        staffSearch: {
          ...state.staffSearch,
          isShowUnlockSuccessModal: true,
        },
      };
    }
    case 'staff/HIDE_UNLOCK_SUCCESS_MODAL': {
      return {
        ...state,
        searchResults: {
          pagination: {
            page: 1,
            page_size: 10,
          },
          staffs: [],
        },
        staffSearch: {
          ...state.staffSearch,
          isShowUnlockSuccessModal: false,
        },
      };
    }
    case 'staff/RE_SEND_ACTIVATION_MAIL_SUCCESS': {
      return {
        ...state,
        staffRefer: {
          ...state.staffRefer,
          isDisplayReSendComplete: true,
        },
      };
    }
    case 'staff/HIDE_RE_SEND_MAIL_COMPLETE': {
      return {
        ...state,
        staffRefer: {
          ...state.staffRefer,
          isDisplayReSendComplete: false,
          isAlreadySend: true,
        },
      };
    }
    case 'staff/RE_SEND_ACTIVATION_MAIL_FAILURE': {
      const payload = action.payload;
      return {
        ...state,
        staffRefer: {
          ...state.staffRefer,
          isDisplayReSendFailureModal: true,
          errorMessages: payload.messages.general,
        },
      };
    }
    case 'staff/HIDE_RE_SEND_FAILURE_DIALOG': {
      return {
        ...state,
        staffRefer: {
          ...state.staffRefer,
          isDisplayReSendFailureModal: false,
          errorMessages: [],
        },
      };
    }
    case 'staff/FETCH_ADMIN_PROFILE_SUCCESS': {
      return {
        ...state,
        staffRefer: {
          ...state.staffRefer,
          isSystemAdmin: action.payload.systemAdminFlag,
        },
      };
    }
    case 'staff/FETCH_BULK_UPDATE_STATUS_SUCCESS': {
      const existsRunning = action.payload.existsProcessingBulkUpdate;

      return {
        ...state,
        staffBulkUpdate: {
          ...state.staffBulkUpdate,
          existsRunning: existsRunning,
        },
      };
    }
    case 'staff/BULK_UPDATE_EXEC_FAILURE': {
      const data = action.payload;

      let messages = [];

      if (data && data.messages && data.messages.general) {
        messages = data.messages.general;
      }

      return {
        ...state,
        staffBulkUpdate: {
          ...state.staffBulkUpdate,
          isShowBulkUpdateFailureDialog: true,
          failureMessages: messages,
        },
      };
    }
    case 'staff/HIDE_BULK_UPDATE_FAILURE_DIALOG': {
      return {
        ...state,
        staffBulkUpdate: {
          ...state.staffBulkUpdate,
          isShowBulkUpdateFailureDialog: false,
          failureMessages: [],
        },
      };
    }
    case 'staff/INITIALIZE_STAFF_BULK': {
      return {
        ...state,
        staffBulkUpdate: initialState.staffBulkUpdate,
        isLoading: true,
      };
    }
    case 'staff/STORE_SEARCH_VALUES': {
      return {
        ...state,
        staffSearch: {
          ...state.staffSearch,
          searchValues: action.payload,
        },
      };
    }
    case 'staff/INIT_REFER_STATE': {
      return {
        ...state,
        staffRefer: initialState.staffRefer,
        staffEdit: initialState.staffEdit,
      };
    }
    default:
      return state;
  }
};

export { staffReducer as default };

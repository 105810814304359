import { FromZipcode } from '../components/model/Zipcode';

const initialState = {
  searchResults: {
    searchParams: {
      pagination: {
        page: 1,
        page_size: 10,
      },
    },

    bases: [],
  },
  searchParams: {},
  searchValues: {},
  baseRefer: {
    detail: {},
  },
  baseEdit: {
    isSubmitting: false,
    isComplete: false,
    isConfirm: false,
    accessList: [],
    errorMessages: {},
  },
  baseDelete: {
    isDisplayUsedContainsAlertDialog: false,
    isSubmitting: false,
    isConfirm: false,
    isComplete: false,
    errMessage: null,
  },
  isLoading: false,
};

const baseReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'base/SHOW_DELETE_CONFIRM': {
      return {
        ...state,
        baseDelete: {
          ...state.baseDelete,
          isConfirm: true,
          errMessage: null,
        },
      };
    }
    case 'base/CLOSE_DELETE_CONFIRM': {
      return {
        ...state,
        baseDelete: {
          ...state.baseDelete,
          isConfirm: false,
        },
      };
    }
    case 'base/DELETE_BASE': {
      return {
        ...state,
        baseDelete: {
          ...state.baseDelete,
          isConfirm: false,
          isComplete: true,
        },
      };
    }
    case 'base/CLOSE_DELETE_COMPLETE': {
      return {
        ...state,
        baseDelete: {
          ...state.baseDelete,
          isComplete: false,
        },
      };
    }
    case 'base/ERROR_DELETE': {
      return {
        ...state,
        baseDelete: {
          ...state.baseDelete,
          errMessage: '選択してください',
        },
      };
    }
    case 'base/DELETE_BASE_LOADING': {
      return {
        ...state,
        baseDelete: { ...state.baseDelete, isSubmitting: true },
      };
    }
    case 'base/FETCH_BASE_SEARCH': {
      const searchResults = action.payload.result;
      return { ...state, searchResults };
    }
    case 'base/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'base/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'base/FETCH_BASE': {
      const results = action.payload.result;
      return {
        ...state,
        baseRefer: { ...state.baseRefer, detail: results },
        isLoading: false,
      };
    }
    case 'base/UPDATE_BASE_LOADING': {
      return {
        ...state,
        baseEdit: { ...state.baseEdit, isSubmitting: true },
      };
    }
    case 'base/UPDATE_BASE': {
      return {
        ...state,
        baseEdit: { ...state.baseEdit, isComplete: true },
      };
    }
    case 'base/ADD_ACCESS': {
      const results = action.payload.result;
      return {
        ...state,
        baseEdit: { ...state.baseEdit, accessList: results },
      };
    }
    case 'base/DELETE_ACCESS': {
      const results = action.payload.result;
      return {
        ...state,
        baseEdit: { ...state.baseEdit, accessList: results },
      };
    }
    case 'base/UPDATE_BASE_ERROR': {
      const payload = action.payload;

      return {
        ...state,
        baseEdit: {
          ...state.baseEdit,
          isSubmitting: false,
          isComplete: false,
          isConfirm: false,
          errorMessages: payload,
        },
      };
    }
    case 'base/STORE_VALUES': {
      const values = action.payload;

      return {
        ...state,
        baseRefer: {
          ...state.baseRefer,
          detail: {
            ...state.baseRefer.detail,
            name: values.name,
            displayName: values.displayName,
            companyName: values.companyName,
            isCaravan: values.isCaravan,
            isDisplay: values.isDisplay,
            areaId: values.areaId,
            zipCode: FromZipcode(values.zipCode1, values.zipCode2).toString(),
            prefId: values.prefId,
            city: values.city,
            buildingName: values.buildingName,
            phoneNumber: `${values.phoneNumber1}-${values.phoneNumber2}-${values.phoneNumber3}`,
            mailAddress: values.mailAddress,
            eventDisplayLimit: values.eventDisplayLimit,
            googleMapUrl: values.googleMapUrl,
            googleMapShortUrl: values.googleMapShortUrl,
            accessList: values.accessList,
            nearestStation: values.nearestStation,
          },
        },
        baseEdit: {
          ...state.baseEdit,
          isConfirm: true,
        },
      };
    }
    case 'base/INIT_REFER_DETAIL': {
      return {
        ...state,
        baseRefer: {
          ...state.baseRefer,
          detail: initialState.baseRefer.detail,
        },
        baseEdit: {
          ...initialState.baseEdit,
        },
      };
    }
    case 'base/NEW_BASE': {
      return {
        ...state,
        baseRefer: { detail: {} },
      };
    }
    case 'base/INIT_REFER_STATE': {
      return {
        ...state,
        baseRefer: {
          ...initialState.baseRefer,
        },
      };
    }
    case 'base/BACK_TO_INPUT': {
      return {
        ...state,
        baseEdit: {
          ...state.baseEdit,
          isConfirm: false,
        },
      };
    }
    case 'base/STORE_SEARCH_VALUES': {
      const { values, searchParams } = action.payload;
      return {
        ...state,
        searchValues: values,
        searchParams: searchParams,
      };
    }
    case 'base/SHOW_USED_CONTAINS_ALERT': {
      return {
        ...state,
        baseDelete: {
          ...state.baseDelete,
          isDisplayUsedContainsAlertDialog: true,
        },
      };
    }
    case 'base/HIDE_USED_CONTAINS_ALERT': {
      return {
        ...state,
        baseDelete: {
          ...state.baseDelete,
          isDisplayUsedContainsAlertDialog: false,
        },
      };
    }
    default:
      return state;
  }
};

export { baseReducer as default };

import PropTypes from 'prop-types';
import React from 'react';

import Badge from '../atoms/Badge';

class HorizontalBadgesComponent extends React.Component {
  static get propTypes() {
    return {
      badges: PropTypes.array,
    };
  }

  render() {
    return (
      <div className="row">
        <div className="col badges">
          {this.props.badges.map((badge, i) => {
            return <Badge key="badge">{badge}</Badge>;
          })}
        </div>
      </div>
    );
  }
}

export default HorizontalBadgesComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';

class FormTextArea extends React.Component {
  static get propTypes() {
    return {
      placeholder: PropTypes.string,
      onChange: PropTypes.func,
      value: PropTypes.string,
      defaultValue: PropTypes.string,
      className: PropTypes.string,
      input: PropTypes.any,
      meta: PropTypes.any,
    };
  }

  constructor(props) {
    super(props);

    console.debug('formTextArea props', props);
    const { input, meta, ...other } = props;
    this.other = other;
  }

  render() {
    return (
      <FormControl
        as="textarea"
        rows="10"
        className="h-100"
        {...this.props.input}
        {...this.other}
        isInvalid={this.props.meta.touched && this.props.meta.error}
      />
    );
  }
}

export default FormTextArea;

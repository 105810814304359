const initialState = {
  venueList: [],
  searchResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    hallList: [],
  },
  searchParams: {
    name: '',
    areaId: '',
  },
  searchOptions: {},
  venueRefer: {
    accessList: [],
  },
  venueEdit: {
    isSubmitting: false,
    isComplete: false,
    isConfirm: false,
    newVenueId: null,
    isNewData: false,
    role: {},
    errorMessages: {},
  },
  areas: {},
  isLoading: true,
  isDisplaySavedModal: false,
  data: {},
  prefectures: [],
  errorResult: {
    messages: [],
  },
  isShowDisableSuccessModal: false,
  isDisplayContainsUsedAlertModal: false,
  isDisplayDeleteConfirmModal: false,
};

const venueReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'venue/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'venue/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'SEARCH_DATA': {
      const searchResults = action.payload.result;
      const searchParams = action.payload.searchParams;
      return { ...state, searchResults, searchParams };
    }
    case 'venue/REFER_DATA': {
      const venueRefer = action.payload.result;
      return { ...state, venueRefer };
    }
    case 'venue/UPDATE_VENUE_LOADING': {
      return {
        ...state,
        venueEdit: { ...state.venueEdit, isSubmitting: true },
      };
    }
    case 'venue/FETCH_UPDATE_VENUE': {
      const isNewData = action.payload.id == null;
      return {
        ...state,
        venueEdit: {
          ...state.venueEdit,
          isComplete: true,
          isNewData: isNewData,
        },
      };
    }
    case 'venue/INSERT_NEW_VENUE': {
      return {
        ...state,
        venueEdit: {
          ...state.venueEdit,
          venueRefer: initialState.venueRefer,
          isNewData: true,
        },
        isLoading: false,
      };
    }
    case 'venue/DELETE_VENUE': {
      return {
        ...state,
        venueDelete: { ...state.venueDelete, isComplete: true },
        isShowDisableSuccessModal: true,
      };
    }
    case 'venue/HIDE_DELETE_SUCCESS_MODAL': {
      return {
        ...state,
        isShowDisableSuccessModal: false,
      };
    }
    case 'venue/STORE_VALUES': {
      const values = action.payload;

      return {
        ...state,
        venueRefer: {
          ...state.baseRefer,
          name: values.name,
          areaId: values.areaId,
          baseId: values.baseId,
          zipCode: `${values.zipCode1}-${values.zipCode2}`,
          prefId: values.prefId,
          city: values.city,
          buildingName: values.buildingName,
          phoneNumber: `${values.tel1}-${values.tel2}-${values.tel3}`,
          mailAddress: values.mail,
          eventDisplayLimit: values.eventDisplayLimit,
          googleMapUrl: values.googleMapUrl,
          googleMapShortUrl: values.googleMapShortUrl,
          accessList: values.accessList,
          nearestStation: values.nearestStation,
        },
        venueEdit: {
          ...state.venueEdit,
          isConfirm: true,
          errorMessages: {},
        },
      };
    }
    case 'FETCH_ERROR': {
      const errorResult = action.payload.error;
      return { ...state, errorResult };
    }
    case 'venue/INIT_STATE': {
      return initialState;
    }
    case 'venue/DELETE_REFER': {
      return {
        ...state,
        venueRefer: initialState.venueRefer,

        venueEdit: initialState.venueEdit,
        isDisplaySavedModal: initialState.isDisplaySavedModal,
        errorResult: initialState.errorResult,
        isShowDisableSuccessModal: initialState.isShowDisableSuccessModal,
      };
    }
    case 'venue/STORE_SEARCH_VALUES': {
      const { values, searchParams } = action.payload;
      return {
        ...state,
        searchParams: values,
        searchOptions: searchParams,
      };
    }
    case 'venue/UPDATE_BAD_REQUEST': {
      const errorMessages = action.payload;
      return {
        ...state,
        venueEdit: {
          ...state.venueEdit,
          errorMessages: errorMessages,
          isSubmitting: false,
          isComplete: false,
          isConfirm: false,
        },
      };
    }
    case 'venue/BACK_TO_EDIT': {
      return {
        ...state,
        venueEdit: {
          ...state.venueEdit,
          isConfirm: false,
        },
      };
    }
    case 'venue/INIT_ERROR_MESSAGES': {
      return {
        ...state,
        venueEdit: {
          ...state.venueEdit,
          errorMessages: {},
        },
      };
    }
    case 'venue/SHOW_CONTAINS_USED_ALERT': {
      return {
        ...state,
        isDisplayContainsUsedAlertModal: true,
      };
    }
    case 'venue/HIDE_CONTAINS_USED_ALERT': {
      return {
        ...state,
        isDisplayContainsUsedAlertModal: false,
      };
    }
    case 'venue/SHOW_DELETE_CONFIRM_MODAL': {
      return {
        ...state,
        isDisplayDeleteConfirmModal: true,
      };
    }
    case 'venue/HIDE_DELETE_CONFIRM_MODAL': {
      return {
        ...state,
        isDisplayDeleteConfirmModal: false,
      };
    }
    default:
      return state;
  }
};

export { venueReducer as default };

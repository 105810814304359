import PropTypes, { number } from 'prop-types';
import React from 'react';
import { Col, FormLabel } from 'react-bootstrap';

import FormGroupRow, {
  VERTICAL as FormGropuRowVertical,
} from '../atoms/FormGroupRow';

class FormReferItem extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      label: PropTypes.string,
      labelVertical: number,
    };
  }

  render() {
    const getVerticalLabel = () => {
      if (Object.prototype.hasOwnProperty.call(this.props, 'labelVertical')) {
        if (this.props.labelVertical === VERTICAL.TOP) {
          return FormGropuRowVertical.TOP;
        } else if (this.props.labelVertical === VERTICAL.CENTER) {
          return FormGropuRowVertical.CENTER;
        } else if (this.props.labelVertical === VERTICAL.BOTTOM) {
          return FormGropuRowVertical.BOTTOM;
        }
      }

      return FormGropuRowVertical.CENTER;
    };

    return (
      <FormGroupRow vertical={getVerticalLabel()}>
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </FormLabel>

        <Col xs={6} className="control-detail-div">
          {this.props.children}
        </Col>
      </FormGroupRow>
    );
  }
}

export default FormReferItem;

export const VERTICAL = {
  TOP: 0,
  CENTER: 1,
  BOTTOM: 2,
};

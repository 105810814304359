import { BaseSearch, BaseRefer, BaseEdit } from '../../../containers/Base';

import routePath from './routePath';

const BaseRoutes = [
  {
    path: routePath.search,
    exact: true,
    component: BaseSearch,
  },
  {
    path: routePath.refer(':id'),
    component: BaseRefer,
  },
  {
    path: routePath.edit(':id'),
    component: BaseEdit,
  },
  {
    path: routePath.newEdit,
    component: BaseEdit,
  },
];

export default BaseRoutes;

import { connect } from 'react-redux';

import {
  makeStoreTabIndex,
  makeFetchPrefs,
  makeFetchCompanyNames,
  makeFetchCurrentStatuses,
  makeFetchCollegeRegistrationCategories,
  makeSearchContact,
  makeSearchLead,
  makeSearch,
  makeFetchUser,
  makeUpdateUser,
  showEdit,
  showEditClose,
  showEditConfirm,
  showEditConfirmClose,
  showEditCompleteClose,
  initState,
  makeUnlockUserAccount,
  makeHideUnlockSuccessModal,
  makeDeactivateUser,
  makeHideDeactivateCompleteModal,
} from '../actions/User';
import UserComponent from '../components/pages/user/User';
import UserReferComponent from '../components/pages/user/UserRefer';
import UserSearchComponent from '../components/pages/user/UserSearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.user,
    formState: state.form.userSearchForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeTabIndex(index) {
      dispatch(makeStoreTabIndex(index));
    },
    fetchPrefs() {
      dispatch(makeFetchPrefs());
    },
    fetchCompanyNames() {
      dispatch(makeFetchCompanyNames());
    },
    fetchCurrentStatuses() {
      dispatch(makeFetchCurrentStatuses());
    },
    fetchCollegeRegistrationCategories() {
      dispatch(makeFetchCollegeRegistrationCategories());
    },
    // 検索(B.人(取責))
    searchContact(values, initFlg, searchParams) {
      dispatch(makeSearchContact(values, initFlg, searchParams));
    },
    // 検索(リード)
    searchLead(values, initFlg, searchParams) {
      dispatch(makeSearchLead(values, initFlg, searchParams));
    },
    // 検索(会員DB)
    search(values, initFlg, searchParams) {
      dispatch(makeSearch(values, initFlg, searchParams));
    },
    fetchUser(id) {
      dispatch(makeFetchUser(id));
    },
    updateUser(values, id) {
      dispatch(makeUpdateUser(values, id));
    },
    showEdit() {
      dispatch(showEdit());
    },
    showEditClose() {
      dispatch(showEditClose());
    },
    showEditConfirm() {
      dispatch(showEditConfirm());
    },
    showEditConfirmClose() {
      dispatch(showEditConfirmClose());
    },
    showEditCompleteClose() {
      dispatch(showEditCompleteClose());
    },
    initState() {
      dispatch(initState());
    },
    unlockUserAccount(userId) {
      dispatch(makeUnlockUserAccount(userId));
    },
    hideUnlockSuccessModal() {
      dispatch(makeHideUnlockSuccessModal());
    },
    deactivate(userId) {
      dispatch(makeDeactivateUser(userId));
    },
    hideDeactivateCompleteModal(userId) {
      dispatch(makeHideDeactivateCompleteModal(userId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);

const UserSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSearchComponent);

export { UserSearch };

const UserRefer = connect((state) => {
  return {
    ...mapStateToProps(state),

    userEdit: {
      ...state.user.userEdit,
      isEdit: state.user.userEdit.isEdit,
      isConfirm: state.user.userEdit.isConfirm,
      isComplete: state.user.userEdit.isComplete,
    },
    profile: state.user.profile,
  };
}, mapDispatchToProps)(UserReferComponent);

export { UserRefer };

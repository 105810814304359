import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import ModalBody from '../../atoms/ModalBody';
import HorizontalBadgesComponent from '../../molecules/HorizontalBadgesComponent';
import ModalFooterThreeButtonComponent from '../../molecules/ModalFooterThreeButtonComponent';
import ReferItem from '../../molecules/ReferItem';
import { EditAction, ReferAction } from '../../utils/PermissionComponent';

import SearchDetailModalAccordion from './SearchDetailModalAccordion';
import SubstituteAlertForModal from './SubstituteAlertForModal';

class SearchInterviewsDetailModal extends React.Component {
  static get propTypes() {
    return {
      show: PropTypes.bool,
      interviewsDetail: PropTypes.any,
      methodTypeOptions: PropTypes.array,
      onClickInterviewDetailButton: PropTypes.func,
      onClickReservationDetailButton: PropTypes.func,
      onClickModalCloseButton: PropTypes.func,
      substituteUser: PropTypes.object,
    };
  }

  constructor(props) {
    super(props);
    this.onClickReservationDetailButton = this.onClickReservationDetailButton.bind(
      this
    );
    this.onClickInterviewDetailButton = this.onClickInterviewDetailButton.bind(
      this
    );
  }

  onClickInterviewDetailButton() {
    const interviewId = this.props.interviewsDetail.id;
    const interviewerId = this.props.interviewsDetail.interviewerId;

    this.props.onClickInterviewDetailButton(interviewId, interviewerId);
  }

  onClickReservationDetailButton() {
    const interviewId = this.props.interviewsDetail.id;
    this.props.onClickReservationDetailButton(interviewId);
  }

  render() {
    const getMethodType = (id) => {
      const filteredOptions = this.props.methodTypeOptions.filter(
        (methodType) => methodType.value === id
      );

      console.debug('getMethodType', filteredOptions);

      if (filteredOptions.length > 0) {
        return filteredOptions[0].name;
      }
      return '未選択';
    };

    const participantsNumber = `${this.props.interviewsDetail.interviewees.length} / ${this.props.interviewsDetail.seatAvailability}の参加者`;

    return (
      <Modal show={this.props.show}>
        <ModalBody>
          {this.props.substituteUser ? (
            <SubstituteAlertForModal
              substituteUser={this.props.substituteUser}
            />
          ) : (
            <></>
          )}
          {this.props.interviewsDetail.tags != null &&
          this.props.interviewsDetail.tags.length > 0 ? (
            <HorizontalBadgesComponent
              badges={this.props.interviewsDetail.tags.map((tag) => tag.name)}
            />
          ) : (
            <></>
          )}

          <br />
          <ReferItem label="開催日：">
            {this.props.interviewsDetail.dateOfHolding}
          </ReferItem>
          <ReferItem label="面談形式：">
            {getMethodType(this.props.interviewsDetail.methodType)}
          </ReferItem>
          <ReferItem label="担当者：">
            {this.props.interviewsDetail.interviewer}
          </ReferItem>
          <ReferItem label="会場：">
            {this.props.interviewsDetail.venue}
          </ReferItem>
          <ReferItem label="面談予定者：">
            <SearchDetailModalAccordion
              accordionTitle={participantsNumber}
              list={this.props.interviewsDetail.interviewees.map(
                (user) => user.name
              )}
            />
          </ReferItem>
          <ReferItem label="備考：">
            {this.props.interviewsDetail.descriptionForPersonal}
          </ReferItem>
        </ModalBody>
        <ModalFooterThreeButtonComponent
          leftButtonLabel="面談予定詳細"
          leftButtonAction={ReferAction}
          onClickLeftButton={this.onClickInterviewDetailButton}
          rightButtonLabel="予約編集"
          rightButtonAction={EditAction}
          rightButtonDisable={false}
          onClickRightButton={this.onClickReservationDetailButton}
          onClickCloseButton={this.props.onClickModalCloseButton}
        />
      </Modal>
    );
  }
}

export default SearchInterviewsDetailModal;

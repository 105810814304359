import PropTypes from 'prop-types';
import React from 'react';

import DetailButton from './DetailButton';

class TdLabelButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      children: PropTypes.any,
    };
  }

  render() {
    return (
      <td className="text-white">
        <DetailButton onClick={this.props.onClick}>
          {this.props.children}
        </DetailButton>
      </td>
    );
  }
}

export default TdLabelButton;

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import CopyButton from '../../molecules/CopyButton';
import ModifyButton from '../../molecules/ModifyButton';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import Card from '../../organisms/Card';

import routePath from './routePath';

class TemplateRefer extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      fetchTemplate: PropTypes.func,
      fetchContactTypes: PropTypes.func,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        templateRefer: PropTypes.shape({
          detail: PropTypes.shape({
            categoryName: PropTypes.string,
            templateName: PropTypes.string,
            contactType: PropTypes.string,
            groupId: PropTypes.string,
            customName: PropTypes.string,
            subject: PropTypes.string,
            body: PropTypes.string,
            isDefault: PropTypes.string,
            categoryId: PropTypes.string,
            templateNameId: PropTypes.string,
            groupName: PropTypes.string,
            bodyHtml: PropTypes.string,
          }),
        }),
        contactTypes: PropTypes.array,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
    this.transitionToNewEdit = this.transitionToNewEdit.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.fetchTemplate(this.id);
    this.props.fetchContactTypes();
  }

  transitionToSearch() {
    this.props.history.push(routePath.search);
  }

  transitionToEdit() {
    this.props.history.push(routePath.edit(this.id));
  }

  transitionToNewEdit(values) {
    values.id = null;
    this.setState({ values: values });
    console.debug('transitionToNewEdit_values', values);
    this.props.history.push({
      pathname: routePath.edit(''),
      state: { id: this.id },
    });
  }

  render() {
    const getCategoryName = () => {
      return this.props.state.templateRefer.detail.categoryName;
    };

    const getTemplateName = () => {
      return this.props.state.templateRefer.detail.templateName;
    };

    const getGroupName = () => {
      return this.props.state.templateRefer.detail.groupName;
    };

    const getContactToolName = () => {
      if (this.props.state.contactTypes.length > 0) {
        const findContactType = this.props.state.contactTypes.find(
          (type) =>
            type.value === this.props.state.templateRefer.detail.contactType
        );

        return findContactType ? findContactType.label : '';
      }
      return '';
    };

    const getCustomName = () => {
      return this.props.state.templateRefer.detail.customName;
    };

    const getSubject = () => {
      return this.props.state.templateRefer.detail.subject;
    };

    const getBody = () => {
      return this.props.state.templateRefer.detail.body;
    };

    const getBodyHtml = () => {
      return this.props.state.templateRefer.detail.bodyHtml;
    };

    const isContactTypeMail = () => {
      return this.props.state.contactTypes.some(
        (type) =>
          type.value === this.props.state.templateRefer.detail.contactType &&
          type.isMail
      );
    };

    const isDefault = () => {
      return this.props.state.templateRefer.detail.isDefault;
    };

    return (
      <section className="section">
        <div className="section-header">
          <h1>通知テンプレート管理</h1>
        </div>

        <div className="section-body">
          <Row>
            <Col>
              <Card headerTitle="詳細">
                {this.props.state.isLoading ? (
                  <></>
                ) : (
                  <>
                    <ReferItem label="カテゴリー">
                      {getCategoryName()}
                    </ReferItem>
                    <ReferItem label="テンプレート名">
                      {getTemplateName()}
                    </ReferItem>
                    <ReferItem label="グループ名">{getGroupName()}</ReferItem>
                    <ReferItem label="通知媒体">
                      {getContactToolName()}
                    </ReferItem>
                    {!isDefault() && (
                      <ReferItem label="カスタム名">
                        {getCustomName()}
                      </ReferItem>
                    )}
                    <ReferItem label="件名">{getSubject()}</ReferItem>
                    <ReferItem label="本文">{getBody()}</ReferItem>
                    {isContactTypeMail() ? (
                      <ReferItem label="HTMLメール用本文">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: getBodyHtml(),
                          }}
                        />
                      </ReferItem>
                    ) : (
                      <></>
                    )}
                    <hr />
                    <Row>
                      <div className="mx-auto">
                        <PrevButton onClick={this.transitionToSearch} />
                        <ModifyButton onClick={this.transitionToEdit} />
                        <CopyButton onClick={this.transitionToNewEdit} />
                      </div>
                    </Row>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default TemplateRefer;

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  makeFetchAreas,
  makeFetchCategories,
  makeFetchTags,
  makeFetchBases,
  makeFetchEvents,
  makeFetchEventTypes,
  initState,
} from '../../../actions/Events';
import Necessities, { NecessitiesType } from '../../../domain/Necessities';
import EventTypes from '../../model/event/EventTypes';
import EditButton from '../../molecules/EditButton';
import HorizontalBadgesComponent from '../../molecules/HorizontalBadgesComponent';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import Card from '../../organisms/Card';

import routePath from './routePath';

class EventsRefer extends React.Component {
  static get propTypes() {
    return {
      values: PropTypes.any,
      state: PropTypes.shape({
        eventsRefer: PropTypes.shape({
          profileId: PropTypes.string,
          detail: PropTypes.shape({
            areaId: PropTypes.string,
            area: PropTypes.string,
            methodTypeName: PropTypes.string,
            webEventUrl: PropTypes.string,
            venueId: PropTypes.string,
            venue: PropTypes.string,
            content: PropTypes.string,
            name: PropTypes.string,
            kindId: PropTypes.array,
            kindName: PropTypes.string,
            categoryId: PropTypes.string,
            chairmanName: PropTypes.string,
            chargeBaseName: PropTypes.string,
            tags: PropTypes.array,
            tagsId: PropTypes.array,
            defaultMessage: PropTypes.string,
            dateYMDFrom: PropTypes.string,
            dateHHFrom: PropTypes.string,
            dateMMFrom: PropTypes.string,
            dateYMDTo: PropTypes.string,
            dateHHTo: PropTypes.number,
            dateMMTo: PropTypes.number,
            startAcceptingTime: PropTypes.number,
            openDateYMD: PropTypes.string,
            openDateHH: PropTypes.number,
            openDateMM: PropTypes.number,
            closeDateYMD: PropTypes.string,
            closeDateHH: PropTypes.number,
            closeDateMM: PropTypes.number,
            seatAvailability: PropTypes.number,
            isPaid: PropTypes.bool,
            price: PropTypes.number,
            attire: PropTypes.bool,
            attireDescription: PropTypes.string,
            necessities: PropTypes.bool,
            necessitiesDescription: PropTypes.string,
            staffId: PropTypes.string,
            staffName: PropTypes.string,
            chargeBaseId: PropTypes.string,
            companyName: PropTypes.string,
            displayAreaId: PropTypes.array,
            displayAreaName: PropTypes.arrayOf(PropTypes.string),
            isDisplaySeat: PropTypes.boolean,
            paidFlag: PropTypes.bool,
            displayAreas: PropTypes.array,
            createdBy: PropTypes.string,
          }),
        }),
        isLoading: PropTypes.bool,
      }),
      options: PropTypes.shape({
        areas: PropTypes.array,
        halls: PropTypes.array,
        categories: PropTypes.array,
        tags: PropTypes.array,
        eventTypes: PropTypes.array,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      handleSubmit: PropTypes.func,
      fetchAreas: PropTypes.func,
      fetchTags: PropTypes.func,
      fetchCategory: PropTypes.func,
      fetchEvents: PropTypes.func,
      fetchBases: PropTypes.func,
      initState: PropTypes.func,
      onPrevButtonClick: PropTypes.func,
      staffSearchModalShow: PropTypes.func,
      fetchCategories: PropTypes.func,
      fetchEventTypes: PropTypes.func,
      fetchReferItem: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
    this.isEditButtonEnable = this.isEditButtonEnable.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.fetchReferItem(this.id);
  }

  transitionToSearch() {
    this.props.history.push(routePath.search);
  }

  transitionToEdit() {
    this.props.history.push(routePath.edit(this.id));
  }

  onJaicStaffModalShow() {
    this.props.staffSearchModalShow();
  }

  isEditButtonEnable() {
    const endTime = moment(
      `${this.props.state.eventsRefer.detail.dateYMDTo} ${this.props.state.eventsRefer.detail.dateHHTo}:${this.props.state.eventsRefer.detail.dateMMTo}`,
      'YYYY/MM/DD hh:mm'
    );

    const now = moment();

    const expired = endTime.isBefore(now);

    return !expired;
  }

  render() {
    const getKind = () => {
      const id = this.props.state.eventsRefer.detail.kindId;

      const eventTypes = this.props.options.eventTypes.filter((eventType) => {
        return eventType.value === id;
      });

      if (eventTypes && eventTypes.length > 0) {
        return eventTypes[0].name;
      }

      return '';
    };

    const getMethodTypeName = () => {
      return this.props.state.eventsRefer.detail.methodTypeName;
    };

    const getWebEventUrl = () => {
      return this.props.state.eventsRefer.detail.webEventUrl;
    };

    const getContent = () => {
      return this.props.state.eventsRefer.detail.content;
    };

    const getName = () => {
      return this.props.state.eventsRefer.detail.name;
    };

    const getVenue = () => {
      return this.props.state.eventsRefer.detail.venue;
    };

    const getArea = () => {
      return this.props.state.eventsRefer.detail.area;
    };

    const isDisplayCategories = () => {
      const eventTypes = new EventTypes(this.props.options.eventTypes);
      return eventTypes.isSeminar(this.props.state.eventsRefer.detail.kindId);
    };

    const getCategories = () => {
      if (this.props.state.eventsRefer.detail.categoryId) {
        const categoryId = this.props.state.eventsRefer.detail.categoryId;
        const categories = this.props.options.categories
          .filter((category) => category.value === categoryId)
          .map((category) => category.name);

        if (categories.length > 0) {
          return categories[0];
        }
        return '';
      }
    };

    const getTags = () => {
      const tags = this.props.state.eventsRefer.detail.tags;
      if (tags && tags.length > 0) {
        return tags;
      }

      return [];
    };

    const getDefaultMessage = () => {
      return this.props.state.eventsRefer.detail.defaultMessage;
    };

    const getPeriodDate = () => {
      const values = this.props.state.eventsRefer.detail;
      if (
        values.dateYMDFrom != null &&
        values.dateHHFrom != null &&
        values.dateMMFrom != null &&
        values.dateHHTo != null &&
        values.dateMMTo != null
      ) {
        return (
          values.dateYMDFrom +
          ' ' +
          ('00' + values.dateHHFrom).substr(-2) +
          ':' +
          ('00' + values.dateMMFrom).substr(-2) +
          ' ~ ' +
          ('00' + values.dateHHTo).substr(-2) +
          ':' +
          ('00' + values.dateMMTo).substr(-2)
        );
      }
      return '';
    };

    const getStartAcceptingTime = () => {
      if (this.props.state.eventsRefer.detail.startAcceptingTime) {
        return this.props.state.eventsRefer.detail.startAcceptingTime;
      }
      return '0';
    };

    const getOpenDate = () => {
      const values = this.props.state.eventsRefer.detail;
      if (
        values.openDateYMD != null &&
        values.openDateHH != null &&
        values.openDateMM != null
      ) {
        return (
          values.openDateYMD +
          ' ' +
          ('00' + values.openDateHH).substr(-2) +
          ':' +
          ('00' + values.openDateMM).substr(-2)
        );
      }
      return '';
    };

    const getCloseDate = () => {
      const values = this.props.state.eventsRefer.detail;
      if (
        values.closeDateYMD != null &&
        values.closeDateHH != null &&
        values.closeDateMM != null
      ) {
        return (
          '~' +
          values.closeDateYMD +
          ' ' +
          ('00' + values.closeDateHH).substr(-2) +
          ':' +
          ('00' + values.closeDateMM).substr(-2)
        );
      }
      return '';
    };

    const getSeachAvailability = () => {
      return this.props.state.eventsRefer.detail.seatAvailability;
    };

    const getAttire = () => {
      if (this.props.state.eventsRefer.detail.attire) {
        return 'デフォルト(自由)';
      } else if (this.props.state.eventsRefer.detail.attire != null) {
        return (
          'その他【' +
          this.props.state.eventsRefer.detail.attireDescription +
          '】'
        );
      }

      return '';
    };

    const getNecessities = () => {
      let necessitiesType;
      if (this.props.state.eventsRefer.detail.necessities) {
        necessitiesType = new NecessitiesType.Default();
      } else if (this.props.state.eventsRefer.detail.necessities != null) {
        necessitiesType = new NecessitiesType.Other(
          this.props.state.eventsRefer.detail.necessitiesDescription
        );
      } else {
        return '';
      }

      return new Necessities(necessitiesType, isWebEvent()).displayName;
    };

    const getChairman = () => {
      return this.props.state.eventsRefer.detail.chairmanName;
    };

    const getChargeBaseId = () => {
      return this.props.state.eventsRefer.detail.chargeBaseName;
    };

    const getCompanyName = () => {
      return this.props.state.eventsRefer.detail.companyName;
    };

    const getDisplayArea = () => {
      const displayAreas = this.props.state.eventsRefer.detail.displayAreas;

      if (displayAreas != null && displayAreas.length > 0) {
        return displayAreas.map((displayArea) => {
          console.debug(
            displayArea.areaId,
            displayArea.areaName,
            displayArea.bases
          );

          const bases = displayArea.bases.map((base, index) => {
            return <BaseDiv key={index}>{base.baseName}</BaseDiv>;
          });

          const areaTag = (
            <div>
              {displayArea.areaName}
              {bases}
            </div>
          );

          return areaTag;
        });
      }

      return '';
    };

    const isShowDefaultMessage = () => {
      if (this.props.state.eventsRefer.detail.venueId) {
        return false;
      } else if (this.props.state.eventsRefer.detail.defaultMessage) {
        return true;
      }
      return false;
    };

    const getIsDisplaySeat = () => {
      if (this.props.state.eventsRefer.detail.isDisplaySeat != null) {
        if (this.props.state.eventsRefer.detail.isDisplaySeat) {
          return '表示する';
        } else {
          return '表示しない';
        }
      }
      return '';
    };

    const isWebEvent = () => {
      return (
        this.props.state.eventsRefer.detail.methodTypeName === 'オンライン開催'
      );
    };

    const isLoading = () => {
      return this.props.state.isLoading;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>イベント管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="詳細">
                  {isLoading() ? (
                    <></>
                  ) : (
                    <>
                      <ReferItem label="種別">{getKind()}</ReferItem>
                      <ReferItem label="開催方法">
                        {getMethodTypeName()}
                      </ReferItem>
                      <ReferItem label="ウェブイベント用URL">
                        <a
                          href={getWebEventUrl()}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {getWebEventUrl()}
                        </a>
                      </ReferItem>
                      <ReferItem label="イベント名">{getName()}</ReferItem>
                      <ReferItem label="エリア">{getArea()}</ReferItem>
                      {!isWebEvent() &&
                        (isShowDefaultMessage() ? (
                          <ReferItem label="会場未選択時表示文言">
                            {getDefaultMessage()}
                          </ReferItem>
                        ) : (
                          <ReferItem label="会場">{getVenue()}</ReferItem>
                        ))}
                      <ReferItem label="開催日時">{getPeriodDate()}</ReferItem>
                      <ReferItem label="受付開始時間">
                        {getStartAcceptingTime()}分前開場
                      </ReferItem>
                      <ReferItem label="公開開始日時">
                        {getOpenDate()}
                      </ReferItem>
                      <ReferItem label="公開終了日時">
                        {getCloseDate()}
                      </ReferItem>
                      <ReferItem label="定員">
                        {getSeachAvailability()}人
                      </ReferItem>
                      <ReferItem label="空席状況の表示">
                        {getIsDisplaySeat()}
                      </ReferItem>
                      <ReferItem label="服装">{getAttire()}</ReferItem>
                      <ReferItem label="持ち物">{getNecessities()}</ReferItem>
                      <ReferItem label="講師">{getChairman()}</ReferItem>
                      {isDisplayCategories() ? (
                        <ReferItem label="カテゴリー">
                          {getCategories()}
                        </ReferItem>
                      ) : (
                        <></>
                      )}
                      <ReferItem label="タグ">
                        <HorizontalBadgesComponent badges={getTags()} />
                      </ReferItem>
                      <ReferItem label="内容">{getContent()}</ReferItem>
                      <ReferItem label="担当拠点">
                        {getChargeBaseId()}
                      </ReferItem>
                      <ReferItem label="企業名">{getCompanyName()}</ReferItem>
                      <ReferItem label="表示エリア">
                        {getDisplayArea()}
                      </ReferItem>
                      <hr />
                      <Row>
                        <div className="mx-auto">
                          <PrevButton onClick={this.transitionToSearch} />
                          <EditButton
                            onClick={this.transitionToEdit}
                            isEnabled={this.isEditButtonEnable}
                          />
                        </div>
                      </Row>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </>
    );
  }
}

const BaseDiv = styled.div`
  margin-left: 3em;
`;

export default connect(
  (state) => {
    return {
      ...state,
      options: state.options,
    };
  },
  (dispatch) => {
    return {
      fetchAreas() {
        dispatch(makeFetchAreas());
      },
      fetchBases() {
        dispatch(makeFetchBases());
      },
      fetchCategories() {
        dispatch(makeFetchCategories());
      },
      fetchTags() {
        dispatch(makeFetchTags());
      },
      fetchEvents(id) {
        dispatch(makeFetchEvents(id));
      },
      fetchEventTypes() {
        dispatch(makeFetchEventTypes());
      },
      initState() {
        dispatch(initState());
      },
    };
  }
)(EventsRefer);

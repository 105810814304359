import { array, bool, func, object, shape } from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import FormLabel from '../../atoms/FormLabel';
import { FromZipcode } from '../../model/Zipcode';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import RegisterButton from '../../molecules/RegisterButton';
import ReferAccess from '../../organisms/base/ReferAccess';

class VenueEditConfirmForm extends React.Component {
  static get propTypes() {
    return {
      backToInput: func,
      options: shape({
        areas: array,
        bases: array,
        divisions: array,
        occupations: array,
        prefs: array,
        roles: array,
      }),
      onSubmit: func,
      state: shape({
        role: shape({ isSystemAdmin: bool }),
        isSubmitting: bool,
      }),
      values: object,
    };
  }

  render() {
    const getName = () => {
      return `${this.props.values.name}`;
    };

    const getMail = () => {
      return this.props.values.mail;
    };

    const getTel = () => {
      return `${this.props.values.tel1}-${this.props.values.tel2}-${this.props.values.tel3}`;
    };

    const getArea = () => {
      console.debug('area: ', this.props.options.areas);

      const areas = this.props.options.areas
        .filter((area) => area.value === this.props.values.areaId)
        .map((area) => area.name);

      if (areas.length > 0) {
        return areas[0];
      }

      return this.props.values.areaId;
    };

    const getBase = () => {
      console.debug('base: ', this.props.options.bases);

      const bases = this.props.options.bases
        .filter((base) => base.value === this.props.values.baseId)
        .map((base) => base.name);

      if (bases.length > 0) {
        return bases[0];
      }

      return this.props.values.baseId;
    };

    const getPrefs = () => {
      console.debug('prefs: ', this.props.options.prefs);

      const prefs = this.props.options.prefs
        .filter((pref) => pref.value === this.props.values.prefId)
        .map((pref) => pref.name);

      if (prefs.length > 0) {
        return prefs[0];
      }

      return this.props.values.prefId;
    };

    const getZipCode = () => {
      const zipcode = FromZipcode(
        this.props.values.zipCode1,
        this.props.values.zipCode2
      );
      return zipcode.toString();
    };

    const getAccessList = () => {
      return this.props.values.accessList;
    };

    return (
      <>
        <ReferItem label="名前">{getName()}</ReferItem>
        <ReferItem label="エリア">{getArea()}</ReferItem>
        <ReferItem label="拠点">{getBase()}</ReferItem>
        {/* 住所 */}
        <ReferItem label="郵便番号">{getZipCode()}</ReferItem>
        <ReferItem label="都道府県">{getPrefs()}</ReferItem>
        <ReferItem label="市区町村">{this.props.values.city}</ReferItem>
        <ReferItem label="建物名">{this.props.values.buildingName}</ReferItem>
        <ReferItem label="電話番号">{getTel()}</ReferItem>
        <ReferItem label="メールアドレス">{getMail()}</ReferItem>
        <FormLabel className="col-sm-3 col-form-label">地図</FormLabel>
        <ReferItem label="通常URL">
          <a
            href={this.props.values.googleMapUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.props.values.googleMapUrl}
          </a>
        </ReferItem>
        <ReferItem label="短縮URL">
          <a
            href={this.props.values.googleMapShortUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.props.values.googleMapShortUrl}
          </a>
        </ReferItem>
        <ReferAccess accessList={getAccessList()} />
        <ReferItem label="最寄駅">{this.props.values.nearestStation}</ReferItem>

        <hr />
        <Row>
          <div className="mx-auto">
            <PrevButton onClick={this.props.backToInput} />
            <RegisterButton
              onClick={this.props.onSubmit}
              isSubmitting={this.props.state.isSubmitting}
            />
          </div>
        </Row>
      </>
    );
  }
}

export default connect(
  (state) => {
    return { state: state.staff.staffEdit, options: state.options };
  },
  (dispatch) => {
    return {};
  }
)(VenueEditConfirmForm);

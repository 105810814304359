import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from './NormalButton';

class LightButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <NormalButton color="light" {...this.props}>
        {this.props.children}
      </NormalButton>
    );
  }
}

export default LightButton;

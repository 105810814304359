import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';
import styled from 'styled-components';

class FormHour extends React.Component {
  static get propTypes() {
    return {
      placeholder: PropTypes.string,
      onChange: PropTypes.func,
      value: PropTypes.string,
      defaultValue: PropTypes.string,
      className: PropTypes.string,
      input: PropTypes.any,
      meta: PropTypes.any,
      isDisable: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    console.debug('forminput props', props);
  }

  render() {
    const { input, meta, ...other } = this.props;
    console.debug('form hour', input);
    return (
      <div className="input-group mb-2">
        <ReduceFormControl
          type="number"
          {...this.props.input}
          {...other}
          disabled={this.props.isDisable}
          step="1"
          min="0"
          max="23"
        ></ReduceFormControl>
        <div className="input-group-append">
          <ReducePaddingText className="input-group-text">時</ReducePaddingText>
        </div>
      </div>
    );
  }
}

const ReduceFormControl = styled(FormControl)`
  max-width: 5em;
  min-width: 2em;
  padding: 10px 5px !important;
  display: inline-block;
  text-align: center;
`;

const ReducePaddingText = styled.div`
  padding: 10px 5px;
`;

export default FormHour;

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import SearchButton from '../../molecules/SearchButton';

class TagSearchForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      options: PropTypes.shape({
        areas: PropTypes.array,
        bases: PropTypes.array,
        occupations: PropTypes.array,
        roles: PropTypes.array,
      }),
      state: PropTypes.shape({
        isSystemAdmin: PropTypes.bool,
      }),
    };
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent
            label="名前"
            name="name"
            component="input"
            type="text"
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton
                isEnabled={() => {
                  return false;
                }}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
export default connect(
  (state) => {
    const values = state.staff.staffSearch.searchValues.values || {};

    return {
      options: state.options,
      initialValues: {
        ...values,
        occupationId: values.occupationId || [],
      },
      state: {
        isSystemAdmin: state.staff.staffRefer.isSystemAdmin,
      },
    };
  },
  () => {}
)(
  reduxForm({
    // a unique name for the form
    form: 'tagSearchForm',
  })(TagSearchForm)
);

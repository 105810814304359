import AdminPasswordResetcomponent from '../containers/AdminPasswordReset';
import AdminProfileComponent from '../containers/AdminProfile';
import BaseCompnent from '../containers/Base';
import CategoryComponent from '../containers/Category';
import CompanyComponent from '../containers/Company';
import ConsultationPlanCompnent from '../containers/ConsultationPlan';
import EventsComponent from '../containers/Events';
import FormManagement from '../containers/FormManagement';
import LoginComponent from '../containers/Login';
import ReservationManagement from '../containers/ReservationManagement';
import RoleManagementComponent from '../containers/RoleManagement';
import SampleComponent from '../containers/Sample';
import SettingPasswordComponent from '../containers/SettingPassword';
import StaffCompnent from '../containers/Staff';
import SummaryCompnent from '../containers/Summary';
import TagComponent from '../containers/Tag';
import TemplateManagementComponent from '../containers/Template';
import UserCompnent from '../containers/User';
import VenueComponent from '../containers/venueManagement/Venue';

import adminPasswordResetRoutes from './pages/adminPasswordReset/AdminPasswordResetRoutes';
import adminProfileRoutes from './pages/adminProfile/AdminProfileRoutes';
import baseRoutes from './pages/base/BaseRoutes';
import categoryRoutes from './pages/category/CategoryRoutes';
import error from './pages/common/Error';
import Forbidden from './pages/common/Forbidden';
import notFound from './pages/common/NotFound';
import companyRoutes from './pages/company/CompanyRoutes';
import consultationPlanRoutes from './pages/consultationPlan/ConsultationPlanRoutes';
import dashboard from './pages/Dashboard';
import EventsRoutes from './pages/events/EventsRoutes';
import formManagementRoutes from './pages/formManagement/FormManagementRoutes';
import loginRoutes from './pages/login/LoginRoutes';
import reservationManagementRoutes from './pages/reservationManagement/ReservationManagementRoutes';
import roleManagementRoutes from './pages/roleManagement/RoleManagementRoutes';
import sampleRoutes from './pages/sample/SampleRoutes';
import settingPasswordRoutes from './pages/settingPassword/SettingPasswordRoutes';
import staffRoutes from './pages/staff/StaffRoutes';
import summaryRoutes from './pages/summary/SummaryRoutes';
import tagRoutes from './pages/tag/TagRoutes';
import templateRoutes from './pages/template/TemplateRoutes';
import userRoutes from './pages/user/UserRoutes';
import veuneRoutes from './pages/venueManagement/VenueRoutes';

const routes = [
  {
    path: '/',
    exact: true,
    component: dashboard,
  },
  {
    path: '/403',
    exact: true,
    component: Forbidden,
  },
  {
    path: '/404',
    exact: true,
    component: notFound,
  },
  {
    path: '/500',
    exact: true,
    component: error,
  },
  {
    path: '/sample',
    component: SampleComponent,
    routes: sampleRoutes,
  },
  {
    path: '/admin/role',
    component: RoleManagementComponent,
    routes: roleManagementRoutes,
  },
  {
    path: '/admin/reservation',
    component: ReservationManagement,
    routes: reservationManagementRoutes,
  },
  {
    path: '/admin/template',
    component: TemplateManagementComponent,
    routes: templateRoutes,
  },
  {
    path: '/admin/bases',
    component: BaseCompnent,
    routes: baseRoutes,
  },
  {
    path: '/admin/profile',
    component: AdminProfileComponent,
    routes: adminProfileRoutes,
  },
  {
    path: '/admin/staff',
    component: StaffCompnent,
    routes: staffRoutes,
  },
  {
    path: '/template',
    component: StaffCompnent,
    routes: templateRoutes,
  },

  {
    path: '/admin/form',
    component: FormManagement,
    routes: formManagementRoutes,
  },
  {
    path: '/login',
    component: LoginComponent,
    routes: loginRoutes,
  },
  {
    path: '/admin/interviews',
    component: ConsultationPlanCompnent,
    routes: consultationPlanRoutes,
  },
  {
    path: '/admin/events',
    component: EventsComponent,
    routes: EventsRoutes,
  },
  {
    path: '/admin/password_reset',
    component: AdminPasswordResetcomponent,
    routes: adminPasswordResetRoutes,
  },
  {
    path: '/admin/summary',
    component: SummaryCompnent,
    routes: summaryRoutes,
  },
  {
    path: '/admin/users',
    component: UserCompnent,
    routes: userRoutes,
  },
  {
    path: '/admin/venues',
    component: VenueComponent,
    routes: veuneRoutes,
  },
  {
    path: '/admin/setting_password',
    component: SettingPasswordComponent,
    routes: settingPasswordRoutes,
  },
  {
    path: '/admin/tag',
    component: TagComponent,
    routes: tagRoutes,
  },
  {
    path: '/admin/category',
    component: CategoryComponent,
    routes: categoryRoutes,
  },
  {
    path: '/admin/company',
    component: CompanyComponent,
    routes: companyRoutes,
  },
  {
    path: '*',
    component: notFound,
  },
];

export default routes;

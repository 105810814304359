import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import CopyButton from '../../molecules/CopyButton';
import ModifyButton from '../../molecules/ModifyButton';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import Card from '../../organisms/Card';
import CopyCompleteModal from '../../organisms/roleManagement/CopyCompleteModal';
import CopyConfirmModal from '../../organisms/roleManagement/CopyConfirmModal';
import ReferRoles from '../../organisms/roleManagement/ReferRoles';

import routePath from './routePath';

class RoleManagementRefer extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      fetchRole: PropTypes.func,
      fetchMenus: PropTypes.func,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        roleSearch: PropTypes.shape({
          values: PropTypes.object,
          searchParams: PropTypes.object,
        }),
        roleRefer: PropTypes.shape({
          detail: PropTypes.shape({
            name: PropTypes.string,
            isSystemAdmin: PropTypes.bool,
            initMenuId: PropTypes.string,
            roles: PropTypes.array,
            menus: PropTypes.array,
          }),
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      roleCopy: PropTypes.shape({
        isCopyConfirm: PropTypes.bool,
        isCopyComplete: PropTypes.bool,
      }),
      search: PropTypes.func,
      copyRole: PropTypes.func,
      showCopyConfirm: PropTypes.func,
      closeCopyConfirm: PropTypes.func,
      closeCopyComplete: PropTypes.func,
      fetchRoleItemForRefer: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
    this.copyButtonClick = this.copyButtonClick.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.fetchRoleItemForRefer(this.id);
  }

  transitionToSearch() {
    this.props.history.push(routePath.search);
  }

  transitionToEdit() {
    this.props.history.push(routePath.edit(this.id));
  }

  copyButtonClick() {
    this.props.showCopyConfirm();
  }

  // 確認ダイアログ_YES
  onConfirmYesClick() {
    this.props.copyRole(this.id);
  }

  // 確認ダイアログ_YES
  onConfirmNoClick() {
    this.props.closeCopyConfirm(this.id);
  }

  // 完了ダイアログ_CLOSE
  onCompleteCloseClick() {
    const values = this.props.state.roleSearch.values;
    const searchParams = this.props.state.roleSearch.searchParams;

    if (
      Object.keys(values).length > 0 ||
      Object.keys(searchParams).length > 0
    ) {
      this.props.search(values, searchParams);
    }

    this.props.closeCopyComplete();
  }

  render() {
    const getName = () => {
      return this.props.state.roleRefer.detail.name;
    };

    const getIsSystemAdmin = () => {
      if (this.props.state.roleRefer.detail.isSystemAdmin) {
        return 'システム管理者';
      }
      return 'なし';
    };

    const getInitMenu = () => {
      if (this.props.state.roleRefer.detail.initMenuId) {
        const initMenuId = this.props.state.roleRefer.detail.initMenuId;
        const filteredMenus = this.props.state.roleRefer.detail.menus.flatMap(
          (section) => {
            return section.menus
              .filter((menu) => {
                return menu.menuId === initMenuId;
              })
              .map((menu) => {
                return menu.menuName;
              });
          }
        );
        if (filteredMenus.length > 0) {
          return filteredMenus[0];
        }
      }
      return '';
    };

    const getRoles = () => {
      return this.props.state.roleRefer.detail.roles || [];
    };

    const getMenus = () => {
      return this.props.state.roleRefer.detail.menus;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>ロール管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="詳細">
                  {this.props.state.isLoading ? (
                    <></>
                  ) : (
                    <>
                      <Col sm={{ offset: 1 }}>
                        <ReferItem label="名前">{getName()}</ReferItem>
                      </Col>
                      <Col sm={{ offset: 1 }}>
                        <ReferItem label="システム管理者フラグ">
                          {getIsSystemAdmin()}
                        </ReferItem>
                      </Col>
                      <Col sm={{ offset: 1 }}>
                        <ReferItem label="ログイン時初期表示機能">
                          {getInitMenu()}
                        </ReferItem>
                      </Col>
                      <ReferRoles
                        RoleList={getRoles()}
                        menus={getMenus()}
                      ></ReferRoles>

                      <hr />
                      <Row>
                        <div className="mx-auto">
                          <PrevButton onClick={this.transitionToSearch} />
                          <ModifyButton onClick={this.transitionToEdit} />
                          <CopyButton onClick={this.copyButtonClick} />
                        </div>
                      </Row>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <CopyConfirmModal
          show={this.props.roleCopy.isCopyConfirm}
          onYesClick={this.onConfirmYesClick.bind(this)}
          onNoClick={this.onConfirmNoClick.bind(this)}
        />
        <CopyCompleteModal
          show={this.props.roleCopy.isCopyComplete}
          onCloseClick={this.onCompleteCloseClick.bind(this)}
        />
      </>
    );
  }
}

export default connect((state) => {
  return {
    ...state,
    roleCopy: {
      ...state.roleManagement.roleCopy,
      isCopyConfirm: state.roleManagement.roleCopy.isCopyConfirm,
      isCopyComplete: state.roleManagement.roleCopy.isCopyComplete,
    },
  };
})(RoleManagementRefer);

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Card from '../../organisms/Card';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import ConfirmForm from '../../organisms/staff/StaffEditConfirmForm';
import Form from '../../organisms/staff/StaffEditForm';

import routes from './routePath';

class StaffEdit extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        staffSearch: PropTypes.shape({
          searchValues: PropTypes.shape({
            values: PropTypes.object,
            searchParams: PropTypes.object,
          }),
        }),
        staffEdit: PropTypes.shape({
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
          newStaffId: PropTypes.string,
          isNewData: PropTypes.bool,
        }),
      }),
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      updateStaff: PropTypes.func,
      fetchStaffEditData: PropTypes.func,
      initState: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      backToInput: PropTypes.func,
      setEditValues: PropTypes.func,
      search: PropTypes.func,
      initReferState: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
    this.transitionToRefer = this.transitionToRefer.bind(this);
    this.backToInput = this.backToInput.bind(this);
    this.submit = this.submit.bind(this);
    this.transitionToBack = this.transitionToBack.bind(this);
    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.isNew = this.isNew.bind(this);
    this.onCloseCompleteModal = this.onCloseCompleteModal.bind(this);

    this.state = { isConfirm: false, values: {} };
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.fetchStaffEditData(this.id);
  }

  isNew() {
    console.debug('StaffEdit isNew: ', !this.id);
    return !this.id;
  }

  confirm(values) {
    console.debug('values', values);

    this.setState({ values: values, isConfirm: true });

    this.props.setEditValues(values);
  }

  submit() {
    console.debug('submit values: ', this.state.values);

    this.props.updateStaff(this.state.values);
  }

  onCloseCompleteModal() {
    const { values, searchParams } = this.props.state.staffSearch.searchValues;
    console.debug('onCloseCompleteModal', values, searchParams);
    if (
      (values && Object.keys(values).length > 0) ||
      (searchParams && Object.keys(searchParams).length > 0)
    ) {
      this.props.search(values, searchParams);
    }

    this.transitionToRefer();
  }

  transitionToRefer() {
    const id = this.props.state.staffEdit.newStaffId;

    if (
      this.props.state.staffEdit.isNewData &&
      this.props.state.staffEdit.isComplete
    ) {
      this.props.history.push(routes.refer(id));
      return;
    }
    this.props.history.push(routes.refer(this.id));

    this.props.initReferState();
  }

  transitionToSearch() {
    this.props.history.push(routes.search);
  }

  transitionToBack() {
    if (this.id == null) {
      this.transitionToSearch();
    } else {
      this.transitionToRefer();
    }
  }

  backToInput() {
    this.setState({ isConfirm: false });
    this.props.backToInput();
  }

  render() {
    const isConfirm = () => {
      return this.props.state.staffEdit.isConfirm;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>社員管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="編集">
                  {isConfirm() ? (
                    <ConfirmForm
                      onSubmit={this.submit}
                      backToInput={this.backToInput}
                      values={this.state.values}
                      isNew={this.isNew()}
                    />
                  ) : (
                    <Form
                      onSubmit={this.confirm}
                      onPrevButtonClick={this.transitionToBack}
                      isNew={this.isNew()}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <RegisterCompleteModal
          show={this.props.state.staffEdit.isComplete}
          onCloseClick={this.onCloseCompleteModal}
        />
      </>
    );
  }
}

export default StaffEdit;

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import PrimaryFormButton from '../../atoms/PrimaryFormButton';
import MarginForm, { MarginSize } from '../../molecules/MarginFormComponent';
import * as Validator from '../../utils/Validate';

import PasswordInputComponent from './PasswordInputComponent';

class SettingPasswordEditForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      onUpdate: PropTypes.func,
      invalid: PropTypes.bool,
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      formState: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
      state: PropTypes.shape({
        messages: PropTypes.shape({
          general: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        {this.props.state.messages != null &&
        this.props.state.messages.general &&
        this.props.state.messages.general.length > 0 ? (
          <div className="alert alert-danger">
            {this.props.state.messages.general.map((message, i) => (
              <p key={i}>{message}</p>
            ))}
          </div>
        ) : (
          <></>
        )}
        <MarginForm margin={MarginSize.L}>
          <div className="l-width">
            <div className="p-detail_info -profile">
              <dl className="c-form">
                <dt className="-lbl">現在のパスワード</dt>
                <dd>
                  <PasswordInputComponent
                    name="currentPassword"
                    placeholder="入力してください"
                    validate={[Validator.required]}
                  />
                </dd>
              </dl>
              <dl className="c-form">
                <dt className="-lbl">新しいパスワード</dt>
                <dd>
                  <PasswordInputComponent
                    name="newPassword"
                    placeholder="入力してください"
                    validate={[Validator.required, Validator.password]}
                  />
                </dd>
              </dl>
              <dl className="c-form">
                <dt className="-lbl">新しいパスワード(確認用)</dt>
                <dd>
                  <PasswordInputComponent
                    name="newPasswordConfirm"
                    placeholder="入力してください"
                    validate={[Validator.required, Validator.password]}
                  />
                </dd>
              </dl>
            </div>
            <Row>
              <Col>
                <div className="text-center">
                  <PrimaryFormButton>変更</PrimaryFormButton>
                </div>
              </Col>
            </Row>
          </div>
        </MarginForm>
      </Form>
    );
  }
}

const settingPasswordEditForm = reduxForm({
  // a unique name for the form
  form: 'settingPasswordEditForm',
  enableReinitialize: true,
})(SettingPasswordEditForm);

export default connect((state) => {
  return {
    state: {
      messages: state.updatePassword.updatePasswordEdit.messages,
    },
    initialValues: {
      ...state.updatePassword.updatePasswordEdit.updatePasswordData,
    },
  };
})(settingPasswordEditForm);

import { connect } from 'react-redux';

import { makeFetchLogin } from '../actions/Login';
import LoginComponent from '../components/pages/login/login';
import LoginEditComponent from '../components/pages/login/LoginEdit';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.login,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    staffLogin(values) {
      dispatch(makeFetchLogin(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);

const LoginEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginEditComponent);

export { LoginEdit };

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { makeFetchAdminProfile } from '../../../actions/AdminProfile';
import CancelButton from '../../molecules/CancelButton';
import ConfirmButton from '../../molecules/ConfirmButton';
import FormTelComponent from '../../molecules/FormTelComponent';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import ProfileImage, {
  ProfileImageFieldParam,
} from '../../molecules/ProfileImage';
import NameInput from '../../organisms/adminProfile/NameInputComponent';

class AdminProfileEditForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      fetchAdminProfile: PropTypes.func,
      fetchBases: PropTypes.func,
      options: PropTypes.shape({
        bases: PropTypes.array,
      }),
      onPrevButtonClick: PropTypes.func,
      uploadProfile: PropTypes.func,
      initialValues: PropTypes.any,
      formState: PropTypes.any,
    };
  }

  constructor(props) {
    super(props);

    this.uploadProfile = this.uploadProfile.bind(this);
    this.state = { isUploadedFile: false };
  }

  uploadProfile(isUploaded) {
    this.setState({ isUploadedFile: isUploaded });
  }

  render() {
    const getProfileImageURL = () => {
      console.debug('getProfileImageURL', this.props.initialValues);
      if (!this.props.formState || !this.props.formState.values) {
        return null;
      }

      if (this.props.formState.values.profileImageTempId) {
        return `${process.env.REACT_APP_API_URI}/static/tmp/${this.props.formState.values.profileImageTempId}`;
      } else if (this.props.formState.values.profileImageId) {
        return `${process.env.REACT_APP_API_URI}/${this.props.formState.values.profileImgURL}`;
      }
    };

    console.debug('props', this.props);
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <NameInput label="名前" />

          <ProfileImage
            fieldName={
              new ProfileImageFieldParam(
                'profileImageTempId',
                'profileImageId',
                'profileImgURL'
              )
            }
            form="adminProfileEditForm"
            referer="/admin/profile"
            label="プロフィール画像"
            onChange={this.uploadProfile}
            url={getProfileImageURL()}
          />

          <HorizontalFormInputComponent
            label="メールアドレス"
            name="mailAddress"
            component="input"
            type="text"
          />
          <FormTelComponent label="電話番号" name="tel" />
          <HorizontalSelectComponent
            label="拠点"
            options={this.props.options.bases}
            name="baseId"
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <CancelButton onClick={this.props.onPrevButtonClick} />
              <ConfirmButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const adminProfileEditForm = reduxForm({
  // a unique name for the form
  form: 'adminProfileEditForm',
  enableReinitialize: true,
})(AdminProfileEditForm);

export default connect(
  (state) => {
    console.debug('admin profile edit form state', state);
    const tel1Number = (tel) => {
      if (tel == null) {
        return '';
      }
      const index = tel.indexOf('-');

      return tel.slice(0, index);
    };

    const tel2Number = (tel) => {
      if (tel == null) {
        return '';
      }

      const firstSplitterIndex = tel.indexOf('-');
      const secondSplitterIndex = tel.indexOf('-', firstSplitterIndex + 1);
      return tel.slice(firstSplitterIndex + 1, secondSplitterIndex);
    };

    const tel3Number = (tel) => {
      if (tel == null) {
        return '';
      }

      const firstSplitterIndex = tel.indexOf('-');
      const secondSplitterIndex = tel.indexOf('-', firstSplitterIndex + 1);
      return tel.slice(secondSplitterIndex + 1);
    };

    console.debug('connect', state.adminProfile.adminProfileRefer.detail);

    let profileImageId;
    if (state.adminProfile.adminProfileRefer.detail.profileImgURL) {
      const profileImgURL =
        state.adminProfile.adminProfileRefer.detail.profileImgURL;
      const profileImageIds = profileImgURL.split('/');
      profileImageId = profileImageIds[profileImageIds.length - 1];
    }

    return {
      initialValues: {
        ...state.adminProfile.adminProfileRefer.detail,
        familyName: state.adminProfile.adminProfileRefer.detail.familyName,
        firstName: state.adminProfile.adminProfileRefer.detail.firstName,
        tel1: tel1Number(
          state.adminProfile.adminProfileRefer.detail.phoneNumber
        ),
        tel2: tel2Number(
          state.adminProfile.adminProfileRefer.detail.phoneNumber
        ),
        tel3: tel3Number(
          state.adminProfile.adminProfileRefer.detail.phoneNumber
        ),
        profileImageId: profileImageId,
      },
      formState: state.form.adminProfileEditForm,
      options: state.options,
    };
  },
  (dispatch) => {
    return {
      fetchAdminProfile() {
        dispatch(makeFetchAdminProfile());
      },
    };
  }
)(adminProfileEditForm);

import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import {
  makeFetchTemplateCategories,
  makeFetchTemplateNames,
  makeFetchTemplateGroups, makeFetchContactTypes,
} from '../../../actions/Template';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import RegisterButton from '../../molecules/RegisterButton';

class TemplateEditConfirmForm extends React.Component {
  static get propTypes() {
    return {
      onSubmit: PropTypes.func,
      backToInput: PropTypes.func,
      values: PropTypes.any,
      state: PropTypes.shape({
        role: PropTypes.shape({ isSystemAdmin: PropTypes.bool }),
        isSubmitting: PropTypes.bool,
      }),
      options: PropTypes.shape({
        templateCategories: PropTypes.array,
        templateNames: PropTypes.array,
        templateGroups: PropTypes.array,
      }),
      fetchTemplateCategories: PropTypes.func,
      fetchTemplateNames: PropTypes.func,
      fetchTemplateGroups: PropTypes.func,
    };
  }

  componentDidMount() {
    this.props.fetchTemplateCategories();
    this.props.fetchTemplateNames();
    this.props.fetchTemplateGroups();
    this.props.fetchContactTypes();
  }

  render() {
    const getCategoryName = () => {
      console.debug(
        'templateCategories: ',
        this.props.options.templateCategories
      );

      const templateCategories = this.props.options.templateCategories
        .filter(
          (templateCategory) =>
            templateCategory.value === this.props.values.categoryId
        )
        .map((templateCategory) => templateCategory.name);

      if (templateCategories.length > 0) {
        return templateCategories[0];
      }

      return this.props.values.categoryId;
    };

    const getTemplateName = () => {
      console.debug('templateNames: ', this.props.options.templateNames);

      const templateNames = this.props.options.templateNames
        .filter(
          (templateName) =>
            templateName.value === this.props.values.templateNameId
        )
        .map((templateName) => templateName.name);

      if (templateNames.length > 0) {
        return templateNames[0];
      }

      return this.props.values.templateNameId;
    };

    const getGroup = () => {
      if (this.props.values.groupId === '0') {
        return this.props.values.newGroupName;
      }

      console.debug('templateGroups: ', this.props.options.templateGroups);

      const templateGroups = this.props.options.templateGroups
        .filter(
          (templateGroup) => templateGroup.value === this.props.values.groupId
        )
        .map((templateGroup) => templateGroup.name);

      if (templateGroups.length > 0) {
        return templateGroups[0];
      }

      return this.props.values.groupId;
    };

    const getContactType = () => {
      return this.props.optionContactTypes.find(type => type.value === this.props.values.contactType).label;
    };

    const getCustomName = () => {
      return this.props.values.customName;
    };

    const getSubject = () => {
      return this.props.values.subject;
    };

    const getBody = () => {
      return this.props.values.body;
    };

    const getBodyHtml = () => {
      return this.props.values.bodyHtml;
    };

    const isContactTypeMail = () => {
      return this.props.optionContactTypes.some(type => type.value === this.props.values.contactType && type.isMail);
    };

    return (
      <>
        <ReferItem label="カテゴリー">{getCategoryName()}</ReferItem>
        <ReferItem label="テンプレート名">{getTemplateName()}</ReferItem>
        <ReferItem label="グループ名">{getGroup()}</ReferItem>
        <ReferItem label="通知媒体">{getContactType()}</ReferItem>
        {
          this.props.isCopy
          &&
          <ReferItem label="カスタム名">{getCustomName()}</ReferItem>
        }
        <ReferItem label="件名">{getSubject()}</ReferItem>
        <ReferItem label="本文">{getBody()}</ReferItem>
        {isContactTypeMail() ? (
          <ReferItem label="HTMLメール用本文">
            <div
              dangerouslySetInnerHTML={{
                __html: getBodyHtml()
              }}
            />
          </ReferItem>
        ) : (
          <></>
        )}
        <hr />
        <Row>
          <div className="mx-auto">
            <PrevButton onClick={this.props.backToInput} />
            <RegisterButton
              onClick={this.props.onSubmit}
              isSubmitting={this.props.state.isSubmitting}
            />
          </div>
        </Row>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      state: state.template.templateEdit,
      options: state.options,
      optionContactTypes: state.template.contactTypes,
    };
  },
  (dispatch) => {
    return {
      fetchTemplateCategories() {
        dispatch(makeFetchTemplateCategories());
      },
      fetchTemplateNames(id) {
        dispatch(makeFetchTemplateNames(id));
      },
      fetchTemplateGroups() {
        dispatch(makeFetchTemplateGroups());
      },
      fetchContactTypes() {
        dispatch(makeFetchContactTypes());
      },
    };
  }
)(TemplateEditConfirmForm);

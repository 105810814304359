import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Field } from 'redux-form';

import FormLabel from '../atoms/FormLabel';
import SearchAddressButton from '../organisms/venueManagement/SearchAddressButton';
import * as Validator from '../utils/Validate';

class FormZipCodeComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      buttonLabel: PropTypes.string,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
      errorMessages: PropTypes.array,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  fieldComponent(field) {
    return (
      <>
        <Form.Control
          type="text"
          {...field.input}
          maxlength={field.maxlength}
          isInvalid={field.meta.touched && field.meta.error}
        />
        {field.meta.touched && field.meta.error && (
          <Form.Control.Feedback type="invalid">
            {field.meta.error}
          </Form.Control.Feedback>
        )}
      </>
    );
  }

  component() {
    return (
      <div className="form-group row">
        {this.props.name ? (
          <>
            <Element name={`position-${this.props.name}1`} />
            <Element name={`position-${this.props.name}2`} />
          </>
        ) : (
          <></>
        )}
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          <span className="text-danger">*</span>
        </FormLabel>
        <div className="col-sm-9">
          <Row>
            <Col>
              <Field
                name={this.props.name + '1'}
                className="form-control"
                type="text"
                maxlength={3}
                component={this.fieldComponent}
                validate={[
                  Validator.required,
                  Validator.number,
                  customZipcodePrefixValidate,
                ]}
              />
            </Col>
            <FormLabel className="col-form-label">-</FormLabel>
            <Col>
              <Field
                name={this.props.name + '2'}
                className="form-control"
                type="text"
                maxlength={4}
                component={this.fieldComponent}
                validate={[
                  Validator.required,
                  Validator.number,
                  customZipcodeSuffixValidate,
                ]}
              />
            </Col>
            <Col>
              <SearchAddressButton onClick={this.props.onClick}>
                {this.props.buttonLabel}
              </SearchAddressButton>
            </Col>
          </Row>
          {this.props.errorMessages != null &&
          this.props.errorMessages.length > 0 ? (
            <div className="alert alert-danger">
              {this.props.errorMessages.map((message, i) => (
                <p key={i}>{message}</p>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }

  render() {
    return this.component();
  }
}

const customZipcodeValidate = (length) => (value) => {
  const validate =
    value && value.length !== length
      ? `${length}文字で入力してください`
      : undefined;
  return validate;
};

const customZipcodePrefixValidate = customZipcodeValidate(3);

const customZipcodeSuffixValidate = customZipcodeValidate(4);

export default FormZipCodeComponent;

import PropTypes, { func, shape, string } from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import {
  clearSuggestion,
  makeFetchStaffForSuggestion,
  makeFetchProfile,
} from '../../../actions/ConsultationPlan';
import Suggest, { Param as SuggestParam } from '../../atoms/Suggest';
import BulkRegisterButton from '../../molecules/BulkRegisterButton';
import MineButton from '../../molecules/MineButton';
import { AllowOwner } from '../../utils/PermissionComponent';

class ConsultationPlan extends React.Component {
  static get propTypes() {
    return {
      state: shape({
        profile: shape({
          familyName: string,
          firstName: string,
        }),
      }),
      handleSubmit: PropTypes.func,
      changeStaff: func,
      fetchStaff: PropTypes.func,
      bulkRegisterButtonClick: PropTypes.func,
      clearSuggestion: PropTypes.func,
      currentSelectedStaffId: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      isFirstSearch: true,
    };

    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    );
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    );
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClickMineButton = this.onClickMineButton.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.isFirstSearch && state.value === '') {
      const staffName =
        nextProps.state.profile != null
          ? nextProps.state.profile.familyName +
            ' ' +
            nextProps.state.profile.firstName
          : '';

      nextProps.changeStaff(null, staffName);

      return {
        value: staffName,
        isFirstSearch: staffName === '',
      };
    }

    return {};
  }

  onSuggestionsFetchRequested({ value }) {
    this.props.fetchStaff(value);
  }

  onSuggestionsClearRequested() {
    this.props.clearSuggestion();
  }

  onSuggestionSelected(suggestion) {
    console.debug('suggest', suggestion.value);

    this.props.changeStaff(suggestion.value, suggestion.name);
  }

  onChange(event, { newValue }) {
    console.debug('staff serach modal modal newValue', newValue);
    this.setState({
      ...this.state,
      value: newValue,
    });
  }

  onClickMineButton() {
    const staffName =
      this.props.state.profile != null
        ? this.props.state.profile.familyName +
          ' ' +
          this.props.state.profile.firstName
        : '';
    this.setState({
      ...this.state,
      value: staffName,
    });
    this.props.changeStaff(this.props.state.profile.id, staffName);
  }

  render() {
    const { value } = this.state;
    const suggestions = this.props.state.suggestions;

    const getSuggestionValue = (suggestion) => suggestion.name;

    const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

    const inputProps = {
      placeholder: '社員名を入力してください',
      value,
      onChange: this.onChange,
    };

    return (
      <Row className="justify-content-md-center">
        <Col xs lg="2">
          <div>社員名</div>
        </Col>
        <Col md="auto">
          <Suggest
            {...new SuggestParam(
              suggestions,
              this.props.fetchStaff,
              this.props.clearSuggestion,
              getSuggestionValue,
              renderSuggestion,
              inputProps,
              this.onSuggestionSelected
            ).toObject()}
          />
        </Col>
        <Col xs lg="2">
          <MineButton onClick={this.onClickMineButton} />
          <AllowOwner dataOwnerId={this.props.currentSelectedStaffId}>
            <BulkRegisterButton onClick={this.props.bulkRegisterButtonClick} />
          </AllowOwner>
        </Col>
      </Row>
    );
  }
}

export default connect(
  (state) => {
    console.debug('Interviewable state', state);

    return {
      state: {
        suggestions: state.consultationPlan.staff,
        profile: state.consultationPlan.profile,
      },
    };
  },
  (dispatch) => {
    return {
      fetchStaff(value) {
        dispatch(makeFetchStaffForSuggestion(value));
      },
      clearSuggestion() {
        dispatch(clearSuggestion());
      },
      fetchProfile() {
        dispatch(makeFetchProfile());
      },
    };
  }
)(ConsultationPlan);

import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { makeFetchRole, makeFetchPrefGroups } from '../../../actions/Staff';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import RegisterButton from '../../molecules/RegisterButton';
import SaveButton from '../../molecules/SaveButton';
import ProfileImageReferComponent from '../../organisms/adminProfile/ProfileImage';

class StaffEditConfirmForm extends React.Component {
  static get propTypes() {
    return {
      onSubmit: PropTypes.func,
      backToInput: PropTypes.func,
      options: PropTypes.shape({
        bases: PropTypes.array,
        divisions: PropTypes.array,
        occupations: PropTypes.array,
        roles: PropTypes.array,
        prefGroups: PropTypes.array,
      }),
      values: PropTypes.any,
      fetchRole: PropTypes.func,
      fetchPrefGroups: PropTypes.func,
      state: PropTypes.shape({
        role: PropTypes.shape({ isSystemAdmin: PropTypes.bool }),
        isSubmitting: PropTypes.bool,
      }),
      isNew: PropTypes.bool,
    };
  }

  componentDidMount() {
    if (this.props.values && this.props.values.roleId) {
      this.props.fetchRole(this.props.values.roleId);
    }
  }

  render() {
    const getName = () => {
      return `${this.props.values.familyName} ${this.props.values.firstName}`;
    };

    const getCode = () => {
      return `${this.props.values.code}`;
    };

    const getMail = () => {
      return this.props.values.mail;
    };

    const getTel = () => {
      if (
        this.props.values.tel1 &&
        this.props.values.tel2 &&
        this.props.values.tel3
      ) {
        return `${this.props.values.tel1}-${this.props.values.tel2}-${this.props.values.tel3}`;
      }
      return '';
    };

    const getBase = () => {
      console.debug('base: ', this.props.options.bases);

      const bases = this.props.options.bases
        .filter((base) => base.value === this.props.values.baseId)
        .map((base) => base.name);

      if (bases.length > 0) {
        return bases[0];
      }

      return '';
    };

    const getDivision = () => {
      const divisionNames = this.props.options.divisions
        .filter((division) => division.value === this.props.values.divisionId)
        .map((division) => division.name);

      if (divisionNames.length > 0) {
        return divisionNames[0];
      }

      return '';
    };

    const getOccupation = () => {
      console.debug('occupations: ', this.props.options.occupations);

      const occupations = this.props.options.occupations
        .filter(
          (occupation) => occupation.value === this.props.values.occupationId
        )
        .map((occupation) => occupation.name);

      if (occupations.length > 0) {
        return occupations[0];
      }

      return '';
    };

    const getRole = () => {
      console.debug('roles: ', this.props.options.roles);

      const roles = this.props.options.roles
        .filter((role) => role.value === this.props.values.roleId)
        .map((role) => role.name);

      if (roles.length > 0) {
        return roles[0];
      }

      return '';
    };

    const isSystemAdmin = () => {
      if (this.props.state.role.isSystemAdmin) {
        return 'システム管理者';
      } else {
        return 'なし';
      }
    };

    const getYoutube = () => {
      return `${this.props.values.youtube || ''}`;
    };

    const getIndividualMeetingUrl = () => {
      return `${this.props.values.individualMeetingUrl || ''}`;
    };

    const getNote = () => {
      return `${this.props.values.note || ''}`;
    };

    const getProfileURL = () => {
      if (this.props.values.profileTempId != null) {
        return `${process.env.REACT_APP_API_URI}/static/tmp/${this.props.values.profileTempId}`;
      }

      if (this.props.values.profileUrl) {
        return `${process.env.REACT_APP_API_URI}/${this.props.values.profileUrl}`;
      }

      return '';
    };

    const getInterviewPriorityData = () => {
      const values = this.props.values;
      const prefGroups = this.props.options.prefGroups;
      if (prefGroups.length === 0) {
        return null;
      }
      const priorityData = [];
      prefGroups.forEach((prefGroup) => {
        const key = `prefGroup_${prefGroup.id}`;
        const priority =
          values[key] && values[key] !== ''
            ? parseInt(values[key], 10)
            : (values[key] = null);
        priorityData.push({ field: prefGroup.name, priority: priority });
      });
      return priorityData;
    };

    return (
      <>
        <ReferItem label="名前">{getName()}</ReferItem>
        <ReferItem label="社員番号">{getCode()}</ReferItem>
        <ProfileImageReferComponent
          label="プロフィール画像"
          url={getProfileURL()}
        />

        <ReferItem label="メールアドレス">{getMail()}</ReferItem>
        <ReferItem label="電話番号">{getTel()}</ReferItem>
        <ReferItem label="拠点">{getBase()}</ReferItem>
        <ReferItem label="部署">{getDivision()}</ReferItem>
        <ReferItem label="職種">{getOccupation()}</ReferItem>
        <ReferItem label="ロール">{getRole()}</ReferItem>
        <ReferItem label="システム管理者フラグ">{isSystemAdmin()}</ReferItem>
        <ReferItem label="アドバイザー紹介動画">{getYoutube()}</ReferItem>
        <ReferItem label="個別面談MTG用URL">
          {getIndividualMeetingUrl()}
        </ReferItem>
        <ReferItem label="note URL">{getNote()}</ReferItem>
        <ReferItem label="▼面談優先度"></ReferItem>
        {/* eslint-disable react/jsx-key */}
        {getInterviewPriorityData() ? (
          getInterviewPriorityData().map((item) => (
            <ReferItem label={item.field}>{item.priority}</ReferItem>
          ))
        ) : (
          <></>
        )}
        {/* eslint-enable react/jsx-key */}

        <hr />
        <Row>
          <div className="mx-auto">
            <PrevButton onClick={this.props.backToInput} />
            {this.props.isNew ? (
              <RegisterButton
                onClick={this.props.onSubmit}
                isSubmitting={this.props.state.isSubmitting}
              />
            ) : (
              <SaveButton
                onClick={this.props.onSubmit}
                isSubmitting={this.props.state.isSubmitting}
              />
            )}
          </div>
        </Row>
      </>
    );
  }
}

export default connect(
  (state) => {
    return { state: state.staff.staffEdit, options: state.options };
  },
  (dispatch) => {
    return {
      fetchRole(id) {
        dispatch(makeFetchRole(id));
      },
      fetchPrefGroups() {
        dispatch(makeFetchPrefGroups());
      },
    };
  }
)(StaffEditConfirmForm);

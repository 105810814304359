import PropTypes from 'prop-types';
import React from 'react';
import { Switch } from 'react-router-dom';

import RouteWithSubRoutes from '../../RouterUtil';
import { ActionCreatorProvider } from '../../utils/PermissionComponent';

class ReservationManagement extends React.Component {
  static get propTypes() {
    return {
      routes: PropTypes.array,
    };
  }

  render() {
    return (
      <section className="section">
        <div className="section-header">
          <h1>予約管理</h1>
        </div>

        <div className="section-body">
          <ActionCreatorProvider menu={'/admin/reservation'}>
            <Switch>
              {this.props.routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </ActionCreatorProvider>
        </div>
      </section>
    );
  }
}

export default ReservationManagement;

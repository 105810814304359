import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import { reduxForm } from 'redux-form';

import {
  makeFetchStaff,
  makeFetchBases,
  makeFetchDivisions,
  makeFetchOccupations,
  makeFetchRoles,
  makeFetchPrefGroups,
} from '../../../actions/Staff';
import FormGroupRow from '../../atoms/FormGroupRow';
import FormLabel from '../../atoms/FormLabel';
import { OptionCollection } from '../../model/Option';
import CancelButton from '../../molecules/CancelButton';
import ConfirmButton from '../../molecules/ConfirmButton';
import FormTelComponent from '../../molecules/FormTelComponent';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import ProfileImage from '../../molecules/ProfileImage';
import StaffCodeInputComponent from '../../molecules/staff/StaffCodeInputComponent';
import NameInput from '../../organisms/staff/NameInputComponent';
import * as Validator from '../../utils/Validate';

class StaffEditForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      fetchStaff: PropTypes.func,
      fetchBases: PropTypes.func,
      fetchOccupations: PropTypes.func,
      fetchDivisions: PropTypes.func,
      fetchPrefGroups: PropTypes.func,
      fetchRoles: PropTypes.func,
      options: PropTypes.shape({
        bases: PropTypes.array,
        divisions: PropTypes.array,
        occupations: PropTypes.array,
        roles: PropTypes.array,
        prefGroups: PropTypes.array,
      }),
      onPrevButtonClick: PropTypes.func,
      invalid: PropTypes.bool,
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      isNew: PropTypes.func,
      formState: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
    };
  }

  componentDidMount() {}

  getOccupationOptinos() {
    console.debug('getOccupationOptions', this.props.options.occupations);
    return new OptionCollection(this.props.options.occupations).getOptions();
  }

  getPrefGroups() {
    console.debug('getPrefGroupOptinos', this.props.options.prefGroups);
    return this.props.options.prefGroups;
  }

  render() {
    const getProfileImageURL = () => {
      console.debug('getProfileImageURL', this.props.initialValues);
      if (this.props.formState && this.props.formState.values.profileTempId) {
        console.debug('getProfileImageURL formState selected');
        return `${process.env.REACT_APP_API_URI}/static/tmp/${this.props.formState.values.profileTempId}`;
      }

      if (this.props.formState && this.props.formState.values.profileImageId) {
        console.debug('getProfileImageURL initivalValues profileUrl selected');
        return `${process.env.REACT_APP_API_URI}/${this.props.initialValues.profileUrl}`;
      }
      return null;
    };

    var prefGroupErrors = [];
    if (this.props.messages) {
      prefGroupErrors = Object.keys(this.props.messages)
        .filter((key) => key.startsWith('prefGroup_'))
        .map((key) => ({ id: key, message: this.props.messages[key] }));
    }

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <NameInput
            label="名前"
            firstNameMessages={
              this.props.isError ? this.props.messages.firstName : []
            }
            familyNameMessages={
              this.props.isError ? this.props.messages.familyName : []
            }
          />
          <StaffCodeInputComponent
            label="社員番号"
            name="code"
            component="input"
            type="text"
            validate={[Validator.required, Validator.number]}
            messages={this.props.isError ? this.props.messages.code : []}
            isNew={this.props.isNew}
          />
          <ProfileImage
            fieldName={{
              profileTempId: 'profileTempId',
              profileImageId: 'profileImageId',
              profileUrl: 'profileUrl',
            }}
            form="staffEditForm"
            referer="/admin/staff"
            label="プロフィール画像"
            url={getProfileImageURL()}
          />
          <HorizontalFormInputComponent
            label="メールアドレス"
            name="mail"
            component="input"
            type="text"
            validate={[Validator.required, Validator.email]}
            messages={this.props.isError ? this.props.messages.mail : []}
          />
          <FormTelComponent name="tel" />
          <HorizontalSelectComponent
            label="拠点"
            options={this.props.options.bases}
            name="baseId"
            messages={this.props.isError ? this.props.messages.baseId : []}
          />
          <HorizontalSelectComponent
            label="部署"
            options={this.props.options.divisions}
            name="divisionId"
            messages={this.props.isError ? this.props.messages.divisionId : []}
          />
          <HorizontalSelectComponent
            label="職種"
            options={this.getOccupationOptinos()}
            name="occupationId"
            validate={[Validator.required]}
            messages={
              this.props.isError ? this.props.messages.occupationId : []
            }
          />
          <HorizontalSelectComponent
            label="ロール"
            options={this.props.options.roles}
            name="roleId"
            validate={[Validator.required]}
            messages={this.props.isError ? this.props.messages.roleId : []}
          />
          <HorizontalFormInputComponent
            label="アドバイザー紹介動画"
            name="youtube"
            component="input"
            type="text"
            messages={this.props.isError ? this.props.messages.youtube : []}
          />
          <HorizontalFormInputComponent
            label="個別面談MTG用URL"
            name="individualMeetingUrl"
            component="input"
            type="text"
            messages={
              this.props.isError ? this.props.messages.individualMeetingUrl : []
            }
          />
          <HorizontalFormInputComponent
            label="note URL"
            name="note"
            component="input"
            type="text"
            messages={this.props.isError ? this.props.messages.note : []}
          />

          <FormGroupRow>
            <Element name={`position-prefGroups`} />
            <FormLabel className="offset-sm-1 col-sm-8 col-form-label">
              ▼面談優先度
              ※1〜100の半角数字で入力してください（数字が小さい方が優先されます）
            </FormLabel>
            {this.props.isError &&
              this.props.messages.prefGroups != null &&
              this.props.messages.length > 0 && (
                <div className="alert alert-danger">
                  {this.props.messages.prefGroups.map((message, i) => (
                    <p key={i}>{message}</p>
                  ))}
                </div>
              )}
            {this.props.isError && prefGroupErrors.length > 0 && (
              <div className="alert alert-danger">
                <p>{prefGroupErrors[0].message}</p>
              </div>
            )}
          </FormGroupRow>

          {/* eslint-disable react/jsx-key */}
          {this.getPrefGroups() && this.getPrefGroups().length > 0 ? (
            this.getPrefGroups().map((group) => (
              <HorizontalFormInputComponent
                label={group.name}
                name={'prefGroup_' + group.id}
                validate={[Validator.numberBetweenOneAndTen]}
                component="input"
                type="text"
              />
            ))
          ) : (
            <></>
          )}
          {/* eslint-enable react/jsx-key */}
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <CancelButton onClick={this.props.onPrevButtonClick} />
              <ConfirmButton
                disabled={this.props.invalid || this.props.submitting}
                isNew={this.props.isNew}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const staffEditForm = reduxForm({
  // a unique name for the form
  form: 'staffEditForm',
  enableReinitialize: true,
})(StaffEditForm);

export default connect(
  (state) => {
    console.debug('staffEditForm: ', state);

    const tel1Number = (tel) => {
      if (tel == null) {
        return '';
      }
      const index = tel.indexOf('-');

      return tel.slice(0, index);
    };

    const tel2Number = (tel) => {
      if (tel == null) {
        return '';
      }

      const firstSplitterIndex = tel.indexOf('-');
      const secondSplitterIndex = tel.indexOf('-', firstSplitterIndex + 1);
      return tel.slice(firstSplitterIndex + 1, secondSplitterIndex);
    };

    const tel3Number = (tel) => {
      if (tel == null) {
        return '';
      }

      const firstSplitterIndex = tel.indexOf('-');
      const secondSplitterIndex = tel.indexOf('-', firstSplitterIndex + 1);
      return tel.slice(secondSplitterIndex + 1);
    };

    console.debug(
      'staffeditform roleId: ',
      state.staff.staffRefer.detail.roleId
    );

    let profileImageId;
    if (state.staff.staffRefer.detail.profileUrl) {
      const profileUrl = state.staff.staffRefer.detail.profileUrl;
      const profileUrls = profileUrl.split('/');
      profileImageId = profileUrls[profileUrls.length - 1];
    }

    return {
      initialValues: {
        ...state.staff.staffRefer.detail,
        name:
          state.staff.staffRefer.detail.familyName +
          state.staff.staffRefer.detail.firstName,
        tel1: tel1Number(state.staff.staffRefer.detail.tel),
        tel2: tel2Number(state.staff.staffRefer.detail.tel),
        tel3: tel3Number(state.staff.staffRefer.detail.tel),
        profileImageId: profileImageId,
      },
      formState: state.form.staffEditForm,
      isError: state.staff.staffEdit.isError,
      isNewData: state.staff.staffEdit.isNewData,
      messages: state.staff.staffEdit.messages,
      options: state.options,
    };
  },
  (dispatch) => {
    return {
      fetchStaff(id) {
        dispatch(makeFetchStaff(id));
      },
      fetchBases() {
        dispatch(makeFetchBases());
      },
      fetchDivisions() {
        dispatch(makeFetchDivisions());
      },
      fetchOccupations() {
        dispatch(makeFetchOccupations());
      },
      fetchRoles() {
        dispatch(makeFetchRoles());
      },
      fetchPrefGroups() {
        dispatch(makeFetchPrefGroups());
      },
    };
  }
)(staffEditForm);

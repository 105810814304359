import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLastLocation } from 'react-router-last-location';
import styled from 'styled-components';

import Option from '../../model/Option';
import DeleteButton from '../../molecules/DeleteButton';
import DetailButton from '../../molecules/DetailButton';
import NewButton from '../../molecules/NewButton';
import Card from '../../organisms/Card';
import Form from '../../organisms/company/CompanySearchForm';
import ContainsUsedAlertModal from '../../organisms/company/ContainsUsedAlertModal';
import DeleteConfirmModal from '../../organisms/company/DeleteConfirmModal';
import Pager from '../../organisms/Pager';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import SearchResultTable from '../../organisms/SearchResultTable';
import SearchComponent from '../common/SearchComponent';

import routes from './routePath';

const MarginRow = styled(Row)`
  margin-bottom: 1em;
  margin-top: 1em;
`;

class CompanySearch extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        companySearch: PropTypes.shape({
          searchValues: PropTypes.shape({
            searchParams: PropTypes.object,
            values: PropTypes.object,
          }),
          isDisplayUsedContainsAlertDialog: PropTypes.bool,
          isConfirm: PropTypes.bool,
          isShowDeleteCompleteModal: PropTypes.bool,
        }),
        searchResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          items: PropTypes.array,
        }),
        areas: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
        bases: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
        occupations: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
        roles: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      search: PropTypes.func,
      hideDisableSuccessModal: PropTypes.func,
      hideUnlockSuccessModal: PropTypes.func,
      unlockStaff: PropTypes.func,
      initFetchSearchPage: PropTypes.func,
      lastLocation: PropTypes.shape({
        pathname: PropTypes.string,
      }),
      showDeleteConfirmModal: PropTypes.func,
      confirmIsUsed: PropTypes.func,
      onAlertModalCloseButtonClick: PropTypes.func,
      hideDeleteCompleteModal: PropTypes.func,
      hideDeleteConfirmModal: PropTypes.func,
      deleteCompany: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.state = { values: {}, disableSelections: [] };

    this.searchComponent = new SearchComponent();

    this.clickSearchButton = this.clickSearchButton.bind(this);
    this.getSearchItem = this.getSearchItem.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
    this.onDisableButtonClick = this.onDisableButtonClick.bind(this);
    this.onClickDisableModalCloseButton = this.onClickDisableModalCloseButton.bind(
      this
    );
    this.isEnableDeleteButton = this.isEnableDeleteButton.bind(this);
    this.onAlertModalCloseButtonClick = this.onAlertModalCloseButtonClick.bind(
      this
    );
    this.clearSelections = this.clearSelections.bind(this);
    this.containsSelection = this.containsSelection.bind(this);
    this.onClickCompleteModalCloseButton = this.onClickCompleteModalCloseButton.bind(
      this
    );

    this.search = this.search.bind(this);
  }

  componentDidMount() {
    const isComeFromOtherPage =
      this.props.lastLocation &&
      !this.props.lastLocation.pathname.includes('/admin/company');

    console.debug(
      'lastLocation',
      this.props.lastLocation,
      this.props.lastLocation &&
        this.props.lastLocation.pathname.includes('/admin/company')
    );
    this.props.initFetchSearchPage(isComeFromOtherPage);
  }

  clickSearchButton(values) {
    this.setState({ values: values });

    const prevSearchParams = this.props.state.companySearch.searchValues
      .searchParams;

    let pageSize = this.searchComponent.pageDefaultSize;
    if (
      prevSearchParams &&
      prevSearchParams.pagination &&
      prevSearchParams.pagination.pageSize
    ) {
      pageSize = prevSearchParams.pagination.pageSize;
    }

    this.search(
      {
        sort: {
          sortColumn: 'id',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      values
    );
  }

  selectionDeleteTag(company) {
    const currentSelections = this.state.disableSelections;

    let newSelections;
    if (
      currentSelections.filter((other) => other.id === company.id).length > 0
    ) {
      newSelections = currentSelections.filter((other) => {
        return other.id !== company.id;
      });
    } else {
      newSelections = currentSelections.concat([company]);
    }

    this.setState({ disableSelections: newSelections });
  }

  onDisableButtonClick() {
    if (
      !(this.state.disableSelections && this.state.disableSelections.length > 0)
    ) {
      return;
    }

    this.props.confirmIsUsed(
      this.state.disableSelections.map((company) => {
        return company.name;
      })
    );
  }

  onClickDisableModalCloseButton() {
    this.setState({ disableSelections: [] });
    this.props.hideDisableSuccessModal();
    this.clickSearchButton(this.state.values || {});
  }

  transitionToEdit() {
    this.props.history.push(routes.newEdit);
  }

  containsSelection(id) {
    return (
      this.state.disableSelections.filter((company) => {
        return company.id === id;
      }).length > 0
    );
  }

  getSearchItem() {
    const tags = this.props.state.searchResults.items;

    const transitionRefer = (id) => {
      this.props.history.push(routes.refer(id));
    };

    const items = tags.map((company, index) => {
      const TR = 'tr';

      return (
        <TR key={company.id}>
          <th scope="row">
            <input
              className="form-check-input mx-auto"
              type="checkbox"
              onChange={this.selectionDeleteTag.bind(this, {
                id: company.id,
                name: company.name,
              })}
              checked={this.containsSelection(company.id)}
            />
          </th>
          <td>{company.name}</td>
          <td>
            <DetailButton onClick={transitionRefer.bind(this, company.id)} />
          </td>
        </TR>
      );
    });
    return items;
  }

  clearSelections() {
    this.setState({ disableSelections: [] });
  }

  search(
    searchParams = this.props.state.companySearch.searchValues.searchParams,
    values = this.props.state.companySearch.searchValues.values
  ) {
    this.clearSelections();
    this.props.search(values, searchParams);
  }

  isEnableDeleteButton() {
    return this.state.disableSelections.length > 0;
  }

  onAlertModalCloseButtonClick() {
    this.props.onAlertModalCloseButtonClick();
  }

  onClickCompleteModalCloseButton() {
    this.props.hideDeleteCompleteModal(
      this.props.state.companySearch.searchValues
    );
    this.clearSelections();
  }

  render() {
    const headerItems = [{}, { name: 'name', value: '名称' }, {}];

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>企業名管理</h1>
          </div>

          <div className="section-body">
            {!this.props.state.isLoading ? (
              <Row>
                <Col>
                  <Card headerTitle="企業名検索">
                    <div className="section-title">検索項目</div>
                    <Form onSubmit={this.clickSearchButton} />
                    <div className="text-left">
                      <Row>
                        <Col>
                          <MarginRow>
                            <Col>
                              <NewButton onClick={this.transitionToEdit} />
                            </Col>
                          </MarginRow>
                        </Col>
                      </Row>
                      {this.props.state.searchResults.items.length > 0 ? (
                        <>
                          <hr />

                          <Pager
                            searchParams={
                              this.props.state.searchResults.searchParams
                            }
                            search={this.search}
                          />

                          <SearchResultTable
                            headerItems={headerItems}
                            searchResults={this.props.state.searchResults}
                            getSearchItem={this.getSearchItem}
                            search={this.search}
                          />
                          <Row>
                            <Col>
                              <DeleteButton
                                isEnable={this.isEnableDeleteButton}
                                onClick={this.onDisableButtonClick}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </section>

        <ContainsUsedAlertModal
          show={this.props.state.companySearch.isDisplayUsedContainsAlertDialog}
          onCloseButtonClick={this.onAlertModalCloseButtonClick.bind(this)}
        />

        <DeleteConfirmModal
          show={this.props.state.companySearch.isConfirm}
          onNoButtonClick={() => {
            this.props.hideDeleteConfirmModal();
          }}
          onYesButtonClick={() => {
            this.props.deleteCompany(
              this.state.disableSelections.map((company) => company.id)
            );
          }}
        />

        <RegisterCompleteModal
          show={this.props.state.companySearch.isShowDeleteCompleteModal}
          onCloseClick={this.onClickCompleteModalCloseButton}
          text={'正常に削除処理が完了しました'}
        />
      </>
    );
  }
}

const LastLocation = (Component) => {
  const InnerComponent = (props) => {
    const lastLocation = useLastLocation();
    return <Component {...props} lastLocation={lastLocation} />;
  };

  return InnerComponent;
};

export default LastLocation(CompanySearch);

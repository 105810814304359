import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from '../atoms/NormalButton';

class DeleteButton extends React.Component {
  static get propTypes() {
    return {
      message: PropTypes.string,
      buttonHidenFlg: PropTypes.bool,
      onClick: PropTypes.func,
      isEnable: PropTypes.bool,
    };
  }

  render() {
    return (
      <NormalButton
        color="danger"
        onClick={this.props.onClick}
        hiddenFlg={this.props.buttonHidenFlg}
        isEnable={this.props.isEnable}
      >
        解除
      </NormalButton>
    );
  }
}

export default DeleteButton;

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ModifyButton from '../../molecules/ModifyButton';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import ProfileImage from '../../organisms/adminProfile/ProfileImage';
import Card from '../../organisms/Card';
import ReSendActivationMailButton from '../../organisms/staff/ReSendActivationMailButton';
import ReSendMailComplete from '../../organisms/staff/ReSendMailComplete';
import ReSendMailFailureDialog from '../../organisms/staff/ReSendMailFailureDialog';

import routes from './routePath';

class StaffRefer extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      fetchStaff: PropTypes.func,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        staffRefer: PropTypes.shape({
          detail: PropTypes.shape({
            firstName: PropTypes.string,
            familyName: PropTypes.string,
            isAdminFlg: PropTypes.bool,
            code: PropTypes.string,
            mail: PropTypes.string,
            tel: PropTypes.string,
            baseName: PropTypes.string,
            occupationName: PropTypes.string,
            roleName: PropTypes.string,
            divisionName: PropTypes.string,
            isDisabled: PropTypes.bool,
            profileUrl: PropTypes.string,
            isPasswordConfigured: PropTypes.bool,
            youtube: PropTypes.string,
            individualMeetingUrl: PropTypes.string,
            note: PropTypes.string,
            interviewPriority: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                priority: PropTypes.number,
              })
            ),
          }),
          errorMessages: PropTypes.arrayOf(PropTypes.string),
          isSystemAdmin: PropTypes.bool,
          isAlreadySend: PropTypes.bool,
          isDisplayReSendComplete: PropTypes.bool,
          isDisplayReSendFailureModal: PropTypes.bool,
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      reSendActivationMail: PropTypes.func,
      hideReSendCompleteDialog: PropTypes.func,
      hideReSendFailureDialog: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
    this.reSendActivationMail = this.reSendActivationMail.bind(this);
    this.hideReSendCompleteDialog = this.hideReSendCompleteDialog.bind(this);
    this.hideReSendFailureDialog = this.hideReSendFailureDialog.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.fetchStaff(this.id);
  }

  transitionToSearch() {
    this.props.history.push(routes.search);
  }

  transitionToEdit() {
    this.props.history.push(routes.edit(this.id));
  }

  reSendActivationMail() {
    this.props.reSendActivationMail(this.id);
  }

  hideReSendCompleteDialog() {
    this.props.hideReSendCompleteDialog();
  }

  hideReSendFailureDialog() {
    this.props.hideReSendFailureDialog();
  }

  render() {
    const getName = () => {
      return (
        this.props.state.staffRefer.detail.familyName +
        this.props.state.staffRefer.detail.firstName
      );
    };

    const getCode = () => {
      return this.props.state.staffRefer.detail.code;
    };

    const getMail = () => {
      return this.props.state.staffRefer.detail.mail;
    };

    const getTel = () => {
      return this.props.state.staffRefer.detail.tel;
    };

    const getBase = () => {
      return this.props.state.staffRefer.detail.baseName;
    };

    const getDivision = () => {
      return this.props.state.staffRefer.detail.divisionName;
    };

    const getOccupation = () => {
      return this.props.state.staffRefer.detail.occupationName;
    };

    const getRole = () => {
      return this.props.state.staffRefer.detail.roleName;
    };

    const getSystemAdmin = () => {
      return this.props.state.staffRefer.detail.isAdminFlg === '1'
        ? 'はい'
        : 'いいえ';
    };

    const getStatus = () => {
      if (this.props.state.staffRefer.detail.isDisabled === '0') {
        return '有効';
      } else {
        return '無効';
      }
    };

    const getYoutube = () => {
      return this.props.state.staffRefer.detail.youtube;
    };

    const getIndividualMeetingUrl = () => {
      return this.props.state.staffRefer.detail.individualMeetingUrl;
    };

    const getNote = () => {
      return this.props.state.staffRefer.detail.note;
    };

    // const getInterviewPriority = () => {
    //   let InterviewPriorityList = this.props.state.staffRefer?.detail?.InterviewPriority || [];
    //   if (InterviewPriorityList && InterviewPriorityList.length > 0) {
    //     return InterviewPriorityList.map((item) => {
    //       return (
    //         <ReferItem label={item.name}>
    //           {item.priority}
    //         </ReferItem>
    //       );
    //     });
    //   }
    // };

    const isPasswordConfigured = () => {
      return this.props.state.staffRefer.detail.isPasswordConfigured;
    };

    const getURL = () => {
      if (this.props.state.staffRefer.detail.profileUrl) {
        return `${process.env.REACT_APP_API_URI}/${this.props.state.staffRefer.detail.profileUrl}`;
      }

      return null;
    };

    const isNotAlreadySend = () => {
      return !this.props.state.staffRefer.isAlreadySend;
    };

    const getErrorMessages = () => {
      return this.props.state.staffRefer.errorMessages;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>社員管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="詳細">
                  {this.props.state.isLoading ? (
                    <></>
                  ) : (
                    <>
                      <ReferItem label="名前">{getName()}</ReferItem>
                      <ReferItem label="社員番号">{getCode()}</ReferItem>
                      <ProfileImage label="プロフィール画像" url={getURL()} />
                      <ReferItem label="メールアドレス">{getMail()}</ReferItem>
                      <ReferItem label="電話番号">{getTel()}</ReferItem>
                      <ReferItem label="拠点">{getBase()}</ReferItem>
                      <ReferItem label="部署">{getDivision()}</ReferItem>
                      <ReferItem label="職種">{getOccupation()}</ReferItem>
                      <ReferItem label="ロール">{getRole()}</ReferItem>
                      <ReferItem label="システム管理者">
                        {getSystemAdmin()}
                      </ReferItem>
                      {this.props.state.staffRefer.isSystemAdmin ? (
                        <ReferItem label="社員ステータス">
                          {getStatus()}
                        </ReferItem>
                      ) : (
                        <></>
                      )}
                      <ReferItem label="アドバイザー紹介動画">
                        {getYoutube()}
                      </ReferItem>
                      <ReferItem label="個別面談MTG用URL">
                        {getIndividualMeetingUrl()}
                      </ReferItem>
                      <ReferItem label="note URL">{getNote()}</ReferItem>
                      <ReferItem label="▼面談優先度"></ReferItem>
                      {/* eslint-disable react/jsx-key */}
                      {Array.isArray(
                        this.props.state.staffRefer.detail.interviewPriority
                      ) &&
                        this.props.state.staffRefer.detail.interviewPriority.map(
                          (item) => (
                            <ReferItem label={item.name}>
                              {item.priority}
                            </ReferItem>
                          )
                        )}
                      {/* eslint-enable react/jsx-key */}
                      <hr />
                      <Row>
                        <div className="mx-auto">
                          <PrevButton onClick={this.transitionToSearch} />
                          <ModifyButton onClick={this.transitionToEdit} />
                          {isPasswordConfigured() ? (
                            <></>
                          ) : (
                            <ReSendActivationMailButton
                              isEnabled={isNotAlreadySend}
                              onClick={this.reSendActivationMail}
                            />
                          )}
                        </div>
                      </Row>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <ReSendMailComplete
          show={this.props.state.staffRefer.isDisplayReSendComplete}
          onCloseClick={this.hideReSendCompleteDialog}
        />
        <ReSendMailFailureDialog
          show={this.props.state.staffRefer.isDisplayReSendFailureModal}
          onCloseClick={this.hideReSendFailureDialog}
          messages={getErrorMessages()}
        />
      </>
    );
  }
}

export default StaffRefer;

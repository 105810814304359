import PropTypes from 'prop-types';
import React from 'react';

import LightButton from '../atoms/LightButton';
import { ReferAction } from '../utils/PermissionComponent';

class SearchButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      children: PropTypes.node,
    };
  }

  render() {
    return (
      <ReferAction>
        <LightButton onClick={this.props.onClick}>
          {this.props.children}
        </LightButton>
      </ReferAction>
    );
  }
}

export default SearchButton;

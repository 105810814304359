import moment from 'moment';
import { trackPromise } from 'react-promise-tracker';
import { change } from 'redux-form';

import Action, { EmptyAction } from '../components/model/Action';
import EventTypes from '../components/model/event/EventTypes';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('/admin/events');

const initState = () => {
  return new EmptyAction('events/INIT_STATE');
};

const initEditState = () => {
  return new EmptyAction('events/INIT_EDIT_STATE');
};

const fetchBases = () => {
  return axios.get('/admin/bases', {
    params: {
      isCaravan: false,
    },
  });
};

const successFetchBases = (data) => {
  return {
    type: 'FETCH_BASES',
    payload: {
      result: new OptionCollection(
        data.bases.map((area) => {
          return new Option(area.id, area.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchBases = () => {
  return async (dispatch) => {
    try {
      const data = await fetchBases();
      return dispatch(successFetchBases(data.data));
    } catch (err) {
      return dispatch(fetchErrorData(err));
    }
  };
};

const fetchDisplayAreas = () => {
  return axios.get('/admin/display_areas');
};

const successFetchDisplayAreas = (data) => {
  return {
    type: 'events/FETCH_DISPLAY_AREAS',
    payload: {
      result: data.areaList,
    },
  };
};

const makeFetchDisplayAreas = () => {
  return async (dispatch) => {
    try {
      const res = await fetchDisplayAreas();
      return dispatch(successFetchDisplayAreas(res.data));
    } catch (err) {
      return dispatch(fetchErrorData(err));
    }
  };
};

const fetchAreas = () => {
  return axios.get(`/admin/areas`);
};

const fetchAreasSuccess = (result) => {
  return {
    type: 'FETCH_AREAS',
    payload: {
      result: new OptionCollection(
        result.areaList.map((area) => {
          return new Option(area.id, area.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchAreas = () => {
  return (dispatch) => {
    return fetchAreas()
      .then((data) => dispatch(fetchAreasSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchHallsByAreaId = (areaId) => {
  return axios.get(`/admin/halls`, {
    params: { areaId: areaId },
  });
};

const fetchHallsSuccess = (result) => {
  const options = new OptionCollection(
    result.hallList.map((hall) => {
      return {
        name: hall.name,
        value: hall.id,
      };
    })
  ).getOptions();

  console.debug('hall fetch success options', options);
  return {
    type: 'FETCH_HALLS',
    payload: {
      result: options,
    },
  };
};

const makeFetchHallsByAreaId = (id) => {
  return (dispatch) => {
    return fetchHallsByAreaId(id)
      .then((data) => dispatch(fetchHallsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchCategories = () => {
  return axios.get(`/admin/categories`);
};

const fetchCategoriesSuccess = (result) => {
  return {
    type: 'FETCH_CATEGORIES',
    payload: {
      result: new OptionCollection(
        result.categoryList.map((category) => {
          return new Option(category.ID, category.Name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchCategories = () => {
  return (dispatch) => {
    return fetchCategories()
      .then((data) => dispatch(fetchCategoriesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchTags = () => {
  return axios.get(`/admin/tags`);
};

const fetchTagsSuccess = (result) => {
  return {
    type: 'option/FETCH_TAGS',
    payload: {
      result: new OptionCollection(
        result.map((tag) => {
          return new Option(tag.id, tag.name);
        })
      ).getOptionsWithoutEmpty(),
    },
  };
};

const makeFetchTags = () => {
  return (dispatch) => {
    return fetchTags()
      .then((data) => dispatch(fetchTagsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const searchEvents = (values, searchParams) => {
  let entryKindId;

  if (
    values &&
    Object.hasOwnProperty.call(values, 'kindId') &&
    values.kindId &&
    values.kindId.length > 0
  ) {
    entryKindId = Object.entries(values.kindId)
      .map(([key, value]) => {
        return { key: key, value: value };
      })
      .filter((value) => {
        return value.value;
      })
      .map((value) => {
        return value.value;
      })
      .reduce((accumulator, value) => {
        return accumulator + ',' + value;
      });
  } else {
    entryKindId = null;
  }

  let tagId;

  if (
    values &&
    Object.hasOwnProperty.call(values, 'tagId') &&
    values.tagId &&
    values.tagId.length > 0
  ) {
    tagId = Object.entries(values.tagId)
      .map(([key, value]) => {
        return { key: key, value: value };
      })
      .filter((value) => {
        return value.value;
      })
      .map((value) => {
        return value.value;
      })
      .reduce((accumulator, value) => {
        return accumulator + ',' + value;
      });
  } else {
    tagId = null;
  }

  let targetDateFrom;

  if (values && values.startDateTime) {
    targetDateFrom = dateHourMinuteToDateObject(
      values.startDateTime.date,
      values.startDateTime.hour,
      values.startDateTime.minute
    );
  } else {
    targetDateFrom = '';
  }

  let targetDateTo;

  if (values && values.endDateTime) {
    targetDateTo = dateHourMinuteToDateObject(
      values.endDateTime.date,
      values.endDateTime.hour,
      values.endDateTime.minute
    );
  } else {
    targetDateTo = '';
  }

  let publishFrom;

  if (
    values &&
    values.publishType &&
    values.publishType === '4' &&
    values.publishFrom
  ) {
    const tmpDate = dateHourMinuteToDateObject(
      values.publishFrom.date,
      values.publishFrom.hour,
      values.publishFrom.minute
    );
    publishFrom = tmpDate.toISOString();
  } else {
    publishFrom = '';
  }

  let publishTo;

  if (
    values &&
    values.publishType &&
    values.publishType === '4' &&
    values.publishTo
  ) {
    const tmpDate = dateHourMinuteToDateObject(
      values.publishTo.date,
      values.publishTo.hour,
      values.publishTo.minute
    );
    publishTo = tmpDate.toISOString();
  } else {
    publishTo = '';
  }

  const params = new Params({
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
    name: values.name,
    eventKind: entryKindId,
    areaId: values.areaId,
    venueId: values.venueId,
    registerName: values.registerName,
    chairmanName: values.chairmanName,
    categoryId: values.categoryId,
    tagId: tagId,
    targetDateFrom: targetDateFrom,
    targetDateTo: targetDateTo,
    publishType: values.publishType,
    publishFrom: publishFrom,
    publishTo: publishTo,
  });

  return axios('/admin/events', {
    params: params.removeParams(),
  });
};

function dateHourMinuteToDateObject(date, hour, minute) {
  let tmpDate;
  let tmpHour;
  let tmpMinute;

  if (date) {
    tmpDate = date;
  }

  if (tmpDate && hour) {
    tmpHour = hour;
  } else {
    tmpHour = 0;
  }

  if (tmpDate && tmpHour && minute) {
    tmpMinute = minute;
  } else {
    tmpMinute = 0;
  }

  return moment(tmpDate).locale('ja').hour(tmpHour).minute(tmpMinute).toDate();
}

const searchEventsSuccess = (result) => {
  return {
    type: 'events/SERARCH_EVENTS',
    payload: {
      result: result,
    },
  };
};

const storeSearchConditions = (values, searchParams) => {
  return {
    type: 'events/STORE_SEARCH_CONDITIONS',
    payload: {
      values,
      searchParams,
    },
  };
};

const makeSearchEvents = (values, searchParams) => {
  return (dispatch) => {
    dispatch(storeSearchConditions(values, searchParams));
    return trackPromise(
      searchEvents(values, searchParams)
        .then((data) => dispatch(searchEventsSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const searchEvent = (id) => {
  const searchUrl = '/admin/events/' + id;
  return axios.get(searchUrl);
};

const searchEventExistence = () => {
  return {
    type: 'events/SHOW_DELETE_ALERT_MODAL',
  };
};

const searchEventConfirm = () => {
  return {
    type: 'events/SHOW_DELETE_CONFIRM_MODAL',
  };
};

const hideDeleteAlertModal = () => {
  return {
    type: 'events/HIDE_DELETE_ALERT_MODAL',
  };
};

const hideDeleteConfirmModal = () => {
  return {
    type: 'events/HIDE_DELETE_CONFIRM_MODAL',
  };
};

const hideDeleteCompleteModal = () => {
  return {
    type: 'events/HIDE_DELETE_COMPLETE_MODAL',
  };
};

const makeSearchEventCheck = (ids) => {
  console.debug('makeSearchEventCheck', ids);
  return async (dispatch) => {
    var exists = false;
    for (const id of ids) {
      console.debug('makeSearchEventCheck', id);
      const data = await searchEvent(id);

      try {
        if (data.data) {
          const eventDetail = data.data;
          console.debug('eventDetail.seats', eventDetail.seats);
          if (eventDetail.seats > 0) {
            exists = true;
            break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (exists) {
      dispatch(searchEventExistence());
    } else {
      dispatch(searchEventConfirm());
    }
  };
};

const deleteEvents = (ids) => {
  return Promise.all(ids.map((id) => axios.delete(`/admin/events/${id}`)));
};

const deleteEventsSuccess = () => {
  return {
    type: 'events/SHOW_DELETE_COMPLETE_MODAL',
  };
};

const makeDeleteEvents = (ids) => {
  return (dispatch) => {
    return trackPromise(
      deleteEvents(ids)
        .then(dispatch(deleteEventsSuccess()))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const setEditValues = (values) => {
  return {
    type: 'events/SET_EDIT_VALUES',
    payload: {
      values: values,
    },
  };
};

const fetchEvent = (id) => {
  return axios.get(`/admin/events/${id}`);
};

const fetchEventSuccess = (data) => {
  return {
    type: 'events/FETCH_EVENT',
    payload: {
      result: data,
    },
  };
};

const makeFetchEvents = (id) => {
  return (dispatch) => {
    return fetchEvent(id)
      .then((data) => dispatch(fetchEventSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const beginFetchEventsForEdit = () => {
  return new EmptyAction('events/BEGIN_FETCH_EVENTS_FOR_EDIT');
};

const endFetchEventsForEdit = () => {
  return new EmptyAction('events/END_FETCH_EVENTS_FOR_EDIT');
};

const fetchIsDefaultBaseAndOccupation = () => {
  return axios.get('/admin/staff/defaultsettingbase');
};

const fetchIsDefaultBaseAndOccupationSuccess = (data) => {
  return new Action(
    'events/FETCH_IS_DEFAULT_SETTING_BASE',
    data.isDefaultSetting
  );
};

const makeFetchEventsForEdit = (id) => {
  return async (dispatch) => {
    try {
      dispatch(beginFetchEventsForEdit());
      if (id) {
        const res = await fetchEvent(id);
        if (res.data) {
          if (res.data.areaId) {
            const fetchHallsRes = await fetchHallsByAreaId(res.data.areaId);
            dispatch(fetchHallsSuccess(fetchHallsRes.data));
          }
        }
        dispatch(fetchEventSuccess(res.data));
      }

      const isDefaultBaseAndOccupationRes = await fetchIsDefaultBaseAndOccupation();
      dispatch(
        fetchIsDefaultBaseAndOccupationSuccess(
          isDefaultBaseAndOccupationRes.data
        )
      );

      if (isDefaultBaseAndOccupationRes.data.isDefaultSetting) {
        const profileRes = await fetchProfile();
        dispatch(fetchProfileSuccess(profileRes.data));
      }

      dispatch(endFetchEventsForEdit());
    } catch (err) {
      return dispatch(fetchErrorData(err));
    }
  };
};

const postEvent = (params) => {
  return axios.post(`/admin/events`, params.removeParams());
};

const putEvent = (id, params) => {
  return axios.put(`/admin/events/${id}`, params.removeParams());
};

const updateEventSuccess = (data) => {
  return {
    type: 'events/FETCH_UPDATE_EVENT',
    payload: {
      result: data,
    },
  };
};

const failureUpdateEvent = (err) => {
  if (err.response) {
    if (err.response.status === 400) {
      return {
        type: 'events/BAD_REQUEST',
        payload: {
          result: err.response.data,
        },
      };
    }
  }

  return fetchErrorData(err);
};

const createParams = (values, options) => {
  let staffId;
  if (values.chairman) {
    staffId = values.chairman.value;
  }

  let openDateYMD;
  if (values.openStartDate.date) {
    openDateYMD = moment(values.openStartDate.date).format('YYYY/MM/DD');
  }

  let openDateHH;
  if (values.openStartDate.hour) {
    openDateHH = parseInt(values.openStartDate.hour);
  } else {
    if (values.openStartDate.date) {
      openDateHH = 0;
    }
  }

  let openDateMM;
  if (values.openStartDate.minute) {
    openDateMM = parseInt(values.openStartDate.minute);
  } else {
    if (values.openStartDate.date) {
      openDateMM = 0;
    }
  }

  let closeDateYMD;
  if (values.openCloseDate.date) {
    closeDateYMD = moment(values.openCloseDate.date).format('YYYY/MM/DD');
  }

  let closeDateHH;
  if (values.openCloseDate.hour) {
    closeDateHH = parseInt(values.openCloseDate.hour);
  } else {
    if (values.openCloseDate.date) {
      closeDateHH = 0;
    }
  }

  let closeDateMM;
  if (values.openCloseDate.hour) {
    closeDateMM = parseInt(values.openCloseDate.minute);
  } else {
    if (values.openCloseDate.date) {
      closeDateMM = 0;
    }
  }

  const eventTypes = new EventTypes(options.eventTypes);

  if (!eventTypes.isSeminar(values.kindId)) {
    values.categoryId = '';
  }

  const params = new Params({
    name: values.name,
    methodTypeId: values.methodTypeId,
    webEventUrl: values.webEventUrl,
    kindId: values.kindId,
    venueId: values.venueId,
    defaultMessage: values.defaultMessage,
    dateYMDFrom: moment(values.startDateTime.from.date).format('YYYY/MM/DD'),
    dateHHFrom: parseInt(values.startDateTime.from.hour),
    dateMMFrom: parseInt(values.startDateTime.from.minute),
    dateYMDTo: moment(values.startDateTime.to.date).format('YYYY/MM/DD'),
    dateHHTo: parseInt(values.startDateTime.to.hour),
    dateMMTo: parseInt(values.startDateTime.to.minute),
    startAcceptingTime: parseInt(values.startAcceptingTime),
    openDateYMD: openDateYMD,
    openDateHH: openDateHH,
    openDateMM: openDateMM,
    closeDateYMD: closeDateYMD,
    closeDateHH: closeDateHH,
    closeDateMM: closeDateMM,
    seatAvailability: parseInt(values.seatAvailability),
    seatDisplayFlag: values.seatDisplayFlag === '1',
    paidFlag: values.paidFlag === '1',
    attire: values.attire.value === '1',
    attireDescription: values.attire.otherText,
    necessities: values.necessities.value === '1',
    necessitiesDescription: values.necessities.otherText,
    staffId: staffId,
    categoryId: values.categoryId,
    tags: values.tagId,
    content: values.content,
    chargeBaseId: values.chargeBaseId,
    type: values.type,
    price: parseInt(values.price),
    displayAreaId: values.displayAreas,
    companyName: values.companyName,
  });

  return params;
};

const makeUpdateEvent = (values) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(updateEventsLoading());
    let req;
    const params = createParams(values, state.options);
    if (values.id == null) {
      req = postEvent(params);
    } else {
      req = putEvent(values.id, params);
    }
    trackPromise(
      req
        .then((data) => dispatch(updateEventSuccess(data.data)))
        .catch((err) => dispatch(failureUpdateEvent(err)))
    );
  };
};

const staffSearchModalShow = () => {
  return {
    type: 'events/STAFF_SEARCH_MODAL_SHOW',
  };
};

const staffSearchModalHide = () => {
  return {
    type: 'events/STAFF_SEARCH_MODAL_HIDE',
  };
};

const fetchStaff = () => {
  return axios.get(`/admin/staffs`);
};

const fetchStaffForSuggestion = (name) => {
  return axios.get(`/admin/staffs`, {
    params: {
      name: name,
      isDisable: false,
    },
  });
};

const fetchStaffSuccess = (result) => {
  return {
    type: 'events/FETCH_STAFF',
    payload: {
      result: new OptionCollection(
        result.staffs.map((staff) => {
          return new Option(staff.id, staff.familyName + staff.firstName);
        })
      ).getOptionsWithoutEmpty(),
    },
  };
};

const makefetchStaff = () => {
  return (dispatch) => {
    return fetchStaff()
      .then((data) => dispatch(fetchStaffSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const makeFetchStaffForSuggestion = (name) => {
  return async (dispatch) => {
    try {
      const data = await fetchStaffForSuggestion(name);
      return dispatch(fetchStaffSuccess(data.data));
    } catch (err) {
      return dispatch(fetchErrorData(err));
    }
  };
};

const updateEventsLoading = () => {
  return {
    type: 'events/UPDATE_EVENTS_LOADING',
  };
};

const uploadBulkInsertFile = (file) => {
  const params = new FormData();
  params.append('file', file);

  return axios.post(`/events/bulk_register/file_upload`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const uploadBulkInsertFileSuccess = (data) => {
  return {
    type: 'events/UPLOAD_BULK_INSERT_FILE_SUCCESS',
    payload: data,
  };
};

const makeUploadBulkInsertFile = (file) => {
  return (dispatch) => {
    return trackPromise(
      uploadBulkInsertFile(file)
        .then((data) => dispatch(uploadBulkInsertFileSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const bulkInsertExec = (tempFileId, originalFileName) => {
  return axios.post(`/events/bulk_register`, {
    tempFileId: tempFileId,
    originalFileName: originalFileName,
  });
};

const bulkInsertExecSuccess = (data) => {
  return {
    type: 'events/BULK_INSERT_EXEC_SUCCESS',
  };
};

const failureBulkInsertExec = (err) => {
  if (err.response) {
    if (err.response.status === 400) {
      return {
        type: 'events/BAD_REQUEST_BULK_UPDATE',
        payload: {
          result: err.response.data,
        },
      };
    }
  }

  return {
    type: 'FETCH_ERROR',
    payload: {
      result: err,
    },
  };
};

const makeBulkInsertExec = (tempFileId, originalFileName) => {
  return (dispatch) => {
    return trackPromise(
      bulkInsertExec(tempFileId, originalFileName)
        .then((data) => dispatch(bulkInsertExecSuccess(data.data)))
        .catch((err) => dispatch(failureBulkInsertExec(err)))
    );
  };
};

const hideExecutedDialog = () => {
  return {
    type: 'events/HIDE_EXECUTED_DIALOG',
  };
};

const setFormStartDateValue = (date) => {
  return change('eventsSearchFrom', 'startDateTime', date);
};

const setFormEndDateValue = (date) => {
  return change('eventsSearchFrom', 'endDateTime', date);
};

const fetchEventTypes = async () => {
  return axios.get('/event_types');
};

const fetchEventTypeSuccess = (data) => {
  return {
    type: 'option/FETCH_EVENT_TYPE',
    payload: {
      result: new OptionCollection(
        data.map((option) => {
          return new Option(option.id, option.name);
        })
      ).getOptionsWithoutEmpty(),
    },
  };
};

const makeFetchEventTypes = () => {
  return async (dispatch) => {
    try {
      const data = await fetchEventTypes();
      return dispatch(fetchEventTypeSuccess(data.data));
    } catch (err) {
      return dispatch(fetchErrorData(err));
    }
  };
};

const fetchEventMethodTypes = async () => {
  return axios.get('/event_method_types');
};

const fetchEventMethodTypeSuccess = (data) => {
  return {
    type: 'option/FETCH_EVENT_METHOD_TYPE',
    payload: {
      result: data,
    },
  };
};

const makeFetchEventMethodTypes = () => {
  return async (dispatch) => {
    try {
      const data = await fetchEventMethodTypes();
      return dispatch(fetchEventMethodTypeSuccess(data.data));
    } catch (err) {
      return dispatch(fetchErrorData(err));
    }
  };
};

const clearSuggestion = () => {
  return {
    type: 'events/CLEAR_SUGGESTION',
  };
};

const selectedChairman = (id) => {
  return change('eventsEditForm', 'chairman', id);
};

const backToInput = () => {
  return {
    type: 'events/BACK_TO_INPUT',
  };
};

const initEventDetail = () => {
  return {
    type: 'events/INIT_EVENT_DETAIL',
  };
};

const fetchProfile = () => {
  return axios.get('/admin/profile');
};

const fetchProfileSuccess = (data) => {
  return new Action('events/FETCH_PROFILE_SUCCESS', data);
};

const makeFetchReferItem = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'events/LOAD_REFER_ITEM' });
      const fetchAreaRes = await fetchAreas();
      dispatch(fetchAreasSuccess(fetchAreaRes.data));

      const fetchCategoriesRes = await fetchCategories();
      dispatch(fetchCategoriesSuccess(fetchCategoriesRes.data));

      const fetchTagsRes = await fetchTags();
      dispatch(fetchTagsSuccess(fetchTagsRes.data));

      const fetchBasesRes = await fetchBases();
      dispatch(successFetchBases(fetchBasesRes.data));

      const fetchEventTypeRes = await fetchEventTypes();
      dispatch(fetchEventTypeSuccess(fetchEventTypeRes.data));

      const fetchEventRes = await fetchEvent(id);
      dispatch(fetchEventSuccess(fetchEventRes.data));

      const profileRes = await fetchProfile();
      dispatch(fetchProfileSuccess(profileRes.data));

      dispatch({ type: 'events/LOAD_REFER_ITEM_COMPLETE' });
    } catch (err) {
      fetchErrorData(err);
    }
  };
};

const initBulkUpdate = () => {
  return {
    type: 'events/INIT_BULK_UPDATE',
  };
};

const clearErrorMessage = () => {
  return {
    type: 'events/CLEAR_BULK_UPDATE_ERROR_MESSAGE',
  };
};

const beginFetchSearchOptions = () => {
  return new EmptyAction('events/BEGIN_FETCH_SEARCH_OPTIONS');
};

const endFetchSearchOptions = () => {
  return new EmptyAction('events/END_FETCH_SEARCH_OPTIONS');
};

const makeFetchSearchOptions = () => {
  return async (dispatch) => {
    try {
      dispatch(beginFetchSearchOptions());
      const areasRes = await fetchAreas();
      dispatch(fetchAreasSuccess(areasRes.data));
      const categoryRes = await fetchCategories();
      dispatch(fetchCategoriesSuccess(categoryRes.data));
      const tagsRes = await fetchTags();
      dispatch(fetchTagsSuccess(tagsRes.data));
      const eventTypesRes = await fetchEventTypes();
      dispatch(fetchEventTypeSuccess(eventTypesRes.data));
      dispatch(endFetchSearchOptions());
    } catch (e) {
      fetchErrorData(e);
    }
  };
};

const clearHallsOptions = () => {
  return (dispatch) => {
    dispatch(new EmptyAction('option/CLEAR_HALLS'));
    dispatch(change('eventsSearchFrom', 'venueId', ''));
    dispatch(change('eventsEditForm', 'venueId', ''));
  };
};

const fetchSFCompanyNames = () => {
  return axios.get('/options/sf_company_names');
};

const fetchSFCompanyNamesSuccess = (datas) => {
  const options = new OptionCollection(
    datas.map((data) => {
      return new Option(data.name, data.name);
    })
  );

  return new Action('options/SF_COMPANY_NAMES', options.getOptions());
};

const makeFetchSFCompanyNames = () => {
  return async (dispatch) => {
    try {
      const companyNamesRes = await fetchSFCompanyNames();
      dispatch(fetchSFCompanyNamesSuccess(companyNamesRes.data));
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

export {
  initState,
  initEditState,
  makeFetchAreas,
  makeFetchHallsByAreaId,
  makeFetchCategories,
  makeFetchTags,
  makeFetchEventTypes,
  makeFetchEventMethodTypes,
  makeSearchEvents,
  makeSearchEventCheck,
  makeFetchBases,
  makeFetchDisplayAreas,
  hideDeleteAlertModal,
  hideDeleteConfirmModal,
  hideDeleteCompleteModal,
  makeDeleteEvents,
  setEditValues,
  makeFetchEvents,
  makeUpdateEvent,
  staffSearchModalShow,
  staffSearchModalHide,
  makefetchStaff,
  makeFetchStaffForSuggestion,
  makeUploadBulkInsertFile,
  makeBulkInsertExec,
  hideExecutedDialog,
  setFormStartDateValue,
  setFormEndDateValue,
  makeFetchEventsForEdit,
  selectedChairman,
  clearSuggestion,
  backToInput,
  initEventDetail,
  initBulkUpdate,
  makeFetchReferItem,
  clearErrorMessage,
  makeFetchSearchOptions,
  clearHallsOptions,
  makeFetchSFCompanyNames,
};

import PropTypes from 'prop-types';
import React from 'react';

import PrimaryButton from '../atoms/PrimaryButton';
import { EditAction } from '../utils/PermissionComponent';

class EditButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      isSubmitting: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.getClass = this.getClass.bind(this);
  }

  getClass() {
    let loadingClass = '';
    if (this.props.isSubmitting) {
      loadingClass = 'btn-progress';
    }

    return loadingClass;
  }

  render() {
    return (
      <EditAction>
        <PrimaryButton
          onClick={this.props.onClick}
          className={this.getClass()}
          {...this.props}
        >
          編集
        </PrimaryButton>
      </EditAction>
    );
  }
}

export default EditButton;

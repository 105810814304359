import PropTypes from 'prop-types';
import React from 'react';

import PrimaryFormButton from '../atoms/PrimaryFormButton';
import { RegisterAction, EditAction } from '../utils/PermissionComponent';

class ConfirmButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      isNew: PropTypes.func,
    };
  }

  render() {
    let Action;
    if (Object.hasOwnProperty.call(this.props, 'isNew')) {
      console.debug('ConfirmButton :', this.props.isNew);
      if (this.props.isNew) {
        Action = RegisterAction;
      } else {
        Action = EditAction;
      }
    } else {
      Action = React.Fragment;
    }
    return (
      <Action>
        <PrimaryFormButton
          onClick={this.props.onClick}
          isEnabled={this.props.isEnabled}
        >
          確認
        </PrimaryFormButton>
      </Action>
    );
  }
}

export default ConfirmButton;

import defaultReducer from './DefaultReducers';

const initialState = {
  searchResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },

    items: [],
  },
  tagSearch: {
    searchValues: {
      searchParams: {},
      values: {},
    },
    isDisplayUsedContainsAlertDialog: false,
    isShowDeleteCompleteModal: false,
    isConfirm: false,
  },
  tagRefer: {
    detail: {
      name: '',
      romanCharName: '',
    },
  },
  tagEdit: {
    isConfirm: false,
    isError: false,
    errorMessages: {},
  },
  isLoading: false,
};

const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'tag/INIT_TAG': {
      return {
        ...initialState,
      };
    }
    case 'tag/INIT_TAG_WITHOUT_SEARCH': {
      return {
        ...initialState,
        searchResults: {
          ...state.searchResults,
        },
        tagSearch: {
          ...state.tagSearch,
        },
      };
    }
    case 'tag/FETCH_STAFF_SEARCH': {
      const searchResults = action.payload.result;
      return {
        ...state,
        searchResults: {
          items: searchResults.result,
          searchParams: searchResults.searchParams,
        },
      };
    }
    case 'tag/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'tag/SHOW_USED_CONTAINS_ALERT': {
      return {
        ...state,
        tagSearch: {
          ...state.tagSearch,
          isDisplayUsedContainsAlertDialog: true,
        },
      };
    }
    case 'tag/SHOW_DELETE_CONFIRM': {
      return {
        ...state,
        tagSearch: {
          ...state.tagSearch,
          isConfirm: true,
        },
      };
    }
    case 'tag/HIDE_USED_CONTAINS_ALERT': {
      return {
        ...state,
        tagSearch: {
          ...state.tagSearch,
          isDisplayUsedContainsAlertDialog: false,
        },
      };
    }
    case 'tag/HIDE_DELETE_CONFIRM_MODAL': {
      return {
        ...state,
        tagSearch: {
          ...state.tagSearch,
          isConfirm: false,
        },
      };
    }
    case 'tag/STORE_SEARCH_VALUES': {
      return {
        ...state,
        tagSearch: {
          ...state.tagSearch,
          searchValues: action.payload,
        },
      };
    }
    case 'tag/SHOW_DELETE_COMPLETE_MODAL': {
      return {
        ...state,
        tagSearch: {
          ...state.tagSearch,
          isShowDeleteCompleteModal: true,
        },
      };
    }
    case 'tag/HIDE_DELETE_COMPLETE_MODAL': {
      return {
        ...state,
        tagSearch: {
          ...state.tagSearch,
          isShowDeleteCompleteModal: false,
        },
      };
    }
    case 'tag/FETCH_TAG_SUCCESS': {
      const results = action.payload;
      return {
        ...state,
        tagRefer: {
          ...state.tagRefer,
          detail: results,
        },
      };
    }
    case 'tag/SET_EDIT_VALUES': {
      const values = action.payload;
      return {
        ...state,
        tagRefer: {
          ...state.tagRefer,
          detail: {
            name: values.name,
            romanCharName: values.romanCharName,
            displayOrder: values.displayOrder,
          },
        },
        tagEdit: {
          ...state.tagEdit,
          isConfirm: true,
          isError: false,
          errorMessages: {},
        },
      };
    }
    case 'tag/GO_BACK_TO_INPUT': {
      return {
        ...state,
        tagEdit: {
          ...state.tagEdit,
          isConfirm: false,
        },
      };
    }
    case 'tag/UPDATE_TAG_SUCCESS': {
      return {
        ...state,
        tagEdit: {
          ...state.tagEdit,
          isComplete: true,
        },
      };
    }
    case 'tag/CREATE_TAG_SUCCESS': {
      return {
        ...state,
        tagEdit: {
          ...state.tagEdit,
          isComplete: true,
        },
      };
    }
    case 'tag/INIT_TAG_EDIT': {
      return {
        ...state,
        tagRefer: {
          ...state.tagRefer,
          detail: {},
        },
        tagEdit: {
          ...initialState.tagEdit,
        },
      };
    }
    case 'tag/UPDATE_ERROR': {
      const result = action.payload;
      return {
        ...state,
        tagEdit: {
          errorMessages: result.messages,
          isConfirm: false,
          isError: true,
        },
      };
    }
    default:
      return defaultReducer(action, state);
  }
};

export { tagReducer as default };

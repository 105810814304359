import { connect } from 'react-redux';

import {
  makeFetchAreas,
  makeFetchBases,
  makeSearch,
  makeFetchById,
  makeFetchStaff,
  makeEditInterview,
  makeDeleteById,
  resetState,
  showModal,
  makeFetchProfile,
  makeFetchMethodTypes,
  setSelectFrom,
  makeFetchHalls,
  makeChangeInterviewer,
  makeInitFetchData,
  clearErrorMessage,
  makeFetchStaffForSuggestion,
  clearSuggestion,
  makeGoBack,
  makeInitBulkInsert,
  makeUploadBulkInsertFile,
  makeBulkInsertExec,
} from '../actions/ConsultationPlan';
import BulkInsertComponent from '../components/pages/consultationPlan/BulkInsert';
import ConsultationPlanComponent from '../components/pages/consultationPlan/ConsultationPlan';
import ConsultationPlanSearchComponent from '../components/pages/consultationPlan/ConsultationPlanSearch';

const mapStateToProps = (state) => {
  return {
    state: {
      ...state.consultationPlan,
      reservationManagement: {
        interviews: {
          searchValues:
            state.reservationManagement.reservationManagementInterview
              .searchValues,
        },
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initFetchData(comeFromOtherPageFlag, searchValues) {
      dispatch(makeInitFetchData(comeFromOtherPageFlag, searchValues));
    },
    fetchById(id) {
      dispatch(makeFetchById(id));
    },
    /**
     *
     * @param values
     * @param searchParams
     * @param {ReservationManagementSearchParams} reservationManagementSearchParams
     */
    editInterview(values, searchParams, reservationManagementSearchParams) {
      dispatch(
        makeEditInterview(
          values,
          searchParams,
          reservationManagementSearchParams
        )
      );
    },
    delete(values, searchParam) {
      dispatch(makeDeleteById(values, searchParam));
    },
    fetchArea() {
      dispatch(makeFetchAreas());
    },
    fetchBases() {
      dispatch(makeFetchBases());
    },
    search(searchParams) {
      dispatch(makeSearch(searchParams));
    },
    fetchStaff() {
      dispatch(makeFetchStaff());
    },
    resetState() {
      dispatch(resetState());
    },
    showModal(isNew, interviewId) {
      dispatch(showModal(isNew, interviewId));
    },
    fetchProfile() {
      dispatch(makeFetchProfile());
    },
    fetchMethodTypes() {
      dispatch(makeFetchMethodTypes());
    },
    setSelectFrom(values) {
      dispatch(setSelectFrom(values));
    },
    fetchHalls(areaId) {
      dispatch(makeFetchHalls(areaId));
    },
    changeInterviewer(values, searchParam, reservationManagementSearchValues) {
      dispatch(
        makeChangeInterviewer(
          values,
          searchParam,
          reservationManagementSearchValues
        )
      );
    },
    clearErrorMessage() {
      dispatch(clearErrorMessage());
    },
    fetchStaffForSuggestion(staffId, value) {
      dispatch(makeFetchStaffForSuggestion(value, staffId));
    },
    clearSuggestion() {
      dispatch(clearSuggestion());
    },
    goBack() {
      dispatch(makeGoBack());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationPlanComponent);
const ConsultationPlan = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationPlanSearchComponent);

const BulkInsert = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initBulkInsert: () => {
      dispatch(makeInitBulkInsert());
    },
    uploadBulkInsertFile: (file) => {
      dispatch(makeUploadBulkInsertFile(file));
    },
    bulkInsertExec(tempFileId, originalFileName) {
      dispatch(makeBulkInsertExec(tempFileId, originalFileName));
    },
    clearErrorMessage() {
      dispatch(clearErrorMessage());
    },
  };
})(BulkInsertComponent);

export { ConsultationPlan, BulkInsert };

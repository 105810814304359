import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';

import DeleteButton from '../../molecules/DeleteButton';
import DetailButton from '../../molecules/DetailButton';
import NewButton from '../../molecules/NewButton';
import Card from '../../organisms/Card';
import Pager from '../../organisms/Pager';
import DeleteAlertModal from '../../organisms/roleManagement/DeleteAlertModal';
import DeleteCompleteModal from '../../organisms/roleManagement/DeleteCompleteModal';
import DeleteConfirmModal from '../../organisms/roleManagement/DeleteConfirmModal';
import Form from '../../organisms/roleManagement/RoleManagementSearchForm';
import SearchResultTable from '../../organisms/SearchResultTable';
import SearchComponent from '../common/SearchComponent';

import routePath from './routePath';

const MarginBottomRow = styled(Row)`
  margin-bottom: 1em;
`;

class RoleManagementSearch extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        searchResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          roles: PropTypes.array,
        }),
        roleSearch: PropTypes.shape({
          values: PropTypes.object,
          searchParams: PropTypes.shape({
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
            }),
          }),
        }),
      }),
      newRole: PropTypes.func,
      showDeleteRole: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      roleDelete: PropTypes.shape({
        isDeleteConfirm: PropTypes.bool,
        isDeleteAlert: PropTypes.bool,
        isDeleteComplete: PropTypes.bool,
        isDelete: PropTypes.bool,
        cnt: PropTypes.number,
        deleteSelections: PropTypes.number,
      }),
      fetchMenus: PropTypes.func,
      search: PropTypes.func,
      fetchRoles: PropTypes.func,
      deleteRole: PropTypes.func,
      showDeleteConfirm: PropTypes.func,
      closeDeleteConfirm: PropTypes.func,
      showDeleteAlert: PropTypes.func,
      closeDeleteAlert: PropTypes.func,
      closeDeleteComplete: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      values: {},
      deleteSelections: [],
    };
    this.searchComponent = new SearchComponent();

    this.clickSearchButton = this.clickSearchButton.bind(this);
    this.getSearchItem = this.getSearchItem.bind(this);

    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.props.fetchMenus();
  }

  clickSearchButton(values) {
    this.setState({ values: values });

    const prevSearchParams = this.props.state.roleSearch.searchParams;

    let pageSize = this.searchComponent.pageDefaultSize;
    if (
      prevSearchParams &&
      prevSearchParams.pagination &&
      prevSearchParams.pagination.pageSize
    ) {
      pageSize = prevSearchParams.pagination.pageSize;
    }

    this.search(
      {
        sort: {
          sortColumn: 'name',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      values
    );
  }

  onNewButtonClick() {
    this.props.newRole();
    this.props.history.push(routePath.newEdit);
  }

  // 削除ボタンクリック
  deleteButtonClick() {
    const containsHasStaffRole = () => {
      const containsHasStaffRole = this.state.deleteSelections.some(
        (selectionId) => {
          const filteredRoles = this.props.state.searchResults.roles.filter(
            (role) => {
              return role.id === selectionId;
            }
          );

          if (filteredRoles.length === 0) {
            return false;
          }

          return filteredRoles[0].hasStaff;
        }
      );

      return containsHasStaffRole;
    };

    if (containsHasStaffRole()) {
      this.props.showDeleteAlert();
    } else {
      this.props.showDeleteConfirm(this.state.deleteSelections);
    }
  }

  // 確認ダイアログ_YES
  onConfirmYesClick() {
    this.props.fetchRoles(this.state.deleteSelections);
  }

  // 確認ダイアログ_NO
  onConfirmNoClick() {
    this.props.closeDeleteConfirm();
    this.setState({ deleteSelections: [] });
  }

  // アラートダイアログ_NO
  onAlertNoClick() {
    this.props.closeDeleteAlert();
  }

  // 完了ダイアログ_CLOSE
  onCompleteCloseClick() {
    console.debug('onCompleteCloseClick');
    this.props.closeDeleteComplete();
    this.setState({ deleteSelections: [] });
    this.clickSearchButton(this.state.values);
  }

  selectionDeleteRole(id) {
    const currentSelections = this.state.deleteSelections;

    let newSelections;
    if (currentSelections.indexOf(id) !== -1) {
      newSelections = currentSelections.filter((selectionId) => {
        return selectionId !== id;
      });
    } else {
      newSelections = currentSelections.concat([id]);
    }

    this.setState({ deleteSelections: newSelections });
  }

  getSearchItem() {
    const roles = this.props.state.searchResults.roles;

    const transitionRefer = (id) => {
      console.debug('transition refer');
      this.props.history.push(routePath.refer(id));
    };

    const items = roles.map((role, index) => {
      return (
        <tr key={role.id}>
          <th scope="row">
            <input
              className="form-check-input mx-auto"
              type="checkbox"
              onChange={this.selectionDeleteRole.bind(this, role.id)}
              checked={this.state.deleteSelections.includes(role.id)}
            />
          </th>
          <td>{role.name}</td>
          <td>{role.isSystemAdmin ? '○' : '✕'}</td>
          <td>
            <DetailButton onClick={transitionRefer.bind(this, role.id)} />
          </td>
        </tr>
      );
    });
    return items;
  }

  search(
    searchParams,
    values = this.state.values || this.props.state.roleSearch.values
  ) {
    this.props.search(values, searchParams);
  }

  render() {
    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>ロール管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="ロール管理検索">
                  <div className="section-title">検索項目</div>
                  <Form onSubmit={this.clickSearchButton} />
                  <div className="text-left">
                    <Row>
                      <Col>
                        <MarginBottomRow>
                          <Col>
                            <NewButton
                              onClick={this.onNewButtonClick.bind(this)}
                            />
                          </Col>
                        </MarginBottomRow>
                      </Col>
                    </Row>
                    {this.props.state.searchResults.roles.length > 0 ? (
                      <>
                        <hr />

                        <Pager
                          searchParams={
                            this.props.state.searchResults.searchParams
                          }
                          search={this.search}
                        />

                        <SearchResultTable
                          headerItems={[
                            {},
                            { name: 'name', value: '名前' },
                            {
                              name: 'is_system_admin',
                              value: 'システム管理者フラグ',
                            },
                            {},
                          ]}
                          searchResults={this.props.state.searchResults}
                          getSearchItem={this.getSearchItem}
                          search={this.search}
                        />
                        <Row>
                          <Col>
                            {/* ロールがチェックされるまで非活性とする */}
                            <DeleteButton
                              isEnable={() => {
                                if (
                                  !this.state.deleteSelections ||
                                  this.state.deleteSelections.length === 0
                                ) {
                                  return false;
                                }

                                return true;
                              }}
                              onClick={this.deleteButtonClick.bind(this)}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <DeleteConfirmModal
          show={this.props.roleDelete.isDeleteConfirm}
          onYesClick={this.onConfirmYesClick.bind(this)}
          onNoClick={this.onConfirmNoClick.bind(this)}
        />
        <DeleteAlertModal
          show={this.props.roleDelete.isDeleteAlert}
          onNoClick={this.onAlertNoClick.bind(this)}
        />
        <DeleteCompleteModal
          show={this.props.roleDelete.isDeleteComplete}
          onCloseClick={this.onCompleteCloseClick.bind(this)}
        />
      </>
    );
  }
}

export default connect((state) => {
  let isErr = false;
  const deleteSelections = state.roleManagement.roleDelete.deleteSelections;
  let isDeleteAlert = state.roleManagement.roleDelete.isDeleteAlert;
  const cnt = state.roleManagement.roleDelete.cnt;
  if (state.roleManagement.roleDelete.cnt > 0) {
    isErr = cnt !== deleteSelections;
  }

  if (isErr) {
    isDeleteAlert = false;
  }

  return {
    ...state,
    roleDelete: {
      ...state.roleManagement.roleDelete,
      isDeleteConfirm: state.roleManagement.roleDelete.isDeleteConfirm,
      isDeleteAlert: isDeleteAlert,
      isDeleteComplete: state.roleManagement.roleDelete.isDeleteComplete,
      deleteSelections: deleteSelections,
      cnt: cnt,
      isErr: isErr,
    },
  };
})(RoleManagementSearch);

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';

import Card from '../../organisms/Card';
import Pager from '../../organisms/Pager';
import SearchResultTable from '../../organisms/SearchResultTable';
import FormInterview from '../../organisms/summary/SummarySearchInterviewForm';
import FormSeminor from '../../organisms/summary/SummarySearchSeminorForm';
import FormSession from '../../organisms/summary/SummarySearchSessionForm';
import SearchComponent from '../common/SearchComponent';

class SummarySearch extends React.Component {
  static get propTypes() {
    const searchParamsShape = PropTypes.shape({
      id: PropTypes.string,
      searchParams: PropTypes.shape({
        pagination: PropTypes.shape({ pageSize: PropTypes.number }),
      }),
      values: PropTypes.object,
      tagType: PropTypes.string,
    });

    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        interviewBaseId: PropTypes.string,
        interviewBaseName: PropTypes.string,
        sessionBaseId: PropTypes.string,
        sessionBaseName: PropTypes.string,
        seminorBaseId: PropTypes.string,
        seminorBaseName: PropTypes.string,
        searchParams: PropTypes.shape({
          interview: searchParamsShape,
          session: searchParamsShape,
          seminar: searchParamsShape,
        }),
        searchResultsInterview: {
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          result: [],
        },
        searchType: PropTypes.string,
        searchResultsEventSession: {
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          result: [],
        },
        searchResultsEventSeminor: {
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          result: [],
        },
        searchResults: PropTypes.shape({
          interviewBases: PropTypes.array,
          interviewAreas: PropTypes.array,
          interviewDays: PropTypes.array,
          eventSessionBases: PropTypes.array,
          eventSeminorBases: PropTypes.array,
          errorMessage: PropTypes.array,
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      searchInterviewBase: PropTypes.func,
      searchInterview: PropTypes.func,
      searchEventSessionBase: PropTypes.func,
      searchEventSession: PropTypes.func,
      searchEventSeminorBase: PropTypes.func,
      searchEventSeminor: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      summarys: [],
      valuesInterview: null,
      interviewBaseName: null,
      valuesSession: null,
      sessionBaseName: null,
      valuesSeminor: null,
      seminorBaseName: null,
    };

    this.searchComponent = new SearchComponent();

    // 面談
    this.clickSearchInterviewButton = this.clickSearchInterviewButton.bind(
      this
    );
    this.searchInterviewBase = this.searchInterviewBase.bind(this);
    this.searchInterview = this.searchInterview.bind(this);
    this.getSearchInterviewItem = this.getSearchInterviewItem.bind(this);

    // 説明会
    this.clickSearchSessionButton = this.clickSearchSessionButton.bind(this);
    this.searchSessionBase = this.searchSessionBase.bind(this);
    this.searchSession = this.searchSession.bind(this);
    this.getSearchSessionItem = this.getSearchSessionItem.bind(this);

    // セミナー
    this.clickSearchSeminorButton = this.clickSearchSeminorButton.bind(this);
    this.searchSeminorBase = this.searchSeminorBase.bind(this);
    this.searchSeminor = this.searchSeminor.bind(this);
    this.getSearchSeminorItem = this.getSearchSeminorItem.bind(this);
  }

  getPageSizeOrDefault(propertyName, searchParams, defaultSize) {
    if (
      searchParams &&
      Object.prototype.hasOwnProperty.call(searchParams, propertyName) &&
      searchParams[propertyName].searchParams &&
      searchParams[propertyName].searchParams.pagination &&
      searchParams[propertyName].searchParams.pagination.pageSize
    ) {
      return searchParams[propertyName].searchParams.pagination.pageSize;
    }

    return defaultSize;
  }

  getInterviewPageSizeOrDefault(searchParams, defaultSize) {
    return this.getPageSizeOrDefault('interview', searchParams, defaultSize);
  }

  getSessionPageSizeOrDefault(searchParams, defaultSize) {
    return this.getPageSizeOrDefault('session', searchParams, defaultSize);
  }

  getSeminarPageSizeOrDefault(searchParams, defaultSize) {
    return this.getPageSizeOrDefault('seminar', searchParams, defaultSize);
  }

  // 面談
  searchInterviewBase(values = this.state.valuesInterview) {
    console.debug('searchInterviewBase-values', values);
    this.props.searchInterviewBase(values);

    const pageSize = this.getInterviewPageSizeOrDefault(
      this.props.state.searchParams,
      this.searchComponent.pageDefaultSize
    );

    const searchParams = {
      sort: {
        sortColumn: 'staff_id',
        sortType: 'asc',
      },
      pagination: { page: 1, pageSize: pageSize },
    };
    this.props.searchInterview('', values, searchParams);
  }

  clickSearchInterviewButton(values) {
    this.setState({ valuesInterview: values });
    this.searchInterviewBase(values);
  }

  searchInterview(
    id,
    searchParams = this.props.state.searchParams.interview.searchParams,
    values = this.props.state.searchParams.interview.values,
    searchType
  ) {
    console.debug('searchInterviewBase-values', values);
    console.debug('searchInterviewBase-searchParams', searchParams);

    this.props.searchInterview(
      id,
      values,
      searchParams,
      searchType !== undefined ? searchType : this.props.state.searchType
    );

    this.setState({
      searchType: searchType,
    });
  }

  clickSearchInterviewAButton(id, searchType) {
    const pageSize = this.getInterviewPageSizeOrDefault(
      this.props.state.searchParams,
      this.searchComponent.pageDefaultSize
    );

    this.searchInterview(
      id,
      {
        sort: {
          sortColumn: 'staff_id',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      this.state.valuesInterview,
      searchType
    );
    return false;
  }

  getSearchInterviewBaseName() {
    const results = this.props.state.searchResultsInterview.result;

    return results[0] ? results[0].baseName : '';
  }

  getSearchInterviewItem() {
    const results = this.props.state.searchResultsInterview.result;
    var items;
    if (this.props.state.searchType === '1') {
      items = results.map((result, id) => {
        return (
          <tr key={id}>
            <td>{result.name}</td>
            <td>{result.registeredNum}</td>
            <td>{result.reservedNum}</td>
            <td>{result.openedNum}</td>
          </tr>
        );
      });
    } else if (this.props.state.searchType === '2') {
      items = results.map((result, id) => {
        return (
          <tr key={id}>
            <td>{result.name}</td>
            <td>{result.registeredNum}</td>
          </tr>
        );
      });
    }
    return items;
  }

  // 説明会
  searchSessionBase(values = this.state.valuesSession) {
    console.debug('searchSessionBase-values', values);
    this.props.searchEventSessionBase(values);
  }

  clickSearchSessionButton(values) {
    this.setState({ valuesSession: values });
    this.searchSessionBase(values);

    const pageSize = this.getSessionPageSizeOrDefault(
      this.props.state.searchParams,
      this.searchComponent.pageDefaultSize
    );

    const searchParams = {
      sort: {
        sortColumn: 'events_name',
        sortType: 'asc',
      },
      pagination: { page: 1, pageSize: pageSize },
    };
    this.props.searchEventSession('', values, searchParams);
  }

  searchSession(
    id,
    searchParams = this.props.state.searchParams.session.searchParams,
    values = this.props.state.searchParams.session.values
  ) {
    console.debug('searchSessionBase-values', values);
    console.debug('searchSessionBase-searchParams', searchParams);
    this.props.searchEventSession(id, values, searchParams);
  }

  clickSearchSessionAButton(id, name) {
    this.setState({ sessionBaseId: id, sessionBaseName: name });

    const pageSize = this.getSessionPageSizeOrDefault(
      this.props.state.searchParams,
      this.searchComponent.pageDefaultSize
    );

    this.searchSession(
      id,
      {
        sort: {
          sortColumn: 'events_name',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      this.state.valuesSession
    );
    return false;
  }

  getSearchSessionBaseName() {
    const results = this.props.state.searchResultsEventSession.result;

    return results[0] ? results[0].baseName : '';
  }

  getSearchSessionItem() {
    const results = this.props.state.searchResultsEventSession.result;

    const items = results.map((result, id) => {
      return (
        <tr key={id}>
          <td>{result.name}</td>
          <td>{result.registeredNum}</td>
          <td>{result.reservedNum}</td>
          <td>{result.openedNum}</td>
        </tr>
      );
    });
    return items;
  }

  // セミナー
  searchSeminorBase(values = this.state.valuesSeminor) {
    console.debug('searchSeminorBase-values', values);
    this.props.searchEventSeminorBase(values);
  }

  clickSearchSeminorButton(values) {
    this.setState({ valuesSeminor: values });
    this.searchSeminorBase(values);

    const pageSize = this.getSeminarPageSizeOrDefault(
      this.props.state.searchParams,
      this.searchComponent.pageDefaultSize
    );

    const searchParams = {
      sort: {
        sortColumn: 'events_name',
        sortType: 'asc',
      },
      pagination: { page: 1, pageSize: pageSize },
    };
    this.props.searchEventSeminor('', values, searchParams);
  }

  searchSeminor(
    id,
    searchParams = this.props.state.searchParams.seminar.searchParams,
    values = this.props.state.searchParams.seminar.values
  ) {
    console.debug('searchSeminorBase-values', values);
    console.debug('searchSeminorBase-searchParams', searchParams);
    this.props.searchEventSeminor(id, values, searchParams);
  }

  clickSearchSeminorAButton(id, name) {
    this.setState({ seminorBaseId: id, seminorBaseName: name });

    const pageSize = this.getSeminarPageSizeOrDefault(
      this.props.state.searchParams,
      this.searchComponent.pageDefaultSize
    );

    this.searchSeminor(
      id,
      {
        sort: {
          sortColumn: 'events_name',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      this.state.valuesSeminor
    );
    return false;
  }

  getSearchSeminorBaseName() {
    const results = this.props.state.searchResultsEventSeminor.result;

    return results[0] ? results[0].baseName : '';
  }

  getSearchSeminorItem() {
    const results = this.props.state.searchResultsEventSeminor.result;

    const items = results.map((result, id) => {
      return (
        <tr key={id}>
          <td>{result.name}</td>
          <td>{result.registeredNum}</td>
          <td>{result.reservedNum}</td>
          <td>{result.openedNum}</td>
        </tr>
      );
    });
    return items;
  }

  getInterviewDayTableBody() {
    const result = this.props.state.searchResults.interviewDays;
    let weekdays = {};
    let saturdays = {};
    let sundays = {};
    let holidays = {};
    result.map((item, i) => {
      if (item.day === 'weekday') {
        weekdays = item;
      }
      if (item.day === 'saturday') {
        saturdays = item;
      }
      if (item.day === 'sunday') {
        sundays = item;
      }
      if (item.day === 'holiday') {
        holidays = item;
      }
    });
    return (
      <tbody>
        <tr>
          <th></th>
          <th style={{ width: '100px' }}>平日</th>
          <th style={{ width: '100px' }}>土曜</th>
          <th style={{ width: '100px' }}>日曜</th>
          <th style={{ width: '100px' }}>祝日</th>
          <th style={{ width: '100px' }}>合計</th>
        </tr>
        <tr>
          <td>日中（0:00〜17:59）</td>
          <td>
            {weekdays.daytimeReserved}/{weekdays.daytimeRegistered}
          </td>
          <td>
            {saturdays.daytimeReserved}/{saturdays.daytimeRegistered}
          </td>
          <td>
            {sundays.daytimeReserved}/{sundays.daytimeRegistered}
          </td>
          <td>
            {holidays.daytimeReserved}/{holidays.daytimeRegistered}
          </td>
          <td>
            {weekdays.daytimeReserved +
              saturdays.daytimeReserved +
              sundays.daytimeReserved +
              holidays.daytimeReserved}
            /
            {weekdays.daytimeRegistered +
              saturdays.daytimeRegistered +
              sundays.daytimeRegistered +
              holidays.daytimeRegistered}
          </td>
        </tr>
        <tr>
          <td>夜間（18:00〜0:00）</td>
          <td>
            {weekdays.nighttimeReserved}/{weekdays.nighttimeRegistered}
          </td>
          <td>
            {saturdays.nighttimeReserved}/{saturdays.nighttimeRegistered}
          </td>
          <td>
            {sundays.nighttimeReserved}/{sundays.nighttimeRegistered}
          </td>
          <td>
            {holidays.nighttimeReserved}/{holidays.nighttimeRegistered}
          </td>
          <td>
            {weekdays.nighttimeReserved +
              saturdays.nighttimeReserved +
              sundays.nighttimeReserved +
              holidays.nighttimeReserved}
            /
            {weekdays.nighttimeRegistered +
              saturdays.nighttimeRegistered +
              sundays.nighttimeRegistered +
              holidays.nighttimeRegistered}
          </td>
        </tr>
        <tr>
          <td>合計</td>
          <td>
            {weekdays.daytimeReserved + weekdays.nighttimeReserved}/
            {weekdays.daytimeRegistered + weekdays.nighttimeRegistered}
          </td>
          <td>
            {saturdays.daytimeReserved + saturdays.nighttimeReserved}/
            {saturdays.daytimeRegistered + saturdays.nighttimeRegistered}
          </td>
          <td>
            {sundays.daytimeReserved + sundays.nighttimeReserved}/
            {sundays.daytimeRegistered + sundays.nighttimeRegistered}
          </td>
          <td>
            {holidays.daytimeReserved + holidays.nighttimeReserved}/
            {holidays.daytimeRegistered + holidays.nighttimeRegistered}
          </td>
          <td>
            {weekdays.daytimeReserved +
              saturdays.daytimeReserved +
              sundays.daytimeReserved +
              holidays.daytimeReserved +
              weekdays.nighttimeReserved +
              saturdays.nighttimeReserved +
              sundays.nighttimeReserved +
              holidays.nighttimeReserved}
            /
            {weekdays.daytimeRegistered +
              saturdays.daytimeRegistered +
              sundays.daytimeRegistered +
              holidays.daytimeRegistered +
              weekdays.nighttimeRegistered +
              saturdays.nighttimeRegistered +
              sundays.nighttimeRegistered +
              holidays.nighttimeRegistered}
          </td>
        </tr>
      </tbody>
    );
  }

  render() {
    var element = document.getElementById('errorList');
    if (element != null) {
      element.style.display = 'block';
    }
    return (
      <section className="section">
        <div className="section-header">
          <h1>サマリー</h1>
        </div>

        <div className="section-body">
          <Row>
            <Col>
              <Card headerTitle="サマリー検索">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <Tabs onSelect={this.handleSelect}>
                      <TabList>
                        <Tab>面談</Tab>
                        <Tab>説明会</Tab>
                        <Tab>セミナー</Tab>
                      </TabList>
                      <TabPanel>
                        <div className="section-title">検索項目</div>
                        <FormInterview
                          onSubmit={this.clickSearchInterviewButton}
                        />
                        {this.props.state.searchResults.errorMessage != null &&
                        this.props.state.searchResults.errorMessage.length >
                          0 ? (
                          <div className="invalid-feedback" id="errorList">
                            <ul>
                              {this.props.state.searchResults.errorMessage.map(
                                (errorMessage, i) => {
                                  return <li key={i}>{errorMessage}</li>;
                                }
                              )}
                            </ul>
                          </div>
                        ) : (
                          <></>
                        )}
                        <hr />
                        {this.props.state.searchResults.interviewBases !==
                        null ? (
                          <div>
                            {this.props.state.searchResults.interviewBases
                              .length > 0 ? (
                              <>
                                <label className="row">担当拠点別</label>
                                <Row>
                                  {this.props.state.searchResults.interviewBases.map(
                                    (item, i) => {
                                      return (
                                        <>
                                          <a
                                            key={i}
                                            href="#"
                                            onClick={this.clickSearchInterviewAButton.bind(
                                              this,
                                              item.id,
                                              '1'
                                            )}
                                          >
                                            {item.name +
                                              '(' +
                                              item.reservedNum +
                                              '/' +
                                              item.registeredNum +
                                              ')'}
                                          </a>
                                          {/* eslint-disable-next-line no-irregular-whitespace */}
                                          <>　　　　</>
                                        </>
                                      );
                                    }
                                  )}
                                </Row>
                                <br />
                                <Row>※(拠点の総予約数/拠点の登録席数)</Row>
                                <hr></hr>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        {this.props.state.searchResults.interviewAreas !==
                        null ? (
                          <div>
                            {this.props.state.searchResults.interviewAreas
                              .length > 0 ? (
                              <>
                                <label className="row">表示エリア別</label>
                                <Row>
                                  {this.props.state.searchResults.interviewAreas.map(
                                    (item, i) => {
                                      return (
                                        <>
                                          <a
                                            key={i}
                                            href="#"
                                            onClick={this.clickSearchInterviewAButton.bind(
                                              this,
                                              item.id,
                                              '2'
                                            )}
                                          >
                                            {item.name +
                                              '(' +
                                              item.registeredNum +
                                              ')'}
                                          </a>
                                          {/* eslint-disable-next-line no-irregular-whitespace */}
                                          <>　　　　</>
                                        </>
                                      );
                                    }
                                  )}
                                </Row>
                                <br />
                                <Row>※(拠点の登録席数)</Row>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        {this.props.state.searchResults.interviewDays !==
                        null ? (
                          <div>
                            {this.props.state.searchResults.interviewDays
                              .length > 0 ? (
                              <>
                                <hr />
                                <table border="1">
                                  {this.getInterviewDayTableBody()}
                                </table>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        {this.props.state.searchResultsInterview.result.length >
                        0 ? (
                          <>
                            <hr />
                            拠点：{this.getSearchInterviewBaseName()}
                            <Pager
                              searchParams={
                                this.props.state.searchResultsInterview
                                  .searchParams
                              }
                              search={this.searchInterview.bind(
                                this,
                                this.props.state.searchParams.interview.id
                              )}
                            />
                            {this.props.state.searchType === '1' ? (
                              <SearchResultTable
                                headerItems={[
                                  { name: 'staff_name', value: '社員名' },
                                  { name: 'registered_num', value: '枠登録数' },
                                  { name: 'reserved_num', value: '確定数' },
                                  { name: 'reserved_num', value: '空き数' },
                                ]}
                                searchResults={
                                  this.props.state.searchResultsInterview
                                }
                                getSearchItem={this.getSearchInterviewItem}
                                search={this.searchInterview}
                              />
                            ) : (
                              <></>
                            )}
                            {this.props.state.searchType === '2' ? (
                              <SearchResultTable
                                headerItems={[
                                  { name: 'staff_name', value: '社員名' },
                                  { name: 'registered_num', value: '表示枠数' },
                                ]}
                                searchResults={
                                  this.props.state.searchResultsInterview
                                }
                                getSearchItem={this.getSearchInterviewItem}
                                search={this.searchInterview}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </TabPanel>
                      <TabPanel>
                        <div className="section-title">検索項目</div>
                        <FormSession onSubmit={this.clickSearchSessionButton} />
                        <hr />
                        {this.props.state.searchResults.eventSessionBases !==
                        null ? (
                          <div>
                            {this.props.state.searchResults.eventSessionBases
                              .length > 0 ? (
                              <>
                                <Row>
                                  {this.props.state.searchResults.eventSessionBases.map(
                                    (item, i) => {
                                      return (
                                        <>
                                          <a
                                            key={i}
                                            href="#"
                                            onClick={this.clickSearchSessionAButton.bind(
                                              this,
                                              item.id
                                            )}
                                          >
                                            {item.name +
                                              '(' +
                                              item.reservedNum +
                                              '/' +
                                              item.registeredNum +
                                              ')'}
                                          </a>
                                          {/* eslint-disable-next-line no-irregular-whitespace */}
                                          <>　　　　</>
                                        </>
                                      );
                                    }
                                  )}
                                </Row>
                                <br />
                                <Row>※(拠点の総予約数/拠点の登録席数)</Row>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        {this.props.state.searchResultsEventSession.result
                          .length > 0 ? (
                          <>
                            <hr />
                            拠点：{this.getSearchSessionBaseName()}
                            <Pager
                              searchParams={
                                this.props.state.searchResultsEventSession
                                  .searchParams
                              }
                              search={this.searchSession.bind(
                                this,
                                this.props.state.searchParams.session.id
                              )}
                            />
                            <SearchResultTable
                              headerItems={[
                                { name: 'events_name', value: '説明会名' },
                                { name: 'registered_num', value: '席数' },
                                { name: 'reserved_num', value: '予約数' },
                                { name: 'reserved_num', value: '空き数' },
                              ]}
                              searchResults={
                                this.props.state.searchResultsEventSession
                              }
                              getSearchItem={this.getSearchSessionItem}
                              search={this.searchSession}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </TabPanel>

                      <TabPanel>
                        <div className="section-title">検索項目</div>
                        <FormSeminor onSubmit={this.clickSearchSeminorButton} />
                        <hr />
                        {this.props.state.searchResults.eventSeminorBases !==
                        null ? (
                          <div>
                            {this.props.state.searchResults.eventSeminorBases
                              .length > 0 ? (
                              <>
                                <Row>
                                  {this.props.state.searchResults.eventSeminorBases.map(
                                    (item, i) => {
                                      return (
                                        <>
                                          <a
                                            key={i}
                                            href="#"
                                            onClick={this.clickSearchSeminorAButton.bind(
                                              this,
                                              item.id
                                            )}
                                          >
                                            {item.name +
                                              '(' +
                                              item.reservedNum +
                                              '/' +
                                              item.registeredNum +
                                              ')'}
                                          </a>
                                          {/* eslint-disable-next-line no-irregular-whitespace */}
                                          <>　　　　</>
                                        </>
                                      );
                                    }
                                  )}
                                </Row>
                                <br />
                                <Row>※(拠点の総予約数/拠点の登録席数)</Row>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        {this.props.state.searchResultsEventSeminor.result
                          .length > 0 ? (
                          <>
                            <hr />
                            拠点：{this.getSearchSeminorBaseName()}
                            <Pager
                              searchParams={
                                this.props.state.searchResultsEventSeminor
                                  .searchParams
                              }
                              search={this.searchSeminor.bind(
                                this,
                                this.props.state.searchParams.seminar.id
                              )}
                            />
                            <SearchResultTable
                              headerItems={[
                                { name: 'events_name', value: '説明会名' },
                                { name: 'registered_num', value: '席数' },
                                { name: 'reserved_num', value: '予約数' },
                                { name: 'reserved_num', value: '空き数' },
                              ]}
                              searchResults={
                                this.props.state.searchResultsEventSeminor
                              }
                              getSearchItem={this.getSearchSeminorItem}
                              search={this.searchSeminor}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default SummarySearch;

// import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import LoginLinkButton from '../../molecules/LoginLinkButton';
import Card from '../../organisms/Card';

class AdminPasswordResetComplete extends React.Component {
  render() {
    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>パスワード再発行</h1>
          </div>
          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="パスワード設定完了">
                  <div>
                    パスワードの設定が完了しました。<br></br>
                    <br></br>
                    ログイン画面からログインを行ってください。
                  </div>
                  <div className="text-center">
                    <LoginLinkButton href={'/login'} />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </>
    );
  }
}

export default AdminPasswordResetComplete;

import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import IsDisplay from '../../../domain/base/IsDisplay';
import FormLabel from '../../atoms/FormLabel';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import RegisterAnchorButton from '../../molecules/RegisterAnchorButton';
import ReferAccess from '../../organisms/base/ReferAccess';

class BaseEditConfirmForm extends React.Component {
  static get propTypes() {
    return {
      onSubmit: PropTypes.func,
      backToInput: PropTypes.func,
      options: PropTypes.shape({
        areas: PropTypes.array,
        prefs: PropTypes.array,
        companyNames: PropTypes.array,
      }),
      values: PropTypes.any,
      state: PropTypes.shape({
        isSubmitting: PropTypes.bool,
      }),
    };
  }

  render() {
    const getName = () => {
      return `${this.props.values.name}`;
    };

    const getDisplayName = () => {
      return `${this.props.values.displayName}`;
    };

    const getIsCaravan = () => {
      return `${this.props.values.isCaravan ? 'キャラバン拠点' : '通常拠点'}`;
    };

    const getIsDisplay = () => {
      const isDisplay = new IsDisplay(this.props.values.isDisplay);
      return `${isDisplay.displayName}`;
    };

    const getCompanyName = () => {
      return `${this.props.values.companyName}`;
    };

    const getAreaName = () => {
      console.debug('areas: ', this.props.options.areas);

      const areas = this.props.options.areas
        .filter((area) => area.value === this.props.values.areaId)
        .map((area) => area.name);
      if (areas.length > 0) {
        return areas[0];
      }

      return '';
    };

    const getZipCode = () => {
      return `${this.props.values.zipCode1}-${this.props.values.zipCode2}`;
    };

    const getPrefName = () => {
      console.debug('prefs: ', this.props.options.prefs);

      const prefs = this.props.options.prefs
        .filter((pref) => pref.value === this.props.values.prefId)
        .map((pref) => pref.name);
      if (prefs.length > 0) {
        return prefs[0];
      }

      return this.props.values.prefId;
    };

    const getCity = () => {
      return `${this.props.values.city}`;
    };

    const getBuildingName = () => {
      return this.props.values.buildingName
        ? `${this.props.values.buildingName}`
        : '';
    };

    const getTel = () => {
      return `${this.props.values.phoneNumber1}-${this.props.values.phoneNumber2}-${this.props.values.phoneNumber3}`;
    };

    const getMail = () => {
      return this.props.values.mailAddress;
    };

    const getEventDisplayLimit = () => {
      return this.props.values.eventDisplayLimit;
    };

    const getGoogleMapUrl = () => {
      return this.props.values.googleMapUrl;
    };

    const getGoogleMapShortUrl = () => {
      return this.props.values.googleMapShortUrl;
    };

    const getAccessList = () => {
      return this.props.values.accessList;
    };

    const getNearestStation = () => {
      return this.props.values.nearestStation;
    };

    return (
      <>
        <ReferItem label="拠点名">{getName()}</ReferItem>
        <ReferItem label="表示名">{getDisplayName()}</ReferItem>
        <ReferItem label="キャラバンフラグ">{getIsCaravan()}</ReferItem>
        <ReferItem label="ユーザ向け表示フラグ">{getIsDisplay()}</ReferItem>
        <ReferItem label="企業名">{getCompanyName()}</ReferItem>
        <ReferItem label="エリア">{getAreaName()}</ReferItem>
        <FormLabel className="col-sm-3 col-form-label">住所</FormLabel>
        <ReferItem label="郵便番号">{getZipCode()}</ReferItem>
        <ReferItem label="都道府県">{getPrefName()}</ReferItem>
        <ReferItem label="市区町村">{getCity()}</ReferItem>
        <ReferItem label="建物名">{getBuildingName()}</ReferItem>
        <ReferItem label="連絡先電話番号">{getTel()}</ReferItem>
        <ReferItem label="連絡先メールアドレス">{getMail()}</ReferItem>
        <ReferItem label="イベント表示上限件数">
          {getEventDisplayLimit()}
        </ReferItem>
        <FormLabel className="col-sm-3 col-form-label">地図</FormLabel>
        <ReferItem label="通常URL">
          <a href={getGoogleMapUrl()} target="_blank" rel="noopener noreferrer">
            {getGoogleMapUrl()}
          </a>
        </ReferItem>
        <ReferItem label="短縮URL">
          <a
            href={getGoogleMapShortUrl()}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getGoogleMapShortUrl()}
          </a>
        </ReferItem>

        <ReferAccess accessList={getAccessList()}></ReferAccess>

        <ReferItem label="最寄駅">{getNearestStation()}</ReferItem>

        <hr />
        <Row>
          <div className="mx-auto">
            <PrevButton onClick={this.props.backToInput} />
            <RegisterAnchorButton
              onClick={this.props.onSubmit}
              isSubmitting={this.props.state.isSubmitting}
            />
          </div>
        </Row>
      </>
    );
  }
}

export default connect((state) => {
  return { state: state.base.baseEdit, options: state.options };
})(BaseEditConfirmForm);

import {
  CategorySearch,
  CategoryRefer,
  CategoryEdit,
} from '../../../containers/Category';

import routePath from './routePath';

const CategoryRoutes = [
  {
    path: routePath.search,
    exact: true,
    component: CategorySearch,
  },
  {
    path: routePath.refer(':id'),
    component: CategoryRefer,
  },
  {
    path: routePath.newEdit,
    component: CategoryEdit,
    exact: true,
  },
  {
    path: routePath.edit(':id'),
    component: CategoryEdit,
  },
];

export default CategoryRoutes;

import { connect } from 'react-redux';

import {
  makeSearch,
  makeFetchCategory,
  makeInitCategoryEdit,
  // hideLoading,
  makeConfirmIsUsed,
  makeHideContainsAlertModal,
  makeHideDeleteConfirmModal,
  makeDeleteCategories,
  makeHideDeleteCompleteModal,
  makeSetEditValues,
  makeBackToInput,
  makeUpdateCategory,
  makeInitCategory,
} from '../actions/Category';
import CategoryComponent from '../components/pages/category/Category';
import CategoryEditComponent from '../components/pages/category/CategoryEdit';
import CategoryReferComponent from '../components/pages/category/CategoryRefer';
import CategorySearchComponent from '../components/pages/category/CategorySearch';

const mapStateToProps = (state) => {
  return {
    state: state.category,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search(values, searchParams) {
      dispatch(makeSearch(values, searchParams));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryComponent);

const CategorySearch = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initFetchSearchPage(isComeFromOtherPage) {
      dispatch(makeInitCategory(isComeFromOtherPage));
    },

    confirmIsUsed(categoryIds) {
      dispatch(makeConfirmIsUsed(categoryIds));
    },
    onAlertModalCloseButtonClick() {
      dispatch(makeHideContainsAlertModal());
    },
    hideDeleteConfirmModal() {
      dispatch(makeHideDeleteConfirmModal());
    },
    deleteCategories(ids) {
      dispatch(makeDeleteCategories(ids));
    },
    hideDeleteCompleteModal(searchValues) {
      dispatch(makeHideDeleteCompleteModal(searchValues));
    },
  };
})(CategorySearchComponent);

const CategoryRefer = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchCategory(categoryId) {
      dispatch(makeFetchCategory(categoryId));
    },
  };
})(CategoryReferComponent);

const CategoryEdit = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initCategoryEdit(tagId) {
      dispatch(makeInitCategoryEdit(tagId));
    },
    // fetchTag(tagId) {
    //   dispatch(makeFetchCategory(tagId));
    // },
    setEditValues(values) {
      dispatch(makeSetEditValues(values));
    },
    backToInput() {
      dispatch(makeBackToInput());
    },
    updateCategory(values) {
      dispatch(makeUpdateCategory(values));
    },
  };
})(CategoryEditComponent);

export { CategorySearch, CategoryRefer, CategoryEdit };

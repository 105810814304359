import PropTypes from 'prop-types';
import React from 'react';

class ColAlignRight extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
    };
  }

  render() {
    return (
      <div className="col" style={{ textAlign: 'Left' }}>
        {this.props.children}
      </div>
    );
  }
}

export default ColAlignRight;

import { UserSearch, UserRefer } from '../../../containers/User';

import routePath from './routePath';

const UserRoutes = [
  {
    path: routePath.search,
    exact: true,
    component: UserSearch,
  },
  {
    path: routePath.refer(':id'),
    component: UserRefer,
  },
];

export default UserRoutes;

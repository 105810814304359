import { push } from 'connected-react-router';
import { trackPromise } from 'react-promise-tracker';
import { change } from 'redux-form';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';
import { IdType } from '../components/organisms/reservationManagement/SearchUserModal';
import ConsultationPlanRoutePath from '../components/pages/consultationPlan/routePath';

import createAxios from './axios';
import { fetchErrorData } from './common';

/**
 * @type {number} イベント検索のパラメータ
 */
const SEARCH_TYPE_EVENTS = 0;

/**
 * @type {number} 個別面談検索のパラメータ
 */
const SEARCH_TYPE_INTERVIEW = 1;

const axios = createAxios('/admin/reservation');

/**
 * fetchError
 * @param {import('axios').AxiosError} err
 */
const fetchError = (err) => {
  if (err.response) {
    if (err.response.status === 400) {
      return {
        type: 'reservation/BAD_REQUEST',
        payload: err.response.data,
      };
    }
  }

  return fetchErrorData(err);
};

/**
 * @typedef {Object} Area エリア
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} AreaResponse areasのレスポンス
 * @property {Array.<Area>} areaList
 */

/**
 * (エリア)fetchAreas
 * @returns {import('axios').AxiosResponse<AreaResponse>}
 */
const fetchAreas = () => {
  return axios.get(`/admin/areas`);
};

/**
 * @template T
 * @typedef {Object} ActionResult
 * @property {T} result
 */

/**
 *
 * @param {AreaResponse} result
 * @returns {Action<ActionResult<Array.<Option>>>}
 */
const fetchAreasSuccess = (result) => {
  return new Action('FETCH_AREAS', {
    result: new OptionCollection(
      result.areaList.map((area) => {
        return new Option(area.id, area.name);
      })
    ).getOptions(),
  });
};

/**
 * areaにリクエストするPromiseを生成する
 * @returns {Promise<any>}
 */
const makeFetchAreas = () => {
  return (dispatch) => {
    return fetchAreas()
      .then((data) => dispatch(fetchAreasSuccess(data.data)))
      .catch((err) => dispatch(fetchError(err)));
  };
};

/**
 * @typedef {Object} Base 拠点
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} BaseResponse basesのレスポンス
 * @property {Array.<Base>} bases
 */

/**
 * (拠点)fetchBases
 * @returns {Promise<import('axios').AxiosResponsese<BaseResponse>>}
 */
const fetchBases = () => {
  return axios.get(`/admin/bases`, {
    params: {
      isCaravan: false,
    },
  });
};

/**
 *
 * @param {BaseResponse} result
 * @returns {Action<ActionResult<Array.<Option>>>}
 */
const fetchBasesSuccess = (result) => {
  return new Action('FETCH_BASES', {
    result: new OptionCollection(
      result.bases.map((base) => {
        return new Option(base.id, base.name);
      })
    ).getOptions(),
  });
};

/**
 * baseのリクエストのPromiseを生成する
 * @returns {Promise<any>}
 */
const makeFetchBases = () => {
  return (dispatch) => {
    return fetchBases()
      .then((data) => dispatch(fetchBasesSuccess(data.data)))
      .catch((err) => dispatch(fetchError(err)));
  };
};

/**
 * @typedef {Object} Category
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} CategoryResponse
 * @property {Array.<Category>} categoryList
 */

/**
 * (カテゴリー)fetchCategories
 * @returns {Promise<import('axios').AxiosResponse<CategoryResponse>>}
 */
const fetchCategories = () => {
  return axios.get('/admin/categories');
};

/**
 *
 * @param {CategoryResponse} res
 * @returns {Action<ActionResult<Array.<Option>>>}
 */
const fetchCategoriesSuccess = (res) => {
  return new Action('FETCH_CATEGORIES', {
    result: new OptionCollection(
      res.categoryList.map((category) => {
        return new Option(category.ID, category.Name);
      })
    ).getOptionsWithoutEmpty(),
  });
};

/**
 * categoryのリクエストのPromiseを生成する
 * @returns {Promise<any>}
 */
const makeFetchCategories = () => {
  return (dispatch) => {
    return fetchCategories()
      .then((data) => dispatch(fetchCategoriesSuccess(data.data)))
      .catch((err) => dispatch(fetchError(err)));
  };
};

/**
 * @typedef {Object} Hall
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} HallResponse
 * @property {Array.<Hall>} hallList
 */

/**
 * (会場)fetchHallsById
 * @param {number} areaId エリアID
 * @returns {Promise<import('axios').AxiosResponse<HallResponse>>}
 */
const fetchHallsById = (areaId) => {
  const params = new Params({
    pageSize: 99999999,
    areaId: areaId,
  });
  return axios.get(`/admin/halls`, {
    params: params.removeParams(),
  });
};

/**
 *
 * @param {HallResponse} res
 * @returns {Action<ActionResult<Array.<Option>>>}
 */
const fetchHallsByIdSuccess = (res) => {
  /**
   * @type {Array.<Option>}
   */
  var halls;

  if (res.hallList && res.hallList.length > 0) {
    halls = new OptionCollection(
      res.hallList.map((hall) => {
        return new Option(hall.id, hall.name);
      })
    ).getOptions();
  } else {
    halls = [];
  }

  return new Action('FETCH_HALLS', {
    result: halls,
  });
};

/**
 * fetchHallsByIdへリクエストするPromiseを生成する
 * @param {number} areaId エリアID
 * @returns {Promise<any>}
 */
const makeFetchHallsById = (areaId) => {
  return (dispatch) => {
    return fetchHallsById(areaId)
      .then((data) => dispatch(fetchHallsByIdSuccess(data.data)))
      .catch((err) => dispatch(fetchError(err)));
  };
};

/**
 * @returns {EmptyAction}
 */
const makeInitStateHalls = () => {
  return new EmptyAction('INIT_STATE_FETCH_HALLS');
};

/**
 * @typedef {Object} Tag
 * @property {string} id
 * @property {string} name
 */

/**
 * (タグ)fetchTags
 * @returns {Promise<import('axios').AxiosResponse<Array.<Tag>>}
 */
const fetchTags = () => {
  return axios.get(`/admin/tags`);
};

/**
 *
 * @param {Array.<Tag>} res
 * @returns {Action<ActionResult<Array.<Option>>>}
 */
const fetchTagsSuccess = (res) => {
  return new Action('option/FETCH_TAGS', {
    result: new OptionCollection(
      res.map((tag) => {
        return new Option(tag.id, tag.name);
      })
    ).getOptionsWithoutEmpty(),
  });
};

/**
 * tagsのリクエストのPromiseを生成する
 * @returns {Proimse<any>}
 */
const makeFetchTags = () => {
  return (dispatch) => {
    return fetchTags()
      .then((data) => dispatch(fetchTagsSuccess(data.data)))
      .catch((err) => dispatch(fetchError(err)));
  };
};

/**
 * @typedef {Object} EventType
 * @property {string} id
 * @property {string} name
 */

/**
 * @returns {Promise<import('axios').AxiosResponse<Array.<EventType>>>}
 */
const fetchEventTypes = () => {
  return axios.get('/event_types');
};

/**
 *
 * @param {Array.<EventType>} data
 * @returns {Action<ActionResult<Array.<Option>>>}
 */
const fetchEventTypesSuccess = (data) => {
  return new Action('option/FETCH_EVENT_TYPE', {
    result: new OptionCollection(
      data.map((option) => {
        return new Option(option.id, option.name);
      })
    ).getOptionsWithoutEmpty(),
  });
};

/**
 * eventTypeのリクエストのPromiseを生成する
 * @returns {Promise<any>}
 */
const makeFetchEventTypes = () => {
  return (dispatch) => {
    return fetchEventTypes()
      .then((res) => dispatch(fetchEventTypesSuccess(res.data)))
      .catch((err) => dispatch(fetchError(err)));
  };
};

/**
 * @typedef ReservationEventsValues
 * @property {Array.<string>} tagsIds
 * @property {Array.<string>} eventTypeIds
 * @property {Array.<string>} categoryIds
 * @property {string} chairman
 * @property {string} areaId
 * @property {string} baseId
 * @property {string} venueId
 */

/**
 *
 * @param {ReservationEventsValues} values
 * @returns {Promise<import('axios').AxiosResponse<any>}
 */
const fetchReservationsEvents = (values) => {
  var tagsIds;
  if (values.tagsIds.length > 0) {
    tagsIds = values.tagsIds.reduce((acc, value) => {
      return acc + ',' + value;
    });
  }

  var eventTypeIds;
  if (values.eventTypeIds.length > 0) {
    eventTypeIds = values.eventTypeIds.reduce((acc, value) => {
      return acc + ',' + value;
    });
  }

  var categoryIds;
  if (values.categoryIds.length > 0) {
    categoryIds = values.categoryIds.reduce((acc, value) => {
      return acc + ',' + value;
    });
  }

  const params = new Params({
    chairmanName: values.chairman,
    eventTypeIds: eventTypeIds,
    categoryIds: categoryIds,
    tagsIds: tagsIds,
    areaId: values.areaId,
    baseId: values.baseId,
    venueId: values.venueId,
    // 固定値
    searchType: SEARCH_TYPE_EVENTS,
  });

  console.trace('fetchReservationEvents');
  return axios.get(`/admin/reservations`, {
    params: params.removeParams(),
  });
};

/**
 *
 * @param {Object} data
 * @returns {Action<ActionResult<any>>}
 */
const fetchReservationEventsSuccess = (data) => {
  return new Action('reservation/FETCH_RESERVATION_EVENTS', {
    result: data,
  });
};

/**
 *
 * @param {ReservationEventsValues} values
 * @returns {Promise<any>}
 */
const makeFetchReservationEvents = (values) => {
  return (dispatch) => {
    dispatch({
      type: 'reservation/STORE_SEARCH_EVENTS_VALUES',
      payload: values,
    });
    return trackPromise(
      fetchReservationsEvents(values)
        .then((data) => dispatch(fetchReservationEventsSuccess(data.data)))
        .catch((err) => dispatch(fetchError(err)))
    );
  };
};

/**
 * @typedef {Object} ReservationInterviewsValues
 * @property {Array.<string>} isMatch
 * @property {string} staffId
 * @property {Array.<string>} categoryIds
 * @property {string} areaId
 * @property {string} baseId
 * @property {string} venueId
 */

/**
 *
 * @param {ReservationInterviewsValues} values
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetchReservationInterviews = (values) => {
  let isMatch;
  if (values && values.isMatch) {
    if (values.isMatch.length > 0 && values.isMatch.length < 2) {
      isMatch = values.isMatch[0] === '1';
    }
  }
  let isUrlRegistered;
  if (values && values.isUrlRegistered) {
    if (
      values.isUrlRegistered.length > 0 &&
      values.isUrlRegistered.length < 2
    ) {
      isUrlRegistered = values.isUrlRegistered[0] === '1';
    }
  }

  let params;
  if (values) {
    params = new Params({
      chairmanName: values.chairman,
      staffId: values.staffId,
      categoryIds: values.categoryIds,
      areaId: values.areaId,
      baseId: values.baseId,
      venueId: values.venueId,
      isMatch: isMatch,
      isUrlRegistered: isUrlRegistered,
      searchType: SEARCH_TYPE_INTERVIEW,
    }).removeParams();
  }

  console.trace('fetchReservationInterviews');
  return axios.get(`/admin/reservations`, {
    params: params,
  });
};

/**
 *
 * @param {ReservationInterviewsValues} values
 * @param {Object} data
 * @returns {Action<ActionResult<Object>>}
 */
const fetchReservationInterviewsSuccess = (values, data) => {
  return new Action('reservation/FETCH_RESERVATION_INTERVIEWS', {
    result: data,
    values: values,
  });
};

const storeInterviewSearchValues = (values) => {
  return new Action('reservation/STORE_INTERVIEW_SEARCH_VALUES', values);
};

/**
 *
 * @param {ReservationInterviewsValues} values
 * @returns {Promise<any>}
 */
const makeFetchReservationInterviews = (values) => {
  return (dispatch) => {
    dispatch(storeInterviewSearchValues(values));

    return trackPromise(
      fetchReservationInterviews(values)
        .then((data) =>
          dispatch(fetchReservationInterviewsSuccess(values, data.data))
        )
        .catch((err) => dispatch(fetchError(err)))
    );
  };
};

/**
 *
 * @param {string} id イベントID
 * @returns {Promise<AxiosResponse<Object>>}
 */
const fetchReservationEventsDetail = (id) => {
  return axios.get(`/admin/events/${id}`);
};

/**
 *
 * @param {Object} data
 * @returns {Action<ActionResult<Object>>}
 */
const fetchReservationEventsDetailSuccess = (data) => {
  return new Action('reservation/FETCH_RESERVATION_EVENTS_DETAIL', {
    result: data,
  });
};

/**
 *
 * @param {string} id イベントID
 * @param {Date} selectedDate
 * @returns {Promise<any>}
 */
const makeFetchReservationEventsDetail = (id, selectedDate) => {
  return (dispatch) => {
    dispatch(
      new Action('reservation/STORE_EVENTS_SEARCH_SELECTED_EVENT', selectedDate)
    );
    return trackPromise(
      fetchReservationEventsDetail(id)
        .then((data) =>
          dispatch(fetchReservationEventsDetailSuccess(data.data))
        )
        .catch((err) => dispatch(fetchError(err)))
    );
  };
};

/**
 *
 * @param {string} id 個人面談ID
 * @returns {Promise<import('axios').AxiosResponse<Object>>}
 */
const fetchReservationInterviewsDetail = (id) => {
  return axios.get(`/admin/interviewavailable/${id}`);
};

/**
 *
 * @param {Object} data
 * @returns {Action<ActionResult<Object>>}
 */
const fetchReservationInterviewsDetailSuccess = (data) => {
  return new Action('reservation/FETCH_RESERVATION_INTERVIEWS_DETAIL', {
    result: data,
  });
};

/**
 * @param {string} id 個人面談ID
 * @param {Date} selectedDate
 * @returns {Promise<Object>}
 */
const makeFetchReservationInterviewsDetail = (id, selectedDate) => {
  return (dispatch) => {
    dispatch(
      new Action(
        'reservation/STORE_INTERVIEW_SEARCH_SELECTED_EVENT',
        selectedDate
      )
    );
    return trackPromise(
      fetchReservationInterviewsDetail(id)
        .then((data) => {
          dispatch(fetchReservationInterviewsDetailSuccess(data.data));
        })
        .catch((err) => dispatch(fetchError(err)))
    );
  };
};

/**
 *
 * @param {Object} data
 * @param {string} userId
 * @param {UserProfileRes} userProfileRes
 */
const fetchReservationInterviewsEditDetailSuccess = (
  data,
  userId,
  userProfileRes
) => {
  if (userProfileRes) {
    return new Action('reservation/FETCH_RESERVATION_INTERVIEWS_EDIT', {
      result: {
        data: data,
        user: {
          id: userId,
          fullName:
            userProfileRes.data.profileBaseInfo.familyName +
            userProfileRes.data.profileBaseInfo.firstName,
        },
      },
    });
  }
  return new Action('reservation/FETCH_RESERVATION_INTERVIEWS_EDIT', {
    result: {
      data: data,
    },
  });
};

/**
 * @param {string} id 個人面談ID
 * @param {string} userId 代理会員ID
 * @returns {Promise<Object>}
 */
const makeFetchReservationInterviewsEditDetail = (id, userId) => {
  return async (dispatch) => {
    dispatch(new EmptyAction('reservation/FETCH_INTERVIEW_EDIT_DETAIL_INIT'));
    try {
      let userProfileRes;
      if (userId) {
        userProfileRes = await fetchUserProfile(userId);
      }

      const data = await fetchReservationInterviewsDetail(id);
      dispatch(
        fetchReservationInterviewsEditDetailSuccess(
          data.data,
          userId,
          userProfileRes
        )
      );
      dispatch(
        new EmptyAction('reservation/FETCH_INTERVIEW_EDIT_DETAIL_COMPLETE')
      );
    } catch (err) {
      return dispatch(fetchError(err));
    }
  };
};

/**
 *
 * @param {Object} data
 * @param {string} userId
 * @param {UserProfileRes} userProfileRes
 * @returns {Action<ActionResult<Object>>}
 */
const fetchResetvationEventsEditDetailSuccess = (
  data,
  userId,
  userProfileRes
) => {
  if (userProfileRes) {
    return new Action('reservation/FETCH_RESERVATION_EVENTS_EDIT', {
      result: {
        data: data,
        user: {
          userId,
          userName: userProfileRes.data.profileBaseInfo.name,
        },
      },
    });
  }

  return new Action('reservation/FETCH_RESERVATION_EVENTS_EDIT', {
    result: {
      data: data,
    },
  });
};

/**
 *
 * @param {string} id イベントID
 * @param {string} userId 会員ID
 * @returns {Promise<Object>}
 */
const makeFetchReservationEventsEditDetail = (id, userId) => {
  return async (dispatch) => {
    dispatch(new EmptyAction('reservation/FETCH_EVENTS_EDIT_DETAIL'));
    try {
      let userProfileRes;
      if (userId) {
        userProfileRes = await fetchUserProfile(userId);
        dispatch(fetchUserProfileSuccess(userId, userProfileRes.data));
      }

      const eventTypeRes = await fetchEventTypes();
      dispatch(fetchEventTypesSuccess(eventTypeRes.data));

      const data = await fetchReservationEventsDetail(id);
      dispatch(
        fetchResetvationEventsEditDetailSuccess(
          data.data,
          userId,
          userProfileRes
        )
      );
      dispatch(
        new EmptyAction('reservation/FETCH_EVENTS_EDIT_DETAIL_COMPLETE')
      );
    } catch (err) {
      return dispatch(fetchError(err));
    }
  };
};

/**
 * @typedef {Object} Participant
 * @property {Array.<string>} participants 面談者
 * @property {Array.<string>} deleteParticipants 削除面談者
 */

/**
 *
 * @param {string} eventId イベントID
 * @param {Participant} values
 * @returns {Promise<import('axios').AxiosResponse<Object>>}
 */
const updateEventsEditDetail = (eventId, values) => {
  const participantIds = values.participants.map(
    (participant) => participant.id
  );
  const deleteParticipantIds = values.deleteParticipants.map(
    (participant) => participant.id
  );
  return axios.put(`/admin/reservations/events/${eventId}`, {
    participants: participantIds,
    deleteParticipants: deleteParticipantIds,
  });
};

/**
 *
 * @param {Object} data
 * @returns {Action<ActionResult<Object>>}
 */
const updateEventsEditDetailSuccess = (data) => {
  return new Action('reservation/EVENT_EDIT_DETAIL', {
    result: data,
  });
};

const updateEventEditDetailFailure = (err) => {
  if (err.response && err.response.status === 400 && err.response.data) {
    return new Action(
      'reservation/UPDATE_EVENT_EDIT_DETAIL_FAILURE',
      err.response.data.message
    );
  }

  return fetchError(err);
};

const updateInterviewEditDetailFailure = (err) => {
  if (err.response && err.response.status === 400 && err.response.data) {
    return new Action(
      'reservation/UPDATE_INTERVIEW_EDIT_DETAIL_FAILURE',
      err.response.data
    );
  }

  return fetchError(err);
};

/**
 *
 * @param {string} eventId イベントID
 * @param {Array.<string>} values 参加者ID
 * @returns {Promise<Object>}
 */
const makeUpdateEventsEditDetail = (eventId, values) => {
  return (dispatch) => {
    return trackPromise(
      updateEventsEditDetail(eventId, values)
        .then((data) => dispatch(updateEventsEditDetailSuccess(data.data)))
        .catch((err) => dispatch(updateEventEditDetailFailure(err)))
    );
  };
};

/**
 * @typedef {Object} Participant
 * @property {string} userId
 * @property {string} userName
 */

/**
 * @typedef {Object} UpdateInterviewsEditValues
 * @property {Participant[]} participants 面談者
 * @property {Participant[]} deleteParticipants 削除面談者
 * @property {string} url ウェブ面談URL
 */

/**
 *
 * @param {string} interviewId 個人面談ID
 * @param {UpdateInterviewsEditValues} values
 * @returns {Promise<import('axios').AxiosResponse<Object>>}
 */
const updateInterviewsEditDetail = (interviewId, values) => {
  const intervieweeIds = [];
  const deleteIntervieweeIds = [];

  if (values) {
    values.interviewees.forEach((interviewee) => {
      intervieweeIds.push(interviewee.id);
    });
  }
  if (values) {
    values.deleteInterviewees.forEach((interviewee) => {
      deleteIntervieweeIds.push(interviewee.id);
    });
  }

  const params = new Params({
    intervieweeIds: intervieweeIds,
    deleteIntervieweeIds: deleteIntervieweeIds,
    webMeetingUrl: values.url,
  });
  return axios.put(
    `/admin/reservations/interview/${interviewId}`,
    params.removeParams()
  );
};

/**
 *
 * @param {Object} data
 * @returns {Action<ActionResult<Object>>}
 */
const updateInterviewsEditDetailSuccess = (data) => {
  return new Action('reservation/INTERVIEWS_EDIT_DETAIL', {
    result: data,
  });
};

/**
 * @param {string} interviewId
 * @param {UpdateInterviewsEditValues} values
 * @returns {Promise<Object>}
 */
const makeUpdateInterviewsEditDetail = (interviewId, values) => {
  return (dispatch) => {
    return trackPromise(
      updateInterviewsEditDetail(interviewId, values)
        .then((data) => dispatch(updateInterviewsEditDetailSuccess(data.data)))
        .catch((err) => dispatch(updateInterviewEditDetailFailure(err)))
    );
  };
};

/**
 *
 * @param {Object} values
 * @returns {Promise<import('axios').AxiosResponse<Object>>}
 */
const searchUsers = (values) => {
  let SFID = values.sfId;
  let company = values.company;
  if (values.searchCode === IdType.Lead) {
    SFID = null;
    company = null;
  }
  const params = new Params({ ...values, sfId: SFID, company: company });

  return axios.get(`/admin/users`, {
    params: params.removeParams(),
    headers: {
      'X-Referer': '/admin/reservation',
    },
  });
};

/**
 *
 * @param {Object} data
 * @returns {Action<ActionResult<Object>>}
 */
const searchUsersSuccess = (data) => {
  return new Action('reservation/SEARCH_USERS', {
    result: data,
  });
};

/**
 *
 * @param {Object} values
 * @returns {Promise<Object>}
 */
const makeSearchUsers = (values) => {
  return (dispatch) => {
    return trackPromise(
      searchUsers(values)
        .then((data) => dispatch(searchUsersSuccess(data.data)))
        .catch((err) => dispatch(fetchError(err)))
    );
  };
};

/**
 * @returns {EmptyAction}
 */
const hideEventsDetailModal = () => {
  return new EmptyAction('reservation/HIDE_EVENTS_DETAIL_MODAL');
};

/**
 * @returns {EmptyAction}
 */
const hideInterviewDetailModal = () => {
  return new EmptyAction('reservation/HIDE_INTERVIEW_DETAIL_MODAL');
};

/**
 * @returns {EmptyAction}
 */
const initUserSearch = () => {
  return new EmptyAction('reservation/INIT_USER_SEARCH');
};

/**
 *
 * @param {Object} values
 * @returns {Action<Object>}
 */
const setEventsEditParticipants = (values) => {
  return new Action('reservation/SET_EVENTS_EDIT_PARTICIPANTS', values);
};

/**
 *
 * @param {Object} values
 * @returns {Action<Object>}
 */
const setInterviewInterviewees = (values) => {
  return new Action('reservation/SET_INTERVIEW_EDIT_INTERVIEWEES', values);
};

/**
 * @typedef {Object} ProfileResData
 * @property {string} familyName 姓
 * @property {string} firstName 名
 */

/**
 * profileにリクエストする
 * @returns {Promise<import('axios').AxiosResponse<ProfileResData>>}
 */
const fetchProfile = () => {
  return axios.get('/admin/profile', {
    headers: {
      'X-Referer': '/admin/reservation',
    },
  });
};

/**
 * profileのリクエストのPromiseを作成する
 * @returns {Promise<any>}
 */
const makeFetchProfile = () => {
  return (dispatch) => {
    return fetchProfile()
      .then((res) => {
        fetchProfileSuccess(dispatch, res.data);
      })
      .catch((err) => dispatch(fetchError(err)));
  };
};

/**
 * @typedef {Object} Profile
 * @property {string} familyName
 * @property {string} firstName
 */

/**
 * profile取得成功時のアクションを返す
 * @param {ProfileResData} data
 */
const fetchProfileSuccess = (dispatch, data) => {
  const familyName = data.familyName;
  const firstName = data.firstName;
  dispatch(
    change(
      'ReservationSearchEventsForm',
      'chairman',
      `${familyName}${firstName}`
    )
  );
  dispatch(
    change(
      'ReservationSearchInterviewsForm',
      'chairman',
      `${familyName}${firstName}`
    )
  );
};

/**
 * @typedef {Object} MethodType
 * @property {string} id
 * @property {string} name
 */

/**
 * @returns {Promise<import('axios').AxiosResponse<Array.<MethodType>>>}
 */
const fetchMethodType = () => {
  return axios.get('/options/method_types');
};

/**
 * @param {Array.<MethodType>} data
 * @returns {Action<Array.<MethodType>>}
 */
const fetchMethodTypeSuccess = (data) => {
  const optionCollection = new OptionCollection(
    data.map((option) => {
      return new Option(option.id, option.name);
    })
  );
  return new Action('OPTIONS/METHOD_TYPE', optionCollection.getOptions());
};

/**
 * @returns {Promise<any>}
 */
const makeFetchMethodTypes = () => {
  return async (dispatch) => {
    try {
      const res = await fetchMethodType();
      return dispatch(fetchMethodTypeSuccess(res.data));
    } catch (err) {
      return dispatch(fetchError(err));
    }
  };
};

/**
 * 編集で設定した個人面談の面談者を未設定にする
 * @returns {EmptyAction}
 */
const deleteIntervieweeParticipant = () => {
  return change('reservationManagementInterviewEdit', 'participant', null);
};

/**
 * @typedef {Object} User
 * @property {string} userId
 * @property {string} userName
 */

/**
 * 渡されたid, nameをもとに個人面談の面談者を設定する
 * @param {string} id ユーザーID
 * @param {string} name ユーザー名
 * @returns {import('redux-form').FormAction}
 */
const addIntervieweeParticipant = (id, name) => {
  return change('reservationManagementInterviewEdit', 'participant', {
    userId: id,
    userName: name,
  });
};

const initStoreSearchValues = () => {
  return new EmptyAction('reservation/INIT_STORE_SEARCH_VALUES');
};

/**
 * @typedef {Object} UserProfile
 * @property {string} name
 */

/**
 * @typedef {Object} UserProfileRes
 * @property {string} id 会員ID
 * @property {UserProfile} profile
 */

/**
 *
 * @param {string} id 会員ID
 * @returns {Promise<import('axios').AxiosResponse<UserProfileRes>>}
 */
const fetchUserProfile = (id) => {
  return axios.get(`/admin/users/${id}`);
};

/**
 *
 * @param {param} id
 * @param {UserProfileRes} data
 * @returns {Action<Object>}
 */
const fetchUserProfileSuccess = (id, data) => {
  const user = {
    id: id,
    name:
      data.profileBaseInfo.familyName + ' ' + data.profileBaseInfo.firstName,
  };
  return new Action('reservation/FETCH_USER_PROFILE', user);
};

/**
 * /admin/usersへのリクエストのPromiseを作成する
 * @param {string} id 会員ID
 * @returns {Object}
 */
const makeFetchUserProfile = (id) => {
  return async (dispatch) => {
    try {
      const res = await fetchUserProfile(id);
      return dispatch(fetchUserProfileSuccess(id, res.data));
    } catch (err) {
      return fetchError(err);
    }
  };
};

/**
 * 代理予約状態を解除する
 * @returns {EmptyAction}
 */
const clearSubstitute = () => {
  return new EmptyAction('reservation/CLEAR_SUBSTITUTION');
};

/**
 * イベント編集時の代理予約の状態を解除する
 * @return {Action<string>}
 */
const clearSubstituteEventEdit = (userId) => {
  return new Action('reservation/CLEAR_SUBSTITTUION_EVENT', userId);
};

/**
 * 個人面談編集時の代理予約の状態を解除する
 * @param {string} userId  会員Id
 */
const clearSubstituteInterviewEdit = (userId) => {
  return new Action('reservation/CLEAR_SUBSTITUTION_INTERVIEW', userId);
};

/**
 * @returns {EmptyAction}
 */
const initSubstituteUser = () => {
  return new EmptyAction('reservation/INIT_SUBSTITUTE_USER');
};

const fetchCompaniesOptions = () => {
  return axios.get('/options/company_names');
};

const fetchCompaniesOptionsSuccess = (data) => {
  const options = new OptionCollection(
    data.map((companyName) => {
      return new Option(companyName.id, companyName.name);
    })
  ).getOptions();
  return new Action('option/FETCH_COMPANY_NAMES', { result: options });
};

const makeFetchCompaniesOptions = () => {
  return async (dispatch) => {
    try {
      const companiesOptions = await fetchCompaniesOptions();
      dispatch(fetchCompaniesOptionsSuccess(companiesOptions.data));
    } catch (err) {
      fetchErrorData(err);
    }
  };
};

const clearSearchUserResult = () => {
  return new EmptyAction('reservation/INIT_USER_SEARCH');
};

const makeTransitionToInterview = (
  interviewId,
  interviewerId,
  selectedDate
) => {
  return (dispatch) => {
    dispatch(
      push(
        ConsultationPlanRoutePath.referByDetail(
          interviewId,
          interviewerId,
          selectedDate.toISOString()
        )
      )
    );
  };
};

const makeSetInitializeVenueSelect = (formName) => {
  return change(formName, 'venueId', '');
};

export {
  makeFetchAreas,
  makeFetchBases,
  makeFetchCategories,
  makeFetchTags,
  makeFetchEventTypes,
  makeFetchMethodTypes,
  makeFetchHallsById,
  makeFetchReservationEvents,
  makeInitStateHalls,
  makeFetchReservationInterviews,
  makeFetchReservationEventsDetail,
  makeFetchReservationInterviewsDetail,
  makeFetchReservationEventsEditDetail,
  makeFetchReservationInterviewsEditDetail,
  makeUpdateEventsEditDetail,
  makeUpdateInterviewsEditDetail,
  makeSearchUsers,
  makeFetchUserProfile,
  hideEventsDetailModal,
  hideInterviewDetailModal,
  initUserSearch,
  setEventsEditParticipants,
  setInterviewInterviewees,
  deleteIntervieweeParticipant,
  addIntervieweeParticipant,
  makeFetchProfile,
  initStoreSearchValues,
  clearSubstitute,
  clearSubstituteEventEdit,
  clearSubstituteInterviewEdit,
  initSubstituteUser,
  makeFetchCompaniesOptions,
  clearSearchUserResult,
  makeTransitionToInterview,
  makeSetInitializeVenueSelect,
};

import PropTypes from 'prop-types';
import React from 'react';

import PrimaryIconButton from '../atoms/PrimaryIconButton';
import { EditAction } from '../utils/PermissionComponent';

class SaveButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    };
  }

  render() {
    return (
      <EditAction>
        <PrimaryIconButton
          onClick={this.props.onClick}
          disabled={this.props.disabled}
        >
          <i className="far fa-edit" />
          保存
        </PrimaryIconButton>
      </EditAction>
    );
  }
}

export default SaveButton;

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';

import EventType from '../../../domain/EventType';
import Card from '../../organisms/Card';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const SeminarColor = '#fdae13';
const SessionColor = '#8ec31f';

const localizer = momentLocalizer(moment);

class MemoizeCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.events = this.props.events;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const shouldUpdate = this.events !== nextProps.events;

    if (shouldUpdate) {
      this.events = nextProps.events;
    }
    return shouldUpdate;
  }

  render() {
    const setEventsCalendarValues = () => {
      const events = this.props.events;
      // データがない場合は空配列のまま返却
      if (!events.length > 0) {
        return [];
      }
      const eventsList = events.map((event) => {
        const fromDateTime = new Date(event.from);
        const toDateTime = new Date(event.to);
        let title = event.title;

        // 空席フラグがfalseの場合は[満]と表示
        if (!event.existsSeats) {
          title = '[ 満 ]' + title;
        }

        const eventType = new EventType(event.eventType);

        return {
          id: event.id,
          title: title,
          allDay: false,
          start: fromDateTime,
          end: toDateTime,
          color: eventType.isSeminar() ? SeminarColor : SessionColor,
        };
      });

      return eventsList;
    };

    return (
      <Calendar
        selectable
        localizer={localizer}
        events={setEventsCalendarValues()}
        timeslots={2}
        defaultView={Views.WEEK}
        onSelectEvent={this.props.onSelectEvent}
        style={{ height: 500 }}
        eventPropGetter={this.props.eventPropGetter}
        defaultDate={this.props.defaultDate}
      />
    );
  }
}

MemoizeCalendar.propTypes = {
  events: PropTypes.any,
  onSelectEvent: PropTypes.func,
  eventPropGetter: PropTypes.func,
  defaultDate: PropTypes.any,
};

class SearchEventsResultCalendar extends React.Component {
  static get propTypes() {
    return {
      options: PropTypes.shape({
        areas: PropTypes.array,
        halls: PropTypes.array,
        categories: PropTypes.array,
        tags: PropTypes.array,
        bases: PropTypes.array,
      }),
      eventsList: PropTypes.array,
      searchParams: PropTypes.object,
      clickToolBar: PropTypes.func,
      fetchAreas: PropTypes.func,
      fetchBases: PropTypes.func,
      fetchCategories: PropTypes.func,
      fetchTags: PropTypes.func,
      fetchHallsByAreaId: PropTypes.func,
      fetchReservationEventsDetail: PropTypes.func,
      onClickEvent: PropTypes.func,
      defaultDate: PropTypes.Date,
    };
  }

  constructor(props) {
    super(props);
    this.onClickCalendarEvents = this.onClickCalendarEvents.bind(this);
  }

  onClickCalendarEvents(eventId) {
    this.props.onClickEvent(eventId);
  }

  render() {
    // const now = moment().subtract({ days: 14 }).toDate();
    // console.debug('SearchEventsResultCalendar', now);
    return (
      <Card>
        <MemoizeCalendar
          events={this.props.eventsList}
          onSelectEvent={(event) => this.onClickCalendarEvents(event.id)}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.color,
              border: '1px black',
              borderStyle: 'solid',
            },
          })}
          defaultDate={this.props.defaultDate}
        />
      </Card>
    );
  }
}

export default SearchEventsResultCalendar;

import PropTypes from 'prop-types';
import React from 'react';

import Card from '../Card';

import SearchEvents from './SearchEvents';
import SearchEventsDetailModal from './SearchEventsDetailModal';
import SearchEventsResultCalendar from './SearchEventsResultCalendar';

export default class EventContent extends React.Component {
  render() {
    return (
      <Card>
        <SearchEvents
          onSubmit={this.props.onSubmit}
          options={this.props.options}
          substituteUser={this.props.state.substituteUser}
          onClickSubstituteReleaseButton={
            this.props.onClickSubstituteReleaseButton
          }
        />
        {this.props.state.reservationManagementEvents.events &&
        this.props.state.reservationManagementEvents.events.length > 0 ? (
          <div>
            <SearchEventsResultCalendar
              eventsList={this.props.eventsCalendarValues}
              onClickEvent={this.props.onClickEvent}
              defaultDate={
                this.props.state.reservationManagementEvents.selectedDate ||
                undefined
              }
            />
            {this.props.state.reservationManagementEvents.eventsDetail ? (
              <SearchEventsDetailModal
                show={
                  this.props.state.reservationManagementEvents
                    .isShowPlansDetailModal
                }
                eventsDetail={
                  this.props.state.reservationManagementEvents.eventsDetail
                }
                eventTypeOptions={this.props.options.eventTypes}
                tagOptions={this.props.options.tags}
                onClickModalCloseButton={this.props.onClickModalCloseButton}
                onClickModalEventDetailButton={
                  this.props.onClickModalEventDetailButton
                }
                onClickModalEventEditButton={
                  this.props.onClickModalEventEditButton
                }
                substituteUser={this.props.state.substituteUser}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </Card>
    );
  }
}

EventContent.propTypes = {
  onSubmit: PropTypes.any,
  options: PropTypes.any,
  state: PropTypes.any,
  onClickSubstituteReleaseButton: PropTypes.func,
  eventsCalendarValues: PropTypes.any,
  onClickEvent: PropTypes.func,
  onClickModalCloseButton: PropTypes.func,
  onClickModalEventDetailButton: PropTypes.func,
  onClickModalEventEditButton: PropTypes.func,
};

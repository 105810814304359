import moment from 'moment';
import { trackPromise } from 'react-promise-tracker';

import Action from '../components/model/Action';
import SearchParams from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('/admin/summary');

const fromDateToString = (dateYmd) => {
  let ret = dateToString(dateYmd);
  if (ret !== '') {
    ret = ret + ' 00:00:00';
  }
  return ret;
};

const toDateToString = (dateYmd) => {
  let ret = dateToString(dateYmd);
  if (ret !== '') {
    ret = ret + ' 23:59:59';
  }
  return ret;
};

const dateToString = (dateYmd) => {
  let ret = '';
  if (dateYmd) {
    const dateYmdDate = moment(dateYmd.date, 'YYYY/MM/DD').toDate();
    const year = dateYmdDate.getFullYear();
    const monthInt = dateYmdDate.getMonth() + 1;
    const dayInt = dateYmdDate.getDate();

    ret =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }
  return ret;
};

// 面談
const makeSearchInterviewBase = (values) => {
  return (dispatch) => {
    return trackPromise(
      searchInterviewBase(values)
        .then((data) => dispatch(fetchSearchInterviewBaseSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const searchInterviewBase = (values) => {
  console.debug('searchInterviewBase-values: ', values);

  const params = new SearchParams({
    from: fromDateToString(values.interviewFrom),
    to: toDateToString(values.interviewTo),
    tagType: values.tagType,
  });

  return axios('/summary/interview/bases', {
    params: params.removeParams(),
  });
};

const fetchSearchInterviewBaseSuccess = (result) => {
  return {
    type: 'summary/FETCH_SUMMARY_SEARCH_INTERVIEW_BASE',
    payload: {
      result: result,
    },
  };
};

const makeStoreSearchInterviewsParams = (id, searchParams, values) => {
  return new Action('summary/STORE_SEARCH_INTERVIEWS_PARAMS', {
    id: id,
    searchParams: searchParams,
    values: values,
  });
};

const makeSearchInterview = (id, values, searchParams, searchType) => {
  console.debug('makeSearchInterview-searchParams: ', searchParams);
  return (dispatch) => {
    dispatch(
      makeStoreSearchInterviewsParams(id, searchParams, values, searchType)
    );
    return trackPromise(
      searchInterview(id, values, searchParams, searchType)
        .then((data) =>
          dispatch(fetchSearchInterviewSuccess(data.data, searchType))
        )
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const searchInterview = (id, values, searchParams, searchType) => {
  console.debug('values: ', values);

  const params = new SearchParams({
    baseId: id,
    from: fromDateToString(values.interviewFrom),
    to: toDateToString(values.interviewTo),
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
    searchType: searchType,
    tagType: values.tagType,
  });

  console.debug('searchParams: ', params.removeParams());

  return axios(`/summary/interview/reservation_status`, {
    params: params.removeParams(),
  });
};

const fetchSearchInterviewSuccess = (result, searchType) => {
  return {
    type: 'summary/FETCH_SUMMARY_SEARCH_INTERVIEW',
    payload: {
      result: result,
      searchType: searchType,
    },
  };
};

// セミナー
const makeSearchEventSeminorBase = (values) => {
  return (dispatch) => {
    return trackPromise(
      searchEventSeminorBase(values)
        .then((data) => dispatch(fetchSearchEventSeminorBaseSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const searchEventSeminorBase = (values) => {
  console.debug('searchEventSeminorBase-values: ', values);

  const params = new SearchParams({
    from: fromDateToString(values.seminorFrom),
    to: toDateToString(values.seminorTo),
    eventTypeId: '1',
  });

  return axios('/summary/event/bases', {
    params: params.removeParams(),
  });
};

const fetchSearchEventSeminorBaseSuccess = (result) => {
  return {
    type: 'summary/FETCH_SUMMARY_SEARCH_EVENT_SEMINOR_BASE',
    payload: {
      result: result,
    },
  };
};

const makeStoreSearchSeminarParams = (id, searchParams, values) => {
  return new Action('summary/STORE_SEARCH_SEMINAR_PARAMS', {
    id: id,
    searchParams: searchParams,
    values: values,
  });
};

const makeSearchEventSeminor = (id, values, searchParams) => {
  return (dispatch) => {
    dispatch(makeStoreSearchSeminarParams(id, searchParams, values));
    return trackPromise(
      searchEventSeminor(id, values, searchParams)
        .then((data) => dispatch(fetchSearchEventSeminorSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const searchEventSeminor = (id, values, searchParams) => {
  console.debug('values: ', values);

  let baseId = '0';
  if (id) {
    if (id.length > 0) {
      baseId = id;
    }
  }

  const params = new SearchParams({
    from: fromDateToString(values.seminorFrom),
    to: toDateToString(values.seminorTo),
    eventTypeId: '1',
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  });

  console.debug('searchParams: ', params.removeParams());

  return axios(`/summary/event/reservation_status/${baseId}`, {
    params: params.removeParams(),
  });
};

const fetchSearchEventSeminorSuccess = (result) => {
  return {
    type: 'summary/FETCH_SUMMARY_SEARCH_EVENT_SEMINOR',
    payload: {
      result: result,
    },
  };
};

// 説明会
const makeSearchEventSessionBase = (values) => {
  return (dispatch) => {
    return trackPromise(
      searchEventSessionBase(values)
        .then((data) => dispatch(fetchSearchEventSessionBaseSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const searchEventSessionBase = (values) => {
  console.debug('searchEventSessionBase-values: ', values);

  const params = new SearchParams({
    from: fromDateToString(values.sessionFrom),
    to: toDateToString(values.sessionTo),
    eventTypeId: '3',
  });

  return axios('/summary/event/bases', {
    params: params.removeParams(),
  });
};

const fetchSearchEventSessionBaseSuccess = (result) => {
  return {
    type: 'summary/FETCH_SUMMARY_SEARCH_EVENT_SESSION_BASE',
    payload: {
      result: result,
    },
  };
};

const makeStoreSearchSessionParams = (id, searchParams, values) => {
  return new Action('summary/STORE_SEARCH_SESSION_PARAMS', {
    id: id,
    searchParams: searchParams,
    values: values,
  });
};

const makeSearchEventSession = (id, values, searchParams) => {
  return (dispatch) => {
    dispatch(makeStoreSearchSessionParams(id, searchParams, values));

    return trackPromise(
      searchEventSession(id, values, searchParams)
        .then((data) => dispatch(fetchSearchEventSessionSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const searchEventSession = (id, values, searchParams) => {
  console.debug('values: ', values);
  let baseId = '0';
  if (id) {
    if (id.length > 0) {
      baseId = id;
    }
  }

  const params = new SearchParams({
    from: fromDateToString(values.sessionFrom),
    to: toDateToString(values.sessionTo),
    eventTypeId: '3',
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  });

  console.debug('searchParams: ', params.removeParams());

  return axios(`/summary/event/reservation_status/${baseId}`, {
    params: params.removeParams(),
  });
};

const fetchSearchEventSessionSuccess = (result) => {
  return {
    type: 'summary/FETCH_SUMMARY_SEARCH_EVENT_SESSION',
    payload: {
      result: result,
    },
  };
};

const initState = () => {
  return {
    type: 'summary/INIT_STATE',
  };
};

export {
  makeSearchInterviewBase,
  makeSearchInterview,
  makeSearchEventSeminorBase,
  makeSearchEventSeminor,
  makeSearchEventSessionBase,
  makeSearchEventSession,
  initState,
};

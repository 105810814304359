const initialState = {
  menus: [],
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'sidebar/FETCH_MENUS': {
      const menus = action.payload;
      return {
        ...state,
        menus: menus,
      };
    }
    default:
      return state;
  }
};

export { sidebarReducer as default };

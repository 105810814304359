import PropTypes from 'prop-types';
import React from 'react';

class TdLabel extends React.Component {
  static get propTypes() {
    return {
      value: PropTypes.string
    };
  }

  render() {
    return (
      <td>
        {this.props.value}
      </td>
    );
  }
}

export default TdLabel;

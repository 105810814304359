import moment from 'moment';

const initialState = {
  eventsSearchValues: {},
  eventsSearchParams: {},
  eventsSearchResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    searchParams: {
      sort: {
        sortColumn: 'seats',
      },
    },
    events: [],
  },
  eventsDelete: {
    isDeleteAlert: false,
    isDeleteConfirm: false,
    isDeleteComplete: false,
    isExistence: false,
  },
  eventsEdit: {
    isSubmitting: false,
    isConfirm: false,
    isComplete: false,
    isStaffSearchModal: false,
    staff: [],
    events: {},
    errorMessages: {},
    generalErrorMessages: [],
  },
  eventsRefer: {
    detail: {},
    profileId: null,
  },
  eventsBulkInsert: {
    isShowExecutedDialog: false,
    generalErrorMessages: [],
  },
  isLoading: false,
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'events/INIT_STATE': {
      return {
        ...initialState,
      };
    }
    case 'events/INIT_EDIT_STATE': {
      return {
        ...state,
        eventsEdit: initialState.eventsEdit,
      };
    }
    case 'events/SERARCH_EVENTS': {
      const eventsSearchResults = action.payload.result;
      return { ...state, eventsSearchResults };
    }
    case 'events/SHOW_DELETE_ALERT_MODAL': {
      return {
        ...state,
        eventsDelete: {
          ...state.eventsDelete,
          isDeleteAlert: true,
          isDeleteConfirm: false,
          isDeleteComplete: false,
        },
      };
    }
    case 'events/HIDE_DELETE_ALERT_MODAL': {
      return {
        ...state,
        eventsDelete: {
          ...state.eventsDelete,
          isDeleteAlert: false,
          isDeleteConfirm: false,
          isDeleteComplete: false,
        },
      };
    }
    case 'events/SHOW_DELETE_CONFIRM_MODAL': {
      return {
        ...state,
        eventsDelete: {
          ...state.eventsDelete,
          isDeleteAlert: false,
          isDeleteConfirm: true,
          isDeleteComplete: false,
        },
      };
    }
    case 'events/HIDE_DELETE_CONFIRM_MODAL': {
      return {
        ...state,
        eventsDelete: {
          ...state.eventsDelete,
          isDeleteAlert: false,
          isDeleteConfirm: false,
          isDeleteComplete: false,
        },
      };
    }
    case 'events/SHOW_DELETE_COMPLETE_MODAL': {
      return {
        ...state,
        eventsDelete: {
          ...state.eventsDelete,
          isDeleteAlert: false,
          isDeleteConfirm: false,
          isDeleteComplete: true,
        },
      };
    }
    case 'events/HIDE_DELETE_COMPLETE_MODAL': {
      return {
        ...state,
        eventsDelete: {
          ...state.eventsDelete,
          isDeleteAlert: false,
          isDeleteConfirm: false,
          isDeleteComplete: false,
        },
      };
    }
    case 'events/SET_EDIT_VALUES': {
      const values = action.payload.values;

      let staffId;
      let chairmanName;
      if (values.chairman) {
        staffId = values.chairman.value;
        chairmanName = values.chairman.name;
      }

      return {
        ...state,
        eventsRefer: {
          detail: {
            ...state.eventsRefer.detail,
            name: values.name,
            kindId: values.kindId,
            methodTypeId: values.methodTypeId,
            areaId: values.areaId,
            venueId: values.venueId,
            defaultMessage: values.defaultMessage,
            dateYMDFrom: moment(values.startDateTime.from.date).format(
              'YYYY/MM/DD'
            ),
            dateHHFrom: values.startDateTime.from.hour,
            dateMMFrom: values.startDateTime.from.minute,
            dateYMDTo: moment(values.startDateTime.to.date).format(
              'YYYY/MM/DD'
            ),
            dateHHTo: values.startDateTime.to.hour,
            dateMMTo: values.startDateTime.to.minute,
            startAcceptingTime: values.startAcceptingTime,
            openDateYMD: moment(values.openStartDate.date).format('YYYY/MM/DD'),
            openDateHH: values.openStartDate.hour,
            openDateMM: values.openStartDate.minute,
            closeDateYMD: moment(values.openCloseDate.date).format(
              'YYYY/MM/DD'
            ),
            closeDateHH: values.openCloseDate.hour,
            closeDateMM: values.openCloseDate.minute,
            seatAvailability: values.seatAvailability,
            isDisplaySeat: values.seatDisplayFlag === '1',
            isPaid: values.paidFlag === '1',
            price: values.price,
            isAttireDefault: values.attire.value === '1',
            attireDescription: values.attire.otherText,
            isNecessitiesDefault: values.necessities.value === '1',
            necessitiesDescription: values.necessities.otherText,
            staffId: staffId,
            chairmanName: chairmanName,
            categoryId: values.categoryId,
            tagIds: values.tagId,
            content: values.content,
            chargeBaseId: values.chargeBaseId,
            companyName: values.companyName,
            displayAreas: values.displayAreas,
          },
        },
        eventsEdit: {
          ...state.eventsEdit,
          isConfirm: true,
        },
      };
    }
    case 'events/FETCH_EVENT': {
      const result = action.payload.result;
      return {
        ...state,
        eventsRefer: { ...state.eventsRefer, detail: result },
      };
    }
    case 'events/FETCH_UPDATE_EVENT': {
      return {
        ...state,
        eventsEdit: { ...state.eventsEdit, isComplete: true },
      };
    }
    case 'events/STAFF_SEARCH_MODAL_SHOW': {
      return {
        ...state,
        eventsEdit: { ...state.eventsEdit, isStaffSearchModal: true },
      };
    }
    case 'events/STAFF_SEARCH_MODAL_HIDE': {
      return {
        ...state,
        eventsEdit: { ...state.eventsEdit, isStaffSearchModal: false },
      };
    }
    case 'events/FETCH_STAFF': {
      return {
        ...state,
        eventsEdit: {
          ...state.eventsEdit,
          staff: action.payload.result,
        },
      };
    }
    case 'events/UPDATE_EVENTS_LOADING': {
      return {
        ...state,
        eventsEdit: {
          ...state.eventsEdit,
          isSubmitting: true,
          generalErrormessages: [],
        },
      };
    }
    case 'events/UPLOAD_BULK_INSERT_FILE_SUCCESS': {
      const data = action.payload;
      return {
        ...state,
        eventsBulkInsert: {
          ...state.eventsBulkInsert,
          tempFileId: data.id,
          originalFileName: data.originalFileName,
        },
      };
    }
    case 'events/BULK_INSERT_EXEC_SUCCESS': {
      return {
        ...state,
        eventsBulkInsert: {
          ...state.eventsBulkInsert,
          isShowExecutedDialog: true,
        },
      };
    }
    case 'events/HIDE_EXECUTED_DIALOG': {
      return {
        ...state,
        staffBulkInsert: {
          ...state.staffBulkInsert,
          isShowExeutedDialog: false,
        },
      };
    }
    case 'events/FETCH_DISPLAY_AREAS': {
      return {
        ...state,
        eventsEdit: {
          ...state.eventsEdit,
          displayAreas: action.payload.result,
        },
      };
    }
    case 'events/CLEAR_SUGGESTION': {
      return {
        ...state,
        eventsEdit: {
          ...state.eventsEdit,
          staff: [],
        },
      };
    }
    case 'events/BAD_REQUEST': {
      const result = action.payload.result;

      return {
        ...state,
        eventsEdit: {
          ...state.eventsEdit,
          errorMessages: result.messages,
          isSubmitting: false,
          isConfirm: false,
        },
      };
    }
    case 'events/BAD_REQUEST_BULK_UPDATE': {
      const result = action.payload.result;

      return {
        ...state,
        eventsBulkInsert: {
          ...state.eventsBulkInsert,
          generalErrorMessages: result.messages.general,
        },
      };
    }
    case 'FETCH_ERROR': {
      const result = action.payload.result;

      if (result.response) {
        if (result.response.status === 500) {
          if (
            result.response.data &&
            result.response.data.messages &&
            result.response.data.messages.general
          ) {
            return {
              ...state,
              eventsEdit: {
                ...state.eventsEdit,
                generalErrorMessages: result.response.data.messages.general,
                isSubmitting: false,
              },
            };
          }
        }
      }
      return state;
    }
    case 'events/BACK_TO_INPUT': {
      return {
        ...state,
        eventsEdit: {
          ...state.eventsEdit,
          isConfirm: false,
          generalErrorMessages: [],
        },
      };
    }
    case 'events/INIT_EVENT_DETAIL': {
      return {
        ...state,
        eventsEdit: initialState.eventsEdit,
        eventsRefer: {
          detail: {},
        },
      };
    }
    case 'events/STORE_SEARCH_CONDITIONS': {
      const values = action.payload.values;
      const searchParams = action.payload.searchParams;
      return {
        ...state,
        eventsSearchParams: searchParams,
        eventsSearchValues: values,
      };
    }
    case 'events/LOAD_REFER_ITEM': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'events/LOAD_REFER_ITEM_COMPLETE': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'events/CLEAR_BULK_UPDATE_ERROR_MESSAGE': {
      return {
        ...state,
        eventsBulkInsert: {
          ...state.eventsBulkInsert,
          generalErrorMessages: [],
        },
      };
    }
    case 'events/INIT_BULK_UPDATE': {
      return {
        ...state,
        eventsBulkInsert: initialState.eventsBulkInsert,
      };
    }
    case 'events/BEGIN_FETCH_SEARCH_OPTIONS': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'events/END_FETCH_SEARCH_OPTIONS': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'events/BEGIN_FETCH_EVENTS_FOR_EDIT': {
      return {
        ...state,
        isLoading: true,
      };
    }

    case 'events/END_FETCH_EVENTS_FOR_EDIT': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'events/FETCH_PROFILE_SUCCESS': {
      return {
        ...state,
        eventsRefer: {
          ...state.eventsRefer,
          profileId: action.payload.id,
        },
        eventsEdit: {
          ...state.eventsEdit,
          profile: action.payload,
        },
      };
    }
    case 'events/FETCH_IS_DEFAULT_SETTING_BASE': {
      return {
        ...state,
        eventsEdit: {
          ...state.eventsEdit,
          isDefaultSettingBase: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export { eventsReducer as default };

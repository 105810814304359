import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ReferItem from '../../../molecules/ReferItem';

const DisableUserRow = styled.th`
  background-color: rgba(0, 0, 0, 0.03);
`;

export default class CareerInformation extends React.Component {
  static get propTypes() {
    return {
      userRefer: PropTypes.object,
    };
  }

  render() {
    const selector = (prop, name) => {
      if (
        Object.hasOwnProperty.call(this.props.userRefer, prop) &&
        Object.hasOwnProperty.call(this.props.userRefer[prop], name)
      ) {
        return this.props.userRefer[prop][name];
      }
      return null;
    };

    const flip = (f) => (arg1) => (arg2) => {
      return f(arg2, arg1);
    };

    const selectorOr = (selector, name, elseValue) => {
      const value = selector(name);

      if (value != null) {
        return value;
      }
      return elseValue;
    };

    const selectorMap = (selector, name, f) => {
      const value = selector(name);

      if (value != null) {
        return f(value);
      }
      return null;
    };

    const selectorList = (selector, listName, index, name) => {
      return selectorMap(selector, listName, (list) => {
        if (list.length >= index) {
          const listValue = list[index];

          if (
            listValue != null &&
            Object.hasOwnProperty.call(listValue, name)
          ) {
            return listValue[name];
          }
        }
        return null;
      });
    };

    const profileWorkInfoSelector = selector.bind(this, 'profileWorkInfo');
    const educationListSelector = selectorList.bind(
      null,
      profileWorkInfoSelector,
      'profileEducationList'
    );
    const profileWorkExperiencesListSelector = selectorList.bind(
      null,
      profileWorkInfoSelector,
      'profileWorkExperiencesList'
    );

    // 最終学歴
    const getFinalEducation = () => {
      return profileWorkInfoSelector('finalEducation');
    };

    // 卒業年・卒業予定年
    const getGraduationDate = (index) => {
      return selectorMap(
        educationListSelector.bind(null, index),
        'graduationDate',
        (graduationDate) => {
          const date = new Date(graduationDate);

          return `${date.getFullYear()}年`;
        }
      );
    };

    // 学校名 + 学部・学科・専攻
    const getCollageName = (index) => {
      const educationListSelectorWithIndex = flip(
        selectorOr.bind(null, educationListSelector.bind(null, index))
      )('');

      return selectorMap(
        educationListSelectorWithIndex,
        'collageName',
        (collageName) => {
          return selectorMap(
            educationListSelectorWithIndex,
            'major',
            (major) => {
              return `${collageName}${major}`;
            }
          );
        }
      );
    };

    // 修了状況
    // const getStatus = (index) => {
    //   return educationListSelector(index, 'status');
    // };

    // <職歴>
    // 在職期間(勤務年数)
    const getLengthOfService = (index) => {
      return profileWorkExperiencesListSelector(index, 'lengthOfService');
    };

    // 会社名(業界)
    const getCompanyName = (index) => {
      const profileWorkExperiencesListSelectorWithIndex = profileWorkExperiencesListSelector.bind(
        null,
        index
      );

      return selectorMap(
        profileWorkExperiencesListSelectorWithIndex,
        'companyName',
        (companyName) => {
          return selectorMap(
            profileWorkExperiencesListSelectorWithIndex,
            'industry',
            (industry) => {
              return `${companyName}(${industry})`;
            }
          );
        }
      );
    };

    // 雇用形態(職種)
    const getEmployeementPattern = (index) => {
      const profileWorkExperiencesListSelectorWithIndex = profileWorkExperiencesListSelector.bind(
        null,
        index
      );

      return selectorMap(
        profileWorkExperiencesListSelectorWithIndex,
        'employeementPattern',
        (employeementPattern) => {
          return selectorMap(
            profileWorkExperiencesListSelectorWithIndex,
            'jobCategory',
            (jobCategory) => {
              return `${employeementPattern}(${jobCategory})`;
            }
          );
        }
      );
    };

    // 退職理由
    const getRetirementReason = (index) => {
      return profileWorkExperiencesListSelector(index, 'retirementReason');
    };

    return (
      <>
        <h3>学歴・経歴</h3>
        <ReferItem label="<学歴>"></ReferItem>

        <table className="table" border="1">
          <tr>
            <DisableUserRow scope="col">最終学歴</DisableUserRow>
            <th scope="col">{getFinalEducation()}</th>
          </tr>
        </table>
        <br />

        <table className="table" border="1">
          <tr>
            <DisableUserRow scope="col">卒業年・卒業予定年</DisableUserRow>
            <DisableUserRow scope="col">
              学校名／学部・専攻／学科・専攻コース
            </DisableUserRow>
          </tr>
          <tr>
            <th scope="col">{getGraduationDate(0)}</th>
            <th scope="col">{getCollageName(0)}</th>
          </tr>
          <tr>
            <th scope="col">{getGraduationDate(1)}</th>
            <th scope="col">{getCollageName(1)}</th>
          </tr>
          <tr>
            <th scope="col">{getGraduationDate(2)}</th>
            <th scope="col">{getCollageName(2)}</th>
          </tr>
        </table>
        <br />

        <ReferItem label="<職歴>"></ReferItem>
        <table className="table" border="1">
          <tr>
            <DisableUserRow scope="col">在職期間(勤務年数)</DisableUserRow>
            <DisableUserRow scope="col">会社名(業界)</DisableUserRow>
            <DisableUserRow scope="col">雇用形態(職種)</DisableUserRow>
            <DisableUserRow scope="col">退職理由</DisableUserRow>
          </tr>
          <tr>
            <th scope="col">{getLengthOfService(0)}</th>
            <th scope="col">{getCompanyName(0)}</th>
            <th scope="col">{getEmployeementPattern(0)}</th>
            <th scope="col">{getRetirementReason(0)}</th>
          </tr>
          <tr>
            <th scope="col">{getLengthOfService(1)}</th>
            <th scope="col">{getCompanyName(1)}</th>
            <th scope="col">{getEmployeementPattern(1)}</th>
            <th scope="col">{getRetirementReason(1)}</th>
          </tr>
          <tr>
            <th scope="col">{getLengthOfService(2)}</th>
            <th scope="col">{getCompanyName(2)}</th>
            <th scope="col">{getEmployeementPattern(2)}</th>
            <th scope="col">{getRetirementReason(2)}</th>
          </tr>
        </table>
        <br />
      </>
    );
  }
}

import PropTypes from 'prop-types';
import React from 'react';

class LeggendColorBox extends React.Component {
  static get propTypes() {
    return {
      color: PropTypes.string,
      height: PropTypes.string,
      width: PropTypes.string,
    };
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: this.props.color,
          width: this.props.width,
          height: this.props.height,
          borderRadius: '0.2em',
        }}
      ></div>
    );
  }
}

export default LeggendColorBox;

import PropTypes, { bool, func } from 'prop-types';
import React from 'react';

import DeleteButton from '../../molecules/DeleteButton';
import DeleteConfirmModal from '../../molecules/DeleteConfirmModal';
import LabelErrorComponent from '../../molecules/LabelErrorComponent';
import NewButton from '../../molecules/NewButton';
import Card from '../../organisms/Card';
import DeleteCompleteModal from '../../organisms/DeleteCompleteModal';
import Pager from '../../organisms/Pager';
import ContainsUsedVenueAlertModal from '../../organisms/venueManagement/ContainsUsedVenueAlertModal';
import Form from '../../organisms/venueManagement/SearchForm';
import SearchResultTable from '../../organisms/venueManagement/SearchResultTable';
import SearchComponent from '../../pages/common/SearchComponent';

class Search extends React.Component {
  static get propTypes() {
    return {
      searchValues: PropTypes.object,
      searchParams: PropTypes.object,
      searchData: PropTypes.shape({
        searchParams: PropTypes.shape({
          sort: PropTypes.shape({
            sortColumn: PropTypes.string,
            sortType: PropTypes.string,
          }),
          pagination: PropTypes.shape({
            pageSize: PropTypes.number,
            total: PropTypes.numsber,
            page: PropTypes.number,
          }),
        }),
        hallList: PropTypes.array,
      }),
      sysAdminRadioValue: PropTypes.array,
      areas: PropTypes.array,
      searchFunction: PropTypes.func,
      transitionToEditFunction: func,
      moveDetailFunction: func,
      selectionDelete: func,
      onDeleteButtonClick: func,
      isShowDisableSuccessModal: bool,
      onClickDeleteModalCloseButton: func,
      hideContainsUsedAlertModal: func,
      isDisplayContainsUsedAlertModal: bool,
      isDisplayDeleteConfirmModal: bool,
      onYesClick: func,
      onNoClick: func,
    };
  }

  constructor(props) {
    super(props);

    this.searchComponent = new SearchComponent();

    this.search = this.search.bind(this);
    this.clickSearchButton = this.clickSearchButton.bind(this);
    this.onAlertModalCloseClick = this.onAlertModalCloseClick.bind(this);

    let values;
    if (props.searchValues && Object.keys(props.searchValues).length > 0) {
      values = props.searchValues;
    }

    this.state = {
      name: '',
      prefectureId: null,
      values: values,
    };
  }

  search(searchParams = this.props.searchParams, values = this.state.values) {
    this.props.searchFunction(
      { name: this.state.name, prefectureId: this.state.prefectureId },
      searchParams,
      values
    );
  }

  clickSearchButton(values) {
    this.setState({ values: values });

    const prevSearchParams = this.props.searchParams;

    let pageSize = this.searchComponent.pageDefaultSize;
    if (
      prevSearchParams &&
      prevSearchParams.pagination &&
      prevSearchParams.pagination.pageSize
    ) {
      pageSize = prevSearchParams.pagination.pageSize;
    }
    this.search(
      {
        sort: {
          sortColumn: 'id',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      values
    );
  }

  onAlertModalCloseClick() {
    this.props.hideContainsUsedAlertModal();
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <Card headerTitle="会場管理 検索">
            <div style={{ display: this.state.error == null ? 'none' : '' }}>
              <LabelErrorComponent
                title="ErrorTitle1"
                directly="ErrorMessage."
              ></LabelErrorComponent>
            </div>
            <Form onSubmit={this.clickSearchButton} areas={this.props.areas} />
            <div className="text-left">
              <NewButton onClick={() => this.props.transitionToEditFunction()}>
                新規作成
              </NewButton>
            </div>
            {this.props.searchData != null &&
            this.props.searchData.hallList.length > 0 ? (
              <>
                <hr />
                <Pager
                  searchParams={this.props.searchData.searchParams}
                  search={this.search}
                />
                <div className="row">
                  <div className="col-sm-12">
                    <SearchResultTable
                      datas={this.props.searchData.hallList}
                      moveDetailFunction={this.props.moveDetailFunction}
                      selectionDelete={this.props.selectionDelete}
                    />
                  </div>
                </div>
                <DeleteButton onClick={() => this.props.onDeleteButtonClick()}>
                  削除
                </DeleteButton>
              </>
            ) : (
              <></>
            )}
          </Card>
        </div>

        <ContainsUsedVenueAlertModal
          show={this.props.isDisplayContainsUsedAlertModal}
          onCloseButtonClick={this.onAlertModalCloseClick}
        />

        <DeleteConfirmModal
          show={this.props.isDisplayDeleteConfirmModal}
          onYesClick={this.props.onYesClick}
          onNoClick={this.props.onNoClick}
        >
          選択された会場を削除してよろしいですか？
        </DeleteConfirmModal>

        <DeleteCompleteModal
          show={this.props.isShowDisableSuccessModal}
          onCloseClick={this.props.onClickDeleteModalCloseButton}
        />
      </div>
    );
  }
}

export default Search;

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import BulkRegisterButton from '../../molecules/BulkRegisterButton';
import FileButton from '../../molecules/FileButton';
import TransitionButton from '../../molecules/TransitionButton';

class EventsBulkUpdate extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      fileName: PropTypes.string,
      state: PropTypes.shape({
        staffBulkUpdate: PropTypes.shape({
          tempFileId: PropTypes.string,
          originalFileName: PropTypes.string,
          searchResults: PropTypes.shape({
            results: PropTypes.array,
            searchParams: PropTypes.shape(),
          }),
          isShowExecutedDialog: PropTypes.bool,
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      hideExecutedDialog: PropTypes.func,
      fetchBulkUpdateHistories: PropTypes.func,
      uploadBulkInsertFile: PropTypes.func,
      bulkUpdateExec: PropTypes.func,
      onClickFileButton: PropTypes.func,
      onClickConfirmButton: PropTypes.func,
      isClickableConfirmButton: PropTypes.func,
      onClickPrevButton: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.state = { file: '' };

    this.onClickCloseButton = this.onClickCloseButton.bind(this);
    this.onClickExecButton = this.onClickExecButton.bind(this);
  }

  onClickCloseButton() {
    this.props.hideExecutedDialog();
    this.initSearch();
  }

  onClickExecButton() {
    this.props.bulkUpdateExec(
      this.props.state.staffBulkUpdate.tempFileId,
      this.props.state.staffBulkUpdate.originalFileName
    );
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <p>
              テンプレートファイルは
              <a
                href={`${process.env.REACT_APP_API_URI}/interviews/bulk_register/template?20230214`}
              >
                こちら
              </a>
              <br />
              2～5行目のデータはサンプルです。登録時には削除してください。
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <FileButton onChange={this.props.onClickFileButton} />
          </Col>
          <Col xs={9}> {this.props.fileName}</Col>
        </Row>

        <Row>
          <Col className="text-center">
            <TransitionButton onClick={this.props.onClickPrevButton}>
              一覧へ戻る
            </TransitionButton>
            &nbsp;
            <BulkRegisterButton
              onClick={this.props.onClickConfirmButton}
              isEnabled={this.props.isClickableConfirmButton}
            >
              アップロード
            </BulkRegisterButton>
          </Col>
        </Row>
      </>
    );
  }
}

export default EventsBulkUpdate;

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import PrimaryButton from '../../atoms/PrimaryButton';
import DeleteButton from '../../molecules/DeleteButton';
import EditButton from '../../molecules/EditButton';
import SearchForm from '../../organisms/formManagement/FormManagementSearchForm';
import SearchResultTable from '../../organisms/SearchResultTable';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import DeleteCompleteModal from "../../organisms/DeleteCompleteModal";
import CopyAndEditButton from "../../molecules/formsManagement/CopyAndEditButton";
import NewButton from "../../molecules/NewButton";
import DeleteConfirmModal from "../../organisms/formManagement/DeleteConfirmModal";

class Search extends React.Component {
  static get propTypes() {
    return {
      search: PropTypes.func,
      resultDatas: PropTypes.array,
      onClickDisableModalCloseButton: PropTypes.func,
      isShowRegisterModal: PropTypes.bool,
      moveEdit: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.search = this.search.bind(this);
    this.inputOnChange = this.inputOnChange.bind(this);
    this.getResultList = this.getResultList.bind(this);
    this.getSearchItem = this.getSearchItem.bind(this);
    this.onClickDisableModalCloseButton = this.onClickDisableModalCloseButton.bind(this);

    this.state = {
      name: '',
    };
  }

  inputOnChange(e) {
    console.log('inputOnChange:', e);
    this.setState({ name: e.target.value });
  }

  search(values) {
    this.props.search(values);
  }

  getResultList() {
    return {
      searchParams: {
        sort: 'id',
      },
      results: this.props.searchResult,
    };
  }

  getSearchItem() {
    const forms = this.getResultList().results.forms;

    if (forms == null || forms === []) {
      return (<></>);
    }

    const items = forms.map((form, index) => {
      return (
        <tr key={form.id}>
          <th scope="row">
            <input
              className="form-check-input mx-auto"
              type="checkbox"
              onClick={() => this.props.selectionDelete(form.id)}
            />
          </th>
          <td>{form.name}</td>
          <td>
            <CopyToClipboard
              text={form.jsCode}
              onCopy={() => this.setState({ copied: true })}
            >
              <PrimaryButton>JSコピー</PrimaryButton>
            </CopyToClipboard>
          </td>
          <td>
            <CopyToClipboard
              text={form.htmlCode}
              onCopy={() => this.setState({ copied: true })}
            >
              <PrimaryButton>CSSコピー</PrimaryButton>
            </CopyToClipboard>
          </td>
          <td>
            <EditButton  onClick={() => this.props.moveEdit(form.id, false)} />
          </td>
          <td>
            <CopyAndEditButton onClick={() => this.props.moveEdit(form.id, true)} />
          </td>
        </tr>
      );
    });
    return items;
  }

  onClickDisableModalCloseButton() {
    this.props.onClickDisableModalCloseButton();
  }

  render() {
    return (
      <>
        <SearchForm onSubmit={this.search} />
        <div className="text-left">
          <NewButton  onClick={() => this.props.moveEdit(null, false)}/>
        </div>
        {
          this.props.searchResult.forms != null &&
          this.props.searchResult.forms.length > 0 ?
            <>
              <SearchResultTable
                headerItems={[
                  {},
                  { name: 'staff.name', value: 'フォーム名' },
                  { name: 'copy_js_button', value: 'コピー（JS/動的）' },
                  { name: 'copy_css_button', value: 'コピー（CSS/静的）' },
                  { name: 'edit_button', value: '編集' },
                  {
                    name: 'copy_button',
                    value: 'コピーして新規作成',
                  },
                ]}
                searchResults={this.getResultList()}
                getSearchItem={this.getSearchItem}
                search={this.search}
              />
              <DeleteButton onClick={this.props.onDeleteButtonClick} />
            </>
            :
            <></>
        }
        <RegisterCompleteModal
          show={this.props.isShowRegisterModal}
          onCloseClick={this.onClickDisableModalCloseButton}
        />
        <DeleteConfirmModal
          show={this.props.isShowDeleteConfirmModal}
          onYesClick={this.props.deleteForm}
          onNoClick={this.props.onClickDeleteConfirmModalCloseButton}
        />
        <DeleteCompleteModal
          show={this.props.isShowDeleteSuccessModal}
          onCloseClick={this.props.onClickDeleteModalCloseButton}
        />
      </>
    );
  }
}

export default Search;

import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

class FormSelect extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.array,
      options: PropTypes.array,
      input: PropTypes.any,
      meta: PropTypes.any,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.state = { value: '' };

    const { input, meta, ...other } = props;
    this.other = other;

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const value = e.target.value;

    this.setState({ value: value });
    if (this.props.onChange) {
      this.props.onChange(e);
    }

    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(e);
    }
  }

  render() {
    return (
      <Form.Control
        as="select"
        className="form-control"
        {...this.other}
        {...this.props.input}
        onChange={this.onChange}
        onClick={this.props.onClick}
        isInvalid={this.props.meta.touched && this.props.meta.error}
      >
        {this.props.children}
      </Form.Control>
    );
  }
}

export default FormSelect;

import PropTypes from 'prop-types';
import React from 'react';

import TdLabel from '../../atoms/TdLabel';
import ThLabel from '../../atoms/ThLabel';
import TdLabelButton from '../../molecules/TdLabelButton';
import TdLink from '../../molecules/TdLink';

class SearchResultTable extends React.Component {
  static get propTypes() {
    return {
      datas: PropTypes.array,
      selectionDelete: PropTypes.func,
      moveDetailFunction: PropTypes.func,
    };
  }

  render() {
    return (
      <table className="table table-hover dataTable no-footer" role="grid">
        <thead className="thead-dark">
          <tr>
            <ThLabel />
            <ThLabel title="会場名" />
            <ThLabel title="エリア" />
            <ThLabel title="住所" />
            <ThLabel title="地図" />
            <ThLabel />
          </tr>
        </thead>
        <tbody>
          {this.props.datas.map((data) => {
            return (
              <tr key={data.id}>
                <td>
                  <input
                    className="form-check-input mx-auto"
                    type="checkbox"
                    onChange={() => this.props.selectionDelete(data.id)}
                  />
                </td>
                <TdLabel value={data.name} />
                <TdLabel value={data.areaName} />
                <TdLabel
                  value={
                    data.prefName +
                    data.city +
                    (data.buildingName ? data.buildingName : '')
                  }
                />
                <TdLink name={data.googleMapShortUrl} />
                <TdLabelButton
                  onClick={() => this.props.moveDetailFunction(data.id)}
                >
                  詳細
                </TdLabelButton>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default SearchResultTable;

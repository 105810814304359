import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ModifyButton from '../../molecules/ModifyButton';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import Card from '../../organisms/Card';

import routes from './routePath';

class CompanyRefer extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      fetchStaff: PropTypes.func,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        companyRefer: PropTypes.shape({
          detail: PropTypes.shape({
            name: PropTypes.string,
          }),
          errorMessages: PropTypes.arrayOf(PropTypes.string),
          isSystemAdmin: PropTypes.bool,
          isAlreadySend: PropTypes.bool,
          isDisplayReSendComplete: PropTypes.bool,
          isDisplayReSendFailureModal: PropTypes.bool,
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      fetchCompany: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.fetchCompany(this.id);
  }

  transitionToSearch() {
    this.props.history.push(routes.search);
  }

  transitionToEdit() {
    this.props.history.push(routes.edit(this.id));
  }

  render() {
    const getName = () => {
      console.debug('CompanyRefer getName', this.props);
      return this.props.state.companyRefer.detail.name;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>企業管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="詳細">
                  {this.props.state.isLoading ? (
                    <></>
                  ) : (
                    <>
                      <ReferItem label="名前">{getName()}</ReferItem>
                      <hr />
                      <Row>
                        <div className="mx-auto">
                          <PrevButton onClick={this.transitionToSearch} />
                          <ModifyButton onClick={this.transitionToEdit} />
                        </div>
                      </Row>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </>
    );
  }
}

export default CompanyRefer;

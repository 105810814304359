import { ConnectedRouter } from 'connected-react-router';
import { func, node, object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { makeFetchProfile } from '../actions/Authentication';
import history from '../history';

import LoadingSpinnerComponent from './loadingSpinner';
import Footer from './organisms/Footer';
import NavBar from './organisms/NavBar';
import SideBar from './organisms/SideBar';
import routes from './routes';

class BasePage extends React.Component {
  static get propTypes() {
    return {
      fetchProfile: func,
      children: node,
    };
  }

  componentDidMount() {
    this.props.fetchProfile();
  }

  render() {
    return <>{this.props.children}</>;
  }
}

const BasePageComponent = connect(
  (state) => {
    return {};
  },
  (dispatch) => {
    return {
      fetchProfile(path) {
        dispatch(makeFetchProfile(path));
      },
    };
  }
)(BasePage);

const RouteWithSubRoutes = (route) => {
  const routerComponent = (props) => {
    return <route.component {...props} routes={route.routes} />;
  };

  const isNoMenuPath = (path) => {
    return path.includes('/login') || path.includes('/admin/password_reset');
  };

  return (
    <Route
      path={route.path}
      render={(props) => {
        if (isNoMenuPath(route.path)) {
          return (
            <>
              <section className="section">
                <div className="container mt-5">{routerComponent(props)}</div>
              </section>
              <Footer></Footer>
            </>
          );
        }
        return (
          <div className="main-wrapper">
            <NavBar></NavBar>
            <SideBar></SideBar>
            <BasePageComponent>
              <div className="main-content">{routerComponent(props)}</div>
            </BasePageComponent>
            <Footer></Footer>
          </div>
        );
      }}
    />
  );
};

RouteWithSubRoutes.propTypes = {
  history: object,
};
function App(props) {
  return (
    <div id="app">
      <ConnectedRouter history={history}>
        <LastLocationProvider>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </LastLocationProvider>
      </ConnectedRouter>
      <LoadingSpinnerComponent />
    </div>
  );
}

export default App;

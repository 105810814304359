import PropTypes from 'prop-types';
import React from 'react';

class Badge extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.string,
    };
  }

  render() {
    return <span className="badge badge-primary">{this.props.children}</span>;
  }
}

export default Badge;

import PropTypes from 'prop-types';
import React from 'react';

class SearchResultTableNoSort extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      headerItems: PropTypes.array.isRequired,
      getSearchItem: PropTypes.func,
      search: PropTypes.func,
    };
  }

  render() {
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            {this.props.headerItems.map((headerItem, i) => {
              return (
                <th scope="col" key={i}>
                  {headerItem.name !== null && headerItem.name !== undefined ? (
                    <span>{headerItem.value}</span>
                  ) : (
                    <></>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{this.props.getSearchItem()}</tbody>
      </table>
    );
  }
}

export default SearchResultTableNoSort;

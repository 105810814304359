import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { makeFetchMenus } from '../../../actions/RoleManagement';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import RegisterButton from '../../molecules/RegisterButton';
import SaveButton from '../../molecules/SaveButton';
import ReferRoles from '../../organisms/roleManagement/ReferRoles';

class RoleManagementEditConfirmForm extends React.Component {
  static get propTypes() {
    return {
      fetchMenus: PropTypes.func,
      onSubmit: PropTypes.func,
      backToInput: PropTypes.func,
      values: PropTypes.any,
      state: PropTypes.shape({
        isSubmitting: PropTypes.bool,
      }),
      menuOption: PropTypes.array,
      rolePermissions: PropTypes.array,
      menus: PropTypes.array,
      isNew: PropTypes.bool,
    };
  }

  componentDidMount() {
    this.props.fetchMenus();
  }

  render() {
    const getName = () => {
      return this.props.values.name;
    };

    const isSystemAdmin = () => {
      if (this.props.values.isSystemAdmin === '1') {
        return 'システム管理者';
      } else {
        return 'なし';
      }
    };

    const getInitMenu = () => {
      const menuOption = this.props.menus
        .flatMap((section) => {
          return section.menus;
        })
        .filter((menu) => {
          return menu.menuId === this.props.values.initMenu;
        });

      if (menuOption.length > 0) {
        return menuOption[0].menuName;
      }

      return '';
    };

    const getRoles = () => {
      return Object.entries(this.props.values)
        .filter(([key, value]) => {
          return key.includes('RolePermissionId-');
        })
        .map(([key, value]) => {
          const menuId = key.replace('RolePermissionId-', '');
          const rolePermissions = this.props.rolePermissions.filter(
            (rolePermission) => {
              return rolePermission.value === value;
            }
          );

          const roleName = rolePermissions[0].name;
          return {
            menuId: menuId,
            permissionId: value,
            roleName: roleName,
          };
        });
    };

    const getMenus = () => {
      return this.props.menus;
    };

    return (
      <>
        <Col sm={{ offset: 1 }}>
          <ReferItem label="名前">{getName()}</ReferItem>
        </Col>
        <Col sm={{ offset: 1 }}>
          <ReferItem label="システム管理者フラグ">{isSystemAdmin()}</ReferItem>
        </Col>
        <Col sm={{ offset: 1 }}>
          <ReferItem label="ログイン時初期表示機能">{getInitMenu()}</ReferItem>
        </Col>
        <ReferRoles RoleList={getRoles()} menus={getMenus()}></ReferRoles>

        <hr />
        <Row>
          <div className="mx-auto">
            <PrevButton onClick={this.props.backToInput} />
            {this.props.isNew ? (
              <RegisterButton
                onClick={this.props.onSubmit}
                isSubmitting={this.props.state.isSubmitting}
              />
            ) : (
              <SaveButton
                onClick={this.props.onSubmit}
                isSubmitting={this.props.state.isSubmitting}
              />
            )}
          </div>
        </Row>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      state: state.roleManagement.roleEdit,
      menus: state.roleManagement.roleRefer.detail.menus,
      rolePermissions: state.options.rolePermissions,
    };
  },
  (dispatch) => {
    return {
      fetchMenus(id) {
        dispatch(makeFetchMenus(id));
      },
    };
  }
)(RoleManagementEditConfirmForm);

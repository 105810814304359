import PropTypes from 'prop-types';
import React from 'react';

import LightButton from '../atoms/LightButton';

class PrevButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
    };
  }

  render() {
    return <LightButton onClick={this.props.onClick}>戻る</LightButton>;
  }
}

export default PrevButton;

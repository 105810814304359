import PropTypes from 'prop-types';
import React from 'react';

import InfoButton from '../../atoms/InfoButton';
import { ReferAction } from '../../utils/PermissionComponent';

class SearchAddressButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.node,
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <ReferAction>
        <InfoButton onClick={this.props.onClick}>
          {this.props.children ? this.props.children : '検索'}
        </InfoButton>
      </ReferAction>
    );
  }
}

export default SearchAddressButton;

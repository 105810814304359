import { trackPromise } from 'react-promise-tracker';
import { change } from 'redux-form';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import SearchParams from '../components/model/Params';
import { FromZipcode } from '../components/model/Zipcode';

import createAxios from './axios';
import { fetchErrorData } from './common';
import { trackAsync } from './util';

const axios = createAxios('/admin/bases');

const fetchAreas = () => {
  return axios.get(`/admin/areas`);
};

const fetchAreasSuccess = (result) => {
  console.log('fetchAreasSuccess(result)');
  return {
    type: 'FETCH_AREAS',
    payload: {
      result: new OptionCollection(
        result.areaList.map((area) => {
          return new Option(area.id, area.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchAreas = () => {
  return (dispatch) => {
    return fetchAreas()
      .then((data) => dispatch(fetchAreasSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchPrefs = () => {
  return axios.get(`/prefs`);
};

const fetchPrefssSuccess = (result) => {
  console.log('fetchPrefsSuccess(result)');
  return {
    type: 'FETCH_PREFS',
    payload: {
      result: new OptionCollection(
        result.map((pref) => {
          return new Option(pref.id, pref.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchPrefs = () => {
  return (dispatch) => {
    return fetchPrefs()
      .then((data) => dispatch(fetchPrefssSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchSearchSuccess = (result) => {
  return {
    type: 'base/FETCH_BASE_SEARCH',
    payload: {
      result: result,
    },
  };
};

const search = (values, searchParams) => {
  console.debug('values: ', values);

  const params = new SearchParams({
    name: values.name,
    areaId: values.areaId,
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  });

  console.debug('searchParams: ', params.removeParams());

  return axios('/admin/bases', {
    params: params.removeParams(),
  });
};

const storeSearchValues = (values, searchParams) => {
  return new Action('base/STORE_SEARCH_VALUES', { values, searchParams });
};

const makeSearch = (values, searchParams) => {
  return (dispatch) => {
    dispatch(storeSearchValues(values, searchParams));

    return trackPromise(
      search(values, searchParams)
        .then((data) => dispatch(fetchSearchSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const fetchBaseSuccess = (data) => {
  return {
    type: 'base/FETCH_BASE',
    payload: {
      result: data,
    },
  };
};

const fetchBase = (id) => {
  return axios.get(`/admin/bases/${id}`);
};

const showLoading = () => {
  return {
    type: 'base/SHOW_LOADING',
  };
};

const hideLoading = () => {
  return new EmptyAction('base/HIDE_LOADING');
};

const makeFetchBase = (id) => {
  return (dispatch) => {
    dispatch(showLoading());
    return fetchBase(id)
      .then((data) => dispatch(fetchBaseSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const putBase = (id, params) => {
  return axios.put(`/admin/bases/${id}`, params);
};

const postBase = (params) => {
  return axios.post(`/admin/bases`, params);
};

const fetchUpdateBase = (data) => {
  return {
    type: 'base/UPDATE_BASE',
    payload: {
      result: data,
    },
  };
};

const updateBaseLoading = () => {
  return {
    type: 'base/UPDATE_BASE_LOADING',
  };
};

const makeUpdateBase = (values) => {
  return (dispatch) => {
    dispatch(updateBaseLoading());

    const accessList = values.accessList.map((accessList) => {
      return {
        id: accessList.id,
        accessMapImageId: accessList.accessMapImageId,
        accessNote: accessList.accessNote,
      };
    });

    const params = new SearchParams({
      id: values.id,
      name: values.name,
      displayName: values.displayName,
      isCaravan: values.isCaravan,
      isDisplay: values.isDisplay,
      companyName: values.companyName,
      areaId: values.areaId,
      zipCode: FromZipcode(values.zipCode1, values.zipCode2).toString(),
      city: values.city,
      prefId: values.prefId,
      buildingName: values.buildingName,
      phoneNumber: `${values.phoneNumber1}-${values.phoneNumber2}-${values.phoneNumber3}`,
      mailAddress: values.mailAddress,
      eventDisplayLimit: Number(values.eventDisplayLimit),
      nearestStation: values.nearestStation,
      googleMapUrl: values.googleMapUrl,
      googleMapShortUrl: values.googleMapShortUrl,
      accessList: accessList,
    });

    let request;
    if (values.id == null) {
      request = postBase(params.removeParams());
    } else {
      request = putBase(values.id, params.removeParams());
    }

    trackPromise(
      request
        .then((data) => dispatch(fetchUpdateBase(data.data)))
        .catch((err) => dispatch(updateBaseError(err)))
    );
  };
};

const updateBaseError = (err) => {
  if (err.response.status === 400 && err.response.data) {
    return new Action('base/UPDATE_BASE_ERROR', err.response.data.messages);
  }

  return fetchErrorData(err);
};

const makeDeleteBase = (ids) => {
  return (dispatch) => {
    dispatch(deleteBaseLoading());
    return trackPromise(
      deleteBase(ids)
        .then((data) => dispatch(deleteBaseSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const deleteBase = (ids) => {
  return Promise.all(ids.map((id) => axios.delete(`/admin/bases/${id}`)));
};

const deleteBaseLoading = () => {
  return {
    type: 'base/DELETE_BASE_LOADING',
  };
};

const deleteBaseSuccess = (data) => {
  return {
    type: 'base/DELETE_BASE',
    payload: {
      result: data,
    },
  };
};

const errorDelete = () => {
  return {
    type: 'base/ERROR_DELETE',
  };
};

const showDeleteConfirm = () => {
  return {
    type: 'base/SHOW_DELETE_CONFIRM',
  };
};

const closeDeleteConfirm = () => {
  return {
    type: 'base/CLOSE_DELETE_CONFIRM',
  };
};

const closeDeleteComplete = () => {
  return {
    type: 'base/CLOSE_DELETE_COMPLETE',
  };
};

const addAccessList = (accessList) => {
  return {
    type: 'base/ADD_ACCESS',
    payload: {
      result: accessList,
    },
  };
};

const deleteAccessList = (accessList) => {
  return {
    type: 'base/DELETE_ACCESS',
    payload: {
      result: accessList,
    },
  };
};

const initReferDetail = () => {
  return {
    type: 'base/INIT_REFER_DETAIL',
  };
};

const fetchCompanyNames = () => {
  return axios.get('/options/company_names');
};

const fetchCompanyNameSuccess = (datas) => {
  const options = new OptionCollection(
    datas.map((data) => {
      return new Option(data.name, data.name);
    })
  );

  return new Action('option/FETCH_COMPANY_NAMES', {
    result: options.getOptions(),
  });
};

const makeFetchBaseDetailForEdit = (id) => {
  return async (dispatch) => {
    dispatch(showLoading());
    dispatch(initReferDetail());
    try {
      const areaRes = await fetchAreas();
      dispatch(fetchAreasSuccess(areaRes.data));

      const prefRes = await fetchPrefs();
      dispatch(fetchPrefssSuccess(prefRes.data));

      const companyNamesRes = await fetchCompanyNames();
      dispatch(fetchCompanyNameSuccess(companyNamesRes.data));

      if (id) {
        const baseRes = await fetchBase(id);
        dispatch(fetchBaseSuccess(baseRes.data));
      }

      dispatch(hideLoading());
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const uploadAccessMapFile = (file) => {
  console.debug(file);

  const params = new FormData();
  params.append('file', file);

  return axios.post(`/access_map_img`, params, {
    headers: {
      'X-Referer': '/admin/bases',
      'Content-Type': 'multipart/form-data',
    },
  });
};

const uploadAccessMapFileSuccess = (dispatch, data, index) => {
  dispatch(
    change('baseEditForm', `accessList[${index}].accessMapImageId`, data.id)
  );

  dispatch(
    change(
      'baseEditForm',
      `accessList[${index}].accessMap`,
      `static/tmp/${data.id}`
    )
  );
};

const makeUploadAccessMapFile = (file, index) => {
  return async (dispatch) => {
    trackAsync(async () => {
      try {
        const uploadAccessMapFileRes = await uploadAccessMapFile(file);

        uploadAccessMapFileSuccess(
          dispatch,
          uploadAccessMapFileRes.data,
          index
        );
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

const makeDeleteAccessMapImage = (index) => {
  return (dispatch) => {
    dispatch(change('baseEditForm', `accessList[${index}].accessMap`, null));
    dispatch(
      change('baseEditForm', `accessList[${index}].accessMapImageId`, null)
    );
  };
};

const fetchSearchZipCode = (zipcode) => {
  return axios.get('/admin/search_address', {
    params: {
      zipCode: zipcode,
    },
  });
};

const fetchSearchZipCodeSuccess = (dispatch, data) => {
  dispatch(change('baseEditForm', 'prefId', data.prefId));

  dispatch(change('baseEditForm', 'city', data.address2 + data.address3));
};

const fetchSearchZipCodeFailure = (err) => {
  return fetchErrorData(err);
};

const makeFetchSearchZipCode = (zipcode) => {
  return async (dispatch) => {
    try {
      const res = await fetchSearchZipCode(zipcode);

      fetchSearchZipCodeSuccess(dispatch, res.data);
    } catch (err) {
      dispatch(fetchSearchZipCodeFailure(err));
    }
  };
};

const storeValues = (values) => {
  return {
    type: 'base/STORE_VALUES',
    payload: values,
  };
};

const backToInput = () => {
  return new EmptyAction('base/BACK_TO_INPUT');
};

const confirmIsUsed = (baseIds) => {
  return axios.get('/admin/base/used', {
    params: {
      bases: baseIds,
    },
  });
};

const confirmIsUsedSuccess = (data) => {
  if (data.containsUsed) {
    return new EmptyAction('base/SHOW_USED_CONTAINS_ALERT');
  } else {
    return showDeleteConfirm();
  }
};

const makeConfirmIsUsed = (baseIds) => {
  return (dispatch) => {
    return trackAsync(async () => {
      try {
        const isUsedRes = await confirmIsUsed(baseIds);
        dispatch(confirmIsUsedSuccess(isUsedRes.data));
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

const newBase = () => {
  return {
    type: 'base/NEW_BASE',
  };
};

const initReferState = () => {
  return {
    type: 'base/INIT_REFER_STATE',
  };
};

const makeHideContainsAlertModal = () => {
  return new EmptyAction('base/HIDE_USED_CONTAINS_ALERT');
};

export {
  makeFetchAreas,
  makeFetchPrefs,
  makeSearch,
  makeFetchBase,
  makeUpdateBase,
  makeDeleteBase,
  errorDelete,
  showDeleteConfirm,
  closeDeleteConfirm,
  closeDeleteComplete,
  addAccessList,
  deleteAccessList,
  makeFetchBaseDetailForEdit,
  makeUploadAccessMapFile,
  makeDeleteAccessMapImage,
  makeFetchSearchZipCode,
  makeConfirmIsUsed,
  makeHideContainsAlertModal,
  storeValues,
  backToInput,
  newBase,
  initReferState,
};

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const DisableUserRow = styled.th`
  background-color: rgba(0, 0, 0, 0.03);
`;

export default class JobHuntingInformation extends React.Component {
  static get propTypes() {
    return {
      userRefer: PropTypes.object,
    };
  }

  render() {
    const selector = (prop, name) => {
      if (
        Object.hasOwnProperty.call(this.props.userRefer, prop) &&
        Object.hasOwnProperty.call(this.props.userRefer[prop], name)
      ) {
        return this.props.userRefer[prop][name];
      }
      return null;
    };

    const selectorOr = (selector, name, elseValue) => {
      const value = selector(name);

      if (value != null) {
        return value;
      }
      return elseValue;
    };

    const selectorMap = (selector, name, f) => {
      const value = selector(name);

      if (value != null) {
        return f(value);
      }
      return null;
    };

    const selectorList = (selector, listName, index, name) => {
      return selectorMap(selector, listName, (list) => {
        if (list.length >= index) {
          const listValue = list[index];

          if (
            listValue != null &&
            Object.hasOwnProperty.call(listValue, name)
          ) {
            return listValue[name];
          }
        }
        return null;
      });
    };

    const profileBaseInfoSelector = selector.bind(this, 'profileBaseInfo');
    const profileCareerInfoSelector = selector.bind(this, 'profileCareerInfo');
    const profileWorkInfoSelector = selector.bind(this, 'profileWorkInfo');

    // 受けた企業数
    const getApplications = () => {
      return selectorOr(profileWorkInfoSelector, 'applications', 0);
    };

    // 面接まで進んだ企業数
    const getInterviews = () => {
      return profileWorkInfoSelector('interviews');
    };

    // 内定数
    const getOffers = () => {
      return profileWorkInfoSelector('offers');
    };

    // 就活状況
    // 応募企業名
    const getWipCompanyName = (index) => {
      return selectorList(
        profileWorkInfoSelector,
        'wipList',
        index,
        'wipCompanyName'
      );
    };

    // 応募企業名 選考状況
    const getWipStatus = (index) => {
      return selectorList(
        profileWorkInfoSelector,
        'wipList',
        index,
        'wipStatus'
      );
    };

    // 応募企業名 業界1
    const getWipIndustry = (index) => {
      return selectorList(
        profileWorkInfoSelector,
        'wipList',
        index,
        'wipIndustry'
      );
    };

    // 応募企業名 職種1
    const getWipJobCategory = (index) => {
      return selectorList(
        profileWorkInfoSelector,
        'wipList',
        index,
        'wipJobCategory'
      );
    };

    // 現在の状況
    const getCondition = () => {
      return profileBaseInfoSelector('statusName');
    };

    // 就職・転職の理由
    const getPurpose = () => {
      return profileCareerInfoSelector('purpose');
    };

    // 就活に置いて相談したいこと
    const getConsultationTopic = () => {
      const topics = profileCareerInfoSelector('consultationTopic');
      if (topics && topics.length > 0) {
        return topics.reduce((acm, value) => {
          return `${acm}・${value}`;
        });
      }
      return '';
    };

    // 就職を希望する時期
    const getDesiredTime = () => {
      return profileCareerInfoSelector('desiredTime');
    };

    // 就職を希望する企業の規模
    // const getDesiredCompanyScale = () => {
    //   return profileCareerInfoSelector('desiredCompanyScale');
    // };

    // 希望業界
    const getDesiredIndustry = () => {
      return profileCareerInfoSelector('desiredIndustry');
    };

    // 希望職種
    const getDesiredJobCategory = () => {
      return profileCareerInfoSelector('desiredJobCategory');
    };

    // 希望給与（月給）
    const getDesiredSalary = () => {
      return profileCareerInfoSelector('desiredSalary');
    };

    return (
      <>
        <h3>就活状況</h3>
        <table className="table" border="1">
          <tr>
            <DisableUserRow scope="col">受けた企業の社数</DisableUserRow>
            <th scope="col">{getApplications()}件</th>
            <DisableUserRow scope="col">面接まで進んだ社数</DisableUserRow>
            <th scope="col">{getInterviews()}件</th>
            <DisableUserRow scope="col">内定数</DisableUserRow>
            <th scope="col">{getOffers()}件</th>
          </tr>
          <tr>
            <DisableUserRow scope="col" colSpan="3">
              応募企業名
            </DisableUserRow>
            <DisableUserRow scope="col">選考状況</DisableUserRow>
            <DisableUserRow scope="col">業界</DisableUserRow>
            <DisableUserRow scope="col">職種</DisableUserRow>
          </tr>
          <tr>
            <th scope="col" colSpan="3">
              {getWipCompanyName(0)}
            </th>
            <th scope="col">{getWipStatus(0)}</th>
            <th scope="col">{getWipIndustry(0)}</th>
            <th scope="col">{getWipJobCategory(0)}</th>
          </tr>
          <tr>
            <th scope="col" colSpan="3">
              {getWipCompanyName(1)}
            </th>
            <th scope="col">{getWipStatus(1)}</th>
            <th scope="col">{getWipIndustry(1)}</th>
            <th scope="col">{getWipJobCategory(1)}</th>
          </tr>
          <tr>
            <th scope="col" colSpan="3">
              {getWipCompanyName(2)}
            </th>
            <th scope="col">{getWipStatus(2)}</th>
            <th scope="col">{getWipIndustry(2)}</th>
            <th scope="col">{getWipJobCategory(2)}</th>
          </tr>
        </table>
        <br />

        <table className="table" border="1">
          <tr>
            <DisableUserRow scope="col">現在の状況</DisableUserRow>
            <th scope="col">{getCondition()}</th>
          </tr>
          <tr>
            <DisableUserRow scope="col">就職・転職の理由</DisableUserRow>
            <th scope="col">{getPurpose()}</th>
          </tr>
          <tr>
            <DisableUserRow scope="col">
              就活において相談したいこと
            </DisableUserRow>
            <th scope="col">{getConsultationTopic()}</th>
          </tr>
          <tr>
            <DisableUserRow scope="col">就職を希望する時期</DisableUserRow>
            <th scope="col">{getDesiredTime()}</th>
          </tr>
          <tr>
            <DisableUserRow scope="col">希望業界</DisableUserRow>
            <th scope="col">{getDesiredIndustry()}</th>
          </tr>
          <tr>
            <DisableUserRow scope="col">希望職種</DisableUserRow>
            <th scope="col">{getDesiredJobCategory()}</th>
          </tr>
          <tr>
            <DisableUserRow scope="col">希望給与（月給）</DisableUserRow>
            <th scope="col">{getDesiredSalary()}</th>
          </tr>
        </table>
        <br />
      </>
    );
  }
}

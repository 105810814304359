import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ConfirmForm from '../../organisms/adminProfile/AdminProfileConfirmForm';
import Form from '../../organisms/adminProfile/AdminProfileEditForm';
import Card from '../../organisms/Card';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';

import routePath from './routePath';

class AdminProfileEdit extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        adminProfileEdit: PropTypes.shape({
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
        }),
      }),
      updateAdminProfile: PropTypes.func,
      initState: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      fetchAdminProfile: PropTypes.func,
      setEditValues: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.confirm = this.confirm.bind(this);
    this.transitionToRefer = this.transitionToRefer.bind(this);
    this.backToInput = this.backToInput.bind(this);
    this.submit = this.submit.bind(this);
    this.state = { isConfirm: false };
  }

  componentDidMount() {
    this.props.fetchAdminProfile();
  }

  confirm(values) {
    console.debug('confirm', values);
    this.setState({ values: values, isConfirm: true });

    this.props.setEditValues(values);
  }

  submit() {
    this.props.updateAdminProfile(this.state.values);
  }

  backToInput() {
    this.setState({ isConfirm: false });
  }

  transitionToRefer() {
    this.props.initState();
    this.props.history.push(routePath.refer);
  }

  render() {
    const isConfirm = () => {
      return this.state.isConfirm;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>社員プロフィール情報</h1>
          </div>

          <div className="section-body">
            {this.props.state.isLoading ? (
              <></>
            ) : (
              <Row>
                <Col>
                  <Card headertitle={isConfirm() ? '確認' : '編集'}>
                    {isConfirm() ? (
                      <ConfirmForm
                        onSubmit={this.submit}
                        backToInput={this.backToInput}
                        values={this.state.values}
                      />
                    ) : (
                      <Form
                        onSubmit={this.confirm}
                        onPrevButtonClick={this.transitionToRefer}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </section>

        <RegisterCompleteModal
          show={this.props.state.adminProfileEdit.isComplete}
          onCloseClick={this.transitionToRefer}
        />
      </>
    );
  }
}

export default AdminProfileEdit;

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import EditButton from '../../molecules/EditButton';
import ReferItem from '../../molecules/ReferItem';
import ProfileImage from '../../organisms/adminProfile/ProfileImage';
import Card from '../../organisms/Card';

import routePath from './routePath';

class AdminProfileRefer extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      fetchAdminProfile: PropTypes.any,
      state: PropTypes.shape({
        adminProfileRefer: PropTypes.shape({
          detail: PropTypes.shape({
            familyName: PropTypes.string,
            firstName: PropTypes.string,
            profileImgURL: PropTypes.string,
            mailAddress: PropTypes.string,
            baseId: PropTypes.string,
            baseName: PropTypes.string,
            roleId: PropTypes.string,
            roleName: PropTypes.string,
            systemAdminFlag: PropTypes.bool,
            phoneNumber: PropTypes.string,
          }),
        }),
        isLoading: PropTypes.bool,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.transitionToEdit = this.transitionToEdit.bind(this);
  }

  componentDidMount() {
    this.props.fetchAdminProfile();
  }

  transitionToEdit() {
    this.props.history.push(routePath.edit);
  }

  render() {
    /* 名前 */
    const getName = () => {
      return `${this.props.state.adminProfileRefer.detail.familyName} ${this.props.state.adminProfileRefer.detail.firstName}`;
    };

    /* メールアドレス */
    const getMailAddress = () => {
      return this.props.state.adminProfileRefer.detail.mailAddress;
    };

    /* 電話番号 */
    const getPhoneNumber = () => {
      return this.props.state.adminProfileRefer.detail.phoneNumber;
    };

    /* 拠点 */
    const getBase = () => {
      return this.props.state.adminProfileRefer.detail.baseName;
    };

    /* ロール */
    const getRole = () => {
      return this.props.state.adminProfileRefer.detail.roleName;
    };

    /* システム管理者フラグ */
    const isSystemAdmin = () => {
      if (this.props.state.adminProfileRefer.detail.systemAdminFlag) {
        return 'システム管理者';
      } else {
        return 'なし';
      }
    };

    const getProfileImageUrl = () => {
      if (this.props.state.adminProfileRefer.detail.profileImgURL) {
        return `${process.env.REACT_APP_API_URI}/${this.props.state.adminProfileRefer.detail.profileImgURL}`;
      }

      return null;
    };

    return (
      <section className="section">
        <div className="section-header">
          <h1>社員プロフィール情報</h1>
        </div>

        <div className="section-body">
          {this.props.state.isLoading ? (
            <></>
          ) : (
            <Row>
              <Col>
                <Card headerTitle="詳細">
                  <ReferItem label="名前">{getName()}</ReferItem>
                  <ProfileImage
                    label="プロフィール画像"
                    url={getProfileImageUrl()}
                  />

                  <ReferItem label="メールアドレス">
                    {getMailAddress()}
                  </ReferItem>
                  <ReferItem label="電話番号">{getPhoneNumber()}</ReferItem>
                  <ReferItem label="拠点">{getBase()}</ReferItem>
                  <ReferItem label="ロール">{getRole()}</ReferItem>
                  <ReferItem label="システム管理者フラグ">
                    {isSystemAdmin()}
                  </ReferItem>
                  <hr />
                  <Row>
                    <div className="mx-auto">
                      <EditButton onClick={this.transitionToEdit} />
                    </div>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </section>
    );
  }
}

export default AdminProfileRefer;

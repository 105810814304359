import { bool, func, object, shape, string } from 'prop-types';
import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { connect } from 'react-redux';

import { makeFetchLogout } from '../../actions/Authentication';
import {
  hideErrorModal,
  transitionToProfileRefer,
  transitionToSettingPassword,
  transitionToDefaultPage,
} from '../../actions/NavBar';
import DangerButton from '../atoms/DangerButton';
import LightButton from '../atoms/LightButton';
import ModalFooter from '../atoms/ModalFooter';
import ModalFotterCloseButtonComponent from '../molecules/ModalFotterCloseButtonComponent';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.transitionToProfileRefer = this.transitionToProfileRefer.bind(this);
    this.transitionToSettingPassword = this.transitionToSettingPassword.bind(
      this
    );
    this.onClickHamburgerIconToggle = this.onClickHamburgerIconToggle.bind(
      this
    );

    this.state = { isDisplayLogoutModal: false };
  }

  static get propTypes() {
    return {
      isDisplayErrorModal: bool,
      hideErrorModal: func,
      logout: func,
      transitionToProfileRefer: func,
      transitionToSettingPassword: func,
      transitionToDefaultPage: func,
      history: object,
      user: shape({
        firstName: string,
        familyName: string,
      }),
    };
  }

  logout(e) {
    e.preventDefault();

    this.setState({ isDisplayLogoutModal: true });
  }

  transitionToProfileRefer(e) {
    this.props.transitionToProfileRefer();

    e.preventDefault();
    return false;
  }

  transitionToSettingPassword(e) {
    this.props.transitionToSettingPassword();

    e.preventDefault();
    return false;
  }

  onClickHamburgerIconToggle(e) {
    e.preventDefault();

    this.props.transitionToDefaultPage(this.props.user.loginMenuPath);
  }

  render() {
    return (
      <div>
        <div className="navbar-bg"></div>
        <nav className="navbar navbar-expand-lg main-navbar">
          <form className="form-inline mr-auto">
            <ul className="navbar-nav mr-3">
              <li>
                <a
                  onClick={this.onClickHamburgerIconToggle}
                  href="/#"
                  // data-toggle="sidebar"
                  className="nav-link nav-link-lg"
                >
                  <i className="fas fa-bars"></i>
                </a>
              </li>
            </ul>
          </form>
          <ul className="navbar-nav navbar-right">
            <li className="dropdown">
              <a
                href="/#"
                data-toggle="dropdown"
                className="nav-link dropdown-toggle nav-link-lg nav-link-user"
              >
                <div className="d-sm-none d-lg-inline-block">
                  {this.props.user
                    ? `${this.props.user.familyName} ${this.props.user.firstName}`
                    : ''}
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <a
                  href=""
                  onClick={this.transitionToSettingPassword}
                  className="dropdown-item has-icon"
                >
                  <i className="far fa-user" />
                  パスワード変更
                </a>
                <a
                  href=""
                  onClick={this.transitionToProfileRefer}
                  className="dropdown-item has-icon"
                >
                  <i className="far fa-user" />
                  プロフィール情報編集
                </a>
                <a
                  href=""
                  onClick={this.logout}
                  className="dropdown-item has-icon text-danger"
                >
                  <i className="fas fa-sign-out-alt"></i> Logout
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <Modal show={this.state.isDisplayLogoutModal}>
          <ModalBody>ログアウトしますか</ModalBody>
          <ModalFooter>
            <LightButton
              onClick={() => this.setState({ isDisplayLogoutModal: false })}
            >
              いいえ
            </LightButton>
            <DangerButton
              onClick={(e) => {
                this.setState({ isDisplayLogoutModal: false });
                this.props.logout();
              }}
            >
              はい
            </DangerButton>
          </ModalFooter>
        </Modal>
        <Modal show={this.props.isDisplayErrorModal}>
          <ModalBody>ログアウト処理に失敗しました</ModalBody>
          <ModalFotterCloseButtonComponent
            onClick={this.props.hideErrorModal}
          />
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.navbar.loginUser,
      isDisplayErrorModal: state.navbar.navBar.isDisplayErrorModal,
    };
  },
  (dispatch) => {
    return {
      hideErrorModal() {
        dispatch(hideErrorModal());
      },
      transitionToProfileRefer() {
        dispatch(transitionToProfileRefer());
      },
      transitionToSettingPassword() {
        dispatch(transitionToSettingPassword());
      },
      logout() {
        dispatch(makeFetchLogout());
      },
      transitionToDefaultPage(loginMenuPath) {
        dispatch(transitionToDefaultPage(loginMenuPath));
      },
    };
  }
)(NavBar);

import { push } from 'connected-react-router';

import { EmptyAction } from '../components/model/Action';
import routePath from '../components/pages/adminProfile/routePath';
import settingPasswordRoute from '../components/pages/settingPassword/routePath';

const hideErrorModal = () => {
  return new EmptyAction('navbar/HIDE_ERROR_MODAL');
};

const transitionToProfileRefer = () => {
  return push(routePath.refer);
};

const transitionToSettingPassword = () => {
  return push(settingPasswordRoute.edit);
};

const transitionToDefaultPage = (loginMenuPath) => {
  return push(loginMenuPath);
};

export {
  transitionToProfileRefer,
  transitionToSettingPassword,
  hideErrorModal,
  transitionToDefaultPage,
};

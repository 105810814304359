import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';

import ReferItem from '../../molecules/ReferItem';

class ReferRoles extends React.Component {
  static get propTypes() {
    return {
      RoleList: PropTypes.array,
      menus: PropTypes.array,
    };
  }

  render() {
    console.debug('ReferRoles menus', this.props.menus);
    return (
      <div>
        {this.props.menus != null ? (
          this.props.menus.map((section, index) => {
            return (
              <div key={index}>
                <h5>{section.name}</h5>
                {section.menus.map((menu, index) => {
                  const roles = this.props.RoleList.filter((role) => {
                    return role.menuId === menu.menuId;
                  });
                  if (roles.length > 0) {
                    return (
                      <Col
                        key={index}
                        sm={{
                          offset: 1,
                        }}
                      >
                        <ReferItem label={menu.menuName} key={index}>
                          {roles[0].roleName}
                        </ReferItem>
                      </Col>
                    );
                  }
                  return (
                    <Col key={index} sm={{ offset: 1 }}>
                      <ReferItem label={menu.menuName} key={index}>
                        なし
                      </ReferItem>
                    </Col>
                  );
                })}
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default ReferRoles;

import PropTypes, { func, shape, string } from 'prop-types';
import React from 'react';

class SubstituteAlert extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      substituteUser: shape({
        name: string,
      }),
      onClickReleaseButton: func,
    };
  }

  render() {
    return (
      <div className={'alert alert-warning'}>
        <span>{this.props.substituteUser.name}さんの代理予約処理中</span>
      </div>
    );
  }
}

export default SubstituteAlert;

import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import ModalBody from '../../atoms/ModalBody';
import HorizontalBadgesComponent from '../../molecules/HorizontalBadgesComponent';
import ModalFooterThreeButtonComponent from '../../molecules/ModalFooterThreeButtonComponent';
import ReferItem from '../../molecules/ReferItem';
import { EditAction, ReferAction } from '../../utils/PermissionComponent';

import SearchDetailModalAccordion from './SearchDetailModalAccordion';
import SearchEventsDetailModalHeaderComponent from './SearchEventsDetailModalHeaderComponent';
import SubstituteAlertForModal from './SubstituteAlertForModal';

class SearchEventsDetailModal extends React.Component {
  static get propTypes() {
    return {
      show: PropTypes.bool,
      eventsDetail: PropTypes.any,
      onClickModalEventDetailButton: PropTypes.func,
      onClickModalEventEditButton: PropTypes.func,
      eventTypeOptions: PropTypes.array,
      onClickModalCloseButton: PropTypes.func,
      substituteUser: PropTypes.object,
    };
  }

  constructor(props) {
    super(props);
    this.onClickModalEventDetailButton = this.onClickModalEventDetailButton.bind(
      this
    );

    this.onClickModalEventEditButton = this.onClickModalEventEditButton.bind(
      this
    );
  }

  onClickModalEventDetailButton() {
    const eventId = this.props.eventsDetail.id;

    this.props.onClickModalEventDetailButton(eventId);
  }

  onClickModalEventEditButton() {
    const eventId = this.props.eventsDetail.id;

    this.props.onClickModalEventEditButton(eventId);
  }

  render() {
    const getKindId = () => {
      const filteredOptions = this.props.eventTypeOptions.filter((option) => {
        return option.value === this.props.eventsDetail.kindId;
      });

      if (filteredOptions.length > 0) {
        return filteredOptions[0].name;
      }

      return '';
    };

    const getTags = () => {
      return this.props.eventsDetail.tags || [];
    };

    const participantsNumber = `${this.props.eventsDetail.list.length} / ${this.props.eventsDetail.seatAvailability}の参加者`;

    return (
      <Modal show={this.props.show}>
        <SearchEventsDetailModalHeaderComponent
          isFullyOccupied={this.props.eventsDetail.isFullParticipant}
          title={this.props.eventsDetail.title}
          eventType={getKindId()}
        />
        <ModalBody>
          {this.props.substituteUser ? (
            <SubstituteAlertForModal
              substituteUser={this.props.substituteUser}
            />
          ) : (
            <></>
          )}
          {getTags().length > 0 ? (
            <HorizontalBadgesComponent badges={getTags()} />
          ) : (
            <></>
          )}

          <br />
          <ReferItem label="開催日：">
            {this.props.eventsDetail.dateOfHolding}
          </ReferItem>
          <ReferItem label="講師：">
            {this.props.eventsDetail.lecturer}
          </ReferItem>
          <ReferItem label="会場：">{this.props.eventsDetail.venue}</ReferItem>
          <ReferItem label="参加人数：">
            <SearchDetailModalAccordion
              accordionTitle={participantsNumber}
              list={this.props.eventsDetail.list.map((user) => user.name)}
            />
          </ReferItem>
        </ModalBody>
        <ModalFooterThreeButtonComponent
          onClickCloseButton={this.props.onClickModalCloseButton}
          leftButtonLabel="イベント詳細"
          leftButtonAction={ReferAction}
          onClickLeftButton={this.onClickModalEventDetailButton}
          rightButtonLabel="予約編集"
          rightButtonAction={EditAction}
          rightButtonDisable={false}
          onClickRightButton={this.onClickModalEventEditButton}
        />
      </Modal>
    );
  }
}

export default SearchEventsDetailModal;

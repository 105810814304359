import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';

import DeleteButton from '../../molecules/DeleteButton';
import DetailButton from '../../molecules/DetailButton';
import Card from '../../organisms/Card';
import Pager from '../../organisms/Pager';
import SearchResultTable from '../../organisms/SearchResultTable';
import DeleteCompleteModal from '../../organisms/template/DeleteCompleteModal';
import DeleteConfirmModal from '../../organisms/template/DeleteConfirmModal';
import Form from '../../organisms/template/TemplateSearchForm';
import SearchComponent from '../common/SearchComponent';

import routePath from './routePath';

class TemplateSearch extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        searchParams: PropTypes.shape({
          params: PropTypes.shape({
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
            }),
            sort: PropTypes.object,
          }),
          values: PropTypes.object,
        }),
        searchResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          result: PropTypes.array,
        }),
      }),
      templateDelete: {
        isSubmitting: PropTypes.bool,
        isConfirm: PropTypes.bool,
        isComplete: PropTypes.bool,
        errMessage: PropTypes.string,
      },
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      search: PropTypes.func,
      deleteTemplate: PropTypes.func,
      fetchCategories: PropTypes.func,
      errorDelete: PropTypes.func,
      showDeleteConfirm: PropTypes.func,
      closeDeleteConfirm: PropTypes.func,
      closeDeleteComplete: PropTypes.func,
      newTemplate: PropTypes.func,
      fetchTemplateCategories: PropTypes.func,
      fetchTemplateNames: PropTypes.func,
      fetchTemplateGroups: PropTypes.func,
      initState: PropTypes.func,
      lastLocation: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    };
  }

  constructor(props) {
    super(props);
    this.state = { deleteSelections: [] };
    this.searchComponent = new SearchComponent();

    this.clickSearchButton = this.clickSearchButton.bind(this);
    this.getSearchItem = this.getSearchItem.bind(this);

    this.search = this.search.bind(this);
  }

  componentDidMount() {
    const isComeFromOtherPage =
      this.props.lastLocation &&
      !this.props.lastLocation.pathname.includes(routePath.search);

    const searchCondition =
      isComeFromOtherPage || isComeFromOtherPage == null
        ? {}
        : this.props.state.searchParams.values;

    const searchParams = {
      sort:
        isComeFromOtherPage || isComeFromOtherPage == null
          ? {
              sortColumn: 'id',
              sortType: 'asc',
            }
          : this.props.state.searchParams.params.sort || {
              sortColumn: 'id',
              sortType: 'asc',
            },
      pagination:
        isComeFromOtherPage || isComeFromOtherPage == null
          ? { page: 1, pageSize: 10 }
          : this.props.state.searchParams.params.pagination || {
              page: 1,
              pageSize: 10,
            },
    };

    if (isComeFromOtherPage) {
      this.props.initState();
    }

    this.props.fetchTemplateCategories();
    this.props.fetchTemplateNames();
    this.props.fetchTemplateGroups();
    this.search(searchParams, searchCondition);
  }

  clickSearchButton(values) {
    const prevSearchParams = this.props.state.searchParams.params;
    let pageSize = this.searchComponent.pageDefaultSize;
    if (
      prevSearchParams &&
      prevSearchParams.pagination &&
      prevSearchParams.pagination.pageSize
    ) {
      pageSize = prevSearchParams.pagination.pageSize;
    }

    this.search(
      {
        sort: {
          sortColumn: 'id',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      values
    );
  }

  selectionDisableTempalte(id) {
    const currentSelections = this.state.deleteSelections;

    let newSelections;
    if (currentSelections.indexOf(id) !== -1) {
      newSelections = currentSelections.filter((selectionId) => {
        return selectionId !== id;
      });
    } else {
      newSelections = currentSelections.concat([id]);
    }

    this.setState({ deleteSelections: newSelections });
  }

  getSearchItem() {
    const result = this.props.state.searchResults.result;

    if (result == null) {
      return '';
    }

    const transitionRefer = (id) => {
      this.props.history.push(routePath.refer(id));
    };

    const items = result.map((tempalte, id) => {
      return (
        <tr key={tempalte.id}>
          <th scope="row">
            {tempalte.defaultFlag ? (
              <></>
            ) : (
              <input
                className="form-check-input mx-auto"
                type="checkbox"
                onChange={this.selectionDisableTempalte.bind(this, tempalte.id)}
              />
            )}
          </th>
          <td>{tempalte.defaultFlag ? '●' : '○'}</td>
          <td>{tempalte.categoryName}</td>
          <td>{tempalte.groupName}</td>
          <td>{tempalte.templateName}</td>
          <td>{tempalte.customName}</td>
          <td>{tempalte.sendMethodName}</td>
          <td>{tempalte.updatedAt}</td>
          <td>
            <DetailButton onClick={transitionRefer.bind(this, tempalte.id)} />
          </td>
        </tr>
      );
    });
    return items;
  }

  search(searchParams, values = this.props.state.searchParams.values) {
    this.props.search(values, searchParams);
  }

  // 削除ボタン
  deleteButtonClick() {
    // チェック
    if (this.state.deleteSelections.length === 0) {
      this.props.errorDelete();
      return;
    }

    this.props.showDeleteConfirm();
  }

  onYesClick() {
    this.props.deleteTemplate(this.state.deleteSelections);
  }

  onNoClick() {
    console.debug('onNoClick');
    this.props.closeDeleteConfirm();
  }

  onCloseClick() {
    this.clickSearchButton(this.state.values);
    this.props.closeDeleteComplete();
  }

  render() {
    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>通知テンプレート管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="通知テンプレート管理検索">
                  <div className="section-title">検索項目</div>
                  <Form onSubmit={this.clickSearchButton} />
                  <div className="text-left">
                    {this.props.state.searchResults.result != null &&
                    this.props.state.searchResults.result.length > 0 ? (
                      <>
                        <hr />
                        <Pager
                          searchParams={
                            this.props.state.searchResults.searchParams
                          }
                          search={this.search}
                        />

                        <SearchResultTable
                          headerItems={[
                            {},
                            {
                              name: 'message_templates.is_default',
                              value: 'デフォルト',
                            },
                            {
                              name: 'message_template_category.name',
                              value: 'カテゴリー',
                            },
                            {
                              name: 'message_template_group.name',
                              value: 'グループ',
                            },
                            {
                              name: 'message_template_names.name',
                              value: 'テンプレート名',
                            },
                            {
                              name: 'message_templates.custom_name',
                              value: 'カスタム名',
                            },
                            { name: 'contact_tools.name', value: '通知媒体' },
                            {
                              name: 'message_templates.updated_at',
                              value: '更新日時',
                            },
                            {},
                          ]}
                          searchResults={this.props.state.searchResults}
                          getSearchItem={this.getSearchItem}
                          search={this.search}
                        />
                        <Row>
                          <Col>
                            <DeleteButton
                              onClick={this.deleteButtonClick.bind(this)}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>検索条件に当てはまるテンプレートはありませんでした。</>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <DeleteConfirmModal
          show={this.props.templateDelete.isConfirm}
          onYesClick={this.onYesClick.bind(this)}
          onNoClick={this.onNoClick.bind(this)}
        />

        <DeleteCompleteModal
          show={this.props.templateDelete.isComplete}
          onCloseClick={this.onCloseClick.bind(this)}
        />
      </>
    );
  }
}

const LastLocation = (Component) => {
  const InnerComponent = (props) => {
    const lastLocation = useLastLocation();
    return <Component {...props} lastLocation={lastLocation} />;
  };

  return InnerComponent;
};

export default LastLocation(
  connect((state) => {
    return {
      ...state,
      templateDelete: {
        ...state.template.templateDelete,
        isConfirm: state.template.templateDelete.isConfirm,
        isComplete: state.template.templateDelete.isComplete,
        errMessage: state.template.templateDelete.errMessage,
      },
    };
  })(TemplateSearch)
);

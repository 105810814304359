import { connect } from 'react-redux';

import {
  makeFetchAreas,
  makeFetchBases,
  makeFetchOccupations,
  makeFetchRoles,
  makeFetchDivisions,
  makeFetchPrefGroups,
  makeSearch,
  makeFetchStaff,
  makeUpdateStaff,
  makeFetchStaffEditData,
  setEditValues,
  backToInput,
  makeFetchBulkUpdateHistories,
  makeUploadBulkUpdateFile,
  makeBulkUpdateExec,
  hideExecutedDialog,
  hideBulkUpdateFailureDialog,
  makeDownloadBulkUpdateResultFile,
  makeDisableStaffs,
  hideDisableSuccessModal,
  makeUnlockStaff,
  hideUnlockSuccessModal,
  makeReSendActivationMail,
  initState,
  hideReSendMailCompleteModal,
  hideReSendFailureDialog,
  makeFetchAdminProfile,
  makeFetchBulkUpdateStatus,
  initializeStaffBulk,
  initReferState,
  makeInitFetchSearchPage,
} from '../actions/Staff';
import StaffComponent from '../components/pages/staff/Staff';
import StaffBulkUpdateComponent from '../components/pages/staff/StaffBulkUpdate';
import StaffEditComponent from '../components/pages/staff/StaffEdit';
import StaffReferComponent from '../components/pages/staff/StaffRefer';
import StaffSearchComponent from '../components/pages/staff/StaffSearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.staff,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  console.debug('dispatch staff', dispatch);
  return {
    fetchStaffEditData(id) {
      dispatch(makeFetchStaffEditData(id));
    },
    fetchArea() {
      dispatch(makeFetchAreas());
    },
    fetchBases() {
      dispatch(makeFetchBases());
    },
    fetchOccupations() {
      dispatch(makeFetchOccupations());
    },
    fetchRoles() {
      dispatch(makeFetchRoles());
    },
    fetchDivisions() {
      dispatch(makeFetchDivisions());
    },
    fetchPrefGroups() {
      dispatch(makeFetchPrefGroups());
    },
    search(values, searchParams) {
      dispatch(makeSearch(values, searchParams));
    },
    fetchStaff(id) {
      dispatch(makeFetchStaff(id));
    },
    updateStaff(values) {
      dispatch(makeUpdateStaff(values));
    },
    setEditValues(values) {
      dispatch(setEditValues(values));
    },
    backToInput() {
      dispatch(backToInput());
    },
    fetchBulkUpdateHistories(pagination) {
      dispatch(makeFetchBulkUpdateHistories(pagination));
    },
    uploadBulkUpdateFile(file) {
      dispatch(makeUploadBulkUpdateFile(file));
    },
    bulkUpdateExec(tempFileId, originalFileName) {
      dispatch(makeBulkUpdateExec(tempFileId, originalFileName));
    },
    hideExecutedDialog() {
      dispatch(hideExecutedDialog());
    },
    downloadBulkUpdateResultFile(id) {
      dispatch(makeDownloadBulkUpdateResultFile(id));
    },
    disableStaff(ids, searchValues) {
      dispatch(makeDisableStaffs(ids, searchValues));
    },
    hideDisableSuccessModal() {
      dispatch(hideDisableSuccessModal());
    },
    unlockStaff(id) {
      dispatch(makeUnlockStaff(id));
    },
    hideUnlockSuccessModal() {
      dispatch(hideUnlockSuccessModal());
    },
    fetchProfile() {
      dispatch(makeFetchAdminProfile());
    },
    initState() {
      dispatch(initState());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffComponent);
const StaffSearch = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initFetchSearchPage(isComeFromOtherPage) {
      dispatch(makeInitFetchSearchPage(isComeFromOtherPage));
    },
  };
})(StaffSearchComponent);

export { StaffSearch };

const StaffRefer = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    reSendActivationMail(id) {
      dispatch(makeReSendActivationMail(id));
    },
    hideReSendCompleteDialog() {
      dispatch(hideReSendMailCompleteModal());
    },
    hideReSendFailureDialog() {
      dispatch(hideReSendFailureDialog());
    },
  };
})(StaffReferComponent);

export { StaffRefer };

const StaffEdit = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initReferState() {
      dispatch(initReferState());
    },
  };
})(StaffEditComponent);

export { StaffEdit };

const StaffBulkUpdate = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initializeStaffBulk(searchValues) {
      dispatch(initializeStaffBulk(searchValues));
    },
    fetchBulkUpdateStatus() {
      dispatch(makeFetchBulkUpdateStatus());
    },
    hideBulkUpdateFailureDialog() {
      dispatch(hideBulkUpdateFailureDialog());
    },
  };
})(StaffBulkUpdateComponent);

export { StaffBulkUpdate };

import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from '../atoms/NormalButton';
import { DeleteAction } from '../utils/PermissionComponent';

class DeleteButton extends React.Component {
  static get propTypes() {
    return {
      message: PropTypes.string,
      buttonHidenFlg: PropTypes.bool,
      onClick: PropTypes.func,
      isEnable: PropTypes.bool,
    };
  }

  render() {
    return (
      <DeleteAction>
        <NormalButton
          color="danger"
          onClick={this.props.onClick}
          hiddenFlg={this.props.buttonHidenFlg}
          isEnabled={this.props.isEnable}
        >
          削除
        </NormalButton>
      </DeleteAction>
    );
  }
}

export default DeleteButton;

import { connect } from 'react-redux';

import { makeReferData } from '../../actions/Venue';
import VenueManagementReferComponent from '../../components/pages/venueManagement/Refer';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.venue,
  };
};

const mapDispatchToProps = (dispatch) => {
  console.log('mapDispatchToProps:', dispatch);
  return {
    fetchData: (condition, searchParams) => {
      dispatch(makeReferData(condition, searchParams));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenueManagementReferComponent);

import {
  ReservationManagementSearch,
  ReservationManagementEventEdit,
  ReservationManagementInterviewEdit,
} from '../../../containers/ReservationManagement';

import routePath from './routePath';

const ReservationManagementRoutes = [
  {
    path: routePath.search(':id?'),
    exact: true,
    component: ReservationManagementSearch,
  },
  {
    path: routePath.eventEdit(':id'),
    component: ReservationManagementEventEdit,
  },
  {
    path: routePath.interviewEdit(':id'),
    component: ReservationManagementInterviewEdit,
  },
];

export default ReservationManagementRoutes;

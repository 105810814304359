import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInput';
import FormLabel from '../../atoms/FormLabel';
import RequireMark from '../../atoms/RequireMarkComponent';
import * as Validator from '../../utils/Validate';

import SelectedSelfButton from './SelectedSelfButton';

class ChairmanInputComponent extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      name: PropTypes.string,
      label: PropTypes.string,
      messages: PropTypes.array,
      validate: PropTypes.array,
      onClickSelfAssignButton: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  /**
   *
   * @param {Object} field
   */
  component(field) {
    return (
      <div className="form-group row">
        {this.props.name ? (
          <Element name={`position-${this.props.name}`} />
        ) : (
          <></>
        )}
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          {this.isRequired() ? <RequireMark /> : <></>}
        </FormLabel>
        <div className="col-sm-5">
          <FormInput {...field} />
          {field.meta.touched && field.meta.error && (
            <Form.Control.Feedback type="invalid">
              {field.meta.error}
            </Form.Control.Feedback>
          )}
          {/* サーバーサイドエラーメッセージ */}
          {this.props.messages != null && this.props.messages.length > 0 ? (
            <div className="alert alert-danger">
              {this.props.messages.map((message, i) => (
                <p key={i}>{message}</p>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="col-sm-4">
          <SelectedSelfButton onClick={this.props.onClickSelfAssignButton} />
        </div>
      </div>
    );
  }

  render() {
    return <Field component={this.component} name={this.props.name} />;
  }
}

export default ChairmanInputComponent;

import { connect } from 'react-redux';

import {
  makeFetchAdminProfile,
  makeFetchBases,
  makeUpdateAdminProfile,
  setEditValues,
  initState,
} from '../actions/AdminProfile';
import AdminProfileComponent from '../components/pages/adminProfile/AdminProfile';
import AdminProfileEditComponent from '../components/pages/adminProfile/AdminProfileEdit';
import AdminProfileReferComponent from '../components/pages/adminProfile/AdminProfileRefer';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.adminProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditValues(values) {
      dispatch(setEditValues(values));
    },
    fetchAdminProfile() {
      dispatch(makeFetchAdminProfile());
    },
    fetchBases() {
      dispatch(makeFetchBases());
    },
    updateAdminProfile(values) {
      dispatch(makeUpdateAdminProfile(values));
    },
    initState() {
      dispatch(initState());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProfileComponent);

const AdminProfileRefer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProfileReferComponent);

export { AdminProfileRefer };

const AdminProfileEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProfileEditComponent);

export { AdminProfileEdit };

import PropTypes from 'prop-types';
import React from 'react';

import DropDown from '../atoms/DropDown';

class SearchDisplayItemNum extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      searchParams: PropTypes.shape({
        pagination: PropTypes.shape({
          pageSize: PropTypes.number,
          page: PropTypes.number,
        }),
        sort: PropTypes.object,
      }),
      search: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.changePageSize = this.changePageSize.bind(this);
  }

  changePageSize(pageSize) {
    console.debug('pageSize: ', pageSize);
    if (pageSize === this.props.searchParams.pagination.pageSize) {
      return;
    }

    const page = this.props.searchParams.pagination.page;

    this.props.search({
      pagination: {
        page: page,
        pageSize: pageSize,
      },
      sort: this.props.searchParams.sort,
    });
  }

  render() {
    return (
      <>
        <label htmlFor="inputPassword3" className="col-sm-3 col-form-label">
          表示件数
        </label>
        <div className="col-sm-9">
          <div className="dropdown d-inline mr-2">
            <button
              className="btn btn-primary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {this.props.searchParams.pagination.pageSize + '件'}
            </button>
            <DropDown className="dropdown-menu" x-placement="bottom-start">
              <a
                className="dropdown-item"
                href="#!"
                onClick={this.changePageSize.bind(this, 10)}
              >
                10件
              </a>
              <a
                className="dropdown-item"
                href="#!"
                onClick={this.changePageSize.bind(this, 30)}
              >
                30件
              </a>
              <a
                className="dropdown-item"
                href="#!"
                onClick={this.changePageSize.bind(this, 50)}
              >
                50件
              </a>
            </DropDown>
          </div>
        </div>
      </>
    );
  }
}

export default SearchDisplayItemNum;

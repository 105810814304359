import PropTypes from 'prop-types';
import React from 'react';

ErrorMessageBox.propTypes = {
  errorMessages: PropTypes.arrayOf(PropTypes.string),
};

export default function ErrorMessageBox(props) {
  const existsErrorMessages = () => {
    return props.errorMessages && props.errorMessages.length > 0;
  };

  return existsErrorMessages() ? (
    <div className="alert alert-danger">
      {props.errorMessages.map((e, i) => {
        return <p key={i}>{e}</p>;
      })}
    </div>
  ) : (
    <></>
  );
}

import {
  CompanySearch,
  CompanyRefer,
  CompanyEdit,
} from '../../../containers/Company';

import routePath from './routePath';

const CompanyRoutes = [
  {
    path: routePath.search,
    exact: true,
    component: CompanySearch,
  },
  {
    path: routePath.refer(':id'),
    component: CompanyRefer,
  },
  {
    path: routePath.newEdit,
    component: CompanyEdit,
    exact: true,
  },
  {
    path: routePath.edit(':id'),
    component: CompanyEdit,
  },
];

export default CompanyRoutes;

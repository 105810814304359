import { connect } from 'react-redux';

import {
  makeUpdateVenue,
  initState,
  insertState,
  makeFetchData,
  storeValues,
  deleteReferData,
  makeSearchData,
  backToEdit,
} from '../../actions/Venue';
import VenueManagementEditComponent from '../../components/pages/venueManagement/Edit';

const mapStateToProps = (state) => {
  return {
    state: state.venue,
  };
};

const mapDispatchToProps = (dispatch) => {
  console.log('mapDispatchToProps:', dispatch);
  return {
    search(values, searchParams) {
      dispatch(makeSearchData(values, searchParams));
    },
    updateVenue: (values, id) => {
      dispatch(makeUpdateVenue(values, id));
    },
    initState: () => {
      dispatch(initState());
    },
    insertState() {
      dispatch(insertState());
    },
    fetchData(id) {
      dispatch(makeFetchData(id));
    },
    storeValues(values) {
      dispatch(storeValues(values));
    },
    deleteReferData() {
      dispatch(deleteReferData());
    },
    backToEdit() {
      dispatch(backToEdit());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenueManagementEditComponent);

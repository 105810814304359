import PropTypes from 'prop-types';
import React from 'react';

class CardBody extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any
    };
  }

  render() {
    return <div className="card-body">{this.props.children}</div>;
  }
}

export default CardBody;

import { trackPromise } from 'react-promise-tracker';

import Action from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('/admin/template');

const showLoading = () => {
  return {
    type: 'SHOW_LOADING',
  };
};

const makeFetchTemplateCategories = () => {
  return (dispatch) => {
    return fetchTemplateCategories()
      .then((data) => dispatch(fetchTemplateCategoriesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchTemplateCategories = () => {
  return axios.get(`/admin/message_template/category`);
};

const fetchTemplateCategoriesSuccess = (result) => {
  return {
    type: 'FETCH_TEMPLATE_CATEGORIES',
    payload: {
      result: new OptionCollection(
        result.templateCategoryList.map((templateCategory) => {
          return new Option(templateCategory.id, templateCategory.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchTemplateNames = (id) => {
  return (dispatch) => {
    return fetchTemplateNames(id)
      .then((data) => dispatch(fetchTemplateNamesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchTemplateNames = (id) => {
  const params = new Params({
    categoryId: id,
  });

  return axios.get(`/admin/message_template/template_names`, {
    params: params.removeParams(),
  });
};

const fetchTemplateNamesSuccess = (result) => {
  return {
    type: 'FETCH_TEMPLATE_NAMES',
    payload: {
      result: new OptionCollection(
        result.templateNameList.map((templateName) => {
          return new Option(templateName.id, templateName.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchTemplateGroups = () => {
  return (dispatch) => {
    return fetchTemplateGroups()
      .then((data) => dispatch(fetchTemplateGroupsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchTemplateGroups = () => {
  return axios.get(`/admin/message_template/groups`);
};

const fetchTemplateGroupsSuccess = (result) => {
  let options = result.templateGroupList.map((templateGroup) => {
    return new Option(templateGroup.id, templateGroup.name);
  });

  options = [new Option('0', '新規グループ作成')].concat(options);

  return {
    type: 'FETCH_TEMPLATE_GROUPS',
    payload: {
      result: new OptionCollection(options).getOptions(),
    },
  };
};

const storeSearchValues = (values, params) => {
  return new Action('template/STORE_SEARCH_VALUES', {
    values: values,
    params: params,
  });
};

const makeSearch = (values, searchParams) => {
  return (dispatch) => {
    dispatch(storeSearchValues(values, searchParams));

    return trackPromise(
      search(values, searchParams)
        .then((data) => dispatch(fetchSearchSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const search = (values, searchParams) => {
  console.debug('values: ', values);

  // デフォルトフラグ
  const isDefault =
    values &&
    Object.prototype.hasOwnProperty.call(values, 'defaultFlag') &&
    values.defaultFlag.includes('true');
  const defaultFlag = isDefault ? true : null;

  let contactTools = null;
  if (
    values &&
    Object.prototype.hasOwnProperty.call(values, 'contactTools') &&
    values.contactTools != null
  ) {
    contactTools = Object.entries(values.contactTools)
      .filter(([key, value]) => {
        return key != null;
      })
      .map(([key, value]) => {
        console.debug('key', key);
        return value;
      })
      .join(',');
  }

  const params = new Params({
    categoryId: values.categoryId,
    groupName: values.groupName,
    templateName: values.templateName,
    name: values.name,
    defaultFlag: defaultFlag,
    contactType: contactTools,
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  });

  console.debug('searchParams: ', params.removeParams());

  return axios('/admin/message_templates', {
    params: params.removeParams(),
  });
};

const fetchSearchSuccess = (result) => {
  return {
    type: 'template/FETCH_TEMPLATE_SEARCH',
    payload: {
      result: result,
    },
  };
};

const makeFetchTemplate = (id) => {
  return (dispatch) => {
    dispatch(showLoading());
    return fetchTemplate(id)
      .then((data) => dispatch(fetchTemplateSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchTemplate = (id) => {
  return axios.get(`/admin/message_templates/${id}`);
};

const fetchTemplateSuccess = (data) => {
  return {
    type: 'template/FETCH_TEMPLATE',
    payload: {
      result: data,
    },
  };
};

const updateTemplateLoading = () => {
  return {
    type: 'template/UPDATE_TEMPLATE__LOADING',
  };
};

const makeUpdateTemplate = (values) => {
  return (dispatch) => {
    dispatch(updateTemplateLoading());
    let request;
    if (values.id == null) {
      request = postTemplate(values);
    } else {
      request = putTemplate(values);
    }

    trackPromise(
      request
        .then((data) => dispatch(fetchUpdateTemplate(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const postTemplate = (values) => {
  const bodyHtml = values.bodyHtml;

  const params = new Params({
    categoryId: values.categoryId,
    templateNameId: values.templateNameId,
    groupId: values.groupId,
    groupName: values.newGroupName,
    contactType: values.contactType,
    customName: values.customName,
    subject: values.subject,
    body: values.body,
    BodyHtml: bodyHtml,
  });
  return axios.post(`/admin/message_templates`, params.removeParams());
};

const putTemplate = (values) => {
  const bodyHtml = values.bodyHtml;

  const params = new Params({
    categoryId: values.categoryId,
    templateNameId: values.templateNameId,
    groupId: values.groupId,
    groupName: values.newGroupName,
    contactType: values.contactType,
    customName: values.customName,
    subject: values.subject,
    body: values.body,
    bodyHtml: bodyHtml,
  });
  return axios.put(
    `/admin/message_templates/${values.id}`,
    params.removeParams()
  );
};

const fetchUpdateTemplate = (data) => {
  return {
    type: 'template/FETCH_UPDATE_TEMPLATE',
    payload: {
      result: data,
    },
  };
};

const makeDeleteTemplate = (ids) => {
  return (dispatch) => {
    dispatch(deleteTemplateLoading());
    return trackPromise(
      deleteTemplate(ids)
        .then((data) => dispatch(deleteTemplateSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const deleteTemplate = (ids) => {
  return Promise.all(
    ids.map((id) => axios.delete(`/admin/message_templates/${id}`))
  );
};

const deleteTemplateSuccess = (data) => {
  return {
    type: 'template/FETCH_DELETE_TEMPLATE',
    payload: {
      result: data,
    },
  };
};

const deleteTemplateLoading = () => {
  return {
    type: 'template/DELETE_TEMPLATE_LOADING',
  };
};

const initializationTestMailResult = () => {
  return {
    type: 'template/INIT_SEND_MAIL_RESULT',
  };
};

const makeSendMailTest = (values) => {
  return (dispatch) => {
    return trackPromise(
      sendMailTest(values)
        .then((data) => dispatch(sendMailTestSuccess(data.data)))
        .catch(() => dispatch(sendMailTestError()))
    );
  };
};

const sendMailTest = (values) => {
  console.debug('sendMailTest-values: ', values);
  const params = new Params({
    to: values.to,
    subject: values.subject,
    body: values.body,
    htmlBody: values.bodyHtml,
  });
  return axios.post(`/admin/message_template/send_test`, params.removeParams());
};

const sendMailTestSuccess = (data) => {
  return {
    type: 'template/SEND_TEST_MAIL',
    payload: {
      result: data,
    },
  };
};

const sendMailTestError = () => {
  return {
    type: 'template/SEND_TEST_MAIL_ERROR',
  };
};

const fetchContactTypesSuccess = (result) => {
  return {
    type: 'template/FETCH_CONTACT_TYPES',
    payload: {
      result: result,
    },
  };
};

const makeFetchContactTypes = () => {
  return (dispatch) => {
    dispatch(showLoading());
    return fetchContactTypes()
      .then((data) => dispatch(fetchContactTypesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchContactTypes = () => {
  return axios.get(`/admin/message_template/contact_types`);
};

const newTemplate = () => {
  return {
    type: 'template/NEW_TEMPLATE',
  };
};

const showDeleteConfirm = () => {
  return {
    type: 'template/SHOW_DELETE_CONFIRM',
  };
};
const closeDeleteConfirm = () => {
  return {
    type: 'template/CLOSE_DELETE_CONFIRM',
  };
};
const showDeleteAlert = () => {
  return {
    type: 'template/SHOW_DELETE_ALERT',
  };
};
const closeDeleteAlert = () => {
  return {
    type: 'template/CLOSE_DELETE_ALERT',
  };
};
const closeDeleteComplete = () => {
  return {
    type: 'template/CLOSE_DELETE_COMPLETE',
  };
};
const deleteErr = () => {
  return {
    type: 'template/DELETE_ERROR',
  };
};
const initState = () => {
  return {
    type: 'template/INIT_STATE',
  };
};

export {
  makeFetchTemplateCategories,
  makeFetchTemplateNames,
  makeFetchTemplateGroups,
  makeSearch,
  makeFetchTemplate,
  makeUpdateTemplate,
  makeDeleteTemplate,
  makeSendMailTest,
  newTemplate,
  showDeleteConfirm,
  closeDeleteConfirm,
  showDeleteAlert,
  closeDeleteAlert,
  closeDeleteComplete,
  deleteErr,
  initState,
  makeFetchContactTypes,
  initializationTestMailResult,
};

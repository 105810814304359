import { connect } from 'react-redux';

import {
  makeSearch,
  makeShowDeleteConfirmModal,
  makeFetchCompany,
  makeInitCompanyEdit,
  makeInitCompany,
  makeHideDeleteConfirmModal,
  makeDeleteCompanies,
  makeHideDeleteCompleteModal,
  makeSetEditValues,
  makeBackToInput,
  makeUpdateCompany,
  makeConfirmIsUsed,
  makeHideContainsAlertModal,
} from '../actions/Company';
import CompanyComponent from '../components/pages/company/Company';
import CompanyEditComponent from '../components/pages/company/CompanyEdit';
import CompanyReferComponent from '../components/pages/company/CompanyRefer';
import CompanySearchComponent from '../components/pages/company/CompanySearch';

const mapStateToProps = (state) => {
  return {
    state: state.company,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search(values, searchParams) {
      dispatch(makeSearch(values, searchParams));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyComponent);

const CompanySearch = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    confirmIsUsed(companyNames) {
      dispatch(makeConfirmIsUsed(companyNames));
    },
    showDeleteConfirmModal() {
      dispatch(makeShowDeleteConfirmModal());
    },
    initFetchSearchPage(isComeFromOtherPage) {
      dispatch(makeInitCompany(isComeFromOtherPage));
    },
    hideDeleteConfirmModal() {
      dispatch(makeHideDeleteConfirmModal());
    },
    deleteCompany(ids) {
      dispatch(makeDeleteCompanies(ids));
    },
    hideDeleteCompleteModal(searchValues) {
      dispatch(makeHideDeleteCompleteModal(searchValues));
    },
    onAlertModalCloseButtonClick() {
      dispatch(makeHideContainsAlertModal());
    },
  };
})(CompanySearchComponent);

const CompanyRefer = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchCompany(companyId) {
      dispatch(makeFetchCompany(companyId));
    },
  };
})(CompanyReferComponent);

const CompanyEdit = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initCompanyEdit(companyId) {
      dispatch(makeInitCompanyEdit(companyId));
    },
    setEditValues(values) {
      dispatch(makeSetEditValues(values));
    },
    backToInput() {
      dispatch(makeBackToInput());
    },
    updateCompany(values) {
      dispatch(makeUpdateCompany(values));
    },
  };
})(CompanyEditComponent);

export { CompanySearch, CompanyRefer, CompanyEdit };

import { arrayOf, func, array, node, string, shape } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeFetchProfile } from '../../actions/Authentication';
import { makeFetchMenus } from '../../actions/SideBar';

/**
 *
 * @enum {{number}}
 */
const ActionType = {
  Refer: 0,
  Edit: 1,
  Delete: 2,
  Register: 3,
};

/**
 * @typedef {Object} ActionData
 * @property {ActionType} actionType
 * @property {boolean || null} isAllowOwner
 * @property {number || null} dataOwnerId
 * @property {string} menu
 */

/**
 * @typedef {Function} CreateActionData
 * @param {{actionType: ActionType, isAllowOwner?: boolean || null, dataOwnerId?: string || null}}
 * @returns {ActionData}
 */

/**
 *
 * @param {string} menu
 * @returns {CreateActionData}
 */
const createAction = (menu) => ({
  actionType,
  isAllowOwner = false,
  dataOwnerId = null,
}) => {
  return {
    dataOwnerId: dataOwnerId,
    isAllowOwner: isAllowOwner,
    actionType: actionType,
    menu: menu,
  };
};

export const wrap = (WrappedComponent) => {
  const InnerComponent = class Inner extends React.Component {
    static get propTypes() {
      return {
        fetchMenus: func,
        menus: arrayOf(
          shape({
            url: string,
            permissionName: string,
          })
        ),
        isEnabled: func,
        profileId: string,
        location: shape({
          pathname: string,
        }),
      };
    }

    constructor(props) {
      super(props);

      this.availableActionMap = {
        全て: [
          ActionType.Delete,
          ActionType.Edit,
          ActionType.Register,
          ActionType.Refer,
        ],
        編集: [ActionType.Edit, ActionType.Register, ActionType.Refer],
        登録: [ActionType.Register, ActionType.Refer],
        参照: [ActionType.Refer],
      };

      this.isEnabled = this.isEnabled.bind(this);
    }

    componentDidMount() {
      console.debug('componentDidMount', this.props.location.pathname);

      if (this.ignorePermissionMenus(this.props.location.pathname)) {
        return;
      }

      if (this.props.menus.length === 0) {
        this.props.fetchMenus();
      }
    }

    ignorePermissionMenus(pathname) {
      return pathname.includes('/admin/password_reset');
    }

    isEnabled(isEnabled) {
      return () => {
        /**
         *
         * @type {ActionData}
         */
        const action = this.context;

        console.debug('action', action, this.props);

        if (
          !action ||
          !Object.prototype.hasOwnProperty.call(action, 'menu') ||
          !Object.prototype.hasOwnProperty.call(action, 'actionType')
        ) {
          let isEnable = true;
          if (isEnabled) {
            isEnable = isEnabled();
          }
          return isEnable;
        }

        if (Object.hasOwnProperty.call(action, 'isAllowOwner')) {
          if (action.isAllowOwner) {
            let isEnable = true;
            if (isEnabled) {
              isEnable = isEnabled();
            }
            if (action.dataOwnerId === this.props.profileId) {
              return isEnable;
            }
          }
        }

        const menus = this.props.menus;
        console.debug('InnerComponent :', this.props.menus);

        if (!(menus && menus.length > 0)) {
          return false;
        }

        const filteredMenu = menus.filter((menu) => {
          return menu.url === action.menu;
        });

        if (filteredMenu.length === 0) {
          return false;
        }

        const currentMenu = filteredMenu[0];

        const permissionName = currentMenu.permissionName;

        if (!(permissionName in this.availableActionMap)) {
          return false;
        }

        const availableActions = this.availableActionMap[permissionName];

        let isEnable = true;
        if (isEnabled) {
          isEnable = isEnabled();
        }

        return isEnable && availableActions.includes(action.actionType);
      };
    }

    render() {
      console.debug('isEnabled', this.props);
      const { isEnabled, ...other } = this.props;

      return (
        <WrappedComponent {...other} isEnable={this.isEnabled(isEnabled)} />
      );
    }
  };

  InnerComponent.contextType = ActionContext;

  return connect(
    (state) => {
      let profileId;
      if (state.navbar && state.navbar.loginUser) {
        profileId = state.navbar.loginUser.profileId;
      }
      return {
        menus: state.sidebar.menus,
        profileId: profileId,
      };
    },
    (dispatch) => {
      return {
        fetchMenus() {
          console.debug('PermissionComponent fetchMenus');
          dispatch(makeFetchMenus());
        },
        fetchProfile() {
          dispatch(makeFetchProfile());
        },
      };
    }
  )(withRouter((props) => <InnerComponent {...props} />));
};

/**
 *
 * @type {React.Context<ActionData>}
 */
const ActionContext = React.createContext({});

/**
 *
 * @type {React.Context<CreateActionData>}
 */
const ActionCreatorContext = React.createContext(() => {});
const AllowOwnerContext = React.createContext({ isAllowOwner: false });

class Action extends React.Component {
  static get propTypes() {
    return {
      children: node,
    };
  }

  constructor(props, actionType) {
    super(props);
    this.actionType = actionType;
  }

  render() {
    return (
      <ActionCreatorContext.Consumer>
        {(actionCreator) => (
          <AllowOwnerContext.Consumer>
            {(owner) => (
              <ActionContext.Provider
                value={actionCreator({
                  actionType: this.actionType,
                  ...owner,
                })}
              >
                {this.props.children}
              </ActionContext.Provider>
            )}
          </AllowOwnerContext.Consumer>
        )}
      </ActionCreatorContext.Consumer>
    );
  }
}

export class RegisterAction extends Action {
  constructor(props) {
    super(props, ActionType.Register);
  }
}

export class ReferAction extends Action {
  constructor(props) {
    super(props, ActionType.Refer);
  }
}

export class EditAction extends Action {
  constructor(props) {
    super(props, ActionType.Edit);
  }
}

export class DeleteAction extends Action {
  constructor(props) {
    super(props, ActionType.Delete);
  }
}

export class AllowOwner extends React.Component {
  static get propTypes() {
    return {
      dataOwnerId: string,
      children: node,
    };
  }

  render() {
    return (
      <AllowOwnerContext.Provider
        value={{ dataOwnerId: this.props.dataOwnerId, isAllowOwner: true }}
      >
        {this.props.children}
      </AllowOwnerContext.Provider>
    );
  }
}

export class ActionCreatorProvider extends React.Component {
  static get propTypes() {
    return {
      menu: array,
      children: node,
    };
  }

  render() {
    return (
      <ActionCreatorContext.Provider value={createAction(this.props.menu)}>
        {this.props.children}
      </ActionCreatorContext.Provider>
    );
  }
}

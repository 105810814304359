import PropTypes from 'prop-types';
import React from 'react';

class ThLabel extends React.Component {
  static get propTypes() {
    return {
      title: PropTypes.string
    };
  }

  render() {
    return (
      <th scope="col">
        {this.props.title}
      </th>
    );
  }
}

export default ThLabel;

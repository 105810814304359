import {
  ConsultationPlan,
  BulkInsert,
} from '../../../containers/ConsultationPlan';

const ConsultationPlanRoutes = [
  {
    path: '/admin/interviews/',
    exact: true,
    component: ConsultationPlan,
  },
  {
    path: '/admin/interviews/bulk_register',
    exact: true,
    component: BulkInsert,
  },
  {
    path: '/admin/interviews/:id',
    exact: true,
    component: ConsultationPlan,
  },
];

export default ConsultationPlanRoutes;

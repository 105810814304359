import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Card from '../../organisms/Card';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import ConfirmForm from '../../organisms/template/TemplateEditConfirmForm';
import Form from '../../organisms/template/TemplateEditForm';

import routePath from './routePath';
import SendTestResultModal from "../../organisms/template/SendTestResultModal";

class TemplateEdit extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        templateEdit: PropTypes.shape({
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
        }),
      }),
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      updateTemplate: PropTypes.func,
      sendMailTest: PropTypes.func,
      initState: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
    this.transitionToRefer = this.transitionToRefer.bind(this);
    this.backToInput = this.backToInput.bind(this);
    this.submit = this.submit.bind(this);
    this.testMail = this.testMail.bind(this);
    this.transitionToSearch = this.transitionToSearch.bind(this);

    this.state = {
      values: null,
      isConfirm: false,
      isCopy: false,
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    if (params.id != null) {
      this.id = params.id;
      this.props.fetchTemplate(this.id);
    } else if (this.props.location.state != null && this.props.location.state.id) {
      this.props.fetchTemplate(this.props.location.state.id);
      this.setState({
        ...this.state,
        isCopy: true,
      })
    }
  }

  confirm(values) {
    console.debug('values', values);

    if (!this.id) {
      values.id = null;
    } else {
      values.id = this.id;
    }

    this.setState({ values: values, isConfirm: true });
  }

  submit() {
    console.debug('submit values: ', this.state.values);

    this.props.updateTemplate(this.state.values);
  }

  testMail(values) {
    console.debug('testMail values: ', values);
    this.props.sendMailTest(values);
  }

  transitionToRefer() {
    this.props.initState();
    console.debug('transition to refer');
    if (this.id) {
      this.props.history.push(routePath.refer(this.id));
    }else if (this.props.location.state && this.props.location.state.id) {
      this.props.history.push(routePath.refer(this.props.location.state.id));
    } else {
      this.props.history.push(routePath.search);
    }
  }

  transitionToSearch() {
    this.props.history.push(routePath.search);
  }

  backToInput() {
    this.setState({ isConfirm: false });
  }

  render() {
    const isConfirm = () => {
      return this.state.isConfirm;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>通知テンプレート管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="編集">
                  {isConfirm() ? (
                    <ConfirmForm
                      onSubmit={this.submit}
                      backToInput={this.backToInput}
                      values={this.state.values}
                      isCopy={this.state.isCopy}
                    />
                  ) : (
                    <Form
                      onSubmit={this.confirm}
                      onTestMail={this.testMail}
                      onPrevButtonClick={this.transitionToRefer}
                      isCopy={this.state.isCopy}
                      values={this.state.values}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <SendTestResultModal
          show={this.props.state.sendTestMailResult != null}
          result={this.props.state.sendTestMailResult}
          onCloseClick={this.props.initializationTestMailResult}
        />

        <RegisterCompleteModal
          show={this.props.state.templateEdit.isComplete}
          onCloseClick={this.props.location.state && this.props.location.state.id ? this.transitionToSearch : this.transitionToRefer}
        />
      </>
    );
  }
}

export default TemplateEdit;

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import InterviewCalendar from '../../molecules/interview/Calendar';
import LeggendComponent from '../../molecules/LeggendComponent';
import Card from '../Card';

const localizer = momentLocalizer(moment);

class SearchInterviewsResultCalendar extends React.Component {
  static get propTypes() {
    return {
      options: PropTypes.shape({
        areas: PropTypes.array,
        halls: PropTypes.array,
        categories: PropTypes.array,
        tags: PropTypes.array,
        bases: PropTypes.array,
      }),
      interviewsList: PropTypes.array,
      searchParams: PropTypes.object,
      clickToolBar: PropTypes.func,
      fetchAreas: PropTypes.func,
      fetchBases: PropTypes.func,
      fetchCategories: PropTypes.func,
      fetchTags: PropTypes.func,
      fetchHallsByAreaId: PropTypes.func,
      onClickEvent: PropTypes.func,
      defaultDate: PropTypes.Date,
    };
  }

  onClickCalendarInterviews(interviewsId) {
    console.log('************onClickCalendarEvents*********');
    this.props.onClickEvent(interviewsId);
  }

  render() {
    const darkSeaGreen = 'darkseagreen';
    const deepSkyBlue = 'deepSkyBlue';

    const leggends = [
      {
        label: 'マッチング済',
        color: darkSeaGreen,
      },
      {
        label: '未マッチング',
        color: deepSkyBlue,
      },
    ];

    const eventTitleAndBody = ({ event }) => {
      if (event.interviewer) {
        return (
          <span>
            担当者: {event.interviewer}
            <br></br>面談予定者: {event.interviewee}
          </span>
        );
      }
      return <span>担当者: {event.interviewer}</span>;
    };

    return (
      <Card>
        <LeggendComponent leggends={leggends} />
        <InterviewCalendar
          selectable
          localizer={localizer}
          events={this.props.interviewsList}
          timeslots={2}
          defaultView={Views.WEEK}
          onSelectEvent={(interview) =>
            this.onClickCalendarInterviews(interview.id)
          }
          style={{ height: 500 }}
          eventPropGetter={(interview) => ({
            style: {
              backgroundColor: interview.matched ? darkSeaGreen : deepSkyBlue,
              border: '1px silver',
              borderStyle: 'solid',
            },
          })}
          components={{
            event: eventTitleAndBody,
          }}
          defaultDate={this.props.defaultDate}
        />
      </Card>
    );
  }
}

export default SearchInterviewsResultCalendar;

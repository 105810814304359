import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import ModalBody from '../atoms/ModalBody';

import ModalFotterYesNoButtonComponent from './ModalFotterYesNoButtonComponent';

class DeleteConfirmModal extends React.Component {
  static get propTypes() {
    return {
      targetId: PropTypes.string,
      children: PropTypes.string,
      show: PropTypes.bool,
      onYesClick: PropTypes.func,
      onNoClick: PropTypes.func,
    };
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>
          <p className="text-center">{this.props.children}</p>
        </ModalBody>
        <ModalFotterYesNoButtonComponent
          onNoClick={this.props.onNoClick}
          onYesClick={this.props.onYesClick}
        />
      </Modal>
    );
  }
}

export default DeleteConfirmModal;

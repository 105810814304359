import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { reduxForm } from 'redux-form';

import FormFromtoComponent from '../../molecules/FormFromtoComponent';
import FormInlineRadioComponent from '../../molecules/FormInlineRadioComponentForForm';
import SearchButton from '../../molecules/SearchButton';

class SummarySearchInterviewForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
    };
  }

  render() {
    const getStartDate = () => {
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() + 1);
      return fromDate;
    };

    const getEndDate = () => {
      const toDate = new Date();
      toDate.setDate(toDate.getDate() + 30);
      return toDate;
    };

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <FormFromtoComponent
            label="期間"
            fromId="interviewFrom"
            fromDate={getStartDate()}
            toId="interviewTo"
            toDate={getEndDate()}
          />
          <FormInlineRadioComponent
            label="コース"
            options={[
              { name: 'すべて', value: '0', default: true },
              { name: 'チェックなし', value: '1', default: false },
              { name: '個別', value: '2', default: false },
            ]}
            name="tagType"
            isCentered="true"
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
export default reduxForm({
  // a unique name for the form
  form: 'summarySearchInterviewForm',
})(SummarySearchInterviewForm);

import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import ModalBody from '../../atoms/ModalBody';
import ModalFooter from '../../atoms/ModalFooter';
import ModalCloseButton from '../../molecules/ModalCloseButton';

class DeleteAlertModal extends React.Component {
  static get propTypes() {
    return {
      targetId: PropTypes.string,
      children: PropTypes.string,
      show: PropTypes.bool,
      onYesClick: PropTypes.func,
      onNoClick: PropTypes.func,
    };
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>
          <p className="text-center">
            選択されたロールにはすでにアカウントに紐づくものが含まれています。
          </p>
        </ModalBody>
        <ModalFooter>
          <ModalCloseButton onClick={this.props.onNoClick} />
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeleteAlertModal;

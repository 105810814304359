import Axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { destroy } from 'redux-form';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';
import { trackAsync } from './util';

const axios = createAxios('/admin/staff');

const fetchAreas = () => {
  return axios.get(`/admin/areas`);
};

const fetchAreasSuccess = (result) => {
  return {
    type: 'FETCH_AREAS',
    payload: {
      result: new OptionCollection(
        result.areaList.map((area) => {
          return new Option(area.id, area.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchAreas = () => {
  return (dispatch) => {
    return fetchAreas()
      .then((data) => dispatch(fetchAreasSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchBases = () => {
  return axios.get(`/admin/bases`);
};

const fetchBasesSuccess = (result) => {
  return {
    type: 'FETCH_BASES',
    payload: {
      result: new OptionCollection(
        result.bases.map((base) => {
          return new Option(base.id, base.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchBases = () => {
  return (dispatch) => {
    return fetchBases()
      .then((data) => dispatch(fetchBasesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchOccupations = () => {
  return axios.get(`/occupations`);
};

const fetchOccupationsSuccess = (result) => {
  return {
    type: 'FETCH_OCCUPATIONS',
    payload: {
      result: result.map((base) => {
        return new Option(base.id, base.name);
      }),
    },
  };
};

const makeFetchOccupations = () => {
  return (dispatch) => {
    return fetchOccupations()
      .then((data) => dispatch(fetchOccupationsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchRoles = () => {
  // TODO: パラメータを与えないと値が返ってこないので仮で渡す
  return axios.get(`/admin/roles`, {
    params: {
      pageSize: 99999999,
    },
  });
};

const fetchRolesSuccess = (result) => {
  return {
    type: 'FETCH_ROLES',
    payload: {
      result: new OptionCollection(
        result.roles.map((role) => {
          return new Option(role.id, role.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchRoles = () => {
  return (dispatch) => {
    return fetchRoles()
      .then((data) => dispatch(fetchRolesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchSearchSuccess = (result) => {
  return {
    type: 'staff/FETCH_STAFF_SEARCH',
    payload: {
      result: result,
    },
  };
};

const search = (values, searchParams) => {
  let occupationIds = null;
  if (values.occupationId != null) {
    occupationIds = Object.entries(values.occupationId)
      .filter(([key, value]) => {
        return key != null;
      })
      .map(([key, value]) => {
        console.debug('key', key);
        return value;
      })
      .join(',');
  }

  let isSystemAdmin = null;
  if (values.isSystemAdmin === '1') {
    isSystemAdmin = 0;
  } else if (values.isSystemAdmin === '2') {
    isSystemAdmin = 1;
  }

  let isAccountLock = null;

  if (values.isAccountLock === '1') {
    isAccountLock = 0;
  } else if (values.isAccountLock === '2') {
    isAccountLock = 1;
  }

  let isDisable = null;

  if (values.isDisable === '1') {
    isDisable = 0;
  } else if (values.isDisable === '2') {
    isDisable = 1;
  }

  const params = new Params({
    name: values.name,
    areaId: values.areaId,
    baseId: values.baseId,
    roleId: values.roleId,
    occupationId: occupationIds,
    isSystemAdmin: isSystemAdmin,
    isAccountLock: isAccountLock,
    isDisable: isDisable,
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  });

  console.debug('searchParams: ', params.removeParams());

  return axios('/admin/staffs', {
    params: params.removeParams(),
  });
};

const storeSearchValues = (values, searchParams) => {
  return new Action('staff/STORE_SEARCH_VALUES', {
    values,
    searchParams,
  });
};

const makeSearch = (values, searchParams) => {
  return (dispatch) => {
    dispatch(storeSearchValues(values, searchParams));
    return trackPromise(
      search(values, searchParams)
        .then((data) => dispatch(fetchSearchSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const fetchStaffSuccess = (data) => {
  if (data.interviewPriority) {
    data.interviewPriority.map((interviewPriority) => {
      data[`prefGroup_${interviewPriority.id}`] = interviewPriority.priority;
    });
  }
  return {
    type: 'staff/FETCH_STAFF',
    payload: {
      result: data,
    },
  };
};

const fetchStaff = (id) => {
  return axios.get(`/admin/staffs/${id}`);
};

const showLoading = () => {
  return {
    type: 'SHOW_LOADING',
  };
};

const hideLoading = () => {
  return new EmptyAction('staff/HIDE_LOADING');
};

const makeFetchStaff = (id) => {
  return async (dispatch) => {
    dispatch(showLoading());
    try {
      const [fetchStaffRes, fetchAdminProfileRes] = await Promise.all([
        fetchStaff(id),
        fetchAdminProfile(),
      ]);

      dispatch(fetchStaffSuccess(fetchStaffRes.data));
      dispatch(fetchAdminProfileSuccess(fetchAdminProfileRes.data));

      dispatch(hideLoading());
    } catch (e) {
      fetchErrorData(e);
    }
  };
};

const fetchDivisionsSuccess = (data) => {
  return {
    type: 'FETCH_DIVISIONS',
    payload: {
      result: new OptionCollection(
        data.map((division) => {
          return new Option(division.id, division.name);
        })
      ).getOptions(),
    },
  };
};

const fetchDivisions = () => {
  return axios('/admin/divisions');
};

const makeFetchDivisions = () => {
  return (dispatch) => {
    return fetchDivisions()
      .then((data) => dispatch(fetchDivisionsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchPrefGroupsSuccess = (data) => {
  return {
    type: 'FETCH_PREF_GROUPS',
    payload: {
      result: data,
    },
  };
};

const fetchPrefGroups = () => {
  return axios('/admin/staff/pref_groups');
};

const makeFetchPrefGroups = () => {
  return (dispatch) => {
    return fetchPrefGroups()
      .then((data) => dispatch(fetchPrefGroupsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const putStaff = (id, params) => {
  return axios.put(`/admin/staffs/${id}`, params);
};

const postStaff = (params) => {
  return axios.post(`/admin/staffs`, params);
};

const fetchUpdateStaff = (data) => {
  return {
    type: 'staff/FETCH_UPDATE_STAFF',
    payload: {
      result: data,
    },
  };
};

const fetchInsertStaff = (data) => {
  return {
    type: 'staff/FETCH_INSERT_STAFF',
    payload: {
      result: data,
    },
  };
};

const updateStaffLoading = () => {
  return {
    type: 'staff/UPDATE_STAFF_LOADING',
  };
};

const makeUpdateStaff = (values) => {
  return (dispatch) => {
    dispatch(updateStaffLoading());
    let request;

    const getTel = (tel1, tel2, tel3) => {
      if (tel1 && tel2 && tel3) {
        return `${tel1}-${tel2}-${tel3}`;
      }
      return '';
    };

    const interviewPriorityList = [];
    Object.keys(values).forEach((key) => {
      if (key.startsWith('prefGroup_')) {
        const id = key.split('_')[1];
        const priority =
          values[key] && values[key] !== '' ? parseInt(values[key], 10) : null;
        interviewPriorityList.push({
          id: parseInt(id, 10),
          priority: priority,
        });
      }
    });

    const params = new Params({
      id: values.id,
      familyName: values.familyName,
      firstName: values.firstName,
      code: parseInt(values.code, 10),
      profileTempId: values.profileTempId,
      profileImageId: values.profileImageId,
      mail: values.mail,
      tel: getTel(values.tel1, values.tel2, values.tel3),
      divisionId: values.divisionId,
      occupationId: values.occupationId,
      roleId: values.roleId,
      baseId: values.baseId,
      youtube: values.youtube,
      individualMeetingUrl: values.individualMeetingUrl,
      note: values.note,
      interviewPriority: interviewPriorityList,
    }).removeParams();
    if (values.id == null) {
      request = postStaff(params);
      trackPromise(
        request
          .then((data) => dispatch(fetchInsertStaff(data.data)))
          .catch((err) => dispatch(fetchErrorData(err)))
      );
    } else {
      request = putStaff(values.id, params);
      trackPromise(
        request
          .then((data) => dispatch(fetchUpdateStaff(data.data)))
          .catch((err) => dispatch(fetchErrorData(err)))
      );
    }
  };
};

const fetchRoleSuccess = (data) => {
  return {
    type: 'staff/FETCH_ROLE',
    payload: {
      result: data,
    },
  };
};

const fetchRole = (id) => {
  return axios.get(`/admin/role/${id}`);
};

const makeFetchRole = (id) => {
  return (dispatch) => {
    fetchRole(id)
      .then((data) => dispatch(fetchRoleSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const initFetchStaffEdit = () => {
  return {
    type: 'staff/INIT_FETCH_STAFF_EDIT',
  };
};

const initOptions = () => {
  return {
    type: 'INIT_OPTIONS',
  };
};

const startNewDataEdit = () => {
  return {
    type: 'staff/NEW_DATA_EDIT',
  };
};

const makeFetchStaffEditData = (id) => {
  return (dispatch) => {
    dispatch(initFetchStaffEdit());
    dispatch(initOptions());

    const fetches = [
      fetchBases(),
      fetchOccupations(),
      fetchDivisions(),
      fetchRoles(),
      fetchPrefGroups(),
    ];

    let responder;

    if (id != null) {
      fetches.push(fetchStaff(id));
      responder = (...responses) => {
        const fetchBasesResponse = responses[0];
        dispatch(fetchBasesSuccess(fetchBasesResponse.data));
        const fetchOccupationsResponse = responses[1];
        dispatch(fetchOccupationsSuccess(fetchOccupationsResponse.data));
        const fetchDivisionsResponse = responses[2];
        dispatch(fetchDivisionsSuccess(fetchDivisionsResponse.data));
        const fetchRolesResponse = responses[3];
        dispatch(fetchRolesSuccess(fetchRolesResponse.data));
        const fetchPrefGroupsResponse = responses[4];
        dispatch(fetchPrefGroupsSuccess(fetchPrefGroupsResponse.data));
        const fetchStaffResponse = responses[5];
        dispatch(fetchStaffSuccess(fetchStaffResponse.data));
      };
    } else {
      dispatch(startNewDataEdit());
      responder = (...responses) => {
        const fetchBasesResponse = responses[0];
        dispatch(fetchBasesSuccess(fetchBasesResponse.data));
        const fetchOccupationsResponse = responses[1];
        dispatch(fetchOccupationsSuccess(fetchOccupationsResponse.data));
        const fetchDivisionsResponse = responses[2];
        dispatch(fetchDivisionsSuccess(fetchDivisionsResponse.data));
        const fetchRolesResponse = responses[3];
        dispatch(fetchRolesSuccess(fetchRolesResponse.data));
        const fetchPrefGroupsResponse = responses[4];
        dispatch(fetchPrefGroupsSuccess(fetchPrefGroupsResponse.data));
      };
    }

    Axios.all(fetches)
      .then(Axios.spread(responder))
      .catch((err) => {
        dispatch(fetchErrorData(err));
      });
  };
};

const setEditValues = (values) => {
  return {
    type: 'staff/SET_EDIT_VALUES',
    payload: {
      values: values,
    },
  };
};

const backToInput = () => {
  return {
    type: 'staff/BACK_TO_INPUT',
  };
};

const fetchBulkUpdateHistories = (pagination) => {
  console.debug('fetchBulkUpdateHistories', pagination);
  const params = new Params({
    pageSize: pagination.pagination.pageSize,
    page: pagination.pagination.page,
    sortColumn: pagination.sort.sortColumn,
    sortType: pagination.sort.sortType,
  });

  return axios.get('/admin/staff/bulk_updates', {
    params: params.removeParams(),
  });
};

const fetchBulkUpdateHistoriesSuccess = (data) => {
  console.debug('fetchBulkUpdateHistoriesSuccess', data);
  return {
    type: 'staff/FETCH_BULK_UPDATE_HISTORIES_SUCCESS',
    payload: {
      values: data,
    },
  };
};

const makeFetchBulkUpdateHistories = (pagination) => {
  return (dispatch) => {
    return fetchBulkUpdateHistories(pagination)
      .then((data) => dispatch(fetchBulkUpdateHistoriesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const uploadBulkUpdateFile = (file) => {
  const params = new FormData();
  params.append('file', file);

  return axios.post(`/admin/staff/bulk_updates/file_upload`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const uploadBulkUpdateFileSuccess = (data) => {
  return {
    type: 'staff/UPLOAD_BULK_UPDATE_FILE_SUCCESS',
    payload: data,
  };
};

const makeUploadBulkUpdateFile = (file) => {
  return (dispatch) => {
    return trackPromise(
      uploadBulkUpdateFile(file)
        .then((data) => dispatch(uploadBulkUpdateFileSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const bulkUpdateExec = (tempFileId, originalFileName) => {
  return axios.post(`/admin/staff/bulk_updates`, {
    tempFileId: tempFileId,
    originalFileName: originalFileName,
  });
};

const bulkUpdateExecSuccess = (data) => {
  return {
    type: 'staff/BULK_UPDATE_EXEC_SUCCESS',
  };
};

const bulkUpdateExecFailure = (err) => {
  if (err.response) {
    if (err.response.status === 400) {
      return new Action('staff/BULK_UPDATE_EXEC_FAILURE', err.response.data);
    }
  }

  return fetchErrorData(err);
};

const makeBulkUpdateExec = (tempFileId, originalFileName) => {
  return (dispatch) => {
    trackAsync(async () => {
      try {
        const bulkUpdateExecRes = await bulkUpdateExec(
          tempFileId,
          originalFileName
        );

        dispatch(bulkUpdateExecSuccess(bulkUpdateExecRes.data));
      } catch (err) {
        dispatch(bulkUpdateExecFailure(err));
      }
    });
  };
};

const hideExecutedDialog = () => {
  return {
    type: 'staff/HIDE_EXECUTED_DIALOG',
  };
};

const downloadBulkUpdateResultFile = (id) => {
  return axios.get(`/admin/staff/bulk_update/${id}/download`, {
    responseType: 'arraybuffer',
  });
};

const downloadBulkUpdateResultFileSuccess = (data) => {
  console.debug('downloadBulkUpdateResultFile', data);
  const contentDisposition = data.headers['content-disposition'];
  const fileName = getFileName(contentDisposition);
  return {
    type: 'staff/DOWNLOAD_BULK_UPDATE_RESULT_FILE_SUCCESS',
    payload: { data: data.data, fileName: fileName },
  };
};

function getFileName(contentDisposition) {
  const pattern = /filename="(.*)"/;
  const matches = pattern.exec(contentDisposition);

  return matches[1];
}

const makeDownloadBulkUpdateResultFile = (id) => {
  return (dispatch) => {
    return trackPromise(
      downloadBulkUpdateResultFile(id)
        .then((data) => dispatch(downloadBulkUpdateResultFileSuccess(data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const disableStaffs = (ids) => {
  return axios.post('/admin/staffs/disable', {
    ids: ids,
  });
};

const disableStaffsSuccess = (data) => {
  return {
    type: 'staff/DISABLRE_STAFF_SUCCESS',
  };
};

const makeDisableStaffs = (ids, searchValues) => {
  return (dispatch) => {
    trackAsync(async () => {
      try {
        const disableRes = await disableStaffs(ids);
        dispatch(disableStaffsSuccess(disableRes.data));

        if (searchValues && Object.keys(searchValues).length > 0) {
          const searchRes = await search(
            searchValues.values,
            searchValues.searchParams
          );

          dispatch(fetchSearchSuccess(searchRes.data));
        }
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

const hideDisableSuccessModal = () => {
  return {
    type: 'staff/HIDE_DISABLE_SUCCESS_MODAL',
  };
};

const unlockStaff = (id) => {
  return axios.post(`/admin/staff/unlock/${id}`, {});
};

const unlockStaffSuccess = (data) => {
  return {
    type: 'staff/UNLOCK_STAFF_SUCCESS',
  };
};

const makeUnlockStaff = (id) => {
  return (dispatch) => {
    return trackPromise(
      unlockStaff(id)
        .then((data) => dispatch(unlockStaffSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const reSendActivationMail = (id) => {
  return axios.post(`/admin/staff/send_activation_mail/${id}`);
};

const reSendActivationMailSuccess = () => {
  return new EmptyAction('staff/RE_SEND_ACTIVATION_MAIL_SUCCESS');
};

const reSendActivationMailFailure = (err) => {
  if (err.response && err.response.status === 400) {
    return new Action(
      'staff/RE_SEND_ACTIVATION_MAIL_FAILURE',
      err.response.data
    );
  }

  return fetchErrorData(err);
};

const makeReSendActivationMail = (id) => {
  return async (dispatch) => {
    trackAsync(async () => {
      try {
        const sendRes = await reSendActivationMail(id);
        dispatch(reSendActivationMailSuccess(sendRes.data));
      } catch (err) {
        dispatch(reSendActivationMailFailure(err));
      }
    });
  };
};

const hideReSendMailCompleteModal = () => {
  return new EmptyAction('staff/HIDE_RE_SEND_MAIL_COMPLETE');
};

const hideReSendFailureDialog = () => {
  return new EmptyAction('staff/HIDE_RE_SEND_FAILURE_DIALOG');
};

const hideUnlockSuccessModal = () => {
  return {
    type: 'staff/HIDE_UNLOCK_SUCCESS_MODAL',
  };
};

const fetchAdminProfile = () => {
  return axios.get('/admin/profile');
};

const fetchAdminProfileSuccess = (data) => {
  return new Action('staff/FETCH_ADMIN_PROFILE_SUCCESS', data);
};

const makeFetchAdminProfile = () => {
  return async (dispatch) => {
    try {
      const adminProfileRes = await fetchAdminProfile();
      dispatch(fetchAdminProfileSuccess(adminProfileRes.data));
    } catch (e) {
      dispatch(fetchErrorData(e));
    }
  };
};

const fetchBulkUpdateStatus = () => {
  return axios.get('/admin/staff/bulk_updates/status');
};

const fetchBulkUpdateStatusSuccess = (data) => {
  return new Action('staff/FETCH_BULK_UPDATE_STATUS_SUCCESS', data);
};

const makeFetchBulkUpdateStatus = () => {
  const fetchFunction = async (dispatch) => {
    try {
      const bulkUpdateStatusRes = await fetchBulkUpdateStatus();
      dispatch(fetchBulkUpdateStatusSuccess(bulkUpdateStatusRes.data));
    } catch (e) {
      dispatch(fetchErrorData(e));
    }
  };

  return fetchFunction;
};

const hideBulkUpdateFailureDialog = () => {
  return new EmptyAction('staff/HIDE_BULK_UPDATE_FAILURE_DIALOG');
};

const initializeStaffBulk = (searchValues) => {
  return async (dispatch) => {
    dispatch(new EmptyAction('staff/INITIALIZE_STAFF_BULK'));

    const [
      bulkUpdateHistoriesRes,
      fetchBulkUpdateStatusRes,
    ] = await Promise.all([
      fetchBulkUpdateHistories(searchValues),
      fetchBulkUpdateStatus(),
    ]);

    dispatch(fetchBulkUpdateHistoriesSuccess(bulkUpdateHistoriesRes.data));
    dispatch(fetchBulkUpdateStatusSuccess(fetchBulkUpdateStatusRes.data));
    dispatch(hideLoading());
  };
};

const initReferState = () => {
  return new EmptyAction('staff/INIT_REFER_STATE');
};

const initState = () => {
  return {
    type: 'staff/INIT_STATE',
  };
};

const makeInitFetchSearchPage = (isComeFromOtherPage) => {
  return async (dispatch) => {
    try {
      if (isComeFromOtherPage) {
        dispatch(initState());
        dispatch(destroy('staffSearchForm'));
      }
      dispatch(showLoading());

      const jobs = [
        fetchAreas(),
        fetchBases(),
        fetchOccupations(),
        fetchRoles(),
        fetchAdminProfile(),
      ];

      const [
        areasRes,
        basesRes,
        occupationsRes,
        rolesRes,
        profileRes,
      ] = await Promise.all(jobs);

      dispatch(fetchAreasSuccess(areasRes.data));
      dispatch(fetchBasesSuccess(basesRes.data));
      dispatch(fetchOccupationsSuccess(occupationsRes.data));
      dispatch(fetchRolesSuccess(rolesRes.data));
      dispatch(fetchAdminProfileSuccess(profileRes.data));

      dispatch(hideLoading());
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

export {
  makeFetchAreas,
  makeFetchBases,
  makeFetchOccupations,
  makeFetchRoles,
  makeSearch,
  makeFetchStaff,
  makeFetchDivisions,
  makeFetchPrefGroups,
  makeUpdateStaff,
  makeFetchRole,
  makeFetchStaffEditData,
  makeFetchBulkUpdateHistories,
  makeUploadBulkUpdateFile,
  makeInitFetchSearchPage,
  setEditValues,
  backToInput,
  makeBulkUpdateExec,
  hideExecutedDialog,
  makeDownloadBulkUpdateResultFile,
  makeDisableStaffs,
  hideDisableSuccessModal,
  makeUnlockStaff,
  hideUnlockSuccessModal,
  makeReSendActivationMail,
  hideReSendMailCompleteModal,
  hideReSendFailureDialog,
  makeFetchAdminProfile,
  makeFetchBulkUpdateStatus,
  hideBulkUpdateFailureDialog,
  initializeStaffBulk,
  initState,
  initReferState,
};

import { UpdatePasswordEdit } from '../../../containers/SettingPassword';

import routePath from './routePath';

const SettingPasswordRoutes = [
  {
    path: routePath.edit,
    exact: true,
    component: UpdatePasswordEdit,
  },
];

export default SettingPasswordRoutes;

import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import ModalBody from '../../atoms/ModalBody';
import ModalFotterYesNoButtonComponent from '../../molecules/ModalFotterYesNoButtonComponent';

class BackConfirmModal extends React.Component {
  static get propTypes() {
    return {
      show: PropTypes.bool,
      onClickYes: PropTypes.func,
      onClickNo: PropTypes.func,
    };
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>
          <p className="text-center">
            予約の変更は完了していません。 <br />
            このページから離れますか？
          </p>
        </ModalBody>
        <ModalFotterYesNoButtonComponent
          onYesClick={this.props.onClickYes}
          onNoClick={this.props.onClickNo}
        />
      </Modal>
    );
  }
}

export default BackConfirmModal;

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Option from '../../model/Option';
import DeleteButton from '../../molecules/DeleteButton';
import DeleteConfirmModal from '../../molecules/DeleteConfirmModal';
import DetailButton from '../../molecules/DetailButton';
import LabelErrorComponent from '../../molecules/LabelErrorComponent';
import NewButton from '../../molecules/NewButton';
import Form from '../../organisms/base/BaseSearchForm';
import ContainsUsedBaseAlertModal from '../../organisms/base/ContainsUsedBaseAlertModal';
import Card from '../../organisms/Card';
import DeleteCompleteModal from '../../organisms/DeleteCompleteModal';
import Pager from '../../organisms/Pager';
import SearchResultTable from '../../organisms/SearchResultTable';
import SearchComponent from '../common/SearchComponent';

import routePath from './routePath';

const MarginBottomRow = styled(Row)`
  margin-bottom: 1em;
`;

class BaseSearch extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        searchParams: PropTypes.object,
        searchValues: PropTypes.object,
        searchResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          bases: PropTypes.array,
          isComplete: PropTypes.bool,
        }),
        areas: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      baseDelete: {
        isSubmitting: PropTypes.bool,
        isConfirm: PropTypes.bool,
        isComplete: PropTypes.bool,
        errMessage: PropTypes.string,
      },
      search: PropTypes.func,
      showDeleteModal: PropTypes.bool,
      fetchArea: PropTypes.func,
      deleteBase: PropTypes.func,
      errorDelete: PropTypes.func,
      showDeleteConfirm: PropTypes.func,
      closeDeleteConfirm: PropTypes.func,
      closeDeleteComplete: PropTypes.func,
      hideContainsAlertModal: PropTypes.func,
      confirmIsUsed: PropTypes.func,
      newBase: PropTypes.any,
    };
  }

  constructor(props) {
    super(props);
    this.state = { values: null, deleteSelections: [] };

    this.searchComponent = new SearchComponent();

    this.clickSearchButton = this.clickSearchButton.bind(this);
    this.getSearchItem = this.getSearchItem.bind(this);

    this.search = this.search.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
    this.isEnableDeleteButton = this.isEnableDeleteButton.bind(this);
  }

  clickSearchButton(values) {
    this.setState({ values: values });

    const prevSearchParams = this.props.state.searchParams;
    let pageSize = this.searchComponent.pageDefaultSize;
    if (
      prevSearchParams &&
      prevSearchParams.pagination &&
      prevSearchParams.pagination.pageSize
    ) {
      pageSize = prevSearchParams.pagination.pageSize;
    }

    this.search(
      {
        sort: {
          sortColumn: 'id',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      values
    );
  }

  componentDidMount() {
    this.props.fetchArea();
  }

  selectionDeleteBase(id) {
    const currentSelections = this.state.deleteSelections;

    let newSelections;
    if (currentSelections.indexOf(id) !== -1) {
      newSelections = currentSelections.filter((selectionId) => {
        return selectionId !== id;
      });
    } else {
      newSelections = currentSelections.concat([id]);
    }

    this.setState({ deleteSelections: newSelections });
  }

  // 削除ボタン
  deleteButtonClick() {
    // チェック
    if (this.state.deleteSelections.length === 0) {
      this.props.errorDelete();
    }

    this.props.confirmIsUsed(this.state.deleteSelections);
  }

  onYesClick() {
    this.props.deleteBase(this.state.deleteSelections);
  }

  onNoClick() {
    this.props.closeDeleteConfirm();
  }

  onCloseClick() {
    this.setState({ deleteSelections: [] }, () => {
      this.clickSearchButton(this.state.values || {});
      this.props.closeDeleteComplete();
    });
  }

  onNewButtonClick() {
    this.props.newBase();
    this.props.history.push(routePath.newEdit);
  }

  getSearchItem() {
    const bases = this.props.state.searchResults.bases;

    const transitionRefer = (id) => {
      this.props.history.push(routePath.refer(id));
    };

    const items = bases.map((base, index) => {
      let buildingName = '';

      if (base.buildingName) {
        buildingName = base.buildingName;
      }
      return (
        <tr key={base.id}>
          <th scope="row">
            <input
              className="form-check-input mx-auto"
              type="checkbox"
              onChange={this.selectionDeleteBase.bind(this, base.id)}
            />
          </th>
          <td>
            <DetailButton onClick={transitionRefer.bind(this, base.id)} />
          </td>
          <td>{base.name}</td>
          <td>{base.areaName}</td>
          <td>{base.pref + base.city + buildingName}</td>
          <td>
            <a
              href={base.googleMapUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {base.googleMapUrl}
            </a>
          </td>
        </tr>
      );
    });
    return items;
  }

  search(
    searchParams = this.props.state.searchParams,
    values = this.state.values || this.props.state.searchValues
  ) {
    this.props.search(values, searchParams);
  }

  isEnableDeleteButton() {
    return this.state.deleteSelections.length > 0;
  }

  onAlertModalCloseButtonClick() {
    this.props.hideContainsAlertModal();
  }

  render() {
    const errorShow = () => {
      if (this.props.baseDelete.errMessage != null) {
        return (
          <LabelErrorComponent
            directly={this.props.baseDelete.errMessage}
          ></LabelErrorComponent>
        );
      }
      return '';
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>拠点管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="拠点管理検索">
                  <div className="section-title">検索項目</div>
                  {errorShow()}
                  <Form onSubmit={this.clickSearchButton} />
                  <div className="text-left">
                    <Row>
                      <Col>
                        <MarginBottomRow>
                          <Col>
                            <NewButton
                              onClick={this.onNewButtonClick.bind(this)}
                            />
                          </Col>
                        </MarginBottomRow>
                      </Col>
                    </Row>

                    {this.props.state.searchResults.bases.length !== 0 ? (
                      <>
                        <hr />

                        <Pager
                          searchParams={
                            this.props.state.searchResults.searchParams
                          }
                          search={this.search}
                        />

                        <SearchResultTable
                          headerItems={[
                            {},
                            {},
                            { name: 'base.name', value: '名前' },
                            { name: 'area.name', value: 'エリア' },
                            { name: 'base.pref_id', value: '住所' },
                            { name: 'base.google_map_url', value: '地図' },
                          ]}
                          searchResults={this.props.state.searchResults}
                          getSearchItem={this.getSearchItem}
                          search={this.search}
                        />
                        <Row>
                          <Col>
                            <DeleteButton
                              onClick={this.deleteButtonClick.bind(this)}
                              isEnable={this.isEnableDeleteButton}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <ContainsUsedBaseAlertModal
          show={this.props.baseDelete.isDisplayUsedContainsAlertDialog}
          onCloseButtonClick={this.onAlertModalCloseButtonClick.bind(this)}
        />

        <DeleteConfirmModal
          show={this.props.baseDelete.isConfirm}
          // show={true}
          onYesClick={this.onYesClick.bind(this)}
          onNoClick={this.onNoClick.bind(this)}
        >
          選択された拠点を削除してよろしいですか？
        </DeleteConfirmModal>

        <DeleteCompleteModal
          show={this.props.baseDelete.isComplete}
          onCloseClick={this.onCloseClick.bind(this)}
        />
      </>
    );
  }
}

export default connect((state) => {
  return {
    ...state,
    baseDelete: {
      ...state.base.baseDelete,
      isConfirm: state.base.baseDelete.isConfirm,
      isComplete: state.base.baseDelete.isComplete,
      errMessage: state.base.baseDelete.errMessage,
    },
  };
})(BaseSearch);

import {
  AdminPasswordResetMailInput,
  AdminPasswordResetMailComplete,
  AdminPasswordResetComplete,
  AdminPasswordResetInput,
} from '../../../containers/AdminPasswordReset';

const AdminPasswordResetRoutes = [
  {
    path: '/admin/password_reset/mail/input',
    component: AdminPasswordResetMailInput,
  },
  {
    path: '/admin/password_reset/mail/complete',
    component: AdminPasswordResetMailComplete,
  },
  {
    path: '/admin/password_reset/reset',
    component: AdminPasswordResetInput,
  },
  {
    path: '/admin/password_reset/complete',
    component: AdminPasswordResetComplete,
  },
];

export default AdminPasswordResetRoutes;

import {
  AdminProfileRefer,
  AdminProfileEdit,
} from '../../../containers/AdminProfile';

import routePath from './routePath';

const AdminProfileRoutes = [
  {
    path: routePath.refer,
    component: AdminProfileRefer,
  },
  {
    path: routePath.edit,
    component: AdminProfileEdit,
  },
];

export default AdminProfileRoutes;

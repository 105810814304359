import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SalesForceColorButton = styled.button`
  border-color: rgb(12, 154, 214);
  background-color: rgb(12, 154, 214);
  color: #fff;
  &:hover {
    border-color: rgb(10, 123, 168) !important;
    background-color: rgb(10, 123, 168) !important;
    color: #fff;
  }
  &:active {
    border-color: rgb(10, 123, 168) !important;
    background-color: rgb(10, 123, 168) !important;
    color: #fff;
  }
  &:focus {
    border-color: rgb(10, 123, 168) !important;
    background-color: rgb(10, 123, 168) !important;
    color: #fff;
  }
`;
const SalesForceButton = (props) => {
  const { onClick } = props;
  return (
    <SalesForceColorButton
      className={'btn btn-icon icon-left mr-1'}
      onClick={onClick}
    >
      <i className="fas fa-cloud" />
      SalesForce
    </SalesForceColorButton>
  );
};

SalesForceButton.propTypes = {
  onClick: PropTypes.func,
};

export default SalesForceButton;

import axios from 'axios';

export default function (xRefererName) {
  const instance = axios.create();
  instance.defaults.headers.common['X-Referer'] = xRefererName;
  if (process.env.REACT_APP_ENV === 'stg') {
    instance.defaults.headers.common.Authorization =
      process.env.REACT_APP_BASIC_AUTH;
  }
  instance.defaults.baseURL = process.env.REACT_APP_API_URI;
  instance.defaults.withCredentials = true;
  return instance;
}

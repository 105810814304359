import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';

class WysiwygEditor extends React.Component {
  static get propTypes() {
    return {
      onChange: PropTypes.func,
      initialText: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    const text = props.initialText || '';
    const blocksFromHtml = htmlToDraft(text);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);

    this.onEditorStateChange = this.onEditorStateChange.bind(this);

    this.state = { editorState: editorState };
  }

  /**
   *
   * @param {EditorState} editorState
   */
  onEditorStateChange(editorState) {
    const htmlBody = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    this.props.onChange(htmlBody);
    this.setState({ editorState: editorState });
  }

  render() {
    return (
      <Editor
        editorState={this.state.editorState || {}}
        toolbarClassName="toolbar"
        wrapperClassName="wrapper"
        editorClassName="editor border"
        onEditorStateChange={this.onEditorStateChange}
        localization={{
          locale: 'ja',
        }}
      />
    );
  }
}

export default WysiwygEditor;

import PropTypes from 'prop-types';
import React from 'react';

class InlineFormRadio extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.string,
      label: PropTypes.string,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    console.debug(`InlineFormRadio`, this.props);
  }

  render() {
    return (
      <div className="form-check form-check-inline">
        <input
          type="radio"
          className="form-check-input"
          name={this.props.name}
          checked={this.props.checked}
          id={this.props.id}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <label className="form-check-label" htmlFor={this.props.id}>
          {this.props.label}
        </label>
      </div>
    );
  }
}

export default InlineFormRadio;

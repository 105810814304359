import { connect } from 'react-redux';

import {
  makeSendAdminPasswordResetMail,
  makeSendAdminPasswordReset,
  initState,
} from '../actions/AdminPasswordReset';
import AdminPasswordResetComponent from '../components/pages/adminPasswordReset/AdminPasswordReset';
import AdminPasswordResetCompleteComponent from '../components/pages/adminPasswordReset/AdminPasswordResetComplete';
import AdminPasswordResetInputComponent from '../components/pages/adminPasswordReset/AdminPasswordResetInput';
import AdminPasswordResetMailCompleteComponent from '../components/pages/adminPasswordReset/AdminPasswordResetMailComplete';
import AdminPasswordResetMailInputComponent from '../components/pages/adminPasswordReset/AdminPasswordResetMailInput';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.adminPasswordReset,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    makeSendAdminPasswordResetMail(values) {
      dispatch(makeSendAdminPasswordResetMail(values));
    },
    makeSendAdminPasswordReset(values) {
      dispatch(makeSendAdminPasswordReset(values));
    },
    initState() {
      dispatch(initState());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPasswordResetComponent);

const AdminPasswordResetMailInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPasswordResetMailInputComponent);

export { AdminPasswordResetMailInput };

const AdminPasswordResetMailComplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPasswordResetMailCompleteComponent);

export { AdminPasswordResetMailComplete };

const AdminPasswordResetComplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPasswordResetCompleteComponent);

export { AdminPasswordResetComplete };

const AdminPasswordResetInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPasswordResetInputComponent);

export { AdminPasswordResetInput };

import { connect } from 'react-redux';

import { makeFetchUpdatePassword, initState } from '../actions/SettingPassword';
import UpdatePasswordComponent from '../components/pages/settingPassword/SettingPassword';
import UpdatePasswordEditComponent from '../components/pages/settingPassword/SettingPasswordEdit';

const mapStateToProps = (state) => {
  return {
    state: state.updatePassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUpdatePassword(values, id) {
      dispatch(makeFetchUpdatePassword(values, id));
    },
    initState() {
      dispatch(initState());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePasswordComponent);

const UpdatePasswordEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePasswordEditComponent);

export { UpdatePasswordEdit };

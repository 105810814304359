import PropTypes from 'prop-types';
import React from 'react';

class Loading extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      isLoading: PropTypes.bool,
    };
  }

  render() {
    if (this.props.isLoading) {
      return <></>;
    }

    return this.props.children;
  }
}

export default Loading;

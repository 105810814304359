import React from 'react';

import LightButton from '../../atoms/LightButton';

class SelectedSelfButton extends React.Component {
  render() {
    return <LightButton {...this.props}>自分担当</LightButton>;
  }
}

export default SelectedSelfButton;

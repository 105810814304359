import PropTypes from 'prop-types';
import React from 'react';
import { Switch } from 'react-router-dom';

import RouteWithSubRouters from '../../RouterUtil';
import { ActionCreatorProvider } from '../../utils/PermissionComponent';

class Events extends React.Component {
  static get propTypes() {
    return {
      routes: PropTypes.any,
    };
  }

  render() {
    return (
      <ActionCreatorProvider menu={'/admin/events'}>
        <Switch>
          {this.props.routes.map((route, i) => (
            <RouteWithSubRouters key={i} {...route} />
          ))}
        </Switch>
      </ActionCreatorProvider>
    );
  }
}

export default Events;

import PropTypes from 'prop-types';
import React from 'react';

class FormRadio extends React.Component {
  static get propTypes() {
    return {
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      value: PropTypes.string,
      isChecked: PropTypes.bool,
      onChange: PropTypes.any,
    };
  }

  addChecked(isChecked) {
    return isChecked ? 'checked' : '';
  }

  render() {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name={this.props.name}
          id={this.props.id}
          value={this.props.value}
          checked={this.props.isChecked}
          onChange={this.props.onChange}
        ></input>
        <label className="form-check-label" htmlFor={this.props.id}>
          {this.props.label}
        </label>
      </div>
    );
  }
}

export default FormRadio;

import PropTypes from 'prop-types';
import React from 'react';

class SearchDetailModalAccordion extends React.Component {
  static get propTypes() {
    return {
      accordionTitle: PropTypes.string,
      list: PropTypes.array,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      isShowUserList: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.state.isShowUserList) {
      this.setState({ isShowUserList: false });
    } else {
      this.setState({ isShowUserList: true });
    }
  }

  render() {
    return (
      <>
        <div onClick={this.onClick} style={{ paddingBottom: '0.3em' }}>
          {this.props.accordionTitle}
          <span style={{ paddingLeft: '1em', fontSize: '12px' }}>
            {this.state.isShowUserList ? '▲' : '▼'}
          </span>
        </div>
        {this.state.isShowUserList ? (
          <>
            {this.props.list.map((single, i) => {
              return (
                <div key={i}>
                  <div>{single}</div>
                </div>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default SearchDetailModalAccordion;

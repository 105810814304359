import { trackPromise } from 'react-promise-tracker';
import { change } from 'redux-form';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';
import { trackAsync } from './util';

const axios = createAxios('/admin/role');

const showLoading = () => {
  return {
    type: 'role/SHOW_LOADING',
  };
};

const makeFetchMenus = () => {
  return (dispatch) => {
    return fetchMenus()
      .then((data) => dispatch(fetchMenusSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchMenus = () => {
  return axios.get(`/admin/menus`);
};

const fetchMenusSuccess = (result) => {
  return {
    type: 'role/FETCH_MENUS',
    payload: {
      result: result,
    },
  };
};

const storeSearchValues = (values, searchParams) => {
  return new Action('role/STORE_SEARCH_VALUES', {
    values: values,
    searchParams: searchParams,
  });
};

const makeSearch = (values, searchParams) => {
  return (dispatch) => {
    dispatch(storeSearchValues(values, searchParams));

    return trackPromise(
      search(values, searchParams)
        .then((data) => dispatch(fetchSearchSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const search = (values, searchParams) => {
  console.debug('values: ', values);

  let isSystemAdmin = null;
  if (values.isSystemAdmin && values.isSystemAdmin === '1') {
    isSystemAdmin = 0;
  } else if (values.isSystemAdmin && values.isSystemAdmin === '2') {
    isSystemAdmin = 1;
  }

  const params = new Params({
    name: values.name,
    isSystemAdmin: isSystemAdmin,
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  });

  console.debug('searchParams: ', params.removeParams());

  return axios('/admin/roles', {
    params: params.removeParams(),
  });
};

const fetchSearchSuccess = (result) => {
  return {
    type: 'role/FETCH_ROLE_SEARCH',
    payload: {
      result: result,
    },
  };
};

const finishLoading = () => {
  return {
    type: 'role/FINISH_LOADING',
  };
};

const makeFetchRole = (id) => {
  return (dispatch) => {
    dispatch(showLoading());
    return fetchRole(id)
      .then((data) => {
        dispatch(fetchRoleSuccess(data.data));
        dispatch(finishLoading());
      })
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchRole = (id) => {
  return axios.get(`/admin/role/${id}`);
};

const fetchRoleSuccess = (data) => {
  return {
    type: 'role/FETCH_ROLE',
    payload: {
      result: data,
    },
  };
};

const makeFetchRoles = (ids) => {
  return (dispatch) => {
    trackAsync(async () => {
      dispatch(showLoading());
      try {
        const res = await fetchRoles(ids);
        const roleDetail = res.data;
        if (
          roleDetail.length > 0 &&
          roleDetail.some((role) => role.isUsedStaff)
        ) {
          dispatch(fetchRolesSuccess(res.data));
          dispatch(finishLoading());
        }
        const deleteRes = await deleteRole(ids);
        return dispatch(deleteRoleSuccess(deleteRes.data));
      } catch (err) {
        return dispatch(fetchErrorData(err));
      }
    });
  };
};

const fetchRoles = async (ids) => {
  let idList = '';
  ids.map((id, i) => {
    if (i !== 0) {
      idList = idList + ',';
    }
    idList = idList + id;
  });

  const params = new Params({
    ids: idList,
  });

  return axios.get('/admin/roles/ids', {
    params: params.removeParams(),
  });
};

const fetchRolesSuccess = (data) => {
  return {
    type: 'role/FETCH_ROLES',
    payload: {
      result: data,
    },
  };
};

const updateRoleLoading = () => {
  return {
    type: 'role/UPDATE_ROLE_LOADING',
  };
};

const makeUpdateRole = (values) => {
  return (dispatch) => {
    dispatch(updateRoleLoading());
    let request;

    const params = createUpdateParam(values);
    if (values.id == null) {
      request = postRole(params);
    } else {
      request = putRole(values.id, params);
    }

    trackPromise(
      request
        .then((data) => dispatch(fetchUpdateRole(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const postRole = (params) => {
  return axios.post(`/admin/roles`, params.removeParams());
};

const createUpdateParam = (values) => {
  const roles = Object.entries(values)
    .filter(([key, value]) => {
      return key.includes('RolePermissionId-');
    })
    .map(([key, value]) => {
      const menuId = key.replace('RolePermissionId-', '');
      return {
        menuId: menuId,
        permissionId: value,
      };
    });

  const params = new Params({
    name: values.name,
    initMenuId: values.initMenu,
    isSystemAdmin: values.isSystemAdmin === '1',
    roles: roles,
  });
  return params;
};

const putRole = (id, params) => {
  return axios.put(`/admin/role/${id}`, params.removeParams());
};

const fetchUpdateRole = (data) => {
  return {
    type: 'role/FETCH_UPDATE_ROLE',
    payload: {
      result: data,
    },
  };
};

const makeDeleteRole = (ids) => {
  return (dispatch) => {
    return deleteRole(ids)
      .then((data) => dispatch(deleteRoleSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const deleteRole = async (ids) => {
  return Promise.all(ids.map((id) => axios.delete(`/admin/role/${id}`)));
};

const deleteRoleSuccess = (data) => {
  return {
    type: 'role/FETCH_DELETE_ROLE',
    payload: {
      result: data,
    },
  };
};

const makeCopyRole = (id) => {
  return (dispatch) => {
    return trackPromise(
      copyeRole(id)
        .then((data) => dispatch(copyRoleSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const fetchRolePermission = () => {
  return axios.get('/role_permissions');
};

const fetchRolePermissionSuccess = (data) => {
  return {
    type: 'option/FETCH_ROLE_PERMISSIONS',
    payload: {
      result: new OptionCollection(
        data.map(
          (rolePermission) => new Option(rolePermission.id, rolePermission.name)
        )
      ).getOptionsWithoutEmpty(),
    },
  };
};

const makeFetchRolePermission = () => {
  return (dispatch) => {
    return fetchRolePermission()
      .then((res) => dispatch(fetchRolePermissionSuccess(res.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const copyeRole = (id) => {
  const params = new Params({});

  return axios.post(`/admin/role/${id}/copy`, params.removeParams());
};

const copyRoleSuccess = (data) => {
  return {
    type: 'role/FETCH_COPY_ROLE',
    payload: {
      result: data,
    },
  };
};

const newRole = () => {
  return {
    type: 'role/NEW_ROLE',
  };
};

const setEditValues = (values) => {
  return {
    type: 'role/SET_EDIT_VALUES',
    payload: {
      result: values,
    },
  };
};

const showDeleteConfirm = (ids) => {
  return {
    type: 'role/SHOW_DELETE_CONFIRM',
    payload: {
      result: ids.length,
    },
  };
};
const closeDeleteConfirm = () => {
  return {
    type: 'role/CLOSE_DELETE_CONFIRM',
  };
};
const showDeleteAlert = () => {
  return {
    type: 'role/SHOW_DELETE_ALERT',
  };
};
const closeDeleteAlert = () => {
  return {
    type: 'role/CLOSE_DELETE_ALERT',
  };
};
const closeDeleteComplete = () => {
  return {
    type: 'role/CLOSE_DELETE_COMPLETE',
  };
};

const showCopyConfirm = () => {
  return {
    type: 'role/SHOW_COPY_CONFIRM',
  };
};
const closeCopyConfirm = () => {
  return {
    type: 'role/CLOSE_COPY_CONFIRM',
  };
};
const closeCopyComplete = () => {
  return {
    type: 'role/CLOSE_COPY_COMPLETE',
  };
};
const deleteErr = () => {
  return {
    type: 'role/DELETE_ERROR',
  };
};
const initState = () => {
  return {
    type: 'role/INIT_STATE',
  };
};

const fetchEditInitDataSuccess = () => {
  return {
    type: 'role/FETCH_EDIT_INIT_DATA_SUCCESS',
  };
};

const initNewEditState = () => {
  return new EmptyAction('role/INIT_NEW_EDIT_STATE');
};

const hideLoading = () => {
  return new EmptyAction('role/HIDE_LOADING');
};

const makeFetchRoleForRefer = (id) => {
  return async (dispatch) => {
    try {
      dispatch(showLoading());

      const [roleRes, menuRes] = await Promise.all([
        fetchRole(id),
        fetchMenus(),
      ]);

      dispatch(fetchRoleSuccess(roleRes.data));
      dispatch(fetchMenusSuccess(menuRes.data));

      dispatch(hideLoading());
    } catch (e) {
      fetchErrorData(e);
    }
  };
};

const makeFetchEditInitData = (id) => {
  return async (dispatch) => {
    try {
      dispatch(showLoading());
      const res = await fetchRolePermission();
      dispatch(fetchRolePermissionSuccess(res.data));

      const fetchMenuRes = await fetchMenus();
      dispatch(fetchMenusSuccess(fetchMenuRes.data));

      if (id) {
        const fetchRoleRes = await fetchRole(id);
        dispatch(fetchRoleSuccess(fetchRoleRes.data));
      } else {
        dispatch(initNewEditState());
      }

      dispatch(fetchEditInitDataSuccess());
      dispatch(finishLoading());
    } catch (err) {
      return fetchErrorData(err);
    }
  };
};

const clearInitMenuFormValue = () => {
  return change('roleManagementEditForm', 'initMenu', '');
};

export {
  makeFetchMenus,
  makeSearch,
  makeFetchRole,
  makeFetchRoles,
  makeUpdateRole,
  makeDeleteRole,
  makeCopyRole,
  makeFetchRolePermission,
  makeFetchEditInitData,
  setEditValues,
  newRole,
  showDeleteConfirm,
  closeDeleteConfirm,
  showDeleteAlert,
  closeDeleteAlert,
  closeDeleteComplete,
  showCopyConfirm,
  closeCopyConfirm,
  closeCopyComplete,
  makeFetchRoleForRefer,
  deleteErr,
  initState,
  clearInitMenuFormValue,
};

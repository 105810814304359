import ModalBody from '../../atoms/ModalBody';
import ModalFotterYesNoButtonComponent from '../../molecules/ModalFotterYesNoButtonComponent';

import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

class DeleteConfirmModal extends React.Component {
  static get propTypes() {
    return {
      targetId: PropTypes.string,
      children: PropTypes.string,
      show: PropTypes.bool,
      onClickYes: PropTypes.func,
      onClickNo: PropTypes.func,
    };
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>
          <p className="text-center">
            選択されたイベントを削除してよろしいですか？
          </p>
        </ModalBody>
        <ModalFotterYesNoButtonComponent
          onYesClick={this.props.onClickYes}
          onNoClick={this.props.onClickNo}
        />
      </Modal>
    );
  }
}

export default DeleteConfirmModal;

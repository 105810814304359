import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { wrap } from '../utils/PermissionComponent';

const smallSize = 'S';
const largeSize = 'L';
const space = ' ';

class NormalButton extends React.Component {
  static get propTypes() {
    return {
      href: PropTypes.string,
      onClick: PropTypes.func,
      color: PropTypes.string,
      isOutline: PropTypes.bool,
      hiddenFlg: PropTypes.bool,
      className: PropTypes.string,
      children: PropTypes.any,
      size: PropTypes.string,
      isEnable: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.isEnable = this.isEnable.bind(this);
  }

  /**
   * ボタンスタイルとボタンカラーを設定します。
   * @param {アウトライン} isOutline
   * @param {色} color
   */
  addClassBtnStyleAndColor(isOutline, color) {
    const btnStyle = isOutline ? 'btn-outline-' : 'btn-';
    return btnStyle + color;
  }

  /**
   * ボタンサイズを設定します。
   * @param {ボタンサイズ} size
   */
  addClassBtnSize(size) {
    if (size === largeSize) {
      return 'btn-lg';
    } else if (size === smallSize) {
      return 'btn-sm';
    } else {
      return '';
    }
  }

  getOptionalClass() {
    const optionalClass = this.props.className;
    if (optionalClass === undefined || optionalClass == null) {
      return '';
    } else {
      return optionalClass;
    }
  }

  getHref() {
    const href = this.props.href;

    if (href === undefined || href == null) {
      return '';
    } else {
      return href;
    }
  }

  onClick(e) {
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(e);
    }
    return false;
  }

  isEnable() {
    if (this.props.isEnable) {
      return this.props.isEnable();
    }
    return true;
  }

  /**
   * メイン
   */
  render() {
    return (
      <CursorA
        href={this.getHref()}
        className={
          'btn ' +
          this.addClassBtnStyleAndColor(
            this.props.isOutline,
            this.props.color
          ) +
          space +
          'mr-1' +
          space +
          this.addClassBtnSize(this.props.size) +
          space +
          this.getOptionalClass() +
          space +
          (!this.isEnable() ? 'disabled' : '')
        }
        style={{ display: this.props.hiddenFlg ? 'none' : '' }}
        onClick={this.onClick}
      >
        {this.props.children}
      </CursorA>
    );
  }
}

const CursorA = styled.a`
  cursor: pointer;
`;

export default wrap(NormalButton);

import PropTypes from 'prop-types';
import React from 'react';

import Card from '../Card';

import SearchInterviews from './SearchInterviews';
import SearchInterviewsDetailModal from './SearchInterviewsDetailModal';
import SearchInterviewsResultCalendar from './SearchInterviewsResultCalendar';

export default class InterviewContent extends React.Component {
  render() {
    return (
      <Card>
        <SearchInterviews
          onSubmit={this.props.onSubmit}
          options={this.props.options}
          substituteUser={this.props.state.substituteUser}
          onClickSubstituteReleaseButton={
            this.props.onClickSubstituteReleaseButton
          }
        />
        {this.props.state.reservationManagementInterview.interviews.length >
        0 ? (
          <div>
            <SearchInterviewsResultCalendar
              interviewsList={this.props.interviewsClanderValues}
              onClickEvent={this.props.onClickEvent}
              defaultDate={
                this.props.state.reservationManagementInterview.selectedDate ||
                undefined
              }
            />
            {this.props.state.reservationManagementInterview
              .interviewsDetail ? (
              <SearchInterviewsDetailModal
                show={
                  this.props.state.reservationManagementInterview
                    .isShowPlansDetailModal
                }
                interviewsDetail={
                  this.props.state.reservationManagementInterview
                    .interviewsDetail
                }
                methodTypeOptions={this.props.options.methodTypes}
                onClickModalCloseButton={this.props.onClickModalCloseButton}
                onClickInterviewDetailButton={(interviewId, interviewerId) => {
                  this.props.onClickInterviewDetailButton(
                    interviewId,
                    interviewerId,
                    this.props.state.reservationManagementInterview.selectedDate
                  );
                }}
                onClickReservationDetailButton={
                  this.props.onClickReservationDetailButton
                }
                substituteUser={this.props.state.substituteUser}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </Card>
    );
  }
}

InterviewContent.propTypes = {
  onSubmit: PropTypes.any,
  options: PropTypes.any,
  state: PropTypes.any,
  onClickSubstituteReleaseButton: PropTypes.func,
  interviewsClanderValues: PropTypes.any,
  onClickEvent: PropTypes.func,
  onClickModalCloseButton: PropTypes.func,
  onClickInterviewDetailButton: PropTypes.func,
  onClickReservationDetailButton: PropTypes.func,
};

import PropTypes from 'prop-types';
import React from 'react';

import FormLabel from '../atoms/FormLabel';
import FormSelect from '../atoms/FormSelect';

class FromSelectComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      options: PropTypes.array,
      hiddenFlg: PropTypes.bool,
      onChange: PropTypes.func,
    };
  }

  render() {
    return (
      <div
        className="form-group"
        style={{ display: this.props.hiddenFlg ? 'none' : '' }}
      >
        <FormLabel>{this.props.label}</FormLabel>
        <FormSelect onChange={this.props.onChange}>
          {this.props.options.map((option) => {
            return (
              <option
                key={this.props.label + option.value}
                value={option.value}
              >
                {option.name}
              </option>
            );
          })}
        </FormSelect>
      </div>
    );
  }
}

export default FromSelectComponent;

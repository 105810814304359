import { change } from 'redux-form';

import { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('/admin/profile');

/* プロフィール情報取得API */
const fetchAdminProfile = () => {
  return axios.get('/admin/profile');
};

/* プロフィール情報取得API成功時 */
const fetchAdminProfileSuccess = (result) => {
  return {
    type: 'admin/profile/FETCH_ADMIN_PROFILE',
    payload: {
      result: result,
    },
  };
};

const showLoading = () => {
  return new EmptyAction('admin/profile/SHOW_LOADING');
};

const makeFetchAdminProfile = () => {
  return (dispatch) => {
    dispatch(showLoading());
    return fetchBases()
      .then((data) => {
        dispatch(fetchBasesSuccess(data.data));
        fetchAdminProfile()
          .then((data) => dispatch(fetchAdminProfileSuccess(data.data)))
          .catch((err) => dispatch(fetchErrorData(err)));
      })
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchBases = () => {
  return axios.get(`/admin/bases`);
};

const fetchBasesSuccess = (result) => {
  return {
    type: 'FETCH_BASES',
    payload: {
      result: new OptionCollection(
        result.bases.map((base) => {
          return new Option(base.id, base.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchBases = () => {
  return (dispatch) => {
    return fetchBases()
      .then((data) => dispatch(fetchBasesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const updateAdminProfileLoading = () => {
  return {
    type: 'admin/profile/UPDATE_ADMIN_PROFILE_LOADING',
  };
};

const fetchUpdateAdminProfile = (data) => {
  return {
    type: 'admin/profile/FETCH_UPDATE_ADMIN_PROFILE',
    payload: {
      result: data,
    },
  };
};

const putAdminProfile = (values) => {
  let tel = '';
  if (values.tel1) {
    tel = values.tel1 + '-' + values.tel2 + '-' + values.tel3;
  }

  const params = new Params({
    profileImageTempId: values.profileImageTempId,
    profileImageId: values.profileImageId,
    mailAddress: values.mailAddress,
    phoneNumber: tel,
    baseId: values.baseId,
    roleId: values.roleId,
    firstName: values.firstName,
    familyName: values.familyName,
  });
  return axios.put(`/admin/profile`, params.removeParams());
};

const makeUpdateAdminProfile = (values) => {
  return (dispatch) => {
    dispatch(updateAdminProfileLoading());
    putAdminProfile(values)
      .then((data) => dispatch(fetchUpdateAdminProfile(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const uploadProfileImg = (form, referer) => {
  console.debug(form);

  const params = new FormData();
  params.append('file', form);

  return axios.post(`/admin/staff/profileimg`, params, {
    headers: {
      'X-Referer': referer,
    },
  });
};

const uploadProfileImgSuccess = (data, form, name) => {
  console.debug('upload profile img success', data);
  return change(form, name, data.id);
};

const makeUploadProfileImg = (file, form, name, referer) => {
  return (dispatch) => {
    uploadProfileImg(file, referer)
      .then((data) => dispatch(uploadProfileImgSuccess(data.data, form, name)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const initState = () => {
  return {
    type: 'admin/profile/INIT_STATE',
  };
};

const setEditValues = (values) => {
  return {
    type: 'admin/profile/SET_EDIT_VALUES',
    payload: {
      values: values,
    },
  };
};

const deleteFile = (form, names) => {
  return (dispatch) => {
    names.forEach((name) => {
      dispatch(change(form, name, null));
    });
  };
};

export {
  makeFetchAdminProfile,
  makeFetchBases,
  makeUpdateAdminProfile,
  makeUploadProfileImg,
  setEditValues,
  deleteFile,
  initState,
};

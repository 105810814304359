import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SubmissionError } from 'redux-form';

import Card from '../../organisms/Card';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import Form from '../../organisms/settingPassword/SettingPasswordEditForm';

class SettingPasswordEdit extends React.Component {
  static get propTypes() {
    return {
      initState: PropTypes.func,
      fetchUpdatePassword: PropTypes.func,
      children: PropTypes.any,
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
      state: PropTypes.shape({
        updatePasswordEdit: PropTypes.shape({
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
          isErr: PropTypes.bool,
        }),
      }),
    };
  }

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;
  }

  submit(values) {
    if (values.newPassword !== values.newPasswordConfirm) {
      throw new SubmissionError({
        newPasswordConfirm: '新しいパスワードと一致していません',
      });
    }
    this.props.fetchUpdatePassword(values, this.props.match.params.id);
  }

  onClose() {
    this.props.initState();
    this.setState({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    });
  }

  render() {
    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>パスワード変更</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="編集">
                  <Form onSubmit={this.submit} />

                  <RegisterCompleteModal
                    message="正常にパスワードを変更しました。"
                    show={this.props.state.updatePasswordEdit.isComplete}
                    onCloseClick={this.onClose}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </>
    );
  }
}

export default SettingPasswordEdit;

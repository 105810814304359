import { connect } from 'react-redux';

import {
  makeDeleteForm,
  makeEditForm,
  makeFetchAndSetFormById,
  makeFetchFormById,
  makeFetchForms,
  makeFetchMessageTemplates,
  makeFetchParts,
  makeFetchSfItems,
  resetReferResult,
  setFormTemplate,
  showDeleteConfirmModal
} from '../actions/FormManagement';
import FormManagementComponent from '../components/pages/formManagement/FormManagement';
import FormManagementEditComponent from '../components/pages/formManagement/FormManagementEdit';
import FormManagementSearchComponent from '../components/pages/formManagement/FormManagementSearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.formManagement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchForms(values) {
      dispatch(makeFetchForms(values));
    },
    editForm(values) {
      dispatch(makeEditForm(values));
    },
    fetchSfItems() {
      dispatch(makeFetchSfItems());
    },
    fetchParts() {
      dispatch(makeFetchParts());
    },
    fetchMessageTemplate() {
      dispatch(makeFetchMessageTemplates());
    },
    setFormTemplate(values) {
      dispatch(setFormTemplate(values));
    },
    fetchForm(id) {
      dispatch(makeFetchFormById(id));
    },
    resetReferResult() {
      dispatch(resetReferResult());
    },
    deleteForm(ids) {
      dispatch(makeDeleteForm(ids));
    },
    makeFetchAndSetFormById(id) {
      dispatch(makeFetchAndSetFormById(id));
    },
    showDeleteConfirmModal() {
      dispatch(showDeleteConfirmModal());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormManagementComponent);
const FormManagementSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormManagementSearchComponent);

export { FormManagementSearch };

const FormManagementEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormManagementEditComponent);

export { FormManagementEdit };

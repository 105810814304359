class Params {
  constructor(params) {
    this.params = params;
  }

  removeParams() {
    const removedParams = Object.assign({}, this.params);

    for (const key in removedParams) {
      if (removedParams[key] == null || removedParams[key] === '') {
        delete removedParams[key];
      }
    }

    return removedParams;
  }
}

export default Params;

import PropTypes from 'prop-types';
import React from 'react';

class ModalFooter extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
    };
  }

  render() {
    return <div className="modal-footer">{this.props.children}</div>;
  }
}

export default ModalFooter;

import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Calendar } from 'react-big-calendar';

const CalendarConstant = {
  START_HOUR: 8,
  END_HOUR: 21,
};

const eventStyleGetter = function (date) {
  console.log('eventStyleGetter', date);

  if (moment(date).weekday() === 0) {
    const style = {
      'background-color': '#ff8686',
    };

    return {
      style,
    };
  }

  if (moment(date).weekday() === 6) {
    const style = {
      'background-color': '#8b86ff',
    };

    return {
      style,
    };
  }
  return undefined;
};

const InterviewCalendar = (props) => {
  const getDayPropGetter = (isHighlightWeekend) => {
    if (isHighlightWeekend) {
      return eventStyleGetter;
    }
    return undefined;
  };
  return (
    <Calendar
      dayPropGetter={getDayPropGetter(props.highlightWeekend)}
      min={moment({
        hour: CalendarConstant.START_HOUR,
        minute: 0,
      }).toDate()}
      max={moment({
        hour: CalendarConstant.END_HOUR,
        minute: 0,
      }).toDate()}
      {...props}
    />
  );
};

InterviewCalendar.propTypes = {
  highlightWeekend: PropTypes.bool,
};

export default InterviewCalendar;

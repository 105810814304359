import PropTypes from 'prop-types';
import React from 'react';

class LoginButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      disabled: PropTypes.bool,
    };
  }

  render() {
    return (
      <button type="submit" className={'btn btn-primary'} {...this.props}>
        ログイン
      </button>
    );
  }
}

export default LoginButton;

import { bool, func, object, shape, string } from 'prop-types';
import React from 'react';

import VenueManagementRefer from '../../templates/venueManagement/Refer';

class Refer extends React.Component {
  static get propTypes() {
    return {
      fetchData: func,
      history: object,
      isLoading: bool,
      match: shape({
        params: shape({
          id: string,
        }),
      }),
      state: shape({
        venueRefer: object,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.props.fetchData(null, { id: this.props.match.params.id });

    this.state = {
      id: this.props.match.params.id,
      isLoading: false,
    };
  }

  render() {
    return (
      <section className="section">
        <div className="section-header">
          <h1>会場管理</h1>
        </div>
        <div className="section-body">
          <VenueManagementRefer
            isLoading={this.state.isLoading}
            result={this.props.state.venueRefer}
            id={this.state.id}
            history={this.props.history}
            state={this.props.state}
          />
        </div>
      </section>
    );
  }
}

export default Refer;

const initialState = {
  todoList: [],
  searchResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    samples: [],
  },
  isLoading: true,
  isDisplaySavedModal: false,
  data: {},
  prefectures: [],
  errorResult: {
    messages: [],
  },
};

const todoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TODO': {
      // 新しく追加するTODO
      const todo = action.payload.todo;
      // stateを複製して追加
      const newState = Object.assign({}, state);
      newState.todoList.push(todo);
      return newState;
    }
    case 'SEARCH_DATA': {
      const results = action.payload.results;
      console.log('results: ', results);

      const newState = Object.assign({}, state);
      newState.searchResults = results;
      return newState;
    }
    case 'REFER_DATA': {
      const result = action.payload.result;

      const newState = Object.assign({}, state);
      newState.data = result;
      newState.isLoading = false;
      return newState;
    }
    case 'FETCH_ERROR': {
      const result = action.payload.result;
      if (result.response) {
        console.debug(result.response.data);
        console.debug(result.response.status);
        console.debug(result.response.statusText);
        console.debug(result.response.headers);
      } else if (result.request) {
        console.debug(result.request);
      } else {
        console.debug('Error', result.message);
      }
      console.debug(result.config);

      const newState = Object.assign({}, state);
      /*
      newState.isLoading = false;
      newState.errorResult = result.response.data;
      */
      return newState;
    }
    case 'SAVE_FAILURE': {
      const result = action.payload.result;
      console.debug('save_failure: ', result);

      const newState = Object.assign({}, state);
      newState.isLoading = false;
      newState.errorResult = result.response.data;
      return newState;
    }
    case 'POST_SAVE': {
      const newState = Object.assign({}, state);
      newState.isLoading = true;
      newState.errorResult = {
        messages: [],
      };
      return newState;
    }
    case 'SAVE_SUCCESS': {
      const result = action.payload.result;
      console.debug('save_success: ', result);

      const newState = Object.assign({}, state);
      newState.isLoading = false;
      newState.isDisplaySavedModal = true;
      return newState;
    }
    case 'TRANSITION_REFER': {
      const newState = Object.assign({}, state);
      newState.isDisplaySavedModal = false;
      return newState;
    }
    case 'SET_PREFECTURE_ID': {
      const prefectureId = action.payload.prefectureId;

      const newState = Object.assign({}, state);
      newState.data.prefectureId = prefectureId;
      return newState;
    }
    case 'FETCH_PREFECTURES': {
      const prefectures = action.payload.result;

      const newState = Object.assign({}, state);
      newState.prefectures = prefectures;
      return newState;
    }
    default:
      return state;
  }
};

export { todoReducer as default };

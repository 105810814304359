import PropTypes from 'prop-types';
import React from 'react';

class ModalBody extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
    };
  }

  render() {
    return <div className="modal-body">{this.props.children}</div>;
  }
}

export default ModalBody;

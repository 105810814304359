import PropTypes, { number } from 'prop-types';
import React from 'react';

class FormGroupRow extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.node.isRequired,
      className: PropTypes.string,
      vertical: number,
    };
  }

  constructor(props) {
    super(props);
    this.getClassName = this.getClassName.bind(this);
  }

  getClassName() {
    if (this.props.className) {
      return ' ' + this.props.className;
    }
    return '';
  }

  render() {
    let vertical;
    if (Object.prototype.hasOwnProperty.call(this.props, 'vertical')) {
      if (this.props.vertical === VERTICAL.TOP) {
        vertical = 'align-items-start';
      } else if (this.props.vertical === VERTICAL.CENTER) {
        vertical = 'align-items-center';
      } else if (this.props.vertical === VERTICAL.BOTTOM) {
        vertical = 'align-items-end';
      }
    } else {
      vertical = 'align-items-center';
    }

    return (
      <div
        className={`form-group row d-flex ${vertical}${this.getClassName()}`}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FormGroupRow;

export const VERTICAL = {
  TOP: 0,
  CENTER: 1,
  BOTTOM: 2,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

import {
  makeFetchMenus,
  clearInitMenuFormValue,
} from '../../../actions/RoleManagement';
import { EditStatus } from '../../../reducers/RoleManagement';
import Option, { OptionCollection } from '../../model/Option';
import CancelButton from '../../molecules/CancelButton';
import ConfirmButton from '../../molecules/ConfirmButton';
import FormInlineRadioComponent from '../../molecules/FormInlineRadioComponentForForm';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import HorizontalFormMenuComponent from '../../organisms/roleManagement/HorizontalFormMenuComponent';
import { scrollToFirstError } from '../../utils/ScrollError';
import * as Validator from '../../utils/Validate';

class RoleManagementEditForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      fetchMenus: PropTypes.func,
      onPrevButtonClick: PropTypes.func,
      invalid: PropTypes.bool,
      submitting: PropTypes.bool,
      roles: PropTypes.array,
      menuOptions: PropTypes.array,
      menus: PropTypes.array,
      rolePermissionOptions: PropTypes.array,
      clearInitMenuFormValue: PropTypes.func,
      formValue: PropTypes.shape({
        initMenu: PropTypes.string,
      }),
      isNew: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.onChangeMenuPermission = this.onChangeMenuPermission.bind(this);
  }

  onChangeMenuPermission(menuId, permissionId, e) {
    console.debug(
      'RoleManagementEditForm Radio',
      menuId,
      permissionId,
      e.target.value,
      this.props.formValue.initMenu
    );

    if (!this.props.formValue.initMenu) {
      return;
    }

    if (this.props.formValue.initMenu !== menuId) {
      return;
    }

    const filteredRole = this.props.rolePermissionOptions.filter(
      (rolePermission) => {
        return rolePermission.value === permissionId;
      }
    );
    if (filteredRole.length === 0) {
      return;
    }

    if (filteredRole[0].name !== 'なし') {
      return;
    }

    this.props.clearInitMenuFormValue();
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent
            label="名前"
            name="name"
            component="input"
            type="text"
            validate={[Validator.required]}
          />
          <FormInlineRadioComponent
            label="システム管理者フラグ"
            options={[
              { name: 'なし', value: '0', default: true },
              { name: 'システム管理者', value: '1', default: false },
            ]}
            name="isSystemAdmin"
            validate={[Validator.required]}
          />
          <HorizontalSelectComponent
            label="ログイン時初期表示機能"
            name="initMenu"
            options={this.props.menuOptions}
            validate={[Validator.required]}
          />
          <HorizontalFormMenuComponent
            menuList={this.props.menus || []}
            roleList={this.props.roles}
            options={this.props.rolePermissionOptions || []}
            validate={[Validator.required]}
            onChange={this.onChangeMenuPermission}
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <CancelButton onClick={this.props.onPrevButtonClick} />
              <ConfirmButton
                disabled={this.props.invalid || this.props.submitting}
                isNew={this.props.isNew}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const roleManagementEditForm = reduxForm({
  // a unique name for the form
  form: 'roleManagementEditForm',
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(RoleManagementEditForm);

export default connect(
  (state) => {
    console.debug('roleManagementEditForm: ', state);

    var rolePermissionIds = {};
    if (
      state.roleManagement.roleRefer.detail.roles &&
      state.roleManagement.roleRefer.detail.roles.length > 0
    ) {
      const roles = state.roleManagement.roleRefer.detail.roles;
      rolePermissionIds = roles.reduce((acc, value) => {
        const obj = { ...acc };
        obj['RolePermissionId-' + value.menuId] = value.rolePermissionId;
        return obj;
      }, {});
    }

    const formValues = getFormValues('roleManagementEditForm')(state);

    const menuOptions = new OptionCollection(
      (state.roleManagement.roleRefer.detail.menus || []).flatMap((section) => {
        return section.menus
          .map((menu) => {
            let rolePermissionId;
            if (
              !formValues ||
              !Object.prototype.hasOwnProperty.call(
                formValues,
                `RolePermissionId-${menu.menuId}`
              )
            ) {
              if (
                !state.roleManagement.roleRefer.detail ||
                !state.roleManagement.roleRefer.detail.roles
              ) {
                return null;
              }

              // 編集時の初回(formValuesに値がセットされていない場合)

              const filteredRoles = state.roleManagement.roleRefer.detail.roles.filter(
                (role) => {
                  return role.menuId === menu.menuId;
                }
              );

              if (!filteredRoles || filteredRoles.length === 0) {
                return null;
              }

              rolePermissionId = filteredRoles[0].rolePermissionId;
            } else {
              // formValuesに値がセットされている場合
              rolePermissionId = formValues[`RolePermissionId-${menu.menuId}`];
            }

            if (!rolePermissionId) {
              return null;
            }

            const filteredPermission = state.options.rolePermissions.filter(
              (permission) => permission.value === rolePermissionId
            );

            if (!filteredPermission || filteredPermission.length === 0) {
              return null;
            }

            if (filteredPermission[0].name === 'なし') {
              return null;
            }

            return new Option(menu.menuId, menu.menuName);
          })
          .filter((a) => a);
      })
    ).getOptions();

    console.debug(
      'RoleManagementEditForm editStatus, initMenu, menuOptions',
      state.roleManagement.roleEdit.editStatus,
      state.roleManagement.roleRefer.detail.initMenuId,
      menuOptions,
      state.roleManagement.roleRefer.detail.roles
    );

    let setMenuOptions = [];
    if (state.roleManagement.roleEdit.editStatus === EditStatus.NEW) {
      setMenuOptions = menuOptions;
    } else if (
      state.roleManagement.roleEdit.editStatus === EditStatus.EDIT &&
      state.roleManagement.roleRefer.detail
    ) {
      // initMenuとmenuOptionsの初回のセットを同じタイミングで行うための処理
      setMenuOptions = menuOptions;
    }

    return {
      initialValues: {
        name: state.roleManagement.roleRefer.detail.name,
        isSystemAdmin: state.roleManagement.roleRefer.detail.isSystemAdmin
          ? '1'
          : '0',
        initMenu: state.roleManagement.roleRefer.detail.initMenuId,
        ...rolePermissionIds,
      },
      formValue: {
        initMenu:
          formValues &&
          Object.prototype.hasOwnProperty.call(formValues, 'initMenu')
            ? formValues.initMenu
            : undefined,
      },
      roles: state.roleManagement.roleRefer.detail.roles,
      rolePermissionOptions: state.options.rolePermissions,
      menus: state.roleManagement.roleRefer.detail.menus,
      menuOptions: setMenuOptions,
    };
  },
  (dispatch) => {
    return {
      fetchMenus() {
        dispatch(makeFetchMenus());
      },
      clearInitMenuFormValue() {
        dispatch(clearInitMenuFormValue());
      },
    };
  }
)(roleManagementEditForm);

export default {
  /**
   * @type {string} 検索画面
   */
  search: '/admin/role/',
  /**
   * 詳細画面
   * @param {string} id ロールID
   */
  refer: (id) => `/admin/role/refer/${id}`,
  /**
   * 編集画面
   * @param {string} id ロールID
   */
  edit: (id) => `/admin/role/edit/${id}`,
  /**
   * 新規作成画面
   */
  newEdit: '/admin/role/edit/',
};

import styled from 'styled-components';

const DropDown = styled.div`
  position: absolute;
  transform: translate3d(0px, 28px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
`;

export default DropDown;

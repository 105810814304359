import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import SearchDisplayItemNum from '../molecules/SearchDisplayItemNum';

class Pager extends React.Component {
  MAX_PAGE_ITEM_NUM() {
    return 5;
  }

  static get propTypes() {
    return {
      children: PropTypes.any,
      searchParams: PropTypes.shape({
        pagination: PropTypes.shape({
          total: PropTypes.number,
          pageSize: PropTypes.number,
          page: PropTypes.number,
        }),
        sort: PropTypes.any,
      }),
      search: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  changePage(page) {
    console.debug('page', page);
    if (page === this.props.searchParams.pagination.page) {
      return;
    }

    const pageSize = this.props.searchParams.pagination.pageSize;

    this.props.search({
      pagination: {
        page: page,
        pageSize: pageSize,
      },
      sort: this.props.searchParams.sort,
    });
  }

  prevPage() {
    this.changePage(this.props.searchParams.pagination.page - 1);
  }

  nextPage() {
    this.changePage(this.props.searchParams.pagination.page + 1);
  }

  getPageItem() {
    const maxPageNum = Math.ceil(
      this.props.searchParams.pagination.total /
        this.props.searchParams.pagination.pageSize
    );
    const currentPage = this.props.searchParams.pagination.page;

    console.debug('max_page_item_num', this.MAX_PAGE_ITEM_NUM);
    const minPage = currentPage - Math.floor(this.MAX_PAGE_ITEM_NUM() / 2);
    const maxPage = currentPage + Math.floor(this.MAX_PAGE_ITEM_NUM() / 2);

    console.debug('maxPage >= maxPageNum', maxPage >= maxPageNum);
    let range = [];
    if (minPage < 1) {
      range = [...Array(maxPageNum).keys()].slice(0, this.MAX_PAGE_ITEM_NUM());
    } else if (maxPage >= maxPageNum) {
      range = [...Array(maxPageNum).keys()].slice(
        Math.max(maxPageNum - this.MAX_PAGE_ITEM_NUM(), 0),
        maxPageNum
      );
    } else {
      range = [...Array(maxPageNum).keys()].slice(minPage - 1, maxPage);
    }
    console.debug('minPage, maxPage: ', minPage, maxPage, range);

    const isActive = (v) => {
      return v === currentPage;
    };

    return (
      <>
        {range.map((v, i) => {
          const page = v + 1;
          return (
            <li
              key={i}
              className={isActive(page) ? 'page-item active' : 'page-item'}
            >
              <a
                className="page-link"
                href="#!"
                onClick={this.changePage.bind(this, page)}
              >
                {page}
              </a>
            </li>
          );
        })}
      </>
    );
  }

  isPrevButtonDisabled() {
    return this.props.searchParams.pagination.page === 1;
  }

  isNextButtonDisabled() {
    const maxPageNum = Math.ceil(
      this.props.searchParams.pagination.total /
        this.props.searchParams.pagination.pageSize
    );
    return this.props.searchParams.pagination.page === maxPageNum;
  }

  render() {
    return (
      <Row>
        <Col>
          <div className="form-group row">
            <SearchDisplayItemNum
              searchParams={this.props.searchParams}
              search={this.props.search}
            />
          </div>
        </Col>
        <Col>
          <ul className="pagination">
            <li
              className={
                'page-item' + (this.isPrevButtonDisabled() ? ' disabled' : '')
              }
            >
              <a className="page-link" href="#!" onClick={this.prevPage}>
                «
              </a>
            </li>
            {this.getPageItem()}
            <li
              className={
                'page-item' + (this.isNextButtonDisabled() ? ' disabled' : '')
              }
            >
              <a className="page-link" href="#!" onClick={this.nextPage}>
                »
              </a>
            </li>
          </ul>
        </Col>
        <Col className="d-flex align-items-center">
          総件数:
          {this.props.searchParams.pagination.total}
        </Col>
      </Row>
    );
  }
}

export default Pager;

import { trackPromise } from 'react-promise-tracker';
import { change } from 'redux-form';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';
import Zipcode, { FromZipcode } from '../components/model/Zipcode';

import createAxios from './axios';
import { fetchErrorData } from './common';
import { trackAsync } from './util';

const axios = createAxios('/admin/venues');
const searchData = (results) => {
  return {
    type: 'SEARCH_DATA',
    payload: { result: results },
  };
};

const referDataResult = (results) => {
  return {
    type: 'venue/REFER_DATA',
    payload: { result: results },
  };
};

/**
 * 一覧用
 * @param {*} condition
 * @param {*} searchParams
 */
function fetchSearchData(condition, searchParams) {
  const params = new Params({
    name: condition.name,
    areaId: condition.areaId,
    page: searchParams.pagination.page,
    pageSize: searchParams.pagination.pageSize,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  });

  return axios.get(`/admin/halls`, {
    params: params.removeParams(),
  });
}

const storeSearchValues = (values, searchParams) => {
  return new Action('venue/STORE_SEARCH_VALUES', { values, searchParams });
};

function makeSearchData(condition, searchParams) {
  return (dispatch) => {
    trackAsync(async () => {
      try {
        dispatch(storeSearchValues(condition, searchParams));
        const searchDataRes = await fetchSearchData(condition, searchParams);

        dispatch(fetchDataSuccess(searchDataRes.data, condition));
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
}

/**
 * 参照用
 */
const fetchDataSuccess = (data, searchParams) => {
  return {
    type: 'SEARCH_DATA',
    payload: {
      result: data,
      searchParams: searchParams,
    },
  };
};

function referData(condition, searchParams) {
  return axios.get(`/admin/halls/` + searchParams.id);
}

const showLoading = () => {
  return new EmptyAction('venue/SHOW_LOADING');
};

const hideLoading = () => {
  return new EmptyAction('venue/HIDE_LOADING');
};

function makeReferData(condition, searchParams) {
  return async (dispatch) => {
    try {
      dispatch(showLoading());

      const referDataRes = await referData(condition, searchParams);
      dispatch(referDataSuccess(referDataRes.data));

      dispatch(hideLoading());
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
}

const referDataSuccess = (data) => {
  return {
    type: 'venue/REFER_DATA',
    payload: {
      result: data,
    },
  };
};

/**
 * エリア取得
 */
function makeFetchArea() {
  return function (dispatch) {
    return fetchOptionArea()
      .then((data) => dispatch(fetchAreaSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
}

const fetchAreaSuccess = (data) => {
  return {
    type: 'FETCH_AREAS',
    payload: {
      result: new OptionCollection(
        data.areaList.map((area) => {
          return new Option(area.id, area.name);
        })
      ).getOptions(),
    },
  };
};

function fetchOptionArea() {
  return axios.get(`/admin/areas`);
}

/**
 * 拠点取得
 */
function makeFetchBaces() {
  return function (dispatch) {
    return fetchBases()
      .then((data) => dispatch(fetchBasesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
}

const fetchBasesSuccess = (data) => {
  return {
    type: 'FETCH_BASES',
    payload: {
      result: new OptionCollection(
        data.bases.map((base) => {
          return new Option(base.id, base.name);
        })
      ).getOptions(),
    },
  };
};

function fetchBases() {
  return axios.get(`/admin/bases`);
}

const fetchBase = (id) => {
  return axios.get(`/admin/bases/${id}`);
};

const makeFetchBase = (id) => {
  return async (dispatch) => {
    try {
      const baseRes = await fetchBase(id);

      fetchBaseSuccess(dispatch, baseRes.data);
    } catch (err) {
      fetchErrorData(err);
    }
  };
};

const fetchBaseSuccess = (dispatch, data) => {
  const zipcode = new Zipcode(data.zipCode);

  dispatch(change('venueEditForm', 'zipCode1', zipcode.getFirst()));
  dispatch(change('venueEditForm', 'zipCode2', zipcode.getLast()));

  dispatch(change('venueEditForm', 'prefId', data.prefId));
  dispatch(change('venueEditForm', 'city', data.city));
  dispatch(change('venueEditForm', 'buildingName', data.buildingName));

  dispatch(change('venueEditForm', 'googleMapUrl', data.googleMapUrl));
  dispatch(
    change('venueEditForm', 'googleMapShortUrl', data.googleMapShortUrl)
  );
  dispatch(change('venueEditForm', 'nearestStation', data.nearestStation));

  if (data.accessList && data.accessList.length > 0) {
    dispatch(change('venueEditForm', 'accessList', data.accessList));
  }
};

/**
 * 都道府県取得
 */
function makeFetchPrefs() {
  return function (dispatch) {
    return fetchPrefs()
      .then((data) => dispatch(fetchPrefsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
}

const fetchPrefsSuccess = (data) => {
  return {
    type: 'FETCH_PREFS',
    payload: {
      result: new OptionCollection(
        data.map((pref) => {
          return new Option(pref.id, pref.name);
        })
      ).getOptions(),
    },
  };
};

function fetchPrefs() {
  return axios.get(`/prefs`);
}

/**
 * 編集用
 */
const putVenue = (params, id) => {
  return axios.put(`/admin/halls/${id}`, params);
};

const postVenue = (params) => {
  return axios.post(`/admin/halls`, params);
};

const fetchUpdateVenue = (data, id) => {
  return {
    type: 'venue/FETCH_UPDATE_VENUE',
    payload: {
      result: data,
      id: id,
    },
  };
};

const updateVenueLoading = () => {
  return {
    type: 'venue/UPDATE_VENUE_LOADING',
  };
};

const makeUpdateFetchError = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      return new Action(
        'venue/UPDATE_BAD_REQUEST',
        error.response.data.messages
      );
    }
  }

  return fetchErrorData(error);
};

const makeUpdateVenue = (values, id) => {
  return (dispatch) => {
    dispatch(updateVenueLoading());
    let request;

    const zipcode = FromZipcode(values.zipCode1, values.zipCode2);

    const params = new Params({
      id: id,
      name: values.name,
      areaId: values.areaId,
      baseId: values.baseId,
      zipCode: zipcode.toString(),
      prefId: values.prefId,
      city: values.city,
      buildingName: values.buildingName,
      phoneNumber: `${values.tel1}-${values.tel2}-${values.tel3}`,
      mailAddress: values.mail,
      googleMapUrl: values.googleMapUrl,
      googleMapShortUrl: values.googleMapShortUrl,
      nearestStation: values.nearestStation,
      accessList: values.accessList,
    }).removeParams();

    if (id == null) {
      request = postVenue(params);
    } else {
      request = putVenue(params, id);
    }

    trackPromise(
      request
        .then((data) => dispatch(fetchUpdateVenue(data.data, id)))
        .catch((err) => dispatch(makeUpdateFetchError(err)))
    );
  };
};

/**
 * 削除
 */
const deleteVenueSuccess = () => {
  return {
    type: 'venue/DELETE_VENUE',
  };
};

function deleteVenue(values) {
  return Promise.all(
    values.map((value) => axios.delete(`/admin/halls/${value}`))
  );
}

const searchZipCode = (zipCode) => {
  return axios.get('/admin/search_address', {
    params: {
      zipCode: zipCode,
    },
  });
};

const searchZipCodeSuccess = (dispatch, data) => {
  dispatch(change('venueEditForm', 'prefId', data.prefId));
  dispatch(change('venueEditForm', 'city', data.address2 + data.address3));
};

const makeSearchZipCode = (zipCode) => {
  return async (dispatch) => {
    try {
      const searchZipCodeRes = await searchZipCode(zipCode);
      searchZipCodeSuccess(dispatch, searchZipCodeRes.data);
    } catch (err) {
      fetchErrorData(err);
    }
  };
};

const venueUsed = (ids) => {
  return axios.get('/admin/venue/used', {
    params: {
      venues: ids,
    },
  });
};

const venueUseSuccess = (data) => {
  if (data.containsUsed) {
    return new EmptyAction('venue/SHOW_CONTAINS_USED_ALERT');
  } else {
    return new EmptyAction('venue/SHOW_DELETE_CONFIRM_MODAL');
  }
};

const makeVenueUsed = (ids) => {
  return (dispatch) => {
    trackPromise(
      venueUsed(ids).then((res) => {
        dispatch(venueUseSuccess(res.data));
      })
    ).catch((err) => fetchErrorData(err));
  };
};

function makeDeleteData(condition, searchParams) {
  return function (dispatch) {
    dispatch(hideDeleteConfirmModal());
    return trackPromise(
      deleteVenue(condition, searchParams)
        .then(() => dispatch(deleteVenueSuccess()))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
}

const makeFetchData = (id) => {
  return async (dispatch) => {
    try {
      dispatch(showLoading());

      const areaRes = await fetchOptionArea();
      dispatch(fetchAreaSuccess(areaRes.data));

      const prefsRes = await fetchPrefs();
      dispatch(fetchPrefsSuccess(prefsRes.data));

      const basesRes = await fetchBases();
      dispatch(fetchBasesSuccess(basesRes.data));

      if (id) {
        const referDataRes = await referData({}, { id: id });
        dispatch(referDataSuccess(referDataRes.data));
      } else {
        dispatch(insertState());
      }

      dispatch(hideLoading());
    } catch (err) {
      fetchErrorData(err);
    }
  };
};

const uploadAccessMapFile = (file) => {
  console.debug(file);

  const params = new FormData();
  params.append('file', file);

  return axios.post(`/access_map_img`, params, {
    headers: {
      'X-Referer': '/admin/bases',
      'Content-Type': 'multipart/form-data',
    },
  });
};

const uploadAccessMapFileSuccess = (dispatch, data, index) => {
  dispatch(
    change('venueEditForm', `accessList[${index}].accessMapImageId`, data.id)
  );

  dispatch(
    change(
      'venueEditForm',
      `accessList[${index}].accessMap`,
      `static/tmp/${data.id}`
    )
  );
};

const makeUploadAccessMapFile = (file, index) => {
  return async (dispatch) => {
    try {
      const uploadAccessMapFileRes = await uploadAccessMapFile(file);

      uploadAccessMapFileSuccess(dispatch, uploadAccessMapFileRes.data, index);
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const makeDeleteAccessMapImage = (index) => {
  return (dispatch) => {
    dispatch(change('venueEditForm', `accessList[${index}].accessMap`, null));
    dispatch(
      change('venueEditForm', `accessList[${index}].accessMapImageId`, null)
    );
  };
};

const storeValues = (values) => {
  return {
    type: 'venue/STORE_VALUES',
    payload: values,
  };
};

const initState = () => {
  return {
    type: 'venue/INIT_STATE',
  };
};

const deleteReferData = () => {
  return {
    type: 'venue/DELETE_REFER',
  };
};

const insertState = () => {
  return {
    type: 'venue/INSERT_NEW_VENUE',
  };
};

const hideDeleteSuccessModal = () => {
  return {
    type: 'venue/HIDE_DELETE_SUCCESS_MODAL',
  };
};

const backToEdit = () => {
  return new EmptyAction('venue/BACK_TO_EDIT');
};

const hideContainsUsedAlertModal = () => {
  return new EmptyAction('venue/HIDE_CONTAINS_USED_ALERT');
};

const hideDeleteConfirmModal = () => {
  return new EmptyAction('venue/HIDE_DELETE_CONFIRM_MODAL');
};

export { searchData };
export { makeSearchData };
export { referDataResult };
export { makeReferData };
export { fetchOptionArea };
export { makeFetchArea };
export { fetchUpdateVenue };
export { updateVenueLoading };
export { makeUpdateVenue };
export { deleteVenueSuccess };
export { makeDeleteData };
export { initState };
export { insertState };
export { fetchPrefs };
export { makeFetchPrefs };
export { fetchBases };
export { makeFetchBaces };
export { hideDeleteSuccessModal };
export { makeSearchZipCode };
export { makeFetchData };
export { makeFetchBase };
export { makeUploadAccessMapFile };
export { makeDeleteAccessMapImage };
export { storeValues };
export { deleteReferData };
export { backToEdit };
export { makeVenueUsed };
export { hideContainsUsedAlertModal };
export { hideDeleteConfirmModal };

import PropTypes from 'prop-types';
import React from 'react';

class InlineFormRadio extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.string,
      label: PropTypes.string,
      isChecked: PropTypes.bool,
      defaultValue: PropTypes.string,
      onChange: PropTypes.func,
      field: PropTypes.shape({
        input: PropTypes.shape({
          value: PropTypes.any,
        }),
        meta: PropTypes.object,
      }),
    };
  }

  constructor(props) {
    super(props);

    console.debug(`InlineFormRadio`, this.props);
  }

  onChange(onChange, e) {
    if (onChange) {
      onChange(e);
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    console.log('checked', this.props.isChecked);
    console.debug('field', { ...this.props.field });
    let input;
    let other;
    let onChange;
    if (this.props.field) {
      const { input: fieldInput, meta, ...fieldOther } = this.props.field;
      const { onChange: fieldOnChange, ...inputOther } = fieldInput;
      input = inputOther;
      onChange = fieldOnChange;
      other = fieldOther;
    } else {
      input = { value: null };
    }
    console.debug('field.input', { ...input });

    console.debug(
      'field value',
      input.value,
      'option.value',
      this.props.value,
      this.props.value === input.value
    );

    return (
      <div className="form-check form-check-inline">
        <input
          type="radio"
          className="form-check-input"
          id={this.props.id}
          name={this.props.name}
          onChange={this.onChange.bind(this, onChange)}
          {...input}
          {...other}
          value={this.props.value}
          checked={
            this.props.defaultValue && !input.value
              ? this.props.value === this.props.defaultValue
              : this.props.value === input.value
          }
        />
        <label className="form-check-label" htmlFor={this.props.id}>
          {this.props.label}
        </label>
      </div>
    );
  }
}

export default InlineFormRadio;

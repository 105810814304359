import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormGroupRow from '../atoms/FormGroupRow';
import FormLabel from '../atoms/FormLabel';
import styled from 'styled-components';

class FormNumberFromToComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  fieldComponent(field) {
    return <Form.Control type="number" />;
  }

  component() {
    return (
      <FormGroupRow>
        <label className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </label>
        <div className="col-sm-6">
          <NoMarginRow>
          <div>
            <Row>
             <Col>
                <Field
                  name={this.props.name + 'From'}
                  className="form-control"
                  type="number"
                  component={'input'}
                 />
                </Col>

                <label className="offset-sm-1 col-sm-2 col-form-label">
                  ～  
                </label>
                <Col>
                  <Field
                    name={this.props.name + 'To'}
                    className="form-control"
                    type="number"
                    component={'input'}
                  />
                </Col>
              </Row>
            </div>
          </NoMarginRow>
        </div>
      </FormGroupRow>
    );
  }

  render() {
    return this.component();
  }
}

const NoMarginRow = styled(Row)`
  margin-left: 0px;
`;

export default FormNumberFromToComponent;

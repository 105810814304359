import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Row, Col } from 'react-bootstrap';

class ProfileImage extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      label: PropTypes.string,
      url: PropTypes.string,
    };
  }

  render() {
    return (
      <FormGroup>
        <Row>
          <Col xs={3}>
            <label className="control-label">{this.props.label}</label>
          </Col>
          <Col xs={9} className="control-detail-div">
            {this.props.url ? (
              <img className="ml-3" width="200" src={this.props.url} />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

export default ProfileImage;

import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import ModalBody from '../atoms/ModalBody';
import ModalFotterCloseButton from '../molecules/ModalFotterCloseButtonComponent';

class RegisterCompleteModal extends React.Component {
  static get propTypes() {
    return {
      targetId: PropTypes.string,
      children: PropTypes.string,
      onclick: PropTypes.string,
      show: PropTypes.bool,
      onCloseClick: PropTypes.func,
      text: PropTypes.string,
    };
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>
          <p className="text-center">
            {this.props.text ? this.props.text : '正常に登録が完了しました'}
          </p>
        </ModalBody>
        <ModalFotterCloseButton
          closeButtonTitle="閉じる"
          onClick={this.props.onCloseClick}
        />
      </Modal>
    );
  }
}

export default RegisterCompleteModal;

import { array, bool, func, object, shape, string } from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, FieldArray } from 'redux-form';

import {
  makeFetchArea,
  makeFetchBaces,
  makeFetchBase,
  makeFetchPrefs,
  makeReferData,
  makeSearchZipCode,
  makeUploadAccessMapFile,
  makeDeleteAccessMapImage,
} from '../../../actions/Venue';
import FormLabel from '../../atoms/FormLabel';
import Zipcode from '../../model/Zipcode';
import CancelButton from '../../molecules/CancelButton';
import ConfirmButton from '../../molecules/ConfirmButton';
import FormTelComponent from '../../molecules/FormTelComponent';
import FormZipCodeComponent from '../../molecules/FormZipCodeComponent';
import GoogleMapSearch from '../../molecules/GoogleMapSearchComponent';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalFormTextAreaComponent from '../../molecules/HorizontalFormTextareaComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import MarginFormComponent from '../../molecules/MarginFormComponent';
import HorizontalFormAccess from '../../organisms/base/HorizontalFormAccessComponent';
import { scrollToFirstError } from '../../utils/ScrollError';
import * as Validator from '../../utils/Validate';

class VenueEditForm extends React.Component {
  static get propTypes() {
    return {
      deleteAccessMapImage: func,
      fetchBase: func,
      fetchBases: func,
      fetchDivisions: func,
      fetchOccupations: func,
      fetchRoles: func,
      formValue: shape({
        zipCode: string,
        address: string,
      }),
      handleSubmit: func,
      invalid: bool,
      isError: bool,
      messages: shape({
        baseId: string,
      }),
      onPrevButtonClick: func,
      onSubmit: func,
      options: shape({
        areas: array,
        bases: array,
        divisions: array,
        occupations: array,
        prefs: array,
        roles: array,
      }),
      searchZipCode: func,
      submitting: bool,
      uploadAccessMapFile: func,
      errorMessages: object,
    };
  }

  constructor(props) {
    super(props);

    this.searchZipCode = this.searchZipCode.bind(this);
    this.onChangeBaseSelect = this.onChangeBaseSelect.bind(this);
    this.uploadAccessMapFile = this.uploadAccessMapFile.bind(this);
    this.deleteAccessMapImage = this.deleteAccessMapImage.bind(this);
  }

  uploadAccessMapFile(file, index) {
    this.props.uploadAccessMapFile(file, index);
  }

  deleteAccessMapImage(index) {
    this.props.deleteAccessMapImage(index);
  }

  searchZipCode() {
    this.props.searchZipCode(this.props.formValue.zipCode);
  }

  onChangeBaseSelect(e) {
    console.debug('onChangeBaseSelect:', e.target.value);

    if (e.target.value) {
      this.props.fetchBase(e.target.value);
    }
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <MarginFormComponent>
            <HorizontalFormInputComponent
              label="会場名"
              name="name"
              component="input"
              type="text"
              validate={[Validator.required]}
            />
            <HorizontalSelectComponent
              label="エリア"
              options={this.props.options.areas}
              validate={[Validator.required]}
              name="areaId"
            />
            <HorizontalSelectComponent
              label="拠点"
              options={this.props.options.bases}
              name="baseId"
              onChange={this.onChangeBaseSelect}
              messages={this.props.isError ? this.props.messages.baseId : []}
            />

            <FormLabel className="col-sm-3 col-form-label">住所</FormLabel>
            <FormZipCodeComponent
              label="郵便番号"
              name="zipCode"
              buttonLabel="住所検索"
              validate={[Validator.required]}
              onClick={this.searchZipCode}
              errorMessages={
                Object.prototype.hasOwnProperty.call(
                  this.props.errorMessages,
                  'zipCode'
                ) && this.props.errorMessages.zipCode.length > 0
                  ? this.props.errorMessages.zipCode
                  : []
              }
            />
            <HorizontalSelectComponent
              label="都道府県"
              options={this.props.options.prefs}
              name="prefId"
              validate={[Validator.required]}
            />
            <HorizontalFormInputComponent
              label="市区町村"
              name="city"
              component="input"
              type="text"
              validate={[Validator.required]}
            />

            <HorizontalFormInputComponent
              label="建物名"
              name="buildingName"
              component="input"
              type="text"
            />

            <FormTelComponent name="tel" validate={[Validator.required]} />
            <HorizontalFormInputComponent
              label="連絡先メールアドレス"
              name="mail"
              component="input"
              type="text"
              validate={[Validator.required, Validator.email]}
            />

            <FormLabel className="col-sm-3 col-form-label">地図</FormLabel>

            <GoogleMapSearch address={this.props.formValue.address} />
            <HorizontalFormTextAreaComponent
              label="通常URL"
              name="googleMapUrl"
              validate={[Validator.required, Validator.url]}
            />
            <HorizontalFormInputComponent
              label="短縮URL"
              name="googleMapShortUrl"
              validate={[Validator.required, Validator.url]}
            />

            <FieldArray
              label="アクセス方法"
              name="accessList"
              component={HorizontalFormAccess}
              updateFile={this.uploadAccessMapFile}
              deleteAccessMapImage={this.deleteAccessMapImage}
            />

            <HorizontalFormInputComponent
              label="最寄駅"
              name="nearestStation"
              validate={[Validator.required]}
            />
          </MarginFormComponent>
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <CancelButton onClick={this.props.onPrevButtonClick} />
              <ConfirmButton
                disabled={this.props.invalid || this.props.submitting}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const venueEditForm = reduxForm({
  // a unique name for the form
  form: 'venueEditForm',
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(VenueEditForm);

export default connect(
  (state) => {
    const tel1Number = (tel) => {
      if (tel == null) {
        return '';
      }
      const index = tel.indexOf('-');

      return tel.slice(0, index);
    };

    const tel2Number = (tel) => {
      if (tel == null) {
        return '';
      }

      const firstSplitterIndex = tel.indexOf('-');
      const secondSplitterIndex = tel.indexOf('-', firstSplitterIndex + 1);
      return tel.slice(firstSplitterIndex + 1, secondSplitterIndex);
    };

    const tel3Number = (tel) => {
      if (tel == null) {
        return '';
      }

      const firstSplitterIndex = tel.indexOf('-');
      const secondSplitterIndex = tel.indexOf('-', firstSplitterIndex + 1);
      return tel.slice(secondSplitterIndex + 1);
    };

    const selector = formValueSelector('venueEditForm');

    const zipCode1 = selector(state, 'zipCode1');
    const zipCode2 = selector(state, 'zipCode2');

    const prefId = selector(state, 'prefId');

    const filteredPrefs = state.options.prefs
      .filter((pref) => pref.value === prefId)
      .map((pref) => pref.name);

    let prefName;
    if (filteredPrefs.length > 0) {
      prefName = filteredPrefs[0];
    } else {
      prefName = '';
    }

    const city = selector(state, 'city');
    const buildingName = selector(state, 'buildingName');

    let formValue;
    if (zipCode1 && zipCode2) {
      formValue = {
        zipCode: `${zipCode1}${zipCode2}`,
      };
    }
    formValue = {
      ...formValue,
      address: `${prefName}${city}${buildingName}`,
    };

    let accessList = state.venue.venueRefer.accessList;
    if (!accessList || accessList.length === 0) {
      accessList = [{}];
    }

    let zipcode;
    if (state.venue.venueRefer.zipCode) {
      zipcode = new Zipcode(state.venue.venueRefer.zipCode);
    }

    return {
      initialValues: {
        ...state.venue.venueRefer,
        accessList: accessList,
        tel1: tel1Number(state.venue.venueRefer.phoneNumber),
        tel2: tel2Number(state.venue.venueRefer.phoneNumber),
        tel3: tel3Number(state.venue.venueRefer.phoneNumber),
        zipCode1: zipcode ? zipcode.getFirst() : '',
        zipCode2: zipcode ? zipcode.getLast() : '',
        mail: state.venue.venueRefer.mailAddress,
      },
      options: state.options,
      formValue: formValue,
    };
  },
  (dispatch) => {
    return {
      fetchData(id) {
        dispatch(makeReferData(null, { id: id }));
      },
      fetchAreas() {
        dispatch(makeFetchArea());
      },
      fetchBases() {
        dispatch(makeFetchBaces());
      },
      fetchBase(id) {
        dispatch(makeFetchBase(id));
      },
      fetchPrefs() {
        dispatch(makeFetchPrefs());
      },
      searchZipCode(zipcode) {
        dispatch(makeSearchZipCode(zipcode));
      },
      uploadAccessMapFile(file, index) {
        dispatch(makeUploadAccessMapFile(file, index));
      },
      deleteAccessMapImage(index) {
        dispatch(makeDeleteAccessMapImage(index));
      },
    };
  }
)(venueEditForm);

import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import RegisterButton from '../../molecules/RegisterButton';
import ProfileImageReferComponent from '../../organisms/adminProfile/ProfileImage';

class AdminProfileConfirmForm extends React.Component {
  static get propTypes() {
    return {
      onSubmit: PropTypes.func,
      backToInput: PropTypes.func,
      options: PropTypes.shape({
        bases: PropTypes.array,
      }),
      values: PropTypes.any,
      fetchRole: PropTypes.func,
      state: PropTypes.shape({
        isSubmitting: PropTypes.bool,
      }),
    };
  }

  render() {
    const getName = () => {
      return `${this.props.values.familyName} ${this.props.values.firstName}`;
    };

    const getMail = () => {
      return this.props.values.mailAddress;
    };

    const getTel = () => {
      return `${this.props.values.tel1}-${this.props.values.tel2}-${this.props.values.tel3}`;
    };

    const getBase = () => {
      console.debug('base: ', this.props.options.bases);

      const bases = this.props.options.bases
        .filter((base) => base.value === this.props.values.baseId)
        .map((base) => base.name);

      if (bases.length > 0) {
        return bases[0];
      }

      return this.props.values.baseId;
    };

    const getProfileImageURL = () => {
      if (this.props.values.profileImageTempId) {
        return `${process.env.REACT_APP_API_URI}/static/tmp/${this.props.values.profileImageTempId}`;
      } else if (this.props.values.profileImageId) {
        return `${process.env.REACT_APP_API_URI}/${this.props.values.profileImgURL}`;
      }
    };

    return (
      <>
        <ReferItem label="名前">{getName()}</ReferItem>

        <ProfileImageReferComponent
          label="プロフィール画像"
          url={getProfileImageURL()}
        />
        <ReferItem label="メールアドレス">{getMail()}</ReferItem>
        <ReferItem label="電話番号">{getTel()}</ReferItem>
        <ReferItem label="拠点">{getBase()}</ReferItem>
        <hr />
        <Row>
          <div className="mx-auto">
            <PrevButton onClick={this.props.backToInput} />
            <RegisterButton
              onClick={this.props.onSubmit}
              isSubmitting={this.props.state.isSubmitting}
            />
          </div>
        </Row>
      </>
    );
  }
}

export default connect((state) => {
  return { state: state.adminProfile.adminProfileEdit, options: state.options };
})(AdminProfileConfirmForm);

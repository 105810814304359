const initialState = {
  resultList: [],
  areas: [],
  bases: [],
  staffs: [],
  methodTypes: [],
  getByIdResult: {},
  profile: {},
  displayInterviewId: null,
  editResult: false,
  errorMessage: null,
  deleteResult: null,
  isLoading: false,
  existNextMonth: true,
  checkedExistNextMonth: false,
  isShowModal: false,
  selectSlot: null,
  staff: [],
  editModal: {
    isLoading: false,
  },
  bulkInsert: {
    generalErrorMessages: [],
  },
};

const consultationPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_CONSULTATION_SEARCH': {
      const resultList = action.payload.result;
      return { ...state, resultList: resultList };
    }
    case 'FETCH_CONSULTATION_BY_ID': {
      const resultList = action.payload.result;

      console.debug('FETCH_CONSULTATION_BY_ID', resultList);
      return { ...state, getByIdResult: resultList };
    }
    case 'REGISTER_INTERVIEW': {
      return {
        ...state,
        editResult: true,
        isShowModal: false,
        checkedExistNextMonth: true,
      };
    }
    case 'FETCH_AREAS': {
      const resultList = action.payload.result;
      return { ...state, areas: resultList };
    }
    case 'FETCH_BASES': {
      const resultList = action.payload.result;
      return { ...state, bases: resultList };
    }
    case 'FETCH_STAFF': {
      const results = action.payload.result;
      return {
        ...state,
        staffs: results,
      };
    }
    case 'EDIT_INTERVIEW': {
      return {
        ...state,
        editResult: true,
        isShowModal: false,
        checkedExistNextMonth: true,
      };
    }
    case 'DELETE_INTERVIEW': {
      return { ...state, deleteResult: true, checkedExistNextMonth: true };
    }
    case 'FETCH_NEXT_MONTH_INTERVIEW': {
      const resultList = action.payload.result.interviewables;
      return {
        ...state,
        existNextMonth: resultList.length > 0,
        checkedExistNextMonth: false,
      };
    }
    case 'FETCH_METHOD_TYPE': {
      return {
        ...state,
        methodTypes: action.payload.result,
      };
    }
    case 'SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'FETCH_ERROR': {
      const resultList = action.payload.result;
      return {
        ...state,
        errorMessage: resultList,
      };
    }
    case 'interviews/SHOW_MODAL': {
      const { isNew, interviewId } = action.payload;

      let selectSlot;
      if (!isNew) {
        selectSlot = null;
      } else {
        selectSlot = state.selectSlot;
      }

      return {
        ...state,
        isShowModal: true,
        selectSlot,
        displayInterviewId: interviewId,
      };
    }
    case 'interviews/SET_SELECT_FROM': {
      return {
        ...state,
        getByIdResult: {},
        selectSlot: action.payload.result,
      };
    }
    case 'interviews/FETCH_PROFILE': {
      const result = action.payload.result;
      return {
        ...state,
        profile: result,
      };
    }
    case 'interviews/RESET_STATE': {
      return {
        ...state,
        getByIdResult: {},
        editResult: false,
        errorMessage: null,
        deleteResult: null,
        isLoading: false,
        existNextMonth: true,
        isShowModal: false,
        selectSlot: null,
      };
    }
    case 'interviews/FETCH_STAFF': {
      return {
        ...state,
        staff: action.payload.result,
      };
    }
    case 'interviews/CLEAR_SUGGESTION': {
      return {
        ...state,
        staff: initialState.staffs,
      };
    }
    case 'interviews/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'interview/FETCH_INTERVIEW_EDIT_ERROR': {
      const payload = action.payload;
      return {
        ...state,
        errorMessage: payload,
        isShowModal: true,
      };
    }
    case 'interviews/CLEAR_ERROR_MESSAGE': {
      return {
        ...state,
        errorMessage: {},
        bulkInsert: {
          ...state.bulkInsert,
          generalErrorMessages: [],
        },
      };
    }
    case 'interviews/SHOW_MODAL_LOADING': {
      return {
        ...state,
        editModal: {
          ...state.editModal,
          isLoading: true,
        },
      };
    }
    case 'interviews/HIDE_MODAL_LOADING': {
      return {
        ...state,
        editModal: {
          ...state.editModal,
          isLoading: false,
        },
      };
    }
    case 'interviews/INIT_BULK_INSERT': {
      return {
        ...state,
        bulkInsert: initialState.bulkInsert,
      };
    }
    case 'interviews/UPLOAD_BULK_INSERT_FILE_SUCCESS': {
      const data = action.payload;
      return {
        ...state,
        bulkInsert: {
          ...state.bulkInsert,
          tempFileId: data.id,
          originalFileName: data.originalFileName,
        },
      };
    }
    case 'interviews/BULK_INSERT_EXEC_SUCCESS': {
      return {
        ...state,
        bulkInsert: {
          ...state.bulkInsert,
          isShowExecutedDialog: true,
        },
      };
    }
    case 'interviews/BAD_REQUEST_BULK_UPDATE': {
      const result = action.payload.result;

      return {
        ...state,
        bulkInsert: {
          ...state.bulkInsert,
          generalErrorMessages: result.messages.general,
        },
      };
    }
    case 'interviews/FETCH_IS_DEFAULT_BASE_AND_OCCUPATION_SUCCESS': {
      return {
        ...state,
        editModal: {
          ...state.editModal,
          isDefaultSettingBase: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export { consultationPlanReducer as default };

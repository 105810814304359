import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Card from '../../organisms/Card';
import Form from '../../organisms/events/EventsBulkInsert';
import Confirm from '../../organisms/events/EventsBulkInsertConfirm';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';

import routePath from './routePath';

class EventsBulkUpdate extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        eventsBulkInsert: PropTypes.shape({
          generalErrorMessages: PropTypes.array,
          tempFileId: PropTypes.string,
          originalFileName: PropTypes.string,
          isShowExecutedDialog: PropTypes.bool,
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      hideExecutedDialog: PropTypes.func,
      uploadBulkInsertFile: PropTypes.func,
      BulkInsertExec: PropTypes.func,
      initBulkUpdate: PropTypes.func,
      clearErrorMessage: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.state = { file: null, fileName: '', isConfirm: false, values: null };

    this.onClickCloseButton = this.onClickCloseButton.bind(this);
    this.onClickFileButton = this.onClickFileButton.bind(this);
    this.onClickConfirmButton = this.onClickConfirmButton.bind(this);
    this.onClickExecButton = this.onClickExecButton.bind(this);
    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.isClickableConfirmButton = this.isClickableConfirmButton.bind(this);
    this.onClickPrevConfirmButton = this.onClickPrevConfirmButton.bind(this);
  }

  componentDidMount() {
    this.props.initBulkUpdate();
  }

  isClickableConfirmButton() {
    const file = this.state.file;
    return file != null;
  }

  onClickCloseButton() {
    this.props.history.push(routePath.search);
  }

  onClickFileButton(e) {
    console.debug('onclickFileButton');

    const target = e.target;
    const file = target.files.item(0);
    console.debug('handleChangefile', target);
    console.debug(target.value);
    console.debug('file', file);

    this.setState({ file: file, fileName: file.name });
  }

  onClickConfirmButton(e) {
    const file = this.state.file;
    console.debug('file', file);
    this.props.uploadBulkInsertFile(file);
    this.setState({ isConfirm: true });
  }

  transitionToSearch() {
    this.props.history.push(routePath.search);
  }

  onClickPrevConfirmButton() {
    this.setState({ isConfirm: false });
    this.props.clearErrorMessage();
  }

  onClickExecButton(e) {
    this.props.BulkInsertExec(
      this.props.state.eventsBulkInsert.tempFileId,
      this.props.state.eventsBulkInsert.originalFileName
    );
  }

  render() {
    const isConfirm = () => {
      return this.state.isConfirm;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>イベント管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="一括登録">
                  {this.props.state.eventsBulkInsert.generalErrorMessages !=
                    null &&
                  this.props.state.eventsBulkInsert.generalErrorMessages
                    .length > 0 ? (
                    <div className="alert alert-danger">
                      {this.props.state.eventsBulkInsert.generalErrorMessages.map(
                        (message, i) => (
                          <p key={i}>{message}</p>
                        )
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {isConfirm() ? (
                    <Confirm
                      history={this.props.history}
                      fileName={this.state.fileName}
                      file={this.state.file}
                      onClickExecutionButton={this.onClickExecButton}
                      onClickPrevButton={this.onClickPrevConfirmButton}
                    />
                  ) : (
                    <Form
                      onClickFileButton={this.onClickFileButton}
                      onClickConfirmButton={this.onClickConfirmButton}
                      isClickableConfirmButton={this.isClickableConfirmButton}
                      fileName={this.state.fileName}
                      history={this.props.history}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <RegisterCompleteModal
          show={this.props.state.eventsBulkInsert.isShowExecutedDialog}
          onCloseClick={this.onClickCloseButton}
        />
      </>
    );
  }
}

export default EventsBulkUpdate;

import PropTypes, { any, func, shape, string } from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Form, getFormValues, reduxForm } from 'redux-form';

import {
  addIntervieweeParticipant,
  deleteIntervieweeParticipant,
  makeFetchUserProfile,
  makeUpdateInterviewsEditDetail,
  makeFetchCompaniesOptions,
} from '../../../actions/ReservationManagement';
import ErrorMessageBox from '../../atoms/ErrorMessageBox';
import { VERTICAL } from '../../atoms/FormGroupRow';
import PrimaryFormButton from '../../atoms/PrimaryFormButton';
import CancelButton from '../../molecules/CancelButton';
import FormReferItem from '../../molecules/FormReferItem';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import MarginFormComponent from '../../molecules/MarginFormComponent';
import Card from '../../organisms/Card';
import BackConfirmModal from '../../organisms/reservationManagement/BackConfirmModal';
import CompleteModal from '../../organisms/reservationManagement/CompleteModal';
import EventsEditParticipants from '../../organisms/reservationManagement/EventsEditParticipants';
import SearchUserModal from '../../organisms/reservationManagement/SearchUserModal';
import SubstituteAlert from '../../organisms/reservationManagement/SubstituteAlert';
import {
  ReservationFormatParam,
  toReservationFormat,
} from '../../utils/DateFormat';
import { EditAction } from '../../utils/PermissionComponent';

import { TabParam } from './ReservationManagementSearch';
import routePath from './routePath';

class ReservationManagementInterviewEdit extends React.Component {
  static get propTypes() {
    return {
      location: shape({
        search: any,
      }),
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      state: PropTypes.shape({
        searchValues: PropTypes.any,
        reservationManagementInterviewEdit: {
          isProxyBooking: PropTypes.bool,
          isShowParticipantsSearchModal: PropTypes.bool,
          interview: PropTypes.array,
        },
        reservationManagementInterview: PropTypes.shape({
          selectedDate: PropTypes.Date,
        }),
        reservationManagementUsers: PropTypes.shape({
          users: PropTypes.shape({
            result: PropTypes.array,
          }),
        }),
        formParticipant: PropTypes.array,
        isComplete: PropTypes.bool,
        substituteUser: shape({
          id: string,
        }),
        originalInterviewees: {
          id: PropTypes.string,
          name: PropTypes.string,
        },
        deleteInterviewees: {
          id: PropTypes.string,
          name: PropTypes.string,
        },
      }),
      options: PropTypes.shape({
        companyNames: PropTypes.array,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      handleSubmit: PropTypes.func,

      fetchReservationInterviewsDetail: PropTypes.func,
      updateInterviewsEditDetail: PropTypes.func,
      fetchReservationInterviewsEditDetail: PropTypes.func,
      addIntervieweeParticipant: PropTypes.func,
      setInterviewInterviewees: PropTypes.func,
      deleteIntervieweeParticipant: PropTypes.func,
      searchUsers: PropTypes.func,
      fetchReservationInterviews: PropTypes.func,
      clearSubstituteInterviewEdit: func,
      fetchCompaniesOptions: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    console.debug('ReservationManagementInterviewEdit props', props);
    this.state = {
      id: null,
      userId: '',
      userName: '',
      isSearchUsersModal: false,
      isConfirmModal: false,
      deleteInterviewees: [],
      originalInterviewees: null,
    };
    this.onHideSearchUserModal = this.onHideSearchUserModal.bind(this);
    this.deleteParticipants = this.deleteParticipants.bind(this);
    this.addParticipants = this.addParticipants.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.releaseSubstituteUser = this.releaseSubstituteUser.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;
    const urlParams = new URLSearchParams(this.props.location.search);
    const userId = urlParams.get('userId');
    this.setState({ id: this.id, userId: userId });

    this.props.fetchReservationInterviewsEditDetail(this.id, userId);
    this.props.fetchCompaniesOptions();
  }

  deleteParticipants(id) {
    console.log('****deleteParticipants****');
    const participants = this.props.state.reservationManagementInterviewEdit
      .interviewees;

    if (this.state.originalInterviewees == null) {
      this.setState({ originalInterviewees: participants });
    }

    const newParticipants = participants.filter((p) => p.id !== id);

    const deleteParticipant = participants.filter((p) => p.id === id);
    const stateDeleteInterviewees = this.state.deleteInterviewees;
    deleteParticipant.forEach((participant) => {
      stateDeleteInterviewees.push(participant);
    });

    // 重複削除
    const deleteInterviewees = Array.from(
      new Map(
        stateDeleteInterviewees.map((interviewee) => [
          interviewee.id,
          interviewee,
        ])
      ).values()
    );

    this.setState({
      deleteInterviewees: deleteInterviewees,
    });

    this.props.setInterviewInterviewees({
      newParticipants: newParticipants,
      deleteParticipants: deleteInterviewees,
    });
  }

  addParticipants(id, name) {
    const participants = this.props.state.reservationManagementInterviewEdit
      .interviewees;

    if (this.state.originalInterviewees == null) {
      this.setState({ originalInterviewees: participants });
    }

    const alreadyExists = participants.some(
      (participant) => participant.id === id
    );

    if (alreadyExists) {
      return;
    }

    const newParticipants = participants.concat([{ id: id, name: name }]);
    this.props.setInterviewInterviewees({ newParticipants: newParticipants });

    this.onHideSearchUserModal();
  }

  onClickAddButton() {
    this.setState({ isSearchUsersModal: true });
  }

  searchUsers(values) {
    if (
      !Object.prototype.hasOwnProperty.call(values, 'pageSize') ||
      !Object.prototype.hasOwnProperty.call(values, 'page')
    ) {
      values = {
        ...values,
        pageSize: 10,
        page: 1,
      };
    }
    this.props.searchUsers(values);
    return false;
  }

  onClickCancel() {
    this.setState({ isConfirmModal: true });
  }

  onClickConfirmModalYes() {
    let searchValues = {};
    if (
      this.props.state.searchValues &&
      Object.keys(this.props.state.searchValues).length > 0
    ) {
      searchValues = this.props.state.searchValues;
    }
    this.props.fetchReservationInterviews(searchValues);
    const selectedDate = this.props.state.reservationManagementInterview
      .selectedDate;
    this.props.fetchReservationInterviewsDetail(this.id, selectedDate);

    const userId = this.state.userId;

    this.props.history.push(
      routePath.searchWithTabParam(userId || '')(TabParam.Interview.toString())
    );
  }

  onClickConfirmModalNo() {
    this.setState({ isConfirmModal: false });
  }

  onHideSearchUserModal() {
    this.setState({ isSearchUsersModal: false });
  }

  onSubmit(values) {
    const interviewees = this.props.state.reservationManagementInterviewEdit
      .interviewees;

    let deleteInterviewees = this.state.deleteInterviewees;
    // 元データと比較して削除された会員を抽出
    const original = this.state.originalInterviewees;
    if (original != null) {
      const originalIds = [];
      original.forEach((item) => originalIds.push(item.id));
      deleteInterviewees = deleteInterviewees.filter((interviewee) => {
        return originalIds.includes(interviewee.id);
      });
    }
    // 登録者リストとの重複削除
    if (interviewees.length > 0) {
      const intervieweeIds = [];
      interviewees.forEach((item) => intervieweeIds.push(item.id));
      deleteInterviewees = deleteInterviewees.filter((interviewee) => {
        return !intervieweeIds.includes(interviewee.id);
      });
    }

    const url = values.url;
    if (interviewees.length < 0) {
      return;
    }
    // 登録処理
    this.props.updateInterviewsEditDetail(this.id, {
      interviewees,
      deleteInterviewees,
      url,
    });
  }

  handleSubmit() {
    return this.props.handleSubmit(this.onSubmit);
  }

  releaseSubstituteUser() {
    this.props.clearSubstituteInterviewEdit(this.state.userId);
    this.setState({ userId: null });

    const path = routePath.interviewEdit(this.id);
    this.props.history.push(path);
  }

  render() {
    const getInterviewDateTime = () => {
      const interview = this.props.state.reservationManagementInterviewEdit
        .interview;

      if (interview && Object.keys(interview).length > 0) {
        const param = new ReservationFormatParam(
          interview.date,
          interview.fromHour,
          interview.fromMinute,
          interview.toHour,
          interview.toMinute
        );

        return toReservationFormat(param);
      }
      return '';
    };

    const getMaxOfSeat = () => {
      return this.props.state.reservationManagementInterviewEdit.interview
        .seatAvailability;
    };

    const getSearchResult = () => {
      return this.props.state.reservationManagementUsers.users.result;
    };

    const isOverParticipants = () => {
      const participantsLength = this.props.state
        .reservationManagementInterviewEdit.interviewees.length;

      const maxOfSeat = getMaxOfSeat();

      return !(maxOfSeat < participantsLength);
    };

    return (
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <Card headerTitle="予約管理（面談）">
            <Form onSubmit={this.handleSubmit()}>
              <MarginFormComponent>
                {this.props.state.reservationManagementInterviewEdit &&
                this.props.state.reservationManagementInterviewEdit
                  .errorMessage ? (
                  <Col sm={{ offset: 1, span: 8 }}>
                    <ErrorMessageBox
                      errorMessages={[
                        this.props.state.reservationManagementInterviewEdit
                          .errorMessage,
                      ]}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                {this.props.state.substituteUser ? (
                  <SubstituteAlert
                    substituteUser={this.props.state.substituteUser}
                    onClickReleaseButton={this.releaseSubstituteUser}
                  />
                ) : (
                  <></>
                )}
                <FormReferItem label="予定種別">個別面談</FormReferItem>
                <FormReferItem label="開催日時">
                  {getInterviewDateTime()}
                </FormReferItem>

                {this.props.state.reservationManagementInterviewEdit.interview
                  .isWebMethodType ? (
                  <HorizontalFormInputComponent
                    label="WEB面談用URL"
                    name="url"
                    component="input"
                    type="text"
                  />
                ) : (
                  <></>
                )}

                <FormReferItem label="面談者" labelVertical={VERTICAL.TOP}>
                  <EventsEditParticipants
                    list={
                      this.props.state.reservationManagementInterviewEdit
                        .interviewees == null
                        ? []
                        : this.props.state.reservationManagementInterviewEdit
                            .interviewees
                    }
                    maxOfSeat={getMaxOfSeat()}
                    onDeleteClick={this.deleteParticipants}
                    onAddClick={this.onClickAddButton.bind(this)}
                    substituteUser={this.props.state.substituteUser}
                  />
                </FormReferItem>
                <hr />

                <Row>
                  <div className="mx-auto">
                    <CancelButton onClick={this.onClickCancel.bind(this)}>
                      キャンセル
                    </CancelButton>
                    <EditAction>
                      <PrimaryFormButton isEnable={isOverParticipants}>
                        登録
                      </PrimaryFormButton>
                    </EditAction>
                  </div>
                </Row>
                <BackConfirmModal
                  show={this.state.isConfirmModal}
                  onClickYes={this.onClickConfirmModalYes.bind(this)}
                  onClickNo={this.onClickConfirmModalNo.bind(this)}
                />
                <CompleteModal
                  show={this.props.state.isComplete}
                  onClick={this.onClickConfirmModalYes.bind(this)}
                />
              </MarginFormComponent>
            </Form>
            <SearchUserModal
              show={this.state.isSearchUsersModal}
              onClickSelectButton={this.addParticipants}
              onSubmit={this.searchUsers.bind(this)}
              searchResult={getSearchResult()}
              onHide={this.onHideSearchUserModal}
              companies={this.props.options.companyNames}
            />
          </Card>
        </div>
      </div>
    );
  }
}

const reservationManagementInterviewEdit = reduxForm({
  // a unique name for the form
  form: 'reservationManagementInterviewEdit',
  enableReinitialize: true,
})(ReservationManagementInterviewEdit);

export default connect(
  (state) => {
    console.log('STATE');
    console.log('connect ', state);
    const interview =
      state.reservationManagement.reservationManagementInterviewEdit.interview;

    const values = getFormValues('reservationManagementInterviewEdit')(state);
    console.debug('connect formvalues', values);
    const formParticipant =
      values && values.participant ? values.participant : null;

    let participant;
    if (interview.intervieweeId && interview.interviewees) {
      participant = {
        userName: interview.interviewee,
        userId: interview.intervieweeId,
      };
    } else if (state.reservationManagement.substituteUser != null) {
      participant = {
        userName: state.reservationManagement.substituteUser.name,
        userId: state.reservationManagement.substituteUser.id,
      };
    }

    return {
      initialValues: {
        participant: participant,
        url: interview.webInterviewUrl,
      },
      state: {
        ...state.reservationManagement,
        formParticipant,
      },
      options: {
        companyNames: state.options.companyNames,
      },
    };
  },
  (dispatch) => {
    return {
      updateInterviewsEditDetail(id, values) {
        dispatch(makeUpdateInterviewsEditDetail(id, values));
      },
      deleteIntervieweeParticipant() {
        dispatch(deleteIntervieweeParticipant());
      },
      addIntervieweeParticipant(id, name) {
        dispatch(addIntervieweeParticipant(id, name));
      },
      fetchUserProfile(id) {
        dispatch(makeFetchUserProfile(id));
      },
      fetchCompaniesOptions() {
        dispatch(makeFetchCompaniesOptions());
      },
    };
  }
)(reservationManagementInterviewEdit);

import { connectRouter, routerMiddleware } from 'connected-react-router';
import {
  createStore as reduxCreateStore,
  applyMiddleware,
  combineReducers,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as reduxFormReducer } from 'redux-form';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import Action, { EmptyAction } from './components/model/Action';
import history from './history';
import adminPasswordResetReducer from './reducers/AdminPasswordReset';
import adminProfileReducer from './reducers/AdminProfile';
import baseReducer from './reducers/Base';
import categoryReducer from './reducers/Category';
import companyReducer from './reducers/Company';
import consultationPlanReducer from './reducers/ConsultationPlan';
import eventsReducer from './reducers/Events';
import formManagementReducer from './reducers/FormManagement';
import loginReducer from './reducers/Login';
import NavBarReducer from './reducers/NavBar';
import optionsReducer from './reducers/Options';
import reservationManagementReducer from './reducers/ReservationManagement';
import roleManagementReducer from './reducers/RoleManagement';
import todoReducer from './reducers/Sample';
import settingPasswordReducer from './reducers/SettingPassword';
import sidebarReducer from './reducers/SideBar';
import staffReducer from './reducers/Staff';
import summaryReducer from './reducers/Summary';
import tagReducer from './reducers/Tag';
import templateReducer from './reducers/Template';
import userReducer from './reducers/User';
import venueReducer from './reducers/Venue';

const flattenActionMiddleware = (store) => (next) => (action) => {
  console.debug('flatten action before', action);
  if (action instanceof Action || action instanceof EmptyAction) {
    console.debug('flatten action getObject', action.getObject());
    next(action.getObject());
    return;
  }
  next(action);
};

let middleware;
if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(
    routerMiddleware(history),
    thunk,
    flattenActionMiddleware
  );
} else {
  middleware = composeWithDevTools(
    applyMiddleware(
      logger,
      routerMiddleware(history),
      thunk,
      flattenActionMiddleware
    )
  );
}

export default function createStore() {
  const store = reduxCreateStore(
    combineReducers({
      sample: todoReducer,
      base: baseReducer,
      adminProfile: adminProfileReducer,
      staff: staffReducer,
      template: templateReducer,
      roleManagement: roleManagementReducer,
      adminPasswordReset: adminPasswordResetReducer,
      summary: summaryReducer,
      form: reduxFormReducer,
      options: optionsReducer,
      venue: venueReducer,
      login: loginReducer,
      reservationManagement: reservationManagementReducer,
      events: eventsReducer,
      user: userReducer,
      formManagement: formManagementReducer,
      consultationPlan: consultationPlanReducer,
      router: connectRouter(history),
      navbar: NavBarReducer,
      sidebar: sidebarReducer,
      updatePassword: settingPasswordReducer,
      tag: tagReducer,
      company: companyReducer,
      category: categoryReducer,
    }),
    middleware
  );

  return store;
}

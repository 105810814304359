import { TagSearch, TagRefer, TagEdit } from '../../../containers/Tag';

import routePath from './routePath';

const TagRoutes = [
  {
    path: routePath.search,
    exact: true,
    component: TagSearch,
  },
  {
    path: routePath.refer(':id'),
    component: TagRefer,
  },
  {
    path: routePath.newEdit,
    component: TagEdit,
    exact: true,
  },
  {
    path: routePath.edit(':id'),
    component: TagEdit,
  },
];

export default TagRoutes;

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';

import Card from '../../organisms/Card';
import EditTemplate from '../../templates/FormManagementSearchForm/Edit';
import routePath from "./routePath";

class FormManagementEdit extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        searchResults: PropTypes.shape({
          searchResult: PropTypes.array,
          referResult: PropTypes.object,
          parts: PropTypes.array,
          messageTemplates: PropTypes.array,
          deleteResult:PropTypes.bool,
          test: PropTypes.object,
          error: PropTypes.string,
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.clickPrevButton = this.clickPrevButton.bind(this);
    this.clickSubmit = this.clickSubmit.bind(this);
    this.setFormTemplate = this.setFormTemplate.bind(this);
    this.transitionToSearch = this.transitionToSearch.bind(this);

    const queryId = queryString.parse(this.props.location.search).id
    const { params } = this.props.match;
    this.id = params.id;

    this.state = {
      editId: this.id
    };

    this.props.resetReferResult();
    if (this.id != null) {
      this.props.makeFetchAndSetFormById(this.id);
    } else if ( queryId != null) {
      this.props.makeFetchAndSetFormById(queryId);
    }
  }

  search(values) {
    this.setState({ values: values });
    console.log('do search');
  }

  clickSubmit(values) {
    if (this.state.editId != null) {
      values = {
        ...values,
        id: this.state.editId,
      }
    }
    this.props.editForm(values);
  }

  clickPrevButton() {
    console.debug('transition refer');
    this.props.history.push(`/admin/form/`);
  }

  setFormTemplate(values) {
    const parts = [];

    // エリアパーツの追加
    if (values.default_area != null) {
      values.default_area.forEach( defaultArea => {
        if (defaultArea !== "") {
          parts.push({type: defaultArea, defaultFlag: true});
        }
      });
    }

    // イベントパーツの追加
    if (values.reservation_item != null) {
      values.reservation_item.forEach( reservationItem => {
        if (reservationItem !== "") {
          parts.push({type: reservationItem, defaultFlag: true});
        }
      });
    }

    const template = {
      sections: [{
        sectionName:"",
        parts: parts,
        sectionType: 1,
      }],
      actions:[{type:"2", parts:[{type: "7", defaultFlag: true}], defaultFlag: true}],
      notifications:[{messageTemplateId: 1, defaultFlag: true}],
      sectionType: 1,
    };

    this.props.setFormTemplate(template);
  }

  transitionToSearch() {
    this.props.history.push(routePath.search);
  }

  render() {
    if (this.props.state.updateSuccess) {
      this.props.history.push('/admin/form/');
    }

    return (
      <section className="section">
        <div className="section-header">
          <h1>フォーム管理</h1>
        </div>

        <div className="section-body">
          <Row>
            <Col>
              <Card headerTitle="フォーム管理登録/編集">
                {this.props.state.isLoading ?
                  <></>
                  :
                  <EditTemplate
                    editId={this.id}
                    prevFunction={this.clickPrevButton}
                    edit={this.clickSubmit}
                    setFormTemplate={this.setFormTemplate}
                    referResult={this.props.state.test}
                    transitionToSearch={this.transitionToSearch}
                    error={this.props.state.error}
                  />
                }
              </Card>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default FormManagementEdit;

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Col, Modal, ModalBody, Row } from 'react-bootstrap';

import DangerButton from '../../atoms/DangerButton';
import ModalFooter from '../../atoms/ModalFooter';
import Option from '../../model/Option';
import CloseButton from '../../molecules/CloseButton';
import InterviewCalendar from '../../molecules/interview/Calendar';
import LabelErrorComponent from '../../molecules/LabelErrorComponent';
import LeggendComponent from '../../molecules/LeggendComponent';
import Card from '../../organisms/Card';
import CalendarToolbar from '../../organisms/consultationPlan/CalendarToolbar';
import ChangeStaffConfirmModal, {
  Param as ChangeStaffConfirmModalParam,
} from '../../organisms/consultationPlan/ChangeStaffConfirmModal';
import Form from '../../organisms/consultationPlan/ConsultationPlanForm';
import ConsultationPlanModal from '../../organisms/consultationPlan/ConsultationPlanModal';
import StaffSearchModal from '../../organisms/StaffSearchModal';
import routePath from '../../pages/consultationPlan/routePath';
import { AllowOwner, EditAction, wrap } from '../../utils/PermissionComponent';

const localizer = momentLocalizer(moment);

const PermissionCalendar = wrap(({ isEnable, ...props }) => {
  return <InterviewCalendar selectable={isEnable()} {...props} />;
});

class Search extends React.Component {
  static get propTypes() {
    return {
      profile: PropTypes.shape({
        id: PropTypes.string,
        familyName: PropTypes.string,
        firstName: PropTypes.string,
      }),
      resultList: PropTypes.array,
      searchData: PropTypes.func,
      sysAdminRadioValue: PropTypes.array,
      resultDatas: PropTypes.array,
      searchFunction: PropTypes.func,
      fetchById: PropTypes.func,
      fetchByIdResult: PropTypes.object,
      editInterview: PropTypes.func,
      deleteFunction: PropTypes.func,
      searchParams: PropTypes.object,
      staffs: PropTypes.array,
      areas: PropTypes.array,
      bases: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
      showModalFunction: PropTypes.func,
      comeFromOtherPageFlag: PropTypes.bool,
      goBack: PropTypes.func,
      resetFunction: PropTypes.func,
      registFlag: PropTypes.bool,
      setSelectFrom: PropTypes.func,
      changeInterviewer: PropTypes.func,
      selectedStaffName: PropTypes.string,
      existNextMonth: PropTypes.bool,
      changeStaff: PropTypes.func,
      isShowModal: PropTypes.bool,
      editFlag: PropTypes.bool,
      errorMessage: PropTypes.object,
      clearErrorMessage: PropTypes.func,
      fetchStaffForSuggestion: PropTypes.func,
      clearSuggestion: PropTypes.func,
      staffSuggestions: PropTypes.func,
      interviewId: PropTypes.string,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.search = this.search.bind(this);
    this.createList = this.createList.bind(this);
    this.detailModal = this.detailModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.editInterview = this.editInterview.bind(this);
    this.delete = this.delete.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.changeEditModal = this.changeEditModal.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.showEditInterviewerModal = this.showEditInterviewerModal.bind(this);
    this.updateStaffId = this.updateStaffId.bind(this);
    this.backToReferModal = this.backToReferModal.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.cancelChangeStaff = this.cancelChangeStaff.bind(this);
    this.bulkRegisterButtonClick = this.bulkRegisterButtonClick.bind(this);

    const detailModal = { showFlag: false, isEdit: false };
    if (this.props.comeFromOtherPageFlag) {
      detailModal.id = this.props.interviewId;
    }

    this.state = {
      detailModal: detailModal,
      showDeleteModalFlag: false,
      isEditInterviewer: false,
      editStaffId: null,
      isDisplayChangeStaffConfirmModal: false,
      selectedSuggestion: {},
    };
  }

  search(date) {
    this.props.searchFunction(
      {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        date: date.getDate(),
        day: date.getDay(),
      },
      {}
    );
  }

  createList() {
    const list = [];

    if (!this.props.resultList.length) {
      return [];
    }

    this.props.resultList.map((data, index) => {
      const year = Number(data.date.substr(0, 4));
      const month = Number(data.date.substr(5, 2)) - 1;
      const date = Number(data.date.substr(8, 2));

      const isReserved =
        data.interviewees != null && data.interviewees.length > 0;

      const names = [];
      if (isReserved) {
        data.interviewees.forEach(function (interviewee) {
          names.push(interviewee.name);
        });
      }

      list.push({
        id: data.id,
        title: isReserved && names.length > 0 ? names.join(',') : '',
        allDay: false,
        start: new Date(
          year,
          month,
          date,
          Number(data.fromHour),
          Number(data.fromMinute)
        ),
        end: new Date(
          year,
          month,
          date,
          Number(data.toHour),
          Number(data.toMinute)
        ),
        color: isReserved ? 'green' : 'blue',
      });
    });
    return list;
  }

  detailModal(id, title, start) {
    this.setState({
      detailModal: {
        newInterviewStart: start,
        title: title,
        id: id,
        isEdit: false,
        showFlag: true,
      },
      showDeleteModalFlag: false,
      isEditInterviewer: false,
    });
    this.props.fetchById(id);
    this.props.showModalFunction(false, id);
  }

  closeModal() {
    if (this.props.comeFromOtherPageFlag) {
      this.props.goBack();
    } else {
      this.closeDetailModal();
    }
  }

  closeDetailModal() {
    this.setState({
      detailModal: {
        showFlag: false,
        isEdit: false,
      },
    });
    this.props.resetFunction();
  }

  editInterview(isNew, values) {
    const date = new Date(this.state.detailModal.newInterviewStart);
    const monthStr = ('00' + (date.getMonth() + 1)).slice(-2);
    const dateStr = ('00' + date.getDate()).slice(-2);
    values.date = date.getFullYear() + monthStr + dateStr;

    if (!isNew) {
      values.id = this.props.fetchByIdResult.id;
    }

    this.props.editInterview(values);
  }

  delete(id) {
    this.props.deleteFunction(id);
    this.setState({
      detailModal: {
        showFlag: false,
      },
      showDeleteModalFlag: false,
    });
    this.props.resetFunction();
  }

  handleSelect(slotInfo) {
    if (this.props.registFlag) {
      this.setState({
        detailModal: {
          showFlag: true,
          isEdit: true,
          id: null,
          newInterviewStart: slotInfo.start,
        },
      });

      this.props.setSelectFrom(slotInfo);
      this.props.showModalFunction(true);
    }
  }

  changeEditModal(id) {
    this.setState({
      detailModal: {
        showFlag: true,
        isEdit: true,
        id: id,
        newInterviewStart: this.state.detailModal.newInterviewStart,
      },
    });
    this.props.clearErrorMessage();
  }

  backToReferModal(isNew) {
    this.props.clearErrorMessage();
    if (!isNew) {
      this.setState({
        detailModal: {
          ...this.state.detailModal,
          showFlag: true,
          isEdit: false,
        },
      });
    } else {
      this.closeModal();
    }
  }

  showDeleteModal(hasInterviewee) {
    this.closeModal();
    if (hasInterviewee) {
      this.props.fetchById(this.state.detailModal.id);
    }

    this.setState({
      ...this.state,
      hasInterviewee: hasInterviewee,
      showDeleteModalFlag: true,
    });
  }

  showEditInterviewerModal() {
    this.closeDetailModal();
    this.props.fetchById(this.state.detailModal.id);
    this.setState({
      ...this.state,
      isEditInterviewer: true,
    });
  }

  onSuggestionSelected(suggestion) {
    this.setState({
      selectedSuggestion: suggestion,
      isEditInterviewer: false,
      isDisplayChangeStaffConfirmModal: true,
    });
  }

  cancelChangeStaff() {
    this.setState({
      isDisplayChangeStaffConfirmModal: false,
      selectedSuggestion: {},
      isEditInterviewer: true,
    });
  }

  updateStaffId() {
    const id = this.state.selectedSuggestion.value;
    const param = {
      id: this.props.fetchByIdResult.id,
      staffId: id,
    };

    this.props.changeInterviewer(param);
    this.setState({
      isDisplayChangeStaffConfirmModal: false,
    });
  }

  bulkRegisterButtonClick() {
    this.props.history.push(routePath.bulkRegister);
  }

  render() {
    const darkSeaGreen = 'darkseagreen';
    const deepSkyBlue = 'deepSkyBlue';

    const leggends = [
      {
        label: 'マッチング済',
        color: darkSeaGreen,
      },
      {
        label: '未マッチング',
        color: deepSkyBlue,
      },
    ];

    const currentSelectedStaffName = () => {
      if (this.props.comeFromOtherPageFlag) {
        return this.props.fetchByIdResult.interviewer || '';
      }

      if (this.props.searchParams.staffName) {
        return this.props.searchParams.staffName;
      }
      if (this.props.profile) {
        return `${this.props.profile.familyName} ${this.props.profile.firstName}`;
      }

      return '';
    };

    const currentSelectedStaffId = () => {
      if (this.props.searchParams.staffId) {
        return this.props.searchParams.staffId;
      }
      if (this.props.profile) {
        return this.props.profile.id;
      }

      return '';
    };

    const interviewees = this.props.fetchByIdResult.interviewees;

    const names = [];
    if (interviewees != null && interviewees.length > 0) {
      interviewees.forEach(function (interviewee) {
        names.push(interviewee.name);
      });
    }

    return (
      <>
        <Row>
          <Col>
            <Card
              headerTitle={currentSelectedStaffName() + 'さんの面談予定表示中'}
            >
              {this.props.existNextMonth ? (
                <></>
              ) : (
                <LabelErrorComponent title={'翌月の予定の入力がありません。'} />
              )}
              <Form
                onSubmit={this.clickSearchButton}
                suggestStaff={this.suggestStaff}
                changeStaff={this.props.changeStaff}
                bulkRegisterButtonClick={this.bulkRegisterButtonClick.bind(
                  this
                )}
                currentSelectedStaffId={currentSelectedStaffId()}
              />
              <LeggendComponent leggends={leggends} />
              <AllowOwner dataOwnerId={currentSelectedStaffId()}>
                <EditAction>
                  <PermissionCalendar
                    highlightWeekend
                    localizer={localizer}
                    events={this.createList()}
                    timeslots={2}
                    components={{ toolbar: CalendarToolbar }}
                    test={this.test}
                    defaultView={Views.WEEK}
                    onSelectEvent={(event) =>
                      this.detailModal(event.id, event.title, event.start)
                    }
                    onSelectSlot={(slotInfo) => this.handleSelect(slotInfo)}
                    style={{ height: 800 }}
                    eventPropGetter={(event) => ({
                      style: {
                        backgroundColor: event.color,
                        border: '1px black',
                        borderStyle: 'solid',
                      },
                    })}
                    onNavigate={(date) => {
                      console.debug('onNavigate');
                      this.search(date);
                    }}
                  />
                </EditAction>
              </AllowOwner>
            </Card>
          </Col>
        </Row>
        <ConsultationPlanModal
          show={this.props.isShowModal}
          title={this.state.detailModal.title}
          data={this.props.fetchByIdResult}
          editFlag={this.props.editFlag}
          onCloseClick={this.closeModal}
          editInterviewFunction={this.editInterview}
          deleteFunction={this.delete}
          isEdit={this.state.detailModal.isEdit}
          changeEditModalFunction={this.changeEditModal}
          backToReferModal={this.backToReferModal}
          newInterviewStart={this.state.detailModal.newInterviewStart}
          showDeleteModal={this.showDeleteModal}
          showEditInterviewerModal={this.showEditInterviewerModal}
          areas={this.props.areas}
          bases={this.props.bases}
          staffs={this.props.staffs}
          errorMessage={this.props.errorMessage}
          scrollToTime={new Date()}
          currentSelectedStaffId={currentSelectedStaffId()}
        />
        <Modal show={this.state.showDeleteModalFlag}>
          <ModalBody>
            {this.state.hasInterviewee
              ? `面談予約者(${names.join(',')}
            さん)がいます。`
              : ''}
            削除してもよろしいですか？
          </ModalBody>
          <ModalFooter>
            <CloseButton
              onClick={() => this.detailModal(this.state.detailModal.id, '')}
            />
            <DangerButton
              onClick={() => this.delete(this.state.detailModal.id)}
            >
              削除する
            </DangerButton>
          </ModalFooter>
        </Modal>
        <StaffSearchModal
          show={this.state.isEditInterviewer}
          onCloseButtonClick={() =>
            this.detailModal(this.state.detailModal.id, '')
          }
          onSelected={this.onSuggestionSelected}
          fetchStaff={this.props.fetchStaffForSuggestion.bind(
            this,
            currentSelectedStaffId()
          )}
          clearSuggestion={this.props.clearSuggestion}
          suggestions={this.props.staffSuggestions}
        />
        <ChangeStaffConfirmModal
          show={this.state.isDisplayChangeStaffConfirmModal}
          {...new ChangeStaffConfirmModalParam(
            this.state.selectedSuggestion.name,
            this.cancelChangeStaff,
            this.updateStaffId
          ).toObject()}
        />
      </>
    );
  }
}

export default Search;

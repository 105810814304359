import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLastLocation } from 'react-router-last-location';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';

import constants from '../../../constants';
import SalesForceButton from '../../atoms/SalesForceButton';
import DetailButton from '../../molecules/DetailButton';
import Card from '../../organisms/Card';
import Pager from '../../organisms/Pager';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import SearchResultTable from '../../organisms/SearchResultTable';
import AccountUnlockButton from '../../organisms/staff/AccountUnlockButton';
import ContactForm from '../../organisms/user/UserSearchContactForm';
import MysqlForm from '../../organisms/user/UserSearchForm';
import LeadForm from '../../organisms/user/UserSearchLeadForm';
import SearchComponent from '../common/SearchComponent';

class UserSearch extends React.Component {
  static get propTypes() {
    const paginationShape = PropTypes.shape({
      pagination: PropTypes.shape({ pageSize: PropTypes.number }),
    });

    return {
      lastLocation: PropTypes.shape({
        pathname: PropTypes.string,
      }),
      children: PropTypes.any,
      state: PropTypes.shape({
        search: {
          isDisplayUnlockCompleteModal: PropTypes.bool,
        },
        // 検索結果(B.人(取責))
        searchContactResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          result: PropTypes.array,
        }),
        // 検索結果(リード)
        searchLeadResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          result: PropTypes.array,
        }),
        // 検索結果(会員DB)
        searchResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          result: PropTypes.array,
        }),
        searchLeadParams: paginationShape,
        searchParams: paginationShape,
        searchContactParams: paginationShape,
        tabIndex: PropTypes.number,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      // 検索(B.人(取責))
      searchContact: PropTypes.func,
      // 検索(リード)
      searchLead: PropTypes.func,
      // 検索（会員DB）
      search: PropTypes.func,
      formState: PropTypes.shape({
        values: PropTypes.shape({
          searchCode: PropTypes.string,
        }),
      }),
      initState: PropTypes.func,
      storeTabIndex: PropTypes.func,
      hideUnlockSuccessModal: PropTypes.func,
      unlockUserAccount: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.state = { values: {} };
    this.searchComponent = new SearchComponent();

    // B.人(取責)
    this.clickSearchContactButton = this.clickSearchContactButton.bind(this);
    this.searchContact = this.searchContact.bind(this);
    this.getSearchContactItem = this.getSearchContactItem.bind(this);

    // リード
    this.clickSearchLeadButton = this.clickSearchLeadButton.bind(this);
    this.searchLead = this.searchLead.bind(this);
    this.getSearchLeadItem = this.getSearchLeadItem.bind(this);

    // 会員DB
    this.clickSearchButton = this.clickSearchButton.bind(this);
    this.search = this.search.bind(this);
    this.getSearchItem = this.getSearchItem.bind(this);

    this.onClickUnlockModalCloseButton = this.onClickUnlockModalCloseButton.bind(
      this
    );

    const isComeFromOtherPage =
      this.props.lastLocation == null ||
      (this.props.lastLocation &&
        !this.props.lastLocation.pathname.includes('/admin/users'));

    if (isComeFromOtherPage) {
      this.props.initState();
    }
  }

  // 検索ボタン押下(B.人(取責))
  clickSearchContactButton(values) {
    this.setState({ values: values });

    let pageSize = this.searchComponent.pageDefaultSize;

    if (
      this.props.state.searchContactParams &&
      this.props.state.searchContactParams.pagination &&
      this.props.state.searchContactParams.pagination.pageSize
    ) {
      pageSize = this.props.state.searchContactParams.pagination.pageSize;
    }

    this.searchContact(
      {
        sort: {
          sortColumn: 'id',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      values
    );
  }

  // 検索ボタン押下(リード)
  clickSearchLeadButton(values) {
    this.setState({ values: values });

    let pageSize = this.searchComponent.pageDefaultSize;

    if (
      this.props.state.searchLeadParams &&
      this.props.state.searchLeadParams.pagination &&
      this.props.state.searchLeadParams.pagination.pageSize
    ) {
      pageSize = this.props.state.searchLeadParams.pagination.pageSize;
    }

    this.searchLead(
      {
        sort: {
          sortColumn: 'id',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      values
    );
  }

  // 検索ボタン押下（会員DB）
  clickSearchButton(values) {
    this.setState({ values: values });

    let pageSize = this.searchComponent.pageDefaultSize;
    if (
      this.props.state.searchParams &&
      this.props.state.searchParams.pagination &&
      this.props.state.searchParams.pagination.pageSize
    ) {
      pageSize = this.props.state.searchParams.pagination.pageSize;
    }

    this.search(
      {
        sort: {
          sortColumn: 'id',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      values
    );
  }

  // B.人(取責)
  getSearchContactItem() {
    const onClickSalesForceButton = (sfid) => (e) => {
      e.preventDefault();
      window.open(`https://${constants.SFDomain}/${sfid}`, '_blank');
    };

    const users = this.props.state.searchContactResults.result;

    return users.map((user, id) => {
      return (
        <tr key={id} className={user.deactivateAt ? 'deactivate-tr' : ''}>
          <td>
            <SalesForceButton onClick={onClickSalesForceButton(user.sfid)} />
          </td>
          <td>{user.sfid}</td>
          <td>{user.mailAddress}</td>
          <td>{user.nameKana}</td>
          <td>{user.lineNickName}</td>
          <td>{user.company}</td>
          <td>{user.age}</td>
          <td>{user.registerFromDateYmd}</td>
          <td>{user.contactNg}</td>
          <td>{user.mailNg}</td>
          <td>{user.visitDate}</td>
          <td>{user.visitDate2}</td>
          <td>{user.currentStatus}</td>
          <td>{user.collegeRegistrationCategory}</td>
          <td>{user.gender}</td>
          <td>{user.pref}</td>
          <td>{user.phoneNumber}</td>
          <td>{user.approachCount}</td>
          <td>{user.telephoneCount}</td>
          <td>{user.contactCount}</td>
          <td>{user.lastActionDateYmd}</td>
          <td>{user.inviteCount}</td>
        </tr>
      );
    });
  }

  // リード
  getSearchLeadItem() {
    const onClickSalesForceButton = (sfid) => (e) => {
      e.preventDefault();
      window.open(`https://${constants.SFDomain}/${sfid}`, '_blank');
    };

    const users = this.props.state.searchLeadResults.result;

    return users.map((user, id) => {
      return (
        <tr key={id} className={user.deactivateAt ? 'deactivate-tr' : ''}>
          <td>
            <SalesForceButton
              onClick={onClickSalesForceButton(user.leadsfid)}
            />
          </td>
          <td>{user.leadsfid}</td>
          <td>{user.mailAddress}</td>
          <td>{user.nameKana}</td>
          <td>{user.company}</td>
          <td>{user.age}</td>
          <td>{user.registerFromDateYmd}</td>
          <td>{user.gender}</td>
          <td>{user.pref}</td>
          <td>{user.phoneNumber}</td>
        </tr>
      );
    });
  }

  // 会員DB
  getSearchItem() {
    const transitionRefer = (id) => {
      this.props.history.push(`/admin/users/refer/${id}`);
    };

    const onClickUnlockButton = (userId) => {
      this.props.unlockUserAccount(userId);
    };

    const users = this.props.state.searchResults.result;

    return users.map((user, id) => {
      console.debug('deactivate_at', user.deactivateAt);
      return (
        <tr key={id} className={user.deactivateAt ? 'deactivate-tr' : ''}>
          <td>
            <DetailButton onClick={transitionRefer.bind(this, user.id)} />
          </td>
          <td>{user.leadsfid}</td>
          <td>{user.sfid}</td>
          <td>{user.mailAddress}</td>
          <td>{user.nameKana}</td>
          <td>{user.lineNickName}</td>
          <td>{user.age}</td>
          <td>{user.registerFromDateYmd}</td>
          <td>{user.currentStatus}</td>
          <td>{user.gender}</td>
          <td>{user.pref}</td>
          <td>{user.phoneNumber}</td>
          <td>{user.inviteCount}</td>
          <td>{user.reserveCount}</td>
          <td>
            {user.isAccountLock ? (
              <AccountUnlockButton
                onClick={onClickUnlockButton.bind(this, user.id)}
              />
            ) : (
              ''
            )}
          </td>
        </tr>
      );
    });
  }

  // B.人(取責)
  searchContact(searchParams, values = this.state.values) {
    this.props.searchContact(values, '1', searchParams);
  }

  // リード
  searchLead(searchParams, values = this.state.values) {
    this.props.searchLead(values, '1', searchParams);
  }

  // 会員DB
  search(searchParams, values = this.state.values) {
    this.props.search(values, '1', searchParams);
  }

  onClickUnlockModalCloseButton() {
    this.props.hideUnlockSuccessModal();
  }

  render() {
    const onSelectTab = (index) => {
      this.props.storeTabIndex(index);
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>会員管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="会員管理検索">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <Tabs
                        onSelect={onSelectTab}
                        selectedIndex={this.props.state.tabIndex}
                      >
                        <TabList>
                          <Tab>B.人(取責)</Tab>
                          <Tab>リード</Tab>
                          <Tab>会員DB</Tab>
                        </TabList>
                        <TabPanel>
                          <div className="section-title">検索項目</div>
                          <ContactForm
                            onSubmit={this.clickSearchContactButton}
                          />
                          <div className="text-left">
                            <hr />
                            {this.props.state.searchContactResults.result
                              .length > 0 ? (
                              <>
                                <Pager
                                  searchParams={
                                    this.props.state.searchContactResults
                                      .searchParams
                                  }
                                  search={this.searchContact}
                                />

                                <SearchResultTable
                                  headerItems={[
                                    {},
                                    { name: 'Id', value: 'SFID(B.人)' },
                                    { name: 'Email', value: 'メールアドレス' },
                                    {
                                      name: 'Field1__c',
                                      value: '名前(フリガナ)',
                                    },
                                    {
                                      name: 'eLINEnickname__c',
                                      value: 'LINEニックネーム',
                                    },
                                    { name: 'copycompany__c', value: '企業名' },
                                    { name: 'Field100__c', value: '年齢' },
                                    { name: 'z456789__c', value: '流入日期間' },
                                    { name: 'Field271__c', value: '接触NG' },
                                    { name: 'z_NG__c', value: 'メールNG' },
                                    {
                                      name: 'autoraisyayotei__c',
                                      value: '来社日日期間',
                                    },
                                    {
                                      name: 'auto_raisyayotei_2__c',
                                      value: '来社日2日期間',
                                    },
                                    {
                                      name: 'Field218__c',
                                      value: '現在の状況',
                                    },
                                    {
                                      name: 'Field305__c',
                                      value: 'カレッジ登録区分',
                                    },
                                    { name: 'Field2__c', value: '性別' },
                                    { name: 'Field185__c', value: '都道府県' },
                                    { name: 'Field12__c', value: '携帯番号' },
                                    {
                                      name: 'e_number_of_approached__c',
                                      value: 'アプローチ数',
                                    },
                                    {
                                      name: 'e_number_of_called__c',
                                      value: '通電数',
                                    },
                                    {
                                      name: 'e_number_of_connected__c',
                                      value: '接触数',
                                    },
                                    {
                                      name: 'e_last_actioned_date__c',
                                      value: '最終アクション日',
                                    },
                                    { name: 'eShokaisu__c', value: '招待者数' },
                                  ]}
                                  searchResults={
                                    this.props.state.searchContactResults
                                  }
                                  getSearchItem={this.getSearchContactItem}
                                  search={this.searchContact}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="section-title">検索項目</div>
                          <LeadForm onSubmit={this.clickSearchLeadButton} />
                          <div className="text-left">
                            <hr />
                            {this.props.state.searchLeadResults.result.length >
                            0 ? (
                              <>
                                <Pager
                                  searchParams={
                                    this.props.state.searchLeadResults
                                      .searchParams
                                  }
                                  search={this.searchLead}
                                />

                                <SearchResultTable
                                  headerItems={[
                                    {},
                                    {
                                      name: 'sf_lead_user_key',
                                      value: 'SFID(リード)',
                                    },
                                    { name: 'Email', value: 'メールアドレス' },
                                    {
                                      name: 'eLINEnickname__c',
                                      value: '名前(フリガナ)',
                                    },
                                    { name: 'Company', value: '企業名' },
                                    { name: 'z28912__c', value: '年齢' },
                                    { name: 'z456788__c', value: '流入日期間' },
                                    { name: 'Field2_2__c', value: '性別' },
                                    { name: 'z9281__c', value: '都道府県' },
                                    { name: 'z5__c', value: '携帯番号' },
                                  ]}
                                  searchResults={
                                    this.props.state.searchLeadResults
                                  }
                                  getSearchItem={this.getSearchLeadItem}
                                  search={this.searchLead}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="section-title">検索項目</div>
                          <MysqlForm onSubmit={this.clickSearchButton} />
                          <div className="text-left">
                            <hr />
                            {this.props.state.searchResults.result.length >
                            0 ? (
                              <>
                                <Pager
                                  searchParams={
                                    this.props.state.searchResults.searchParams
                                  }
                                  search={this.search}
                                />

                                <SearchResultTable
                                  headerItems={[
                                    {},
                                    { name: 'Id', value: 'SFID(リード)' },
                                    {
                                      name: 'sf_lead_user_key',
                                      value: 'SFID(B.人)',
                                    },
                                    {
                                      name: 'mail_address',
                                      value: 'メールアドレス',
                                    },
                                    {
                                      name: 'first_name_kana',
                                      value: '名前(フリガナ)',
                                    },
                                    {
                                      name: 'line_nickname',
                                      value: 'LINEニックネーム',
                                    },
                                    { name: 'birthday', value: '年齢' },
                                    { name: 'created_at', value: '流入日期間' },
                                    {
                                      name: 'condition_name',
                                      value: '現在の状況',
                                    },
                                    { name: 'gender', value: '性別' },
                                    { name: 'pref', value: '都道府県' },
                                    {
                                      name: 'phone_number_mobile',
                                      value: '携帯番号',
                                    },
                                    { name: 'i_cnt', value: '招待者数' },
                                    { name: 'r_cnt', value: '予約数' },
                                    {
                                      name: 'is_account_lock',
                                      value: 'アカウントロック解除',
                                    },
                                  ]}
                                  searchResults={this.props.state.searchResults}
                                  getSearchItem={this.getSearchItem}
                                  search={this.search}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
        <RegisterCompleteModal
          show={this.props.state.search.isDisplayUnlockCompleteModal}
          onCloseClick={this.onClickUnlockModalCloseButton}
          text={'ロックを解除しました'}
        />
      </>
    );
  }
}

const LastLocation = (Component) => {
  const InnerComponent = (props) => {
    const lastLocation = useLastLocation();
    return <Component {...props} lastLocation={lastLocation} />;
  };

  return InnerComponent;
};

export default LastLocation(UserSearch);

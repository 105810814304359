import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import ModalBody from '../../atoms/ModalBody';
import ModalFooterCloseButton from '../../molecules/ModalFotterCloseButtonComponent';

class ReSendMailFailureDialog extends React.Component {
  static get propTypes() {
    return {
      targetId: PropTypes.string,
      children: PropTypes.string,
      onclick: PropTypes.string,
      show: PropTypes.bool,
      onCloseClick: PropTypes.func,
      messages: PropTypes.arrayOf(PropTypes.string),
    };
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>
          <p className="text-center">メールの送信に失敗しました</p>
          <ul>
            {this.props.messages &&
              this.props.messages.map((message, index) => {
                return (
                  <li key={index}>
                    <p className="text-danger">{message}</p>
                  </li>
                );
              })}
          </ul>
        </ModalBody>
        <ModalFooterCloseButton
          closeButtonTitle="閉じる"
          onClick={this.props.onCloseClick}
        />
      </Modal>
    );
  }
}

export default ReSendMailFailureDialog;

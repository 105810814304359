import PropTypes, { array, shape } from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { makeFetchArea } from '../../../actions/Venue';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';

class VenueSearchForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      options: shape({
        areas: array,
      }),
    };
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent
            label="名前"
            name="name"
            component="input"
            type="text"
          />
          <HorizontalSelectComponent
            label="エリア"
            options={this.props.options.areas}
            name="areaId"
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
const venueSearchForm = reduxForm({
  // a unique name for the form
  form: 'veneuSearchForm',
})(VenueSearchForm);

export default connect(
  (state) => {
    console.debug('venueSearchForm: ', state);
    return {
      options: state.options,
      initialValues: state.venue.searchParams,
    };
  },
  (dispatch) => {
    return {
      fetchAreas() {
        dispatch(makeFetchArea());
      },
    };
  }
)(venueSearchForm);

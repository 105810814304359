import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import { Fields } from 'redux-form';
import styled from 'styled-components';

import ColAuto from '../../atoms/ColAuto';
import FormDatePicker from '../../atoms/FormDatePickerForForm';
import FormGroupRow from '../../atoms/FormGroupRow';
import FormHour from '../../atoms/FormHour';
import FormLabel from '../../atoms/FormLabel';
import FormMinute from '../../atoms/FormMinute';

class FormPeriodDateTimeComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      startDateName: PropTypes.string,
      startHourName: PropTypes.string,
      startMinuteName: PropTypes.string,
      endDateName: PropTypes.string,
      endHourName: PropTypes.string,
      endMinuteName: PropTypes.string,
      startDate: PropTypes.any,
      startHour: PropTypes.number,
      startMinute: PropTypes.number,
      endDate: PropTypes.string,
      endHour: PropTypes.number,
      endMinute: PropTypes.number,
      defaultValue: PropTypes.number,
      isDisable: PropTypes.bool,

      onChangeStartDateTime: PropTypes.func,
      onChangeEndDateTime: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.toDate = this.toDate.bind(this);

    let startDate;
    if (this.props.startDate) {
      startDate = this.props.startDate;
    } else {
      startDate = '';
    }
    this.state = {
      start: { date: startDate, hour: '', minute: '' },
      end: { date: '', hour: '', minute: '' },
    };

    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeStartHour = this.onChangeStartHour.bind(this);
    this.onChangeStartMinute = this.onChangeStartMinute.bind(this);
    this.component = this.component.bind(this);
    this.subComponent = this.subComponent.bind(this);
  }

  toDate(str) {
    return new Date(str);
  }

  onChangeStartDate(onChange, date) {
    console.debug('date picker', date);
    const timeHourMinute = {
      ...this.state.start,
      date: date,
    };

    this.setState({ start: timeHourMinute });

    onChange(timeHourMinute);
  }

  onChangeStartHour(onChange, hourTagEvent) {
    const value = hourTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.start,
      hour: value,
    };

    this.setState({ start: timeHourMinute });

    onChange(timeHourMinute);
  }

  onChangeStartMinute(onChange, minuteTagEvent) {
    const value = minuteTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.start,
      minute: value,
    };

    this.setState({ start: timeHourMinute });

    onChange(timeHourMinute);
  }

  render() {
    return (
      <FormGroupRow>
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </FormLabel>
        <div className="col-sm-6">
          <Fields
            names={['publishFrom', 'publishTo']}
            component={this.component}
            isDisable={this.props.isDisable}
          />
        </div>
      </FormGroupRow>
    );
  }

  component(fields) {
    const { publishFrom, publishTo } = fields;
    return (
      <>
        {this.subComponent(publishFrom)}
        {this.subComponent(publishTo)}
      </>
    );
  }

  subComponent(field) {
    const { date } = field.input.value;
    return (
      <NoMarginRow>
        <NoLeftPaddingColAuto>
          <FormDatePicker
            highlightWeekend
            format="YYYY/MM/DD"
            locale="ja"
            setDay={date}
            isDisable={this.props.isDisable}
            onChange={this.onChangeStartDate.bind(this, field.input.onChange)}
          />
        </NoLeftPaddingColAuto>
        <NoLeftPaddingColAuto>
          <FormHour
            name={this.props.startHourName}
            defaultValue={this.props.defaultValue}
            value={this.props.startHour}
            isDisable={this.props.isDisable}
            onChange={this.onChangeStartHour.bind(this, field.input.onChange)}
          />
        </NoLeftPaddingColAuto>
        <NoLeftPaddingColAuto>
          <FormMinute
            name={this.props.startMinuteName}
            defaultValue={this.props.defaultValue}
            value={this.props.startMinute}
            isDisable={this.props.isDisable}
            onChange={this.onChangeStartMinute.bind(this, field.input.onChange)}
          />
        </NoLeftPaddingColAuto>
      </NoMarginRow>
    );
  }
}

const NoMarginRow = styled(Row)`
  margin-left: 0px;
`;

const NoLeftPaddingColAuto = styled(ColAuto)`
  padding-left: 0px;
`;

export default FormPeriodDateTimeComponent;

import { func, object, shape, string } from 'prop-types';
import React from 'react';

import ConsultationPlanTemplate from '../../templates/consultationPlan/ConsultationPlan';

class ConsultationPlanSearch extends React.Component {
  static get propTypes() {
    return {
      clearErrorMessage: func,
      fetchStaffForSuggestion: func,
      clearSuggestion: func,
      match: shape({
        params: shape({
          id: string,
        }),
      }),
      location: shape({
        search: string,
      }),
      state: shape({
        values: object,
      }),
      search: func,
      fetchProfile: func,
      fetchArea: func,
      fetchBases: func,
      fetchStaff: func,
      checkNextMonthInterview: func,
      fetchById: func,
      editInterview: func,
      delete: func,
      resetState: func,
      showModal: func,
      history: shape({
        goBack: func,
      }),
      setSelectFrom: func,
      changeInterviewer: func,
      initFetchData: func,
      goBack: func,
    };
  }

  constructor(props) {
    super(props);

    this.search = this.search.bind(this);
    this.fetchById = this.fetchById.bind(this);
    this.editInterview = this.editInterview.bind(this);
    this.deleteById = this.deleteById.bind(this);
    this.resetState = this.resetState.bind(this);
    this.showModal = this.showModal.bind(this);
    this.changeStaff = this.changeStaff.bind(this);
    this.getSearchParam = this.getSearchParam.bind(this);
    this.changeInterviewer = this.changeInterviewer.bind(this);

    const { params } = this.props.match;
    this.id = params.id;
    const urlParams = new URLSearchParams(this.props.location.search);
    const userId = urlParams.get('user_id');
    const selectedDate = urlParams.get('selected_date');

    const comeFromOtherPageFlag = this.id != null && userId != null;

    const firstDay = new Date();
    firstDay.setDate(firstDay.getDate() - firstDay.getDay());

    this.state = {
      comeFromOtherPageFlag: comeFromOtherPageFlag,
      searchParam: {
        year: firstDay.getFullYear(),
        month: firstDay.getMonth() + 1,
        week: Math.floor((firstDay.getDate() - firstDay.getDay() + 12) / 7),
        date: firstDay.getDate(),
        day: firstDay.getDay(),
        staffId: comeFromOtherPageFlag ? userId : null,
      },
      selectedDate: new Date(selectedDate),
    };
  }

  componentDidMount() {
    this.props.initFetchData(
      this.state.comeFromOtherPageFlag,
      this.state.searchParam
    );

    if (
      this.state.comeFromOtherPageFlag &&
      this.props.state.getByIdResult.name == null
    ) {
      this.props.search(this.getSearchParam());
      this.fetchById(this.id);
      this.showModal();
    }
  }

  search(searchParams) {
    this.setState(
      {
        ...this.state,
        searchParam: {
          ...this.state.searchParam,
          year: searchParams.year,
          month: searchParams.month,
          week: Math.floor((searchParams.date - searchParams.day + 12) / 7),
          date: searchParams.date,
          day: searchParams.day,
        },
      },
      () => {
        this.props.search(this.getSearchParam());
      }
    );
  }

  fetchById(id) {
    this.props.fetchById(id);
  }

  editInterview(values) {
    if (values.interviewerId == null) {
      values.interviewerId =
        this.state.searchParam.staffId || this.props.state.profile.id;
    }
    this.props.editInterview(
      values,
      this.getSearchParam(),
      this.state.comeFromOtherPageFlag
        ? {
            interviewId: this.id,
            selectedDate: this.state.selectedDate,
            searchValues: this.props.state.reservationManagement.interviews
              .searchValues,
          }
        : null
    );
  }

  deleteById(id) {
    this.props.delete(id, this.getSearchParam());
  }

  getSearchParam() {
    return {
      ...this.state.searchParam,
      staffId: this.state.searchParam.staffId || this.props.state.profile.id,
    };
  }

  resetState() {
    this.props.resetState();
    this.props.search(this.getSearchParam());
  }

  showModal(isNew, interviewId) {
    this.props.showModal(isNew, interviewId);
  }

  changeStaff(id, name) {
    if (id != null) {
      this.setState(
        {
          ...this.state,
          searchParam: {
            ...this.state.searchParam,
            staffId: id,
            staffName: name,
          },
        },
        () => {
          this.props.search(this.state.searchParam);
        }
      );
    } else {
      this.setState({
        ...this.state,
        searchParam: {
          ...this.state.searchParam,
          staffName: name,
        },
      });
    }
  }

  changeInterviewer(values) {
    this.props.changeInterviewer(
      values,
      this.getSearchParam(),
      this.state.comeFromOtherPageFlag
        ? {
            interviewId: this.id,
            selectedDate: this.state.selectedDate,
            searchValues: this.props.state.reservationManagement.interviews
              .searchValues,
          }
        : null
    );
  }

  render() {
    return (
      <section className="section">
        <div className="section-header">
          <h1>個別面談予定管理</h1>
        </div>

        <div className="section-body">
          {this.props.state.isLoading ? (
            <></>
          ) : (
            <ConsultationPlanTemplate
              resultList={
                this.props.state.resultList.interviewables == null
                  ? []
                  : this.props.state.resultList.interviewables
              }
              editFlag={this.props.state.resultList.editFlag}
              registFlag={this.props.state.resultList.registFlag}
              searchFunction={this.search}
              fetchById={this.fetchById}
              fetchByIdResult={this.props.state.getByIdResult}
              editInterview={this.editInterview}
              editResult={this.props.state.editResult}
              deleteFunction={this.deleteById}
              searchParams={this.state.searchParam}
              areas={this.props.state.areas}
              bases={this.props.state.bases}
              staffs={this.props.state.staffs}
              errorMessage={this.props.state.errorMessage}
              existNextMonth={!this.props.state.resultList.shouldDisplayAlert}
              resetFunction={this.resetState}
              showModalFunction={this.showModal}
              isShowModal={this.props.state.isShowModal}
              changeStaff={this.changeStaff}
              profile={this.props.state.profile}
              setSelectFrom={this.props.setSelectFrom}
              changeInterviewer={this.changeInterviewer}
              selectedStaffName={
                this.props.state.profile
                  ? `${this.props.state.profile.familyName} ${this.props.state.profile.firstName}`
                  : ''
              }
              comeFromOtherPageFlag={this.state.comeFromOtherPageFlag}
              interviewId={
                this.state.comeFromOtherPageFlag ? this.id : undefined
              }
              goBack={this.props.goBack}
              clearErrorMessage={this.props.clearErrorMessage}
              fetchStaffForSuggestion={this.props.fetchStaffForSuggestion}
              clearSuggestion={this.props.clearSuggestion}
              staffSuggestions={this.props.state.staff}
              history={this.props.history}
            />
          )}
        </div>
      </section>
    );
  }
}

export default ConsultationPlanSearch;

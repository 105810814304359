import { connect } from 'react-redux';

import {
  makeFetchAreas,
  makeFetchPrefs,
  makeSearch,
  makeFetchBase,
  makeUpdateBase,
  makeDeleteBase,
  showDeleteConfirm,
  closeDeleteConfirm,
  closeDeleteComplete,
  errorDelete,
  addAccessList,
  deleteAccessList,
  storeValues,
  makeFetchBaseDetailForEdit,
  newBase,
  makeConfirmIsUsed,
  makeHideContainsAlertModal,
  initReferState,
  backToInput,
} from '../actions/Base';
import BaseComponent from '../components/pages/base/Base';
import BaseEditComponent from '../components/pages/base/BaseEdit';
import BaseReferComponent from '../components/pages/base/BaseRefer';
import BaseSearchComponent from '../components/pages/base/BaseSearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.base,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArea() {
      dispatch(makeFetchAreas());
    },
    fetchPref() {
      dispatch(makeFetchPrefs());
    },
    search(values, searchParams) {
      dispatch(makeSearch(values, searchParams));
    },
    fetchBase(id) {
      dispatch(makeFetchBase(id));
    },
    updateBase(values) {
      dispatch(makeUpdateBase(values));
    },
    deleteBase(id) {
      dispatch(makeDeleteBase(id));
    },
    errorDelete() {
      dispatch(errorDelete());
    },
    showDeleteConfirm() {
      dispatch(showDeleteConfirm());
    },
    closeDeleteConfirm() {
      dispatch(closeDeleteConfirm());
    },
    closeDeleteComplete() {
      dispatch(closeDeleteComplete());
    },
    addAccessList(data) {
      dispatch(addAccessList(data));
    },
    deleteAccessList(data) {
      dispatch(deleteAccessList(data));
    },
    newBase() {
      dispatch(newBase());
    },
    confirmIsUsed(baseIds) {
      dispatch(makeConfirmIsUsed(baseIds));
    },
    hideContainsAlertModal() {
      dispatch(makeHideContainsAlertModal());
    },
    initReferState() {
      dispatch(initReferState());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseComponent);
const BaseSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseSearchComponent);

export { BaseSearch };

const BaseRefer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseReferComponent);

export { BaseRefer };

const BaseEdit = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchBaseDetailForEdit(id) {
      dispatch(makeFetchBaseDetailForEdit(id));
    },
    storeValues(values) {
      dispatch(storeValues(values));
    },
    backToInput() {
      dispatch(backToInput());
    },
  };
})(BaseEditComponent);

export { BaseEdit };

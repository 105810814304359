import PropTypes, { object, func } from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import {
  makeFetchHallsById,
  makeFetchProfile,
  makeSetInitializeVenueSelect,
} from '../../../actions/ReservationManagement';
import FormInlineCheckBoxesComponent from '../../molecules/FormInlineCheckBoxesComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';

import ChairmanInput from './ChairmanInputComponent';
import SubstituteAlert from './SubstituteAlert';

class SearchInterviews extends React.Component {
  static get propTypes() {
    return {
      options: PropTypes.shape({
        areas: PropTypes.array,
        halls: PropTypes.array,
        tags: PropTypes.array,
        bases: PropTypes.array,
      }),
      areaId: PropTypes.string,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      fetchHallsById: PropTypes.func,
      setInitializeVenueSelect: PropTypes.func,
      fetchMyName: PropTypes.func,
      substituteUser: object,
      onClickSubstituteReleaseButton: func,
    };
  }

  constructor(props) {
    super(props);
    this.onClickSelectBox = this.onClickSelectBox.bind(this);
    this.onClickSelfButton = this.onClickSelfButton.bind(this);

    this.state = { selectedAreaId: null, selectedBaseId: null };
  }

  /**
   * エリアのセレクト
   * @param {Event} e
   */
  onClickSelectBox(e) {
    this.setState({ selectedAreaId: e.target.value });

    if (e || this.state.selectedBaseId) {
      this.props.fetchHallsById(e.target.value);
    }
    this.props.setInitializeVenueSelect();
  }

  /**
   * 自分担当ボタンを押下
   */
  onClickSelfButton() {
    this.props.fetchMyName();
  }

  render() {
    const isSelectedAreaId = () => {
      if (!this.props.areaId) {
        return false;
      }
      return true;
    };
    return (
      <div>
        {this.props.substituteUser ? (
          <SubstituteAlert
            substituteUser={this.props.substituteUser}
            onClickReleaseButton={this.props.onClickSubstituteReleaseButton}
          />
        ) : (
          <></>
        )}
        <Form onSubmit={this.props.handleSubmit}>
          {/* 講師 */}
          <ChairmanInput
            name="chairman"
            label="講師"
            onClickSelfAssignButton={this.onClickSelfButton}
          />
          {/* 対象 */}
          <FormInlineCheckBoxesComponent
            label="マッチング状況"
            name="isMatch"
            options={[
              { name: 'マッチング済', value: '1' },
              { name: '未マッチング', value: '0' },
            ]}
          />
          {/* WEB面談用URL */}
          <FormInlineCheckBoxesComponent
            label="WEB面談用URL"
            name="isUrlRegistered"
            options={[
              { name: '入力済', value: '1' },
              { name: '未入力', value: '0' },
            ]}
          />
          {/* エリア */}
          <HorizontalSelectComponent
            label="エリア"
            name="areaId"
            options={this.props.options.areas}
            onChange={this.onClickSelectBox}
          />
          {/* 会場 */}
          {isSelectedAreaId() ? (
            <HorizontalSelectComponent
              label="会場"
              name="venueId"
              options={this.props.options.halls}
            />
          ) : (
            <></>
          )}
          {/* 担当拠点 */}
          <HorizontalSelectComponent
            label="担当拠点"
            name="baseId"
            options={this.props.options.bases}
          />
          {/* 検索ボタン */}
          <Row>
            <Col>
              <div className="text-center">
                <SearchButton />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const reservationSearchInterviewsForm = reduxForm({
  form: 'ReservationSearchInterviewsForm',
})(SearchInterviews);

const selector = formValueSelector('ReservationSearchInterviewsForm');

export default connect(
  (state) => {
    const areaIdValue = selector(state, 'areaId');
    const searchValues = state.reservationManagement.searchValues;

    return {
      options: state.options,
      areaId: areaIdValue,
      initialValues: {
        chairman:
          searchValues && searchValues.chairman ? searchValues.chairman : null,
        isMatch:
          searchValues && searchValues.isMatch ? searchValues.isMatch : [],
        isUrlRegistered:
          searchValues && searchValues.isUrlRegistered
            ? searchValues.isUrlRegistered
            : [],
        areaId:
          searchValues && searchValues.areaId ? searchValues.areaId : null,
        baseId:
          searchValues && searchValues.baseId ? searchValues.baseId : null,
        venueId:
          searchValues && searchValues.venueId ? searchValues.venueId : null,
      },
    };
  },
  (dispatch) => {
    return {
      fetchHallsById(areaId) {
        dispatch(makeFetchHallsById(areaId));
      },

      /**
       * ログイン者自身の名前を取得する
       */
      fetchMyName() {
        dispatch(makeFetchProfile());
      },
      setInitializeVenueSelect() {
        dispatch(
          makeSetInitializeVenueSelect('ReservationSearchInterviewsForm')
        );
      },
    };
  }
)(reservationSearchInterviewsForm);

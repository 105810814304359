import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

class MarginFormComponent extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.node,
      margin: PropTypes.oneOf([MarginSize.S, MarginSize.L]),
    };
  }

  render() {
    const margin = this.props.margin;
    let offset = MarginSize.S;

    if (margin) {
      offset = margin;
    }

    return (
      <Row>
        <Col
          xl={{ span: 12 - offset * 2, offset: offset }}
          lg={{ span: 12 - offset * 2, offset: offset }}
          md={12}
          sm={12}
          xs={12}
        >
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

export const MarginSize = {
  L: 4,
  S: 2,
};

export default MarginFormComponent;

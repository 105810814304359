import { func } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import PasswordInputComponent from '../../organisms/login/PasswordInputComponent';
import * as Validator from '../../utils/Validate';

import LoginButton from './LoginButton';
import LoginMailInput from './LoginMailInput';

const LoginEditForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="mail"
        type="mail"
        component={LoginMailInput}
        label="メールアドレス"
        validate={[Validator.required, Validator.email]}
        required
      />
      <PasswordInputComponent
        name="password"
        label="パスワード"
        validate={[Validator.required]}
      />
      <div className="text-center">
        <LoginButton />
      </div>
    </form>
  );
};

LoginEditForm.propTypes = {
  handleSubmit: func,
};

export default connect(() => {
  return {
    initialValues: {},
  };
})(
  reduxForm({
    // a unique name for the form
    form: 'loginEditForm',
    enableReinitialize: true,
  })(LoginEditForm)
);

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

class FormDatePicker extends React.Component {
  static get propTypes() {
    return {
      day: PropTypes.instanceOf(Date),
      setDay: PropTypes.instanceOf(Date),
      format: PropTypes.string,
      locale: PropTypes.string,
      onChange: PropTypes.func,
      isShowMonthYearPicker: PropTypes.bool,
      name: PropTypes.string,
      highlightWeekend: PropTypes.bool,
      isDisable: PropTypes.bool,
    };
    // this.getFormatDate = this.getFormatDate.bind(this);
  }

  constructor(props) {
    super(props);

    const highlightedDates = this.updateHighlightDates();

    this.state = {
      highlightedDates,
    };

    this.onMonthNext = this.onMonthNext.bind(this);
    this.onCalendarOpen = this.onCalendarOpen.bind(this);
  }

  getFormatDate(date, format) {
    if (!date) {
      return '----/--/--';
    }
    return moment(date).format(format);
  }

  updateHighlightDates(date) {
    let baseDate;
    if (date) {
      baseDate = date;
    } else {
      baseDate = new Date();
    }

    const firstDay = 1;
    const lastDay = moment(baseDate).clone().endOf('month').date();

    // 1週間前
    const {
      saturdays: beforeWeekMonthSaturdays,
      sundays: beforeWeekMonthSundays,
    } = this.beforeWeekMonth(baseDate, firstDay);

    const {
      saturdays: thisMonthSaturdays,
      sundays: thisMonthSundays,
    } = this.searchThisMonth(firstDay, lastDay, baseDate);

    const {
      saturdays: afterWeekMonthSaturdays,
      sundays: afterWeekMonthSundays,
    } = this.afterWeekMonth(baseDate, lastDay);

    const highlightedDates = [
      {
        'react-datepicker__day--saturday': [].concat(
          beforeWeekMonthSaturdays,
          thisMonthSaturdays,
          afterWeekMonthSaturdays
        ),
      },
      {
        'react-datepicker__day--sunday': [].concat(
          beforeWeekMonthSundays,
          thisMonthSundays,
          afterWeekMonthSundays
        ),
      },
    ];

    return highlightedDates;
  }

  beforeWeekMonth(baseDate, firstDay) {
    const saturdays = [];
    const sundays = [];
    const beforeWeekMonth = moment(baseDate)
      .clone()
      .set({ date: firstDay })
      .subtract(7, 'days');
    for (
      let i = beforeWeekMonth.date();
      i <= beforeWeekMonth.clone().endOf('month').date();
      i++
    ) {
      console.debug('before month day = ', i);
      const m = beforeWeekMonth.clone().set({ date: i });
      const weekday = m.weekday();

      if (weekday === 6) {
        saturdays.push(m.set({ hour: 0, minute: 0, second: 0 }).toDate());
      }
      if (weekday === 0) {
        sundays.push(m.set({ hour: 0, minute: 0, second: 0 }).toDate());
      }
    }
    return {
      saturdays,
      sundays,
    };
  }

  afterWeekMonth(baseDate, lastDay) {
    const saturdays = [];
    const sundays = [];
    const afterWeekMonth = moment(baseDate)
      .clone()
      .set({ date: lastDay })
      .add(7, 'days');
    for (let i = 1; i <= afterWeekMonth.clone().date(); i++) {
      console.debug('after month day = ', i);
      const m = afterWeekMonth.clone().set({ date: i });
      const weekday = m.weekday();

      if (weekday === 6) {
        saturdays.push(m.set({ hour: 0, minute: 0, second: 0 }).toDate());
      }
      if (weekday === 0) {
        sundays.push(m.set({ hour: 0, minute: 0, second: 0 }).toDate());
      }
    }
    return {
      saturdays,
      sundays,
    };
  }

  searchThisMonth(firstDay, lastDay, baseDate) {
    const saturdays = [];
    const sundays = [];
    // 当月分
    for (let i = firstDay; i <= lastDay; i++) {
      const m = moment(baseDate).clone().set({ date: i });
      const weekday = m.weekday();

      if (weekday === 6) {
        saturdays.push(m.set({ hour: 0, minute: 0, second: 0 }).toDate());
      }
      if (weekday === 0) {
        sundays.push(m.set({ hour: 0, minute: 0, second: 0 }).toDate());
      }
    }

    return { saturdays, sundays };
  }

  onCalendarOpen() {
    const highlightedDates = this.updateHighlightDates(
      this.props.day || new Date()
    );

    this.setState({
      highlightedDates,
    });
  }

  onMonthNext(date) {
    const highlightedDates = this.updateHighlightDates(date);

    this.setState({
      highlightedDates,
    });
  }

  render() {
    if (this.props.isShowMonthYearPicker) {
      return (
        <div className="customDatePickerWidth">
          <DatePicker
            locale={this.props.locale}
            selected={this.props.day}
            onChange={this.props.onChange}
            onMonthChange={this.onMonthNext}
            onCalendarOpen={this.onCalendarOpen}
            disabled={this.props.isDisable}
            customInput={
              <div>
                <input
                  type="hidden"
                  className="form-control"
                  name={this.props.name}
                  value={this.getFormatDate(
                    this.props.setDay,
                    this.props.format
                  )}
                ></input>
                <PointerSpan className="btn btn-outline-secondary">
                  {this.getFormatDate(this.props.setDay, this.props.format)}
                </PointerSpan>
              </div>
            }
            highlightDates={
              this.props.highlightWeekend
                ? this.state.highlightedDates
                : undefined
            }
            showMonthYearPicker
          />
        </div>
      );
    } else {
      return (
        <div className="customDatePickerWidth">
          <DatePicker
            locale={this.props.locale}
            selected={this.props.day}
            onChange={this.props.onChange}
            onMonthChange={this.onMonthNext}
            onCalendarOpen={this.onCalendarOpen}
            disabled={this.props.isDisable}
            customInput={
              <div>
                <input
                  type="hidden"
                  className="form-control"
                  name={this.props.name}
                  value={this.getFormatDate(
                    this.props.setDay,
                    this.props.format
                  )}
                ></input>
                {this.props.isDisable ? (
                  <DisableSpan className={`btn btn-outline-secondary`}>
                    {this.getFormatDate(this.props.setDay, this.props.format)}
                  </DisableSpan>
                ) : (
                  <PointerSpan className={`btn btn-outline-secondary`}>
                    {this.getFormatDate(this.props.setDay, this.props.format)}
                  </PointerSpan>
                )}
              </div>
            }
            highlightDates={
              this.props.highlightWeekend
                ? this.state.highlightedDates
                : undefined
            }
          />
        </div>
      );
    }
  }
}

const PointerSpan = styled.span`
  cursor: pointer;
  width: 100%;
  min-width: 8em;
  height: 42px;
  border-color: #e4e6fc;
  background-color: #fdfdff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DisableSpan = styled(PointerSpan)`
  &&&&& {
    background-color: #e9ecef;
    opacity: 1;
    &:hover {
      background-color: #e9ecef !important;
      color: #000 !important;
      cursor: default;
    }
  }
`;

export default FormDatePicker;

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import {
  makeFetchAreas,
  makeFetchPrefs,
  initReferState,
} from '../../../actions/Base';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';

class BaseSearchForm extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        searchResults: PropTypes.shape({
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
        }),
      }),
      handleSubmit: PropTypes.func,
      fetchPrefs: PropTypes.func,
      initState: PropTypes.func,
      options: PropTypes.shape({
        areas: PropTypes.array,
        prefs: PropTypes.array,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  componentDidMount() {
    this.props.fetchPrefs();
    this.props.initState();
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent
            label="拠点名"
            name="name"
            component="input"
            type="text"
          />
          <HorizontalSelectComponent
            label="エリア"
            name="areaId"
            options={this.props.options.areas}
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
const baseSearchForm = reduxForm({
  // a unique name for the form
  form: 'baseSearchForm',
})(BaseSearchForm);

export default connect(
  (state) => {
    let initialValues;
    if (
      state.base.searchValues &&
      Object.keys(state.base.searchValues).length > 0
    ) {
      initialValues = {
        ...state.base.searchValues,
      };
    }

    return {
      options: state.options,
      state: state.base,
      searchResults: state.base.searchResults,
      isComplete: state.base.searchResults.isComplete,
      initialValues,
    };
  },
  (dispatch) => {
    return {
      initState() {
        dispatch(initReferState());
      },
      fetchAreas() {
        dispatch(makeFetchAreas());
      },
      fetchPrefs() {
        dispatch(makeFetchPrefs());
      },
    };
  }
)(baseSearchForm);

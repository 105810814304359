export default {
  /**
   *  @type {string} 検索画面
   */
  search: '/admin/events/',
  /**
   * 参照画面
   * @param {string} id 社員ID
   */
  refer: (id) => {
    return `/admin/events/refer/${id}`;
  },
  /**
   * 編集画面
   * @param {string} id 社員ID
   */
  edit: (id) => {
    return `/admin/events/edit/${id}`;
  },
  /**
   * @type {string} 一括更新画面
   */
  bulkRegister: '/admin/events/bulk_register',
};

import PropTypes from 'prop-types';
import React from 'react';

class CardHeader extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any
    };
  }

  render() {
    return (
      <div className="card-header">
        <h4>{this.props.children}</h4>
      </div>
    );
  }
}

export default CardHeader;

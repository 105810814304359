import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormGroupRow from '../../atoms/FormGroupRow';
import FormLabel from "../../atoms/FormLabel";

class FormInlineRequiredCheckBoxesComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string.isRequired,
      name: PropTypes.string,
    };
  }

  render() {
    return (
      <FormGroupRow>
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">{this.props.label}</FormLabel>
        <div className="col-sm-6">
          <Field
            name={`${this.props.name}`}
            component="input"
            type="checkbox"
          />
        </div>
      </FormGroupRow>
    );
  }
}

export default FormInlineRequiredCheckBoxesComponent;

const initialState = {
  // 検索結果(B.人(取責))
  searchContactResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    result: [],
  },
  // 検索結果(リード)
  searchLeadResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    result: [],
  },
  // 検索結果(会員DB)
  searchResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },
    result: [],
  },
  search: {
    isDisplayUnlockCompleteModal: false,
  },
  userRefer: {
    detail: {},
    isDisplayDeactivateCompleteModal: false,
  },
  userEdit: {
    isEdit: false,
    isConfirm: false,
    isComplete: false,
    isSubmitting: false,
    compnay: '',
    compnayOld: '',
  },
  isLoading: false,
  tabIndex: 0,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    // 検索(B.人(取責))
    case 'admin_users/FETCH_USER_SEARCH_CONTACT': {
      const searchContactResults = action.payload.result;
      return { ...state, searchContactResults: searchContactResults };
    }
    // 検索(リード)
    case 'admin_users/FETCH_USER_SEARCH_LEAD': {
      const searchLeadResults = action.payload.result;
      return { ...state, searchLeadResults: searchLeadResults };
    }
    // 検索(会員DB)
    case 'admin_users/FETCH_USER_SEARCH': {
      const searchResults = action.payload.result;
      return { ...state, searchResults };
    }
    case 'admin_users/FETCH_USER': {
      const results = action.payload.result;
      return {
        ...state,
        userRefer: { ...state.userRefer, detail: results },
        isLoading: false,
      };
    }
    case 'admin_users/SHOW_EDIT': {
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          isEdit: true,
          isConfirm: false,
          isComplete: false,
          compnay: '',
          compnayOld: '',
        },
      };
    }
    case 'admin_users/SHOW_EDIT_CLOSE': {
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          isEdit: false,
          isConfirm: false,
          isComplete: false,
          compnay: '',
          compnayOld: '',
        },
      };
    }
    case 'admin_users/SHOW_EDIT_CONFIRM': {
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          isEdit: false,
          isConfirm: true,
          isComplete: false,
          compnay: '',
          compnayOld: '',
        },
      };
    }
    case 'admin_users/SHOW_EDIT_CONFIRM_CLOSE': {
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          isEdit: false,
          isConfirm: false,
          isComplete: false,
          compnay: '',
          compnayOld: '',
        },
      };
    }
    case 'admin_users/UPDATE_USER': {
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          isEdit: false,
          isConfirm: false,
          isComplete: true,
          compnay: '',
          compnayOld: '',
        },
      };
    }
    case 'admin_users/SHOW_EDIT_COMPLETE_CLOSE': {
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          isEdit: false,
          isConfirm: false,
          isComplete: false,
          compnay: '',
          compnayOld: '',
        },
      };
    }
    case 'admin_users/STORE_TAB_INDEX': {
      const tabIndex = action.payload;
      return {
        ...state,
        tabIndex: tabIndex,
      };
    }
    case 'admin_users/INIT_STATE': {
      return initialState;
    }
    case 'admin_users/STORE_SEARCH_CONTACT_PARAMS': {
      return {
        ...state,
        searchContactParams: action.payload,
      };
    }
    case 'admin_users/STORE_SEARCH_LEAD_PARAMS': {
      return {
        ...state,
        searchLeadParams: action.payload,
      };
    }
    case 'admin_users/STORE_SEARCH_PARAMS': {
      return {
        ...state,
        searchParams: action.payload.searchParams,
        values: action.payload.values,
      };
    }
    case 'admin_users/FETCH_PROFILE': {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case 'admin_users/UNLOCK_SUCCESS': {
      return {
        ...state,
        search: {
          ...state.search,
          isDisplayUnlockCompleteModal: true,
        },
      };
    }
    case 'admin_users/HIDE_UNLOCK_SUCCESS_MODAL': {
      return {
        ...state,
        search: {
          ...state.search,
          isDisplayUnlockCompleteModal: false,
        },
      };
    }
    case 'admin_users/DEACTIVATE_USER_SUCCESS': {
      return {
        ...state,
        userRefer: {
          ...state.userRefer,
          isDisplayDeactivateCompleteModal: true,
        },
      };
    }
    case 'admin_users/HIDE_DEACTIVATE_COMPLETE_MODAL': {
      return {
        ...state,
        userRefer: {
          ...state.userRefer,
          isDisplayDeactivateCompleteModal: false,
        },
      };
    }
    default:
      return state;
  }
};

export { userReducer as default };

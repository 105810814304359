import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import Necessities, { NecessitiesType } from '../../../domain/Necessities';
import DangerButton from '../../atoms/DangerButton';
import ModalBody from '../../atoms/ModalBody';
import ModalFooter from '../../atoms/ModalFooter';
import ModalHeader from '../../atoms/ModalHeader';
import PrimaryButton from '../../atoms/PrimaryButton';
import WordWrapDiv from '../../atoms/WordWrapDiv';
import Option from '../../model/Option';
import CloseButton from '../../molecules/CloseButton';
import HorizontalBadgesComponent from '../../molecules/HorizontalBadgesComponent';
import LabelErrorComponent from '../../molecules/LabelErrorComponent';
import ReferItem from '../../molecules/ReferItem';
import Form from '../../organisms/consultationPlan/ConsultationPlanEditForm';
import {
  AllowOwner,
  DeleteAction,
  EditAction,
} from '../../utils/PermissionComponent';
import SearchDetailModalAccordion from '../reservationManagement/SearchDetailModalAccordion';

class ConsultationPlanModal extends React.Component {
  static get propTypes() {
    return {
      targetId: PropTypes.string,
      children: PropTypes.string,
      onclick: PropTypes.string,
      show: PropTypes.bool,
      onCloseClick: PropTypes.func,
      title: PropTypes.string,
      data: PropTypes.shape({
        id: PropTypes.string,
        methodTypeName: PropTypes.string,
        date: PropTypes.instanceOf(Date),
        fromHour: PropTypes.number,
        fromMinute: PropTypes.number,
        toHour: PropTypes.number,
        toMinute: PropTypes.number,
        intervieweeId: PropTypes.string,
        frameType: PropTypes.number,
        interviewees: PropTypes.array,
        webInterviewUrl: PropTypes.string,
        venueName: PropTypes.string,
        seatAvailability: PropTypes.number,
        tags: PropTypes.array,
        clothes: PropTypes.bool,
        clothesOther: PropTypes.string,
        belongings: PropTypes.bool,
        belongingsOther: PropTypes.string,
        descriptionForUser: PropTypes.string,
        descriptionForPersonal: PropTypes.string,
        chargeBaseName: PropTypes.string,
        displayAreas: PropTypes.arrayOf(
          PropTypes.shape({
            areaName: PropTypes.string,
          })
        ),
        registrantFlag: PropTypes.bool,
        venue: PropTypes.string,
        defaultMessage: PropTypes.string,
        companyName: PropTypes.string,
      }),
      editInterviewFunction: PropTypes.func,
      deleteFunction: PropTypes.func,
      isEdit: PropTypes.bool,
      changeEditModalFunction: PropTypes.func,
      newInterviewStart: PropTypes.instanceOf(Date),
      staffs: PropTypes.array,
      areas: PropTypes.array,
      bases: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
      errorMessage: PropTypes.object,
      editFlag: PropTypes.bool,
      showEditInterviewerModal: PropTypes.func,
      showDeleteModal: PropTypes.func,
      backToReferModal: PropTypes.func,
      currentSelectedStaffId: PropTypes.number,
    };
  }

  constructor(props) {
    super(props);

    this.changeEdit = this.changeEdit.bind(this);
    this.changeDetail = this.changeDetail.bind(this);
    this.editInterview = this.editInterview.bind(this);
    this.delete = this.delete.bind(this);
  }

  changeEdit(id) {
    this.props.changeEditModalFunction(id);
  }

  changeDetail() {
    this.setState({
      isEdit: false,
    });
  }

  editInterview(isNew, values) {
    this.setState({ values: values });
    this.props.editInterviewFunction(isNew, values);
  }

  delete(id) {
    this.props.deleteFunction(id);
  }

  render() {
    const messages =
      this.props.errorMessage && this.props.errorMessage.general
        ? this.props.errorMessage.general.map((message, i) => {
            return <p key={i}>{message}</p>;
          })
        : [];

    const isExpired = () => {
      const endTime = moment(
        `${this.props.data.date} ${('0' + this.props.data.toHour).slice(-2)}:${(
          '0' + this.props.data.toMinute
        ).slice(-2)}`,
        'YYYY/MM/DD hh:mm'
      );

      const now = moment();

      return endTime.isBefore(now);
    };

    const getMethodTypeName = () => {
      const methodTypeName = this.props.data.methodTypeName;
      if (!methodTypeName || methodTypeName.length === 0) {
        return '未選択';
      }
      return methodTypeName;
    };

    const isWebMethodType = () => {
      return this.props.data.methodTypeName === 'Web面談';
    };

    const isDirectMethodType = () => {
      return this.props.data.methodTypeName === '対面面談';
    };

    const isUnselectVenue = () => {
      return (
        !Object.prototype.hasOwnProperty.call(this.props.data, 'venue') ||
        !this.props.data.venue
      );
    };

    const getDisplayArea = () => {
      const displayAreas = this.props.data.displayAreas;

      if (displayAreas != null && displayAreas.length > 0) {
        return displayAreas.map((displayArea) => {
          console.debug(
            displayArea.areaId,
            displayArea.areaName,
            displayArea.bases
          );

          const bases = displayArea.bases.map((base, index) => {
            return <BaseDiv key={index}>{base.baseName}</BaseDiv>;
          });

          const areaTag = (
            <div>
              {displayArea.areaName}
              {bases}
            </div>
          );

          return areaTag;
        });
      }

      return '';
    };

    const BaseDiv = styled.div`
      margin-left: 3em;
    `;

    const interviewees = this.props.data.interviewees;
    const participantsNumber =
      interviewees != null
        ? `${interviewees.length} / ${this.props.data.seatAvailability}の参加者`
        : '';
    const intervieweeNames =
      interviewees != null
        ? this.props.data.interviewees.map((user) => user.name)
        : [];
    const isIntervieweesRegistered =
      interviewees != null && interviewees.length > 0;
    const canReserve =
      interviewees != null &&
      interviewees.length < this.props.data.seatAvailability;

    return (
      <Modal show={this.props.show} onHide={this.props.onCloseClick} size="lg">
        <ModalHeader>
          {this.props.isEdit
            ? this.props.data.id != null
              ? '予定編集'
              : '新規登録'
            : '予定詳細'}
        </ModalHeader>
        <ModalBody>
          {messages.length > 0 ? (
            <Row>
              <LabelErrorComponent title={messages} />
            </Row>
          ) : (
            <></>
          )}
          {this.props.isEdit ? (
            <Form
              onSubmit={this.editInterview.bind(
                this,
                this.props.data.id == null
              )}
              cancelFunction={this.props.backToReferModal.bind(
                this,
                this.props.data.id == null
              )}
              newInterviewStart={this.props.newInterviewStart}
              areas={this.props.areas}
              bases={this.props.bases}
              staffs={this.props.staffs}
              data={this.props.data}
            />
          ) : (
            <a></a>
          )}

          {this.props.isEdit !== true && this.props.data != null ? (
            <div>
              <ModalBody>
                <Row>
                  <Col>
                    <ReferItem label="日程">
                      {this.props.data.date +
                        ' ' +
                        ('0' + this.props.data.fromHour).slice(-2) +
                        ':' +
                        ('0' + this.props.data.fromMinute).slice(-2) +
                        '〜' +
                        ('0' + this.props.data.toHour).slice(-2) +
                        ':' +
                        ('0' + this.props.data.toMinute).slice(-2)}
                    </ReferItem>
                    <ReferItem label="状態">
                      {canReserve ? '予約可能' : '予約有り'}
                    </ReferItem>
                    <ReferItem label="枠">
                      {this.props.data.frameType === 1 ? '通常枠' : '特別枠'}
                    </ReferItem>

                    <ReferItem label="面談予定者">
                      <SearchDetailModalAccordion
                        accordionTitle={participantsNumber}
                        list={intervieweeNames}
                      />
                    </ReferItem>
                    <ReferItem label="面談手段">
                      {getMethodTypeName()}
                    </ReferItem>
                    {isWebMethodType() ? (
                      <ReferItem label="Web面談URL">
                        <WordWrapDiv>
                          <a
                            href={this.props.data.webInterviewUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.props.data.webInterviewUrl}
                          </a>
                        </WordWrapDiv>
                      </ReferItem>
                    ) : (
                      <></>
                    )}

                    {isDirectMethodType() ? (
                      <>
                        <ReferItem label="会場">
                          {this.props.data.venueName}
                        </ReferItem>
                        {isUnselectVenue() ? (
                          <>
                            <ReferItem label="会場未選択時表示文言">
                              {this.props.data.defaultMessage}
                            </ReferItem>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <ReferItem label="定員">
                      {this.props.data.seatAvailability}
                    </ReferItem>

                    <ReferItem label="コース">
                      {this.props.data.tags != null &&
                        this.props.data.tags.length > 0 && (
                          <HorizontalBadgesComponent
                            badges={this.props.data.tags.map((tag) => tag.name)}
                          />
                        )}
                    </ReferItem>

                    <ReferItem label="服装">
                      {this.props.data.clothes
                        ? 'デフォルト(自由)'
                        : 'その他(' + this.props.data.clothesOther + ')'}
                    </ReferItem>
                    <ReferItem label="持ち物">
                      {this.props.data.belongings
                        ? new Necessities(
                            new NecessitiesType.Default(),
                            !isDirectMethodType()
                          ).displayName
                        : new Necessities(
                            new NecessitiesType.Other(
                              this.props.data.belongingsOther
                            ),
                            !isDirectMethodType()
                          ).displayName}
                    </ReferItem>
                    <ReferItem label="備考(会員閲覧用)">
                      {this.props.data.descriptionForUser}
                    </ReferItem>
                    <ReferItem label="備考(個人用)">
                      {this.props.data.descriptionForPersonal}
                    </ReferItem>
                    <ReferItem label="担当拠点">
                      {this.props.data.chargeBaseName}
                    </ReferItem>
                    <ReferItem label="企業名">
                      {this.props.data.companyName}
                    </ReferItem>
                    <ReferItem label="表示エリア">{getDisplayArea()}</ReferItem>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <CloseButton onClick={() => this.props.onCloseClick()} />
                {(this.props.editFlag || this.props.data.registrantFlag) && (
                  <>
                    <AllowOwner dataOwnerId={this.props.currentSelectedStaffId}>
                      <EditAction>
                        <PrimaryButton
                          onClick={() => this.changeEdit(this.props.data.id)}
                          isEnabled={() => {
                            return !isExpired();
                          }}
                        >
                          編集
                        </PrimaryButton>
                      </EditAction>
                    </AllowOwner>
                    {isIntervieweesRegistered && (
                      <PrimaryButton
                        onClick={() => this.props.showEditInterviewerModal()}
                        isEnabled={() => {
                          return !isExpired();
                        }}
                      >
                        担当者変更
                      </PrimaryButton>
                    )}
                    <DeleteAction>
                      <DangerButton
                        onClick={this.props.showDeleteModal.bind(
                          this,
                          isIntervieweesRegistered
                        )}
                        isEnabled={() => {
                          return !isExpired();
                        }}
                      >
                        削除
                      </DangerButton>
                    </DeleteAction>
                  </>
                )}
              </ModalFooter>
            </div>
          ) : (
            <a></a>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default ConsultationPlanModal;

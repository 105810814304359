import {
  FormManagementSearch,
  FormManagementEdit,
} from '../../../containers/FormManagement';
import routePath from "../formManagement/routePath";

const FormManagementRoutes = [
  {
    path: routePath.search,
    exact: true,
    component: FormManagementSearch,
  },
  {
    path: routePath.edit(':id'),
    exact: true,
    component: FormManagementEdit,
  },
  {
    path: routePath.newEdit,
    exact: true,
    component: FormManagementEdit,
  },
];

export default FormManagementRoutes;

import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Row, Col } from 'react-bootstrap';

class ReferItem extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      label: PropTypes.string,
    };
  }

  render() {
    return (
      <FormGroup>
        <Row>
          <Col xs={3}>
            <label className="control-label">{this.props.label}</label>
          </Col>
          <Col xs={9} className="control-detail-div" style={{whiteSpace: 'pre-wrap'}}>
            {this.props.children}
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

export default ReferItem;

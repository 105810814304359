export default {
  /**
   *  @type {string} 検索画面
   */
  search: '/admin/form/',
  /**
   * 編集画面
   * @param {string} id フォームID
   */
  edit: (id) => {
    return `/admin/form/edit/${id}`;
  },
  /**
   * @type {string} 新規登録画面
   */
  newEdit: '/admin/form/edit',
};

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';

import ColAlignLeft from '../../atoms/ColAlignLeft';
import FormHour from '../../atoms/FormHour';
import FormLabel from '../../atoms/FormLabel';
import FormMinute from '../../atoms/FormMinute';
import ColAuto from "../../atoms/ColAuto";

class FormTimeInputComponentForForm extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      hourName: PropTypes.string,
      minuteName: PropTypes.string,
      onChange: PropTypes.func,
      startHour: PropTypes.number,
      startMinute: PropTypes.number,
      defaultValue: PropTypes.any,
      onChangeHour: PropTypes.func,
      onChangeStartDateTime: PropTypes.func,
      onChangeDateTime: PropTypes.func,
      name: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.render = this.render.bind(this);

    this.state = {
      value: { hour: '', minute: '' },
    };
  }

  onChangeHour(field, hourTagEvent) {
    const value = hourTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.value,
      hour: value,
    };

    this.setState({ value: timeHourMinute });

    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange(timeHourMinute);
    }

    if (this.props.onChangeHour) {
      this.props.onChangeHour(timeHourMinute);
    }
  }

  onChangeMinute(field, minuteTagEvent) {
    const value = minuteTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.value,
      minute: value,
    };

    this.setState({ value: timeHourMinute });

    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange(timeHourMinute);
    }

    if (this.props.onChangeStartDateTime) {
      this.props.onChangeDateTime(timeHourMinute);
    }
  }

  component(field) {
    return (
      <div className="form-group row">
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </FormLabel>
        <div className="col-sm-6">
          <Row>
            <ColAuto>
              <FormHour
                name={this.props.hourName}
                defaultValue={this.props.defaultValue}
                value={
                  field.input.value != null
                    ? field.input.value.hour
                    : this.props.startHour
                }
                onChange={this.onChangeHour.bind(this, field)}
              />
            </ColAuto>
            <ColAuto>
              <FormMinute
                name={this.props.minuteName}
                defaultValue={this.props.defaultValue}
                value={
                  field.input.value != null
                    ? field.input.value.minute
                    : this.props.startMinute
                }
                onChange={this.onChangeMinute.bind(this, field)}
              />
            </ColAuto>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    return <Field component={this.component} name={this.props.name} />;
  }
}

export default FormTimeInputComponentForForm;

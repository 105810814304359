import PropTypes from 'prop-types';
import React from 'react';

import InfoButton from '../../atoms/InfoButton';
import { ReferAction } from '../../utils/PermissionComponent';

class StaffSearchButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <ReferAction>
        <InfoButton onClick={this.props.onClick}>JAIC社員検索</InfoButton>
      </ReferAction>
    );
  }
}

export default StaffSearchButton;

import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Field } from 'redux-form';

import FormGroupRow from '../atoms/FormGroupRow';
import FormLabel from '../atoms/FormLabel';
import FormTextArea from '../atoms/FormTextArea';
import * as Validator from '../utils/Validate';

class HorizontalFormTextAreaComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    return (
      <FormGroupRow>
        {this.props.name ? (
          <Element name={`position-${this.props.name}`} />
        ) : (
          <></>
        )}
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          {this.isRequired() ? (
            <>
              <span className="text-danger">*</span>
            </>
          ) : (
            <></>
          )}
        </FormLabel>
        <div className="col-sm-6">
          <FormTextArea {...field} />
          {field.meta.touched && field.meta.error && (
            <Form.Control.Feedback type="invalid">
              {field.meta.error}
            </Form.Control.Feedback>
          )}
        </div>
      </FormGroupRow>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.component}
        validate={this.props.validate}
      />
    );
  }
}

export default HorizontalFormTextAreaComponent;

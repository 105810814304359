const initialState = {
  searchResults: {
    pagination: {
      page: 1,
      pagSize: 10,
      total: 0,
    },
    result: [],
  },
  templateRefer: {
    detail: {},
  },
  templateEdit: {
    isSubmitting: false,
    isComplete: false,
  },
  templateDelete: {
    isSubmitting: false,
    isConfirm: false,
    isComplete: false,
    errMessage: null,
  },
  isLoading: false,
  contactTypes: [],
  sendTestMailResult: null,
  searchParams: {
    params: {},
    values: {},
  },
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'template/FETCH_TEMPLATE_SEARCH': {
      const searchResults = action.payload.result;
      return { ...state, searchResults };
    }
    case 'SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'template/FETCH_TEMPLATE': {
      const results = action.payload.result;
      return {
        ...state,
        templateRefer: { ...state.templateRefer, detail: results },
        isLoading: false,
      };
    }
    case 'template/UPDATE_TEMPLATE_LOADING': {
      return {
        ...state,
        templateEdit: { ...state.templateEdit, isSubmitting: true },
      };
    }
    case 'template/FETCH_UPDATE_TEMPLATE': {
      return {
        ...state,
        templateEdit: { ...state.templateEdit, isComplete: true },
      };
    }
    case 'template/SHOW_DELETE_CONFIRM': {
      return {
        ...state,
        templateDelete: {
          ...state.baseDelete,
          isConfirm: true,
          errMessage: null,
        },
      };
    }
    case 'template/CLOSE_DELETE_CONFIRM': {
      return {
        ...state,
        templateDelete: {
          ...state.templateDelete,
          isConfirm: false,
        },
      };
    }
    case 'template/FETCH_DELETE_TEMPLATE': {
      return {
        ...state,
        templateDelete: {
          ...state.templateDelete,
          isConfirm: false,
          isComplete: true,
        },
      };
    }
    case 'template/CLOSE_DELETE_COMPLETE': {
      return {
        ...state,
        templateDelete: {
          ...state.templateDelete,
          isComplete: false,
        },
      };
    }
    case 'template/ERROR_DELETE': {
      return {
        ...state,
        templateDelete: {
          ...state.templateDelete,
          errMessage: '選択してください',
        },
      };
    }
    case 'template/DELETE_BASE_LOADING': {
      return {
        ...state,
        templateDelete: { ...state.templateDelete, isSubmitting: true },
      };
    }
    case 'template/SEND_TEST_MAIL': {
      return {
        ...state,
        sendTestMailResult: true,
      };
    }
    case 'template/SEND_TEST_MAIL_ERROR': {
      return {
        ...state,
        sendTestMailResult: false,
      };
    }
    case 'template/INIT_SEND_MAIL_RESULT': {
      return {
        ...state,
        sendTestMailResult: null,
      };
    }
    case 'template/NEW_TEMPLATE': {
      return {
        ...state,
        templateRefer: { detail: {} },
      };
    }
    case 'template/FETCH_CONTACT_TYPES': {
      return {
        ...state,
        contactTypes: action.payload.result,
      };
    }
    case 'template/INIT_STATE': {
      return initialState;
    }
    case 'template/STORE_SEARCH_VALUES': {
      return {
        ...state,
        searchParams: action.payload,
      };
    }
    default:
      return state;
  }
};

export { templateReducer as default };

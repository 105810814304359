import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';

const axios = createAxios('/admin/setting_password');

const fetchUpdatePasswordFailure = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      return {
        type: 'updatePassword/BAD_REQUEST',
        payload: error.response.data,
      };
    }
  }

  return fetchErrorData(error);
};

const updateUpdatePasswordLoading = () => {
  return {
    type: 'updatePassword/UPDATE_LOADING',
  };
};

const makeFetchUpdatePassword = (values, id) => {
  return (dispatch) => {
    dispatch(updateUpdatePasswordLoading());
    const params = new Params({
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      newPasswordConfirm: values.newPasswordConfirm,
      id: id,
    }).removeParams();
    const request = postUpdatePassword(params);
    request
      .then((data) => dispatch(fetchUpdatePasswordSuccess(data.data)))
      .catch((err) => dispatch(fetchUpdatePasswordFailure(err)));
  };
};

const postUpdatePassword = (params) => {
  return axios.post(`/admin/setting/password`, params);
};

const fetchUpdatePasswordSuccess = (data) => {
  return {
    type: 'updatePassword/FETCH_UPDATE_PASSWORD',
    payload: {
      result: data,
    },
  };
};

const initState = () => {
  return {
    type: 'updatePassword/INIT_STATE',
  };
};

export { makeFetchUpdatePassword, initState };

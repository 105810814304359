import React from 'react';

import CloseButton from './CloseButton';

class ModalCloseButton extends React.Component {
  /**
   * メイン
   */
  render() {
    return <CloseButton {...this.props} />;
  }
}

export default ModalCloseButton;

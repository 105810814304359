import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, FormControl } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Field } from 'redux-form';
import styled from 'styled-components';

import FormGroupRow from '../../atoms/FormGroupRow';
import InlineFormRadio from '../../atoms/InlineFormRadio';
import * as Validator from '../../utils/Validate';

class FormInlineRadioAndOtherComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          default: PropTypes.bool,
        }).isRequired
      ).isRequired,
      otherName: PropTypes.string,
      otherLabel: PropTypes.string,
      onChange: PropTypes.func,
      name: PropTypes.string,
      input: PropTypes.any,
      isRequired: PropTypes.bool,
      errorMessages: PropTypes.array,
    };
  }

  constructor(props) {
    super(props);

    this.state = { options: props.options };

    this.component = this.component.bind(this);
  }

  onChangeRadio(field, e) {
    console.debug('onChangeRadio', e);
    console.debug('onChangeRadio target', e.target);

    if (field.input) {
      console.debug('field.input.value', field.input.value);
      console.debug('e.target.value', e.target.value);
      const value = {
        ...field.input.value,
        value: e.target.value,
      };
      field.input.onChange(value);
    }
  }

  onChangeOtherText(field, e) {
    console.debug('onChangeOtherText', e);

    if (field.input) {
      console.debug('field.input.value', field.input.value);
      const value = {
        ...field.input.value,
        otherText: e.target.value,
      };

      field.input.onChange(value);
    }
  }

  isRequired() {
    return this.props.isRequired;
  }

  component(field) {
    return (
      <FormGroupRow>
        {this.props.name ? (
          <Element name={`position-${this.props.name}`} />
        ) : (
          <></>
        )}
        <label className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          {this.isRequired() ? <span className="text-danger">*</span> : <></>}
        </label>
        <Col xs={6}>
          {field.options.map((option, index) => {
            return (
              <InlineFormRadio
                label={option.name}
                key={index}
                name={this.props.name}
                id={this.props.name + option.name + option.value}
                {...field.input}
                value={option.value}
                onChange={this.onChangeRadio.bind(this, field)}
                checked={option.value === field.input.value.value}
              />
            );
          })}
          <InlineFormRadio
            {...field.input}
            label={this.props.otherLabel}
            key={`${this.props.label}-other`}
            id={this.props.name + 'other'}
            name={this.props.name}
            value="0"
            onChange={this.onChangeRadio.bind(this, field)}
            checked={field.input.value.value === '0'}
          />
          <FormControl
            type="text"
            className="form-input"
            name={this.props.otherName}
            value={field.input.value.otherText || ''}
            disabled={field.input.value.value !== '0'}
            onChange={this.onChangeOtherText.bind(this, field)}
          />
          {field.meta.error && (
            <DisplayFeedback type="invalid">{field.meta.error}</DisplayFeedback>
          )}
          {field.errorMessages != null && field.errorMessages.length > 0 ? (
            <div className="alert alert-danger">
              {field.errorMessages.map((message, i) => (
                <p key={i}>{message}</p>
              ))}
            </div>
          ) : (
            <></>
          )}
        </Col>
      </FormGroupRow>
    );
  }

  validation(value) {
    console.debug('FormInlineRadioAndOtherComponent', value);
    if (
      !value ||
      !Object.hasOwnProperty.call(value, 'value') ||
      !Object.hasOwnProperty.call(value, 'otherText')
    ) {
      return undefined;
    }
    if (value.value === '0' && !value.otherText) {
      return 'その他の場合は入力欄を埋めてください';
    } else if (value.value === '0' && value.otherText) {
      return Validator.maxLength(128, value.otherText);
    }
    return undefined;
  }

  render() {
    return (
      <Field
        component={this.component}
        name={this.props.name}
        errorMessages={this.props.errorMessages}
        validate={[this.validation]}
        options={this.props.options}
      />
    );
  }
}

const DisplayFeedback = styled(Form.Control.Feedback)`
  display: block;
`;

export default FormInlineRadioAndOtherComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import ColAuto from '../atoms/ColAuto';
import FormDatePicker from '../atoms/FormDatePickerForForm';
import FormGroupRow from '../atoms/FormGroupRow';
import FormHour from '../atoms/FormHour';
import FormLabel from '../atoms/FormLabel';
import FormMinute from '../atoms/FormMinute';

class FormPeriodDateTimeComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      startDateName: PropTypes.string,
      startHourName: PropTypes.string,
      startMinuteName: PropTypes.string,
      endDateName: PropTypes.string,
      endHourName: PropTypes.string,
      endMinuteName: PropTypes.string,
      startDate: PropTypes.any,
      startHour: PropTypes.number,
      startMinute: PropTypes.number,
      endDate: PropTypes.string,
      endHour: PropTypes.number,
      endMinute: PropTypes.number,
      defaultValue: PropTypes.number,

      onChangeStartDateTime: PropTypes.func,
      onChangeEndDateTime: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.toDate = this.toDate.bind(this);

    let startDate;
    if (this.props.startDate) {
      startDate = this.props.startDate;
    } else {
      startDate = '';
    }
    this.state = {
      start: { date: startDate, hour: '', minute: '' },
      end: { date: '', hour: '', minute: '' },
    };

    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeStartHour = this.onChangeStartHour.bind(this);
    this.onChangeStartMinute = this.onChangeStartMinute.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onChangeEndHour = this.onChangeEndHour.bind(this);
    this.onChangeEndMinute = this.onChangeEndMinute.bind(this);
  }

  toDate(str) {
    return new Date(str);
  }

  onChangeStartDate(date) {
    console.debug('date picker', date);
    const timeHourMinute = {
      ...this.state.start,
      date: date,
    };

    this.setState({ start: timeHourMinute });

    if (this.props.onChangeStartDateTime) {
      this.props.onChangeStartDateTime(timeHourMinute);
    }
  }

  onChangeStartHour(hourTagEvent) {
    const value = hourTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.start,
      hour: value,
    };

    this.setState({ start: timeHourMinute });

    if (this.props.onChangeStartDateTime) {
      this.props.onChangeStartDateTime(timeHourMinute);
    }
  }

  onChangeEndHour(hourTagEvent) {
    const value = hourTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.end,
      hour: value,
    };

    this.setState({ end: timeHourMinute });

    if (this.props.onChangeEndDateTime) {
      this.props.onChangeEndDateTime(timeHourMinute);
    }
  }

  onChangeStartMinute(minuteTagEvent) {
    const value = minuteTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.start,
      minute: value,
    };

    this.setState({ start: timeHourMinute });

    if (this.props.onChangeStartDateTime) {
      this.props.onChangeStartDateTime(timeHourMinute);
    }
  }

  onChangeEndMinute(minuteTagEvent) {
    const value = minuteTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.end,
      minute: value,
    };

    this.setState({ end: timeHourMinute });

    if (this.props.onChangeEndDateTime) {
      this.props.onChangeEndDateTime(timeHourMinute);
    }
  }

  onChangeEndDate(date) {
    console.debug('date picker', date);

    const timeHourMinute = {
      ...this.state.end,
      date: date,
    };

    this.setState({ end: timeHourMinute });

    if (this.props.onChangeEndDateTime) {
      this.props.onChangeEndDateTime(timeHourMinute);
    }

    return timeHourMinute;
  }

  render() {
    return (
      <FormGroupRow>
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </FormLabel>
        <div className="col-sm-6">
          <NoMarginRow>
            <NoLeftPaddingColAuto>
              <FormDatePicker
                highlightWeekend
                name={this.props.startDateName}
                day={this.state.start.date}
                setDay={this.state.start.date}
                format="YYYY/MM/DD"
                locale="ja"
                onChange={this.onChangeStartDate}
              />
            </NoLeftPaddingColAuto>
            <NoLeftPaddingColAuto>
              <FormHour
                name={this.props.startHourName}
                defaultValue={this.props.defaultValue}
                value={this.props.startHour}
                onChange={this.onChangeStartHour}
              />
            </NoLeftPaddingColAuto>
            <NoLeftPaddingColAuto>
              <FormMinute
                name={this.props.startMinuteName}
                defaultValue={this.props.defaultValue}
                value={this.props.startMinute}
                onChange={this.onChangeStartMinute}
              />
            </NoLeftPaddingColAuto>
          </NoMarginRow>
          <NoMarginRow>
            <NoLeftPaddingColAuto className="col">
              <FormDatePicker
                highlightWeekend
                name={this.props.endDateName}
                day={this.state.end.date}
                setDay={this.state.end.date}
                format="YYYY/MM/DD"
                locale="ja"
                onChange={this.onChangeEndDate}
              />
            </NoLeftPaddingColAuto>
            <NoLeftPaddingColAuto>
              <FormHour
                name={this.props.endHourName}
                defaultValue={this.props.defaultValue}
                value={this.props.endHour}
                onChange={this.onChangeEndHour}
              />
            </NoLeftPaddingColAuto>
            <NoLeftPaddingColAuto>
              <FormMinute
                name={this.props.endMinuteName}
                defaultValue={this.props.defaultValue}
                value={this.props.endMinute}
                onChange={this.onChangeEndMinute}
              />
            </NoLeftPaddingColAuto>
          </NoMarginRow>
        </div>
      </FormGroupRow>
    );
  }
}

const NoMarginRow = styled(Row)`
  margin-left: 0px;
`;

const NoLeftPaddingColAuto = styled(ColAuto)`
  padding-left: 0px;
`;

export default FormPeriodDateTimeComponent;

import PropTypes, { shape, string } from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from 'styled-components';

import NormalButton from '../../atoms/NormalButton';

class EventsEditParticipants extends React.Component {
  static get propTypes() {
    return {
      substituteUser: shape({
        id: string,
      }),
      list: PropTypes.any,
      maxOfSeat: PropTypes.number,
      onDeleteClick: PropTypes.func,
      onAddClick: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      isShowUserList: false,
    };
  }

  onDeleteButtonClick(id) {
    this.props.onDeleteClick(id);
  }

  render() {
    const isSubstituteUser = (id) => {
      if (this.props.substituteUser) {
        const substituteUser = this.props.substituteUser;

        return substituteUser.id === id;
      }
      return false;
    };
    return (
      <>
        <div>
          {this.props.list.length} / {this.props.maxOfSeat} 人
        </div>
        <br />
        {this.props.list.map((single, i) => {
          return (
            <PaddingRow key={i}>
              <div className="col-6">{single.name}</div>
              {isSubstituteUser(single.id) ? (
                <></>
              ) : (
                <Col>
                  <NormalButton
                    color="danger"
                    onClick={this.onDeleteButtonClick.bind(this, single.id)}
                  >
                    削除
                  </NormalButton>
                </Col>
              )}
            </PaddingRow>
          );
        })}
        {this.props.list.length < this.props.maxOfSeat ? (
          <div>
            <br />
            <NormalButton
              onClick={this.props.onAddClick}
              isOutline={true}
              color="dark"
            >
              ＋
            </NormalButton>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const PaddingRow = styles(Row)`
  padding: 2.5px;
`;

export default EventsEditParticipants;

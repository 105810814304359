import PropTypes from 'prop-types';
import React from 'react';

class Link extends React.Component {
  static get propTypes() {
    return {
      href: PropTypes.string
    };
  }

  render() {
    return (
      <a href={this.props.href}>
        {this.props.href}
      </a>
    );
  }
}

export default Link;

import PropTypes from 'prop-types';
import React from 'react';

class InlineFormCheckbox extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      optionValue: PropTypes.any,
      id: PropTypes.string,
      onChange: PropTypes.func,
      input: PropTypes.object,
      label: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    console.debug(`InlineFormCheckbox props:`, props);
  }

  onChange(value, onChange, e) {
    console.debug('inline form checkbox onChange', value, onChange, e);

    const targetValue = e.target.value;
    const checked = e.target.checked;

    if (onChange) {
      if (checked) {
        if (!value.includes(targetValue)) {
          onChange(value.concat([targetValue]));
        }
      } else {
        onChange(
          value.filter((value) => {
            return value !== targetValue;
          })
        );
      }
    }

    console.debug('targetValue', targetValue, 'checked', checked);
  }

  render() {
    const {
      input: { onChange, ...other },
      label,
    } = this.props;

    console.debug('inlineformcheckbox props', this.props);

    return (
      <div className="form-check form-check-inline">
        <input
          type="checkbox"
          className="form-check-input"
          onChange={this.onChange.bind(this, other.value, onChange)}
          checked={other.value.includes(this.props.optionValue)}
          id={this.props.id}
          value={this.props.optionValue}
        />
        <label className="form-check-label" htmlFor={this.props.id}>
          {label}
        </label>
      </div>
    );
  }
}

export default InlineFormCheckbox;

import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RouteWithSubRoutes from '../../RouterUtil';
import { ActionCreatorProvider } from '../../utils/PermissionComponent';
import NotFound from '../common/NotFound';

class ConsultationPlan extends React.Component {
  static get propTypes() {
    return {
      routes: PropTypes.array,
    };
  }

  render() {
    return (
      <ActionCreatorProvider menu={'/admin/interviews'}>
        <Switch>
          {this.props.routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </ActionCreatorProvider>
    );
  }
}

export default ConsultationPlan;

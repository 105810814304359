import PropTypes, { bool, object, func } from 'prop-types';
import React from 'react';

import VenueManagementSearch from '../../templates/venueManagement/Search';

import routePath from './routePath';

class Search extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        searchOptions: PropTypes.object,
        searchParams: PropTypes.object,
        isShowDisableSuccessModal: bool,
        searchResults: PropTypes.shape({
          pagination: PropTypes.shape({
            pageSize: PropTypes.number,
            total: PropTypes.number,
            page: PropTypes.number,
          }),
          hallList: PropTypes.array,
        }),
        areas: PropTypes.shape({
          areaList: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
            })
          ),
        }),
        isDisplayContainsUsedAlertModal: PropTypes.bool,
        isDisplayDeleteConfirmModal: PropTypes.bool,
      }),
      searchData: PropTypes.func,
      fetchArea: PropTypes.func,
      history: object,
      deleteData: func,
      hideDeleteSuccessModal: func,
      venueUsed: PropTypes.func,
      hideContainsUsedAlertModal: PropTypes.func,
      hideDeleteConfirmModal: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.search = this.search.bind(this);
    this.moveDetail = this.moveDetail.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
    this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this);
    this.selectionDelete = this.selectionDelete.bind(this);
    this.onClickDeleteModalCloseButton = this.onClickDeleteModalCloseButton.bind(
      this
    );

    this.props.fetchArea();

    this.state = {
      name: '',
      prefectureId: null,
      areas: [],
      deleteSelections: [],
    };
  }

  search(state, searchParams, values) {
    this.setState({ searchParams: searchParams, values: values });
    this.props.searchData(values, searchParams);
  }

  moveDetail(id) {
    this.props.history.push(routePath.refer(id));
  }

  transitionToEdit() {
    this.props.history.push(routePath.newEdit);
  }

  selectionDelete(id) {
    const currentSelections = this.state.deleteSelections;

    let newSelections;
    if (currentSelections.indexOf(id) !== -1) {
      newSelections = currentSelections.filter((selectionId) => {
        return selectionId !== id;
      });
    } else {
      newSelections = currentSelections.concat([id]);
    }

    this.setState({ deleteSelections: newSelections });
  }

  onDeleteButtonClick() {
    console.debug('delete venue ids:', this.state.deleteSelections);

    if (
      !(this.state.deleteSelections && this.state.deleteSelections.length > 0)
    ) {
      return;
    }

    this.props.venueUsed(this.state.deleteSelections);
  }

  onDeleteConfirmYesButtonClick() {
    console.debug('delete venue ids:', this.state.deleteSelections);

    if (
      !(this.state.deleteSelections && this.state.deleteSelections.length > 0)
    ) {
      return;
    }

    this.props.deleteData(this.state.deleteSelections);
  }

  onClickDeleteModalCloseButton() {
    this.setState({ deleteSelections: [] });
    this.props.hideDeleteSuccessModal();
    const searchParams = this.state.searchParams;
    const values = this.state.values;
    this.search({}, searchParams, values);
  }

  render() {
    return (
      <section className="section">
        <div className="section-header">
          <h1>会場管理</h1>
        </div>
        <div className="section-body">
          <VenueManagementSearch
            areas={
              this.props.state.areas != null
                ? this.props.state.areas.areaList
                : []
            }
            searchData={
              this.props.state.searchResults != null
                ? this.props.state.searchResults
                : []
            }
            searchParams={this.props.state.searchOptions}
            searchValues={this.props.state.searchParams}
            isShowDisableSuccessModal={
              this.props.state.isShowDisableSuccessModal
            }
            searchFunction={this.search}
            moveDetailFunction={this.moveDetail}
            transitionToEditFunction={this.transitionToEdit}
            selectionDelete={this.selectionDelete}
            onDeleteButtonClick={this.onDeleteButtonClick}
            onClickDeleteModalCloseButton={this.onClickDeleteModalCloseButton}
            isDisplayContainsUsedAlertModal={
              this.props.state.isDisplayContainsUsedAlertModal
            }
            isDisplayDeleteConfirmModal={
              this.props.state.isDisplayDeleteConfirmModal
            }
            hideContainsUsedAlertModal={this.props.hideContainsUsedAlertModal}
            onNoClick={this.props.hideDeleteConfirmModal}
            onYesClick={this.onDeleteConfirmYesButtonClick.bind(this)}
          />
        </div>
      </section>
    );
  }
}

export default Search;

export default {
  /**
   *  @type {string} 検索画面
   */
  search: '/admin/venues/',
  /**
   * 参照画面
   * @param {string} id 社員ID
   */
  refer: (id) => {
    return `/admin/venues/refer/${id}`;
  },
  /**
   * @type {string} 新規作成画面
   */
  newEdit: '/admin/venues/edit/',
  /**
   * 編集画面
   * @param {string} id 社員ID
   */
  edit: (id) => {
    return `/admin/venues/edit/${id}`;
  },
};

import PropTypes from 'prop-types';
import React from 'react';

import FormLabel from '../atoms/FormLabel';

class FormLabelComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      data: PropTypes.string
    };
  }

  render() {
    return (
      <div className="form-group">
        <FormLabel>{this.props.label}</FormLabel>
        <FormLabel>　</FormLabel>
        <FormLabel>{this.props.data}</FormLabel>
      </div>
    );
  }
}

export default FormLabelComponent;

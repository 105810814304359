import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import {formValueSelector, reduxForm} from 'redux-form';

import ConfirmButton from "../../molecules/ConfirmButton";
import Option from "../../model/Option";
import FormInlineCheckBoxes from "../../molecules/FormInlineCheckBoxesComponent";
import * as Validator from "../../utils/Validate";
import NormalButton from "../../atoms/NormalButton";
import DeleteButton from "../../molecules/DeleteButton";
import FormInlineCheckBoxesComponent from "../../molecules/FormInlineCheckBoxesComponent";
import {connect} from "react-redux";
import {
  makeFetchDisplayAreas,
  makeFetchHalls,
  makeFetchMethodTypes,
  makeFetchTags
} from "../../../actions/ConsultationPlan";
import PrevButton from "../../molecules/PrevButton";

class FormTemplateForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      onClickPrev: PropTypes.func,
    };
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          会員登録(必須)
          <FormInlineCheckBoxesComponent
            label="デフォルトエリア項目"
            name="default_area"
            options={[
              new Option('105', 'お住いの都道府県'),
              new Option('106', 'お住いのエリア'),
              new Option('107', '就職を希望するエリア'),
              new Option('108', '説明会を希望するエリア'),
            ]}
          />
          <FormInlineCheckBoxes
            label="予約の有無"
            options={[
              new Option('200', 'イベント/面談予約'),
            ]}
            name="reservation_item"
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <PrevButton
                onClick={this.props.onClickPrev}
              />
              <ConfirmButton
                isNew={true}
                isEnabled={() => this.props.canClickNextButton}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

// export default Search;
const formTemplateForm = reduxForm({
  // a unique name for the form
  form: 'formManagementCreateTemplateForm',
  enableReinitialize: true,
})(FormTemplateForm);

const selector = formValueSelector('formManagementCreateTemplateForm');

export default connect(
  (state) => {
    const selectedAreas = selector(state, "default_area")

    return {
      initialValues: {
        default_area: [],
        reservation_item: []
      },
      canClickNextButton: selectedAreas && selectedAreas.length > 0
    };
  },
  (dispatch) => {
    return {
    };
  }
)(formTemplateForm);

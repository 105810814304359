import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormGroupRow from '../atoms/FormGroupRow';
import InlineFormCheckbox from '../atoms/InlineFormCheckbox';

class FormInlineCheckBoxes extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }).isRequired
      ).isRequired,
      onChange: PropTypes.func,
      name: PropTypes.string,
      description: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.state = { selections: [] };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    let newSelections;
    const id = e.target.value;

    const selections = this.state.selections;
    if (e.target.checked) {
      if (selections.some((selection) => selection === id)) {
        newSelections = selections;
      } else {
        newSelections = selections.concat([id]);
      }
    } else {
      if (selections.some((selection) => selection === id)) {
        newSelections = selections.filter((selection) => selection !== id);
      } else {
        newSelections = selections;
      }
    }

    this.setState({ selections: newSelections });

    this.props.onChange(newSelections);
  }

  render() {
    return (
      <FormGroupRow>
        <label className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </label>
        <Col xs={6} className=" align-items-center">
          <div>
            {this.props.options.map((option, index) => {
              console.debug('option.value', option.value);
              return (
                <Field
                  component={InlineFormCheckbox}
                  key={index}
                  name={`${this.props.name}`}
                  id={this.props.label + option.name}
                  label={option.name}
                  optionValue={option.value}
                />
              );
            })}
          </div>
          {this.props.description ? (
            <div>
              <p>{this.props.description}</p>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </FormGroupRow>
    );
  }
}

export default FormInlineCheckBoxes;

import PropTypes from 'prop-types';
import React from 'react';

class ModalHeader extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
    };
  }

  render() {
    return <div className="modal-header">{this.props.children}</div>;
  }
}

export default ModalHeader;

const initialState = {
  areas: [],
  bases: [],
  divisions: [],
  occupations: [],
  roles: [],
  halls: [],
  categories: [],
  tags: [],
  templateCategories: [],
  templateNames: [],
  templateGroups: [],
  prefs: [],
  eventTypes: [],
  eventMethodTypes: [],
  methodTypes: [],
  companyNames: [],
  sfCompanyNames: [],
  currentStatuses: [],
  collegeRegistrationCategories: [],
  sfItems: [],
  rolePermissions: [],
  displayAreas: [],
};

const optionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_AREAS': {
      const areas = action.payload.result;
      console.debug('areas', areas);
      return { ...state, areas: areas };
    }
    case 'FETCH_BASES': {
      const bases = action.payload.result;
      return { ...state, bases: bases };
    }
    case 'FETCH_OCCUPATIONS': {
      const occupations = action.payload.result;
      return { ...state, occupations: occupations };
    }
    case 'FETCH_DIVISIONS': {
      const divisions = action.payload.result;
      return { ...state, divisions: divisions };
    }
    case 'FETCH_PREF_GROUPS': {
      const prefGroups = action.payload.result;
      return { ...state, prefGroups: prefGroups };
    }
    case 'FETCH_ROLES': {
      const roles = action.payload.result;
      return { ...state, roles: roles };
    }
    case 'FETCH_HALLS': {
      const halls = action.payload.result;
      return { ...state, halls: halls };
    }
    case 'FETCH_CATEGORIES': {
      const categories = action.payload.result;
      return { ...state, categories: categories };
    }
    case 'option/FETCH_TAGS': {
      const tags = action.payload.result;
      return { ...state, tags: tags };
    }
    case 'FETCH_TEMPLATE_CATEGORIES': {
      const templateCategories = action.payload.result;
      return { ...state, templateCategories: templateCategories };
    }
    case 'FETCH_TEMPLATE_NAMES': {
      const templateNames = action.payload.result;
      return { ...state, templateNames: templateNames };
    }
    case 'FETCH_TEMPLATE_GROUPS': {
      const templateGroups = action.payload.result;
      return { ...state, templateGroups: templateGroups };
    }
    case 'FETCH_PREFS': {
      const prefs = action.payload.result;
      return { ...state, prefs: prefs };
    }
    case 'option/FETCH_EVENT_TYPE': {
      const eventTypes = action.payload.result;
      return { ...state, eventTypes: eventTypes };
    }
    case 'option/FETCH_EVENT_METHOD_TYPE': {
      const eventMethodTypes = action.payload.result;
      return { ...state, eventMethodTypes: eventMethodTypes };
    }
    case 'option/FETCH_COMPANY_NAMES': {
      const companyNames = action.payload.result;
      return { ...state, companyNames: companyNames };
    }
    case 'option/FETCH_CURRENT_STATUSES': {
      const currentStatuses = action.payload.result;
      return { ...state, currentStatuses: currentStatuses };
    }
    case 'option/COLLEGE_REGISTRATION_CATEGORIES': {
      const collegeRegistrationCategories = action.payload.result;
      return {
        ...state,
        collegeRegistrationCategories: collegeRegistrationCategories,
      };
    }
    case 'option/FETCH_ROLE_PERMISSIONS': {
      const rolePermissions = action.payload.result;
      return { ...state, rolePermissions: rolePermissions };
    }
    case 'options/SF_COMPANY_NAMES': {
      const companyNames = action.payload;
      return { ...state, sfCompanyNames: companyNames };
    }
    case 'INIT_OPTIONS': {
      return {
        ...state,
        roles: [],
        bases: [],
        occupations: [],
        divisions: [],
        prefs: [],
        companyNames: [],
        currentStatuses: [],
        collegeRegistrationCategories: [],
        prefGroups: [],
      };
    }
    case 'OPTIONS/METHOD_TYPE': {
      const methodTypes = action.payload;
      return { ...state, methodTypes: methodTypes };
    }
    case 'INIT_STATE_FETCH_HALLS': {
      return { ...state, halls: [] };
    }
    case 'FETCH_SF_ITEMS': {
      return { ...state, sfItems: action.payload.result };
    }
    case 'OPTIONS/DISPLAY_AREAS': {
      return { ...state, displayAreas: action.payload.result };
    }
    case 'option/CLEAR_HALLS': {
      return {
        ...state,
        halls: [],
      };
    }
    default:
      return state;
  }
};

export { optionsReducer as default };

import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { FadeLoader } from 'react-spinners';
import styled from 'styled-components';

const Spinner = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #66666688;
  `;

  const MiddleContainer = styled.div`
    display: table-cell;
    vertical-align: middle;
  `;

  return (
    promiseInProgress && (
      <Overlay>
        <MiddleContainer>
          <FadeLoader
            css={{ margin: '0 auto;', display: 'block' }}
            color="#333333"
            height="15"
            width="5"
            radius="2"
            margin="2"
          />
        </MiddleContainer>
      </Overlay>
    )
  );
};

export default Spinner;

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import {
  makeFetchHalls,
  makeFetchMethodTypes,
  makeFetchTags,
  makeFetchDisplayAreas,
  makeFetchEditDataForModal,
} from '../../../actions/ConsultationPlan';
import Necessities, { NecessitiesType } from '../../../domain/Necessities';
import Option from '../../model/Option';
import FormInlineCheckBoxesComponent from '../../molecules/FormInlineCheckBoxesComponent';
import FormInlineRadioComponentForForm from '../../molecules/FormInlineRadioComponentForForm';
import FormNumberOfPeopleComponent from '../../molecules/FormNumberOfPeopleComponent';
import FormTimeInputComponentForForm from '../../molecules/formsManagement/FormTimeInputComponentForForm';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalFormTextAreaComponent from '../../molecules/HorizontalFormTextareaComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import * as Validator from '../../utils/Validate';
import DisplayAreaCheckbox from '../events/DisplayAreaCheckboxComponent';
import FormInlineRadioAndOtherComponent from '../events/FormInlineRadioAndOtherComponent';

const selector = formValueSelector('consultationPlanEditForm');

const validateURL = (value) => {
  return Validator.maxLength(256, value);
};
const validateDefaultMessage = (value) => {
  return Validator.maxLength(128, value);
};

class ConsultationPlanEditForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      cancelFunction: PropTypes.func,
      isHidden: PropTypes.bool,
      fetchMethodTypes: PropTypes.func,
      fetchTag: PropTypes.func,
      makeFetchHalls: PropTypes.func,
      fetchDisplayAreas: PropTypes.func,
      newInterviewStart: PropTypes.instanceOf(Date),
      data: PropTypes.shape({
        fromHour: PropTypes.number,
        seatAvailability: PropTypes.number,
      }),
      methodTypeForOption: PropTypes.array,
      needVenue: PropTypes.bool,
      options: PropTypes.shape({
        halls: PropTypes.array,
        tags: PropTypes.array,
        displayAreas: PropTypes.array,
        sfCompanyNames: PropTypes.array,
      }),
      unselectVenue: PropTypes.bool,
      bases: PropTypes.array,
      needUrl: PropTypes.bool,
      fetchEditDataForModal: PropTypes.func,
      isLoading: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.getFromHour = this.getFromHour.bind(this);
    this.getFromMinutes = this.getFromMinutes.bind(this);
  }

  componentDidMount() {
    this.props.fetchEditDataForModal();
  }

  getFromHour() {
    if (this.props.newInterviewStart != null) {
      const date = new Date(this.props.newInterviewStart);
      return date.getHours();
    } else if (this.props.data != null) {
      return this.props.data.fromHour;
    }
    return new Date();
  }

  getFromMinutes() {
    if (this.props.newInterviewStart != null) {
      const date = new Date(this.props.newInterviewStart);
      return date.getMinutes();
    } else if (this.props.data != null) {
      return this.props.data.fromHour;
    }
    return new Date();
  }

  render() {
    const seatAvailability = this.props.data.seatAvailability;
    const options = this.props.methodTypeForOption;
    const defaultType = options
      ? options.find((option) => option.value === 'AAECAwQFBgcICQoLDA0ODyw=')
      : '';
    return !this.props.isLoading ? (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <FormInlineRadioComponentForForm
            label="枠"
            options={[new Option('1', '通常枠'), new Option('2', '特別枠')]}
            name="frameType"
            validate={[Validator.required]}
          />
          <FormInlineRadioComponentForForm
            label="面談タイプ"
            options={
              this.props.methodTypeForOption
                ? this.props.methodTypeForOption.concat([
                    new Option('0', '未選択'),
                  ])
                : []
            }
            defaultValue={defaultType ? defaultType.value : ''}
            name="methodType"
          />
          <FormTimeInputComponentForForm
            name="startTime"
            label="開催開始日時"
            hourName="fromHour"
            minuteName="fromMinute"
            isRequired={true}
          />
          <FormTimeInputComponentForForm
            name="endTime"
            label="開催終了日時"
            hourName="toHour"
            minuteName="toMinute"
            isRequired={true}
          />
          {this.props.needVenue && (
            <>
              <HorizontalSelectComponent
                label="会場"
                options={this.props.options.halls}
                name="venueId"
              />
              {this.props.unselectVenue && (
                <HorizontalFormInputComponent
                  label="会場未選択文言"
                  name="unselectVenue"
                  component="input"
                  type="text"
                  validate={[Validator.required, validateDefaultMessage]}
                />
              )}
            </>
          )}
          {this.props.needUrl && (
            <HorizontalFormInputComponent
              label="Web面談用URL"
              name="webInterviewUrl"
              component="input"
              type="text"
              validate={[validateURL]}
            />
          )}
          <FormInlineCheckBoxesComponent
            label="コース"
            name="tags"
            options={this.props.options.tags}
            description="専門の面談日程（中退専門など）でない場合は、設定する必要はありません。"
          />
          <FormNumberOfPeopleComponent
            label="定員"
            name="seatAvailability"
            defaultValue={seatAvailability != null ? seatAvailability : '1'}
            validate={[Validator.required]}
          />
          <FormInlineRadioAndOtherComponent
            label="服装"
            name="isClothesNormal"
            options={[{ name: 'デフォルト(自由)', value: '1' }]}
            otherName="clothesOther"
            otherLabel="その他"
            isRequired={true}
            errorMessages={[]}
          />
          <FormInlineRadioAndOtherComponent
            label="持ち物"
            name="isBeglongingsNormal"
            options={[
              {
                name: new Necessities(
                  new NecessitiesType.Default(),
                  !this.props.needVenue
                ).displayName,
                value: '1',
              },
            ]}
            otherName="belongingsOther"
            otherLabel="その他"
            isRequired={true}
            errorMessages={[]}
          />
          <HorizontalFormTextAreaComponent
            label="備考(会員閲覧用)"
            name="descriptionForUser"
            component="input"
            type="textarea"
          />

          <HorizontalFormTextAreaComponent
            label="備考(個人用)"
            name="descriptionForPersonal"
            component="input"
            type="textarea"
          />
          <HorizontalSelectComponent
            label="担当拠点"
            options={this.props.bases}
            name="chargeBase"
            validate={[Validator.required]}
          />
          <HorizontalSelectComponent
            label="企業名"
            options={this.props.options.sfCompanyNames}
            name="companyName"
            validate={[Validator.required]}
          />
          <DisplayAreaCheckbox
            label="表示エリア"
            name="displayAreaIds"
            options={this.props.options.displayAreas}
          />
        </div>
        <Row>
          <Col>
            <div className="text-right">
              <button
                className="btn btn-secondary mr-1"
                type="button"
                onClick={this.props.cancelFunction}
              >
                キャンセル
              </button>
              <button type="submit" className="btn btn-primary mr-1">
                登録する
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    ) : (
      <></>
    );
  }
}

// export default Search;
const interviewEditForm = reduxForm({
  // a unique name for the form
  form: 'consultationPlanEditForm',
  enableReinitialize: true,
})(ConsultationPlanEditForm);

export default connect(
  (state) => {
    const methodTypeRes = state.consultationPlan.methodTypes.find(
      (methodType) => methodType.id === selector(state, 'methodType')
    );
    const needVenue = methodTypeRes != null && methodTypeRes.needVenue;

    const needUrl = methodTypeRes != null && methodTypeRes.needUrl;

    const selectedVenue = selector(state, 'venueId');
    const unselectVenue =
      needVenue && (selectedVenue == null || selectedVenue === '');

    const methodTypeForOption = state.consultationPlan.methodTypes.map(
      (option) => {
        return new Option(option.id, option.name);
      }
    );

    let initialValues = state.consultationPlan.getByIdResult;
    if (state.consultationPlan.selectSlot != null) {
      const startDate = new Date(state.consultationPlan.selectSlot.start);
      const endDate = new Date(state.consultationPlan.selectSlot.end);
      initialValues = {
        fromHour: startDate.getHours(),
        fromMinute: startDate.getMinutes(),
        toHour: endDate.getHours(),
        toMinute: endDate.getMinutes(),
      };
    }

    // 服装
    let isAttire;
    if (initialValues.clothes === true || initialValues.clothes == null) {
      isAttire = '1';
    } else {
      isAttire = '0';
    }

    // 持ち物
    let isNecessities;
    if (initialValues.belongings === true || initialValues.belongings == null) {
      isNecessities = '1';
    } else {
      isNecessities = '0';
    }

    // tag
    let tags = [];
    if (initialValues.tags != null && initialValues.tags.length > 0) {
      tags = initialValues.tags.map((tag) => tag.id);
    }

    const chargeBase =
      initialValues.chargeBase ||
      (state.consultationPlan.editModal.isDefaultSettingBase
        ? state.consultationPlan.profile.baseId
        : null);

    const companyName =
      initialValues.companyName ||
      (state.consultationPlan.editModal.isDefaultSettingBase
        ? state.consultationPlan.profile.companyName
        : null);

    return {
      initialValues: {
        ...initialValues,
        frameType: String(initialValues.frameType),
        isClothesNormal: {
          value: isAttire,
          otherText: initialValues.clothesOther,
        },
        isBeglongingsNormal: {
          value: isNecessities,
          otherText: initialValues.belongingsOther,
        },
        tags: tags,
        venueId: initialValues.venue,
        startTime: {
          hour: String(initialValues.fromHour),
          minute: String(initialValues.fromMinute),
        },
        endTime: {
          hour: String(initialValues.toHour),
          minute: String(initialValues.toMinute),
        },
        displayAreaIds: initialValues.displayAreas || [],
        unselectVenue: initialValues.defaultMessage,
        chargeBase: chargeBase,
        companyName: companyName,
      },
      isLoading: state.consultationPlan.editModal.isLoading,
      methodTypes: state.consultationPlan.methodTypes,
      referData: state.consultationPlan.getByIdResult,
      methodTypeForOption: methodTypeForOption,
      needVenue: needVenue,
      needUrl: needUrl,
      unselectVenue: unselectVenue,
      options: state.options,
    };
  },
  (dispatch) => {
    return {
      fetchMethodTypes() {
        dispatch(makeFetchMethodTypes());
      },
      fetchTag() {
        dispatch(makeFetchTags());
      },
      makeFetchHalls() {
        dispatch(makeFetchHalls());
      },
      fetchDisplayAreas() {
        dispatch(makeFetchDisplayAreas());
      },
      fetchEditDataForModal() {
        dispatch(makeFetchEditDataForModal());
      },
    };
  }
)(interviewEditForm);

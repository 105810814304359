// eslint-disable-next-line no-unused-vars
import moment from 'moment';

import {
  ReservationFormatParam,
  toReservationFormat,
} from '../components/utils/DateFormat';

const SEARCH_STATE = {
  INIT: -1,
  EVENT: 0,
  INTERVIEW: 1,
};

const initialState = {
  ProxyBooking: {
    memberId: '',
    memberName: '',
  },
  prevState: SEARCH_STATE.INIT,
  searchValues: {},
  reservationManagementEvents: {
    isProxyBooking: false,
    isShowPlansDetailModal: false,
    events: [],
    eventsDetail: {
      list: [],
    },
  },
  reservationManagementInterview: {
    isProxyBooking: false,
    isShowPlansDetailModal: false,
    interviews: [],
    interviewsDetail: null,
  },
  reservationManagementEventsEdit: {
    isProxyBooking: false,
    isShowParticipantsSearchModal: false,
    event: {},
    participants: [],
    errorMessage: null,
  },
  reservationManagementInterviewEdit: {
    isProxyBooking: false,
    isShowParticipantsSearchModal: false,
    interview: {},
    interviewees: [],
    deleteInterviewees: [],
    errorMessage: null,
  },
  reservationManagementUsers: {
    users: {
      result: [],
    },
    isShowModal: false,
  },
  isComplete: false,
  isLoading: false,
};

/**
 *
 * @param {Object} state
 * @param {Action | EmptyAction} action
 */
const reservationManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'reservation/FETCH_RESERVATION_EVENTS':
      console.log('reservation/FETCH_RESERVATION_EVENTS');
      console.log(action.payload.result);
      return {
        ...state,
        reservationManagementEvents: {
          ...state.reservationManagementEvents,
          events: action.payload.result,
        },
      };
    case 'reservation/FETCH_RESERVATION_INTERVIEWS': {
      return {
        ...state,
        prevState: SEARCH_STATE.INTERVIEW,
        searchValues: action.payload.values,
        reservationManagementInterview: {
          ...state.reservationManagementInterview,
          interviews: action.payload.result,
        },
      };
    }
    case 'reservation/FETCH_RESERVATION_EVENTS_DETAIL': {
      const event = action.payload.result;

      const param = new ReservationFormatParam(
        event.dateYMDFrom,
        event.dateHHFrom,
        event.dateMMFrom,
        event.dateHHTo,
        event.dateMMTo
      );

      const eventDateTo = new EventDateTo(
        event.dateYMDFrom,
        event.dateHHTo,
        event.dateMMTo
      );

      const dateOfHolding = toReservationFormat(param);
      const accordionTitle = '0' + '/' + event.seatAvailability + 'の参加者';
      const userList = event.participants;
      const eventsDetail = {
        id: event.id,
        title: event.name,
        dateOfHolding: dateOfHolding,
        lecturer: event.chairmanName,
        venue: event.venue,
        accordionTitle: accordionTitle,
        list: userList,
        seatAvailability: event.seatAvailability,
        kindId: event.kindId,
        tags: event.tags,
        isFullParticipant: event.seatAvailability === userList.length,
        isExpired: eventDateTo.isExpired(moment()),
      };

      console.debug(
        'FETCH_RESERVATION_EVENTS_DETAIL state',
        state.reservationManagementEvents
      );
      return {
        ...state,
        reservationManagementEvents: {
          ...state.reservationManagementEvents,
          eventsDetail: eventsDetail,
          isShowPlansDetailModal: true,
        },
      };
    }
    case 'reservation/HIDE_EVENTS_DETAIL_MODAL': {
      return {
        ...state,
        reservationManagementEvents: {
          ...state.reservationManagementEvents,
          isShowPlansDetailModal: false,
        },
      };
    }
    case 'reservation/HIDE_INTERVIEW_DETAIL_MODAL': {
      return {
        ...state,
        reservationManagementInterview: {
          ...state.reservationManagementInterview,
          isShowPlansDetailModal: false,
        },
      };
    }
    case 'reservation/FETCH_RESERVATION_INTERVIEWS_DETAIL': {
      const interview = action.payload.result;
      const param = new ReservationFormatParam(
        interview.date,
        interview.fromHour,
        interview.fromMinute,
        interview.toHour,
        interview.toMinute
      );

      const dateOfHolding = toReservationFormat(param);

      const eventDateTo = new EventDateTo(
        interview.date,
        interview.toHour,
        interview.toMinute
      );

      const interviewDetail = {
        id: interview.id,
        tags: interview.tags || [],
        dateOfHolding: dateOfHolding,
        methodType: interview.methodType,
        interviewer: interview.interviewer,
        interviewerId: interview.interviewerId,
        venue: interview.venueName,
        descriptionForPersonal: interview.descriptionForPersonal,
        interviewees: interview.interviewees,
        seatAvailability: interview.seatAvailability,
        isExpired: eventDateTo.isExpired(moment()),
      };
      return {
        ...state,
        reservationManagementInterview: {
          ...state.reservationManagementInterview,
          interviewsDetail: interviewDetail,
          isShowPlansDetailModal: true,
        },
        reservationManagementInterviewEdit: {
          interview: interview,
        },
      };
    }
    case 'reservation/FETCH_RESERVATION_EVENTS_EDIT': {
      const data = action.payload.result.data;
      const user = action.payload.result.user;

      let participants = data.participants;
      if (user) {
        participants = participants.concat([
          {
            id: user.userId,
            name: user.userName,
          },
        ]);
      }

      return {
        ...state,
        reservationManagementEventsEdit: {
          ...state.reservationManagementEventsEdit,
          event: data,
          participants: participants,
        },
      };
    }
    case 'reservation/FETCH_INTERVIEW_EDIT_DETAIL_INIT': {
      return {
        ...state,
        reservationManagementInterviewEdit:
          initialState.reservationManagementInterviewEdit,
        isLoading: true,
        isComplete: false,
      };
    }
    case 'reservation/FETCH_RESERVATION_INTERVIEWS_EDIT': {
      console.log('edit_edit');
      console.log(action.payload.result);
      const interview = action.payload.result.data;
      const user = action.payload.result.user;
      let substituteUser = null;

      if (user != null) {
        substituteUser = {
          id: user.id,
          name: user.fullName,
        };
      }

      let interviewees = interview.interviewees;
      let deleteInterviewees = interview.deleteInterviewees;
      if (user) {
        interviewees = interviewees.concat([
          {
            id: user.userId,
            name: user.userName,
          },
        ]);
        deleteInterviewees = deleteInterviewees.concat([
          {
            id: user.userId,
            name: user.userName,
          },
        ]);
      }

      return {
        ...state,
        reservationManagementInterviewEdit: {
          ...state.reservationManagementInterviewEdit,
          interview: interview,
          interviewees: interviewees,
          deleteInterviewees: deleteInterviewees,
        },
        substituteUser: substituteUser,
      };
    }
    case 'reservation/FETCH_INTERVIEW_EDIT_DETAIL_COMPLETE': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'reservation/SEARCH_USERS': {
      return {
        ...state,
        reservationManagementUsers: {
          ...state.reservationManagementUsers,
          users: action.payload.result,
        },
      };
    }
    case 'reservation/EVENT_EDIT_DETAIL': {
      return {
        ...state,
        isComplete: true,
      };
    }
    case 'reservation/INTERVIEWS_EDIT_DETAIL': {
      return {
        ...state,
        isComplete: true,
      };
    }
    case 'reservation/FETCH_EVENTS_EDIT_DETAIL': {
      return {
        ...state,
        isLoading: true,
        isComplete: false,
        reservationManagementEventsEdit:
          initialState.reservationManagementEventsEdit,
      };
    }
    case 'reservation/FETCH_EVENTS_EDIT_DETAIL_COMPLETE': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'reservation/INIT_USER_SEARCH': {
      return {
        ...state,
        reservationManagementUsers: {
          ...state.reservationManagementUsers,
          users: { result: [] },
        },
      };
    }
    case 'reservation/SET_EVENTS_EDIT_PARTICIPANTS': {
      const values = action.payload;
      return {
        ...state,
        reservationManagementEventsEdit: {
          ...state.reservationManagementEventsEdit,
          participants: values.participants,
          deleteParticipants: values.deleteParticipants,
        },
      };
    }
    case 'reservation/SET_INTERVIEW_EDIT_INTERVIEWEES': {
      const values = action.payload;
      return {
        ...state,
        reservationManagementInterviewEdit: {
          ...state.reservationManagementInterviewEdit,
          interviewees: values.newParticipants,
          deleteInterviewees: values.deleteParticipants,
        },
      };
    }
    case 'reservation/STORE_SEARCH_EVENTS_VALUES': {
      const values = action.payload;
      return {
        ...state,
        prevState: SEARCH_STATE.EVENT,
        searchValues: values,
      };
    }
    case 'reservation/STORE_EVENTS_SEARCH_SELECTED_EVENT': {
      const selectedDate = action.payload;
      return {
        ...state,
        reservationManagementEvents: {
          ...state.reservationManagementEvents,
          selectedDate: selectedDate,
        },
      };
    }
    case 'reservation/STORE_INTERVIEW_SEARCH_SELECTED_EVENT': {
      const selectedDate = action.payload;
      return {
        ...state,
        reservationManagementInterview: {
          ...state.reservationManagementInterview,
          selectedDate: selectedDate,
        },
      };
    }
    case 'reservation/INIT_STORE_SEARCH_VALUES': {
      return {
        ...state,
        searchValues: initialState.searchValues,
      };
    }
    case 'reservation/FETCH_USER_PROFILE': {
      const user = action.payload;
      return {
        ...state,
        substituteUser: user,
      };
    }
    case 'reservation/CLEAR_SUBSTITUTION': {
      return {
        ...state,
        substituteUser: null,
      };
    }
    case 'reservation/CLEAR_SUBSTITTUION_EVENT': {
      const userId = action.payload;

      const participants = state.reservationManagementEventsEdit.participants;

      const participantsWithoutSubstituteUser = participants.filter(
        (participant) => {
          return participant.id !== userId;
        }
      );
      return {
        ...state,
        substituteUser: null,
        reservationManagementEventsEdit: {
          ...state.reservationManagementEventsEdit,
          participants: participantsWithoutSubstituteUser,
        },
      };
    }
    case 'reservation/CLEAR_SUBSTITUTION_INTERVIEW': {
      const userId = action.payload;
      const interview = state.reservationManagementInterviewEdit.interview;

      if (interview.intervieweeId === userId) {
        interview.intervieweeId = null;
        interview.interviewee = null;
      }

      const interviewees = state.reservationManagementEventsEdit.interviewees;
      const intervieweesWithoutSubstituteUser = interviewees.filter(
        (interviewee) => {
          return interviewee.id !== userId;
        }
      );

      return {
        ...state,
        substituteUser: null,
        reservationManagementInterviewEdit: {
          ...state.reservationManagementInterviewEdit,
          interview: interview,
          interviewees: intervieweesWithoutSubstituteUser,
        },
      };
    }
    case 'reservation/INIT_SUBSTITUTE_USER': {
      return {
        ...state,
        substituteUser: null,
      };
    }
    case 'reservation/UPDATE_EVENT_EDIT_DETAIL_FAILURE': {
      return {
        ...state,
        reservationManagementEventsEdit: {
          ...state.reservationManagementEventsEdit,
          errorMessage: action.payload,
        },
      };
    }
    case 'reservation/UPDATE_INTERVIEW_EDIT_DETAIL_FAILURE': {
      return {
        ...state,
        reservationManagementInterviewEdit: {
          ...state.reservationManagementInterviewEdit,
          errorMessage: action.payload,
        },
      };
    }
    case 'reservation/STORE_INTERVIEW_SEARCH_VALUES': {
      return {
        ...state,
        reservationManagementInterview: {
          ...state.reservationManagementInterview,
          searchValues: action.payload,
        },
      };
    }
    default:
      console.log('default: ' + action.type);
      return state;
  }
};

export { reservationManagementReducer as default, SEARCH_STATE };

class EventDateTo {
  /**
   *
   * @param {string} date (yyyy/mm/dd)
   * @param {string} hour (hh)
   * @param {string} minute (mm)
   */
  constructor(date, hour, minute) {
    this.date = date;
    this.hour = hour;
    this.minute = minute;
  }

  /**
   * @param {MomentInput} now
   */
  isExpired(now) {
    const toDate = moment(
      `${this.date} ${this.hour}:${this.minute}`,
      'YYYY/MM/DD hh:mm'
    );

    console.debug('isExpired EventDateTo:', toDate, now);

    return toDate.isBefore(now);
  }
}

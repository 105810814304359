import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  initState,
  makeFetchAreas,
  makeFetchCategories,
  makeFetchEventMethodTypes,
  makeFetchEventTypes,
  makeFetchTags,
  staffSearchModalShow,
} from '../../../actions/Events';
import Necessities, { NecessitiesType } from '../../../domain/Necessities';
import EventTypes from '../../model/event/EventTypes';
import HorizontalBadgesComponent from '../../molecules/HorizontalBadgesComponent';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import RegisterButton from '../../molecules/RegisterButton';
import SaveButton from '../../molecules/SaveButton';
import { AllowOwner } from '../../utils/PermissionComponent';

class EventsEditConfirmForm extends React.Component {
  static get propTypes() {
    return {
      values: PropTypes.any,
      state: PropTypes.shape({
        isSubmitting: PropTypes.bool,
        displayAreas: PropTypes.array,
      }),
      options: PropTypes.shape({
        areas: PropTypes.array,
        halls: PropTypes.array,
        bases: PropTypes.array,
        categories: PropTypes.array,
        tags: PropTypes.array,
        eventTypes: PropTypes.array,
        eventMethodTypes: PropTypes.array,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      handleSubmit: PropTypes.func,
      fetchAreas: PropTypes.func,
      fetchHalls: PropTypes.func,
      fetchTags: PropTypes.func,
      fetchCategory: PropTypes.func,
      staffSearchModalShow: PropTypes.func,
      staffSearchModalHide: PropTypes.func,
      fetchStaff: PropTypes.func,
      fetchCategories: PropTypes.func,
      initState: PropTypes.func,
      onPrevButtonClick: PropTypes.func,
      backToInput: PropTypes.func,
      onSubmit: PropTypes.func,
      fetchEventTypes: PropTypes.func,
      isNew: PropTypes.bool,
      createdBy: PropTypes.string,
      needUrl: PropTypes.bool,
      needVenue: PropTypes.bool,
    };
  }

  render() {
    const getKind = () => {
      const id = this.props.values.kindId;

      const eventTypes = this.props.options.eventTypes.filter((eventType) => {
        return eventType.value === id;
      });

      if (eventTypes.length > 0) {
        return eventTypes[0].name;
      }

      return '';
    };

    const getMethodTypeName = () => {
      const id = this.props.values.methodTypeId;

      const eventMethodTypes = this.props.options.eventMethodTypes.filter(
        (type) => {
          return type.id === id;
        }
      );

      if (eventMethodTypes.length > 0) {
        return eventMethodTypes[0].name;
      }

      return '';
    };

    const getWebEventUrl = () => {
      return this.props.values.webEventUrl;
    };

    const isShowDefaultMessage = () => {
      return !this.props.values.venueId;
    };

    const getContent = () => {
      return this.props.values.content;
    };

    const getName = () => {
      return this.props.values.name;
    };

    const getVenue = () => {
      const venues = this.props.options.halls
        .filter((venue) => venue.value === this.props.values.venueId)
        .map((venue) => venue.name);

      if (venues.length > 0) {
        return venues[0];
      }
      return this.props.values.venueId;
    };
    const getArea = () => {
      if (this.props.values.areaId) {
        const areas = this.props.options.areas
          .filter((area) => area.value === this.props.values.areaId)
          .map((area) => area.name);

        if (areas.length > 0) {
          return areas[0];
        }
        return this.props.values.areaId;
      }
      return '';
    };

    const getTags = () => {
      const tags = this.props.options.tags
        .filter((tag) => this.props.values.tagId.includes(tag.value))
        .map((tag) => tag.name);
      if (tags.length > 0) {
        return tags;
      }
      return [];
    };

    const isDisplayCategories = () => {
      const eventTypes = new EventTypes(this.props.options.eventTypes);

      return eventTypes.isSeminar(this.props.values.kindId);
    };

    const getCategories = () => {
      const categories = this.props.options.categories
        .filter((category) => category.value === this.props.values.categoryId)
        .map((category) => category.name);
      if (categories.length > 0) {
        return categories[0];
      }
      return this.props.values.categoryId;
    };

    const getDefaultMessage = () => {
      return this.props.values.defaultMessage;
    };

    const getPeriodDate = () => {
      const values = this.props.values;
      if (values.startDateTime) {
        const fromDate = values.startDateTime.from.date;
        const fromHour = values.startDateTime.from.hour;
        const fromMinute = values.startDateTime.from.minute;

        const formatFromDate = moment(fromDate)
          .hour(fromHour)
          .minute(fromMinute)
          .format('YYYY/MM/DD HH:mm');

        const toDate = values.startDateTime.to.date;
        const toHour = values.startDateTime.to.hour;
        const toMinute = values.startDateTime.to.minute;

        const formatToDate = moment(toDate)
          .hour(toHour)
          .minute(toMinute)
          .format('YYYY/MM/DD HH:mm');

        return `${formatFromDate} 〜 ${formatToDate}`;
      }
      return '';
    };

    const getStartAcceptingTime = () => {
      if (this.props.values.startAcceptingTime) {
        return this.props.values.startAcceptingTime;
      }
      return '0';
    };

    const getOpenDate = () => {
      const values = this.props.values;
      if (values.openStartDate && values.openStartDate.date) {
        const date = values.openStartDate.date;
        const hour = values.openStartDate.hour || 0;
        const minute = values.openStartDate.minute || 0;

        const formatedDate = moment(date)
          .hour(hour)
          .minute(minute)
          .format('YYYY/MM/DD HH:mm');

        return formatedDate;
      }
      return '';
    };

    const getCloseDate = () => {
      const values = this.props.values;
      if (values.openCloseDate && values.openCloseDate.date) {
        const date = values.openCloseDate.date;
        const hour = values.openCloseDate.hour || 0;
        const minute = values.openCloseDate.minute || 0;

        const formatedDate = moment(date)
          .hour(hour)
          .minute(minute)
          .format('YYYY/MM/DD HH:mm');

        return formatedDate;
      }
      return '';
    };

    const getSeachAvailability = () => {
      return this.props.values.seatAvailability;
    };

    const getIsDisplaySeat = () => {
      if (this.props.values.seatDisplayFlag === '1') {
        return '表示する';
      }
      return '表示しない';
    };

    const getAttire = () => {
      if (this.props.values.attire.value === '1') {
        return 'デフォルト(自由)';
      } else if (this.props.values.attire.value === '0') {
        return 'その他【' + this.props.values.attire.otherText + '】';
      }
      return '';
    };

    const getNecessities = () => {
      let necessitiesType;
      if (this.props.values.necessities.value === '1') {
        necessitiesType = new NecessitiesType.Default();
      } else if (this.props.values.necessities.value === '0') {
        necessitiesType = new NecessitiesType.Other(
          this.props.values.necessities.otherText
        );
      }
      return new Necessities(necessitiesType, this.props.needUrl).displayName;
    };

    const getChairman = () => {
      if (this.props.values.chairman) {
        return this.props.values.chairman.name;
      }
      return '';
    };

    const getchargeBaseId = () => {
      const bases = this.props.options.bases;
      const filteredBases = bases.filter(
        (base) => base.value === this.props.values.chargeBaseId
      );

      if (filteredBases.length > 0) {
        return filteredBases[0].name;
      }
      return '';
    };

    const getDisplayArea = () => {
      const displayAreas = this.props.values.displayAreas;
      const displayAreasBases = this.props.state.displayAreas.flatMap(
        (area) => {
          return area.bases || [];
        }
      );

      if (displayAreas != null && displayAreas.length > 0) {
        return displayAreas.map((displayArea) => {
          console.debug(
            displayArea.areaId,
            displayArea.areaName,
            displayArea.bases
          );

          const areaName = this.props.options.areas.filter(
            (a) => a.value === displayArea.areaId
          )[0].name;

          const bases = displayArea.bases.map((base, index) => {
            const baseId = base.baseId;
            const baseName = displayAreasBases.filter(
              (b) => b.baseId === baseId
            )[0].baseName;
            return <BaseDiv key={index}>{baseName}</BaseDiv>;
          });

          const areaTag = (
            <div>
              {areaName}
              {bases}
            </div>
          );

          return areaTag;
        });
      }

      return '';
    };

    const getCompanyName = () => {
      return this.props.values.companyName;
    };

    return (
      <>
        <ReferItem label="種別">{getKind()}</ReferItem>
        <ReferItem label="開催方法">{getMethodTypeName()}</ReferItem>
        {this.props.needUrl && (
          <ReferItem label="ウェブイベント用URL">{getWebEventUrl()}</ReferItem>
        )}
        <ReferItem label="イベント名">{getName()}</ReferItem>
        {this.props.needVenue && (
          <>
            <ReferItem label="エリア">{getArea()}</ReferItem>
            <ReferItem label="会場">{getVenue()}</ReferItem>
            {isShowDefaultMessage() ? (
              <ReferItem label="会場未選択時表示文言">
                {getDefaultMessage()}
              </ReferItem>
            ) : (
              <></>
            )}
          </>
        )}
        <ReferItem label="開催日時">{getPeriodDate()}</ReferItem>
        <ReferItem label="受付開始時間">
          {getStartAcceptingTime()}分前会場
        </ReferItem>
        <ReferItem label="公開開始日時">{getOpenDate()}</ReferItem>
        <ReferItem label="公開終了日時">{getCloseDate()}</ReferItem>
        <ReferItem label="定員">{getSeachAvailability()}人</ReferItem>
        <ReferItem label="空席状況の表示">{getIsDisplaySeat()}</ReferItem>

        <ReferItem label="服装">{getAttire()}</ReferItem>
        <ReferItem label="持ち物">{getNecessities()}</ReferItem>
        <ReferItem label="講師">{getChairman()}</ReferItem>
        {isDisplayCategories() ? (
          <ReferItem label="カテゴリー">{getCategories()}</ReferItem>
        ) : (
          <></>
        )}
        <ReferItem label="タグ">
          <HorizontalBadgesComponent badges={getTags()} />
        </ReferItem>
        <ReferItem label="内容">{getContent()}</ReferItem>
        <ReferItem label="担当拠点">{getchargeBaseId()}</ReferItem>
        <ReferItem label="企業名">{getCompanyName()}</ReferItem>
        <ReferItem label="表示エリア">{getDisplayArea()}</ReferItem>
        <hr />
        <Row>
          <div className="mx-auto">
            <PrevButton onClick={this.props.backToInput} />
            {this.props.isNew ? (
              <RegisterButton
                onClick={this.props.onSubmit}
                isSubmitting={this.props.state.isSubmitting}
              />
            ) : (
              <AllowOwner dataOwnerId={this.props.createdBy}>
                <SaveButton
                  onClick={this.props.onSubmit}
                  isSubmitting={this.props.state.isSubmitting}
                />
              </AllowOwner>
            )}
          </div>
        </Row>
      </>
    );
  }
}

const BaseDiv = styled.div`
  margin-left: 3em;
`;

export default connect(
  (state, props) => {
    const methodTypeRes = state.options.eventMethodTypes.find(
      (methodType) => methodType.id === props.values.methodTypeId
    );
    const needVenue = methodTypeRes != null && methodTypeRes.needVenue;
    const needUrl = methodTypeRes != null && methodTypeRes.needUrl;

    return {
      state: state.events.eventsEdit,
      createdBy: state.events.eventsRefer.detail.createdBy,
      options: state.options,
      needVenue: needVenue,
      needUrl: needUrl,
    };
  },
  (dispatch) => {
    return {
      fetchAreas() {
        dispatch(makeFetchAreas());
      },
      fetchCategories() {
        dispatch(makeFetchCategories());
      },
      fetchTags() {
        dispatch(makeFetchTags());
      },
      staffSearchModalShow() {
        dispatch(staffSearchModalShow());
      },
      fetchEventTypes() {
        dispatch(makeFetchEventTypes());
      },
      fetchEventMethodTypes() {
        dispatch(makeFetchEventMethodTypes());
      },
      initState() {
        dispatch(initState());
      },
    };
  }
)(EventsEditConfirmForm);

import VenueEditComponent from '../../../containers/venueManagement/Edit';
import VenueReferComponent from '../../../containers/venueManagement/Refer';
import VenueSearchComponent from '../../../containers/venueManagement/Search';

import routePath from './routePath';

const VenueRoutes = [
  {
    path: routePath.search,
    exact: true,
    component: VenueSearchComponent,
  },
  {
    path: routePath.refer(':id'),
    component: VenueReferComponent,
  },
  {
    path: routePath.edit(':id'),
    component: VenueEditComponent,
  },
  {
    path: routePath.edit(''),
    component: VenueEditComponent,
  },
];

export default VenueRoutes;

import {
  EventsSearch,
  EventsEdit,
  EventsRefer,
  EventsBulkInsert,
} from '../../../containers/Events';

import routePath from './routePath';

const EventsRouters = [
  {
    path: routePath.search,
    exact: true,
    component: EventsSearch,
  },
  {
    path: routePath.edit(':id?'),
    component: EventsEdit,
  },
  {
    path: routePath.refer(':id'),
    component: EventsRefer,
  },
  {
    path: routePath.bulkRegister,
    component: EventsBulkInsert,
  },
];

export default EventsRouters;

import React from 'react';

import Card from '../../organisms/Card';

const Forbidden = () => (
  <div>
    <section className="section">
      <div className="section-body">
        <Card headerTitle="403 Forbidden">
          この操作を行うには権限が足りません。
          <br />
          管理者にお問い合わせください。
        </Card>
      </div>
    </section>
  </div>
);

export default Forbidden;

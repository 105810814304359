import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import Option from '../../model/Option';
import Card from '../../organisms/Card';
import SearchTemplate from '../../templates/FormManagementSearchForm/Search';

class FormManagementSearch extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        searchResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          staffs: PropTypes.array,
        }),
        areas: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
        bases: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
        occupations: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
        roles: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      fetchArea: PropTypes.func,
      fetchBases: PropTypes.func,
      fetchOccupations: PropTypes.func,
      fetchRoles: PropTypes.func,
      search: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.onClickDisableModalCloseButton = this.onClickDisableModalCloseButton.bind(this);
    this.moveEdit = this.moveEdit.bind(this);
    this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this);
    this.selectionDelete = this.selectionDelete.bind(this);
    this.onClickDeleteModalCloseButton = this.onClickDeleteModalCloseButton.bind(
      this
    );
    this.deleteForm = this.deleteForm.bind(this);

    this.state = {
      values: this.props.state.searchParams,
      isShowRegisterModal: false,
      deleteForms: [],
    };
  }

  componentDidMount() {
    this.props.searchForms(this.state.values);
  }

  search(values) {
    this.setState({ values: values });
    this.props.searchForms(values);
  }

  onClickDisableModalCloseButton() {
    this.setState({ isShowRegisterModal: false });
    // this.props.hideDisableSuccessModal();
    // this.clickSearchButton(this.state.values);
  }

  moveEdit(id, copyFlag) {
    if (id == null) {
      this.props.history.push(`/admin/form/edit/`);
    } else {
      if (copyFlag) {
        this.props.history.push(`/admin/form/edit/?id=` + id);
      } else {
        this.props.history.push(`/admin/form/edit/` + id);
      }
    }
  }

  selectionDelete(id) {
    const currentSelections = this.state.deleteForms;

    let newSelections;
    if (currentSelections.indexOf(id) !== -1) {
      newSelections = currentSelections.filter((selectionId) => {
        return selectionId !== id;
      });
    } else {
      newSelections = currentSelections.concat([id]);
    }

    this.setState({ ...this.state, deleteForms: newSelections });
  }

  onDeleteButtonClick() {
    console.debug('delete forms ids:', this.state.deleteForms);

    if (
      !(this.state.deleteForms && this.state.deleteForms.length > 0)
    ) {
      return;
    }

    this.props.showDeleteConfirmModal();
  }

  deleteForm() {
    this.props.deleteForm(this.state.deleteForms);
  }

  onClickDeleteModalCloseButton() {
    this.setState({ ...this.state, deleteForms: [] });
    this.props.resetReferResult();
    this.props.searchForms(this.state.values);
  }

  render() {
    return (
      <section className="section">
        <div className="section-header">
          <h1>フォーム管理</h1>
        </div>

        <div className="section-body">
          <Row>
            <Col>
              <Card headerTitle="フォーム管理検索">
                <SearchTemplate
                  search={this.search}
                  searchResult={this.props.state.searchResult}
                  history={this.props.history}
                  isShowRegisterModal={this.state.isShowRegisterModal}
                  onClickDisableModalCloseButton={this.onClickDisableModalCloseButton}
                  moveEdit={this.moveEdit}
                  selectionDelete={this.selectionDelete}
                  onDeleteButtonClick={this.onDeleteButtonClick}
                  onClickDeleteConfirmModalCloseButton={this.props.resetReferResult}
                  deleteForm={this.deleteForm}
                  onClickDeleteModalCloseButton={this.onClickDeleteModalCloseButton}
                  isShowDeleteConfirmModal={this.props.state.isDeleteConfirm}
                  isShowDeleteSuccessModal={this.props.state.deleteResult}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default FormManagementSearch;

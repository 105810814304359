import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from '../../atoms/NormalButton';

class AccountUnlockButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <NormalButton color="warning" onClick={this.props.onClick}>
        ロック解除
      </NormalButton>
    );
  }
}

export default AccountUnlockButton;

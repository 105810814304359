import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { reduxForm } from 'redux-form';

import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import SearchButton from '../../molecules/SearchButton';
import {connect} from "react-redux";

class FormManagementSearchForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
    };
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent
            label="名前"
            name="name"
            component="input"
            type="text"
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const formsSearchForm = reduxForm({
  // a unique name for the form
  form: 'formManagementSearchForm',
  enableReinitialize: true,
})(FormManagementSearchForm);

export default connect(
  (state) => {
    console.debug('formsEditForm: ', state);
    return {
      initialValues: state.formManagement.searchParams,
    };
  }
)(formsSearchForm);

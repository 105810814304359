import { goBack } from 'connected-react-router';
import { trackPromise } from 'react-promise-tracker';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import SearchParams from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';
import {
  makeFetchReservationInterviewsDetail,
  makeFetchReservationInterviews,
} from './ReservationManagement';
import { trackAsync } from './util';

const axios = createAxios('/admin/interviews');

/**
 * 一覧検索
 */

const fetchSearchSuccess = (result) => {
  return {
    type: 'FETCH_CONSULTATION_SEARCH',
    payload: {
      result: result,
    },
  };
};

const search = (searchParams) => {
  const params = {
    year: searchParams.year,
    month: searchParams.month,
    week: searchParams.week,
    staffId: searchParams.staffId,
  };

  return axios.get('/admin/interviewavailables', {
    params,
  });
};

const makeSearch = (searchParams) => {
  return (dispatch) => {
    return trackPromise(
      search(searchParams)
        .then((data) => dispatch(fetchSearchSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

/**
 * 詳細取得
 */
const fetchByIdSuccess = (result) => {
  return {
    type: 'FETCH_CONSULTATION_BY_ID',
    payload: {
      result: result,
    },
  };
};

const fetchById = (id) => {
  return axios.get(`/admin/interviewavailable/${id}`);
};

const makeFetchById = (id) => {
  return (dispatch) => {
    return trackPromise(
      fetchById(id)
        .then((data) => dispatch(fetchByIdSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

/**
 * 登録/編集
 */
const editSuccess = (result) => {
  return {
    type: 'REGISTER_INTERVIEW',
    payload: {
      result: result,
    },
  };
};

const editInterview = (values) => {
  const params = {
    date: values.date,
    fromHour: Number(values.startTime.hour),
    fromMinute: Number(values.startTime.minute),
    toHour: Number(values.endTime.hour),
    toMinute: Number(values.endTime.minute),
    frameType: Number(values.frameType),
    interviewerId: values.interviewerId,
    venueId: values.venueId,
    chargeBaseId: values.chargeBase,
    methodType: values.methodType
      ? values.methodType
      : 'AAECAwQFBgcICQoLDA0ODyw=',
    defaultMessage: values.venueId == null ? values.unselectVenue : null,
    isClothesNormal: values.isClothesNormal.value === '1',
    clothesOther: values.isClothesNormal.otherText,
    isBeglongingsNormal: values.isBeglongingsNormal.value === '1',
    belongingsOther: values.isBeglongingsNormal.otherText,
    tagIds: values.tags,
    displayAreaIds: values.displayAreaIds,
    descriptionForPersonal: values.descriptionForPersonal,
    descriptionForUser: values.descriptionForUser,
    webInterviewUrl: values.webInterviewUrl,
    companyName: values.companyName,
    seatAvailability: parseInt(values.seatAvailability),
  };

  const removedParams = new SearchParams(params).removeParams();
  const id = values.id;
  if (id != null) {
    // 更新
    return axios.put(`/admin/interviewavailable/${id}`, removedParams);
  } else {
    // 新規登録
    return axios.post('/admin/interviewavailables', removedParams);
  }
};

/**
 * 予約管理からの遷移時の検索用パラメータ
 * @typedef {Object} ReservationManagementSearchParams
 * @prop {string} interviewId
 * @prop {Date} selectedDate
 * @prop {ReservationInterviewsValues} searchValues
 */

/**
 *
 * @param {Object} values
 * @param {Object} searchParams
 * @param {ReservationManagementSearchParams} reservationManagementSearchParams
 * @returns {function(*): void}
 */
const makeEditInterview = (
  values,
  searchParams,
  reservationManagementSearchParams
) => {
  return (dispatch) => {
    trackAsync(async () => {
      try {
        const editInterviewRes = await editInterview(values);
        dispatch(editSuccess(editInterviewRes.data));

        if (searchParams) {
          const searchRes = await search(searchParams);
          dispatch(fetchSearchSuccess(searchRes.data));
        }

        if (reservationManagementSearchParams) {
          makeSearchForReservationManagement(
            dispatch,
            reservationManagementSearchParams
          );
        }
      } catch (err) {
        dispatch(failureMakeEditInterview(err));
      }
    });
  };
};

const failureMakeEditInterview = (err) => {
  if (err && err.response && err.response.status === 400) {
    if (err.response.data && err.response.data.messages) {
      return new Action(
        'interview/FETCH_INTERVIEW_EDIT_ERROR',
        err.response.data.messages
      );
    }
  }

  return fetchErrorData(err);
};

const makeGoBack = () => {
  return goBack();
};

const fetchChangeInterviewerError = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      if (
        Object.prototype.hasOwnProperty.call(error.response.data, 'messages')
      ) {
        return new Action(
          'interview/FETCH_INTERVIEW_EDIT_ERROR',
          error.response.data.messages
        );
      }
    }
  }

  return fetchErrorData(error);
};

/**
 * 担当変更
 */
const changeInterviewerSuccess = (result) => {
  return {
    type: 'REGISTER_INTERVIEW',
    payload: {
      result: result,
    },
  };
};

const changeInterviewer = (values) => {
  return axios.put('/admin/interviewavailables/' + values.id + '/change', {
    staffId: values.staffId,
  });
};

/**
 *
 * @param {Object} values
 * @param {Object} searchParam
 * @param {ReservationManagementSearchParams} reservationManagementSearchParams
 * @returns {function(*): void}
 */
const makeChangeInterviewer = (
  values,
  searchParam,
  reservationManagementSearchParams
) => {
  return (dispatch) => {
    trackAsync(async () => {
      try {
        const changeInterviewerRes = await changeInterviewer(values);
        dispatch(changeInterviewerSuccess(changeInterviewerRes.data));

        if (searchParam && Object.keys(searchParam).length > 0) {
          const searchRes = await search(searchParam);
          dispatch(fetchSearchSuccess(searchRes.data));
        }

        if (reservationManagementSearchParams) {
          makeSearchForReservationManagement(
            dispatch,
            reservationManagementSearchParams
          );
        }
      } catch (err) {
        dispatch(fetchChangeInterviewerError(err));
      }
    });
  };
};

/**
 *
 * @param {Function} dispatch
 * @param {ReservationManagementSearchParams} reservationManagementSearchParams
 */
const makeSearchForReservationManagement = (
  dispatch,
  reservationManagementSearchParams
) => {
  dispatch(
    makeFetchReservationInterviewsDetail(
      reservationManagementSearchParams.interviewId,
      reservationManagementSearchParams.selectedDate
    )
  );
  dispatch(
    makeFetchReservationInterviews(
      reservationManagementSearchParams.searchValues
    )
  );
  dispatch(makeGoBack());
};

/**
 * 削除
 */
const deleteSuccess = (result) => {
  return {
    type: 'DELETE_INTERVIEW',
    payload: {
      result: result,
    },
  };
};

const deleteById = (id) => {
  return axios.delete(`/admin/interviewavailable/${id}`);
};

const makeDeleteById = (id, searchParam) => {
  return (dispatch) => {
    trackAsync(async () => {
      try {
        const deleteRes = await deleteById(id);
        dispatch(deleteSuccess(deleteRes.data));

        if (searchParam && Object.keys(searchParam).length > 0) {
          const searchRes = await search(searchParam);
          dispatch(fetchSearchSuccess(searchRes.data));
        }
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

/**
 * プロフィール
 */
const fetchProfile = () => {
  return axios.get(`/admin/profile`);
};

const fetchProfileSuccess = (result) => {
  return {
    type: 'interviews/FETCH_PROFILE',
    payload: {
      result: result,
    },
  };
};

const makeFetchProfile = () => {
  return (dispatch) => {
    return fetchProfile()
      .then((data) => dispatch(fetchProfileSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

/**
 * OPTION系
 */
const fetchAreas = () => {
  return axios.get(`/admin/display_areas`);
};

const fetchAreasSuccess = (result) => {
  return {
    type: 'FETCH_AREAS',
    payload: {
      // result: new OptionCollection(
      //   result.areaList.map((area) => {
      //     return new Option(area.id, area.name);
      //   })
      // ).getOptions(),
      result: result.areaList,
    },
  };
};

const makeFetchAreas = () => {
  return (dispatch) => {
    return fetchAreas()
      .then((data) => dispatch(fetchAreasSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchBases = () => {
  return axios.get(`/admin/bases`, {
    params: {
      isCaravan: false,
    },
  });
};

const fetchBasesSuccess = (result) => {
  return {
    type: 'FETCH_BASES',
    payload: {
      result: new OptionCollection(
        result.bases.map((base) => {
          return new Option(base.id, base.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchBases = () => {
  return (dispatch) => {
    return fetchBases()
      .then((data) => dispatch(fetchBasesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchStaffSuccess = (data) => {
  return {
    type: 'FETCH_STAFF',
    payload: {
      result: new OptionCollection(
        data.staffs.map((staff) => {
          return new Option(staff.id, staff.familyName);
        })
      ).getOptions(),
    },
  };
};

const fetchStaff = () => {
  return axios.get(`/admin/staffs`);
};

const fetchMethodTypes = () => {
  return axios.get(`/admin/interview/method_types`);
};

const fetchMethodTypesSuccess = (result) => {
  return {
    type: 'FETCH_METHOD_TYPE',
    payload: {
      result: result,
    },
  };
};

const makeFetchMethodTypes = () => {
  return (dispatch) => {
    return fetchMethodTypes()
      .then((data) => dispatch(fetchMethodTypesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

/**
 *
 * @returns {*}
 */
const fetchTags = () => {
  return axios.get(`/admin/tags`);
};

const fetchTagsSuccess = (result) => {
  return {
    type: 'option/FETCH_TAGS',
    payload: {
      result: new OptionCollection(
        result.map((tag) => {
          return new Option(tag.id, tag.name);
        })
      ).getOptionsWithoutEmpty(),
    },
  };
};

const makeFetchTags = () => {
  return (dispatch) => {
    return fetchTags()
      .then((data) => dispatch(fetchTagsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

/**
 * 表示エリア取得
 */
const fetchDisplayAreas = () => {
  return axios.get('/admin/display_areas');
};

const successFetchDisplayAreas = (data) => {
  return {
    type: 'OPTIONS/DISPLAY_AREAS',
    payload: {
      result: data.areaList,
    },
  };
};

const makeFetchDisplayAreas = () => {
  return async (dispatch) => {
    try {
      const res = await fetchDisplayAreas();
      return dispatch(successFetchDisplayAreas(res.data));
    } catch (err) {
      return dispatch(fetchErrorData(err));
    }
  };
};

/**
 * 会場取得
 * @returns {{payload: {result: Array<Option>}, type: string}}
 * @param areaId
 */
const fetchHallsByAreaId = (areaId) => {
  let params = {};
  if (areaId != null) {
    params = { areaId: areaId };
  }
  return axios.get(`/admin/halls`, params);
};

const fetchHallsSuccess = (result) => {
  const options = new OptionCollection(
    result.hallList.map((hall) => {
      return {
        name: hall.name,
        value: hall.id,
      };
    })
  ).getOptions();

  console.debug('hall fetch success options', options);
  return {
    type: 'FETCH_HALLS',
    payload: {
      result: options,
    },
  };
};

const makeFetchHalls = (id) => {
  return (dispatch) => {
    return fetchHallsByAreaId(id)
      .then((data) => dispatch(fetchHallsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchSfCompanyNames = () => {
  return axios.get('/options/sf_company_names');
};

const fetchSfCompanyNamesSuccess = (datas) => {
  const options = new OptionCollection(
    datas.map((data) => {
      return new Option(data.name, data.name);
    })
  );

  return new Action('options/SF_COMPANY_NAMES', options.getOptions());
};

const showLoading = () => {
  return {
    type: 'SHOW_LOADING',
  };
};

const hideLoading = () => {
  return new EmptyAction('interviews/HIDE_LOADING');
};

const makeFetchStaff = () => {
  return (dispatch) => {
    dispatch(showLoading());
    return fetchStaff()
      .then((data) => dispatch(fetchStaffSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

/**
 *
 * @param result
 * @returns {{payload: {result: Array<Option>}, type: string}}
 */
const fetchStaffForSuggestionSuccess = (result, staffId) => {
  return {
    type: 'interviews/FETCH_STAFF',
    payload: {
      result: new OptionCollection(
        result.staffs
          .filter((staff) => {
            return staff.id !== staffId;
          })
          .map((staff) => {
            return new Option(staff.id, staff.familyName + staff.firstName);
          })
      ).getOptionsWithoutEmpty(),
    },
  };
};

const fetchStaffForSuggestion = (name) => {
  return axios.get(`/admin/staffs`, {
    params: {
      name: name,
      isDisable: false,
    },
  });
};

const makeFetchStaffForSuggestion = (name, staffId) => {
  return async (dispatch) => {
    try {
      const data = await fetchStaffForSuggestion(name);
      return dispatch(fetchStaffForSuggestionSuccess(data.data, staffId));
    } catch (err) {
      return dispatch(fetchErrorData(err));
    }
  };
};

const resetState = () => {
  return {
    type: 'interviews/RESET_STATE',
  };
};

const showModal = (isNew, interviewId) => {
  return new Action('interviews/SHOW_MODAL', { isNew, interviewId });
};

const setSelectFrom = (values) => {
  return {
    type: 'interviews/SET_SELECT_FROM',
    payload: {
      result: values,
    },
  };
};

const clearSuggestion = () => {
  return {
    type: 'interviews/CLEAR_SUGGESTION',
  };
};

const makeInitFetchData = (comeOtherPageFlag, searchValues) => {
  return async (dispatch) => {
    try {
      dispatch(resetState());
      dispatch(showLoading());
      const jobs = [fetchProfile(), fetchAreas(), fetchBases(), fetchStaff()];

      const [profileRes, areasRes, basesRes, staffRes] = await Promise.all(
        jobs
      );

      dispatch(fetchProfileSuccess(profileRes.data));

      if (!comeOtherPageFlag) {
        searchValues.staffId = profileRes.data.id;
        const searchRes = await search(searchValues);

        dispatch(fetchSearchSuccess(searchRes.data));
      }

      dispatch(fetchAreasSuccess(areasRes.data));
      dispatch(fetchBasesSuccess(basesRes.data));
      dispatch(fetchStaffSuccess(staffRes.data));

      dispatch(hideLoading());
    } catch (err) {
      fetchErrorData(err);
    }
  };
};

const clearErrorMessage = () => {
  return new EmptyAction('interviews/CLEAR_ERROR_MESSAGE');
};

const showModalLoading = () => {
  return new EmptyAction('interviews/SHOW_MODAL_LOADING');
};

const hideModalLoading = () => {
  return new EmptyAction('interviews/HIDE_MODAL_LOADING');
};

const fetchIsDefaultBaseAndOccupation = () => {
  return axios.get('/admin/staff/defaultsettingbase');
};

const fetchIsDefaultBaseAndOccupationSuccess = (data) => {
  return new Action(
    'interviews/FETCH_IS_DEFAULT_BASE_AND_OCCUPATION_SUCCESS',
    data.isDefaultSetting
  );
};

const makeFetchEditDataForModal = () => {
  return async (dispatch) => {
    try {
      dispatch(showModalLoading());
      const responses = await Promise.all([
        fetchMethodTypes(),
        fetchTags(),
        fetchHallsByAreaId(),
        fetchDisplayAreas(),
        fetchSfCompanyNames(),
        fetchIsDefaultBaseAndOccupation(),
      ]);

      const [
        methodTypeRes,
        tagsRes,
        hallsRes,
        displayAreasRes,
        sfCompanyNamesRes,
        isDefaultBaseAndOccupationRes,
      ] = responses;
      dispatch(fetchMethodTypesSuccess(methodTypeRes.data));
      dispatch(fetchTagsSuccess(tagsRes.data));
      dispatch(fetchHallsSuccess(hallsRes.data));
      dispatch(successFetchDisplayAreas(displayAreasRes.data));
      dispatch(fetchSfCompanyNamesSuccess(sfCompanyNamesRes.data));
      dispatch(
        fetchIsDefaultBaseAndOccupationSuccess(
          isDefaultBaseAndOccupationRes.data
        )
      );

      if (isDefaultBaseAndOccupationRes.data.isDefaultSetting) {
        const profile = await fetchProfile();
        dispatch(fetchProfileSuccess(profile.data));
      }

      dispatch(hideModalLoading());
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

const makeInitBulkInsert = () => {
  return new EmptyAction('interviews/INIT_BULK_INSERT');
};

const uploadBulkInsertFile = (file) => {
  const params = new FormData();
  params.append('file', file);

  return axios.post(`/interviews/bulk_register/file_upload`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const uploadBulkInsertFileSuccess = (data) => {
  return {
    type: 'interviews/UPLOAD_BULK_INSERT_FILE_SUCCESS',
    payload: data,
  };
};

const makeUploadBulkInsertFile = (file) => {
  return (dispatch) => {
    return trackPromise(
      uploadBulkInsertFile(file)
        .then((data) => dispatch(uploadBulkInsertFileSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const bulkInsertExec = (tempFileId, originalFileName) => {
  return axios.post(`/interviews/bulk_register`, {
    tempFileId: tempFileId,
    originalFileName: originalFileName,
  });
};

const bulkInsertExecSuccess = (data) => {
  return {
    type: 'interviews/BULK_INSERT_EXEC_SUCCESS',
  };
};

const failureBulkInsertExec = (err) => {
  if (err.response) {
    if (err.response.status === 400) {
      return {
        type: 'interviews/BAD_REQUEST_BULK_UPDATE',
        payload: {
          result: err.response.data,
        },
      };
    }
  }

  return fetchErrorData(err);
};

const makeBulkInsertExec = (tempFileId, originalFileName) => {
  return (dispatch) => {
    return trackPromise(
      bulkInsertExec(tempFileId, originalFileName)
        .then((data) => dispatch(bulkInsertExecSuccess(data.data)))
        .catch((err) => dispatch(failureBulkInsertExec(err)))
    );
  };
};

export {
  makeFetchAreas,
  makeFetchBases,
  makeSearch,
  makeFetchStaff,
  makeFetchById,
  makeEditInterview,
  makeDeleteById,
  resetState,
  showModal,
  makeFetchProfile,
  makeFetchMethodTypes,
  setSelectFrom,
  makeFetchTags,
  makeFetchHalls,
  makeChangeInterviewer,
  makeFetchDisplayAreas,
  makeFetchStaffForSuggestion,
  clearSuggestion,
  makeInitFetchData,
  clearErrorMessage,
  makeFetchEditDataForModal,
  makeGoBack,
  makeInitBulkInsert,
  makeUploadBulkInsertFile,
  makeBulkInsertExec,
};

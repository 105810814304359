const initialState = {
  updatePasswordEdit: {
    isSubmitting: false,
    isComplete: false,
    isError: false,
    updatePasswordData: {},
  },
};

const updatePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'updatePassword/UPDATE_LOADING': {
      return {
        ...state,
        updatePasswordEdit: {
          ...state.updatePasswordEdit,
          messages: [],
        },
      };
    }
    case 'updatePassword/FETCH_UPDATE_PASSWORD': {
      return {
        ...state,
        updatePasswordEdit: {
          ...state.updatePasswordEdit,
          isComplete: true,
          isError: false,
          updatePasswordData: {},
        },
      };
    }
    case 'updatePassword/BAD_REQUEST': {
      const messages = action.payload.messages;
      return {
        ...state,
        updatePasswordEdit: {
          ...state.updatePasswordEdit,
          isError: true,
          messages: messages,
          isConfirm: false,
          isSubmitting: false,
          updatePasswordData: {},
        },
      };
    }
    case 'updatePassword/INIT_STATE': {
      return initialState;
    }
    default:
      return state;
  }
};

export { updatePasswordReducer as default };

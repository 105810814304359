import PropTypes from 'prop-types';
import React from 'react';

class Error extends React.Component {
  static get propTypes() {
    return {
      routes: PropTypes.array,
    };
  }

  render() {
    return 'Internal Server Error';
  }
}

export default Error;

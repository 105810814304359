import PropTypes from 'prop-types';
import React from 'react';

import LabelErrorTitle from '../atoms/LabelErrorTitle';
import LabelErrorDirectly from '../atoms/LabelErrorDirectly';

class LabelErrorComponent extends React.Component {
  static get propTypes() {
    return {
      title: PropTypes.string,
      directly: PropTypes.string
    };
  }

  render() {
    return (
      <div className="alert alert-danger">
        <LabelErrorTitle>{this.props.title}</LabelErrorTitle>
        <LabelErrorDirectly>{this.props.directly}</LabelErrorDirectly>
      </div>
    );
  }
}

export default LabelErrorComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import {
  makeFetchCollegeRegistrationCategories,
  makeFetchCompanyNames,
  makeFetchCurrentStatuses,
  makeFetchPrefs,
} from '../../../actions/User';
import FormInlineCheckBoxesComponent from '../../molecules/FormInlineCheckBoxesComponent';
import FormNumberFromToComponent from '../../molecules/FormNumberFromToComponent';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';
import FormFromtoComponent from '../../molecules/user/FormFromtoComponent';

class UserSearchContactForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      fetchPrefs: PropTypes.func,
      fetchCompanyNames: PropTypes.func,
      fetchCurrentStatuses: PropTypes.func,
      fetchCollegeRegistrationCategories: PropTypes.func,
      options: PropTypes.shape({
        prefs: PropTypes.array,
        companyNames: PropTypes.array,
        currentStatuses: PropTypes.array,
        collegeRegistrationCategories: PropTypes.array,
      }),
    };
  }

  componentDidMount() {
    this.props.fetchPrefs();
    this.props.fetchCompanyNames();
    this.props.fetchCurrentStatuses();
    this.props.fetchCollegeRegistrationCategories();
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent label="SFID(B.人)" name="sfId" />
          <HorizontalFormInputComponent
            label="メールアドレス"
            name="mailAddress"
          />
          <HorizontalFormInputComponent label="名前(フリガナ)" name="kana" />
          <HorizontalFormInputComponent
            label="LINEニックネーム"
            name="lineNickName"
          />
          <HorizontalSelectComponent
            label="企業名"
            name="company"
            options={this.props.options.companyNames}
          />
          <FormInlineCheckBoxesComponent
            label="年齢"
            options={[
              { name: '10代', value: '1' },
              { name: '20代', value: '2' },
              { name: '30代', value: '3' },
            ]}
            name="age"
          />
          <FormFromtoComponent
            label="流入日期間"
            fromId="registerFromDateYmd"
            toId="registerToDateYmd"
          />
          <FormInlineCheckBoxesComponent
            label="接触NG"
            name="contactNg"
            options={[{ name: '除く', value: '1' }]}
          />
          <FormInlineCheckBoxesComponent
            label="メールNG"
            name="mailNg"
            options={[{ name: '除く', value: '1' }]}
          />
          <FormFromtoComponent
            label="来社日"
            fromId="visitFromDateYmd"
            toId="visitToDateYmd"
          />
          <FormFromtoComponent
            label="来社日2"
            fromId="visitFromDate2Ymd"
            toId="visitToDate2Ymd"
          />
          <HorizontalSelectComponent
            label="現在の状況"
            name="currentStatus"
            options={this.props.options.currentStatuses}
          />
          <HorizontalSelectComponent
            label="カレッジ登録区分"
            name="collegeRegistrationCategory"
            options={this.props.options.collegeRegistrationCategories}
          />

          <FormInlineCheckBoxesComponent
            label="性別"
            name="sex"
            options={[
              { name: '男性', value: '1' },
              { name: '女性', value: '2' },
              { name: '未設定', value: '0' },
            ]}
          />
          <HorizontalSelectComponent
            label="都道府県"
            name="pref"
            options={this.props.options.prefs}
          />
          <HorizontalFormInputComponent label="携帯番号" name="phoneNumber" />

          <FormNumberFromToComponent
            label="アプローチ数"
            name="approachCount"
          />
          <FormNumberFromToComponent label="通電数" name="telephoneCount" />
          <FormNumberFromToComponent label="接触数" name="contactCount" />
          <FormFromtoComponent
            label="最終アクション日"
            fromId="lastActionDateYmdFrom"
            toId="lastActionDateYmdTo"
          />
          <FormNumberFromToComponent label="招待者数" name="inviteCount" />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
const userSearchContactForm = reduxForm({
  // a unique name for the form
  form: 'userSearchContactForm',
  enableReinitialize: true,
})(UserSearchContactForm);

export default connect(
  (state) => {
    return {
      initialValues: {
        age: [],
        contactNg: [],
        mailNg: [],
        sex: [],
      },
      options: state.options,
      state: state.user,
    };
  },
  (dispatch) => {
    return {
      fetchPrefs() {
        dispatch(makeFetchPrefs());
      },
      fetchCompanyNames() {
        dispatch(makeFetchCompanyNames());
      },
      fetchCurrentStatuses() {
        dispatch(makeFetchCurrentStatuses());
      },
      fetchCollegeRegistrationCategories() {
        dispatch(makeFetchCollegeRegistrationCategories());
      },
    };
  }
)(userSearchContactForm);

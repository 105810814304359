import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import BulkUpdateExecButton from '../../molecules/BulkUpdateExecButton';
import DetailButton from '../../molecules/DetailButton';
import FileButton from '../../molecules/FileButton';
import PrevButton from '../../molecules/PrevButton';
import Card from '../../organisms/Card';
import Pager from '../../organisms/Pager';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import SearchResultTable from '../../organisms/SearchResultTable';
import BulkUpdateFailureDialog from '../../organisms/staff/BulkUpdateFailureDialog';

import routes from './routePath';

class StaffBulkUpdate extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        staffBulkUpdate: PropTypes.shape({
          isShowBulkUpdateFailureDialog: PropTypes.bool,
          failureMessages: PropTypes.arrayOf(PropTypes.string),
          tempFileId: PropTypes.string,
          originalFileName: PropTypes.string,
          searchResults: PropTypes.shape({
            results: PropTypes.array,
            searchParams: PropTypes.object,
          }),
          isShowExecutedDialog: PropTypes.bool,
          existsRunning: PropTypes.bool,
          isAlreadyExec: PropTypes.bool,
        }),
        isLoading: PropTypes.bool,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      hideExecutedDialog: PropTypes.func,
      fetchBulkUpdateHistories: PropTypes.func,
      uploadBulkUpdateFile: PropTypes.func,
      bulkUpdateExec: PropTypes.func,
      downloadBulkUpdateResultFile: PropTypes.func,
      initializeStaffBulk: PropTypes.func,
      fetchBulkUpdateStatus: PropTypes.func,
      hideBulkUpdateFailureDialog: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.state = { fileName: '' };

    this.getSearchItem = this.getSearchItem.bind(this);
    this.onClickCloseButton = this.onClickCloseButton.bind(this);
    this.initSearch = this.initSearch.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
    this.onClickFileButton = this.onClickFileButton.bind(this);
    this.onClickExecButton = this.onClickExecButton.bind(this);
    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.onClickBulkUpdateFailureCloseButton = this.onClickBulkUpdateFailureCloseButton.bind(
      this
    );

    this.search = this.search.bind(this);

    this.fileButtonRef = React.createRef();
  }

  componentDidMount() {
    this.props.initializeStaffBulk({
      sort: {
        sortColumn: 'id',
        sortType: 'desc',
      },
      pagination: { page: 1, pageSize: 10 },
    });
  }

  onClickCloseButton() {
    this.setState({ fileName: '' });

    if (this.fileButtonRef) {
      this.fileButtonRef.current.value = null;
    }

    this.props.hideExecutedDialog();
    this.initSearch();
    this.props.fetchBulkUpdateStatus();
  }

  onClickBulkUpdateFailureCloseButton() {
    this.props.hideBulkUpdateFailureDialog();
    this.initSearch();
    this.props.fetchBulkUpdateStatus();
  }

  initSearch() {
    this.props.fetchBulkUpdateHistories({
      sort: {
        sortColumn: 'id',
        sortType: 'desc',
      },
      pagination: { page: 1, pageSize: 10 },
    });
  }

  onClickFileButton(e) {
    const target = e.target;
    const file = target.files.item(0);
    console.debug('handleChangefile', target);
    console.debug(target.value);
    console.debug('file', file);

    if (!file) {
      return;
    }

    this.setState({ fileName: file.name });
    this.props.uploadBulkUpdateFile(file);
  }

  onClickExecButton() {
    this.props.bulkUpdateExec(
      this.props.state.staffBulkUpdate.tempFileId,
      this.props.state.staffBulkUpdate.originalFileName
    );
  }

  onClickDownloadButton(id) {
    console.debug('onClickDownloadButton', id);
    this.props.downloadBulkUpdateResultFile(id);
  }

  transitionToEdit() {
    this.props.history.push(routes.newEdit);
  }

  getSearchItem() {
    const results = this.props.state.staffBulkUpdate.searchResults.results;

    const items = results.map((result, id) => {
      const executionTime = moment(
        result.executionStartTime,
        moment.ISO_8601
      ).format('YYYY-MM-DD HH:mm:SS');

      return (
        <tr key={id}>
          <th scope="row">{result.status}</th>
          <td>{executionTime}</td>
          <td>
            {result.successNumber} / {result.number}
          </td>

          <td>
            <DetailButton
              onClick={this.onClickDownloadButton.bind(this, result.id)}
            >
              結果ファイル
            </DetailButton>
          </td>
        </tr>
      );
    });
    return items;
  }

  transitionToSearch() {
    this.props.history.push(routes.search);
  }

  search(searchParams) {
    this.props.fetchBulkUpdateHistories(searchParams);
  }

  render() {
    const isEnabled = (existsRunning) => {
      return () =>
        !existsRunning &&
        this.state.fileName &&
        !this.props.state.staffBulkUpdate.isAlreadyExec;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>社員管理</h1>
          </div>

          <div className="section-body">
            {this.props.state.isLoading ? (
              <></>
            ) : (
              <Row>
                <Col>
                  <Card headerTitle="一括変更">
                    {this.props.state.staffBulkUpdate.existsRunning ? (
                      <div className="alert alert-danger">
                        <p>
                          他の一括変更の処理が実行中のため、登録できません。
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col xs={3}>
                        <FileButton
                          onChange={this.onClickFileButton}
                          ref={this.fileButtonRef}
                        />
                      </Col>
                      <Col xs={9}> {this.state.fileName}</Col>
                    </Row>
                    <BulkUpdateExecButton
                      onClick={this.onClickExecButton}
                      isEnabled={isEnabled(
                        this.props.state.staffBulkUpdate.existsRunning
                      )}
                    />
                    {this.props.state.staffBulkUpdate.searchResults.results
                      .length > 0 ? (
                      <>
                        <hr />

                        <Pager
                          searchParams={
                            this.props.state.staffBulkUpdate.searchResults
                              .searchParams
                          }
                          search={this.search}
                        />

                        <SearchResultTable
                          headerItems={[
                            { name: 'status_id', value: 'ステータス' },
                            {
                              name: 'execution_start_time',
                              value: '実行開始時刻',
                            },
                            { name: 'number', value: '成功件数/件数' },
                            {},
                          ]}
                          searchResults={
                            this.props.state.staffBulkUpdate.searchResults
                          }
                          getSearchItem={this.getSearchItem}
                          search={this.search}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col className="text-center">
                        <PrevButton onClick={this.transitionToSearch} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </section>

        <RegisterCompleteModal
          show={this.props.state.staffBulkUpdate.isShowExecutedDialog}
          onCloseClick={this.onClickCloseButton}
          text="正常に一括更新処理の登録が完了しました"
        />

        <BulkUpdateFailureDialog
          show={this.props.state.staffBulkUpdate.isShowBulkUpdateFailureDialog}
          onCloseClick={this.onClickBulkUpdateFailureCloseButton}
          messages={this.props.state.staffBulkUpdate.failureMessages}
        />
      </>
    );
  }
}

export default StaffBulkUpdate;

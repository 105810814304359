import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Field } from 'redux-form';
import styled from 'styled-components';

import FormGroupRow from '../../atoms/FormGroupRow';
import FormInput from '../../atoms/FormInput';
import FormLabel from '../../atoms/FormLabel';
import * as Validator from '../../utils/Validate';

class HorizontalFormInputComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      messages: PropTypes.arrayOf(PropTypes.string),
      isNew: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    return (
      <FormGroupRow>
        {this.props.name ? (
          <Element name={`position-${this.props.name}`} />
        ) : (
          <></>
        )}
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          {this.isRequired() ? (
            <>
              <span className="text-danger">*</span>
            </>
          ) : (
            <></>
          )}
        </FormLabel>
        <div className="col-sm-6">
          <FormInput {...field} />
          {field.meta.touched && field.meta.error && (
            <Form.Control.Feedback type="invalid">
              {field.meta.error}
            </Form.Control.Feedback>
          )}
          {this.props.isNew ? (
            <DisplayFeedback type="invalid">
              ※社員番号が無い場合は、999999を入力する
            </DisplayFeedback>
          ) : (
            <></>
          )}
          {/* サーバーサイドエラーメッセージ */}
          {this.props.messages != null && this.props.messages.length > 0 ? (
            <div className="alert alert-danger">
              {this.props.messages.map((message, i) => (
                <p key={i}>{message}</p>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </FormGroupRow>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.component}
        validate={this.props.validate}
      />
    );
  }
}

const DisplayFeedback = styled(Form.Control.Feedback)`
  display: block;
`;

export default HorizontalFormInputComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLastLocation } from 'react-router-last-location';
import styled from 'styled-components';

import Option from '../../model/Option';
import DetailButton from '../../molecules/DetailButton';
import NewButton from '../../molecules/NewButton';
import Card from '../../organisms/Card';
import Pager from '../../organisms/Pager';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import SearchResultTable from '../../organisms/SearchResultTable';
import AccountUnlockButton from '../../organisms/staff/AccountUnlockButton';
import DisableButton from '../../organisms/staff/DisableButton';
import DisableConfirmModal from '../../organisms/staff/DisableConfirmModal';
import Form from '../../organisms/staff/StaffSearchForm';
import TransitionButton from '../../organisms/staff/TransitionBulkUpdateButton';
import SearchComponent from '../common/SearchComponent';

import routes from './routePath';

const MarginRow = styled(Row)`
  margin-bottom: 1em;
  margin-top: 1em;
`;

class StaffSearch extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        staffSearch: PropTypes.shape({
          isShowDisableSuccessModal: PropTypes.bool,
          isShowUnlockSuccessModal: PropTypes.bool,
          searchValues: PropTypes.shape({
            searchParams: PropTypes.object,
            values: PropTypes.object,
          }),
        }),
        staffRefer: PropTypes.shape({
          isSystemAdmin: PropTypes.bool,
        }),
        searchResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
            pagination: PropTypes.shape({
              pageSize: PropTypes.number,
              total: PropTypes.number,
              page: PropTypes.number,
            }),
          }),
          staffs: PropTypes.array,
        }),
        areas: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
        bases: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
        occupations: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
        roles: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      fetchArea: PropTypes.func,
      fetchBases: PropTypes.func,
      fetchOccupations: PropTypes.func,
      fetchRoles: PropTypes.func,
      fetchProfile: PropTypes.bool,
      search: PropTypes.func,
      disableStaff: PropTypes.func,
      hideDisableSuccessModal: PropTypes.func,
      hideUnlockSuccessModal: PropTypes.func,
      unlockStaff: PropTypes.func,
      initFetchSearchPage: PropTypes.func,
      lastLocation: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    };
  }

  constructor(props) {
    super(props);
    this.state = { values: {}, disableSelections: [] };

    this.searchComponent = new SearchComponent();

    this.clickSearchButton = this.clickSearchButton.bind(this);
    this.getSearchItem = this.getSearchItem.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
    this.transitionToBulkUpdate = this.transitionToBulkUpdate.bind(this);
    this.onDisableButtonClick = this.onDisableButtonClick.bind(this);
    this.onClickDisableModalCloseButton = this.onClickDisableModalCloseButton.bind(
      this
    );
    this.onClickUnlockModalCloseButton = this.onClickUnlockModalCloseButton.bind(
      this
    );

    this.search = this.search.bind(this);
  }

  componentDidMount() {
    const isComeFromOtherPage =
      this.props.lastLocation &&
      !this.props.lastLocation.pathname.includes('/admin/staff');
    this.props.initFetchSearchPage(isComeFromOtherPage);
  }

  clickSearchButton(values) {
    this.setState({ values: values });

    const prevSearchParams = this.props.state.staffSearch.searchValues
      .searchParams;

    let pageSize = this.searchComponent.pageDefaultSize;
    if (
      prevSearchParams &&
      prevSearchParams.pagination &&
      prevSearchParams.pagination.pageSize
    ) {
      pageSize = prevSearchParams.pagination.pageSize;
    }

    this.search(
      {
        sort: {
          sortColumn: 'id',
          sortType: 'asc',
        },
        pagination: { page: 1, pageSize: pageSize },
      },
      values
    );
  }

  selectionDisableStaff(id) {
    const currentSelections = this.state.disableSelections;

    let newSelections;
    if (currentSelections.indexOf(id) !== -1) {
      newSelections = currentSelections.filter((selectionId) => {
        return selectionId !== id;
      });
    } else {
      newSelections = currentSelections.concat([id]);
    }

    this.setState({ disableSelections: newSelections });
  }

  onDisableButtonClick() {
    if (
      !(this.state.disableSelections && this.state.disableSelections.length > 0)
    ) {
      return;
    }

    this.setState({ isDisplayDisableConfirmModal: true });
  }

  onClickDisableModalCloseButton() {
    this.setState({ disableSelections: [] });
    this.props.hideDisableSuccessModal();
    this.clickSearchButton(this.state.values || {});
  }

  onClickUnlockModalCloseButton() {
    this.props.hideUnlockSuccessModal();
    this.clickSearchButton(this.state.values);
  }

  transitionToEdit() {
    this.props.history.push(routes.newEdit);
  }

  transitionToBulkUpdate() {
    this.props.history.push(routes.bulkUpdate);
  }

  getSearchItem() {
    const staffs = this.props.state.searchResults.staffs;

    const transitionRefer = (id) => {
      this.props.history.push(routes.refer(id));
    };

    const getAdmin = (isAdminFlg) => {
      if (isAdminFlg === '1') {
        return '○';
      } else {
        return '☓';
      }
    };

    const getDisabled = (isDisabled) => {
      if (isDisabled === '1') {
        return '無効';
      } else {
        return '有効';
      }
    };

    const onClickUnlockButton = (id) => {
      this.props.unlockStaff(id);
    };

    const DisableUserRow = styled.tr`
      color: #212529;
      background-color: rgba(0, 0, 0, 0.03);
    `;

    const items = staffs.map((staff, index) => {
      const isSystemAdmin = this.props.state.staffRefer.isSystemAdmin;

      const isDisabled = staff.isDisabled === '1';

      let TR = 'tr';
      if (isDisabled) {
        TR = DisableUserRow;
      }

      return (
        <TR key={staff.id}>
          {isSystemAdmin ? (
            <th scope="row">
              {isDisabled ? (
                <b>無効化済</b>
              ) : (
                <input
                  className="form-check-input mx-auto"
                  type="checkbox"
                  onChange={this.selectionDisableStaff.bind(this, staff.id)}
                />
              )}
            </th>
          ) : (
            <></>
          )}
          <td>{staff.familyName + ' ' + staff.firstName}</td>
          <td>{staff.baseName}</td>
          <td>{staff.occupationName}</td>
          <td>{staff.roleName}</td>
          <td className="text-center">{getAdmin(staff.isAdminFlg)}</td>
          {this.props.state.staffRefer.isSystemAdmin ? (
            <td>{getDisabled(staff.isDisabled)}</td>
          ) : (
            <></>
          )}
          <td>
            {staff.isLocked ? (
              <AccountUnlockButton
                onClick={onClickUnlockButton.bind(this, staff.id)}
              />
            ) : (
              <></>
            )}
          </td>
          <td>
            <DetailButton onClick={transitionRefer.bind(this, staff.id)} />
          </td>
        </TR>
      );
    });
    return items;
  }

  search(
    searchParams = this.props.state.staffSearch.searchValues.searchParams,
    values = this.props.state.staffSearch.searchValues.values
  ) {
    this.props.search(values, searchParams);
  }

  render() {
    const isSystemAdmin = this.props.state.staffRefer.isSystemAdmin;
    let headerItems;

    if (isSystemAdmin) {
      headerItems = [{}];
    } else {
      headerItems = [];
    }

    headerItems = headerItems.concat([
      { name: 'staff.first_name', value: '名前' },
      { name: 'staff.base_id', value: '拠点' },
      { name: 'staff.occupation_id', value: '職種' },
      { name: 'staff.role_id', value: 'ロール' },
      {
        name: 'role.is_system_admin',
        value: 'システム管理者フラグ',
      },
    ]);

    if (isSystemAdmin) {
      headerItems.push({
        name: 'staff.is_disabled',
        value: '社員ステータス',
      });
    }

    headerItems = headerItems.concat([
      {
        name: 'staff.is_account_lock',
        value: 'アカウントロック解除',
      },
      {},
    ]);

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>社員管理</h1>
          </div>

          <div className="section-body">
            {!this.props.state.isLoading ? (
              <Row>
                <Col>
                  <Card headerTitle="社員管理検索">
                    <div className="section-title">検索項目</div>
                    <Form onSubmit={this.clickSearchButton} />
                    <div className="text-left">
                      <Row>
                        <Col>
                          <MarginRow>
                            <Col>
                              <NewButton onClick={this.transitionToEdit} />
                              <TransitionButton
                                onClick={this.transitionToBulkUpdate}
                              >
                                拠点・エリア・部署・職種一括変更
                              </TransitionButton>
                            </Col>
                          </MarginRow>
                        </Col>
                      </Row>
                      {this.props.state.searchResults.staffs.length > 0 ? (
                        <>
                          <hr />

                          <Pager
                            searchParams={
                              this.props.state.searchResults.searchParams
                            }
                            search={this.search}
                          />

                          <SearchResultTable
                            headerItems={headerItems}
                            searchResults={this.props.state.searchResults}
                            getSearchItem={this.getSearchItem}
                            search={this.search}
                          />
                          {this.props.state.staffRefer.isSystemAdmin ? (
                            <Row>
                              <Col>
                                <DisableButton
                                  onClick={this.onDisableButtonClick}
                                />
                              </Col>
                            </Row>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </section>

        <DisableConfirmModal
          show={this.state.isDisplayDisableConfirmModal}
          onNoButtonClick={() =>
            this.setState({ isDisplayDisableConfirmModal: false })
          }
          onYesButtonClick={(e) => {
            this.setState({ isDisplayDisableConfirmModal: false });

            this.props.disableStaff(
              this.state.disableSelections,
              this.props.state.staffSearch.searchValues
            );
          }}
        />

        <RegisterCompleteModal
          show={this.props.state.staffSearch.isShowDisableSuccessModal}
          onCloseClick={this.onClickDisableModalCloseButton}
          text={'正常に無効化処理が完了しました'}
        />

        <RegisterCompleteModal
          show={this.props.state.staffSearch.isShowUnlockSuccessModal}
          onCloseClick={this.onClickUnlockModalCloseButton}
          text={'ロックを解除しました'}
        />
      </>
    );
  }
}

const LastLocation = (Component) => {
  const InnerComponent = (props) => {
    const lastLocation = useLastLocation();
    return <Component {...props} lastLocation={lastLocation} />;
  };

  return InnerComponent;
};

export default LastLocation(StaffSearch);

import PropTypes from 'prop-types';
import React from 'react';

import FormInputComponent from './FormInputComponent';

class AgeInputComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'ok',
      message: null,
    };

    this.onChange = this.onChange.bind(this);
  }

  static get propTypes() {
    return {
      name: PropTypes.type,
      onChange: PropTypes.func,
      value: PropTypes.string,
      errorMessage: PropTypes.array,
      onError: PropTypes.func,
    };
  }

  onChange(e) {
    this.props.onChange(e);
    let value = e.target.value;

    if (value == null || value === '') {
      this.setState({
        status: 'error',
        message: 'この項目は必須項目です',
      });
      this.props.onError(true);
      return;
    }

    value = parseInt(value);
    console.debug('test: ', !(typeof value === 'number' && isFinite(value)));
    if (
      !(typeof value === 'number' && isFinite(value)) ||
      value < 0 ||
      value >= 40
    ) {
      this.setState({
        status: 'error',
        message: '0以上40以下の数値で入力してください',
      });
      this.props.onError(true);
      return;
    }

    this.setState({
      status: 'ok',
      message: null,
    });
    this.props.onError(false);
  }

  render() {
    return (
      <FormInputComponent
        label="年齢"
        onChange={this.onChange}
        value={this.props.value}
        errorMessage={
          this.state.status === 'error'
            ? [this.state.message]
            : this.props.errorMessage
        }
      />
    );
  }
}

export default AgeInputComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import FormInlineCheckBoxes from '../../molecules/FormInlineCheckBoxesComponent';
import FormInlineRadioComponent from '../../molecules/FormInlineRadioComponentForForm';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';

class StaffSearchForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      options: PropTypes.shape({
        areas: PropTypes.array,
        bases: PropTypes.array,
        occupations: PropTypes.array,
        roles: PropTypes.array,
      }),
      state: PropTypes.shape({
        isSystemAdmin: PropTypes.bool,
      }),
    };
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent
            label="名前"
            name="name"
            component="input"
            type="text"
          />
          <HorizontalSelectComponent
            label="エリア"
            options={this.props.options.areas}
            name="areaId"
          />
          <HorizontalSelectComponent
            label="拠点"
            options={this.props.options.bases}
            name="baseId"
          />
          <FormInlineCheckBoxes
            label="職種"
            options={this.props.options.occupations}
            name="occupationId"
          />
          <FormInlineRadioComponent
            label="システム管理者フラグ"
            options={[
              { name: '全て', value: '0', default: true },
              { name: 'なし', value: '1', default: false },
              { name: 'システム管理者', value: '2', default: false },
            ]}
            name="isSystemAdmin"
          />
          <HorizontalSelectComponent
            label="ロール"
            options={this.props.options.roles}
            name="roleId"
          />
          <FormInlineRadioComponent
            label="アカウントロック状態"
            options={[
              { name: '全て', value: '0', default: true },
              { name: 'なし', value: '1', default: false },
              { name: 'ロック状態', value: '2', default: false },
            ]}
            name="isAccountLock"
          />
          {this.props.state.isSystemAdmin ? (
            <FormInlineRadioComponent
              label="社員ステータス"
              options={[
                { name: '全て', value: '0', default: true },
                { name: '有効', value: '1', default: false },
                { name: '無効', value: '2', default: false },
              ]}
              name="isDisable"
            />
          ) : (
            <></>
          )}
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton
                isEnabled={() => {
                  return false;
                }}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
export default connect(
  (state) => {
    const values = state.staff.staffSearch.searchValues.values || {};

    return {
      options: state.options,
      initialValues: {
        ...values,
        occupationId: values.occupationId || [],
      },
      state: {
        isSystemAdmin: state.staff.staffRefer.isSystemAdmin,
      },
    };
  },
  () => {}
)(
  reduxForm({
    // a unique name for the form
    form: 'staffSearchForm',
  })(StaffSearchForm)
);

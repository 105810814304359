import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Card from '../../organisms/Card';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';
import ConfirmForm from '../../organisms/roleManagement/RoleManagementEditConfirmForm';
import Form from '../../organisms/roleManagement/RoleManagementEditForm';

import routePath from './routePath';

class RoleManagementEdit extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        roleSearch: PropTypes.shape({
          values: PropTypes.object,
          searchParams: PropTypes.object,
        }),
        roleEdit: PropTypes.shape({
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
        }),
      }),
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      search: PropTypes.func,
      updateRole: PropTypes.func,
      initState: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      fetchEditInitData: PropTypes.func,
      setEditValues: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
    this.transitionToRefer = this.transitionToRefer.bind(this);
    this.backToInput = this.backToInput.bind(this);
    this.submit = this.submit.bind(this);
    this.onCloseCompleteModal = this.onCloseCompleteModal.bind(this);

    this.state = { isConfirm: false };
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.fetchEditInitData(this.id);
  }

  confirm(values) {
    console.debug('values', values);

    this.setState({ values: values, isConfirm: true });
    this.props.setEditValues(values);
  }

  submit() {
    console.debug('submit values: ', this.state.values);

    const values = {
      ...this.state.values,
      id: this.id,
    };
    this.props.updateRole(values);
  }

  transitionToRefer() {
    this.props.initState();
    console.debug('transition to refer');
    if (this.id == null) {
      this.props.history.push(routePath.search);
    } else {
      this.props.history.push(routePath.refer(this.id));
    }
  }

  onCloseCompleteModal() {
    const values = this.props.state.roleSearch.values;
    const searchParams = this.props.state.roleSearch.searchParams;

    if (
      Object.keys(values).length > 0 ||
      Object.keys(searchParams).length > 0
    ) {
      this.props.search(values, searchParams);
    }
    this.transitionToRefer();
  }

  backToInput() {
    this.setState({ isConfirm: false });
  }

  render() {
    const isConfirm = () => {
      return this.state.isConfirm;
    };

    const isLoading = () => {
      return this.props.state.isLoading;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>ロール管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="編集">
                  {isLoading() ? (
                    <></>
                  ) : isConfirm() ? (
                    <ConfirmForm
                      onSubmit={this.submit}
                      backToInput={this.backToInput}
                      values={this.state.values}
                      isNew={!this.id}
                    />
                  ) : (
                    <Form
                      onSubmit={this.confirm}
                      onPrevButtonClick={this.transitionToRefer}
                      isNew={!this.id}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <RegisterCompleteModal
          show={this.props.state.roleEdit.isComplete}
          onCloseClick={this.onCloseCompleteModal}
        />
      </>
    );
  }
}

export default RoleManagementEdit;

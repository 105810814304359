import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Field } from 'redux-form';
import styled from 'styled-components';

import FormGroupRow from '../atoms/FormGroupRow';
import FormLabel from '../atoms/FormLabel';
import WysiwygEditor from '../atoms/WysiwygEditor';
import * as Validator from '../utils/Validate';

const DisplayBlockFeedback = styled(Form.Control.Feedback)`
  display: block;
`;

class HorizontalFormWysiwygEditorComponent extends React.Component {
  static get propTypes() {
    return {
      editorState: PropTypes.any,
      onEditorStateChange: PropTypes.func,
      validate: PropTypes.array,
      name: PropTypes.string,
      label: PropTypes.string,
      initialText: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    const { meta } = field;

    return (
      <FormGroupRow>
        {this.props.name ? (
          <Element name={`position-${this.props.name}`} />
        ) : (
          <></>
        )}
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          {this.isRequired() ? (
            <>
              <span className="text-danger">*</span>
            </>
          ) : (
            <></>
          )}
        </FormLabel>
        <div className="col-sm-6">
          <WysiwygEditor
            onChange={field.input.onChange}
            initialText={field.initialText}
          />
          {meta.error ? (
            <DisplayBlockFeedback type="invalid">
              {meta.error}
            </DisplayBlockFeedback>
          ) : (
            <></>
          )}
        </div>
      </FormGroupRow>
    );
  }

  render() {
    return (
      <Field
        label={this.props.label}
        name={this.props.name}
        component={this.component}
        initialText={this.props.initialText}
        validate={this.props.validate}
      />
    );
  }
}

export default HorizontalFormWysiwygEditorComponent;

import PropTypes, { func } from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { SEARCH_STATE } from '../../../reducers/ReservationManagement';
import Card from '../../organisms/Card';
import EventContent from '../../organisms/reservationManagement/EventContent';
import InterviewContent from '../../organisms/reservationManagement/InterviewContent';
import eventRoutePath from '../events/routePath';

import routePath from './routePath';

import 'react-tabs/style/react-tabs.css';

export const TabParam = {
  Event: null,
  Interview: 1,
};

class ReservationManagementSearch extends React.Component {
  static get propTypes() {
    return {
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
      state: PropTypes.shape({
        prevState: PropTypes.number,
        initStoreSearchValues: PropTypes.any,
        reservationManagementEvents: PropTypes.shape({
          isProxyBooking: PropTypes.bool,
          isShowPlansDetailModal: PropTypes.bool,
          events: PropTypes.array,
          eventsDetail: PropTypes.object,
          selectedDate: PropTypes.Date,
        }),
        reservationManagementInterview: PropTypes.shape({
          isProxyBooking: PropTypes.bool,
          isShowPlansDetailModal: PropTypes.bool,
          interviews: PropTypes.array,
          interviewsDetail: PropTypes.array,
          selectedDate: PropTypes.Date,
        }),
        substituteUser: PropTypes.object,
      }),
      options: PropTypes.shape({
        areas: PropTypes.array,
        halls: PropTypes.array,
        categories: PropTypes.array,
        tags: PropTypes.array,
        bases: PropTypes.array,
        eventTypes: PropTypes.array,
        methodTypes: PropTypes.array,
      }),
      fetchAreas: PropTypes.func,
      fetchBases: PropTypes.func,
      fetchCategories: PropTypes.func,
      fetchTags: PropTypes.func,
      fetchMethodTypes: PropTypes.func,
      fetchHallsByAreaId: PropTypes.func,
      initStateHalls: PropTypes.func,
      fetchReservationEvents: PropTypes.func,
      fetchReservationInterviews: PropTypes.func,
      fetchReservationEventsDetail: PropTypes.func,
      fetchEventTypes: PropTypes.func,
      fetchReservationInterviewsDetail: PropTypes.func,
      fetchUserProfile: PropTypes.func,
      clearSubstitute: PropTypes.func,
      hideEventsDetailModal: PropTypes.func,
      hideInterviewDetailModal: PropTypes.func,
      initStoreSearchValues: PropTypes.func,
      initSubstituteUser: func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      transitionToInterview: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.onClickEventsAreasSelectBox = this.onClickEventsAreasSelectBox.bind(
      this
    );

    this.hideEventsDetailModal = this.hideEventsDetailModal.bind(this);
    this.transitionToEventDetail = this.transitionToEventDetail.bind(this);
    this.transitionToEventReserveEdit = this.transitionToEventReserveEdit.bind(
      this
    );
    this.onClickEvent = this.onClickEvent.bind(this);
    this.onClickInterviewDetail = this.onClickInterviewDetail.bind(this);
    this.hideInterviewDetailModal = this.hideInterviewDetailModal.bind(this);
    this.transitionToInterviewDetail = this.transitionToInterviewDetail.bind(
      this
    );
    this.transitionToInterviewReserveEdit = this.transitionToInterviewReserveEdit.bind(
      this
    );
    this.onTabSelect = this.onTabSelect.bind(this);
    this.substituteRelease = this.substituteRelease.bind(this);

    const { params } = this.props.match;
    console.debug('reservationmanagement search params', params);
    if (params && params.id) {
      this.state = { userId: params.id };

      this.props.fetchUserProfile(params.id);
    } else {
      this.props.initSubstituteUser();
    }
  }

  componentDidMount() {
    this.props.fetchAreas();
    this.props.fetchBases();
    this.props.fetchCategories();
    this.props.fetchTags();
    this.props.fetchEventTypes();
    this.props.fetchMethodTypes();
  }

  onClickEventsAreasSelectBox(e) {
    if (e && e.target) {
      this.props.fetchHallsByAreaId(e.target.value);
    } else {
      this.props.initStateHalls();
    }
  }

  onClickEventsSearchButton(values) {
    this.props.fetchReservationEvents(values);
  }

  onClickInterviewsSearchButton(values) {
    this.props.fetchReservationInterviews(values);
  }

  hideEventsDetailModal() {
    this.props.hideEventsDetailModal();
  }

  hideInterviewDetailModal() {
    this.props.hideInterviewDetailModal();
  }

  transitionToEventDetail(eventId) {
    const path = eventRoutePath.refer(eventId);
    this.props.history.push(path);
  }

  transitionToEventReserveEdit(eventId) {
    const path = routePath.eventEdit(eventId);
    if (this.state && this.state.userId) {
      this.props.history.push(`${path}?userId=${this.state.userId}`);
      return;
    }
    this.props.history.push(path);
  }

  onClickEvent(eventId) {
    const events = this.props.state.reservationManagementEvents.events;

    const filteredEvents = events.filter((event) => event.id === eventId);

    let startTime;
    if (filteredEvents.length > 0) {
      startTime = new Date(filteredEvents[0].from);
    }

    this.props.fetchReservationEventsDetail(eventId, startTime);
  }

  onClickInterviewDetail(interviewsId) {
    const interviews = this.props.state.reservationManagementInterview
      .interviews;

    const filteredInterviews = interviews.filter(
      (interview) => interview.id === interviewsId
    );

    let startTime;
    if (filteredInterviews.length > 0) {
      startTime = new Date(filteredInterviews[0].from);
    }

    this.props.fetchReservationInterviewsDetail(interviewsId, startTime);
  }

  transitionToInterviewDetail(interviewId, interviewerId, selectedDate) {
    this.props.transitionToInterview(interviewId, interviewerId, selectedDate);
  }

  transitionToInterviewReserveEdit(interviewId) {
    const path = routePath.interviewEdit(interviewId);
    if (this.state && this.state.userId) {
      this.props.history.push(`${path}?userId=${this.state.userId}`);
      return;
    }

    this.props.history.push(path);
  }

  /**
   * タブの切替が起こった際に検索条件の入力値を初期化する
   */
  onTabSelect() {
    this.props.initStoreSearchValues();
  }

  /**
   * 代理予約の状態を解除する
   */
  substituteRelease() {
    const path = routePath.search('');
    this.props.clearSubstitute();
    this.props.history.push(path);
    this.setState({ userId: null });
  }

  render() {
    const setInterviewsClanderValues = () => {
      const interviews = this.props.state.reservationManagementInterview
        .interviews;
      // データがない場合は空配列のまま返却
      if (interviews.length === 0) {
        return [];
      }

      const interviewsList = interviews.map((interview) => {
        const fromDateTime = new Date(interview.from);
        const toDateTime = new Date(interview.to);
        let matched = false;

        // 面談者が決定している場合はマッチングをTrueにする
        if (interview.interviewee) {
          matched = true;
        }

        let methodTypeName = interview.methodTypeName;
        if (methodTypeName == null) {
          methodTypeName = '未選択';
        }

        let title = `面談方法: ${methodTypeName} 担当者: ${interview.interviewer}`;

        if (interview.interviewee && interview.interviewee.length > 0) {
          title = `${title} 面談者: ${interview.interviewee}`;
        }

        return {
          id: interview.id,
          title: title,
          interviewer: interview.interviewer,
          interviewee: interview.interviewee,
          allDay: false,
          start: fromDateTime,
          end: toDateTime,
          matched: matched,
        };
      });

      return interviewsList;
    };

    const getTabDefaultIndex = () => {
      if (this.props.state.prevState === SEARCH_STATE.EVNET) {
        return 0;
      }

      if (this.props.state.prevState === SEARCH_STATE.INTERVIEW) {
        return 1;
      }

      return 0;
    };

    return (
      <>
        <section className="section">
          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="予約管理">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <Tabs
                        onSelect={this.onTabSelect}
                        defaultIndex={getTabDefaultIndex()}
                      >
                        <TabList>
                          <Tab>イベント</Tab>
                          <Tab>面談</Tab>
                        </TabList>
                        <TabPanel>
                          <EventContent
                            onSubmit={this.onClickEventsSearchButton.bind(this)}
                            options={this.props.options}
                            state={this.props.state}
                            onClickSubstituteReleaseButton={
                              this.substituteRelease
                            }
                            eventsCalendarValues={
                              this.props.state.reservationManagementEvents
                                .events
                            }
                            onClickEvent={this.onClickEvent}
                            onClickModalCloseButton={this.hideEventsDetailModal}
                            onClickModalEventDetailButton={
                              this.transitionToEventDetail
                            }
                            onClickModalEventEditButton={
                              this.transitionToEventReserveEdit
                            }
                          />
                        </TabPanel>
                        <TabPanel>
                          <InterviewContent
                            onSubmit={this.onClickInterviewsSearchButton.bind(
                              this
                            )}
                            options={this.props.options}
                            state={this.props.state}
                            onClickSubstituteReleaseButton={
                              this.substituteRelease
                            }
                            interviewsClanderValues={setInterviewsClanderValues()}
                            onClickEvent={this.onClickInterviewDetail}
                            onClickModalCloseButton={
                              this.hideInterviewDetailModal
                            }
                            onClickInterviewDetailButton={
                              this.transitionToInterviewDetail
                            }
                            onClickReservationDetailButton={
                              this.transitionToInterviewReserveEdit
                            }
                          />
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </>
    );
  }
}

export default ReservationManagementSearch;

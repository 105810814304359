import PropTypes, { shape, bool, string, array, object } from 'prop-types';
import React from 'react';
import { Form, FormGroup, FormLabel } from 'react-bootstrap';

class LoginMailInput extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      meta: shape({
        touched: bool,
        error: string,
      }),
      input: object,
      messages: array,
    };
  }

  render() {
    return (
      <FormGroup>
        <FormLabel htmlFor="email">メールアドレス</FormLabel>
        <Form.Control
          id="email"
          isInvalid={this.props.meta.touched && this.props.meta.invalid}
          {...this.props.input}
        />
        {this.props.meta.touched && this.props.meta.error && (
          <Form.Control.Feedback type="invalid">
            {this.props.meta.error}
          </Form.Control.Feedback>
        )}
        {/* サーバーサイドエラーメッセージ */}
        {this.props.messages != null && this.props.messages.length > 0 ? (
          <div className="alert alert-danger">
            {this.props.messages.map((message, i) => (
              <p key={i}>{message}</p>
            ))}
          </div>
        ) : (
          <></>
        )}
      </FormGroup>
    );
  }
}

export default LoginMailInput;

import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from '../atoms/NormalButton';
import { ReferAction } from '../utils/PermissionComponent';

class DetailButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <ReferAction>
        <NormalButton color="info" onClick={this.props.onClick}>
          {this.props.children ? this.props.children : '詳細'}
        </NormalButton>
      </ReferAction>
    );
  }
}

export default DetailButton;

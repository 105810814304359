import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Card, { Color } from '../../organisms/Card';
import Form from '../../organisms/login/LoginEditForm';

class LoginEdit extends React.Component {
  static get propTypes() {
    return {
      staffLogin: PropTypes.func,
      history: PropTypes.any,
      state: PropTypes.shape({
        errorMessages: PropTypes.array,
        isError: PropTypes.bool,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
  }

  componentDidMount() {}

  confirm(values) {
    this.props.staffLogin(values);
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <div className="login-brand">
            予約管理システム
            {process.env.REACT_APP_HOST ? (
              <p>{process.env.REACT_APP_HOST}</p>
            ) : (
              <></>
            )}
          </div>
          <section className="section">
            <div className="section-body">
              <Row>
                <Col>
                  <Card headerTitle="ログイン" color={Color.PRIMARY}>
                    {this.props.state.isError &&
                    this.props.state.errorMessages &&
                    this.props.state.errorMessages.length > 0 ? (
                      <>
                        <div className="alert alert-danger">
                          {this.props.state.errorMessages &&
                            this.props.state.errorMessages.map((e, i) => {
                              return <p key={i}>{e}</p>;
                            })}
                        </div>
                        <div className="text-center">
                          <a href="mailto:system-admin@jaic.co.jp">
                            ログインにお困りの方はこちら
                          </a>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <Form onSubmit={this.confirm} />
                    <TopMarginDiv className="text-center">
                      <Link to="/admin/password_reset/mail/input">
                        パスワードを忘れた方はこちら
                      </Link>
                    </TopMarginDiv>
                  </Card>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const TopMarginDiv = styled.div`
  margin-top: 10px;
`;

export default LoginEdit;

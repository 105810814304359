import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormInput from '../../atoms/FormInputPassword';
import * as Validator from '../../utils/Validate';

class PasswordInputComponent extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.any,
      placeholder: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    return (
      <>
        <FormInput {...field} />
        {field.meta.touched && field.meta.error && (
          <Form.Control.Feedback type="invalid">
            {field.meta.error}
          </Form.Control.Feedback>
        )}
      </>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        placeholder={this.props.placeholder}
        component={this.component}
        validate={this.props.validate}
      />
    );
  }
}

export default PasswordInputComponent;

import { LoginEdit } from '../../../containers/Login';

import routePath from './routePath';

const LoginRoutes = [
  {
    path: routePath.login,
    exact: true,
    component: LoginEdit,
  },
];

export default LoginRoutes;

import { trackPromise } from 'react-promise-tracker';
import { destroy } from 'redux-form';

import Action, { EmptyAction } from '../components/model/Action';
import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';
import { trackAsync } from './util';

const axios = createAxios('/admin/company');

const fetchCompanySuccess = (result) => {
  return {
    type: 'company/FETCH_SEARCH',
    payload: {
      result: result,
    },
  };
};

const search = (values, searchParams) => {
  const params = new Params({
    name: values.name,
    romanCharName: values.romanCharName,
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  });

  return axios('/admin/company_names', {
    params: params.removeParams(),
  });
};

const storeSearchValues = (values, searchParams) => {
  return new Action('company/STORE_SEARCH_VALUES', {
    values,
    searchParams,
  });
};

const makeSearch = (values, searchParams) => {
  return (dispatch) => {
    dispatch(storeSearchValues(values, searchParams));
    return trackPromise(
      search(values, searchParams)
        .then((data) => dispatch(fetchCompanySuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const makeShowDeleteConfirmModal = () => {
  return new EmptyAction('company/SHOW_DELETE_CONFIRM_MODAL');
};

const makeHideDeleteConfirmModal = () => {
  return new EmptyAction('company/HIDE_DELETE_CONFIRM_MODAL');
};

const deleteCompanies = (ids) => {
  return axios.delete('/admin/company_names', {
    data: {
      companyNameIds: ids,
    },
  });
};

const deleteTagsSuccess = (data) => {
  return {
    type: 'company/DELETE_COMPANIES_SUCCESS',
    payload: {
      result: data,
    },
  };
};

const makeDeleteCompanies = (ids) => {
  return (dispatch) => {
    return trackPromise(
      deleteCompanies(ids)
        .then((data) => {
          dispatch(deleteTagsSuccess(data.data));
          dispatch(makeHideDeleteConfirmModal());
          dispatch(makeShowDeleteCompleteModal());
        })
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const makeShowDeleteCompleteModal = () => {
  return new EmptyAction('company/SHOW_DELETE_COMPLETE_MODAL');
};

const makeHideDeleteCompleteModal = (searchValues) => {
  return (dispatch) => {
    if (searchValues && Object.keys(searchValues).length > 0) {
      search(searchValues.values, searchValues.searchParams)
        .then((data) => {
          dispatch(fetchCompanySuccess(data.data));
          dispatch(new EmptyAction('company/HIDE_DELETE_COMPLETE_MODAL'));
        })
        .catch((err) => {
          dispatch(fetchErrorData(err));
        });
    } else {
      dispatch(new EmptyAction('company/HIDE_DELETE_COMPLETE_MODAL'));
    }
  };
};

const fetchCompany = (id) => {
  return axios.get(`/admin/company_name/${id}`);
};

const fetchCompanyDetailSuccess = (data) => {
  return new Action('company/FETCH_COMPANY_NAME_SUCCESS', data);
};

const makeFetchCompany = (companyId) => {
  return (dispatch) => {
    return trackPromise(
      fetchCompany(companyId)
        .then((data) => {
          dispatch(fetchCompanyDetailSuccess(data.data));
        })
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const makeSetEditValues = (values) => {
  return new Action('company/SET_EDIT_VALUES', values);
};

const makeBackToInput = () => {
  return new EmptyAction('company/GO_BACK_TO_INPUT');
};

const makeFetchCreateCompany = (params) => {
  return axios.post(`/admin/company_names`, params);
};

const makeFetchUpdateCompany = (id, params) => {
  return axios.put(`/admin/company_name/${id}`, params);
};

const fetchCreateCompanySuccess = () => {
  return new EmptyAction('company/CREATE_COMPANY_SUCCESS');
};

const fetchUpdateCompanySuccess = () => {
  return new EmptyAction('company/UPDATE_COMPANY_SUCCESS');
};

const makeUpdateError = (err) => {
  if (err.response) {
    if (err.response.status === 400 && err.response.data) {
      return new Action('company/UPDATE_ERROR', err.response.data);
    }
  }

  return fetchErrorData(err);
};

const makeUpdateCompany = (values) => {
  return (dispatch) => {
    const params = new Params({
      id: values.id,
      name: values.name,
    }).removeParams();

    if (values.id == null) {
      return trackPromise(
        makeFetchCreateCompany(params)
          .then(() => {
            dispatch(fetchCreateCompanySuccess());
          })
          .catch((err) => dispatch(makeUpdateError(err)))
      );
    } else {
      return trackPromise(
        makeFetchUpdateCompany(values.id, params)
          .then(() => {
            dispatch(fetchUpdateCompanySuccess());
          })
          .catch((err) => dispatch(makeUpdateError(err)))
      );
    }
  };
};

const makeInitEdit = () => {
  return new EmptyAction('company/INIT_COMPANY_EDIT');
};

const makeInitCompanyEdit = (companyId) => {
  return (dispatch) => {
    return trackAsync(async () => {
      try {
        dispatch(makeInitEdit());

        if (companyId) {
          const tagData = await fetchCompany(companyId);
          dispatch(fetchCompanyDetailSuccess(tagData.data));
        }
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

const confirmIsUsed = (companyNames) => {
  return axios.get('/admin/company_names/used', {
    params: {
      companyNames: companyNames,
    },
  });
};

const showDeleteConfirm = () => {
  return {
    type: 'company/SHOW_DELETE_CONFIRM',
  };
};

const confirmIsUsedSuccess = (data) => {
  if (data.containsUsed) {
    return new EmptyAction('company/SHOW_USED_CONTAINS_ALERT');
  } else {
    return showDeleteConfirm();
  }
};

const makeConfirmIsUsed = (companyNames) => {
  return (dispatch) => {
    return trackAsync(async () => {
      try {
        const isUsedRes = await confirmIsUsed(companyNames);
        dispatch(confirmIsUsedSuccess(isUsedRes.data));
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

const makeHideContainsAlertModal = () => {
  return new EmptyAction('company/HIDE_USED_CONTAINS_ALERT');
};

const initState = () => {
  return {
    type: 'company/INIT_STATE',
  };
};

const makeInitCompany = (isComeFromOtherPage) => {
  return async (dispatch) => {
    try {
      if (isComeFromOtherPage) {
        dispatch(initState());
        dispatch(destroy('companyEditForm'));
      }
    } catch (e) {
      fetchErrorData(e);
    }
  };
};

export {
  makeSearch,
  makeShowDeleteConfirmModal,
  makeFetchCompany,
  makeInitCompanyEdit,
  makeInitCompany,
  makeHideDeleteConfirmModal,
  makeDeleteCompanies,
  makeHideDeleteCompleteModal,
  makeSetEditValues,
  makeBackToInput,
  makeUpdateCompany,
  makeConfirmIsUsed,
  makeHideContainsAlertModal,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import IsDisplay from '../../../domain/base/IsDisplay';
import ModifyButton from '../../molecules/ModifyButton';
import PrevButton from '../../molecules/PrevButton';
import ReferItem from '../../molecules/ReferItem';
import ReferAccess from '../../organisms/base/ReferAccess';
import Card from '../../organisms/Card';

import routePath from './routePath';

class BaseRefer extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      fetchBase: PropTypes.func,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        baseRefer: PropTypes.shape({
          detail: PropTypes.shape({
            name: PropTypes.string,
            areaName: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            isDisplay: PropTypes.bool,
            isCaravan: PropTypes.bool,
            pref: PropTypes.string,
            prefId: PropTypes.string,
            zipCode: PropTypes.string,
            city: PropTypes.string,
            buildingName: PropTypes.string,
            phoneNumber: PropTypes.string,
            mailAddress: PropTypes.string,
            eventDisplayLimit: PropTypes.string,
            googleMapUrl: PropTypes.string,
            googleMapShortUrl: PropTypes.string,
            accessList: PropTypes.array,
            nearestStation: PropTypes.string,
            id: PropTypes.string,
          }),
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      initReferState: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.transitionToEdit = this.transitionToEdit.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.initReferState();
    this.props.fetchBase(this.id);
  }

  transitionToSearch() {
    this.props.history.push(routePath.search);
  }

  transitionToEdit() {
    this.props.history.push(routePath.edit(this.id));
  }

  render() {
    const getName = () => {
      return this.props.state.baseRefer.detail.name;
    };

    const getDisplayName = () => {
      return this.props.state.baseRefer.detail.displayName;
    };

    const getCompanyName = () => {
      return this.props.state.baseRefer.detail.companyName;
    };

    const getIsCaravan = () => {
      return this.props.state.baseRefer.detail.isCaravan
        ? 'キャラバン拠点'
        : '通常拠点';
    };

    const getIsDisplay = () => {
      const isDisplay = new IsDisplay(
        this.props.state.baseRefer.detail.isDisplay
      );
      return isDisplay.displayName;
    };

    const getAreaName = () => {
      return this.props.state.baseRefer.detail.areaName;
    };

    const getPref = () => {
      return this.props.state.baseRefer.detail.pref;
    };

    const getZipCode = () => {
      return this.props.state.baseRefer.detail.zipCode;
    };

    const getCity = () => {
      return this.props.state.baseRefer.detail.city;
    };

    const getBuildingName = () => {
      return this.props.state.baseRefer.detail.buildingName;
    };

    const getPhoneNumber = () => {
      return this.props.state.baseRefer.detail.phoneNumber;
    };

    const getMailAddress = () => {
      return this.props.state.baseRefer.detail.mailAddress;
    };

    const getEventDisplayLimit = () => {
      return this.props.state.baseRefer.detail.eventDisplayLimit;
    };

    const getGoogleMapUrl = () => {
      return this.props.state.baseRefer.detail.googleMapUrl;
    };

    const getGoogleMapShortUrl = () => {
      return this.props.state.baseRefer.detail.googleMapShortUrl;
    };

    const getAccessList = () => {
      return this.props.state.baseRefer.detail.accessList;
    };

    const getNearestStation = () => {
      return this.props.state.baseRefer.detail.nearestStation;
    };

    return (
      <section className="section">
        <div className="section-header">
          <h1>拠点管理</h1>
        </div>

        <div className="section-body">
          <Row>
            <Col>
              <Card headerTitle="詳細">
                {this.props.state.isLoading ? (
                  <></>
                ) : (
                  <>
                    <ReferItem label="拠点名">{getName()}</ReferItem>
                    <ReferItem label="表示名">{getDisplayName()}</ReferItem>
                    <ReferItem label="キャラバンフラグ">
                      {getIsCaravan()}
                    </ReferItem>
                    <ReferItem label="ユーザ向け表示フラグ">
                      {getIsDisplay()}
                    </ReferItem>
                    <ReferItem label="企業名">{getCompanyName()}</ReferItem>
                    <ReferItem label="エリア">{getAreaName()}</ReferItem>
                    <h4>住所</h4>
                    <hr />
                    <ReferItem label="郵便番号">{getZipCode()}</ReferItem>
                    <ReferItem label="都道府県">{getPref()}</ReferItem>
                    <ReferItem label="市区町村">{getCity()}</ReferItem>
                    <ReferItem label="建物名">{getBuildingName()}</ReferItem>
                    <ReferItem label="連絡先連絡番号">
                      {getPhoneNumber()}
                    </ReferItem>
                    <ReferItem label="連絡先メールアドレス">
                      {getMailAddress()}
                    </ReferItem>
                    <ReferItem label="イベント表示上限件数">
                      {getEventDisplayLimit()}
                    </ReferItem>
                    <h4>地図</h4>
                    <hr />
                    <ReferItem label="通常URL">
                      <a
                        target="_blank"
                        href={getGoogleMapUrl()}
                        rel="noopener noreferrer"
                      >
                        {getGoogleMapUrl()}
                      </a>
                    </ReferItem>
                    <ReferItem label="短縮URL">
                      <a
                        target="_blank"
                        href={getGoogleMapShortUrl()}
                        rel="noopener noreferrer"
                      >
                        {getGoogleMapShortUrl()}
                      </a>
                    </ReferItem>

                    <ReferAccess accessList={getAccessList()} />

                    <ReferItem label="最寄り駅">
                      {getNearestStation()}
                    </ReferItem>

                    <hr />
                    <Row>
                      <div className="mx-auto">
                        <PrevButton onClick={this.transitionToSearch} />
                        <ModifyButton onClick={this.transitionToEdit} />
                      </div>
                    </Row>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default BaseRefer;

import PropTypes from 'prop-types';
import React from 'react';

class LeggendLabel extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.string,
    };
  }

  render() {
    return <div style={{ marginLeft: '0.4em' }}>{this.props.children}</div>;
  }
}

export default LeggendLabel;

import defaultReducer from './DefaultReducers';

const initialState = {
  searchResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },

    items: [],
  },
  companySearch: {
    searchValues: {
      searchParams: {},
      values: {},
    },
    isDisplayUsedContainsAlertDialog: false,
    isShowDeleteCompleteModal: false,
    isConfirm: false,
  },
  companyRefer: {
    detail: {
      name: '',
    },
  },
  companyEdit: {
    isConfirm: false,
    isError: false,
    errorMessages: {},
  },
  isLoading: false,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'company/FETCH_SEARCH': {
      const searchResults = action.payload.result;
      return {
        ...state,
        searchResults: {
          items: searchResults.result,
          searchParams: searchResults.searchParams,
        },
      };
    }
    case 'company/FETCH_COMPANY_NAME_SUCCESS': {
      const result = action.payload;
      return {
        ...state,
        companyRefer: {
          ...state.companyRefer,
          detail: result,
        },
      };
    }
    case 'company/SHOW_DELETE_CONFIRM_MODAL': {
      return {
        ...state,
        companySearch: {
          ...state.companySearch,
          isConfirm: true,
        },
      };
    }
    case 'company/HIDE_DELETE_CONFIRM_MODAL': {
      return {
        ...state,
        companySearch: {
          ...state.companySearch,
          isConfirm: false,
        },
      };
    }
    case 'company/STORE_SEARCH_VALUES': {
      return {
        ...state,
        companySearch: {
          ...state.companySearch,
          searchValues: action.payload,
        },
      };
    }
    case 'company/SHOW_DELETE_COMPLETE_MODAL': {
      return {
        ...state,
        companySearch: {
          ...state.companySearch,
          isShowDeleteCompleteModal: true,
        },
      };
    }
    case 'company/HIDE_DELETE_COMPLETE_MODAL': {
      return {
        ...state,
        companySearch: {
          ...state.companySearch,
          isShowDeleteCompleteModal: false,
        },
      };
    }
    case 'company/SET_EDIT_VALUES': {
      const values = action.payload;
      return {
        ...state,
        companyRefer: {
          ...state.companyRefer,
          detail: {
            name: values.name,
          },
        },
        companyEdit: {
          ...state.companyEdit,
          isConfirm: true,
          isError: false,
          errorMessages: {},
        },
      };
    }
    case 'company/GO_BACK_TO_INPUT': {
      return {
        ...state,
        companyEdit: {
          ...state.companyEdit,
          isConfirm: false,
        },
      };
    }
    case 'company/UPDATE_COMPANY_SUCCESS': {
      return {
        ...state,
        companyEdit: {
          ...state.companyEdit,
          isComplete: true,
        },
      };
    }
    case 'company/CREATE_COMPANY_SUCCESS': {
      return {
        ...state,
        companyEdit: {
          ...state.companyEdit,
          isComplete: true,
        },
      };
    }
    case 'company/INIT_COMPANY_EDIT': {
      return {
        ...state,
        companyRefer: {
          ...state.companyRefer,
          detail: {},
        },
        companyEdit: {
          ...initialState.companyEdit,
        },
      };
    }
    case 'company/UPDATE_ERROR': {
      const result = action.payload;
      return {
        ...state,
        companyEdit: {
          errorMessages: result.messages,
          isConfirm: false,
          isError: true,
        },
      };
    }
    case 'company/SHOW_USED_CONTAINS_ALERT': {
      return {
        ...state,
        companySearch: {
          ...state.companySearch,
          isDisplayUsedContainsAlertDialog: true,
        },
      };
    }
    case 'company/SHOW_DELETE_CONFIRM': {
      return {
        ...state,
        companySearch: {
          ...state.companySearch,
          isConfirm: true,
        },
      };
    }
    case 'company/HIDE_USED_CONTAINS_ALERT': {
      return {
        ...state,
        companySearch: {
          ...state.companySearch,
          isDisplayUsedContainsAlertDialog: false,
        },
      };
    }
    case 'company/INIT_STATE': {
      return initialState;
    }
    default:
      return defaultReducer(action, state);
  }
};

export { companyReducer as default };

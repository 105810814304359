import { connect } from 'react-redux';

import {
  makeFetchTemplateCategories,
  makeFetchTemplateNames,
  makeFetchTemplateGroups,
  makeSearch,
  makeFetchTemplate,
  makeUpdateTemplate,
  makeDeleteTemplate,
  makeSendMailTest,
  newTemplate,
  showDeleteConfirm,
  closeDeleteConfirm,
  showDeleteAlert,
  closeDeleteAlert,
  closeDeleteComplete,
  deleteErr,
  initState,
  makeFetchContactTypes,
  initializationTestMailResult,
} from '../actions/Template';
import TemplateComponent from '../components/pages/template/Template';
import TemplateEditComponent from '../components/pages/template/TemplateEdit';
import TemplateReferComponent from '../components/pages/template/TemplateRefer';
import TemplateSearchComponent from '../components/pages/template/TemplateSearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.template,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTemplateCategories() {
      dispatch(makeFetchTemplateCategories());
    },
    fetchTemplateNames(id) {
      dispatch(makeFetchTemplateNames(id));
    },
    fetchTemplateGroups() {
      dispatch(makeFetchTemplateGroups());
    },
    search(values, searchParams) {
      dispatch(makeSearch(values, searchParams));
    },
    fetchTemplate(id) {
      dispatch(makeFetchTemplate(id));
    },
    updateTemplate(values) {
      dispatch(makeUpdateTemplate(values));
    },
    deleteTemplate(values) {
      dispatch(makeDeleteTemplate(values));
    },
    sendMailTest(values) {
      dispatch(makeSendMailTest(values));
    },
    newTemplate() {
      dispatch(newTemplate());
    },
    showDeleteConfirm() {
      dispatch(showDeleteConfirm());
    },
    closeDeleteConfirm() {
      dispatch(closeDeleteConfirm());
    },
    showDeleteAlert() {
      dispatch(showDeleteAlert());
    },
    closeDeleteAlert() {
      dispatch(closeDeleteAlert());
    },
    closeDeleteComplete() {
      dispatch(closeDeleteComplete());
    },
    deleteErr() {
      dispatch(deleteErr());
    },
    initState() {
      dispatch(initState());
    },
    fetchContactTypes() {
      dispatch(makeFetchContactTypes());
    },
    initializationTestMailResult() {
      dispatch(initializationTestMailResult());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateComponent);
const TemplateSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateSearchComponent);

export { TemplateSearch };

const TemplateRefer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateReferComponent);

export { TemplateRefer };

const TemplateEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateEditComponent);

export { TemplateEdit };

export default class Zipcode {
  constructor(zipcode) {
    /**
     * @property {string} zipcode1
     * @property {string} zipcode2
     */
    const index = zipcode.indexOf('-');
    this.zipcode1 = zipcode.slice(0, index);
    this.zipcode2 = zipcode.slice(index + 1);
  }

  toString() {
    return `${this.zipcode1}-${this.zipcode2}`;
  }

  /**
   * 前の郵便番号を取得する
   * @returns {string}
   */
  getFirst() {
    return this.zipcode1;
  }

  /**
   * 後ろの郵便番号を取得する
   * @returns {string}
   */
  getLast() {
    return this.zipcode2;
  }
}

const FromZipcode = (zipcode1, zipcode2) => {
  return new Zipcode(`${zipcode1}-${zipcode2}`);
};

export { FromZipcode };

import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import Params from '../components/model/Params';

import { fetchErrorData } from './common';
import { trackAsync } from './util';

/**
 * フォーム一覧取得
 * @param values
 * @returns {Promise<AxiosResponse<T>>}
 */
const fetchForms = (values) => {
  const params = new Params({
    name: values.name,
  });

  return axios.get(`/admin/forms`, {
    params: params.removeParams(),
    headers: {
      'X-Referer': '/admin/form',
    },
  });
};

const fetchFormsSuccess = (data, params) => {
  return new Action('forms/FETCH_FORMS', {
    result: data,
    params: params,
  });
};

const makeFetchForms = (values) => {
  return (dispatch) => {
    trackAsync(async () => {
      try {
        dispatch(showLoading());

        dispatch({
          type: 'forms/STORE_SEARCH_FORMS_VALUES',
          payload: values,
        });

        const referDataRes = await fetchForms(values);
        dispatch(fetchFormsSuccess(referDataRes.data, values));

        dispatch(hideLoading());
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

/**
 * フォーム詳細取得
 * @param values
 * @returns {Promise<AxiosResponse<T>>}
 */
const fetchFormById = (id) => {
  return axios.get(`/admin/forms/` + id, {
    headers: {
      'X-Referer': '/admin/form',
    },
  });
};

const fetchFormByIdSuccess = (data) => {
  return new Action('forms/FETCH_FORM_BY_ID', {
    result: data,
  });
};

const makeFetchFormById = (id) => {
  return (dispatch) => {
    return fetchFormById(id)
      .then((data) => dispatch(fetchFormByIdSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

/**
 * フォーム登録/編集
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
const addForm = (params) => {
  return axios.post(`/admin/forms`, params, {
    headers: { 'X-Referer': '/admin/form' },
  });
};

const updateForm = (id, params) => {
  return axios.put(`/admin/forms/` + id, params, {
    headers: { 'X-Referer': '/admin/form' },
  });
};
const fetchUpdateForm = (data) => {
  return {
    type: 'form/FETCH_UPDATE_FORM',
    payload: {
      result: data,
    },
  };
};

const PARTS_TYPE_SELECT = 3;
const PARTS_TYPE_CHECKBOX = 4;
const PARTS_TYPE_RADIO = 5;
const PARTS_TYPE_HTML = 7;

const makeEditForm = (values) => {
  const sections = [];
  for (const section of values.sections) {
    const partsList = [];
    for (const parts of section.parts) {
      let items = null;
      if (
        parts.type === PARTS_TYPE_SELECT ||
        parts.type === PARTS_TYPE_CHECKBOX ||
        parts.type === PARTS_TYPE_RADIO
      ) {
        items = parts.items;
      } else if (parts.type === PARTS_TYPE_HTML) {
        items = [{ value: parts.value }];
      }

      partsList.push({
        ...parts,
        validationMax: parts.validationMax ? Number(parts.validationMax) : null,
        validationMin: parts.validationMin ? Number(parts.validationMin) : null,
        required: parts.required === true,
        defaultFlag: parts.defaultFlag === true,
        items: items,
      });
    }
    sections.push({
      ...section,
      parts: partsList,
    });
  }

  const param = {
    ...values,
    sections: sections,
  };

  return (dispatch) => {
    let request;
    if (values.id == null) {
      request = addForm(param);
      trackPromise(
        request
          .then((data) => dispatch(fetchUpdateForm(data.data)))
          .catch((err) => dispatch(fetchErrorData(err)))
      );
    } else {
      request = updateForm(values.id, param);
      trackPromise(
        request
          .then((data) => dispatch(fetchUpdateForm(data.data)))
          .catch((err) => dispatch(fetchErrorData(err)))
      );
    }
  };
};

/**
 * フォームの削除
 * @param ids
 * @returns {Promise<AxiosResponse<T>>}
 */
function deleteForm(ids) {
  return Promise.all(
    ids.map((id) =>
      axios.delete(`/admin/forms/` + id, {
        headers: {
          'X-Referer': '/admin/form',
        },
      })
    )
  );
}

const deleteFormSuccess = () => {
  return {
    type: 'forms/DELETE_FORM',
  };
};

const makeDeleteForm = (id) => {
  return (dispatch) => {
    return trackPromise(
      deleteForm(id)
        .then((data) => dispatch(deleteFormSuccess()))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

/**
 * SF項目一覧取得
 * @returns {Promise<AxiosResponse<T>>}
 */
const fetchSfItems = () => {
  return axios.get(`/form/sf_item`, {
    headers: {
      'X-Referer': '/admin/form',
    },
  });
};

const fetchSfItemsSuccess = (result) => {
  return {
    type: 'FETCH_SF_ITEMS',
    payload: {
      result: new OptionCollection(
        result.map((sfItem) => {
          return new Option(sfItem.id, sfItem.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchSfItems = () => {
  return (dispatch) => {
    return fetchSfItems()
      .then((data) => dispatch(fetchSfItemsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

/**
 * パーツ一覧取得
 * @returns {Promise<AxiosResponse<T>>}
 */
const fetchParts = (type) => {
  const params = {
    type: type,
  };
  return axios.get(`/admin/form/parts`, {
    params: params,
    headers: {
      'X-Referer': '/admin/form',
    },
  });
};

const fetchPartsSuccess = (result) => {
  return {
    type: 'FETCH_PARTS',
    payload: {
      result: new OptionCollection(
        result.map((parts) => {
          return new Option(parts.id, parts.name);
        })
      ).getOptions(),
    },
  };
};

const fetchPartsForThanksPageSuccess = (result) => {
  return {
    type: 'FETCH_PARTS_FOR_THANKS_PAGE',
    payload: {
      result: new OptionCollection(
        result.map((parts) => {
          return new Option(parts.id, parts.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchParts = () => {
  return (dispatch) => {
    return fetchParts(0)
      .then((data) => dispatch(fetchPartsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const makeFetchPartsForThanksPage = () => {
  return (dispatch) => {
    return fetchParts(1)
      .then((data) => dispatch(fetchPartsForThanksPageSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

/**
 * 通知テンプレート一覧取得
 * @returns {Promise<AxiosResponse<T>>}
 */
const fetchMessageTemplates = () => {
  return axios.get(`/admin/form/message_templates`, {
    headers: {
      'X-Referer': '/admin/form',
    },
  });
};

const fetchMessageTemplatesSuccess = (result) => {
  const resultList =
    result.result === []
      ? []
      : new OptionCollection(
          result.result.map((template) => {
            return new Option(
              template.id,
              template.customName + '(' + template.templateName + ')'
            );
          })
        ).getOptions();
  return {
    type: 'FETCH_MESSAGE_TEMPLATES',
    payload: {
      result: resultList,
    },
  };
};

const makeFetchMessageTemplates = () => {
  return (dispatch) => {
    return fetchMessageTemplates()
      .then((data) => dispatch(fetchMessageTemplatesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

/**
 * 初期フォームテンプレートを設定
 * @param values
 * @returns {{payload: {template: *}, type: string}}
 */
const setFormTemplate = (values) => {
  return {
    type: 'form/SET_TEMPLATE',
    payload: {
      template: values,
    },
  };
};

const makeFetchAndSetFormById = (id) => {
  return async (dispatch) => {
    try {
      dispatch(showLoading());

      const referDataRes = await fetchFormById(id);
      dispatch(setFormTemplate(referDataRes.data));

      dispatch(hideLoading());
    } catch (err) {
      dispatch(fetchErrorData(err));
    }
  };
};

/**
 * ローディング系処理
 * @returns {EmptyAction}
 */
const showLoading = () => {
  return new EmptyAction('forms/SHOW_LOADING');
};

const hideLoading = () => {
  return new EmptyAction('forms/HIDE_LOADING');
};

/**
 * storeデータのリセット
 * @returns {{type: string}}
 */
const resetReferResult = () => {
  return {
    type: 'forms/RESET_RESULT',
  };
};

/**
 * storeデータのリセット
 * @returns {{type: string}}
 */
const showDeleteConfirmModal = () => {
  return {
    type: 'forms/SHOW_DELETE_CONFIRM_MODAL',
  };
};

export {
  makeFetchForms,
  makeEditForm,
  makeFetchSfItems,
  makeFetchParts,
  makeFetchMessageTemplates,
  setFormTemplate,
  makeFetchFormById,
  resetReferResult,
  makeDeleteForm,
  makeFetchAndSetFormById,
  makeFetchPartsForThanksPage,
  showDeleteConfirmModal,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Form, Col } from 'react-bootstrap';

import FormGroupRow from '../../atoms/FormGroupRow';
import FormDatePicker from '../../molecules/user/FormDateYmdInputComponentForm';
import styled from 'styled-components';

class FormFromtoComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      fromId: PropTypes.string,
      fromDate: PropTypes.String,
      toId: PropTypes.string,
      toDate: PropTypes.String,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  fieldComponent(field) {
    return <Form.Control type="text" />;
  }

  component() {
    return (
      <FormGroupRow>
        <label className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </label>
        <div className="col-sm-6">
          <NoMarginRow>
            <FormDatePicker name={this.props.fromId} />
            <label className="offset-sm-1 col-sm-2 col-form-label">
              ～  
            </label>
            <FormDatePicker name={this.props.toId} />
          </NoMarginRow>
        </div>
      </FormGroupRow>
    );
  }

  render() {
    return this.component();
  }
}

const NoMarginRow = styled(Row)`
  margin-left: 0px;
`;

export default FormFromtoComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormGroupRow from '../atoms/FormGroupRow';

function InlineFormCheckbox(props) {
  const { input } = props;
  return (
    <div className="form-check form-check-inline">
      <input
        type="checkbox"
        className="form-check-input"
        id={props.id}
        checked={input.value}
        {...input}
      />
    </div>
  );
}

InlineFormCheckbox.propTypes = {
  input: PropTypes.string,
  id: PropTypes.string,
};

class FormInlineCheckBox extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }).isRequired
      ).isRequired,
      onChange: PropTypes.func,
      name: PropTypes.string,
      description: PropTypes.string,
    };
  }

  render() {
    return (
      <FormGroupRow>
        <label className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </label>
        <Col xs={6} className=" align-items-center">
          <div>
            <Field
              component={InlineFormCheckbox}
              name={`${this.props.name}`}
              id={this.props.label}
              label={this.props.label}
            />
          </div>
          {this.props.description ? (
            <div>
              <p>{this.props.description}</p>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </FormGroupRow>
    );
  }
}

export default FormInlineCheckBox;

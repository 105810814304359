/* eslint camelcase: ["error", {allow: ["UNSAFE_componentWillMount"]}] */

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import LabelErrorComponent from '../../molecules/LabelErrorComponent';
import Form from '../../organisms/adminPasswordReset/AdminPasswordResetInputForm';
import Card from '../../organisms/Card';

class AdminPasswordResetMailInput extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        adminPasswordReset: PropTypes.shape({
          isComplete: PropTypes.bool,
          error: PropTypes.shape({
            isError: PropTypes.bool,
            message: PropTypes.string,
          }),
        }),
      }),
      makeSendAdminPasswordReset: PropTypes.func,
      initState: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      id: PropTypes.any,
    };
  }

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.transitionToComplete = this.transitionToComplete.bind(this);
  }

  componentDidMount() {
    const urlParamsString = window.location.search;

    this.id = new URLSearchParams(urlParamsString).get('id');
  }

  submit(values) {
    values.id = this.id;
    this.props.makeSendAdminPasswordReset(values);
  }

  transitionToComplete() {
    this.props.initState();
    this.props.history.push(`/admin/password_reset/complete`);
  }

  render() {
    const errorShow = (error) => {
      if (error.isError) {
        return (
          <LabelErrorComponent
            directly={'・' + error.message}
          ></LabelErrorComponent>
        );
      }
      return '';
    };

    const sendSuccess = () => {
      if (this.props.state.adminPasswordReset.isComplete) {
        this.transitionToComplete();
      }
    };

    return (
      <>
        {sendSuccess()}
        <section className="section">
          <div className="section-header">
            <h1>パスワード再設定</h1>
          </div>
          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="パスワード設定">
                  {errorShow(this.props.state.adminPasswordReset.error)}
                  <Form id={this.id} onSubmit={this.submit} />
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </>
    );
  }
}

export default AdminPasswordResetMailInput;

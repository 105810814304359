import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Field } from 'redux-form';

import FormLabel from '../atoms/FormLabel';
import * as Validator from '../utils/Validate';

class FormTelComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  fieldComponent(field) {
    return (
      <>
        <Form.Control
          type="text"
          {...field.input}
          isInvalid={field.meta.touched && field.meta.error}
        />
        {field.meta.touched && field.meta.error && (
          <Form.Control.Feedback type="invalid">
            {field.meta.error}
          </Form.Control.Feedback>
        )}
      </>
    );
  }

  component() {
    return (
      <div className="form-group row">
        {this.props.name ? (
          <>
            <Element name={`position-${this.props.name}1`} />
            <Element name={`position-${this.props.name}2`} />
            <Element name={`position-${this.props.name}3`} />
          </>
        ) : (
          <></>
        )}
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          電話番号&nbsp;
          <span className="text-danger">*</span>
        </FormLabel>
        <div className="col-sm-6">
          <Row>
            <Col>
              <Field
                name={this.props.name + '1'}
                className="form-control"
                type="text"
                component={this.fieldComponent}
                validate={[Validator.required, Validator.number]}
              />
            </Col>
            <FormLabel className="col-form-label">-</FormLabel>
            <Col>
              <Field
                name={this.props.name + '2'}
                className="form-control"
                type="text"
                component={this.fieldComponent}
                validate={[Validator.required, Validator.number]}
              />
            </Col>
            <FormLabel className="col-form-label">-</FormLabel>
            <Col>
              <Field
                name={this.props.name + '3'}
                className="form-control"
                type="text"
                component={this.fieldComponent}
                validate={[Validator.required, Validator.number]}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    return this.component();
  }
}

export default FormTelComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import LoginLinkButton from '../../molecules/LoginLinkButton';
import Card from '../../organisms/Card';

class AdminPasswordResetMailComplete extends React.Component {
  static get propTypes() {
    return {
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  render() {
    this.getResetPasswordExtrationTime = 24;

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>パスワード再発行</h1>
          </div>
          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="メール送信完了">
                  <div>
                    入力されたメールアドレスにパスワード再設定用のURLが書かれたメールを送信しました。
                    <br></br>
                    {this.getResetPasswordExtrationTime}
                    時間以内に再設定を完了して下さい。<br></br>
                    <br></br>
                    メールが届かない方は、アカウントが存在しないか入力されたメールアドレスが間違っている可能性があります。
                    <br></br>
                    確認のうえ再度入力をお願いします。
                  </div>
                  <div className="text-center">
                    <LoginLinkButton href={'/login'} />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </>
    );
  }
}

export default AdminPasswordResetMailComplete;

import {
  RoleManagementSearch,
  RoleManagementRefer,
  RoleManagementEdit,
} from '../../../containers/RoleManagement';

import routePath from './routePath';

const RoleManagementRoutes = [
  {
    path: routePath.search,
    exact: true,
    component: RoleManagementSearch,
  },
  {
    path: routePath.refer(':id'),
    component: RoleManagementRefer,
  },
  {
    path: routePath.edit(':id'),
    component: RoleManagementEdit,
  },
  {
    path: routePath.newEdit,
    component: RoleManagementEdit,
  },
];

export default RoleManagementRoutes;

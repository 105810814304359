export default class SearchComponent {
  /**
   * 初期検索時のデフォルトのページサイズを返す
   * @returns {number}
   * @constructor
   */
  get pageDefaultSize() {
    return 10;
  }
}

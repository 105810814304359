import PropTypes from 'prop-types';
import React from 'react';

import FormCheckBox from '../atoms/FormCheckBox';

class FormCheckBoxes extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      onChange: PropTypes.func,
    };
  }

  render() {
    return (
      <div className="form-group">
        <label className="d-block">{this.props.label}</label>
        {this.props.options.map((option) => {
          return (
            <FormCheckBox
              key={this.props.label + option.name}
              name={option.name}
              id={this.props.label + option.name}
              onChange={this.props.onChange}
            />
          );
        })}
      </div>
    );
  }
}

export default FormCheckBoxes;

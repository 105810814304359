import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import FormInlineRadioComponent from '../../molecules/FormInlineRadioComponentForForm';

class HorizontalFormMenuComponent extends React.Component {
  static get propTypes() {
    return {
      menuList: PropTypes.array,
      dataList: PropTypes.array,
      name: PropTypes.string,
      options: PropTypes.array,
      validate: PropTypes.function,
      onChange: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    console.debug('HorizontalFormMenu field', field);
    return (
      <div>
        {field.menuList != null ? (
          <div>
            {field.menuList.map((item, i) => {
              return (
                <div key={i}>
                  <h5>{item.name}</h5>
                  {item.menus.map((menu, index) => {
                    return (
                      <FormInlineRadioComponent
                        key={index}
                        label={menu.menuName}
                        options={field.options}
                        name={`RolePermissionId-${menu.menuId}`}
                        validate={field.optionalValidate}
                        onChange={
                          this.props.onChange
                            ? this.props.onChange.bind(this, menu.menuId)
                            : undefined
                        }
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.component}
        menuList={this.props.menuList}
        optionalValidate={this.props.validate}
        options={this.props.options}
      />
    );
  }
}

export default HorizontalFormMenuComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';

import ColAlignLeft from '../atoms/ColAlignLeft';
import FormDatePicker from '../atoms/FormDatePickerForForm';
import FormHour from '../atoms/FormHour';
import FormLabel from '../atoms/FormLabel';
import FormMinute from '../atoms/FormMinute';

class FormDateInputComopnent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      dateName: PropTypes.string,
      hourName: PropTypes.string,
      minuteName: PropTypes.string,
      onChange: PropTypes.func,
      startDate: PropTypes.any,
      startHour: PropTypes.number,
      startMinute: PropTypes.number,
      endDate: PropTypes.string,
      endHour: PropTypes.number,
      endMinute: PropTypes.number,
      defaultValue: PropTypes.any,
      onChangeDate: PropTypes.func,
      onChangeHour: PropTypes.func,
      onChangeStartDateTime: PropTypes.func,
      onChangeDateTime: PropTypes.func,
      name: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);
    this.toDate = this.toDate.bind(this);

    this.component = this.component.bind(this);
    this.render = this.render.bind(this);

    let startDate;
    if (this.props.startDate) {
      startDate = this.props.startDate;
    } else {
      startDate = '';
    }

    this.state = {
      value: { date: startDate, hour: '', minute: '' },
    };
  }

  toDate(str) {
    return new Date(str);
  }

  onChangeDate(field, date) {
    console.debug('date picker', date);
    const timeHourMinute = {
      ...this.state.value,
      date: date,
    };

    this.setState({ value: timeHourMinute });

    console.debug('field', field);
    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange(timeHourMinute);
    }

    if (this.props.onChangeDate) {
      this.props.onChangeDate(timeHourMinute);
    }
  }

  onChangeHour(field, hourTagEvent) {
    const value = hourTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.value,
      hour: value,
    };

    this.setState({ value: timeHourMinute });

    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange(timeHourMinute);
    }

    if (this.props.onChangeHour) {
      this.props.onChangeHour(timeHourMinute);
    }
  }

  onChangeMinute(field, minuteTagEvent) {
    const value = minuteTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.value,
      minute: value,
    };

    this.setState({ value: timeHourMinute });

    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange(timeHourMinute);
    }

    if (this.props.onChangeStartDateTime) {
      this.props.onChangeDateTime(timeHourMinute);
    }
  }

  component(field) {
    return (
      <div className="form-group row">
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </FormLabel>
        <div className="col-sm-6">
          <Row>
            <Col>
              <FormDatePicker
                highlightWeekend
                day={
                  field.input.value != null
                    ? field.input.value.date
                    : this.state.value.date
                }
                setDay={
                  field.input.value != null
                    ? field.input.value.date
                    : this.state.value.date
                }
                format="YYYY/MM/DD"
                locale="ja"
                onChange={this.onChangeDate.bind(this, field)}
              />
            </Col>
            <ColAlignLeft>
              <FormHour
                defaultValue={this.props.defaultValue}
                value={
                  field.input.value != null
                    ? field.input.value.hour
                    : this.props.startHour
                }
                onChange={this.onChangeHour.bind(this, field)}
              />
            </ColAlignLeft>
            <ColAlignLeft>
              <FormMinute
                defaultValue={this.props.defaultValue}
                value={
                  field.input.value != null
                    ? field.input.value.minute
                    : this.props.startMinute
                }
                onChange={this.onChangeMinute.bind(this, field)}
              />
            </ColAlignLeft>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    return <Field component={this.component} name={this.props.name} />;
  }
}

export default FormDateInputComopnent;

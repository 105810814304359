const initialState = {
  searchResult: [],
  searchParams: {},
  referResult: null,
  parts: [],
  partsForThanksPages: [],
  messageTemplates: [],
  isDeleteConfirm: false,
  deleteResult:false,
  test: {},
  isLoading: true,
  error: null,
};

const FormManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'forms/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'forms/HIDE_LOADING': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'forms/FETCH_FORMS': {
      const searchResults = action.payload.result;
      return { ...state,
        searchResult: searchResults,
        searchParams: action.payload.params,
        updateSuccess: false,
        error: null,
        test: {},
      };
    }
    case 'form/FETCH_INSERT_FORM': {
      return { ...state,
        addSuccess: true,
        error: null,
      };
    }
    case 'form/FETCH_UPDATE_FORM': {
      return {
        ...state,
        updateSuccess: true,
        error: null,
      };
    }
    case 'FETCH_PARTS': {
      return { ...state,
        parts: action.payload.result
      };
    }
    case 'FETCH_PARTS_FOR_THANKS_PAGE': {
      return { ...state,
        partsForThanksPages: action.payload.result
      };
    }
    case 'FETCH_MESSAGE_TEMPLATES': {
      return { ...state,
        messageTemplates: action.payload.result
      };
    }
    case 'form/SET_TEMPLATE': {
      return { ...state,
        test:action.payload.template,
      };
    }
    case 'forms/FETCH_FORM_BY_ID': {
      return { ...state,
        referResult: action.payload.result,
      };
    }
    case 'forms/RESET_RESULT': {
      return { ...state,
        referResult: null,
        isDeleteConfirm: false,
        deleteResult: false,
        error: null,
      };
    }
    case 'forms/SHOW_DELETE_CONFIRM_MODAL': {
      return { ...state,
        isDeleteConfirm: true,
      };
    }
    case 'forms/DELETE_FORM': {
      return { ...state,
        isDeleteConfirm: false,
        deleteResult: true,
      };
    }
    case 'FETCH_ERROR': {
      return { ...state,
        error: action.payload.result,
      };
    }
    default:
      return state;
  }
};

export { FormManagementReducer as default };

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import { clearSearchUserResult } from '../../../actions/ReservationManagement';
import ModalBody from '../../atoms/ModalBody';
import NormalButton from '../../atoms/NormalButton';
import FormInlineRadioComponent from '../../molecules/FormInlineRadioComponentForForm';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';
import SearchResultTableNoSort from '../../organisms/SearchResultTableNoSort';

import * as Validator from './../../utils/Validate';

export const IdType = {
  Contact: '1',
  Lead: '2',
  DB: '3',
};

class SearchUserModal extends React.Component {
  static get propTypes() {
    return {
      options: PropTypes.shape({
        areas: PropTypes.array,
        halls: PropTypes.array,
        categories: PropTypes.array,
        tags: PropTypes.array,
      }),
      state: PropTypes.shape({
        reservationManagementUsers: PropTypes.shape({
          users: PropTypes.array,
          isShowModal: PropTypes.bool,
        }),
      }),
      formValue: PropTypes.shape({
        searchCode: PropTypes.string,
      }),
      companies: PropTypes.array,
      show: PropTypes.bool,
      onSubmit: PropTypes.func,
      handleSubmit: PropTypes.func,
      onClickSelectButton: PropTypes.func,
      searchResult: PropTypes.array,
      onHide: PropTypes.func,
      initSearchResult: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.getSearchUsers = this.getSearchUsers.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = { searchCode: null };
  }

  componentDidMount() {
    this.props.initSearchResult();
  }

  onSubmit(values) {
    console.debug('values', values);
    this.setState({ searchCode: values.searchCode });

    this.props.onSubmit(values);
  }

  getSearchUsers() {
    const users = this.props.state.reservationManagementUsers.users;
    const onClickSelectButton = (id, name) => {
      console.debug('onClickSelectButton', id, name);
      this.props.onClickSelectButton(id, name);
    };

    const items = users.map((user, i) => {
      return (
        <tr key={i}>
          <td>{this.state.searchCode === IdType.Lead ? '-' : user.sfid}</td>
          <td>{user.name}</td>
          <td>{user.lineNickName}</td>
          <td>{this.state.searchCode === IdType.Lead ? '-' : user.company}</td>
          <td>{user.pref}</td>
          <td>
            <NormalButton
              color="success"
              onClick={onClickSelectButton.bind(this, user.id, user.name)}
              isEnabled={() => {
                return (
                  user.id != null && user.id !== '' && user.deactivateAt == null
                );
              }}
            >
              選択
            </NormalButton>
          </td>
        </tr>
      );
    });

    return items;
  }

  render() {
    const handleClose = () => {
      this.props.onHide();
    };

    return (
      <Modal size="xl" show={this.props.show} onHide={handleClose}>
        <Modal.Header closeButton>会員の検索</Modal.Header>
        <ModalBody>
          <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <FormInlineRadioComponent
              label="検索対象"
              name="searchCode"
              options={[
                { value: IdType.Contact, name: 'B.人(取責)' },
                { value: IdType.Lead, name: 'リード' },
                { value: IdType.DB, name: '会員DB' },
              ]}
              validate={[Validator.required]}
            />
            {this.props.formValue.searchCode === IdType.Lead ? (
              <></>
            ) : (
              <HorizontalFormInputComponent
                label="SFID"
                name="sfId"
                component="input"
                type="text"
              />
            )}

            <HorizontalFormInputComponent
              label="氏名"
              name="name"
              component="input"
              type="text"
            />
            <HorizontalFormInputComponent
              label="氏名(かな)"
              name="nameKana"
              component="input"
              type="text"
            />
            <HorizontalFormInputComponent
              label="ニックネーム"
              name="lineNickName"
              component="input"
              type="text"
            />
            <HorizontalFormInputComponent
              label="メールアドレス"
              name="mailAddress"
              component="input"
              type="text"
            />
            <HorizontalFormInputComponent
              label="携帯電話"
              name="phoneNumber"
              component="input"
              type="text"
            />
            {this.props.formValue.searchCode === IdType.Lead ? (
              <></>
            ) : (
              <HorizontalSelectComponent
                label="企業名"
                options={this.props.companies}
                name="company"
              />
            )}

            <Row>
              <Col>
                <div className="text-center">
                  <SearchButton />
                </div>
              </Col>
            </Row>
          </Form>
          {this.props.state.reservationManagementUsers.users.length > 0 ? (
            <Row>
              <SearchResultTableNoSort
                headerItems={[
                  { name: 'SFID', value: 'SFID' },
                  { name: 'name', value: '氏名' },
                  { name: 'nickName', value: 'ニックネーム' },
                  { name: 'responsibleBase', value: '担当拠点' },
                  { name: 'area', value: 'エリア' },
                  {},
                ]}
                getSearchItem={this.getSearchUsers}
                search={this.searchEvents}
              />
            </Row>
          ) : (
            <></>
          )}
        </ModalBody>
      </Modal>
    );
  }
}
const searchUserModal = reduxForm({
  // a unique name for the form
  form: 'searchUserModalForm',
  enableReinitialize: true,
})(SearchUserModal);

export default connect(
  (state) => {
    const selector = formValueSelector('searchUserModalForm');

    let searchCode = '';

    if (selector && selector(state, 'searchCode')) {
      searchCode = selector(state, 'searchCode');
    }

    return {
      formValue: {
        searchCode: searchCode,
      },
      state: {
        reservationManagementUsers: {
          users:
            state.reservationManagement.reservationManagementUsers.users
              .result || [],
        },
      },
    };
  },
  (dispatch) => {
    return {
      initSearchResult() {
        dispatch(clearSearchUserResult());
      },
    };
  }
)(searchUserModal);

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Card from '../../organisms/Card';
import ConfirmForm from '../../organisms/company/EditConfirmForm';
import Form from '../../organisms/company/EditForm';
import RegisterCompleteModal from '../../organisms/RegisterCompleteModal';

import routes from './routePath';

class CompanyEdit extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        companySearch: PropTypes.shape({
          searchValues: PropTypes.shape({
            values: PropTypes.object,
            searchParams: PropTypes.object,
          }),
        }),
        companyEdit: PropTypes.shape({
          isConfirm: PropTypes.bool,
          isComplete: PropTypes.bool,
          isError: PropTypes.bool,
          errorMessages: PropTypes.object,
        }),
      }),
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      initCompanyEdit: PropTypes.func,
      initState: PropTypes.func,
      updateCompany: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      backToInput: PropTypes.func,
      setEditValues: PropTypes.func,
      search: PropTypes.func,
      initReferState: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
    this.transitionToRefer = this.transitionToRefer.bind(this);
    this.backToInput = this.backToInput.bind(this);
    this.submit = this.submit.bind(this);
    this.transitionToBack = this.transitionToBack.bind(this);
    this.transitionToSearch = this.transitionToSearch.bind(this);
    this.isNew = this.isNew.bind(this);
    this.onCloseCompleteModal = this.onCloseCompleteModal.bind(this);

    this.state = { values: {} };
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.id = params.id;

    this.props.initCompanyEdit(this.id);
  }

  isNew() {
    console.debug('CompanyEdit isNew: ', !this.id);
    return !this.id;
  }

  confirm(values) {
    console.debug('values', values);

    this.setState({ values: values });

    this.props.setEditValues(values);
  }

  submit() {
    console.debug('submit values: ', this.state.values);

    this.props.updateCompany({
      ...this.state.values,
      id: this.id,
    });
  }

  onCloseCompleteModal() {
    const {
      values,
      searchParams,
    } = this.props.state.companySearch.searchValues;
    if (
      (values && Object.keys(values).length > 0) ||
      (searchParams && Object.keys(searchParams).length > 0)
    ) {
      this.props.search(values, searchParams);
    }

    this.transitionToBack();
  }

  transitionToRefer(id) {
    this.props.history.push(routes.refer(id));
  }

  transitionToSearch() {
    this.props.history.push(routes.search);
  }

  transitionToBack() {
    const id = this.id;

    if (id) {
      this.transitionToRefer(id);
    } else {
      this.transitionToSearch();
    }

    // this.props.initReferState();
  }

  backToInput() {
    this.props.backToInput();
  }

  render() {
    const isConfirm = () => {
      return this.props.state.companyEdit.isConfirm;
    };

    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>企業名管理</h1>
          </div>

          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="編集">
                  {isConfirm() ? (
                    <ConfirmForm
                      onSubmit={this.submit}
                      backToInput={this.backToInput}
                      values={this.state.values}
                      isNew={this.isNew()}
                    />
                  ) : (
                    <Form
                      onSubmit={this.confirm}
                      onPrevButtonClick={this.transitionToBack}
                      isNew={this.isNew()}
                      isError={this.props.state.companyEdit.isError}
                      messages={this.props.state.companyEdit.errorMessages}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <RegisterCompleteModal
          show={this.props.state.companyEdit.isComplete}
          onCloseClick={this.onCloseCompleteModal}
        />
      </>
    );
  }
}

export default CompanyEdit;

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import {
  makeFetchAreas,
  makeFetchHallsByAreaId,
  makeFetchCategories,
  makeFetchDisplayAreas,
  makeFetchTags,
  makefetchStaff,
  makeFetchBases,
  makeFetchEventTypes,
  selectedChairman,
  initState,
  makeFetchStaffForSuggestion,
  clearSuggestion,
  clearHallsOptions,
  makeFetchSFCompanyNames,
  makeFetchEventMethodTypes,
} from '../../../actions/Events';
import Necessities, { NecessitiesType } from '../../../domain/Necessities';
import FormGroupRow from '../../atoms/FormGroupRow';
import FormLabel from '../../atoms/FormLabel';
import EventTypes from '../../model/event/EventTypes';
import Option, { OptionCollection } from '../../model/Option';
import CancelButton from '../../molecules/CancelButton';
import ConfirmButton from '../../molecules/ConfirmButton';
import FormDateInputComponent from '../../molecules/FormDateInputComponentForm';
import FormInlineCheckBoxesComponent from '../../molecules/FormInlineCheckBoxesComponent';
import FormInlineRadioComponent from '../../molecules/FormInlineRadioComponentForForm';
import FormNumberOfPeopleComponent from '../../molecules/FormNumberOfPeopleComponent';
import FormPeriodDateTimeInputComponent from '../../molecules/FormPeriodDateTimeInputComponentForForm';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import FormTextAreaComponent from '../../molecules/HorizontalFormTextareaComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import MarginFormContainer from '../../molecules/MarginFormComponent';
import { AllowOwner } from '../../utils/PermissionComponent';
import { scrollToFirstError } from '../../utils/ScrollError';
import * as Validator from '../../utils/Validate';
import StaffSearchModal from '../StaffSearchModal';

import DisplayAreaCheckbox from './DisplayAreaCheckboxComponent';
import FormInlineRadioAndOtherComponent from './FormInlineRadioAndOtherComponent';
import FormReceptionStartTimeComponent from './FormReceptionStartTimeComponentForForm';
import StaffSearchButton from './StaffSearchButton';

const selector = formValueSelector('eventsEditForm');

const validateURL = (value) => {
  return Validator.maxLength(256, value);
};

const receptionStartTimeFormat = (value) => {
  if (value) {
    if (parseInt(value, 10) % 15 === 0) {
      return undefined;
    } else {
      return '15分単位で入力してください';
    }
  } else {
    return undefined;
  }
};

class EventsEditFormState {
  constructor(isStaffSearchModal = false, staff = null, chairmanId = '') {
    /**
     *
     * @type {boolean} isStaffSearchModal
     */
    this.isStaffSearchModal = isStaffSearchModal;

    /**
     *
     * @type {Object} staff
     */
    this.staff = staff;

    /**
     *
     * @type {string} chairmanId
     */
    this.chairmanId = chairmanId;
  }

  static fromState(state) {
    return new EventsEditFormState(
      state.isStaffSearchModal,
      state.staff,
      state.chairmanId
    );
  }
}

class EventsEditForm extends React.Component {
  static get propTypes() {
    return {
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        eventsEdit: PropTypes.shape({
          staff: PropTypes.array,
        }),
      }),
      options: PropTypes.shape({
        areas: PropTypes.array,
        halls: PropTypes.array,
        categories: PropTypes.array,
        tags: PropTypes.array,
        bases: PropTypes.array,
        eventTypes: PropTypes.array,
        eventMethodTypes: PropTypes.array,
        sfCompanyNames: PropTypes.array,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      handleSubmit: PropTypes.func,
      fetchAreas: PropTypes.func,
      fetchHalls: PropTypes.func,
      fetchTags: PropTypes.func,
      fetchCategories: PropTypes.func,
      staffSearchModalShow: PropTypes.func,
      staffSearchModalHide: PropTypes.func,
      fetchStaff: PropTypes.func,
      fetchBases: PropTypes.func,
      eventsEdit: PropTypes.shape({
        staff: PropTypes.string,
        displayAreas: PropTypes.array,
        errorMessages: PropTypes.shape({
          attireDescription: PropTypes.array,
          necessitiesDescription: PropTypes.array,
        }),
      }),
      fetchDisplayAreas: PropTypes.func,
      formState: PropTypes.shape({
        values: PropTypes.shape({
          paidFlag: PropTypes.string,
          chairman: PropTypes.shape({
            name: PropTypes.string,
          }),
          venueId: PropTypes.string,
        }),
      }),
      selectedChairman: PropTypes.func,
      initState: PropTypes.func,
      onPrevButtonClick: PropTypes.func,
      fetchCategory: PropTypes.func,
      setChairman: PropTypes.func,
      chairmanId: PropTypes.string,
      chairmanName: PropTypes.string,
      fetchHallsByAreaId: PropTypes.func,
      fetchEventTypes: PropTypes.func,
      fetchSFCompanyNames: PropTypes.func,
      isNew: PropTypes.bool,
      eventsRefer: PropTypes.shape({
        detail: PropTypes.shape({
          createdBy: PropTypes.string,
        }),
      }),
      fetchStaffForSuggestion: PropTypes.func,
      clearSuggestion: PropTypes.func,
      clearHallsOptions: PropTypes.func,
      fetchEventMethodTypes: PropTypes.func,
      needUrl: PropTypes.bool,
      needVenue: PropTypes.bool,
      isDisplayCategory: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = new EventsEditFormState();

    this.onJaicStaffModalHide = this.onJaicStaffModalHide.bind(this);
    this.moneyInputValidate = this.moneyInputValidate.bind(this);
  }

  componentDidMount() {
    this.props.fetchAreas();
    this.props.fetchCategories();
    this.props.fetchTags();
    this.props.fetchStaff();
    this.props.fetchBases();
    this.props.fetchEventTypes();
    this.props.fetchEventMethodTypes();
    this.props.fetchDisplayAreas();
    this.props.fetchSFCompanyNames();
  }

  onClick(e) {
    console.log(e.target.value);
    if (e.target.value && e.target.value.length > 0) {
      this.props.fetchHallsByAreaId(e.target.value);
    } else {
      this.props.clearHallsOptions();
    }
  }

  onChangeKind(e) {
    console.log(e.target.value);
    if (e.target.value === 2) {
      this.props.fetchCategory();
    }
  }

  onJaicStaffModalShow() {
    const state = EventsEditFormState.fromState(this.state);
    state.isStaffSearchModal = true;
    this.setState(state);
  }

  onJaicStaffModalHide(e) {
    const state = EventsEditFormState.fromState(this.state);
    state.isStaffSearchModal = false;
    this.setState(state);
  }

  onClickSelectSearchStaff(e) {
    this.props.setChairman(e.target.value);
  }

  moneyInputValidate(value) {
    if (!this.props || !this.props.formState) {
      return undefined;
    }

    console.debug('moneyInputValidate', this.props.formState.values.paidFlag);
    if (this.props.formState.values.paidFlag === '1' && !value) {
      return '有料の場合は金額を入力してください';
    }
    return undefined;
  }

  render() {
    const chairmanNameComponent = () => {
      if (
        this.props.formState &&
        this.props.formState.values &&
        this.props.formState.values.chairman
      ) {
        return (
          <label className="col-sm-3">
            {this.props.formState.values.chairman.name}
          </label>
        );
      }

      return <></>;
    };

    const onSelectedChairman = (selectedStaffId) => {
      this.props.selectedChairman(selectedStaffId);

      this.onJaicStaffModalHide();
    };

    const isDisplayDefaultMessage = () => {
      if (this.props.formState && this.props.formState.values.venueId) {
        return false;
      } else {
        return true;
      }
    };

    const getOptionMethodTypes = () => {
      return new OptionCollection(
        this.props.options.eventMethodTypes.map((option) => {
          return new Option(option.id, option.name);
        })
      ).getOptionsWithoutEmpty();
    };

    const isOnline = () => {
      return this.props.needUrl;
    };

    return (
      <MarginFormContainer>
        <Form onSubmit={this.props.handleSubmit}>
          <div>
            <FormInlineRadioComponent
              label="種別"
              options={this.props.options.eventTypes}
              name="kindId"
              validate={[Validator.required]}
            />
            <FormInlineRadioComponent
              label="開催方法"
              options={getOptionMethodTypes()}
              name="methodTypeId"
              validate={[Validator.required]}
            />
            {this.props.needUrl && (
              <HorizontalFormInputComponent
                label="ウェブイベント用URL"
                name="webEventUrl"
                component="input"
                type="text"
                validate={[validateURL]}
              />
            )}
            <HorizontalFormInputComponent
              label="イベント名"
              name="name"
              component="input"
              type="text"
              validate={[Validator.required]}
            />
            {this.props.needVenue ? (
              <>
                <HorizontalSelectComponent
                  label="エリア"
                  name="areaId"
                  options={this.props.options.areas}
                  onChange={this.onClick}
                />
                <HorizontalSelectComponent
                  label="会場"
                  name="venueId"
                  options={this.props.options.halls}
                />
                {isDisplayDefaultMessage() ? (
                  <HorizontalFormInputComponent
                    label="会場未選択時表示文言"
                    name="defaultMessage"
                    component="input"
                    type="text"
                    validate={[Validator.required]}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <FormPeriodDateTimeInputComponent
              startLabel="開催開始日時"
              endLabel="開催終了日時"
              name="startDateTime"
              isRequired={true}
            />
            <FormReceptionStartTimeComponent
              label="受付開始日時"
              name="startAcceptingTime"
              validate={[receptionStartTimeFormat]}
            />
            <FormDateInputComponent label="公開開始日時" name="openStartDate" />
            <FormDateInputComponent label="公開終了日時" name="openCloseDate" />
            <FormNumberOfPeopleComponent label="定員" name="seatAvailability" />
            <FormInlineRadioComponent
              label="空席状況の表示"
              name="seatDisplayFlag"
              options={[
                { name: '表示する', value: '1', default: true },
                { name: '表示しない', value: '0' },
              ]}
            />
            <FormInlineRadioAndOtherComponent
              label="服装"
              name="attire"
              options={[{ name: 'デフォルト(自由)', value: '1' }]}
              otherName="attireDescription"
              otherLabel="その他"
              isRequired={true}
              errorMessages={
                this.props.eventsEdit.errorMessages &&
                this.props.eventsEdit.errorMessages.attireDescription
                  ? this.props.eventsEdit.errorMessages.attireDescription
                  : []
              }
            />
            <FormInlineRadioAndOtherComponent
              label="持ち物"
              name="necessities"
              options={[
                {
                  name: new Necessities(
                    new NecessitiesType.Default(),
                    isOnline()
                  ).displayName,
                  value: '1',
                },
              ]}
              otherName="necessitiesDescription"
              otherLabel="その他"
              isRequired={true}
              errorMessages={
                this.props.eventsEdit.errorMessages &&
                this.props.eventsEdit.errorMessages.necessitiesDescription
                  ? this.props.eventsEdit.errorMessages.necessitiesDescription
                  : []
              }
            />
            <FormGroupRow>
              <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
                講師&nbsp;
              </FormLabel>
              <Col sm={6}>
                <Field component={chairmanNameComponent} name="chairman" />
                <StaffSearchButton
                  onClick={this.onJaicStaffModalShow.bind(this)}
                />
              </Col>
            </FormGroupRow>
            {this.props.isDisplayCategory ? (
              <HorizontalSelectComponent
                label="カテゴリー"
                name="categoryId"
                options={this.props.options.categories}
              />
            ) : (
              <></>
            )}
            <FormInlineCheckBoxesComponent
              label="タグ"
              name="tagId"
              options={this.props.options.tags}
              description="専門のセミナー・ONEDAY・説明会日程（中退専門など）でない場合は、設定する必要はありません。"
            />

            <FormTextAreaComponent
              label="内容"
              name="content"
              component="input"
              type="textarea"
              validate={[Validator.required]}
            />
            <HorizontalSelectComponent
              label="担当拠点"
              name="chargeBaseId"
              options={this.props.options.bases}
              validate={[Validator.required]}
            />

            <HorizontalSelectComponent
              label="企業名"
              name="companyName"
              options={this.props.options.sfCompanyNames}
              validate={[Validator.required]}
            />

            <DisplayAreaCheckbox
              label="表示エリア"
              name="displayAreas"
              options={this.props.eventsEdit.displayAreas}
            />
          </div>
          <Row>
            <Col>
              <div className="text-center">
                <CancelButton onClick={this.props.onPrevButtonClick} />
                <AllowOwner
                  dataOwnerId={this.props.eventsRefer.detail.createdBy}
                >
                  <ConfirmButton isNew={this.props.isNew} />
                </AllowOwner>
              </div>
            </Col>
          </Row>
        </Form>
        <StaffSearchModal
          show={EventsEditFormState.fromState(this.state).isStaffSearchModal}
          onCloseButtonClick={this.onJaicStaffModalHide}
          onSelected={onSelectedChairman}
          fetchStaff={this.props.fetchStaffForSuggestion}
          clearSuggestion={this.props.clearSuggestion}
          suggestions={this.props.eventsEdit.staff}
        />
      </MarginFormContainer>
    );
  }
}

const eventsEditFrom = reduxForm({
  // a unique name for the form
  form: 'eventsEditForm',
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(EventsEditForm);

export default connect(
  (state, props) => {
    console.debug('eventsEdit.name', state.events.eventsRefer.detail);
    console.debug('eventsEdit.kindId', state.events.eventsRefer.detail.kindId);
    const eventsRefer = state.events.eventsRefer.detail;

    const dateYMDFrom = eventsRefer.dateYMDFrom;
    let startDateYMD;
    if (dateYMDFrom) {
      startDateYMD = moment(dateYMDFrom, 'YYYY/MM/DD').toDate();
    }

    const dateYMDTo = eventsRefer.dateYMDTo;
    let endDateYMD;
    if (dateYMDTo) {
      endDateYMD = moment(dateYMDTo, 'YYYY/MM/DD').toDate();
    }

    const openDateYMD = eventsRefer.openDateYMD;
    let openDate;
    if (openDateYMD) {
      openDate = moment(openDateYMD, 'YYYY/MM/DD').toDate();
    }

    const closeDateYMD = eventsRefer.closeDateYMD;
    let closeDate;
    if (closeDateYMD) {
      closeDate = moment(closeDateYMD, 'YYYY/MM/DD').toDate();
    }

    let displaySeatFlag;
    if (
      eventsRefer.isDisplaySeat === true ||
      eventsRefer.isDisplaySeat == null
    ) {
      displaySeatFlag = '1';
    } else {
      displaySeatFlag = '0';
    }

    let paidFlag;
    if (eventsRefer.isPaid) {
      paidFlag = '1';
    } else {
      paidFlag = '0';
    }

    let isAttire;

    if (eventsRefer.attire === true || eventsRefer.attire == null) {
      isAttire = '1';
    } else {
      isAttire = '0';
    }

    let isNecessities;

    if (eventsRefer.necessities === true || eventsRefer.necessities == null) {
      isNecessities = '1';
    } else {
      isNecessities = '0';
    }

    const methodTypeRes = state.options.eventMethodTypes.find(
      (methodType) => methodType.id === selector(state, 'methodTypeId')
    );
    const needVenue = methodTypeRes != null && methodTypeRes.needVenue;
    const needUrl = methodTypeRes != null && methodTypeRes.needUrl;

    console.debug('eventsEdit', state.events.eventsEdit);

    let chairman;
    if (eventsRefer.staffId && eventsRefer.chairmanName) {
      chairman = {
        value: eventsRefer.staffId,
        name: eventsRefer.chairmanName,
      };
    }

    const kindId = selector(state, 'kindId');

    const eventTypes = new EventTypes(state.options.eventTypes);
    const isDisplayCategory = eventTypes.isSeminar(kindId);

    console.debug(
      'isDefaultSetting = ',
      state.events.eventsEdit.isDefaultSettingBase,
      state.events.eventsEdit.profile
    );

    const chargeBaseId =
      eventsRefer.chargeBaseId ||
      (state.events.eventsEdit.isDefaultSettingBase
        ? state.events.eventsEdit.profile.baseId
        : null);
    console.debug('chargeBaseId', chargeBaseId);

    const companyName =
      eventsRefer.companyName ||
      (state.events.eventsEdit.isDefaultSettingBase
        ? state.events.eventsEdit.profile.companyName
        : null);
    console.debug('companyName', companyName);

    return {
      formState: state.form.eventsEditForm,
      options: state.options,
      eventsEdit: state.events.eventsEdit,
      eventsRefer: state.events.eventsRefer,
      initialValues: {
        name: eventsRefer.name || '',
        kindId: eventsRefer.kindId,
        methodTypeId: eventsRefer.methodTypeId,
        webEventUrl: eventsRefer.webEventUrl,
        areaId: eventsRefer.areaId,
        venueId: eventsRefer.venueId,
        defaultMessage: eventsRefer.defaultMessage,
        startDateTime: {
          from: {
            date: startDateYMD,
            hour:
              eventsRefer.dateHHFrom != null
                ? String(eventsRefer.dateHHFrom)
                : '',
            minute:
              eventsRefer.dateMMFrom != null
                ? String(eventsRefer.dateMMFrom)
                : '',
          },
          to: {
            date: endDateYMD,
            hour:
              eventsRefer.dateHHTo != null ? String(eventsRefer.dateHHTo) : '',
            minute:
              eventsRefer.dateMMTo != null ? String(eventsRefer.dateMMTo) : '',
          },
        },
        startAcceptingTime: eventsRefer.startAcceptingTime || 15,
        openStartDate: {
          date: openDate,
          hour:
            eventsRefer.openDateHH != null
              ? String(eventsRefer.openDateHH)
              : '',
          minute:
            eventsRefer.openDateMM != null
              ? String(eventsRefer.openDateMM)
              : '',
        },
        openCloseDate: {
          date: closeDate,
          hour:
            eventsRefer.closeDateHH != null
              ? String(eventsRefer.closeDateHH)
              : '',
          minute:
            eventsRefer.closeDateMM != null
              ? String(eventsRefer.closeDateMM)
              : '',
        },
        seatAvailability: eventsRefer.seatAvailability,
        seatDisplayFlag: displaySeatFlag,
        paidFlag: paidFlag,
        price: eventsRefer.price,
        attire: {
          value: isAttire,
          otherText: eventsRefer.attireDescription,
        },
        necessities: {
          value: isNecessities,
          otherText: eventsRefer.necessitiesDescription,
        },
        categoryId: eventsRefer.categoryId,
        tagId: eventsRefer.tagIds ? eventsRefer.tagIds : [],
        content: eventsRefer.content,
        chargeBaseId: chargeBaseId,
        companyName: companyName,
        displayAreas: eventsRefer.displayAreas || [],
        chairman,
      },
      needVenue: needVenue,
      needUrl: needUrl,
      isDisplayCategory: isDisplayCategory,
    };
  },
  (dispatch) => {
    return {
      fetchAreas() {
        dispatch(makeFetchAreas());
      },
      fetchHallsByAreaId(v) {
        dispatch(makeFetchHallsByAreaId(v));
      },
      fetchCategories() {
        dispatch(makeFetchCategories());
      },
      fetchTags() {
        dispatch(makeFetchTags());
      },
      fetchStaff() {
        dispatch(makefetchStaff());
      },
      fetchBases() {
        dispatch(makeFetchBases());
      },
      fetchDisplayAreas() {
        dispatch(makeFetchDisplayAreas());
      },
      fetchEventTypes() {
        dispatch(makeFetchEventTypes());
      },
      fetchEventMethodTypes() {
        dispatch(makeFetchEventMethodTypes());
      },
      selectedChairman(id) {
        dispatch(selectedChairman(id));
      },
      initState() {
        dispatch(initState());
      },
      fetchStaffForSuggestion(value) {
        dispatch(makeFetchStaffForSuggestion(value));
      },
      clearSuggestion() {
        dispatch(clearSuggestion());
      },

      clearHallsOptions() {
        dispatch(clearHallsOptions());
      },
      fetchSFCompanyNames() {
        dispatch(makeFetchSFCompanyNames());
      },
    };
  }
)(eventsEditFrom);

import axios from 'axios';

const initState = () => {
  return {
    type: 'admin/password_reset/INIT_STATE',
  };
};

const adminPasswordResetSendError = (error) => {
  return {
    type: 'admin/password_reset/SEND_ERROR',
    payload: { result: error },
  };
};

const sendAdminPasswordResetMailLoading = () => {
  return {
    type: 'admin/password_reset/SEND_ADMIN_PASSWORD_RESET_MAIL_LOADING',
  };
};

const fetchAdminPasswordResetSendMail = () => {
  return {
    type: 'admin/password_reset/FETCH_ADMIN_PASSWORD_RESET_SEND_MAIL',
  };
};

const postAdminPasswordResetSendMail = (values) => {
  const postJson = { email: values.email };
  return axios.post(`/admin/password_reset/send_mail`, postJson, {
    headers: {
      'X-Referer': '/admin/password_reset/send_mail',
      'Content-Type': 'multipart/form-data',
    },
  });
};

const makeSendAdminPasswordResetMail = (values) => {
  return (dispatch) => {
    dispatch(sendAdminPasswordResetMailLoading());
    postAdminPasswordResetSendMail(values)
      .then((data) => dispatch(fetchAdminPasswordResetSendMail(data.data)))
      .catch((err) => dispatch(adminPasswordResetSendError(err)));
  };
};

const adminpasswordResetError = (error) => {
  if (error.response) {
    if (error.response.status === 500) {
      return {
        type: 'admin/password_reset/INTERNAL_SERVER_ERROR',
        payload: error.response.data,
      };
    } else if (error.response.status === 400) {
      return {
        type: 'admin/password_reset/EXPIRED_ERROR',
        payload: error.response.data,
      };
    }
  }
};

const adminPasswordResetLoading = () => {
  return {
    type: 'admin/password_reset/ADMIN_PASSWORD_RESET_LOADING',
  };
};

const fetchAdminPasswordReset = () => {
  return {
    type: 'admin/password_reset/FETCH_ADMIN_PASSWORD_RESET',
  };
};

const postAdminPasswordReset = (values) => {
  const postJson = {
    newPassword: values.newPassword,
    newPasswordConfirm: values.newPasswordConfirm,
    id: values.id,
  };
  return axios.post(`/admin/password_reset/password`, postJson, {
    headers: {
      'X-Referer': '/admin/password_reset/password',
      'Content-Type': 'multipart/form-data',
    },
  });
};
const checkWrongPassword = (values) => {
  if (values.newPassword !== values.newPasswordConfirm) {
    return {
      type: 'admin/password_reset/PASSWORD_WRONG_ERROR',
    };
  }
  return null;
};
const makeSendAdminPasswordReset = (values) => {
  return (dispatch) => {
    dispatch(adminPasswordResetLoading());
    const type = checkWrongPassword(values);
    if (type) {
      dispatch(type);
      return;
    }
    postAdminPasswordReset(values)
      .then((data) => dispatch(fetchAdminPasswordReset(data.data)))
      .catch((err) => dispatch(adminpasswordResetError(err)));
  };
};

export {
  makeSendAdminPasswordResetMail,
  makeSendAdminPasswordReset,
  initState,
};

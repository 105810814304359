export const ErrorMessages = {
  required: '必須項目です',
  email: '正しいメールアドレスの形式でご入力ください',
  password: '英字、数字を組み合わせた8文字以上16文字以内で入力',
  url: 'URLの形式が間違っています。 例：https://example.com',
  number: '数値を入力してください',
  maxLength: (value) => {
    return `${value}文字以内でご入力ください`;
  },
  numberBetweenOneAndTen: '1から100までの整数を入力してください',
};

const Regex = {
  email: /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  password: /^(?=.*?[a-zA-Z])(?=.*?\d)[!-\\~]{8,16}$/i,
  url: /^(https?)(:\/\/[-_.!~*\\'()a-zA-Z0-9;\\/?:\\@&=+\\$,%#]+)$/,
  number: /^[0-9]+$/,
};

export const required = (value) => (value ? undefined : ErrorMessages.required);

export const email = (value) =>
  value && !Regex.email.test(value) ? ErrorMessages.email : undefined;

export const password = (value) =>
  value && !Regex.password.test(value) ? ErrorMessages.password : undefined;

export const number = (value) =>
  value && !Regex.number.test(value) ? ErrorMessages.number : undefined;

export const url = (value) =>
  value && !Regex.url.test(value) ? ErrorMessages.url : undefined;

export const extendValidator = (text) => (func) => (func ? text : undefined);

export const log = (value) => console.debug('Validate log', value);

export const requiredCustomMessage = (message, value) =>
  value ? undefined : message || ErrorMessages.required;

export const maxLength = (max, value) =>
  value && value.length > max ? ErrorMessages.maxLength(max) : undefined;

export const numberBetweenOneAndTen = (value) =>
  value && (!Regex.number.test(value) || value < 1 || value > 100)
    ? ErrorMessages.numberBetweenOneAndTen
    : undefined;

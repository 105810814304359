import moment from 'moment';
import { trackPromise } from 'react-promise-tracker';
import { destroy } from 'redux-form';

import Action, { EmptyAction } from '../components/model/Action';
import Option, { OptionCollection } from '../components/model/Option';
import SearchParams from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';
import { trackAsync } from './util';

const axios = createAxios('/admin/users');

const showLoading = () => {
  return {
    type: 'SHOW_LOADING',
  };
};

const makeFetchPrefs = () => {
  return (dispatch) => {
    return fetchPrefs()
      .then((data) => dispatch(fetchPrefsSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchPrefs = () => {
  return axios.get(`/prefs`);
};

const fetchPrefsSuccess = (result) => {
  console.log('fetchPrefsSuccess(result)');
  return {
    type: 'FETCH_PREFS',
    payload: {
      result: new OptionCollection(
        result.map((pref) => {
          return new Option(pref.id, pref.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchCompanyNames = () => {
  return (dispatch) => {
    return fetchCompanyNames()
      .then((data) => dispatch(fetchCompanyNamesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchCompanyNames = () => {
  return axios.get(`/options/company_names`);
};

const fetchCompanyNamesSuccess = (result) => {
  console.log('fetchCompanyNamesSuccess(result)');
  return {
    type: 'option/FETCH_COMPANY_NAMES',
    payload: {
      result: new OptionCollection(
        result.map((companyName) => {
          return new Option(companyName.id, companyName.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchCurrentStatuses = () => {
  return (dispatch) => {
    return fetchCurrentStatuses()
      .then((data) => dispatch(fetchCurrentStatusesSuccess(data.data)))
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchCurrentStatuses = () => {
  return axios.get(`/options/current_statuses`);
};

const fetchCurrentStatusesSuccess = (result) => {
  console.log('fetchCompanyNamesSuccess(result)');
  return {
    type: 'option/FETCH_CURRENT_STATUSES',
    payload: {
      result: new OptionCollection(
        result.map((currentStatus) => {
          return new Option(currentStatus.id, currentStatus.name);
        })
      ).getOptions(),
    },
  };
};

const makeFetchCollegeRegistrationCategories = () => {
  return (dispatch) => {
    return fetchCollegeRegistrationCategories()
      .then((data) =>
        dispatch(fetchCollegeRegistrationCategoriesSuccess(data.data))
      )
      .catch((err) => dispatch(fetchErrorData(err)));
  };
};

const fetchCollegeRegistrationCategories = () => {
  return axios.get(`/options/college_registration_categories`);
};

const fetchCollegeRegistrationCategoriesSuccess = (result) => {
  console.log('fetchCollegeRegistrationCategoriesSuccess(result)');
  return {
    type: 'option/COLLEGE_REGISTRATION_CATEGORIES',
    payload: {
      result: new OptionCollection(
        result.map((collegeRegistrationCategory) => {
          return new Option(
            collegeRegistrationCategory.id,
            collegeRegistrationCategory.name
          );
        })
      ).getOptions(),
    },
  };
};

const makeStoreSearchContactValues = (values) => {
  return new Action('admin_users/STORE_SEARCH_CONTACT_PARAMS', values);
};

// 検索(contact)
const makeSearchContact = (values, initFlg, searchParams) => {
  return (dispatch) => {
    dispatch(makeStoreSearchContactValues(searchParams));

    return trackPromise(
      searchContact(values, initFlg, searchParams)
        .then((data) => dispatch(fetchSearchContactSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const searchContact = (values, initFlg, searchParams) => {
  console.debug('values: ', values);

  let sex = '';
  if (values.sex) {
    for (let i = 0; i < values.sex.length; i++) {
      if (i !== 0) {
        sex = sex + ',';
      }
      sex = sex + values.sex[i];
    }
  }

  let age = '';
  if (values.age) {
    for (let i = 0; i < values.age.length; i++) {
      if (i !== 0) {
        age = age + ',';
      }
      age = age + values.age[i];
    }
  }

  let pageSize = 10;
  if (searchParams.pagination) {
    if (searchParams.pagination.pageSize) {
      pageSize = searchParams.pagination.pageSize;
    }
  }
  let page = 1;
  if (searchParams.pagination) {
    if (searchParams.pagination.page) {
      page = searchParams.pagination.page;
    }
  }
  let sortColumn = 'id';
  if (searchParams.sort) {
    if (searchParams.sort.sortColumn) {
      sortColumn = searchParams.sort.sortColumn;
    }
  }
  let sortType = 'asc';
  if (searchParams.sort) {
    if (searchParams.sort.sortColumn) {
      sortType = searchParams.sort.sortType;
    }
  }

  // 流入日期間(From)
  let registerFromDateYmd = '';
  if (values.registerFromDateYmd) {
    const registerFromDateYmdDate = moment(
      values.registerFromDateYmd.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = registerFromDateYmdDate.getFullYear();
    const monthInt = registerFromDateYmdDate.getMonth() + 1;
    const dayInt = registerFromDateYmdDate.getDate();

    registerFromDateYmd =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 流入日期間(To)
  let registerToDateYmd = '';
  if (values.registerToDateYmd) {
    const registerToDateYmdDate = moment(
      values.registerToDateYmd.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = registerToDateYmdDate.getFullYear();
    const monthInt = registerToDateYmdDate.getMonth() + 1;
    const dayInt = registerToDateYmdDate.getDate();

    registerToDateYmd =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 来社日1(FROM)
  let visitFromDateYmd = '';
  if (values.visitFromDateYmd) {
    const visitFromDateYmdDate = moment(
      values.visitFromDateYmd.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = visitFromDateYmdDate.getFullYear();
    const monthInt = visitFromDateYmdDate.getMonth() + 1;
    const dayInt = visitFromDateYmdDate.getDate();

    visitFromDateYmd =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 来社日1(TO)
  let visitToDateYmd = '';
  if (values.visitToDateYmd) {
    const visitToDateYmdDate = moment(
      values.visitToDateYmd.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = visitToDateYmdDate.getFullYear();
    const monthInt = visitToDateYmdDate.getMonth() + 1;
    const dayInt = visitToDateYmdDate.getDate();

    visitToDateYmd =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 来社日2(FROM)
  let visitFromDate2Ymd = '';
  if (values.visitFromDate2Ymd) {
    const visitFromDate2YmdDate = moment(
      values.visitFromDate2Ymd.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = visitFromDate2YmdDate.getFullYear();
    const monthInt = visitFromDate2YmdDate.getMonth() + 1;
    const dayInt = visitFromDate2YmdDate.getDate();

    visitFromDate2Ymd =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 来社日2(TO)
  let visitToDate2Ymd = '';
  if (values.visitToDate2Ymd) {
    const visitToDate2YmdDate = moment(
      values.visitToDate2Ymd.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = visitToDate2YmdDate.getFullYear();
    const monthInt = visitToDate2YmdDate.getMonth() + 1;
    const dayInt = visitToDate2YmdDate.getDate();

    visitToDate2Ymd =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // アプローチ数(FROM)
  let approachCountFrom = '';
  if (values.approachCountFrom) {
    approachCountFrom = String(values.approachCountFrom);
  }

  // アプローチ数(TO)
  let approachCountTo = '';
  if (values.approachCountTo) {
    approachCountTo = String(values.approachCountTo);
  }

  // 通電数(FROM)
  let telephoneCountFrom = '';
  if (values.telephoneCountFrom) {
    telephoneCountFrom = String(values.telephoneCountFrom);
  }

  // 通電数(TO)
  let telephoneCountTo = '';
  if (values.telephoneCountTo) {
    telephoneCountTo = String(values.telephoneCountTo);
  }

  // 接触数(FROM)
  let contactCountFrom = '';
  if (values.contactCountFrom) {
    contactCountFrom = String(values.contactCountFrom);
  }

  // 接触数(TO)
  let contactCountTo = '';
  if (values.contactCountTo) {
    contactCountTo = String(values.contactCountTo);
  }

  // 最終アクション日(FROM)
  let lastActionDateYmdFrom = '';
  if (values.lastActionDateYmdFrom) {
    const lastActionDateYmdFromDate = moment(
      values.lastActionDateYmdFrom.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = lastActionDateYmdFromDate.getFullYear();
    const monthInt = lastActionDateYmdFromDate.getMonth() + 1;
    const dayInt = lastActionDateYmdFromDate.getDate();

    lastActionDateYmdFrom =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 最終アクション日(TO)
  let lastActionDateYmdTo = '';
  if (values.lastActionDateYmdTo) {
    const lastActionDateYmdToDate = moment(
      values.lastActionDateYmdTo.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = lastActionDateYmdToDate.getFullYear();
    const monthInt = lastActionDateYmdToDate.getMonth() + 1;
    const dayInt = lastActionDateYmdToDate.getDate();

    lastActionDateYmdTo =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 招待者数(FROM)
  let inviteCountFrom = '';
  if (values.inviteCountFrom) {
    inviteCountFrom = String(values.inviteCountFrom);
  }

  // 招待者数(TO)
  let inviteCountTo = '';
  if (values.inviteCountTo) {
    inviteCountTo = String(values.inviteCountTo);
  }

  const params = new SearchParams({
    initFlg: initFlg,
    // 検索対象(1:B.人(取責) 2:リード 3: 会員DB)
    searchCode: '1',
    // SFID(B.人)
    sfId: values.sfId,
    // メールアドレス
    mailAddress: values.mailAddress,
    // フリガナ
    nameKana: values.kana,
    // LINEニックネーム
    lineNickName: values.lineNickName,
    // 企業名
    company: values.company,
    // 年齢(1:10代 2:20代 3: 30代)
    age: age,
    // 流入日期間(FROM)
    registerFromDateYmd: registerFromDateYmd,
    // 流入日期間(TO)
    registerToDateYmd: registerToDateYmd,
    // 接触NG (1:除く)
    contactNg: values.contactNg,
    // メールNG (1:除く)
    mailNg: values.mailNg,
    // 来社日1(FROM)
    visitFromDateYmd: visitFromDateYmd,
    // 来社日1(TO)
    visitToDateYmd: visitToDateYmd,
    // 来社日2(FROM)
    visitFromDate2Ymd: visitFromDate2Ymd,
    // 来社日2(TO)
    visitToDate2Ymd: visitToDate2Ymd,
    // 現在の状況
    currentStatus: values.currentStatus,
    // カレッジ登録区分
    collegeRegistrationCategory: values.collegeRegistrationCategory,
    // 性別(1:男性 2:女性)
    sex: sex,
    // 都道府県
    pref: values.pref,
    // 携帯番号
    phoneNumber: values.phoneNumber,
    // アプローチ数(FROM)
    approachCountFrom: approachCountFrom,
    // アプローチ数(TO)
    approachCountTo: approachCountTo,
    // 通電数(FROM)
    telephoneCountFrom: telephoneCountFrom,
    // 通電数(TO)
    telephoneCountTo: telephoneCountTo,
    // 接触数(FROM)
    contactCountFrom: contactCountFrom,
    // 接触数(TO)
    contactCountTo: contactCountTo,
    // 最終アクション日(FROM)
    lastActionDateYmdFrom: lastActionDateYmdFrom,
    // 最終アクション日(TO)
    lastActionDateYmdTo: lastActionDateYmdTo,
    // 招待者数(FROM)
    inviteCountFrom: inviteCountFrom,
    // 招待者数(TO)
    inviteCountTo: inviteCountTo,
    // 予約数(FROM)
    reserveCountFrom: '',
    // 予約数(TO)
    reserveCountTo: '',
    pageSize: pageSize,
    page: page,
    sortColumn: sortColumn,
    sortType: sortType,
  });

  console.debug('searchParams: ', params.removeParams());

  return axios('/admin/users', {
    params: params.removeParams(),
  });
};

const fetchSearchContactSuccess = (result) => {
  return {
    type: 'admin_users/FETCH_USER_SEARCH_CONTACT',
    payload: {
      result: result,
    },
  };
};

const makeStoreSearchLeadValues = (searchParams) => {
  return new Action('admin_users/STORE_SEARCH_LEAD_PARAMS', searchParams);
};

// 検索(Lead)
const makeSearchLead = (values, initFlg, searchParams) => {
  return (dispatch) => {
    dispatch(makeStoreSearchLeadValues(searchParams));

    return trackPromise(
      searchLead(values, initFlg, searchParams)
        .then((data) => dispatch(fetchSearchLeadSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const searchLead = (values, initFlg, searchParams) => {
  console.debug('values: ', values);
  console.debug('initFlg: ', initFlg);

  let sex = '';
  if (values.sex) {
    for (let i = 0; i < values.sex.length; i++) {
      if (i !== 0) {
        sex = sex + ',';
      }
      sex = sex + values.sex[i];
    }
  }

  let age = '';
  if (values.age) {
    for (let i = 0; i < values.age.length; i++) {
      if (i !== 0) {
        age = age + ',';
      }
      age = age + values.age[i];
    }
  }

  let pageSize = 10;
  if (searchParams.pagination) {
    if (searchParams.pagination.pageSize) {
      pageSize = searchParams.pagination.pageSize;
    }
  }
  let page = 1;
  if (searchParams.pagination) {
    if (searchParams.pagination.page) {
      page = searchParams.pagination.page;
    }
  }
  let sortColumn = 'mailAddress';
  if (searchParams.sort) {
    if (searchParams.sort.sortColumn) {
      sortColumn = searchParams.sort.sortColumn;
    }
  }
  let sortType = 'asc';
  if (searchParams.sort) {
    if (searchParams.sort.sortColumn) {
      sortType = searchParams.sort.sortType;
    }
  }

  // 流入日期間(From)
  let registerFromDateYmd = '';
  if (values.registerFromDateYmd) {
    const registerFromDateYmdDate = moment(
      values.registerFromDateYmd.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = registerFromDateYmdDate.getFullYear();
    const monthInt = registerFromDateYmdDate.getMonth() + 1;
    const dayInt = registerFromDateYmdDate.getDate();

    registerFromDateYmd =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 流入日期間(To)
  let registerToDateYmd = '';
  if (values.registerToDateYmd) {
    const registerToDateYmdDate = moment(
      values.registerToDateYmd.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = registerToDateYmdDate.getFullYear();
    const monthInt = registerToDateYmdDate.getMonth() + 1;
    const dayInt = registerToDateYmdDate.getDate();

    registerToDateYmd =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 最終アクション日(FROM)
  let lastActionDateYmdFrom = '';
  if (values.lastActionDateYmdFrom) {
    const lastActionDateYmdFromDate = moment(
      values.lastActionDateYmdFrom.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = lastActionDateYmdFromDate.getFullYear();
    const monthInt = lastActionDateYmdFromDate.getMonth() + 1;
    const dayInt = lastActionDateYmdFromDate.getDate();

    lastActionDateYmdFrom =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 最終アクション日(TO)
  let lastActionDateYmdTo = '';
  if (values.lastActionDateYmdTo) {
    const lastActionDateYmdToDate = moment(
      values.lastActionDateYmdTo.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = lastActionDateYmdToDate.getFullYear();
    const monthInt = lastActionDateYmdToDate.getMonth() + 1;
    const dayInt = lastActionDateYmdToDate.getDate();

    lastActionDateYmdTo =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  const params = new SearchParams({
    initFlg: initFlg,
    // 検索対象(1:B.人(取責) 2:リード 3: 会員DB)
    searchCode: '2',
    // SFID(リード)
    leadSfId: values.leadSfId,
    // メールアドレス
    mailAddress: values.mailAddress,
    // フリガナ
    nameKana: values.nameKana,
    // LINEニックネーム
    lineNickName: values.lineNickName,
    // 企業名
    company: values.company,
    // 年齢(1:10代 2:20代 3: 30代)
    age: age,
    // 流入日期間(FROM)
    registerFromDateYmd: registerFromDateYmd,
    // 流入日期間(TO)
    registerToDateYmd: registerToDateYmd,
    // 接触NG (1:除く)
    contactNg: '',
    // メールNG (1:除く)
    mailNg: '',
    // 来社日1(FROM)
    visitFromDateYmd: '',
    // 来社日1(TO)
    visitToDateYmd: '',
    // 来社日2(FROM)
    visitFromDate2Ymd: '',
    // 来社日2(TO)
    visitToDate2Ymd: '',
    // 現在の状況
    currentStatus: '',
    // カレッジ登録区分
    collegeRegistrationCategory: '',
    // 性別(1:男性 2:女性)
    sex: sex,
    // 都道府県
    pref: values.pref,
    // 携帯番号
    phoneNumber: values.phoneNumber,
    // アプローチ数(FROM)
    approachCountFrom: '',
    // アプローチ数(TO)
    approachCountTo: '',
    // 通電数(FROM)
    telephoneCountFrom: '',
    // 通電数(TO)
    telephoneCountTo: '',
    // 接触数(FROM)
    contactCountFrom: '',
    // 接触数(TO)
    contactCountTo: '',
    // 最終アクション日(FROM)
    lastActionDateYmdFrom: lastActionDateYmdFrom,
    // 最終アクション日(TO)
    lastActionDateYmdTo: lastActionDateYmdTo,
    // 招待者数(FROM)
    inviteCountFrom: '',
    // 招待者数(TO)
    inviteCountTo: '',
    // 予約数(FROM)
    reserveCountFrom: '',
    // 予約数(TO)
    reserveCountTo: '',
    pageSize: pageSize,
    page: page,
    sortColumn: sortColumn,
    sortType: sortType,
  });

  console.debug('searchParams: ', params.removeParams());

  return axios('/admin/users', {
    params: params.removeParams(),
  });
};

const fetchSearchLeadSuccess = (result) => {
  return {
    type: 'admin_users/FETCH_USER_SEARCH_LEAD',
    payload: {
      result: result,
    },
  };
};

const makeStoreSearchValues = (values, searchParams) => {
  return new Action('admin_users/STORE_SEARCH_PARAMS', {
    values,
    searchParams,
  });
};

// 検索(会員DB)
const makeSearch = (values, initFlg, searchParams) => {
  return (dispatch) => {
    dispatch(makeStoreSearchValues(values, searchParams));

    return trackPromise(
      search(values, initFlg, searchParams)
        .then((data) => dispatch(fetchSearchSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const search = (values, initFlg, searchParams) => {
  let sex = '';
  if (values.sex) {
    for (let i = 0; i < values.sex.length; i++) {
      if (i !== 0) {
        sex = sex + ',';
      }
      sex = sex + values.sex[i];
    }
  }

  let age = '';
  if (values.age) {
    for (let i = 0; i < values.age.length; i++) {
      if (i !== 0) {
        age = age + ',';
      }
      age = age + values.age[i];
    }
  }

  let pageSize = 10;
  if (searchParams.pagination) {
    if (searchParams.pagination.pageSize) {
      pageSize = searchParams.pagination.pageSize;
    }
  }
  let page = 1;
  if (searchParams.pagination) {
    if (searchParams.pagination.page) {
      page = searchParams.pagination.page;
    }
  }
  let sortColumn = 'sf_lead_user_key';
  if (searchParams.sort) {
    if (searchParams.sort.sortColumn) {
      sortColumn = searchParams.sort.sortColumn;
    }
  }
  let sortType = 'asc';
  if (searchParams.sort) {
    if (searchParams.sort.sortColumn) {
      sortType = searchParams.sort.sortType;
    }
  }

  // 流入日期間(From)
  let registerFromDateYmd = '';
  if (values.registerFromDateYmd) {
    const registerFromDateYmdDate = moment(
      values.registerFromDateYmd.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = registerFromDateYmdDate.getFullYear();
    const monthInt = registerFromDateYmdDate.getMonth() + 1;
    const dayInt = registerFromDateYmdDate.getDate();

    registerFromDateYmd =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 流入日期間(To)
  let registerToDateYmd = '';
  if (values.registerToDateYmd) {
    const registerToDateYmdDate = moment(
      values.registerToDateYmd.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = registerToDateYmdDate.getFullYear();
    const monthInt = registerToDateYmdDate.getMonth() + 1;
    const dayInt = registerToDateYmdDate.getDate();

    registerToDateYmd =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 最終アクション日(FROM)
  let lastActionDateYmdFrom = '';
  if (values.lastActionDateYmdFrom) {
    const lastActionDateYmdFromDate = moment(
      values.lastActionDateYmdFrom.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = lastActionDateYmdFromDate.getFullYear();
    const monthInt = lastActionDateYmdFromDate.getMonth() + 1;
    const dayInt = lastActionDateYmdFromDate.getDate();

    lastActionDateYmdFrom =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 最終アクション日(TO)
  let lastActionDateYmdTo = '';
  if (values.lastActionDateYmdTo) {
    const lastActionDateYmdToDate = moment(
      values.lastActionDateYmdTo.date,
      'YYYY/MM/DD'
    ).toDate();
    const year = lastActionDateYmdToDate.getFullYear();
    const monthInt = lastActionDateYmdToDate.getMonth() + 1;
    const dayInt = lastActionDateYmdToDate.getDate();

    lastActionDateYmdTo =
      year +
      '/' +
      ('00' + monthInt).slice(-2) +
      '/' +
      ('00' + dayInt).slice(-2);
  }

  // 招待者数(FROM)
  let inviteCountFrom = '';
  if (values.inviteCountFrom) {
    inviteCountFrom = String(values.inviteCountFrom);
  }

  // 招待者数(TO)
  let inviteCountTo = '';
  if (values.inviteCountTo) {
    inviteCountTo = String(values.inviteCountTo);
  }

  // 予約数(FROM)
  let reserveCountFrom = '';
  if (values.reserveCountFrom) {
    reserveCountFrom = String(values.reserveCountFrom);
  }

  // 予約数(TO)
  let reserveCountTo = '';
  if (values.reserveCountTo) {
    reserveCountTo = String(values.reserveCountTo);
  }

  const params = new SearchParams({
    initFlg: initFlg,
    // 検索対象(1:B.人(取責) 2:リード 3: 会員DB)
    searchCode: '3',
    // SFID(リード)
    leadSfId: values.leadSfId,
    // SFID(B.人)
    sfId: values.sfId,
    // メールアドレス
    mailAddress: values.mailAddress,
    // フリガナ
    nameKana: values.nameKana,
    // LINEニックネーム
    lineNickName: values.lineNickName,
    // 企業名
    company: values.company,
    // 年齢(1:10代 2:20代 3: 30代)
    age: age,
    // 流入日期間(FROM)
    registerFromDateYmd: registerFromDateYmd,
    // 流入日期間(TO)
    registerToDateYmd: registerToDateYmd,
    // 接触NG (1:除く)
    contactNg: '',
    // メールNG (1:除く)
    mailNg: '',
    // 来社日1(FROM)
    visitFromDateYmd: '',
    // 来社日1(TO)
    visitToDateYmd: '',
    // 来社日2(FROM)
    visitFromDate2Ymd: '',
    // 来社日2(TO)
    visitToDate2Ymd: '',
    // 現在の状況
    currentStatus: values.currentStatus,
    // カレッジ登録区分
    collegeRegistrationCategory: values.collegeRegistrationCategory,
    // 性別(1:男性 2:女性)
    sex: sex,
    // 都道府県
    pref: values.pref,
    // 携帯番号
    phoneNumber: values.phoneNumber,
    // アプローチ数(FROM)
    approachCountFrom: '',
    // アプローチ数(TO)
    approachCountTo: '',
    // 通電数(FROM)
    telephoneCountFrom: '',
    // 通電数(TO)
    telephoneCountTo: '',
    // 接触数(FROM)
    contactCountFrom: '',
    // 接触数(TO)
    contactCountTo: '',
    // 最終アクション日(FROM)
    lastActionDateYmdFrom: lastActionDateYmdFrom,
    // 最終アクション日(TO)
    lastActionDateYmdTo: lastActionDateYmdTo,
    // 招待者数(FROM)
    inviteCountFrom: inviteCountFrom,
    // 招待者数(TO)
    inviteCountTo: inviteCountTo,
    // 予約数(FROM)
    reserveCountFrom: reserveCountFrom,
    // 予約数(TO)
    reserveCountTo: reserveCountTo,
    pageSize: pageSize,
    page: page,
    sortColumn: sortColumn,
    sortType: sortType,
    isAccountLock: values.isAccountLock,
  });

  return axios('/admin/users', {
    params: params.removeParams(),
  });
};

const fetchSearchSuccess = (result) => {
  return {
    type: 'admin_users/FETCH_USER_SEARCH',
    payload: {
      result: result,
    },
  };
};

const fetchAdminProfile = () => {
  return axios.get('/admin/profile');
};

const fetchProfileSuccess = (data) => {
  return new Action('admin_users/FETCH_PROFILE', data);
};

const fetchUser = (id) => {
  return axios.get(`/admin/users/${id}`);
};

const makeFetchUser = (id) => {
  return async (dispatch) => {
    dispatch(showLoading());
    try {
      const fetchUserRes = await fetchUser(id);
      dispatch(fetchUserSuccess(fetchUserRes.data));

      const profileRes = await fetchAdminProfile();

      dispatch(fetchProfileSuccess(profileRes.data));
    } catch (e) {
      dispatch(fetchErrorData(e));
    }
  };
};

const fetchUserSuccess = (data) => {
  return {
    type: 'admin_users/FETCH_USER',
    payload: {
      result: data,
    },
  };
};

const makeUpdateUser = (values, id) => {
  return (dispatch) => {
    dispatch(showLoading());

    return trackPromise(
      putUser(values, id)
        .then((data) => dispatch(postUserSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const putUser = (values, id) => {
  const params = new SearchParams({
    companyId: values,
  });
  return axios.put(`/admin/users/${id}`, params.removeParams());
};

const postUserSuccess = (data) => {
  return {
    type: 'admin_users/UPDATE_USER',
    payload: {
      result: data,
    },
  };
};

const showEdit = () => {
  return {
    type: 'admin_users/SHOW_EDIT',
  };
};

const showEditClose = () => {
  return (dispatch) => {
    dispatch({
      type: 'admin_users/SHOW_EDIT_CLOSE',
    });
    dispatch(destroy('editModal'));
  };
};

const showEditConfirm = () => {
  return {
    type: 'admin_users/SHOW_EDIT_CONFIRM',
  };
};

const showEditConfirmClose = () => {
  return {
    type: 'admin_users/SHOW_EDIT_CONFIRM_CLOSE',
  };
};

const showEditCompleteClose = () => {
  return {
    type: 'admin_users/SHOW_EDIT_COMPLETE_CLOSE',
  };
};

const initState = () => {
  return {
    type: 'admin_users/INIT_STATE',
  };
};

const makeStoreTabIndex = (index) => {
  return new Action('admin_users/STORE_TAB_INDEX', index);
};

const unlockUserAccount = (userId) => {
  return axios.put(`/admin/user/${userId}/unlock`);
};

const makeUnlockSuccess = () => {
  return new EmptyAction('admin_users/UNLOCK_SUCCESS');
};

const makeUnlockUserAccount = (userId) => {
  return async (dispatch, getState) => {
    trackAsync(async () => {
      try {
        await unlockUserAccount(userId);
        dispatch(makeUnlockSuccess());
        const values = getState().user.values;
        const searchParams = getState().user.searchParams;

        dispatch(makeSearch(values, false, searchParams));
      } catch (e) {
        dispatch(fetchErrorData(e));
      }
    });
  };
};

const makeHideUnlockSuccessModal = () => {
  return new EmptyAction('admin_users/HIDE_UNLOCK_SUCCESS_MODAL');
};

const deactivateUser = (userId) => {
  return axios.put(`/admin/users/${userId}/deactivate`);
};

const makeDeactivateUserSuccess = () => {
  return new EmptyAction('admin_users/DEACTIVATE_USER_SUCCESS');
};

const makeDeactivateUser = (userId) => {
  return async (dispatch) => {
    trackAsync(async () => {
      try {
        await deactivateUser(userId);
        dispatch(makeDeactivateUserSuccess());
      } catch (e) {
        dispatch(fetchErrorData(e));
      }
    });
  };
};

const makeHideDeactivateCompleteModal = (id) => {
  return async (dispatch, getState) => {
    dispatch(new EmptyAction('admin_users/HIDE_DEACTIVATE_COMPLETE_MODAL'));
    await makeFetchUser(id)(dispatch);

    const values = getState().user.values;
    const searchParams = getState().user.searchParams;

    dispatch(makeSearch(values, false, searchParams));
  };
};

export {
  makeStoreTabIndex,
  makeFetchPrefs,
  makeFetchCompanyNames,
  makeFetchCurrentStatuses,
  makeFetchCollegeRegistrationCategories,
  makeSearchContact,
  makeSearchLead,
  makeSearch,
  makeFetchUser,
  makeUpdateUser,
  showEdit,
  showEditClose,
  showEditConfirm,
  showEditConfirmClose,
  showEditCompleteClose,
  initState,
  makeUnlockUserAccount,
  makeHideUnlockSuccessModal,
  makeDeactivateUser,
  makeHideDeactivateCompleteModal,
};

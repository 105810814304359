export default class IsDisplay {
  /**
   * ユーザ向け表示フラグ
   * @param {boolean} isDisplay
   */
  constructor(isDisplay) {
    console.assert(typeof isDisplay === 'boolean');

    /**
     * @property {boolean} value
     */
    this.value = isDisplay;
  }

  /**
   *
   * @returns {string}
   */
  get displayName() {
    if (this.value) {
      return '表示する';
    } else {
      return '表示しない';
    }
  }
}

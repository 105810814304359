import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import LightButton from '../../atoms/LightButton';
import ModalBody from '../../atoms/ModalBody';
import ModalFooter from '../../atoms/ModalFooter';
import RegisterButton from '../../molecules/RegisterButton';

export class Param {
  constructor(selectedStaffName, onBackButton, onUpdateButton) {
    this.selectedStaffName = selectedStaffName;
    this.onBackButton = onBackButton;
    this.onUpdateButton = onUpdateButton;
  }

  toObject() {
    return {
      selectedStaffName: this.selectedStaffName,
      onBackButton: this.onBackButton,
      onUpdateButton: this.onUpdateButton,
    };
  }
}

class DeleteAlertModal extends React.Component {
  static get propTypes() {
    return {
      targetId: PropTypes.string,
      children: PropTypes.string,
      show: PropTypes.bool,
      onClickYes: PropTypes.func,
      onClickNo: PropTypes.func,
      selectedStaffName: PropTypes.string,
      onBackButton: PropTypes.func,
      onUpdateButton: PropTypes.func,
    };
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>
          <p className="text-center">
            担当者を{this.props.selectedStaffName}
            さんに変更します。よろしいですか？
          </p>
        </ModalBody>
        <ModalFooter>
          <LightButton onClick={this.props.onBackButton}>戻る</LightButton>
          <RegisterButton onClick={this.props.onUpdateButton} />
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeleteAlertModal;

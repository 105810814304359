/**
 * @typedef {Object} PlainAction
 * @property {string} type
 * @property {T=} payload
 */

/**
 * @template T
 * Action
 */
class Action {
  /**
   * @param {string} type
   * @param {T} payload
   */
  constructor(type, payload) {
    /**
     * @property {string} type
     */
    this.type = type;

    /**
     *
     * @property {T} payload
     */
    this.payload = payload;
  }

  /**
   * @returns {PlainAction}
   */
  getObject() {
    return {
      type: this.type,
      payload: this.payload,
    };
  }
}

/**
 * EmptyAction
 */
class EmptyAction {
  /**
   *
   * @param {string} type
   */
  constructor(type) {
    /**
     * @property {string} type
     */
    this.type = type;
  }

  /**
   * @returns {PlainAction}
   */
  getObject() {
    return {
      type: this.type,
    };
  }
}

export default Action;
export { EmptyAction };

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import LabelErrorComponent from '../../molecules/LabelErrorComponent';
import Form from '../../organisms/adminPasswordReset/AdminPasswordResetMailInputForm';
import Card from '../../organisms/Card';

class AdminPasswordResetMailInput extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        adminPasswordResetSendMail: PropTypes.shape({
          isComplete: PropTypes.bool,
          isError: PropTypes.bool,
        }),
      }),
      makeSendAdminPasswordResetMail: PropTypes.func,
      initState: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.transitionToComplete = this.transitionToComplete.bind(this);
  }

  submit(values) {
    this.props.makeSendAdminPasswordResetMail(values);
  }

  transitionToComplete() {
    this.props.initState();
    this.props.history.push(`/admin/password_reset/mail/complete`);
  }

  render() {
    const errorShow = () => {
      if (this.props.state.adminPasswordResetSendMail.isError) {
        return (
          <LabelErrorComponent directly="・メールの送信に失敗しました"></LabelErrorComponent>
        );
      }
      return '';
    };

    const sendSuccess = () => {
      if (this.props.state.adminPasswordResetSendMail.isComplete) {
        this.transitionToComplete();
      }
    };

    return (
      <>
        {sendSuccess()}
        <section className="section">
          <div className="section-header">
            <h1>パスワード再発行</h1>
          </div>
          <div className="section-body">
            <Row>
              <Col>
                <Card headerTitle="パスワード再発行">
                  {errorShow()}
                  <Form onSubmit={this.submit} />
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </>
    );
  }
}

export default AdminPasswordResetMailInput;

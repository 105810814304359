class Option {
  /**
   *
   * @param {string} value
   * @param {string} name
   */
  constructor(value, name) {
    this.value = value;
    this.name = name;
  }
}

class OptionCollection {
  /**
   *
   * @param {Array.<Option>} options
   */
  constructor(options) {
    this.options = options;
  }

  /**
   * @returns {Array.<Option>}
   */
  getOptions() {
    return [new Option(null, '')].concat(this.options);
  }

  /**
   * @returns {Array.<Option>}
   */
  getOptionsWithoutEmpty() {
    return this.options;
  }
}

export default Option;
export { OptionCollection };

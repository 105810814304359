export default {
  /**
   * 検索画面
   * @param {string} id 会員ID
   */
  search: (id) => `/admin/reservation/${id}`,
  searchWithTabParam: (id) => (tabId) => {
    if (tabId) {
      return `/admin/reservation/${id}?tab=${tabId}`;
    }
    return `/admin/reservation/${id}`;
  },
  /**
   * イベント編集画面
   * @param {string} id イベントID
   */
  eventEdit: (id) => `/admin/reservation/event/edit/${id}`,
  /**
   * 個人面談編集画面
   * @param {string} id 個人面談ID
   */
  interviewEdit: (id) => `/admin/reservation/interview/edit/${id}`,
};

import PropTypes from 'prop-types';
import React from 'react';

import ModalFooter from '../atoms/ModalFooter';
import CloseButton from '../molecules/CloseButton';

class ModalFotterCloseButtonComponent extends React.Component {
  static get propTypes() {
    return {
      closeButtonTitle: PropTypes.string,
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <ModalFooter>
        <CloseButton onClick={this.props.onClick} />
      </ModalFooter>
    );
  }
}

export default ModalFotterCloseButtonComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import styled from 'styled-components';

import FormGroupRow from '../../atoms/FormGroupRow';
import FormLabel from '../../atoms/FormLabel';
import PrimaryButton from '../../atoms/PrimaryButton';
import * as Validator from '../../utils/Validate';

class DisplayAreaCheckboxComponent extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      validate: PropTypes.array,
      label: PropTypes.string,
      options: PropTypes.array,
      name: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);

    this.InputComponent = this.InputComponent.bind(this);
    this.subComponent = this.subComponent.bind(this);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  onChangeArea(value, onChange, e) {
    console.debug(
      'displayareacheckbox onchange target',
      e.target,
      e.target.value,
      value
    );

    if (e.target.checked) {
      if (!value.some((v) => v.areaId === e.target.value)) {
        const nextValue = value.concat([{ areaId: e.target.value, bases: [] }]);
        onChange(nextValue);
      }
    } else {
      const nextValue = value.filter((v) => v.areaId !== e.target.value);
      onChange(nextValue);
    }
  }

  onChangeBase(value, onChange, areaId, e) {
    if (e.target.checked) {
      const area = value.filter((v) => v.areaId === areaId);
      if (area.length > 0) {
        const bases = area[0].bases;
        if (!bases.map((b) => b.baseId).includes(e.target.value)) {
          const nextArea = [...value].filter((v) => v.areaId !== areaId);
          const nextValue = {
            areaId: areaId,
            bases: bases.concat({ baseId: e.target.value }),
          };
          onChange(nextArea.concat(nextValue));
        }
      } else {
        const nextValue = value.concat({
          areaId,
          bases: [
            {
              baseId: e.target.value,
            },
          ],
        });
        onChange(nextValue);
      }
    } else {
      const areas = value.filter((v) => {
        return v.areaId === areaId;
      });
      console.debug('area', areaId);
      const bases = areas[0].bases;

      const nextBaseValue = bases.filter((b) => b.baseId !== e.target.value);
      let nextArea = [...value].filter((v) => v.areaId !== areaId);

      if (nextBaseValue.length > 0) {
        nextArea = nextArea.concat([{ areaId, bases: nextBaseValue }]);
      }

      console.debug('nextArea', nextArea);
      onChange(nextArea);
    }
  }

  InputComponent(field) {
    const {
      input: { onChange, ...other },
    } = field;

    console.debug('displayareacheckbox component field', field);

    return (
      <div className="form-check form-check-inline">
        <input
          type="checkbox"
          className="form-check-input"
          onChange={
            field.type === 'area'
              ? this.onChangeArea.bind(this, other.value, onChange)
              : this.onChangeBase.bind(
                  this,
                  other.value,
                  onChange,
                  field.areaId
                )
          }
          checked={
            field.type === 'area'
              ? other.value &&
                other.value.some((v) => v.areaId === field.optionValue || false)
              : other.value &&
                other.value.some(
                  (a) =>
                    a.bases.some((b) => b.baseId === field.optionValue) || false
                )
          }
          value={field.optionValue}
          id={field.id}
        />
        <label className="form-check-label" htmlFor={field.id}>
          {field.label}
        </label>
      </div>
    );
  }

  subComponent(field) {
    const {
      input: { onChange, ...other },
    } = field;

    const onAllSelectChange = (e) => {
      const options = this.props.options;

      const nextValue = options.map((option) => {
        const bases = option.bases.map((base) => {
          return { baseId: base.baseId };
        });
        return { areaId: option.id, bases: bases };
      });
      onChange(nextValue);
    };

    return (
      <>
        <div>
          <PrimaryButton onClick={onAllSelectChange}>全選択</PrimaryButton>
        </div>
        {this.props.options ? (
          this.props.options.map((area, index) => {
            console.debug('displayareacheckboxcomponent area', area);
            return (
              <React.Fragment key={index}>
                {area.bases.length > 0 ? (
                  area.name
                ) : (
                  <Row>
                    <this.InputComponent
                      component={this.component}
                      key={index}
                      name={`${this.props.name}`}
                      id={this.props.label + area.name}
                      label={area.name}
                      optionValue={area.id}
                      type="area"
                      input={{
                        onChange: onChange,
                        value: other.value,
                      }}
                    />
                  </Row>
                )}

                {area.bases.map((base, index) => {
                  return (
                    <BaseDiv key={index}>
                      <this.InputComponent
                        component={this.component}
                        name={`${this.props.name}`}
                        id={this.props.label + area.name + base.baseName}
                        areaId={area.id}
                        label={base.baseName}
                        optionValue={base.baseId}
                        type="base"
                        input={{
                          onChange: onChange,
                          value: other.value,
                        }}
                      />
                    </BaseDiv>
                  );
                })}
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    console.debug('display area checkbox optins', this.props);
    return (
      <FormGroupRow>
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          {this.isRequired() ? (
            <>
              <span className="text-danger">*</span>
            </>
          ) : (
            <></>
          )}
        </FormLabel>
        <div className="col-sm-6">
          <Field component={this.subComponent} name={this.props.name} />
        </div>
      </FormGroupRow>
    );
  }
}

const BaseDiv = styled.div`
  margin-left: 3em;
`;

export default DisplayAreaCheckboxComponent;

import moment from 'moment';

export class ReservationFormatParam {
  constructor(ymd, hourFrom, minuteFrom, hourTo, minuteTo) {
    this.ymd = ymd;
    this.hourFrom = hourFrom;
    this.minuteFrom = minuteFrom;
    this.hourTo = hourTo;
    this.minuteTo = minuteTo;
  }
}

/**
 *
 * @param {ReservationFormatParam} param
 */
export const toReservationFormat = (param) => {
  const fromDay = moment(param.ymd).locale('ja');
  fromDay.set('hour', param.hourFrom);
  fromDay.set('minute', param.minuteFrom);

  const from = fromDay.format('YYYY/MM/DD (ddd) HH:mm');
  const toDay = moment(param.ymd).locale('ja');
  toDay.set('hour', param.hourTo);
  toDay.set('minute', param.minuteTo);

  const to = toDay.format('HH:mm');

  return `${from}〜${to}`;
};

import PropTypes, { shape, string, func } from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

import ReleaseButton from '../../molecules/ReleaseButton';

class SubstituteAlert extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      substituteUser: shape({
        name: string,
      }),
      onClickReleaseButton: func,
    };
  }

  render() {
    const Container = styled.div`
      justify-content: space-between;
      display: flex;
    `;

    const MessageParentBlock = styled.div`
      display: flex;
      align-items: center;
    `;

    return (
      <Col sm={{ offset: 1, span: 8 }}>
        <Container className="alert alert-warning">
          <MessageParentBlock>
            <span>{this.props.substituteUser.name}さんの代理予約処理中</span>
          </MessageParentBlock>
          <ReleaseButton onClick={this.props.onClickReleaseButton} />
        </Container>
      </Col>
    );
  }
}

export default SubstituteAlert;

import PropTypes from 'prop-types';
import React from 'react';

import FormRadio from '../atoms/FormRadio';

class FormRadioComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      options: PropTypes.array,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      defaultValue: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);
    this.state = { radio: props.defaultValue };
  }

  isCheckedValue(index, value) {
    if (!this.state.radio) {
      return index === 0;
    }
    return value === this.state.radio;
  }

  render() {
    return (
      <div className="form-group">
        <label className="d-block">{this.props.label}</label>
        {this.props.options.map((option, index) => {
          return (
            <FormRadio
              key={this.props.label + option.name}
              name={this.props.name}
              id={this.props.id + '-' + (index + 1)}
              value={option.value}
              isChecked={this.isCheckedValue(index, option.value)}
              onChange={() => this.setState({ radio: option.value })}
              label={option.name}
            />
          );
        })}
      </div>
    );
  }
}

export default FormRadioComponent;

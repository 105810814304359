import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row } from 'react-bootstrap';

import Badge from '../../atoms/Badge';
import FullyOccupied from '../../atoms/FullyOccupied';
import ModalHeader from '../../atoms/ModalHeader';

// import ModalTitle from '../atoms/ModalTitle';
// import ModalXButton from '../atoms/ModalXButton'

class SearchEventsDetailModalHeaderComponent extends React.Component {
  static get propTypes() {
    return {
      title: PropTypes.string,
      isFullyOccupied: PropTypes.bool,
      eventType: PropTypes.string,
    };
  }

  render() {
    return (
      <ModalHeader>
        <Container>
          <Row>
            <div className="col-2">
              {this.props.isFullyOccupied ? (
                <>
                  <FullyOccupied />
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="col-3">
              <Badge>{this.props.eventType}</Badge>
            </div>
            <div className="col-7">
              <h5>{this.props.title}</h5>
            </div>
          </Row>
        </Container>
      </ModalHeader>
    );
  }
}

export default SearchEventsDetailModalHeaderComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Field } from 'redux-form';

import FormGroupRow from '../atoms/FormGroupRow';
import FormLabel from '../atoms/FormLabel';
import FormSelect from '../atoms/FormSelect';
import * as Validator from '../utils/Validate';

class HorizontalSelectComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      options: PropTypes.array,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
      name: PropTypes.string,
      validate: PropTypes.array,
      messages: PropTypes.arrayOf(PropTypes.string),
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);

    console.debug('HorizontalSelectComponent', this.props);
    console.debug(this.props.messages);
    console.debug(this.props.messages != null && this.props.messages.length);
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  component(field) {
    return (
      <FormGroupRow>
        {this.props.name ? (
          <Element name={`position-${this.props.name}`} />
        ) : (
          <></>
        )}
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          {this.isRequired() ? (
            <>
              <span className="text-danger">*</span>
            </>
          ) : (
            <></>
          )}
        </FormLabel>
        <div className="col-sm-6">
          <FormSelect
            meta={field.meta}
            input={field.input}
            onChange={this.props.onChange}
            onClick={this.props.onClick}
          >
            {field.options.map((op, key) => {
              return (
                <option key={key} value={op.value}>
                  {op.name}
                </option>
              );
            })}
          </FormSelect>
          {field.meta.touched && field.meta.error && (
            <Form.Control.Feedback type="invalid">
              {field.meta.error}
            </Form.Control.Feedback>
          )}
          {/* サーバーサイドエラーメッセージ */}
          {this.props.messages != null && this.props.messages.length > 0 ? (
            <div className="alert alert-danger">
              {this.props.messages.map((message, i) => (
                <p key={i}>{message}</p>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </FormGroupRow>
    );
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.component}
        validate={this.props.validate}
        options={this.props.options}
      />
    );
  }
}

export default HorizontalSelectComponent;

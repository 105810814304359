import PropTypes from 'prop-types';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { makeFetchCompanyNames } from '../../../actions/User';
import LightButton from '../../atoms/LightButton';
import ModalBody from '../../atoms/ModalBody';
import ModalFooter from '../../atoms/ModalFooter';
import PrimaryFormButton from '../../atoms/PrimaryFormButton';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import * as Validator from '../../utils/Validate';

class EditModal extends React.Component {
  static get propTypes() {
    return {
      targetId: PropTypes.string,
      children: PropTypes.string,
      show: PropTypes.bool,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      onEditClick: PropTypes.func,
      onCloseClick: PropTypes.func,
      fetchCompanyNames: PropTypes.func,
      submitting: PropTypes.bool,
      invalid: PropTypes.bool,
      options: PropTypes.shape({
        companyNames: PropTypes.array,
      }),
      compnay: PropTypes.string,
    };
  }

  componentDidMount() {
    this.props.fetchCompanyNames();
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>
          <p className="text-left">企業名設定</p>
          <Form onSubmit={this.props.handleSubmit}>
            <div>
              <HorizontalSelectComponent
                label=""
                name="companyId"
                options={this.props.options.companyNames}
                validate={[Validator.required]}
              />
            </div>
            <ModalFooter>
              <LightButton onClick={this.props.onCloseClick}>
                閉じる
              </LightButton>
              <PrimaryFormButton
                isEnabled={() => {
                  return !this.props.invalid;
                }}
              >
                登録
              </PrimaryFormButton>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

const editModal = reduxForm({
  // a unique name for the form
  form: 'editModal',
  enableReinitialize: true,
})(EditModal);

export default connect(
  (state, props) => {
    let companyId = '';
    if (props.companyName && props.companyName.length > 0) {
      const find = state.options.companyNames.filter(
        (companyName) => companyName.name === props.companyName
      );
      if (find.length > 0) {
        companyId = find[0].value;
      }
    }

    return {
      options: state.options,
      state: state.user,
      initialValues: {
        companyId: companyId,
      },
    };
  },
  (dispatch) => {
    return {
      fetchCompanyNames() {
        dispatch(makeFetchCompanyNames());
      },
    };
  }
)(editModal);

import PropTypes from 'prop-types';
import React from 'react';

import InfoFormButton from '../atoms/InfoFormButton';

class MineButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
    };
  }

  render() {
    return <InfoFormButton onClick={this.props.onClick}>自分担当</InfoFormButton>;
  }
}

export default MineButton;

import { arrayOf, func, array, shape, bool, string } from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, FieldArray } from 'redux-form';

import {
  makeFetchAreas,
  makeFetchPrefs,
  makeUploadAccessMapFile,
  makeDeleteAccessMapImage,
  makeFetchSearchZipCode,
} from '../../../actions/Base';
import FormLabel from '../../atoms/FormLabel';
import Zipcode from '../../model/Zipcode';
import CancelButton from '../../molecules/CancelButton';
import ConfirmButton from '../../molecules/ConfirmButton';
import FormInlineCheckBoxComponent from '../../molecules/FormInlineCheckBoxComponent';
import FormReferItem from '../../molecules/FormReferItem';
import FormTelComponent from '../../molecules/FormTelComponent';
import FormZipCodeComponent from '../../molecules/FormZipCodeComponent';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalFormTextAreaComponent from '../../molecules/HorizontalFormTextareaComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import MarginFormComponent from '../../molecules/MarginFormComponent';
import HorizontalFormAccessComponent from '../../organisms/base/HorizontalFormAccessComponent';
import { scrollToFirstError } from '../../utils/ScrollError';
import * as Validator from '../../utils/Validate';

class BaseEditForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: func,
      onSubmit: func,
      fetchAreas: func,
      fetchPrefs: func,
      accessList: array,
      options: shape({
        areas: array,
        prefs: array,
      }),
      state: shape({
        baseEdit: shape({
          errorMessages: shape({ zipCode: arrayOf(string) }),
        }),
      }),
      formValue: shape({
        zipCode1: string,
        zipCode2: string,
        address: string,
      }),
      onPrevButtonClick: func,
      invalid: bool,
      submitting: bool,
      isNew: bool,
      uploadAccessMapFile: func,
      deleteAccessMapImage: func,
      searchZipCode: func,
    };
  }

  constructor() {
    super();
    this.uploadAccessMapFile = this.uploadAccessMapFile.bind(this);
    this.deleteAccessMapImage = this.deleteAccessMapImage.bind(this);
    this.onClickZipCodeSearch = this.onClickZipCodeSearch.bind(this);
    this.onClickSearchGoogleMap = this.onClickSearchGoogleMap.bind(this);
  }

  uploadAccessMapFile(file, index) {
    this.props.uploadAccessMapFile(file, index);
  }

  deleteAccessMapImage(index) {
    this.props.deleteAccessMapImage(index);
  }

  onClickZipCodeSearch() {
    if (this.props.formValue.zipCode1 && this.props.formValue.zipCode2) {
      this.props.searchZipCode(
        `${this.props.formValue.zipCode1}${this.props.formValue.zipCode2}`
      );
    }
  }

  onClickSearchGoogleMap(e) {
    const address = this.props.formValue.address;

    if (address) {
      window.open(`https://www.google.com/maps/place/${address}`);
    } else {
      window.open('https://www.google.co.jp/maps/?hl=ja');
    }

    e.preventDefault();
    return false;
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <MarginFormComponent>
            <HorizontalFormInputComponent
              label="拠点名"
              name="name"
              validate={[Validator.required]}
            />
            <HorizontalFormInputComponent
              label="表示名"
              name="displayName"
              validate={[Validator.required]}
            />

            <FormInlineCheckBoxComponent
              label={'キャラバンフラグ'}
              name={'isCaravan'}
            />

            <FormInlineCheckBoxComponent
              label={'ユーザ向け表示フラグ'}
              name={'isDisplay'}
            />

            <HorizontalSelectComponent
              label="企業名"
              options={this.props.options.companyNames}
              name="companyName"
              validate={[Validator.required]}
            />

            <HorizontalSelectComponent
              label="エリア"
              options={this.props.options.areas}
              name="areaId"
              validate={[Validator.required]}
            />

            <FormLabel className="col-sm-3 col-form-label">住所</FormLabel>
            <FormZipCodeComponent
              label="郵便番号"
              name="zipCode"
              buttonLabel="住所検索"
              onClick={this.onClickZipCodeSearch}
              errorMessages={
                this.props.state.baseEdit.errorMessages &&
                Object.hasOwnProperty.call(
                  this.props.state.baseEdit.errorMessages,
                  'zipCode'
                )
                  ? this.props.state.baseEdit.errorMessages.zipCode
                  : []
              }
            />
            <HorizontalSelectComponent
              label="都道府県"
              options={this.props.options.prefs}
              name="prefId"
              validate={[Validator.required]}
            />
            <HorizontalFormInputComponent
              label="市区町村"
              name="city"
              component="input"
              type="text"
              validate={[Validator.required]}
            />

            <HorizontalFormInputComponent
              label="建物名"
              name="buildingName"
              component="input"
              type="text"
            />

            <FormTelComponent label="連絡先電話番号" name="phoneNumber" />

            <HorizontalFormInputComponent
              label="連絡先メールアドレス"
              name="mailAddress"
              component="input"
              type="text"
              validate={[Validator.required, Validator.email]}
            />

            <HorizontalFormInputComponent
              label="イベント上限件数"
              name="eventDisplayLimit"
              validate={[Validator.required, Validator.number]}
            />

            <FormLabel className="col-sm-3 col-form-label">地図</FormLabel>

            <FormReferItem label="">
              <a href="" onClick={this.onClickSearchGoogleMap}>
                Googleマップで検索
              </a>
            </FormReferItem>

            <HorizontalFormTextAreaComponent
              label="通常URL"
              name="googleMapUrl"
              validate={[Validator.required, Validator.url]}
            />
            <HorizontalFormInputComponent
              label="短縮URL"
              name="googleMapShortUrl"
              validate={[Validator.required, Validator.url]}
            />

            <FieldArray
              label="アクセス方法"
              name="accessList"
              component={HorizontalFormAccessComponent}
              updateFile={this.uploadAccessMapFile}
              deleteAccessMapImage={this.deleteAccessMapImage}
            />
            <HorizontalFormInputComponent
              label="最寄り駅"
              name="nearestStation"
              validate={[Validator.required]}
            />
          </MarginFormComponent>
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <CancelButton onClick={this.props.onPrevButtonClick} />
              <ConfirmButton
                disabled={this.props.invalid || this.props.submitting}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const baseEditForm = reduxForm({
  // a unique name for the form
  form: 'baseEditForm',
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(BaseEditForm);

export default connect(
  (state) => {
    const tel1Number = (tel) => {
      if (tel == null) {
        return '';
      }
      const index = tel.indexOf('-');

      return tel.slice(0, index);
    };

    const tel2Number = (tel) => {
      if (tel == null) {
        return '';
      }

      const firstSplitterIndex = tel.indexOf('-');
      const secondSplitterIndex = tel.indexOf('-', firstSplitterIndex + 1);
      return tel.slice(firstSplitterIndex + 1, secondSplitterIndex);
    };

    const tel3Number = (tel) => {
      if (tel == null) {
        return '';
      }

      const firstSplitterIndex = tel.indexOf('-');
      const secondSplitterIndex = tel.indexOf('-', firstSplitterIndex + 1);
      return tel.slice(secondSplitterIndex + 1);
    };

    const selector = formValueSelector('baseEditForm');

    const prefId = selector(state, 'prefId');

    const filteredPrefs = state.options.prefs.filter((pref) => {
      return pref.value === prefId;
    });

    let prefName = '';
    if (filteredPrefs.length > 0) {
      prefName = filteredPrefs[0].name;
    }
    const address = `${prefName || ''}${selector(state, 'city') || ''}${
      selector(state, 'buildingName') || ''
    }`;

    const formValue = {
      zipCode1: selector(state, 'zipCode1'),
      zipCode2: selector(state, 'zipCode2'),
      address: address && address.length > 0 ? address : null,
    };

    let accessList = state.base.baseRefer.detail.accessList;
    if (!accessList || accessList.length === 0) {
      accessList = [{}];
    }

    let zipcodePrefix = '';
    let zipcodeSuffix = '';
    if (state.base.baseRefer.detail.zipCode) {
      const zipcode = new Zipcode(state.base.baseRefer.detail.zipCode);
      zipcodePrefix = zipcode.getFirst();
      zipcodeSuffix = zipcode.getLast();
    }

    const isCaravan = state.base.baseRefer.detail.isCaravan || false;
    const isDisplay = state.base.baseRefer.detail.isDisplay || false;

    return {
      initialValues: {
        ...state.base.baseRefer.detail,
        isCaravan: isCaravan,
        isDisplay: isDisplay,
        accessList: accessList,
        phoneNumber1: tel1Number(state.base.baseRefer.detail.phoneNumber),
        phoneNumber2: tel2Number(state.base.baseRefer.detail.phoneNumber),
        phoneNumber3: tel3Number(state.base.baseRefer.detail.phoneNumber),
        zipCode1: zipcodePrefix,
        zipCode2: zipcodeSuffix,
      },
      options: state.options,
      formValue: formValue,
      state: state.base,
    };
  },
  (dispatch) => {
    return {
      fetchAreas() {
        dispatch(makeFetchAreas());
      },
      fetchPrefs() {
        dispatch(makeFetchPrefs());
      },
      uploadAccessMapFile(file, index) {
        dispatch(makeUploadAccessMapFile(file, index));
      },
      deleteAccessMapImage(index) {
        dispatch(makeDeleteAccessMapImage(index));
      },
      searchZipCode(zipcode) {
        dispatch(makeFetchSearchZipCode(zipcode));
      },
    };
  }
)(baseEditForm);

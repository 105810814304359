import PropTypes from 'prop-types';
import React from 'react';

class FormLabel extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      className: PropTypes.string,
    };
  }

  render() {
    return (
      <label className={this.props.className}>{this.props.children}</label>
    );
  }
}

export default FormLabel;

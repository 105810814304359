import {
  SampleSearch,
  SampleRefer,
  SampleEdit,
} from '../../../containers/Sample';

const SampleRoutes = [
  {
    path: '/sample/',
    exact: true,
    component: SampleSearch,
  },
  {
    path: '/sample/refer/:id',
    component: SampleRefer,
  },
  {
    path: '/sample/edit/:id',
    component: SampleEdit,
  },
];

export default SampleRoutes;

import 'moment/locale/ja';
import PropTypes, { func, object, shape } from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ErrorMessageBox from '../../atoms/ErrorMessageBox';
import LightButton from '../../atoms/LightButton';
import Loading from '../../atoms/Loading';
import PrimaryButton from '../../atoms/PrimaryButton';
import FormReferItem, { VERTICAL } from '../../molecules/FormReferItem';
import MarginFormComponent from '../../molecules/MarginFormComponent';
import Card from '../../organisms/Card';
import BackConfirmModal from '../../organisms/reservationManagement/BackConfirmModal';
import CompleteModal from '../../organisms/reservationManagement/CompleteModal';
import EventsEditParticipants from '../../organisms/reservationManagement/EventsEditParticipants';
import SearchUserModal from '../../organisms/reservationManagement/SearchUserModal';
import SubstituteAlert from '../../organisms/reservationManagement/SubstituteAlert';
import {
  ReservationFormatParam,
  toReservationFormat,
} from '../../utils/DateFormat';
import { EditAction } from '../../utils/PermissionComponent';

import { TabParam } from './ReservationManagementSearch';
import routePath from './routePath';

class ReservationManagementEventEdit extends React.Component {
  static get propTypes() {
    return {
      location: shape({
        search: object,
      }),
      match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
      }),
      state: PropTypes.shape({
        reservationManagementEventsEdit: {
          isProxyBooking: PropTypes.bool,
          isShowParticipantsSearchModal: PropTypes.bool,
          event: PropTypes.array,
        },
        reservationManagementEvents: PropTypes.shape({
          selectedDate: PropTypes.Date,
        }),
        reservationManagementUsers: PropTypes.shape({
          users: PropTypes.shape({
            result: PropTypes.array,
          }),
        }),
        isComplete: PropTypes.bool,
        isLoading: PropTypes.bool,
        searchValues: PropTypes.any,
        substituteUser: object,
        deleteParticipants: {
          id: PropTypes.string,
          name: PropTypes.string,
        },
        originalParticipants: {
          id: PropTypes.string,
          name: PropTypes.string,
        },
      }),
      options: PropTypes.shape({
        eventTypes: PropTypes.array,
        companyNames: PropTypes.array,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      fetchReservationEventsEditDetail: PropTypes.func,
      updateEventsEditDetail: PropTypes.func,
      searchUsers: PropTypes.func,
      setEventsEditParticipants: PropTypes.func,
      initUserSearch: PropTypes.func,
      fetchReservationEvents: PropTypes.func,
      fetchReservationEventsDetail: PropTypes.func,
      fetchCompaniesOptions: PropTypes.func,
      clearSubstituteEventEdit: func,
    };
  }

  constructor(props) {
    super(props);
    /* participants: [{id: string, name: string}] */
    this.state = {
      name: '',
      isSearchUsersModal: false,
      deleteParticipants: [],
      originalParticipants: null,
    };
    this.deleteParticipants = this.deleteParticipants.bind(this);
    this.addParticipants = this.addParticipants.bind(this);
    this.onClickAddButton = this.onClickAddButton.bind(this);
    this.hideSearchUserModal = this.hideSearchUserModal.bind(this);
    this.onClickSelectButton = this.onClickSelectButton.bind(this);
    this.releaseSubstituteUser = this.releaseSubstituteUser.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    const urlParams = new URLSearchParams(this.props.location.search);
    const userId = urlParams.get('userId');
    this.setState({ userId: userId });
    this.id = params.id;
    this.props.fetchReservationEventsEditDetail(this.id, userId);
    this.props.fetchCompaniesOptions();
  }

  deleteParticipants(id) {
    console.log('****deleteParticipants****');
    const participants = this.props.state.reservationManagementEventsEdit
      .participants;

    if (this.state.originalParticipants == null) {
      this.setState({ originalParticipants: participants });
    }

    const newParticipants = participants.filter((p) => p.id !== id);

    const deleteParticipant = participants.filter((p) => p.id === id);
    const stateDeleteParticipants = this.state.deleteParticipants;
    deleteParticipant.forEach((participant) => {
      stateDeleteParticipants.push(participant);
    });

    // 重複削除
    const deleteParticipants = Array.from(
      new Map(
        stateDeleteParticipants.map((participant) => [
          participant.id,
          participant,
        ])
      ).values()
    );

    this.setState({
      deleteParticipants: deleteParticipants,
    });

    this.props.setEventsEditParticipants({
      participants: newParticipants,
      deleteParticipants: deleteParticipants,
    });
  }

  addParticipants(id, name) {
    const participants = this.props.state.reservationManagementEventsEdit
      .participants;

    if (this.state.originalParticipants == null) {
      this.setState({ originalParticipants: participants });
    }

    const alreadyExists = participants.some(
      (participant) => participant.id === id
    );

    if (alreadyExists) {
      return;
    }

    const newParticipants = participants.concat([{ id: id, name: name }]);
    this.props.setEventsEditParticipants({ participants: newParticipants });
  }

  onClickAddButton() {
    this.props.initUserSearch();
    this.showUserSearchModal();
  }

  searchUsers(values) {
    if (
      !Object.prototype.hasOwnProperty.call(values, 'pageSize') ||
      !Object.prototype.hasOwnProperty.call(values, 'page')
    ) {
      values = {
        ...values,
        pageSize: 10,
        page: 1,
      };
    }
    this.props.searchUsers(values);
  }

  submit() {
    const participants = this.props.state.reservationManagementEventsEdit
      .participants;
    if (participants.length < 0) {
      return;
    }

    let deleteParticipants = this.state.deleteParticipants;
    // 元データと比較して削除された会員を抽出
    const original = this.state.originalParticipants;
    if (original != null) {
      const originalIds = [];
      original.forEach((item) => originalIds.push(item.id));
      deleteParticipants = deleteParticipants.filter((participant) => {
        return originalIds.includes(participant.id);
      });
    }
    // 登録者リストとの重複削除
    if (participants.length > 0) {
      const participantIds = [];
      participants.forEach((item) => participantIds.push(item.id));
      deleteParticipants = deleteParticipants.filter((participant) => {
        return !participantIds.includes(participant.id);
      });
    }

    // 登録処理
    this.props.updateEventsEditDetail(this.id, {
      participants,
      deleteParticipants,
    });
  }

  onClickCancel() {
    this.setState({ isConfirmModal: true });
  }

  onClickConfirmModalYes() {
    const searchValues = this.props.state.searchValues;

    if (Object.keys(searchValues).length > 0) {
      this.props.fetchReservationEvents(searchValues);
    }

    const selectedDate = this.props.state.reservationManagementEvents
      .selectedDate;
    this.props.fetchReservationEventsDetail(this.id, selectedDate);

    this.props.history.push(
      routePath.searchWithTabParam(this.state.userId || '')(TabParam.Event)
    );
  }

  onClickConfirmModalNo() {
    this.setState({ isConfirmModal: false });
  }

  showUserSearchModal() {
    this.setState({ isSearchUsersModal: true });
  }

  hideSearchUserModal() {
    this.setState({ isSearchUsersModal: false });
  }

  onClickSelectButton(id, name) {
    this.hideSearchUserModal();
    this.addParticipants(id, name);
  }

  releaseSubstituteUser() {
    this.props.clearSubstituteEventEdit(this.state.userId);
    this.setState({ userId: null });

    const path = routePath.eventEdit(this.id);
    this.props.history.push(path);
  }

  render() {
    const getEventType = () => {
      console.debug('getEventType', this.props.options.eventTypes);
      if (
        !this.props.options.eventTypes ||
        this.props.options.eventTypes.length === 0
      ) {
        return '';
      }
      const eventTypes = this.props.options.eventTypes;
      const kindId = this.props.state.reservationManagementEventsEdit.event
        .kindId;

      console.debug('kindId', kindId);

      const filteredEventType = eventTypes.filter(
        (eventType) => eventType.value === kindId
      );

      console.debug('filteredEventType', filteredEventType);

      if (filteredEventType.length > 0) {
        return filteredEventType[0].name;
      }
      return '';
    };

    const getEventName = () => {
      return this.props.state.reservationManagementEventsEdit.event.name;
    };

    const getEventsDateTime = () => {
      const event = this.props.state.reservationManagementEventsEdit.event;

      const param = new ReservationFormatParam(
        event.dateYMDFrom,
        event.dateHHFrom,
        event.dateMMFrom,
        event.dateHHTo,
        event.dateMMTo
      );

      return toReservationFormat(param);
    };

    const getMaxOfSeat = () => {
      return this.props.state.reservationManagementEventsEdit.event
        .seatAvailability;
    };

    const isLoading = () => {
      return this.props.state.isLoading;
    };

    const getSearchResult = () => {
      return this.props.state.reservationManagementUsers.users.result;
    };

    const isOverParticipants = () => {
      const participantsLength = this.props.state
        .reservationManagementEventsEdit.participants.length;

      const maxOfSeat = getMaxOfSeat();

      return !(maxOfSeat < participantsLength);
    };

    return (
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <Card headerTitle="予約管理（イベント）">
            <Loading isLoading={isLoading()}>
              <MarginFormComponent>
                {this.props.state.reservationManagementEventsEdit &&
                this.props.state.reservationManagementEventsEdit
                  .errorMessage ? (
                  <>
                    <Col sm={{ offset: 1, span: 8 }}>
                      <ErrorMessageBox
                        errorMessages={[
                          this.props.state.reservationManagementEventsEdit
                            .errorMessage,
                        ]}
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                {this.props.state.substituteUser ? (
                  <SubstituteAlert
                    substituteUser={this.props.state.substituteUser}
                    onClickReleaseButton={this.releaseSubstituteUser}
                  />
                ) : (
                  <></>
                )}
                <FormReferItem label="予定種別">{getEventType()}</FormReferItem>
                <FormReferItem label="予定名">{getEventName()}</FormReferItem>
                <FormReferItem label="開催日時">
                  {getEventsDateTime()}
                </FormReferItem>
                <FormReferItem label="参加者" labelVertical={VERTICAL.TOP}>
                  <EventsEditParticipants
                    list={
                      this.props.state.reservationManagementEventsEdit
                        .participants == null
                        ? []
                        : this.props.state.reservationManagementEventsEdit
                            .participants
                    }
                    maxOfSeat={getMaxOfSeat()}
                    onDeleteClick={this.deleteParticipants}
                    onAddClick={this.onClickAddButton}
                    substituteUser={this.props.state.substituteUser}
                  />
                </FormReferItem>
                <hr />
                <Row>
                  <div className="mx-auto">
                    <LightButton onClick={this.onClickCancel.bind(this)}>
                      キャンセル
                    </LightButton>
                    &nbsp;
                    <EditAction>
                      <PrimaryButton
                        onClick={this.submit.bind(this)}
                        isEnable={isOverParticipants}
                      >
                        登録
                      </PrimaryButton>
                    </EditAction>
                  </div>
                </Row>
              </MarginFormComponent>
              <SearchUserModal
                show={this.state.isSearchUsersModal}
                onHide={this.hideSearchUserModal}
                onClickSelectButton={this.onClickSelectButton}
                searchResult={getSearchResult()}
                onSubmit={this.searchUsers.bind(this)}
                companies={this.props.options.companyNames}
              />
              <BackConfirmModal
                show={this.state.isConfirmModal}
                onClickYes={this.onClickConfirmModalYes.bind(this)}
                onClickNo={this.onClickConfirmModalNo.bind(this)}
              />
              <CompleteModal
                show={this.props.state.isComplete}
                onClick={this.onClickConfirmModalYes.bind(this)}
              />
            </Loading>
          </Card>
        </div>
      </div>
    );
  }
}

export default ReservationManagementEventEdit;

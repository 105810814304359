import { connect } from 'react-redux';

import {
  makeFetchBases,
  makeFetchAreas,
  makeFetchTags,
  makeFetchCategories,
  makeFetchEventTypes,
  makeFetchMethodTypes,
  makeFetchHallsById,
  makeFetchReservationEvents,
  makeInitStateHalls,
  makeFetchReservationInterviews,
  makeFetchReservationEventsDetail,
  makeFetchReservationInterviewsDetail,
  makeFetchReservationEventsEditDetail,
  makeFetchReservationInterviewsEditDetail,
  makeUpdateEventsEditDetail,
  makeSearchUsers,
  hideEventsDetailModal,
  hideInterviewDetailModal,
  initUserSearch,
  clearSubstitute,
  setEventsEditParticipants,
  setInterviewInterviewees,
  initStoreSearchValues,
  makeFetchUserProfile,
  clearSubstituteEventEdit,
  clearSubstituteInterviewEdit,
  initSubstituteUser,
  makeFetchCompaniesOptions,
  makeTransitionToInterview,
} from '../actions/ReservationManagement';
import ReservationManagement from '../components/pages/reservationManagement/ReservationManagement';
import ReservationManagementEventEditComponent from '../components/pages/reservationManagement/ReservationManagementEventEdit';
import ReservationManagementInterviewEditComponent from '../components/pages/reservationManagement/ReservationManagementInterviewEdit';
import ReservationManagementSearchComponent from '../components/pages/reservationManagement/ReservationManagementSearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.reservationManagement,
    options: state.options,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBases() {
      dispatch(makeFetchBases());
    },
    fetchAreas() {
      dispatch(makeFetchAreas());
    },
    fetchTags() {
      dispatch(makeFetchTags());
    },
    fetchCategories() {
      dispatch(makeFetchCategories());
    },
    fetchEventTypes() {
      dispatch(makeFetchEventTypes());
    },
    fetchMethodTypes() {
      dispatch(makeFetchMethodTypes());
    },
    fetchHallsById(areaId) {
      dispatch(makeFetchHallsById(areaId));
    },
    fetchReservationEvents(values) {
      dispatch(makeFetchReservationEvents(values));
    },
    fetchReservationInterviews(values) {
      dispatch(makeFetchReservationInterviews(values));
    },
    fetchReservationEventsDetail(id, selectedDate) {
      dispatch(makeFetchReservationEventsDetail(id, selectedDate));
    },
    fetchReservationInterviewsDetail(id, selectedDate) {
      dispatch(makeFetchReservationInterviewsDetail(id, selectedDate));
    },
    fetchUserProfile(id) {
      dispatch(makeFetchUserProfile(id));
    },
    updateEventsEditDetail(eventId, values) {
      dispatch(makeUpdateEventsEditDetail(eventId, values));
    },
    searchUsers(searchParams) {
      dispatch(makeSearchUsers(searchParams));
    },
    initStateHalls() {
      dispatch(makeInitStateHalls());
    },
    clearSubstitute() {
      dispatch(clearSubstitute());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationManagement);

const ReservationManagementSearch = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initStoreSearchValues() {
      dispatch(initStoreSearchValues());
    },
    hideEventsDetailModal() {
      dispatch(hideEventsDetailModal());
    },
    hideInterviewDetailModal() {
      dispatch(hideInterviewDetailModal());
    },
    initSubstituteUser() {
      dispatch(initSubstituteUser());
    },
    transitionToInterview(interviewId, interviewerId, selectedDate) {
      dispatch(
        makeTransitionToInterview(interviewId, interviewerId, selectedDate)
      );
    },
  };
})(ReservationManagementSearchComponent);

export { ReservationManagementSearch };

const ReservationManagementEventEdit = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initUserSearch() {
      dispatch(initUserSearch());
    },
    setEventsEditParticipants(values) {
      dispatch(setEventsEditParticipants(values));
    },
    fetchReservationEventsEditDetail(id, userId) {
      dispatch(makeFetchReservationEventsEditDetail(id, userId));
    },
    clearSubstituteEventEdit(userId) {
      dispatch(clearSubstituteEventEdit(userId));
    },
    fetchCompaniesOptions() {
      dispatch(makeFetchCompaniesOptions());
    },
  };
})(ReservationManagementEventEditComponent);

export { ReservationManagementEventEdit };

const ReservationManagementInterviewEdit = connect(
  mapStateToProps,
  (dispatch) => {
    return {
      ...mapDispatchToProps(dispatch),
      fetchReservationInterviewsEditDetail(id, userId) {
        dispatch(makeFetchReservationInterviewsEditDetail(id, userId));
      },
      clearSubstituteInterviewEdit(userId) {
        dispatch(clearSubstituteInterviewEdit(userId));
      },
      setInterviewInterviewees(values) {
        dispatch(setInterviewInterviewees(values));
      },
    };
  }
)(ReservationManagementInterviewEditComponent);

export { ReservationManagementInterviewEdit };

import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-big-calendar';
import styled from 'styled-components';

const InlineSelect = styled.select`
  display: inline-block;
  width: 9em;
  text-align: center;
`;

class CalendarToolbar extends React.PureComponent {
  constructor(props) {
    super(props);

    const currentYear = new Date().getFullYear();

    this.currentYear = currentYear;
  }

  /**
   * return {Array.<number>}
   */
  getYearRange(date) {
    const year = this.currentYear;
    const selectedYear = date.getFullYear();

    const years = [...Array(3).keys()].map((i) => i - 1 + year);

    if (years.includes(selectedYear)) {
      return years;
    } else {
      if (selectedYear < years[0]) {
        return [...Array(years[0] - selectedYear).keys()]
          .map((i) => i + selectedYear)
          .concat(years);
      } else {
        const overYears = [
          ...Array(selectedYear - years[years.length - 1]).keys(),
        ].map((i) => i + years[years.length - 1] + 1);
        return years.concat(overYears);
      }
    }
  }

  render() {
    return (
      <div className="rbc-btn-group text-center">
        <div className="rbc-toolbar">
          <span className="rbc-btn-group">
            <button
              type="button"
              onClick={() => this.props.onNavigate(Navigate.PREVIOUS)}
            >
              ◀︎
            </button>
            <span className="rbc-toolbar-label">
              <InlineSelect
                className={'form-control'}
                value={this.props.date.getFullYear()}
                onChange={(e) => {
                  const year = e.target.value;
                  const date = this.props.date;
                  const newDate = new Date(
                    year,
                    date.getMonth(),
                    date.getDate()
                  );

                  this.props.onNavigate(Navigate.DATE, newDate);
                }}
              >
                {this.getYearRange(this.props.date).map((year, i) => {
                  return (
                    <option key={i} value={year}>
                      {year}
                    </option>
                  );
                })}
              </InlineSelect>
              年{`${this.props.date.getMonth() + 1}月`}
            </span>
            <button
              type="button"
              onClick={() => this.props.onNavigate(Navigate.NEXT)}
            >
              ▶︎
            </button>
          </span>
        </div>
      </div>
    );
  }
}

CalendarToolbar.propTypes = {
  date: PropTypes.instanceOf(Date),
  onNavigate: PropTypes.func,
};

export default CalendarToolbar;

import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import ModalBody from '../../atoms/ModalBody';
import ModalFooterCloseButtonComponent from '../../molecules/ModalFotterCloseButtonComponent';

class ContainsUsedCategoryAlertModal extends React.Component {
  static get propTypes() {
    return {
      targetId: PropTypes.string,
      children: PropTypes.string,
      show: PropTypes.bool,
      onCloseButtonClick: PropTypes.func,
    };
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>
          <p className="text-center">
            選択されたカテゴリの中にすでに使用済みのカテゴリが含まれています
          </p>
        </ModalBody>
        <ModalFooterCloseButtonComponent
          onClick={this.props.onCloseButtonClick}
        />
      </Modal>
    );
  }
}

export default ContainsUsedCategoryAlertModal;

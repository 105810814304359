import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import DangerButton from '../../atoms/DangerButton';
import LightButton from '../../atoms/LightButton';
import ModalBody from '../../atoms/ModalBody';
import ModalFooter from '../../atoms/ModalFooter';

export default class DisableConfirmModal extends React.Component {
  render() {
    return (
      <Modal show={this.props.show}>
        <ModalBody>チェックした企業名を削除しますか</ModalBody>
        <ModalFooter>
          <LightButton onClick={this.props.onNoButtonClick}>いいえ</LightButton>
          <DangerButton onClick={this.props.onYesButtonClick}>
            はい
          </DangerButton>
        </ModalFooter>
      </Modal>
    );
  }
}

DisableConfirmModal.propTypes = {
  show: PropTypes.bool,
  onNoButtonClick: PropTypes.func,
  onYesButtonClick: PropTypes.func,
};

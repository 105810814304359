import React from 'react'
import Card from '../../organisms/Card';

const NotFound = () =>
  <div>
    <section className="section">
      <div className="section-body">
        <Card headerTitle="404 ご指定のページは見つかりませんでした。">
          URLを見直すか、システム管理者までお問い合わせください。
        </Card>
      </div>
    </section>
  </div>

export default NotFound

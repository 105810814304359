import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';

import ModalBody from '../../atoms/ModalBody';
import ModalHeader from "../../atoms/ModalHeader";
import FormTemplateForm from "./FormTemplateForm";

class SelectFormTemplateModal extends React.Component {
  static get propTypes() {
    return {
      show: PropTypes.bool,
      onClickClose: PropTypes.func,
      transitionToSearch: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onCloseClick} size="lg">
        <ModalHeader>
          テンプレートを選択してください
        </ModalHeader>
        <ModalBody>
          <FormTemplateForm
            onSubmit={this.props.onClickClose}
            onClickPrev={this.props.transitionToSearch}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default SelectFormTemplateModal;

import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import ModalBody from '../atoms/ModalBody';
import ModalHeader from '../atoms/ModalHeader';
import Suggest, { Param as SuggestParam } from '../atoms/Suggest';
import ModalFotterCloseButton from '../molecules/ModalFotterCloseButtonComponent';

class StaffSearchModal extends React.Component {
  static get propTypes() {
    return {
      show: PropTypes.bool,
      onClick: PropTypes.func,
      onChange: PropTypes.func,
      options: PropTypes.array,
      setChairman: PropTypes.func,
      fetchStaff: PropTypes.func,
      clearSuggestion: PropTypes.func,
      onSelected: PropTypes.func,
      state: PropTypes.shape({
        suggestions: PropTypes.array,
      }),
      onCloseButtonClick: PropTypes.func,
      suggestions: PropTypes.array,
    };
  }

  constructor(props) {
    super(props);
    this.suggestionRef = React.createRef();

    this.state = { value: '' };

    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && this.props.show) {
      this.suggestionRef.current.focus();
    }
  }

  onChange(event, { newValue }) {
    console.debug('staff serach modal modal newValue', newValue);
    this.setState({
      value: newValue,
    });
  }

  render() {
    const { value } = this.state;

    const suggestions = this.props.suggestions;

    const inputProps = {
      placeholder: '社員名を入力してください',
      value,
      onChange: this.onChange,
      ref: this.suggestionRef,
    };

    const getSuggestionValue = (suggestion) => suggestion.name;

    // const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;
    const renderSuggestion = (suggestion) => {
      return <span>{suggestion.name}</span>;
    };

    return (
      <Modal show={this.props.show}>
        <ModalHeader>JAIC社員検索</ModalHeader>
        <ModalBody>
          <Suggest
            {...new SuggestParam(
              suggestions,
              this.props.fetchStaff,
              this.props.clearSuggestion,
              getSuggestionValue,
              renderSuggestion,
              inputProps,
              this.props.onSelected
            ).toObject()}
          />
        </ModalBody>
        <ModalFotterCloseButton
          closeButtonTitle="閉じる"
          onClick={this.props.onCloseButtonClick}
        />
      </Modal>
    );
  }
}

export default StaffSearchModal;

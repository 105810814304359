import PropTypes from 'prop-types';
import React from 'react';

import NormalButton from './NormalButton';

class DangerButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      onClick: PropTypes.func,
    };
  }

  render() {
    return (
      <NormalButton color="danger" {...this.props}>
        {this.props.children}
      </NormalButton>
    );
  }
}

export default DangerButton;

import { connect } from 'react-redux';

import {
  makeSearch,
  makeInitTag,
  hideLoading,
  makeConfirmIsUsed,
  makeHideContainsAlertModal,
  makeHideDeleteConfirmModal,
  makeDeleteTags,
  makeHideDeleteCompleteModal,
  makeFetchTag,
  makeSetEditValues,
  makeBackToInput,
  makeUpdateTag,
  makeInitTagEdit,
} from '../actions/Tag';
import TagComponent from '../components/pages/tag/Tag';
import TagEditComponent from '../components/pages/tag/TagEdit';
import TagReferComponent from '../components/pages/tag/TagRefer';
import TagSearchComponent from '../components/pages/tag/TagSearch';

const mapStateToProps = (state) => {
  return {
    state: state.tag,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search(values, searchParams) {
      dispatch(makeSearch(values, searchParams));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagComponent);

const TagSearch = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initFetchSearchPage(isComeFromOtherPage) {
      dispatch(makeInitTag(isComeFromOtherPage));
    },

    confirmIsUsed(tagIds) {
      dispatch(makeConfirmIsUsed(tagIds));
    },
    onAlertModalCloseButtonClick() {
      dispatch(makeHideContainsAlertModal());
    },
    hideDeleteConfirmModal() {
      dispatch(makeHideDeleteConfirmModal());
    },
    deleteTags(ids) {
      dispatch(makeDeleteTags(ids));
    },
    hideDeleteCompleteModal(searchValues) {
      dispatch(makeHideDeleteCompleteModal(searchValues));
    },
  };
})(TagSearchComponent);

const TagRefer = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchTag(tagId) {
      dispatch(makeFetchTag(tagId));
    },
  };
})(TagReferComponent);

const TagEdit = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    initTagEdit(tagId) {
      dispatch(makeInitTagEdit(tagId));
    },
    fetchTag(tagId) {
      dispatch(makeFetchTag(tagId));
    },
    setEditValues(values) {
      dispatch(makeSetEditValues(values));
    },
    backToInput() {
      dispatch(makeBackToInput());
    },
    updateTag(values) {
      dispatch(makeUpdateTag(values));
    },
  };
})(TagEditComponent);

export { TagSearch, TagRefer, TagEdit };

import {
  TemplateSearch,
  TemplateRefer,
  TemplateEdit,
} from '../../../containers/Template';

import routePath from './routePath';

const TemplateRoutes = [
  {
    path: routePath.search,
    exact: true,
    component: TemplateSearch,
  },
  {
    path: routePath.refer(':id'),
    component: TemplateRefer,
  },
  {
    path: routePath.edit(':id'),
    component: TemplateEdit,
  },
  {
    path: routePath.edit(''),
    component: TemplateEdit,
  },
];

export default TemplateRoutes;

/**
 *
 * @enum
 */
const EventType = {
  SEMINAR: 'セミナー',
};

export default class EventTypes {
  /**
   *
   * @param {Array.<import('../Option').Option>} options
   */
  constructor(options) {
    /**
     *
     * @type {Array<import('../Option').Option>}
     */
    this.options = options;
  }

  /**
   *
   * @param {string} otherId
   * @returns {boolean}
   */
  isSeminar(otherId) {
    const filteredEventType = this.options.filter(
      (option) => option.name === EventType.SEMINAR
    );

    if (filteredEventType.length > 0) {
      return filteredEventType[0].value === otherId;
    }

    return false;
  }
}

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Field } from 'redux-form';
import styled from 'styled-components';

import InlineFormRadio from '../atoms/InlineFormRadioForForm';
import * as Validator from '../utils/Validate';

class FormInlineRadioComponent extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          default: PropTypes.bool,
        }).isRequired
      ).isRequired,
      onChange: PropTypes.func,
      validate: PropTypes.array,
      defaultValue: PropTypes.string,
      isCentered: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.state = { options: props.options };

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <>
        {field.options.map((option, index) => {
          return (
            <InlineFormRadio
              key={index}
              label={option.name}
              value={option.value}
              name={this.props.name}
              id={this.props.name + option.name + option.value}
              field={field}
              isChecked={option.default}
              defaultValue={this.props.defaultValue}
              onChange={
                this.props.onChange
                  ? this.props.onChange.bind(this, option.value)
                  : undefined
              }
            />
          );
        })}
        {field.meta.touched && field.meta.error && (
          <DisplayFeedback type="invalid">{field.meta.error}</DisplayFeedback>
        )}
      </>
    );
  }

  isRequired() {
    if (this.props.validate == null) {
      return false;
    }
    return this.props.validate.indexOf(Validator.required) >= 0;
  }

  isCentered() {
    if (this.props.isCentered == null) {
      return false;
    }
    return this.props.isCentered;
  }

  render() {
    return (
      <Row className="form-group">
        <label className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}&nbsp;
          {this.isRequired() ? (
            <>
              <span className="text-danger">*</span>
            </>
          ) : (
            <></>
          )}
        </label>
        <Col xs={6} className="d-flex align-items-center">
          {this.isCentered() ? (
            <CenteredRow>
              <Field
                component={this.component}
                name={this.props.name}
                options={this.props.options}
                validate={this.props.validate}
              />
            </CenteredRow>
          ) : (
            <LeftNonMarginRow>
              <Field
                component={this.component}
                name={this.props.name}
                options={this.props.options}
                validate={this.props.validate}
              />
            </LeftNonMarginRow>
          )}
        </Col>
      </Row>
    );
  }
}

const LeftNonMarginRow = styled(Row)`
  margin-left: 0px;
`;
const CenteredRow = styled(Row)`
  margin: auto;
`;

const DisplayFeedback = styled(Form.Control.Feedback)`
  display: block;
`;

export default FormInlineRadioComponent;

import FormFromtoComponent from '../../molecules/FormFromtoComponent';
import SearchButton from '../../molecules/SearchButton';

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { reduxForm } from 'redux-form';

class SummarySearchSeminorForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
    };
  }

  render() {
    const getStartDate = () => {
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() + 1);
      return fromDate;
    };

    const getEndDate = () => {
      const toDate = new Date();
      toDate.setDate(toDate.getDate() + 30);
      return toDate;
    };

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <FormFromtoComponent
            label="期間"
            fromId="seminorFrom"
            fromDate={getStartDate()}
            toId="seminorTo"
            toDate={getEndDate()}
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
export default reduxForm({
  // a unique name for the form
  form: 'summarySearchSeminorForm',
})(SummarySearchSeminorForm);

import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import FormCheckBoxesComponent from '../../molecules/FormCheckBoxesComponent';
import FormInputComponent from '../../molecules/FormInputComponent';
import FormRadioComponent from '../../molecules/FormRadioComponent';
import FormSelectComponent from '../../molecules/FormSelectComponent';
import SearchButton from '../../molecules/SearchButton';
import Card from '../../organisms/Card';

const SortIconTag = styled.i`
  margin-left: 1em;
`;

const DropDown = styled.div`
  position: absolute;
  transform: translate3d(0px, 28px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
`;

class SampleSearch extends React.Component {
  MAX_PAGE_ITEM_NUM() {
    return 5;
  }

  static get propTypes() {
    return {
      addTodo: PropTypes.func,
      state: PropTypes.shape({
        todoList: PropTypes.array,
        searchResults: PropTypes.shape({
          searchParams: PropTypes.shape({
            pagination: PropTypes.shape({
              page: PropTypes.number,
              pageSize: PropTypes.number,
              total: PropTypes.number,
            }),
            sort: PropTypes.shape({
              sortColumn: PropTypes.string,
              sortType: PropTypes.string,
            }),
          }),
          samples: PropTypes.arrayOf(
            PropTypes.shape({
              age: PropTypes.number,
              name: PropTypes.string,
              prefectureId: PropTypes.number,
            })
          ),
        }),
        prefectures: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          })
        ),
      }),
      searchData: PropTypes.func,
      fetchPrefectures: PropTypes.func,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);

    this.search = this.search.bind(this);
    this.inputOnChange = this.inputOnChange.bind(this);
    this.getPageItem = this.getPageItem.bind(this);
    this.changePage = this.changePage.bind(this);
    this.getSearchItem = this.getSearchItem.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prefectureChange = this.prefectureChange.bind(this);
    this.clickSearchButton = this.clickSearchButton.bind(this);
    this.changePageSize = this.changePageSize.bind(this);

    this.state = {
      name: '',
      prefectureId: null,
    };
  }

  componentDidMount() {
    this.props.fetchPrefectures();
  }

  changePageSize(pageSize) {
    console.debug('pageSize: ', pageSize);
    if (
      pageSize ===
      this.props.state.searchResults.searchParams.pagination.pageSize
    ) {
      return;
    }

    const page = this.props.state.searchResults.searchParams.pagination.page;

    this.search({
      pagination: {
        page: page,
        pageSize: pageSize,
      },
      sort: this.props.state.searchResults.searchParams.sort,
    });
  }

  changePage(page) {
    console.debug('page', page);
    if (page === this.props.state.searchResults.searchParams.pagination.page) {
      return;
    }

    const pageSize = this.props.state.searchResults.searchParams.pagination
      .pageSize;

    this.search({
      pagination: {
        page: page,
        pageSize: pageSize,
      },
      sort: this.props.state.searchResults.searchParams.sort,
    });
  }

  prevPage() {
    const currentPage = this.props.state.searchResults.searchParams.pagination
      .page;
    if (currentPage === 1) {
      return;
    }

    this.changePage(currentPage - 1);
  }

  nextPage() {
    const currentPage = this.props.state.searchResults.searchParams.pagination
      .page;
    const maxPageNum = Math.ceil(
      this.props.state.searchResults.searchParams.pagination.total /
        this.props.state.searchResults.searchParams.pagination.pageSize
    );

    if (currentPage === maxPageNum) {
      return;
    }

    this.changePage(currentPage + 1);
  }

  getPageItem() {
    const maxPageNum = Math.ceil(
      this.props.state.searchResults.searchParams.pagination.total /
        this.props.state.searchResults.searchParams.pagination.pageSize
    );
    const currentPage = this.props.state.searchResults.searchParams.pagination
      .page;

    console.debug('max_page_item_num', this.MAX_PAGE_ITEM_NUM);
    const minPage = currentPage - Math.floor(this.MAX_PAGE_ITEM_NUM() / 2);
    const maxPage = currentPage + Math.floor(this.MAX_PAGE_ITEM_NUM() / 2);

    console.debug('maxPage >= maxPageNum', maxPage >= maxPageNum);
    let range = [];
    if (minPage < 1) {
      range = [...Array(maxPageNum).keys()].slice(0, this.MAX_PAGE_ITEM_NUM());
    } else if (maxPage >= maxPageNum) {
      range = [...Array(maxPageNum).keys()].slice(
        Math.max(maxPageNum - this.MAX_PAGE_ITEM_NUM(), 0),
        maxPageNum
      );
    } else {
      range = [...Array(maxPageNum).keys()].slice(minPage - 1, maxPage);
    }
    console.debug('minPage, maxPage: ', minPage, maxPage, range);

    const isActive = (v) => {
      return v === currentPage;
    };

    return (
      <>
        {range.map((v, i) => {
          const page = v + 1;
          return (
            <li
              key={i}
              className={isActive(page) ? 'page-item active' : 'page-item'}
            >
              <a
                className="page-link"
                href="#!"
                onClick={this.changePage.bind(this, page)}
              >
                {page}
              </a>
            </li>
          );
        })}
      </>
    );
  }

  inputOnChange(e) {
    console.log('inputOnChange:', e);
    this.setState({ name: e.target.value });
  }

  clickSearchButton() {
    this.search({
      sort: {
        sortColumn: 'id',
        sortType: 'asc',
      },
      pagination: { page: 1, pageSize: 10 },
    });
  }

  sort(column) {
    console.debug('column: ', column);

    const activeSortColumn = this.props.state.searchResults.searchParams.sort
      .sortColumn;
    const activeSortType = this.props.state.searchResults.searchParams.sort
      .sortType;

    let sortType = 'asc';
    if (activeSortColumn === column && activeSortType === 'asc') {
      sortType = 'desc';
    }

    this.search({
      pagination: this.props.state.searchResults.searchParams.pagination,
      sort: { sortColumn: column, sortType: sortType },
    });
  }

  search(searchParams) {
    this.props.searchData(
      { name: this.state.name, prefectureId: this.state.prefectureId },
      searchParams
    );
  }

  getSearchItem() {
    const samples = this.props.state.searchResults.samples;

    const transitionRefer = (id) => {
      this.props.history.push(`/sample/refer/${id}`);
    };

    const prefectureName = (prefectureId) => {
      const prefecture = this.props.state.prefectures
        .filter((prefecture) => {
          return prefecture.id === prefectureId;
        })
        .slice(0, 1);

      if (prefecture.length === 0) {
        return '';
      } else if (prefecture.length === 1) {
        return prefecture[0].name;
      }
    };

    const items = samples.map((sample, id) => {
      return (
        <tr key={id} onClick={transitionRefer.bind(this, sample.id)}>
          <th scope="row">{sample.id}</th>
          <td>{sample.name}</td>
          <td>{sample.age}</td>
          <td>{prefectureName(sample.prefectureId)}</td>
        </tr>
      );
    });
    return items;
  }

  prefectureChange(e) {
    console.debug('prefecture_select', e.target.value);
    this.setState({ prefectureId: e.target.value });
  }

  render() {
    console.debug('state: ', this.props);
    const getPrefectureOptions = () => {
      console.debug('prefectures: ', this.props.state);
      const prefectureOptions = [{ value: null, name: '' }].concat(
        this.props.state.prefectures.map((prefecture) => {
          return {
            name: prefecture.name,
            value: prefecture.id,
          };
        })
      );
      console.debug('prefectureOptions: ', prefectureOptions);
      return prefectureOptions;
    };

    const getSortStatusTag = (column) => {
      const activeSortColumn = this.props.state.searchResults.searchParams.sort
        .sortColumn;

      if (activeSortColumn !== column) {
        return <SortIconTag className="text-secondary fas fa-sort" />;
      }

      const activeSortType = this.props.state.searchResults.searchParams.sort
        .sortType;
      if (activeSortType === 'asc') {
        return <SortIconTag className="text-primary fas fa-sort-up" />;
      } else {
        return <SortIconTag className="text-primary fas fa-sort-down" />;
      }
    };

    console.log('SampleSearch: ', this.props);
    return (
      <section className="section">
        <div className="section-header">
          <h1>サンプルページ</h1>
        </div>

        <div className="section-body">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <Card headerTitle="サンプル検索ページ">
                <FormInputComponent
                  label="name"
                  onChange={this.inputOnChange}
                />
                <FormSelectComponent
                  label="select"
                  options={getPrefectureOptions()}
                  onChange={this.prefectureChange}
                />
                <FormCheckBoxesComponent
                  label="checkbox"
                  options={[
                    { name: 'hoge', value: 0 },
                    { name: 'fuga', value: 1 },
                  ]}
                />
                <FormRadioComponent
                  label="Radio"
                  options={[{ name: 'hoge' }, { name: 'fuga' }]}
                />
                <Row>
                  <Col>
                    <div className="text-center">
                      <SearchButton onClick={this.clickSearchButton} />
                    </div>
                  </Col>
                </Row>

                {this.props.state.searchResults.samples.length > 0 ? (
                  <>
                    <Row>
                      <Col>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="form-group row">
                          <label
                            htmlFor="inputPassword3"
                            className="col-sm-3 col-form-label"
                          >
                            表示件数
                          </label>
                          <div className="col-sm-9">
                            <div className="dropdown d-inline mr-2">
                              <button
                                className="btn btn-primary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {this.props.state.searchResults.searchParams
                                  .pagination.pageSize + '件'}
                              </button>
                              <DropDown
                                className="dropdown-menu"
                                x-placement="bottom-start"
                              >
                                <a
                                  className="dropdown-item"
                                  href="#!"
                                  onClick={this.changePageSize.bind(this, 10)}
                                >
                                  10件
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#!"
                                  onClick={this.changePageSize.bind(this, 30)}
                                >
                                  30件
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#!"
                                  onClick={this.changePageSize.bind(this, 50)}
                                >
                                  50件
                                </a>
                              </DropDown>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <ul className="pagination">
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={this.prevPage}
                            >
                              «
                            </a>
                          </li>
                          {this.getPageItem()}
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={this.nextPage}
                            >
                              »
                            </a>
                          </li>
                        </ul>
                      </Col>
                      <Col className="d-flex align-items-center">
                        総件数:
                        {
                          this.props.state.searchResults.searchParams.pagination
                            .total
                        }
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                {this.props.state.searchResults.samples.length > 0 ? (
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">
                          <span onClick={this.sort.bind(this, 'id')}>
                            id
                            {getSortStatusTag('id')}
                          </span>
                        </th>
                        <th scope="col">
                          <span onClick={this.sort.bind(this, 'name')}>
                            名前
                            {getSortStatusTag('name')}
                          </span>
                        </th>
                        <th scope="col">
                          <span onClick={this.sort.bind(this, 'age')}>
                            年齢
                            {getSortStatusTag('age')}
                          </span>
                        </th>
                        <th scope="col">
                          <span onClick={this.sort.bind(this, 'prefecture_id')}>
                            都道府県
                            {getSortStatusTag('prefecture_id')}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.getSearchItem()}</tbody>
                  </table>
                ) : (
                  <></>
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SampleSearch;

/**
 * パスワード設定 path
 */
export default {
  /**
   *
   * @type {string} 編集画面
   */
  edit: '/admin/setting_password',
};

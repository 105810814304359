import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import BulkRegisterButton from '../../molecules/BulkRegisterButton';
import PrevButton from '../../molecules/PrevButton';

/**
 * @class EventsBulkUpdateState
 */
class EventsBulkUpdateState {
  constructor(fileName = '') {
    /**
     *
     * @property {string} fileName
     */
    this.fileName = fileName;
  }

  /**
   *
   * @param {EventsBulkUpdateState} state
   * @returns {EventsBulkUpdateState}
   */
  static fromState(state) {
    return new EventsBulkUpdateState(state.fileName);
  }
}

class EventsBulkUpdate extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      fileName: PropTypes.string,
      file: PropTypes.file,
      state: PropTypes.shape({
        staffBulkUpdate: PropTypes.shape({
          tempFileId: PropTypes.string,
          originalFileName: PropTypes.string,
          searchResults: PropTypes.shape({
            results: PropTypes.array,
            searchParams: PropTypes.shape(),
          }),
          isShowExecutedDialog: PropTypes.bool,
        }),
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      hideExecutedDialog: PropTypes.func,
      fetchBulkUpdateHistories: PropTypes.func,
      uploadBulkInsertFile: PropTypes.func,
      onClickExecutionButton: PropTypes.func,
      onClickPrevButton: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.state = new EventsBulkUpdateState();

    this.transitionToBulkRegister = this.transitionToBulkRegister.bind(this);
  }

  onClickCloseButton() {
    this.props.hideExecutedDialog();
    this.initSearch();
  }

  onClickFileButton(e) {
    const target = e.target;
    const file = target.files.item(0);
    console.debug('handleChangefile', target);
    console.debug(target.value);
    console.debug('file', file);

    this.setState({ fileName: file.name });
    this.props.uploadBulkInsertFile(file);
  }

  transitionToBulkRegister() {
    this.props.onClickPrevButton();
  }

  render() {
    return (
      <>
        <Row>
          <Col xs={3}>
            一括アップロード
            <br />
            (csv)
          </Col>
          <Col xs={9}> {this.props.fileName}</Col>
        </Row>

        <Row>
          <Col className="text-center">
            <PrevButton onClick={this.transitionToBulkRegister} />
            &nbsp;
            <BulkRegisterButton onClick={this.props.onClickExecutionButton} />
          </Col>
        </Row>
      </>
    );
  }
}

export default EventsBulkUpdate;

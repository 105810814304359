import { connect } from 'react-redux';

import {
  addTodo,
  makeSearchData,
  makeReferData,
  setAge,
  setPrefectureId,
  save,
  transitionRefer,
  makeFetchPrefectures,
} from '../actions/Sample';
import SampleComponent from '../components/pages/sample/Sample';
import SampleEditComponent from '../components/pages/sample/SampleEdit';
import SampleReferComponent from '../components/pages/sample/SampleRefer';
import SampleSearchComponent from '../components/pages/sample/SampleSearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.sample,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTodo: (todo) => {
      dispatch(addTodo(todo));
    },
    searchData: (condition, searchParams) => {
      dispatch(makeSearchData(condition, searchParams));
    },
    referData: (id) => {
      dispatch(makeReferData(id));
    },
    setAge: (age) => {
      dispatch(setAge(age));
    },
    save: (data) => {
      dispatch(save(data));
    },
    setPrefectureId: (prefectureId) => {
      dispatch(setPrefectureId(prefectureId));
    },
    transitionRefer: (history, id) => {
      dispatch(transitionRefer(history, id));
    },
    fetchPrefectures: () => {
      dispatch(makeFetchPrefectures());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleComponent);
const SampleSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleSearchComponent);

export { SampleSearch };

const SampleRefer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleReferComponent);

export { SampleRefer };

const SampleEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleEditComponent);

export { SampleEdit };

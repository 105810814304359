import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import {
  makeFetchPrefs,
  makeFetchCompanyNames,
  makeFetchCurrentStatuses,
} from '../../../actions/User';
import FormInlineCheckBoxesComponent from '../../molecules/FormInlineCheckBoxesComponent';
import FormInlineRadioComponent from '../../molecules/FormInlineRadioComponentForForm';
import FormNumberFromToComponent from '../../molecules/FormNumberFromToComponent';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';
import FormFromtoComponent from '../../molecules/user/FormFromtoComponent';

class UserSearchForm extends React.Component {
  static get propTypes() {
    return {
      searchCode: PropTypes.string,
      handleSubmit: PropTypes.func,
      fetchPrefs: PropTypes.func,
      fetchCompanyNames: PropTypes.func,
      fetchCurrentStatuses: PropTypes.func,
      fetchCollegeRegistrationCategories: PropTypes.func,
      options: PropTypes.shape({
        prefs: PropTypes.array,
        companyNames: PropTypes.array,
        currentStatuses: PropTypes.array,
      }),
      formState: PropTypes.shape({
        values: PropTypes.shape({
          searchCode: PropTypes.string,
        }),
      }),
    };
  }

  componentDidMount() {
    this.props.fetchPrefs();
    this.props.fetchCompanyNames();
    this.props.fetchCurrentStatuses();
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent label="SFID(リード)" name="leadSfId" />
          <HorizontalFormInputComponent label="SFID(B.人)" name="sfId" />
          <HorizontalFormInputComponent
            label="メールアドレス"
            name="mailAddress"
          />
          <HorizontalFormInputComponent
            label="名前(フリガナ)"
            name="nameKana"
          />
          <HorizontalFormInputComponent
            label="LINEニックネーム"
            name="lineNickName"
          />
          <HorizontalSelectComponent
            label="企業名"
            name="company"
            options={this.props.options.companyNames}
          />
          <FormInlineCheckBoxesComponent
            label="年齢"
            options={[
              { name: '10代', value: '1' },
              { name: '20代', value: '2' },
              { name: '30代', value: '3' },
            ]}
            name="age"
          />
          <FormFromtoComponent
            label="流入日期間"
            fromId="registerFromDateYmd"
            toId="registerToDateYmd"
          />

          <HorizontalSelectComponent
            label="現在の状況"
            name="currentStatus"
            options={this.props.options.currentStatuses}
          />

          <FormInlineCheckBoxesComponent
            label="性別"
            name="sex"
            options={[
              { name: '男性', value: '1' },
              { name: '女性', value: '2' },
              { name: '未設定', value: '0' },
            ]}
          />
          <HorizontalSelectComponent
            label="都道府県"
            name="pref"
            options={this.props.options.prefs}
          />
          <HorizontalFormInputComponent label="携帯番号" name="phoneNumber" />
          <FormNumberFromToComponent label="招待者数" name="inviteCount" />
          <FormNumberFromToComponent label="予約数" name="reserveCount" />
          <FormInlineRadioComponent
            label="アカウントロック状態"
            options={[
              { name: '全て', value: '0', default: true },
              { name: 'なし', value: '1', default: false },
              { name: 'ロック状態', value: '2', default: false },
            ]}
            name="isAccountLock"
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
const userSearchForm = reduxForm({
  // a unique name for the form
  form: 'userSearchForm',
  enableReinitialize: true,
})(UserSearchForm);

export default connect(
  (state) => {
    return {
      options: state.options,
      state: state.user,
      initialValues: {
        age: [],
        sex: [],
      },
    };
  },
  (dispatch) => {
    return {
      fetchPrefs() {
        dispatch(makeFetchPrefs());
      },
      fetchCompanyNames() {
        dispatch(makeFetchCompanyNames());
      },
      fetchCurrentStatuses() {
        dispatch(makeFetchCurrentStatuses());
      },
    };
  }
)(userSearchForm);

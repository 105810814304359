import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { makeSendAdminPasswordResetMail } from '../../../actions/AdminPasswordReset';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import SendButton from '../../molecules/SendButton';
import * as Validator from '../../utils/Validate';

class AdminPasswordResetMailInputForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      sendAdminPasswordResetMail: PropTypes.func,
    };
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          パスワード再設定を行いたいアカウントのメールアドレスを入力してください。
          <br></br>
          パスワード再設定用のURLを送信します。
        </div>
        <div>
          <HorizontalFormInputComponent
            label="メールアドレス"
            name="email"
            component="input"
            type="text"
            validate={[Validator.required, Validator.email]}
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SendButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const adminPasswordResetMailInputForm = reduxForm({
  // a unique name for the form
  form: 'adminPasswordResetMailInputForm',
  enableReinitialize: true,
})(AdminPasswordResetMailInputForm);

export default connect(
  (state) => {
    return {
      formState: state.form.adminPasswordReset,
    };
  },
  (dispatch) => {
    return {
      sendAdminPasswordResetMail(values) {
        dispatch(makeSendAdminPasswordResetMail(values));
      },
    };
  }
)(adminPasswordResetMailInputForm);

import { SummarySearch } from '../../../containers/Summary';

import routePath from './routePath';

const SummaryRoutes = [
  {
    path: routePath.summary,
    exact: true,
    component: SummarySearch,
  },
];

export default SummaryRoutes;

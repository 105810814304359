import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';

import FormDatePicker from '../atoms/FormDatePickerForForm';
import FormLabel from '../atoms/FormLabel';

class FormDateYmdInputComopnent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      dateName: PropTypes.string,
      onChange: PropTypes.func,
      startDate: PropTypes.any,
      defaultValue: PropTypes.any,
      onChangeDate: PropTypes.func,
      name: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);
    this.toDate = this.toDate.bind(this);

    this.component = this.component.bind(this);
    this.render = this.render.bind(this);

    let startDate;
    if (this.props.startDate) {
      startDate = this.props.startDate;
    } else {
      startDate = '';
    }

    this.state = {
      value: { date: startDate },
    };
  }

  toDate(str) {
    return new Date(str);
  }

  onChangeDate(field, date) {
    console.debug('date picker', date);
    const changeDate = {
      ...this.state.value,
      date: date,
    };

    this.setState({ value: changeDate });

    console.debug('field', field);
    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange(changeDate);
    }

    if (this.props.onChangeDate) {
      this.props.onChangeDate(changeDate);
    }
  }

  component(field) {
    return (
      <div className="form-group row">
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </FormLabel>
        <div className="col-sm-6">
          <Row>
            <Col>
              <FormDatePicker
                day={
                  field.input.value != null
                    ? field.input.value.date
                    : this.state.value.date
                }
                setDay={
                  field.input.value != null
                    ? field.input.value.date
                    : this.state.value.date
                }
                format="YYYY/MM/DD"
                locale="ja"
                onChange={this.onChangeDate.bind(this, field)}
                highlightWeekend
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    return <Field component={this.component} name={this.props.name} />;
  }
}

export default FormDateYmdInputComopnent;

import { string } from 'prop-types';
import React from 'react';

import FormReferItem from './FormReferItem';

class GoogleMapSearch extends React.Component {
  static get propTypes() {
    return {
      address: string,
    };
  }

  constructor(props) {
    super(props);

    this.onClickSearchGoogleMap = this.onClickSearchGoogleMap.bind(this);
  }

  onClickSearchGoogleMap(e) {
    const address = this.props.address;

    if (address) {
      window.open(`https://www.google.com/maps/place/${address}`);
    } else {
      window.open('https://www.google.co.jp/maps/?hl=ja');
    }

    e.preventDefault();
    return false;
  }

  render() {
    return (
      <FormReferItem label="">
        <a href="" onClick={this.onClickSearchGoogleMap}>
          Googleマップで検索
        </a>
      </FormReferItem>
    );
  }
}

export default GoogleMapSearch;

import { trackPromise } from 'react-promise-tracker';

import Action, { EmptyAction } from '../components/model/Action';
import Params from '../components/model/Params';

import createAxios from './axios';
import { fetchErrorData } from './common';
import { trackAsync } from './util';

const axios = createAxios('/admin/category');

const fetchCategoriesSuccess = (result) => {
  return {
    type: 'category/FETCH_SEARCH_SUCCESS',
    payload: {
      result: result,
    },
  };
};

const search = (values, searchParams) => {
  const params = new Params({
    name: values.name,
    pageSize: searchParams.pagination.pageSize,
    page: searchParams.pagination.page,
    sortColumn: searchParams.sort.sortColumn,
    sortType: searchParams.sort.sortType,
  });

  return axios('/admin/categories/search', {
    params: params.removeParams(),
  });
};

const storeSearchValues = (values, searchParams) => {
  console.debug(
    'STORE_SEARCH_VALUES = ',
    values,
    'searchParams = ',
    searchParams
  );
  return new Action('category/STORE_SEARCH_VALUES', {
    values,
    searchParams,
  });
};

const makeSearch = (values, searchParams) => {
  return (dispatch) => {
    dispatch(storeSearchValues(values, searchParams));
    return trackPromise(
      search(values, searchParams)
        .then((data) => dispatch(fetchCategoriesSuccess(data.data)))
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const hideLoading = () => {
  return new EmptyAction('tag/HIDE_LOADING');
};

const makeInitCategoryWithoutSearch = () => {
  return new EmptyAction('category/INIT_CATEGORY_WITHOUT_SEARCH');
};

const makeInitCategory = (isComeFromOtherPage) => {
  return (dispatch) => {
    dispatch(hideLoading());
    if (isComeFromOtherPage) {
      dispatch(new EmptyAction('category/INIT_CATEGORY'));
    } else {
      dispatch(makeInitCategoryWithoutSearch());
    }
  };
};

const confirmIsUsed = (categoryIds) => {
  return axios.get('/admin/categories/used', {
    params: {
      categoryIds: categoryIds,
    },
  });
};

const showDeleteConfirm = () => {
  return {
    type: 'category/SHOW_DELETE_CONFIRM',
  };
};

const confirmIsUsedSuccess = (data) => {
  if (data.containsUsed) {
    return new EmptyAction('category/SHOW_USED_CONTAINS_ALERT');
  } else {
    return showDeleteConfirm();
  }
};

const makeConfirmIsUsed = (categoryIds) => {
  return (dispatch) => {
    return trackAsync(async () => {
      try {
        const isUsedRes = await confirmIsUsed(categoryIds);
        dispatch(confirmIsUsedSuccess(isUsedRes.data));
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

const makeHideContainsAlertModal = () => {
  return new EmptyAction('category/HIDE_USED_CONTAINS_ALERT');
};

const makeHideDeleteConfirmModal = () => {
  return new EmptyAction('category/HIDE_DELETE_CONFIRM_MODAL');
};

const deleteCategories = (ids) => {
  return axios.delete('/admin/categories', {
    data: {
      categoryIds: ids,
    },
  });
};

// const deleteCategoriesSuccess = (data) => {
//   return {
//     type: 'tag/DELETE_TAGS_SUCCESS',
//     payload: {
//       result: data,
//     },
//   };
// };

const makeDeleteCategories = (ids) => {
  return (dispatch) => {
    return trackPromise(
      deleteCategories(ids)
        .then(() => {
          dispatch(makeHideDeleteConfirmModal());
          dispatch(makeShowDeleteCompleteModal());
        })
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const makeShowDeleteCompleteModal = () => {
  return new EmptyAction('category/SHOW_DELETE_COMPLETE_MODAL');
};

const makeHideDeleteCompleteModal = (searchValues) => {
  return (dispatch) => {
    if (searchValues && Object.keys(searchValues).length > 0) {
      search(searchValues.values, searchValues.searchParams)
        .then((data) => {
          dispatch(fetchCategoriesSuccess(data.data));
          dispatch(new EmptyAction('category/HIDE_DELETE_COMPLETE_MODAL'));
        })
        .catch((err) => {
          dispatch(fetchErrorData(err));
        });
    } else {
      dispatch(new EmptyAction('category/HIDE_DELETE_COMPLETE_MODAL'));
    }
  };
};

const fetchCategory = (id) => {
  return axios.get(`/admin/category/${id}`);
};

const fetchCategorySuccess = (data) => {
  return new Action('category/FETCH_CATEGORY_SUCCESS', data);
};

const makeFetchCategory = (tagId) => {
  return (dispatch) => {
    return trackPromise(
      fetchCategory(tagId)
        .then((data) => {
          dispatch(fetchCategorySuccess(data.data));
        })
        .catch((err) => dispatch(fetchErrorData(err)))
    );
  };
};

const makeSetEditValues = (values) => {
  return new Action('category/SET_EDIT_VALUES', values);
};

const makeBackToInput = () => {
  return new EmptyAction('category/GO_BACK_TO_INPUT');
};

const makeFetchCreateCategory = (params) => {
  return axios.post(`/admin/categories`, params);
};

const makeFetchUpdateCategory = (id, params) => {
  return axios.put(`/admin/category/${id}`, params);
};

const fetchCreateCategorySuccess = () => {
  return new EmptyAction('category/CREATE_CATEGORY_SUCCESS');
};

const fetchUpdateCategorySuccess = () => {
  return new EmptyAction('category/UPDATE_CATEGORY_SUCCESS');
};

const makeUpdateError = (err) => {
  if (err.response) {
    if (err.response.status === 400 && err.response.data) {
      return new Action('category/UPDATE_ERROR', err.response.data);
    }
  }

  return fetchErrorData(err);
};

const makeUpdateCategory = (values) => {
  return (dispatch) => {
    const params = new Params({
      id: values.id,
      name: values.name,
    }).removeParams();
    if (values.id == null) {
      return trackPromise(
        makeFetchCreateCategory(params)
          .then(() => {
            dispatch(fetchCreateCategorySuccess());
          })
          .catch((err) => dispatch(makeUpdateError(err)))
      );
    } else {
      return trackPromise(
        makeFetchUpdateCategory(values.id, params)
          .then(() => {
            dispatch(fetchUpdateCategorySuccess());
          })
          .catch((err) => dispatch(makeUpdateError(err)))
      );
    }
  };
};

const makeInitEdit = () => {
  return new EmptyAction('category/INIT_CATEGORY_EDIT');
};

const makeInitCategoryEdit = (categoryId) => {
  return (dispatch) => {
    return trackAsync(async () => {
      try {
        dispatch(makeInitEdit());

        if (categoryId) {
          const categoryData = await fetchCategory(categoryId);
          dispatch(fetchCategorySuccess(categoryData.data));
        }
      } catch (err) {
        dispatch(fetchErrorData(err));
      }
    });
  };
};

export {
  makeSearch,
  makeInitCategory,
  hideLoading,
  makeConfirmIsUsed,
  makeHideContainsAlertModal,
  makeHideDeleteConfirmModal,
  makeDeleteCategories,
  makeHideDeleteCompleteModal,
  makeFetchCategory,
  makeSetEditValues,
  makeBackToInput,
  makeUpdateCategory,
  makeInitCategoryEdit,
};

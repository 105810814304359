import defaultReducer from './DefaultReducers';

const initialState = {
  searchResults: {
    pagination: {
      page: 1,
      page_size: 10,
    },

    items: [],
  },
  categorySearch: {
    searchValues: {
      searchParams: {},
      values: {},
    },
    isDisplayUsedContainsAlertDialog: false,
    isShowDeleteCompleteModal: false,
    isConfirm: false,
  },
  categoryRefer: {
    detail: {
      name: '',
      romanCharName: '',
    },
  },
  categoryEdit: {
    isConfirm: false,
    isError: false,
    errorMessages: {},
  },
  isLoading: false,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'category/INIT_CATEGORY': {
      return {
        ...initialState,
      };
    }
    case 'category/INIT_CATEGORY_WITHOUT_SEARCH': {
      return {
        ...initialState,
        searchResults: {
          ...state.searchResults,
        },
        categorySearch: {
          ...state.categorySearch,
        },
      };
    }
    case 'category/FETCH_SEARCH_SUCCESS': {
      const searchResults = action.payload.result;
      return {
        ...state,
        searchResults: {
          items: searchResults.result,
          searchParams: searchResults.searchParams,
        },
      };
    }
    // case 'tag/HIDE_LOADING': {
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };
    // }
    case 'category/SHOW_USED_CONTAINS_ALERT': {
      return {
        ...state,
        categorySearch: {
          ...state.categorySearch,
          isDisplayUsedContainsAlertDialog: true,
        },
      };
    }
    case 'category/SHOW_DELETE_CONFIRM': {
      return {
        ...state,
        categorySearch: {
          ...state.categorySearch,
          isConfirm: true,
        },
      };
    }
    case 'category/HIDE_USED_CONTAINS_ALERT': {
      return {
        ...state,
        categorySearch: {
          ...state.categorySearch,
          isDisplayUsedContainsAlertDialog: false,
        },
      };
    }
    case 'category/HIDE_DELETE_CONFIRM_MODAL': {
      return {
        ...state,
        categorySearch: {
          ...state.categorySearch,
          isConfirm: false,
        },
      };
    }
    case 'category/STORE_SEARCH_VALUES': {
      return {
        ...state,
        categorySearch: {
          ...state.categorySearch,
          searchValues: action.payload,
        },
      };
    }
    case 'category/SHOW_DELETE_COMPLETE_MODAL': {
      return {
        ...state,
        categorySearch: {
          ...state.categorySearch,
          isShowDeleteCompleteModal: true,
        },
      };
    }
    case 'category/HIDE_DELETE_COMPLETE_MODAL': {
      return {
        ...state,
        categorySearch: {
          ...state.categorySearch,
          isShowDeleteCompleteModal: false,
        },
      };
    }
    case 'category/FETCH_CATEGORY_SUCCESS': {
      const results = action.payload;
      return {
        ...state,
        categoryRefer: {
          ...state.categoryRefer,
          detail: results,
        },
      };
    }
    case 'category/SET_EDIT_VALUES': {
      const values = action.payload;
      return {
        ...state,
        categoryRefer: {
          ...state.categoryRefer,
          detail: {
            name: values.name,
          },
        },
        categoryEdit: {
          ...state.categoryEdit,
          isConfirm: true,
          isError: false,
          errorMessages: {},
        },
      };
    }
    case 'category/GO_BACK_TO_INPUT': {
      return {
        ...state,
        categoryEdit: {
          ...state.categoryEdit,
          isConfirm: false,
        },
      };
    }
    case 'category/UPDATE_CATEGORY_SUCCESS': {
      return {
        ...state,
        categoryEdit: {
          ...state.categoryEdit,
          isComplete: true,
        },
      };
    }
    case 'category/CREATE_CATEGORY_SUCCESS': {
      return {
        ...state,
        categoryEdit: {
          ...state.categoryEdit,
          isComplete: true,
        },
      };
    }
    case 'category/INIT_CATEGORY_EDIT': {
      return {
        ...state,
        categoryRefer: {
          ...state.categoryRefer,
          detail: {},
        },
        categoryEdit: {
          ...initialState.categoryEdit,
        },
      };
    }
    case 'category/UPDATE_ERROR': {
      const result = action.payload;
      return {
        ...state,
        categoryEdit: {
          errorMessages: result.messages,
          isConfirm: false,
          isError: true,
        },
      };
    }
    default:
      return defaultReducer(action, state);
  }
};

export { categoryReducer as default };

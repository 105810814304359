import { array, func, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeFetchMenus, transitionToDefaultPage } from '../../actions/SideBar';

class SideBar extends React.Component {
  static get propTypes() {
    return {
      state: shape({
        menus: array,
      }),
      user: shape({
        loginMenuPath: string,
      }),
      fetchMenus: func,
      transitionToDefaultPage: func,
    };
  }

  constructor(props) {
    super(props);
    this.onClickTopIcon = this.onClickTopIcon.bind(this);
  }

  componentDidMount() {
    this.props.fetchMenus();
  }

  onClickTopIcon(e) {
    e.preventDefault();

    this.props.transitionToDefaultPage(this.props.user.loginMenuPath);
  }

  render() {
    return (
      <div className="main-sidebar">
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <a href="/#" onClick={this.onClickTopIcon}>
              予約管理システム
              {process.env.REACT_APP_HOST ? process.env.REACT_APP_HOST : <></>}
            </a>
          </div>
          <ul className="sidebar-menu">
            {this.props.state.menus.map((menu, index) => {
              return (
                <li key={index}>
                  <Link to={menu.url} className="nav-link">
                    {menu.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </aside>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      state: state.sidebar,
      user: state.navbar.loginUser,
    };
  },
  (dispatch) => {
    return {
      fetchMenus() {
        dispatch(makeFetchMenus());
      },
      transitionToDefaultPage(loginMenuPath) {
        dispatch(transitionToDefaultPage(loginMenuPath));
      },
    };
  }
)(SideBar);

import PropTypes from 'prop-types';
import React from 'react';

import LinkButton from '../atoms/LinkButton';

class LoginLinkButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      href: PropTypes.string,
    };
  }

  render() {
    return <LinkButton href={this.props.href}>ログインへ</LinkButton>;
  }
}

export default LoginLinkButton;

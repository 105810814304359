export default {
  search: `/admin/interviews`,
  /**
   * 参照画面
   * @param {string} interviewId 個別面談ID
   * @param {string} interviewerId 講師ID
   * @param {string} selectedDate
   */
  referByDetail: (interviewId, interviewerId, selectedDate) => {
    return `/admin/interviews/${interviewId}?user_id=${interviewerId}&selected_date=${selectedDate}`;
  },
  /**
   * @param {string} interviewerId 講師ID
   */
  bulkRegister: `/admin/interviews/bulk_register`,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import CancelButton from '../../molecules/CancelButton';
import ConfirmButton from '../../molecules/ConfirmButton';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import * as Validator from '../../utils/Validate';

class EditForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      fetchStaff: PropTypes.func,
      fetchBases: PropTypes.func,
      fetchOccupations: PropTypes.func,
      fetchDivisions: PropTypes.func,
      fetchRoles: PropTypes.func,
      options: PropTypes.shape({
        bases: PropTypes.array,
        divisions: PropTypes.array,
        occupations: PropTypes.array,
        roles: PropTypes.array,
      }),
      onPrevButtonClick: PropTypes.func,
      invalid: PropTypes.bool,
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      isNew: PropTypes.func,
      formState: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
    };
  }

  componentDidMount() {}
  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent
            label="名前"
            name="name"
            component="input"
            type="text"
            validate={[Validator.required]}
            messages={
              this.props.isError
                ? this.props.messages.name
                  ? this.props.messages.name
                  : []
                : []
            }
          />
          <HorizontalFormInputComponent
            label="ローマ字名"
            name="romanCharName"
            component="input"
            type="text"
            validate={[Validator.required]}
            messages={
              this.props.isError
                ? this.props.messages.romanCharName
                  ? this.props.messages.romanCharName
                  : []
                : []
            }
          />
          <HorizontalFormInputComponent
            label="表示順序"
            name="displayOrder"
            component="input"
            type="text"
            validate={[Validator.required, Validator.number]}
            messages={
              this.props.isError
                ? this.props.messages.displayOrder
                  ? this.props.messages.displayOrder
                  : []
                : []
            }
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <CancelButton onClick={this.props.onPrevButtonClick} />
              <ConfirmButton
                disabled={this.props.invalid || this.props.submitting}
                isNew={this.props.isNew}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const tagEditForm = reduxForm({
  // a unique name for the form
  form: 'tagEditForm',
  enableReinitialize: true,
})(EditForm);

export default connect(
  (state) => {
    return {
      initialValues: {
        ...state.tag.tagRefer.detail,
      },
      isNewData: state.tag.tagEdit.isNewData,
      options: state.options,
    };
  },
  (dispatch) => {
    return {};
  }
)(tagEditForm);

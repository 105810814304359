import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SortIconTag from '../atoms/SortIconTag';

class SearchResultTable extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      searchResults: PropTypes.any.isRequired,
      headerItems: PropTypes.array.isRequired,
      getSearchItem: PropTypes.func,
      search: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.sort = this.sort.bind(this);
  }

  sort(column) {
    console.debug('column: ', column);

    const activeSortColumn = this.props.searchResults.searchParams.sort
      .sortColumn;
    const activeSortType = this.props.searchResults.searchParams.sort.sortType;

    let sortType = 'asc';
    if (activeSortColumn === column && activeSortType === 'asc') {
      sortType = 'desc';
    }

    this.props.search({
      pagination: this.props.searchResults.searchParams.pagination,
      sort: { sortColumn: column, sortType: sortType },
    });
  }

  render() {
    const getSortStatusTag = (column) => {
      const activeSortColumn = this.props.searchResults.searchParams.sort
        .sortColumn;

      if (activeSortColumn !== column) {
        return <SortIconTag className="text-secondary fas fa-sort" />;
      }

      const activeSortType = this.props.searchResults.searchParams.sort
        .sortType;
      if (activeSortType === 'asc') {
        return <SortIconTag className="text-primary fas fa-sort-up" />;
      } else {
        return <SortIconTag className="text-primary fas fa-sort-down" />;
      }
    };

    return (
      <TableResponsive>
        <table className="table table-hover jambo_table">
          <thead>
            <tr>
              {this.props.headerItems.map((headerItem, i) => {
                return (
                  <MinWidthTh className="column-title" scope="col" key={i}>
                    {headerItem.name !== null &&
                    headerItem.name !== undefined ? (
                      <span onClick={this.sort.bind(this, headerItem.name)}>
                        {headerItem.value}
                        {getSortStatusTag(headerItem.name)}
                      </span>
                    ) : (
                      <></>
                    )}
                  </MinWidthTh>
                );
              })}
            </tr>
          </thead>
          <tbody>{this.props.getSearchItem()}</tbody>
        </table>
      </TableResponsive>
    );
  }
}

const MinWidthTh = styled.th`
  min-width: 120px;
`;

class TableResponsive extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.element,
    };
  }

  render() {
    return <div className="table-responsive">{this.props.children}</div>;
  }
}

export default SearchResultTable;

import React from 'react';
import {Field, FieldArray, formValueSelector, reduxForm} from 'redux-form';
import FormInlineRadioComponent from "../../molecules/FormInlineRadioComponentForForm";
import HorizontalFormInputComponent from "../../molecules/HorizontalFormInputComponent";
import { Row, Col } from 'react-bootstrap';
import Option from "../../model/Option";
import HorizontalSelectComponent from "../../molecules/HorizontalSelectComponent";
import RegisterButton from "../../molecules/RegisterButton";

import {
  makeFetchFormById,
  makeFetchMessageTemplates,
  makeFetchParts,
  makeFetchSfItems,
  makeFetchPartsForThanksPage
} from "../../../actions/FormManagement";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import * as Validator from '../../utils/Validate';
import FormInlineRequiredCheckBoxesComponent
  from "../../molecules/formsManagement/FormInlineRequiredCheckBoxesComponent";
import InfoFormButton from "../../atoms/InfoFormButton";
import PrevButton from "../../molecules/PrevButton";
import HorizontalTextComponent from "../../molecules/HorizontalTextComponent";
import HorizontalFormTextAreaComponent from "../../molecules/HorizontalFormTextareaComponent";

const selector = formValueSelector('fieldArrays')

const required = Validator.required
const number = Validator.number

/**
 * ラジオボタン/プルダウンの値
 * @param fields
 * @param error
 * @returns {JSX.Element}
 */
const renderPartsValue = ({ fields, meta: {error} }) => (
  <ul>
    {fields.map((items, index) => (
      <PartsValue items={items} fields={fields} index={index} />
    ))}
    <li style={{'list-style-type': 'none'}}>
      <InfoFormButton
        onClick={() => fields.push({value: ""})}
      >
        値の追加
      </InfoFormButton>
    </li>
    {error && <li className="error">{error}</li>}
  </ul>
)

let PartsValue = ({items, index, fields}) => (
  <div key={index} className={"list-group-item"}>
    {
      index != 0
      &&
      <div  className="text-right">
        <button className="btn btn-icon btn-danger" type="button" title="Remove Hobby" onClick={() => fields.remove(index)} >
          <i className="fas fa-times"></i>
        </button>
      </div>
    }
    <HorizontalFormInputComponent
      label={"値" + (index + 1)}
      name={`${items}.value`}
      component="input"
      type="text"
      validate={[required]}
    />
  </div>
)

/**
 * パーツ
 * @param fields
 * @param error
 * @returns {JSX.Element}
 */
const renderParts = ({ fields, meta: {error} }) => (
  <ul>
    {fields.map((parts, index) => (
      <Parts parts={parts} fields={fields} index={index} isThanksPageParts={fields && fields.name.match(/actions/)} />
    ))}
    <li style={{'list-style-type': 'none'}}>
      <InfoFormButton
        onClick={() => {fields.push({type: "7", items:[{value: ""}]});return false;}}
      >
        パーツの追加
      </InfoFormButton>
    </li>
    {error && <li className="error">{error}</li>}
  </ul>
)

const PARTS_TYPE_INPUT = 1
const PARTS_TYPE_TEXTAREA = 2
const PARTS_TYPE_SELECT = 3
const PARTS_TYPE_CHECKBOX = 4
const PARTS_TYPE_RADIO = 5
const PARTS_TYPE_HTML = 7
const PARTS_TYPE_EVENT = 200
let Parts = ({parts, index, fields, optionPartsTypes, optionPartsTypeForThanksPage, optionSfItems, isDefault, partsType, isThanksPageParts, partsTypeName}) => (
  <li key={index} className={"card"}>
    <div className={"card-body"} style={{'backgroundColor':'#0'}}>
      {
        !isDefault
        &&
        <div className="text-right">
          <button className="btn btn-icon btn-danger" type="button" title="Remove Hobby" onClick={() => fields.remove(index)} ><i className="fas fa-times"></i></button>
        </div>
      }
      <Row>
        <Col md={12}>
          {
            !isDefault
            &&
            <HorizontalSelectComponent
            label="パーツ種別"
            options={ isThanksPageParts ? optionPartsTypeForThanksPage: optionPartsTypes}
            name={`${parts}.type`}
            validate={[required]}
            />
          }
          {
            isDefault
            &&
            <>
              <HorizontalTextComponent
                label="パーツ種別"
                text={partsTypeName}
              />
              <Field
                component="input"
                name={`${parts}.type`}
                type="hidden"
              />
            </>
          }
        </Col>
        {partsType <= PARTS_TYPE_RADIO &&
        <Col md={12}>
          <HorizontalFormInputComponent
            label="ラベル"
            name={`${parts}.label`}
            component="input"
            type="text"
            validate={[required]}
          />
        </Col>
        }

        {
          (partsType === PARTS_TYPE_SELECT || partsType === PARTS_TYPE_CHECKBOX || partsType === PARTS_TYPE_RADIO)
          &&
          <Col md={12}>
            <FieldArray name={`${parts}.items`} component={renderPartsValue} />
          </Col>
        }

        {
          (partsType === PARTS_TYPE_HTML)
          &&
          <Col md={12}>
            <HorizontalFormTextAreaComponent
              label="値"
              name={`${parts}.value`}
              component="input"
              type="text"
              validate={[required]}
            />
          </Col>
        }

        {
          (partsType === PARTS_TYPE_INPUT || partsType === PARTS_TYPE_TEXTAREA)
          &&
          <>
            <Col md={12}>
              <HorizontalFormInputComponent
                label="プレースホルダー(入力例)"
                name={`${parts}.sample`}
                component="input"
                type="text"
              />
            </Col>
            <Col md={12}>
              <HorizontalFormInputComponent
                label="最小文字数"
                name={`${parts}.validationMin`}
                component="input"
                type="text"
                validate={[number]}
              />
              <HorizontalFormInputComponent
                label="最大文字数"
                name={`${parts}.validationMax`}
                component="input"
                type="text"
                validate={[number]}
              />
            </Col>
          </>

        }

        {
          !(partsType === PARTS_TYPE_HTML || partsType === PARTS_TYPE_EVENT)
          &&
          <Col md={12}>
            <FormInlineRequiredCheckBoxesComponent
              label="必須"
              name={`${parts}.required`}
            />
          </Col>
        }

        {
          partsType <= PARTS_TYPE_RADIO
          &&
          <Col md={12}>
            <HorizontalSelectComponent
              label="SFコード"
              options={optionSfItems}
              name={`${parts}.sfMappingId`}
              validate={[required]}
            />
          </Col>
        }

        <Field
          component="input"
          name={`${parts}.defaultFlag`}
          type="hidden"
        />
      </Row>
    </div>

  </li>
)

Parts = connect(
  (state, props) => {
    const optionPartsTypes = state.formManagement.parts;
    const selectedPartsType = parseInt(selector(state, `${props.parts}.type`), 10);

    let partsTypeName = ""
    if (optionPartsTypes) {
      const samePartsTypes = optionPartsTypes.filter(type => type.value === selectedPartsType)
      if (samePartsTypes.length > 0) {
        partsTypeName = samePartsTypes[0].name
      }
    }

    return {
      optionPartsTypes: optionPartsTypes,
      optionPartsTypeForThanksPage: state.formManagement.partsForThanksPages,
      optionSfItems: state.options.sfItems,
      isDefault: !!selector(state, `${props.parts}.defaultFlag`),
      partsType: selectedPartsType,
      partsTypeName: partsTypeName
    }}
)(Parts)

const renderForms = ({ fields, meta: {error, submitFailed } }) => (
  <ul>
    {fields.map((section, index) => (
      <>
        <Section section={section} fields={fields} index={index} isBottomSection={fields.length == (index + 1)} />
      </>
    ))}
    <div style={{'marginBottom': '10px'}}>
      <InfoFormButton onClick={() => fields.push({sectionType: 1, parts: [{type: "1"}]})}>セクションの追加</InfoFormButton>
      {submitFailed && error && <span>{error}</span>}
    </div>
  </ul>
);

let Section = ({section, index, fields, isBottomSection, hasParts}) => (
  <div key={index} className={"list-group-item"} style={{'backgroundColor':'#e3eaef', 'marginBottom': '10px'}}>
    {
      !hasParts
      &&
        <div  className="text-right">
          <button className="btn btn-icon btn-danger" type="button" title="Remove Hobby" onClick={() => fields.remove(index)} >
            <i className="fas fa-times"></i>
          </button>
        </div>
    }
    <HorizontalFormInputComponent
      label="セクション名"
      name={`${section}.sectionName`}
      component="input"
      type="text"
      validate={[required]}
    />
    <Field
      component="input"
      name={`${section}.sectionType`}
      type="hidden"
      value={1}
    />
    <FieldArray name={`${section}.parts`} component={renderParts} />
    {
      isBottomSection &&
      <div className={"card"} style={{'backgroundColor':'#0', 'marginTop': '10px'}}>
        <div className={"card-body"}>
          送信ボタンパーツ
        </div>
      </div>
    }
  </div>
)
Section = connect(
  (state, props) => {
    return {
      hasParts: !!selector(state, `${props.section}.parts`),
    }}
)(Section)

const renderConditions = ({ fields, meta: {error} }) => (
  <ul>
    {fields.map((condition, index) => (
      <Condition condition={condition} fields={fields} index={index}  />
    ))}
    <li style={{'list-style-type': 'none'}}>
      <InfoFormButton onClick={() => fields.push({})}>
        条件の追加
      </InfoFormButton>
    </li>
  </ul>
);
let Condition = ({condition, index, fields, optionSfItems}) => (
  <li key={{index}} style={{'backgroundColor':'#0', 'list-style-type': 'none'}}>
    <Row>
      <Col md={4}>
        <HorizontalSelectComponent
          label="項目"
          options={optionSfItems}
          name={`${condition}.item`}
          validate={[required]}
        />
      </Col>
      <Col>
        <HorizontalSelectComponent
          label=""
          options={[new Option('=', '='), new Option('!=', '!='), new Option('<', '<'), new Option('>', '>')]}
          name={`${condition}.symbol`}
          validate={[required]}
        />
      </Col>
      <Col md={4}>
        <HorizontalFormInputComponent
          label="値"
          component="input"
          type="text"
          name={`${condition}.value`}
          validate={[required]}
        />
      </Col>
      {
        index !== 0
        &&
        <Col md={1}>
          <button className="btn btn-icon btn-danger" type="button" title="Remove Hobby" onClick={() => fields.remove(index)} ><i className="fas fa-times"></i></button>
        </Col>
      }
    </Row>
  </li>
)
Condition = connect(
  (state, props) => {
    return {
      optionSfItems: state.options.sfItems,
    }}
)(Condition)

const renderActions = ({ fields, meta: {error, submitFailed } }) => (
  <ul>
    {fields.map((action, index) => (
      <Action action={action} fields={fields} index={index} />
    ))}
    <li style={{'list-style-type': 'none', 'marginBottom': '10px'}}>
      <InfoFormButton onClick={() => fields.push({type:"2", conditions:[{}], parts: [{type: String(PARTS_TYPE_HTML), defaultFlag: true}]})}>アクションの追加</InfoFormButton>
      {submitFailed && error && <span>{error}</span>}
    </li>
  </ul>
);

let Action = ({action, index, fields, defaultFlag, actionType}) => (
  <li key={index} className={"list-group-item"} style={{'backgroundColor':'#e3eaef', 'marginBottom': '10px'}}>
    {
      !defaultFlag
      &&
      <>
        <div className="text-right" style={{'marginBottom': '10px'}}>
          <button className="btn btn-icon btn-danger" type="button" title="Remove Hobby" onClick={() => fields.remove(index)} >
            <i className="fas fa-times"></i>
          </button>
        </div>
        <FieldArray name={`${action}.conditions`} component={renderConditions} />
      </>
    }
    <FormInlineRadioComponent
      label="アクションタイプ"
      options={[
        new Option('1', 'サンクスページ'),
        new Option('2', 'URL'),
      ]}
      name={`${action}.type`}
      validate={[required]}
    />

    { actionType == 1 &&
      <FieldArray name={`${action}.parts`} component={renderParts} />
    }
    { actionType == 2 &&
      <HorizontalFormInputComponent
        label="URL"
        name={`${action}.url`}
        component="input"
        type="text"
        validate={[required]}
      />
    }
    <Field
      component="input"
      name={`${action}.defaultFlag`}
      type="hidden"
    />
  </li>
)

Action = connect(
  (state, props) => {
    return {
      defaultFlag: !!selector(state, `${props.action}.defaultFlag`),
      actionType: selector(state, `${props.action}.type`)
    }}
)(Action)

const renderTemplates = ({ fields, meta: {error, submitFailed } }) => (
  <ul>
    {fields.map((template, index) => (
      <MessageTemplate notification={template} fields={fields} index={index} />
    ))}
    <li style={{'list-style-type': 'none', 'marginBottom': '10px'}}>
      <InfoFormButton onClick={() => fields.push({conditions:[{}]})}>通知テンプレートの追加</InfoFormButton>
      {submitFailed && error && <span>{error}</span>}
    </li>
  </ul>
);

let MessageTemplate = ({notification, index, fields, optionMessageTemplates, defaultFlag}) => (
  <li key={index} className={"list-group-item"} style={{'backgroundColor':'#e3eaef', 'marginBottom': '10px'}}>
    {!defaultFlag &&
      <>
        <div className="text-right" style={{'marginBottom': '10px'}}>
          <button className="btn btn-icon btn-danger" type="button" title="Remove Hobby" onClick={() => fields.remove(index)} >
            <i className="fas fa-times"></i>
          </button>
        </div>
        <FieldArray name={`${notification}.conditions`} component={renderConditions} />
      </>
    }

    <HorizontalSelectComponent
      label="通知テンプレート"
      options={optionMessageTemplates}
      name={`${notification}.messageTemplateId`}
      validate={[required]}
    />

    <Field
      component="input"
      name={`${notification}.defaultFlag`}
      type="hidden"
      value={true}
    />
  </li>
)

MessageTemplate = connect(
  (state, props) => {
    return {
      optionMessageTemplates: state.formManagement.messageTemplates,
      defaultFlag: !!selector(state, `${props.notification}.defaultFlag`),
    }}
)(MessageTemplate)

class FormFieldArrayForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      fetchSfItems: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.transitionToSearch = this.transitionToSearch.bind(this);

    this.props.fetchSfItems();
    this.props.fetchParts();
    this.props.fetchPartsForThanksPage();
    this.props.fetchMessageTemplate();
  }

  transitionToSearch() {
    this.props.transitionToSearch();
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Row>
          <Col>
            <div>
              <PrevButton onClick={this.transitionToSearch} />
            </div>
            <div className="text-right">
              {/*<PrimaryButton>プレビュー</PrimaryButton>*/}
              <RegisterButton />
            </div>
          </Col>
        </Row>
        <HorizontalFormInputComponent
          label="フォーム名"
          name="name"
          component="input"
          type="text"
          validate={[required]}
        />
        <FormInlineRadioComponent
          label="セクション表示タイプ"
          options={[
            { name: 'パーセンテージ', value: 1, default: true },
            { name: 'タブ', value: 2},
            { name: 'なし', value: 3},
          ]}
          name="sectionType"
          validate={[required]}
        />

        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active show"
              id="home-tab"
              data-toggle="tab"
              href="#createForm"
              role="tab"
              aria-controls="createForm"
              aria-selected="true"
            >
              1.フォーム作成
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#createAction"
              role="tab"
              aria-controls="createAction"
              aria-selected="false"
            >
              2.登録後アクション
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="contact-tab"
              data-toggle="tab"
              href="#createContact"
              role="tab"
              aria-controls="createContact"
              aria-selected="false"
            >
              3.通知設定
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade active show"
            id="createForm"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <FieldArray name="sections" component={renderForms}/>
          </div>
          <div
            className="tab-pane fade"
            id="createAction"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <FieldArray name="actions" component={renderActions}/>
          </div>
          <div
            className="tab-pane fade active"
            id="createContact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <FieldArray name="notifications" component={renderTemplates}/>
          </div>
        </div>
      </form>
    )
  }
}

// export default Search;
const formsEditForm = reduxForm({
  // a unique name for the form
  form: 'fieldArrays',
  enableReinitialize: true,
})(FormFieldArrayForm);

export default connect(
  (state) => {
    console.debug('formsEditForm: ', state);
    return {
      initialValues: state.formManagement.test,
      test: state.formManagement.test,
      options: state.options,
      parts: state.formManagement.parts,
      partsForThanksPages: state.formManagement.parts,
      messageTemplates: state.formManagement.messageTemplates,

      hasSections: selector(state, 'sections') != null && selector(state, 'sections').length !== 0,
      hasActions: selector(state, 'actions') != null && selector(state, 'actions').length !== 0,
      hasTemplates: selector(state, 'notifications') != null && selector(state, 'notifications').length !== 0
    };
  },
  (dispatch) => {
    return {
      fetchForm(id) {
        dispatch(makeFetchFormById(id));
      },
      fetchSfItems() {
        dispatch(makeFetchSfItems());
      },
      fetchParts() {
        dispatch(makeFetchParts());
      },
      fetchPartsForThanksPage() {
        dispatch(makeFetchPartsForThanksPage());
      },
      fetchMessageTemplate() {
        dispatch(makeFetchMessageTemplates());
      }
    };
  }
)(formsEditForm);




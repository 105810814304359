import { connect } from 'react-redux';

import {
  makeFetchMenus,
  makeSearch,
  makeFetchRole,
  makeFetchRoles,
  makeUpdateRole,
  makeDeleteRole,
  makeCopyRole,
  makeFetchRolePermission,
  makeFetchEditInitData,
  setEditValues,
  newRole,
  showDeleteConfirm,
  closeDeleteConfirm,
  showDeleteAlert,
  closeDeleteAlert,
  closeDeleteComplete,
  showCopyConfirm,
  closeCopyConfirm,
  closeCopyComplete,
  makeFetchRoleForRefer,
  initState,
} from '../actions/RoleManagement';
import RoleManagementComponent from '../components/pages/roleManagement/RoleManagement';
import RoleManagementEditComponent from '../components/pages/roleManagement/RoleManagementEdit';
import RoleManagementReferComponent from '../components/pages/roleManagement/RoleManagementRefer';
import RoleManagementSearchComponent from '../components/pages/roleManagement/RoleManagementSearch';

const mapStateToProps = (state) => {
  console.log('mapStateToProps:', state);
  return {
    state: state.roleManagement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMenus() {
      dispatch(makeFetchMenus());
    },
    search(values, searchParams) {
      dispatch(makeSearch(values, searchParams));
    },
    fetchRole(id) {
      dispatch(makeFetchRole(id));
    },
    fetchRoles(ids) {
      dispatch(makeFetchRoles(ids));
    },
    fetchRolePermissions() {
      dispatch(makeFetchRolePermission());
    },
    fetchEditInitData(id) {
      dispatch(makeFetchEditInitData(id));
    },
    updateRole(values) {
      dispatch(makeUpdateRole(values));
    },
    deleteRole(id) {
      dispatch(makeDeleteRole(id));
    },
    copyRole(id) {
      dispatch(makeCopyRole(id));
    },
    newRole() {
      dispatch(newRole());
    },
    showDeleteConfirm(ids) {
      dispatch(showDeleteConfirm(ids));
    },
    setEditValues(values) {
      dispatch(setEditValues(values));
    },
    closeDeleteConfirm() {
      dispatch(closeDeleteConfirm());
    },
    showDeleteAlert() {
      dispatch(showDeleteAlert());
    },
    closeDeleteAlert() {
      dispatch(closeDeleteAlert());
    },
    closeDeleteComplete() {
      dispatch(closeDeleteComplete());
    },
    showCopyConfirm() {
      dispatch(showCopyConfirm());
    },
    closeCopyConfirm() {
      dispatch(closeCopyConfirm());
    },
    closeCopyComplete() {
      dispatch(closeCopyComplete());
    },
    initState() {
      dispatch(initState());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleManagementComponent);

const RoleManagementSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleManagementSearchComponent);
export { RoleManagementSearch };

const RoleManagementRefer = connect(mapStateToProps, (dispatch) => {
  return {
    ...mapDispatchToProps(dispatch),
    fetchRoleItemForRefer(id) {
      dispatch(makeFetchRoleForRefer(id));
    },
  };
})(RoleManagementReferComponent);
export { RoleManagementRefer };

const RoleManagementEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleManagementEditComponent);

export { RoleManagementEdit };

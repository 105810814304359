import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import {
  makeFetchTemplateCategories,
  initState,
  makeFetchContactTypes,
} from '../../../actions/Template';
import Option from '../../model/Option';
import FormInlineCheckBoxes from '../../molecules/FormInlineCheckBoxesComponent';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';

class TemplateSearchForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
      fetchTemplate: PropTypes.func,
      fetchTemplateCategories: PropTypes.func,
      fetchContactTypes: PropTypes.func,
      initState: PropTypes.func,
      options: PropTypes.shape({
        templateCategories: PropTypes.array,
      }),
      optionContactTypes: PropTypes.array,
    };
  }

  componentDidMount() {
    this.props.initState();
    this.props.fetchTemplateCategories();
    this.props.fetchContactTypes();
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalSelectComponent
            label="カテゴリ"
            options={this.props.options.templateCategories}
            name="categoryId"
          />
          <HorizontalFormInputComponent
            label="テンプレート名"
            name="templateName"
            component="input"
            type="text"
          />
          <HorizontalFormInputComponent
            label="カスタム名"
            name="name"
            component="input"
            type="text"
          />
          <HorizontalFormInputComponent
            label="グループ名"
            name="groupName"
            component="input"
            type="text"
          />
          <FormInlineCheckBoxes
            label="デフォルト"
            options={[new Option('true', 'デフォルトのみ')]}
            name="defaultFlag"
          />
          <FormInlineCheckBoxes
            label="通知媒体"
            options={this.props.optionContactTypes}
            name="contactTools"
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const templateSearchForm = reduxForm({
  // a unique name for the form
  form: 'templateSearchForm',
  enableReinitialize: true,
})(TemplateSearchForm);

export default connect(
  (state) => {
    return {
      options: state.options,
      state: state.template,
      initialValues: {
        defaultFlag: [],
        contactTools: [],
        ...state.template.searchParams.values,
      },
      optionContactTypes: state.template.contactTypes.map(
        (type) => new Option(type.value, type.label)
      ),
    };
  },
  (dispatch) => {
    return {
      initState() {
        dispatch(initState());
      },
      fetchTemplateCategories() {
        dispatch(makeFetchTemplateCategories());
      },
      fetchContactTypes() {
        dispatch(makeFetchContactTypes());
      },
    };
  }
)(templateSearchForm);

import PropTypes from 'prop-types';
import React from 'react';

import PrimaryFormButton from '../atoms/PrimaryFormButton';

class SendButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    };
  }

  render() {
    return (
      <PrimaryFormButton
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        送信
      </PrimaryFormButton>
    );
  }
}

export default SendButton;

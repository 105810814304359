import PropTypes from 'prop-types';
import React from 'react';

class FullyOccupied extends React.Component {
  static get propTypes() {
    return {
      height: PropTypes.string,
      width: PropTypes.string,
    };
  }

  render() {
    return (
      <div
        style={{
          width: this.props.width,
          height: this.props.height,
          borderRadius: '0.5em',
          padding: '7px',
          display: 'inline-block',
          backgroundColor: '#fc544b',
          borderColor: '#fc544b',
          color: '#fff',
          fontSize: '12px',
          lineHeight: '1',
        }}
      >
        満
      </div>
    );
  }
}

export default FullyOccupied;

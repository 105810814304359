import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';

import LeggendColorBox from '../atoms/LeggendColorBox';
import LeggendLabel from '../atoms/LeggendLabel';

class LeggendComponent extends React.Component {
  static get propTypes() {
    return {
      leggends: PropTypes.any,
    };
  }

  render() {
    return (
      <Row>
        <div className="col-3">
          {this.props.leggends.map((leggend) => {
            return (
              <div className="form-check-inline" key={leggend.label}>
                <LeggendColorBox
                  color={leggend.color}
                  height="1em"
                  width="1em"
                />
                <LeggendLabel>{leggend.label}</LeggendLabel>
              </div>
            );
          })}
        </div>
      </Row>
    );
  }
}

export default LeggendComponent;

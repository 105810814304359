import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { Field } from 'redux-form';
import styled from 'styled-components';

import ColAlignLeft from '../atoms/ColAlignLeft';
import FormDatePicker from '../atoms/FormDatePickerForForm';
import FormHour from '../atoms/FormHour';
import FormLabel from '../atoms/FormLabel';
import FormMinute from '../atoms/FormMinute';
import * as Validator from '../utils/Validate';

class FormPeriodDateTimeComponent extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      startLabel: PropTypes.string,
      endLabel: PropTypes.string,
      startDateName: PropTypes.string,
      startHourName: PropTypes.string,
      startMinuteName: PropTypes.string,
      endDateName: PropTypes.string,
      endHourName: PropTypes.string,
      endMinuteName: PropTypes.string,
      startDate: PropTypes.any,
      startHour: PropTypes.number,
      startMinute: PropTypes.number,
      endDate: PropTypes.string,
      endHour: PropTypes.number,
      endMinute: PropTypes.number,
      defaultValue: PropTypes.number,
      isRequired: PropTypes.bool,

      onChangeStartDateTime: PropTypes.func,
      onChangeEndDateTime: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.toDate = this.toDate.bind(this);

    let startDate;
    if (this.props.startDate) {
      startDate = this.props.startDate;
    } else {
      startDate = '';
    }
    this.state = {
      start: { date: startDate, hour: '', minute: '' },
      end: { date: '', hour: '', minute: '' },
    };

    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeStartHour = this.onChangeStartHour.bind(this);
    this.onChangeStartMinute = this.onChangeStartMinute.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onChangeEndHour = this.onChangeEndHour.bind(this);
    this.onChangeEndMinute = this.onChangeEndMinute.bind(this);
    this.component = this.component.bind(this);
    this.isRequired = this.isRequired.bind(this);
  }

  toDate(str) {
    return new Date(str);
  }

  onChangeStartDate(field, date) {
    console.debug('date picker', date);
    const timeHourMinute = {
      ...this.state.start,
      date: date,
    };

    this.setState({ start: timeHourMinute });

    console.debug('field', field);
    if (field && field.input) {
      console.debug('this.field', field);
      let newValue = { ...field.input.value, from: timeHourMinute };

      if (
        !Object.hasOwnProperty.call(field.input.value, 'to') ||
        !Object.hasOwnProperty.call(field.input.value.to, 'date') ||
        field.input.value.to.date == null
      ) {
        this.onChangeEndDate(field, date);
        newValue = { ...newValue, to: { ...newValue.to, date: date } };
      }
      field.input.onChange(newValue);
    }

    if (this.props.onChangeStartDateTime) {
      this.props.onChangeStartDateTime(timeHourMinute);
    }
  }

  onChangeStartHour(field, hourTagEvent) {
    const value = hourTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.start,
      hour: value,
    };

    this.setState({ start: timeHourMinute });

    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange({ ...field.input.value, from: timeHourMinute });
    }

    if (this.props.onChangeStartDateTime) {
      this.props.onChangeStartDateTime(timeHourMinute);
    }
  }

  onChangeEndHour(field, hourTagEvent) {
    const value = hourTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.end,
      hour: value,
    };

    this.setState({ end: timeHourMinute });

    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange({ ...field.input.value, to: timeHourMinute });
    }

    if (this.props.onChangeEndDateTime) {
      this.props.onChangeEndDateTime(timeHourMinute);
    }
  }

  onChangeStartMinute(field, minuteTagEvent) {
    const value = minuteTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.start,
      minute: value,
    };

    this.setState({ start: timeHourMinute });

    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange({ ...field.input.value, from: timeHourMinute });
    }

    if (this.props.onChangeStartDateTime) {
      this.props.onChangeStartDateTime(timeHourMinute);
    }
  }

  onChangeEndMinute(field, minuteTagEvent) {
    const value = minuteTagEvent.target.value;
    const timeHourMinute = {
      ...this.state.end,
      minute: value,
    };

    this.setState({ end: timeHourMinute });

    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange({ ...field.input.value, to: timeHourMinute });
    }

    if (this.props.onChangeEndDateTime) {
      this.props.onChangeEndDateTime(timeHourMinute);
    }
  }

  onChangeEndDate(field, date) {
    console.debug('date picker', date);

    const timeHourMinute = {
      ...this.state.end,
      date: date,
    };

    this.setState({ end: timeHourMinute });

    if (field && field.input) {
      console.debug('this.field', field);
      field.input.onChange({ ...field.input.value, to: timeHourMinute });
    }

    if (this.props.onChangeEndDateTime) {
      this.props.onChangeEndDateTime(timeHourMinute);
    }

    return timeHourMinute;
  }

  isRequired() {
    return this.props.isRequired;
  }

  component(field) {
    console.debug('field value', field);
    return (
      <>
        <div className="form-group row">
          {this.props.name ? (
            <Element name={`position-${this.props.name}`} />
          ) : (
            <></>
          )}
          <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
            {this.props.startLabel}&nbsp;
            {this.isRequired() ? <span className="text-danger">*</span> : <></>}
          </FormLabel>
          <div className="col-sm-6">
            <Row>
              <Col>
                <FormDatePicker
                  highlightWeekend
                  name={this.props.startDateName}
                  day={
                    field.input.value != null
                      ? field.input.value.from.date
                      : this.state.start.date
                  }
                  setDay={
                    field.input.value != null
                      ? field.input.value.from.date
                      : this.state.start.date
                  }
                  format="YYYY/MM/DD"
                  locale="ja"
                  onChange={this.onChangeStartDate.bind(this, field)}
                />
              </Col>
              <ColAlignLeft>
                <FormHour
                  name={this.props.startHourName}
                  value={
                    field.input.value != null
                      ? field.input.value.from.hour
                      : this.props.startHour
                  }
                  onChange={this.onChangeStartHour.bind(this, field)}
                />
              </ColAlignLeft>
              <ColAlignLeft>
                <FormMinute
                  name={this.props.startMinuteName}
                  value={
                    field.input.value != null
                      ? field.input.value.from.minute
                      : this.props.startMinute
                  }
                  onChange={this.onChangeStartMinute.bind(this, field)}
                />
              </ColAlignLeft>
            </Row>
          </div>
        </div>
        <div className="form-group row">
          <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
            {this.props.endLabel}&nbsp;
            {this.isRequired() ? <span className="text-danger">*</span> : <></>}
          </FormLabel>
          <div className="col-sm-6">
            <Row>
              <Col>
                <FormDatePicker
                  highlightWeekend
                  name={this.props.endDateName}
                  day={
                    field.input.value != null
                      ? field.input.value.to.date
                      : this.state.start.date
                  }
                  setDay={
                    field.input.value != null
                      ? field.input.value.to.date
                      : this.state.start.date
                  }
                  format="YYYY/MM/DD"
                  locale="ja"
                  onChange={this.onChangeEndDate.bind(this, field)}
                />
              </Col>
              <ColAlignLeft>
                <FormHour
                  name={this.props.endHourName}
                  value={
                    field.input.value != null
                      ? field.input.value.to.hour
                      : this.props.endHour
                  }
                  onChange={this.onChangeEndHour.bind(this, field)}
                />
              </ColAlignLeft>
              <ColAlignLeft>
                <FormMinute
                  name={this.props.endMinuteName}
                  value={
                    field.input.value != null
                      ? field.input.value.to.minute
                      : this.props.endMinute
                  }
                  onChange={this.onChangeEndMinute.bind(this, field)}
                />
              </ColAlignLeft>
            </Row>
            <Row>
              {field.meta.touched && field.meta.error && (
                <BlockDiv type="invalid">{field.meta.error}</BlockDiv>
              )}
            </Row>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <Field
        component={this.component}
        name={this.props.name}
        validate={this.requiredValidator}
      />
    );
  }

  requiredValidator(value) {
    return value &&
      value.from &&
      value.from.date &&
      value.from.hour &&
      value.from.minute &&
      value.to &&
      value.to.date &&
      value.to.hour &&
      value.to.minute
      ? undefined
      : Validator.ErrorMessages.required;
  }
}

const BlockDiv = styled(Form.Control.Feedback)`
  display: block;
  margin-left: 15px;
`;

export default FormPeriodDateTimeComponent;

import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'redux-form';
import styled from 'styled-components';

import FormGroupRow from '../../atoms/FormGroupRow';
import FormLabel from '../../atoms/FormLabel';
import FormNumber from '../../atoms/FormNumberForForm';

class FormReceptionStartTimeComponent extends React.Component {
  static get propTypes() {
    return {
      label: PropTypes.string,
      value: PropTypes.string,
      min: PropTypes.string,
      max: PropTypes.string,
      step: PropTypes.string,
      onChange: PropTypes.func,
      errorMessage: PropTypes.array,
      name: PropTypes.string,
      validate: PropTypes.arrayOf(PropTypes.func),
    };
  }

  constructor(props) {
    super(props);

    this.component = this.component.bind(this);
  }

  component(field) {
    return (
      <FormGroupRow>
        <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
          {this.props.label}
        </FormLabel>
        <div className="col-6">
          <InlineFormNumber
            onChange={this.props.onChange}
            value={this.props.value}
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            className={
              this.props.errorMessage != null &&
              this.props.errorMessage.length > 0
                ? 'is-invalid'
                : ''
            }
            field={field}
          />
          <LeftMarginSpan>分前開場</LeftMarginSpan>
          {field.meta.touched && field.meta.error && (
            <Form.Control.Feedback type="invalid">
              {field.meta.error}
            </Form.Control.Feedback>
          )}
        </div>
        {this.props.errorMessage != null &&
        this.props.errorMessage.length > 0 ? (
          <div className="invalid-feedback">
            <ul>
              {this.props.errorMessage.map((errorMessage, i) => {
                return <li key={i}>{errorMessage}</li>;
              })}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </FormGroupRow>
    );
  }

  render() {
    return (
      <Field
        component={this.component}
        name={this.props.name}
        validate={this.props.validate || []}
      />
    );
  }
}

const InlineFormNumber = styled(FormNumber).attrs((props) => ({
  className: props.className,
}))`
  display: inline-block;
  width: 5em;
`;

const LeftMarginSpan = styled.span`
  margin-left: 0.5em;
`;

export default FormReceptionStartTimeComponent;

import PropTypes from 'prop-types';
import React from 'react';

import NormalFormButton from './NormalFormButton';

class InfoFormButton extends React.Component {
  static get propTypes() {
    return {
      children: PropTypes.any,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      className: PropTypes.string,
    };
  }

  render() {
    return (
      <NormalFormButton color="info" {...this.props}>
        {this.props.children}
      </NormalFormButton>
    );
  }
}

export default InfoFormButton;

import {
  StaffSearch,
  StaffRefer,
  StaffEdit,
  StaffBulkUpdate,
} from '../../../containers/Staff';

import routePath from './routePath';

const StaffRoutes = [
  {
    path: routePath.search,
    exact: true,
    component: StaffSearch,
  },
  {
    path: routePath.refer(':id'),
    component: StaffRefer,
  },
  {
    path: routePath.newEdit,
    component: StaffEdit,
    exact: true,
  },
  {
    path: routePath.edit(':id'),
    component: StaffEdit,
  },
  {
    path: routePath.bulkUpdate,
    component: StaffBulkUpdate,
  },
];

export default StaffRoutes;

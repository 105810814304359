import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import { makeUploadProfileImg, deleteFile } from '../../actions/AdminProfile';
import DeleteFileButton from '../molecules/DeleteFileButton';
import FileButton from '../molecules/FileButton';

class ProfileImage extends React.Component {
  static get propTypes() {
    return {
      updateFile: PropTypes.func,
      deleteUploadImageFile: PropTypes.func,
      deleteImageFile: PropTypes.func,
      children: PropTypes.any,
      onChange: PropTypes.func,
      form: PropTypes.string,
      name: PropTypes.string,
      referer: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
      fieldName: PropTypes.instanceOf(ProfileImageFieldParam),
      getFormValuesFormName: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
  }

  handleChangeFile(e) {
    const target = e.target;
    const file = target.files.item(0);

    if (file == null) {
      return;
    }

    if (this.props.onChange) {
      this.props.onChange(true);
    }

    console.debug('handleChangefile', target);
    console.debug(target.value);
    console.debug('file', file);

    const profileTempIdName = this.props.fieldName.profileTempId;
    this.props.updateFile(
      file,
      this.props.form,
      profileTempIdName,
      this.props.referer
    );
  }

  deleteFile() {
    const profileTempIdName = this.props.fieldName.profileTempId;
    const profileImageIdName = this.props.fieldName.profileImageId;
    const profileTempId = this.props.getFormValuesFormName(this.props.form)[
      profileTempIdName
    ];

    const profileImageId = this.props.getFormValuesFormName(this.props.form)[
      profileImageIdName
    ];

    if (profileTempId) {
      this.props.deleteUploadImageFile(this.props.form, profileTempIdName);
    } else if (profileImageId) {
      const profileUrlName = this.props.fieldName.profileUrl;

      this.props.deleteImageFile(this.props.form, [
        profileImageIdName,
        profileUrlName,
      ]);
    }

    if (this.props.onChange) {
      this.props.onChange(false);
    }
  }

  render() {
    return (
      <FormGroup>
        <Row>
          <Col xs={{ span: 2, offset: 1 }}>
            <label className="control-label">{this.props.label}</label>
          </Col>
          <Col xs={3}>
            <FileButton onChange={this.handleChangeFile} />
            <DeleteFileButton onClick={this.deleteFile} />
          </Col>

          <Col xs={6} className="control-detail-div">
            {this.props.url ? (
              <img className="ml-3" width="200" src={this.props.url} />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

export class ProfileImageFieldParam {
  constructor(profileTempId, profileImageId, profileUrl) {
    /**
     * @property {string} profileTempId
     *
     */
    this.profileTempId = profileTempId;

    /**
     * @property {string} profileImageId
     */
    this.profileImageId = profileImageId;

    /**
     * @property {string} profileUrl
     */
    this.profileUrl = profileUrl;
  }
}

export default connect(
  (state) => {
    const getFormValuesFormName = (formName) => {
      return getFormValues(formName)(state);
    };

    return {
      getFormValuesFormName: getFormValuesFormName,
    };
  },
  (dispatch) => {
    return {
      updateFile(file, form, name, referer) {
        dispatch(makeUploadProfileImg(file, form, name, referer));
      },
      deleteUploadImageFile(form, name) {
        dispatch(deleteFile(form, [name]));
      },
      deleteImageFile(form, names) {
        dispatch(deleteFile(form, names));
      },
    };
  }
)(ProfileImage);

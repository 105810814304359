import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { reduxForm } from 'redux-form';

import FormInlineRadioComponent from '../../molecules/FormInlineRadioComponentForForm';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import SearchButton from '../../molecules/SearchButton';

class RoleManagementSearchForm extends React.Component {
  static get propTypes() {
    return {
      handleSubmit: PropTypes.func,
    };
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div>
          <HorizontalFormInputComponent
            label="名前"
            name="name"
            component="input"
            type="text"
          />
          <FormInlineRadioComponent
            label="システム管理者フラグ"
            options={[
              { name: '全て', value: '0', default: true },
              { name: 'なし', value: '1', default: false },
              { name: 'システム管理者', value: '2', default: false },
            ]}
            name="isSystemAdmin"
          />
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <SearchButton />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}
export default reduxForm({
  // a unique name for the form
  form: 'roleManagementSearchForm',
})(RoleManagementSearchForm);

const initialState = {
  adminProfileRefer: {
    detail: {},
  },
  adminProfileEdit: {
    isSubmitting: false,
    isComplete: false,
    role: {},
  },
  isLoading: false,
};

const adminProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'admin/profile/FETCH_ADMIN_PROFILE': {
      const results = action.payload.result;
      return {
        ...state,
        adminProfileRefer: { ...state.adminProfileRefer, detail: results },
        isLoading: false,
      };
    }
    case 'admin/profile/UPDATE_ADMIN_PROFILE_LOADING': {
      return {
        ...state,
        adminProfileEdit: { ...state.adminProfileEdit, isSubmitting: true },
      };
    }
    case 'admin/profile/FETCH_UPDATE_ADMIN_PROFILE': {
      return {
        ...state,
        adminProfileEdit: { ...state.adminProfileEdit, isComplete: true },
      };
    }
    case 'admin/profile/INIT_STATE': {
      return initialState;
    }
    case 'admin/profile/SET_EDIT_VALUES': {
      console.debug('set_edit_values', action.payload.values);
      return {
        ...state,
        adminProfileRefer: {
          detail: {
            ...state.adminProfileRefer.detail,
            familyName: action.payload.values.familyName,
            firstName: action.payload.values.firstName,
            profileImageTempId: action.payload.values.profileImageTempId,
            profileImageId: action.payload.values.profileImageId,
            profileImgURL: action.payload.values.profileImgURL,
            mailAddress: action.payload.values.mailAddress,
            phoneNumber: `${action.payload.values.tel1}-${action.payload.values.tel2}-${action.payload.values.tel3}`,
            baseId: action.payload.values.baseId,
          },
        },
      };
    }
    case 'admin/profile/SHOW_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return state;
  }
};

export { adminProfileReducer as default };

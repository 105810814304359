import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';

import AgeInputComponent from '../../molecules/AgeInputComponent';
import CancelButton from '../../molecules/CancelButton';
import CloseButton from '../../molecules/CloseButton';
import FormInputComponent from '../../molecules/FormInputComponent';
import FormLabelComponent from '../../molecules/FormLabelComponent';
import SaveButton from '../../molecules/SaveButton';
import Card from '../../organisms/Card';

class SampleEdit extends React.Component {
  constructor(props) {
    super(props);

    this.inputOnChangeAge = this.inputOnChangeAge.bind(this);
    this.inputOnChangePrefectureId = this.inputOnChangePrefectureId.bind(this);
    this.transitionRefer = this.transitionRefer.bind(this);
    this.save = this.save.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.ageOnError = this.ageOnError.bind(this);
    this.state = {
      age: '' + this.props.state.data.age,
      isValid: {
        age: true,
      },
    };
  }

  static get propTypes() {
    return {
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
      referData: PropTypes.func,
      state: PropTypes.shape({
        data: PropTypes.shape({
          name: PropTypes.string,
          age: PropTypes.string,
          prefectureId: PropTypes.number,
        }),
        isDisplaySavedModal: PropTypes.bool,
        errorResult: PropTypes.shape({
          messages: PropTypes.object,
        }),
      }),
      setAge: PropTypes.func,
      setPrefectureId: PropTypes.func,
      save: PropTypes.func,
      transitionRefer: PropTypes.func,
      history: PropTypes.any,
    };
  }

  save() {
    this.props.save(this.props.state.data);
  }

  inputOnChangeAge(e) {
    this.props.setAge(e.target.value);
  }

  inputOnChangePrefectureId(e) {
    this.props.setPrefectureId(e.target.value);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ id: id });
    console.log(id);

    this.props.referData(id);
  }

  handleClose() {
    this.transitionRefer();
  }

  transitionRefer() {
    this.props.transitionRefer(this.props.history, this.state.id);
  }

  ageOnError(isInValid) {
    console.debug('age isInValid:', isInValid);
    this.setState({
      isValid: {
        age: !isInValid,
      },
    });
  }

  render() {
    const isValid = () => {
      return this.state.isValid.age;
    };
    console.debug('isValid: ', isValid());
    return (
      <>
        <section className="section">
          <div className="section-header">
            <h1>サンプルページ</h1>
          </div>

          <div className="section-body">
            <Card headerTitle="サンプル変更ページ">
              <div className="form-group">
                <FormLabelComponent
                  label="name"
                  value={this.props.state.data.name}
                />
              </div>

              <AgeInputComponent
                label="age"
                value={this.props.state.data.age}
                onChange={this.inputOnChangeAge}
                errorMessage={this.props.state.errorResult.messages.age}
                onError={this.ageOnError}
              />

              <FormInputComponent
                label="prefectureId"
                value={this.props.state.data.prefectureId}
                onChange={this.inputOnChangePrefectureId}
                errorMessage={
                  this.props.state.errorResult.messages.prefectureId
                }
              />

              <CancelButton onClick={this.transitionRefer} />
              <SaveButton onClick={this.save} disabled={!isValid()} />
            </Card>
          </div>
        </section>

        <Modal
          show={this.props.state.isDisplaySavedModal}
          onHide={this.handleClose}
        >
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <p>正常に登録が完了しました。</p>
            <CloseButton onClick={this.handleClose} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withRouter(SampleEdit);

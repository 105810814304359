import { push } from 'connected-react-router';

import { EmptyAction } from '../components/model/Action';
import Params from '../components/model/Params';

import createAxios from './axios';
import { trackAsync } from './util';

const axios = createAxios('/login');

const fetchLogin = (values) => {
  console.debug('fetchLogin', values);

  const params = new Params({
    loginid: values.mail,
    password: values.password,
  });

  return axios.post('/auth/staff/login', params.removeParams());
};

const successLogin = (path) => {
  return push(path);
};

const fetchErrorData = (error) => {
  console.debug('error: ', error);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status); // 例：400
    console.log(error.response.statusText); // Bad Request
    console.log(error.response.headers);

    if (error.response.status === 404) {
      return {
        type: 'auth/login/NOTFOUND_USER',
        payload: error.response.data,
      };
    } else if (error.response.status === 400) {
      return {
        type: 'auth/login/BAD_REQUEST',
        payload: error.response.data,
      };
    }
  }

  return {
    type: 'FETCH_ERROR',
    payload: { result: error },
  };
};

const fetchProfile = () => {
  return axios.get('/admin/profile');
};

const initErrorMessage = () => {
  return new EmptyAction('login/INIT_ERROR_MESSAGE');
};

const makeFetchLogin = (values) => {
  return (dispatch) => {
    return trackAsync(async () => {
      try {
        dispatch(initErrorMessage());

        await fetchLogin(values);
        const profileRes = await fetchProfile();

        dispatch(successLogin(profileRes.data.loginMenuPath));
      } catch (err) {
        return dispatch(fetchErrorData(err));
      }
    });
  };
};

// eslint-disable-next-line import/prefer-default-export
export { makeFetchLogin };

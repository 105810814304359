import PropTypes, { object, func } from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import {
  makeFetchHallsById,
  makeFetchProfile,
  makeSetInitializeVenueSelect,
} from '../../../actions/ReservationManagement';
import FormInlineCheckBoxesComponent from '../../molecules/FormInlineCheckBoxesComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import SearchButton from '../../molecules/SearchButton';

import ChairmanInput from './ChairmanInputComponent';
import SubstituteAlert from './SubstituteAlert';

class SearchEvents extends React.Component {
  static get propTypes() {
    return {
      options: PropTypes.shape({
        areas: PropTypes.array,
        halls: PropTypes.array,
        categories: PropTypes.array,
        tags: PropTypes.array,
        bases: PropTypes.array,
        eventTypes: PropTypes.array,
      }),
      substituteUser: object,
      areaId: PropTypes.string,
      onClickSubstituteReleaseButton: func,
      onClickAreasSelectBox: PropTypes.func,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      fetchHallsById: PropTypes.func,
      setInitializeVenueSelect: PropTypes.func,
      fetchMyName: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.state = {};
    this.onChangeSelectBox = this.onChangeSelectBox.bind(this);
    this.onClickSelfButton = this.onClickSelfButton.bind(this);
  }

  /**
   * エリアのセレクト
   * @param {Event} e
   */
  onChangeSelectBox(e) {
    if (e.target.value) {
      this.setState({ selectedAreaId: e.target.value });
      this.props.fetchHallsById(e.target.value);
    }
    this.props.setInitializeVenueSelect();
  }

  /**
   * 自分担当ボタンを押下
   */
  onClickSelfButton() {
    this.props.fetchMyName();
  }

  render() {
    const isSelectedAreaId = () => {
      if (
        this.props.areaId &&
        this.props.options.halls &&
        this.props.options.halls.length > 0
      ) {
        return true;
      }

      return false;
    };

    return (
      <div>
        {this.props.substituteUser ? (
          <SubstituteAlert
            substituteUser={this.props.substituteUser}
            onClickReleaseButton={this.props.onClickSubstituteReleaseButton}
          />
        ) : (
          <></>
        )}

        <Form onSubmit={this.props.handleSubmit}>
          {/* 講師 */}
          <ChairmanInput
            name="chairman"
            label="講師"
            onClickSelfAssignButton={this.onClickSelfButton}
          />
          {/* 対象 */}
          <FormInlineCheckBoxesComponent
            label="対象"
            name="eventTypeIds"
            options={this.props.options.eventTypes || []}
          />
          {/* カテゴリー */}
          <FormInlineCheckBoxesComponent
            label="カテゴリー"
            name="categoryIds"
            options={this.props.options.categories}
          />
          {/* タグ */}
          <FormInlineCheckBoxesComponent
            label="タグ"
            name="tagsIds"
            options={this.props.options.tags}
          />
          {/* エリア */}
          <HorizontalSelectComponent
            label="エリア"
            name="areaId"
            options={this.props.options.areas}
            onChange={this.onChangeSelectBox}
          />
          {/* 会場 */}
          {isSelectedAreaId() ? (
            <HorizontalSelectComponent
              label="会場"
              name="venueId"
              options={this.props.options.halls}
            />
          ) : (
            <></>
          )}
          {/* 担当拠点 */}
          <HorizontalSelectComponent
            label="担当拠点"
            name="baseId"
            options={this.props.options.bases}
          />
          {/* 検索ボタン */}
          <Row>
            <Col>
              <div className="text-center">
                <SearchButton />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const reservationSearchEventsForm = reduxForm({
  form: 'ReservationSearchEventsForm',
})(SearchEvents);

const selector = formValueSelector('ReservationSearchEventsForm');

export default connect(
  (state) => {
    const areaIdValue = selector(state, 'areaId');

    const searchValues = state.reservationManagement.searchValues;

    return {
      options: state.options,
      areaId: areaIdValue,
      initialValues: {
        chairman:
          searchValues && searchValues.chairman
            ? searchValues.chairman
            : undefined,
        eventTypeIds:
          searchValues && searchValues.eventTypeIds
            ? searchValues.eventTypeIds
            : [],
        categoryIds:
          searchValues && searchValues.categoryIds
            ? searchValues.categoryIds
            : [],
        tagsIds:
          searchValues && searchValues.tagsIds ? searchValues.tagsIds : [],
        areaId:
          searchValues && searchValues.areaId ? searchValues.areaId : null,
        baseId:
          searchValues && searchValues.baseId ? searchValues.baseId : null,
        venueId:
          searchValues && searchValues.venueId ? searchValues.venueId : null,
      },
    };
  },
  (dispatch) => {
    return {
      fetchHallsById(areaId) {
        dispatch(makeFetchHallsById(areaId));
      },
      /**
       * ログイン者自身の名前を取得する
       */
      fetchMyName() {
        dispatch(makeFetchProfile());
      },
      setInitializeVenueSelect() {
        dispatch(makeSetInitializeVenueSelect('ReservationSearchEventsForm'));
      },
    };
  }
)(reservationSearchEventsForm);

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field } from 'redux-form';

import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
  makeFetchTemplateCategories,
  makeFetchTemplateNames,
  makeFetchTemplateGroups,
  makeFetchContactTypes,
} from '../../../actions/Template';
import FormLabel from '../../atoms/FormLabel';
import PrimaryButton from '../../atoms/PrimaryButton';
import Option from '../../model/Option';
import CancelButton from '../../molecules/CancelButton';
import ConfirmButton from '../../molecules/ConfirmButton';
import FormInlineRadioComponent from '../../molecules/FormInlineRadioComponentForForm';
import FormReferItem from '../../molecules/FormReferItem';
import HorizontalFormInputComponent from '../../molecules/HorizontalFormInputComponent';
import HorizontalFormTextAreaComponent from '../../molecules/HorizontalFormTextareaComponent';
import HorizontalFormWysiwygEditorComponent from '../../molecules/HorizontalFormWysiwygEditorComponent';
import HorizontalSelectComponent from '../../molecules/HorizontalSelectComponent';
import HorizontalTextComponent from '../../molecules/HorizontalTextComponent';
import * as Validator from '../../utils/Validate';

const VALIDATE_MAIL_BODY_MAX = 3000;

const maxLengthValidator = (length) => {
  return (value) => {
    return Validator.maxLength(length, value);
  };
};

const bodyHtmlLengthValidator = maxLengthValidator(VALIDATE_MAIL_BODY_MAX);

class TemplateEditForm extends React.Component {
  static get propTypes() {
    return {
      isCopy: PropTypes.bool,
      isDefault: PropTypes.bool,
      isMail: PropTypes.bool,
      htmlBodyInitialText: PropTypes.string,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      onTestMail: PropTypes.func,
      options: PropTypes.shape({
        templateCategories: PropTypes.array,
        templateNames: PropTypes.array,
        templateGroups: PropTypes.array,
      }),
      optionContactTypes: PropTypes.array,
      onPrevButtonClick: PropTypes.func,
      invalid: PropTypes.bool,
      submitting: PropTypes.bool,
      groupId: PropTypes.string,
      fetchTemplateCategories: PropTypes.func,
      fetchTemplateNames: PropTypes.func,
      fetchTemplateGroups: PropTypes.func,
      fetchContactTypes: PropTypes.func,
      contactTypeName: PropTypes.string,
      initialValues: PropTypes.shape({
        isDefault: PropTypes.bool,
        categoryName: PropTypes.string,
        templateName: PropTypes.string,
      }),
      valid: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.clickTemplateCategories = this.clickTemplateCategories.bind(this);
    this.sendTestMail = this.sendTestMail.bind(this);

    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchTemplateCategories();
    this.props.fetchTemplateNames();
    this.props.fetchTemplateGroups();
    this.props.fetchContactTypes();
  }

  clickTemplateCategories(event) {
    this.props.fetchTemplateNames(event.target.value);
  }

  onSubmit(values) {
    this.props.onSubmit(values);
  }

  onEditorStateChange(editorState) {
    this.setState({
      ...this.state,
      editorState: editorState,
    });
  }

  sendTestMail(values) {
    this.props.onTestMail(values);
  }

  render() {
    const isGroupNew = () => {
      return this.props.groupId === '0';
    };

    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <div>
          <HorizontalTextComponent
            label="カテゴリー"
            text={this.props.initialValues.categoryName}
          />
          <HorizontalTextComponent
            label="テンプレート名"
            text={this.props.initialValues.templateName}
          />
          <HorizontalSelectComponent
            label="グループ"
            options={this.props.options.templateGroups}
            name="groupId"
          />

          {isGroupNew() ? (
            <>
              <HorizontalFormInputComponent
                label="新規グループ名"
                name="newGroupName"
                component="input"
                type="text"
                validate={[Validator.required]}
              />
            </>
          ) : (
            <></>
          )}

          {!this.props.isCopy && (
            <>
              <FormReferItem label="通知媒体">
                {this.props.contactTypeName}
              </FormReferItem>
              <Field component="input" name="contactType" type="hidden" />
            </>
          )}
          {(this.props.isCopy || !this.props.initialValues.isDefault) && (
            <>
              <FormInlineRadioComponent
                label="通知媒体"
                options={this.props.optionContactTypes}
                name="contactType"
              />
              <Field component="input" name="isMail" type="hidden" />
              <HorizontalFormInputComponent
                label="カスタム名"
                name="customName"
                component="input"
                type="text"
                validate={[Validator.required, validateCustomName]}
              />
            </>
          )}
          <HorizontalFormInputComponent
            label="件名"
            name="subject"
            component="input"
            type="text"
            validate={[Validator.required, validateMailSubject]}
          />
          <HorizontalFormTextAreaComponent
            label="本文"
            name="body"
            component="input"
            type="text"
            validate={[Validator.required, validateMailBody]}
          />
          {this.props.isMail ? (
            <>
              <HorizontalFormWysiwygEditorComponent
                label="本文(HTML)"
                name="bodyHtml"
                maxLength={VALIDATE_MAIL_BODY_MAX}
                initialText={this.props.htmlBodyInitialText}
                validate={[bodyHtmlLengthValidator]}
              />

              <FormLabel className="col-sm-3 col-form-label">
                テスト送信
              </FormLabel>
              <div className="form-group row">
                <FormLabel className="offset-sm-1 col-sm-2 col-form-label">
                  テスト送信先メールアドレス
                </FormLabel>
                <div className="col-sm-9">
                  <Row>
                    <Col>
                      <Field
                        name="to"
                        className="form-control"
                        type="text"
                        component="input"
                        // validate={[Validator.email]}
                      />
                    </Col>
                    <Col>
                      <PrimaryButton
                        onClick={this.props.handleSubmit((values) =>
                          this.sendTestMail(values)
                        )}
                      >
                        テスト送信
                      </PrimaryButton>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <Row>
          <Col>
            <div className="text-center">
              <CancelButton onClick={this.props.onPrevButtonClick} />
              <ConfirmButton
                isEnabled={() => {
                  return this.props.valid && !this.props.submitting;
                }}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const validateCustomName = (value) => {
  return Validator.maxLength(30, value);
};

const validateMailSubject = (value) => {
  return Validator.maxLength(50, value);
};

const validateMailBody = (value) => {
  return Validator.maxLength(VALIDATE_MAIL_BODY_MAX, value);
};

const templateEditForm = reduxForm({
  // a unique name for the form
  form: 'templateEditForm',
  enableReinitialize: true,
})(TemplateEditForm);

const selector = formValueSelector('templateEditForm');

export default connect(
  (state, props) => {
    const contactTypeValue = selector(state, 'contactType');
    const groupIdValue = selector(state, 'groupId');

    let contactTypeName = '';
    if (state.template.templateRefer.detail.contactType) {
      const find = state.template.contactTypes.find(
        (type) => type.value === state.template.templateRefer.detail.contactType
      );
      contactTypeName = find ? find.label : '';
    }

    let initialValues = state.template.templateRefer.detail;
    if (props.values != null) {
      initialValues = props.values;
    }

    console.debug('templateEditForm-contactType: ', contactTypeValue);
    console.debug('templateEditForm-groupId: ', groupIdValue);
    return {
      initialValues: initialValues,
      htmlBodyInitialText: initialValues.bodyHtml,
      options: state.options,
      optionContactTypes: state.template.contactTypes.map(
        (type) => new Option(type.value, type.label)
      ),
      isMail: state.template.contactTypes.some(
        (type) => type.value === contactTypeValue && type.isMail
      ),
      groupId: groupIdValue,
      contactTypeName: contactTypeName,
      detail: state.template.templateRefer.detail,
    };
  },
  (dispatch) => {
    return {
      fetchTemplateCategories() {
        dispatch(makeFetchTemplateCategories());
      },
      fetchTemplateNames(id) {
        dispatch(makeFetchTemplateNames(id));
      },
      fetchTemplateGroups() {
        dispatch(makeFetchTemplateGroups());
      },
      fetchContactTypes() {
        dispatch(makeFetchContactTypes());
      },
    };
  }
)(templateEditForm);

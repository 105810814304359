import PropTypes from 'prop-types';
import React from 'react';

import PrimaryButton from '../../atoms/PrimaryButton';
import { RegisterAction } from '../../utils/PermissionComponent';

class ModifyButton extends React.Component {
  static get propTypes() {
    return {
      onClick: PropTypes.func,
      isEnabled: PropTypes.func,
    };
  }

  render() {
    return (
      <RegisterAction>
        <PrimaryButton
          isEnabled={this.props.isEnabled}
          onClick={this.props.onClick}
        >
          アクティベーションメールの再送信
        </PrimaryButton>
      </RegisterAction>
    );
  }
}

export default ModifyButton;
